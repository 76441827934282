import React from 'react'
import PropTypes from 'prop-types'
import { CustomSelect, Form, Input } from '../../../../components';
import withLoading from '../../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    frequency: PropTypes.string,
    assignedTo: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  frequency: PropTypes.array.isRequired,
  assignedTo: PropTypes.array.isRequired,
};

const defaultProps = {
  data: {
    title: '',
    frequency: '',
    assignedTo: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  handleDropDownChange: () => null,
};

const FormView = (props) => {
  const { data, refsObj, handleInputChange, handleDropDownChange, enableErrorDisplay, frequency, assignedTo } = props;
  return (
    <Form>
      <Input
        ref={ref => refsObj.title = ref}
        value={data.title}
        name="title"
        type="text"
        placeholder="Title"
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event)}
        labelContent="Title"
      />

      <CustomSelect
        labelContent="Count Frequency"
        getOptionValue={({ id }) => id}
        getOptionLabel={({ label }) => label}
        options={frequency}
        name="frequency"
        value={frequency && frequency.filter(({ id }) => id === data.frequency)}
        onChange={event => handleDropDownChange(event.id, 'frequency')
        }
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        ref={ref => refsObj.frequency = ref}
      />
      <CustomSelect
        labelContent="Assigned To"
        getOptionValue={({ id }) => id}
        getOptionLabel={({ fullName }) => fullName}
        options={assignedTo}
        name="assignedTo"
        value={assignedTo && assignedTo.filter(({ id }) => id === data.assignedTo)}
        onChange={event => handleDropDownChange(event.id, 'assignedTo')
        }
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        ref={ref => refsObj.assignedTo = ref}
      />
    </Form>
  )
}


FormView.propTypes = propTypes;

FormView.defaultProps = defaultProps;

const composedForm = withLoading(FormView);

export default composedForm;
