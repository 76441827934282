import { compose } from 'recompose';
import VisibilityAudit from './VisibilityAudit';
import {
  query,
  mutation,
  CREATE_VISIBILITY_AUDIT,
  UPDATE_VISIBILITY_AUDIT,
  DELETE_VISIBILITY_AUDIT,
  BULK_DELETE_VISIBILITY_AUDIT,
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedVisibilityAudit = compose(
  CREATE_VISIBILITY_AUDIT,
  UPDATE_VISIBILITY_AUDIT,
  DELETE_VISIBILITY_AUDIT,
  BULK_DELETE_VISIBILITY_AUDIT,
  withRequestTracker({
    query,
    mutation,
  })
)(VisibilityAudit);


export default ComposedVisibilityAudit;
