import React from 'react';
import PropTypes from 'prop-types';
import withErrorAndLoading from '../../../../../../utils/composition/withLoading';
import { CustomSelect, Input } from '../../../../../../components';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { EMPTY, INVALID } from '../../../../../../data/enums/ErrorMessage';
import FormContentStyled from '../../../../../../components/global-css/FormContentStyled';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    active: PropTypes.bool,
    address: PropTypes.string,
    Town: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    servicesUsed: PropTypes.shape({
      billing: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
      delivery: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
      logistic: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
    }),
  }).isRequired,
  menu: PropTypes.shape({
    townList: PropTypes.instanceOf(Array),
  }).isRequired,
  enableErrorDisplay: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  refsObj: PropTypes.shape(Object).isRequired,
  getLatLng: PropTypes.func.isRequired,
  crudMode: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(Object).isRequired,
};

const defaultProps = {
  enableErrorDisplay: false,
};

const General = ({ ...props }) => {
  const { details, refsObj, onInputChange, onDropDownChange, enableErrorDisplay, crudMode ,userGroupList } =
    props;
  return (
    <div className='create-ui '>
      <div
        className={`create-ui-inner tab-less-height horizontal-form ${
          crudMode === EVENT_OPERATION.READ ? 'disabled' : ''
        }`}
      >
        <div className='flex'>
          <div className='w-half'>
            <FormContentStyled>
              <h3 className='w-100'>General Details</h3>
              <div className='form-input-wrap w-100'>
                <Input
                  name='name'
                  type='text'
                  enableValidation
                  enableErrorDisplay={enableErrorDisplay}
                  value={details.name || ''}
                  placeholder='Name'
                  labelContent='Name'
                  ref={(ref) => (refsObj.name = ref)}
                  onChange={(event) => onInputChange(event, 'name', ['name'])}
                />

                <CustomSelect
                  enableValidation
                  labelContent='User Group'
                  className='custom-select'
                  options={userGroupList}
                  placeholder='Select User Group'
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ name }) => name}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => onDropDownChange(event.id, ['userGrpId'])}
                  ref={(ref) => (refsObj.userGrpId = ref)}
                  value={userGroupList.filter(({ id }) => id === details.userGrpId)}
                />

                <Input
                  name='displayName'
                  type='text'
                  enableValidation
                  value={details.displayName || ''}
                  labelContent='Alias'
                  placeholder='Alias'
                  ref={(ref) => (refsObj.displayName = ref)}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => onInputChange(event, 'displayName', ['displayName'])}
                />
              </div>
            </FormContentStyled>
          </div>
        </div>
      </div>
    </div>
  );
};

General.propTypes = propTypes;

General.defaultProps = defaultProps;

const GeneralInfo = withErrorAndLoading(General);

export default GeneralInfo;
