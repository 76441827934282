import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { DialogWrapper } from '../../../../../common';
import ContactDialogWrapper from './ContactDialogWrapper';
import { dateTimeFormat } from '../../../../../../utils/date';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    rMapId: PropTypes.number,
    image: PropTypes.string,
    address: PropTypes.string,
    panNumber: PropTypes.string,
    geoLocation: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  }).isRequired,
  menu: PropTypes.shape({
    channelList: PropTypes.instanceOf(Array),
    categoryList: PropTypes.instanceOf(Array),
    routeList: PropTypes.instanceOf(Array),
    townList: PropTypes.instanceOf(Array),
  }).isRequired,
  enableErrorDisplay: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  customInputChange: PropTypes.func.isRequired,
  customInputSelect: PropTypes.func.isRequired,
  refsObj: PropTypes.instanceOf(Object).isRequired,
  crudMode: PropTypes.string.isRequired,
  niranjan: PropTypes.string.isRequired,
  getGeoLocation: PropTypes.func.isRequired,
  getRetailOutletHistory: PropTypes.func,
  id: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  enableToUpdate: PropTypes.bool,
  handleButtonSubmit: PropTypes.func,
  handleButtonCancel: PropTypes.func,
  handleEditIconClick: PropTypes.func,
  retailOutletHistory: PropTypes.arrayOf(Object),
  showCreateModal:PropTypes.bool,
  closeModal:PropTypes.func,
  chainTitle:PropTypes.string,
  addOutletChain: PropTypes.func
};

const defaultProps = {
  enableErrorDisplay: false,
  getRetailOutletHistory: () => null,
  id: null,
  enableToUpdate: false,
  handleButtonSubmit: () => null,
  handleButtonCancel: () => null,
  handleEditIconClick: () => null,
  retailOutletHistory: [],
};

class Detail extends Component {
  render() {
    const {
      data,
      menu,
      crudMode,
      refsObj,
      loading,
      onInputChange,
      enableToUpdate,
      enableErrorDisplay,
      handleButtonSubmit,
      handleButtonCancel,
      customInputChange,
      customInputSelect,
      onDropDownChange,
      handleEditIconClick,
      getGeoLocation,
      retailOutletHistory,
      getRetailOutletHistory,
      id,
      closeModal,
      showCreateModal,
      chainTitle,
      addOutletChain
    } = this.props;
    const title = 'Verified Details';
    return (
      <ContactDialogWrapper>
        <DialogWrapper
          title={title}
          footer
          withOutPadding
          renderDialog={() => (
            <div className='table-wrap'>
              <table>
                <thead>
                  <tr>
                    <th>Updated Numbers</th>
                    <th>Updated By</th>
                    <th>Updated Time</th>
                  </tr>
                </thead>
                <tbody>
                  {retailOutletHistory.map((contact) => (
                    <tr key={`contact-details-${contact.outletid}`}>
                      <td className={contact.isverified ? '' : 'txt-color'}>
                        {contact.phonenumber}
                        {contact.isverified ? <img src='/image/icons/VerifiedIcon.svg' alt='V' /> : ''}
                      </td>
                      <td className={contact.isverified ? '' : 'txt-color'}>{contact.name}</td>
                      <td className={contact.isverified ? '' : 'txt-color'}>{dateTimeFormat(contact.updatedtime)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          render={({ onDialogItemClick }) => (
            <Fragment>
              <View
                data={data}
                id={id}
                menu={menu}
                crudMode={crudMode}
                refsObj={refsObj}
                loading={loading}
                onInputChange={onInputChange}
                onIconClick={onDialogItemClick}
                enableToUpdate={enableToUpdate}
                enableErrorDisplay={enableErrorDisplay}
                handleButtonSubmit={handleButtonSubmit}
                handleButtonCancel={handleButtonCancel}
                onDropDownChange={onDropDownChange}
                customInputChange={customInputChange}
                customInputSelect={customInputSelect}
                handleEditIconClick={handleEditIconClick}
                getGeoLocation={getGeoLocation}
                getRetailOutletHistory={getRetailOutletHistory}
                retailOutletHistory={retailOutletHistory}
                closeModal={closeModal}
                showCreateModal={showCreateModal}
                chainTitle={chainTitle}
                addOutletChain={addOutletChain}
              />
            </Fragment>
          )}
        />
      </ContactDialogWrapper>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export { Detail };

export default Detail;
