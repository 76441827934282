import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Badge } from '../../../../../components';
import { ArrayOfObjSum, filterItems } from '../../../../../utils/arrayProcessor';
import CustomLegend from '../../../components/Legend/CustomLegend';
import ReportTable from '../../../components/ReportTable/ReportTable';
import { fillColor } from '../../../config';
import { getLegend, labelConfig, labelMappings, SOA, SOS, tableHeaderChange } from './config';
import { OutletWiseScoreStyled } from './OutletWiseScoreStyled';
import { TableHeader, TableBody, TableFooter } from './tableConfig';

const OutletWiseScore = ({ title, bu, outletChannel }) => {
  const rScore = useSelector(state => state?.RscoreDatas?.rScore);

  const RSCORE_BU = filterItems(rScore, bu, 'bu');
  const OUTLET_CHANNEL = filterItems(RSCORE_BU, outletChannel, 'outlet_channel');
  const FUNDAMENTAL_WISE = _(OUTLET_CHANNEL).groupBy('fundamental').value();

  const SHARE_0F_SHELF = FUNDAMENTAL_WISE['Share of shelf'];
  const SHARE_0F_AVAILABILITY = FUNDAMENTAL_WISE['Share of availability'];
  const SHARE_0F_DISPLAY = FUNDAMENTAL_WISE['Share of display'];

  // for share of availability
  const summationSOA = () => {
    let pointsAllocatedSum = 0;
    let pointScoredSum = 0;
    let bonusAwarded = 0;
    let requiredJBP = 0;
    let allocated = 0;
    let audited = 0;
    let SOA_ARRAY = [];
    SHARE_0F_AVAILABILITY?.map(item => {
      pointsAllocatedSum += item.points_allocated;
      pointScoredSum += item.point_scored;
      bonusAwarded += item.bonus_point;
      requiredJBP += item?.required_jbp_criteria;
      allocated += item?.allocated_audit_point;
      audited += item?.audit_point;
    });

    SOA_ARRAY.push({ pointsAllocatedSum, pointScoredSum, bonusAwarded, requiredJBP, allocated, audited });
    return SOA_ARRAY;
  };
  const CATEGORY_SOA_TABLE = useMemo(() => summationSOA(), [rScore]);

  // for share of display
  const summationSOD = () => {
    let pointsAllocatedSum = 0;
    let pointScoredSum = 0;
    let bonusAwarded = 0;
    let requiredJBP = 0;
    let allocated = 0;
    let audited = 0;
    let SOD_ARRAY = [];
    SHARE_0F_DISPLAY?.map(item => {
      pointsAllocatedSum += item?.points_allocated;
      pointScoredSum += item?.point_scored;
      bonusAwarded += item?.bonus_point;
      requiredJBP += item?.required_jbp_criteria;
      allocated += item?.allocated_audit_point;
      audited += item?.audit_point;
    });

    SOD_ARRAY.push({ pointsAllocatedSum, pointScoredSum, bonusAwarded, requiredJBP, allocated, audited });
    return SOD_ARRAY;
  };
  const CATEGORY_SOD_TABLE = useMemo(() => summationSOD(), [rScore]);

  const isCategoryHeader = SHARE_0F_SHELF?.every(item => item?.brand === null); //for dynamic table header i.e brand or category
  const groupByLabel = !isCategoryHeader ? 'brand' : 'category';

  // for share of shelf
  const CATEGORY_WISE_SOS = _(SHARE_0F_SHELF).groupBy(groupByLabel).value();
  let arrySOS = [];
  for (const property in CATEGORY_WISE_SOS) {
    let pointScoredSum = 0;
    let pointsAllocatedSum = 0;
    let bonusAwarded = 0;
    let requiredJBP = 0;
    let allocated = 0;
    let audited = 0;

    CATEGORY_WISE_SOS[property]?.map(item => {
      pointScoredSum = pointScoredSum + item?.point_scored;
      pointsAllocatedSum = pointsAllocatedSum + item?.points_allocated;
      bonusAwarded = bonusAwarded + item?.bonus_point;
      requiredJBP += item?.required_jbp_criteria;
      allocated += item?.allocated_audit_point;
      audited += item?.audit_point;
    });
    const data = {
      property,
      pointScoredSum,
      pointsAllocatedSum,
      bonusAwarded,
      requiredJBP,
      allocated,
      audited,
    };
    arrySOS.push(data);
  }

  return (
    <>
      <OutletWiseScoreStyled>
        <div className="title-wrap">
          <h2>{title}</h2>
          <div className={outletChannel.toLowerCase()}>
            <Badge light label={outletChannel} />
          </div>
        </div>
        {(SHARE_0F_DISPLAY || SHARE_0F_SHELF || SHARE_0F_AVAILABILITY) && (
          <CustomLegend data={getLegend(outletChannel)} />
        )}

        {SHARE_0F_SHELF && (
          <div>
            <div className="fundamental"> Share of Shelf</div>
            <div className={`outlet-channel-${outletChannel.toLowerCase()}`}>
              <div className="table-wrap">
                <table>
                  <TableHeader
                    labelConfig={isCategoryHeader ? labelConfig[SOS] : tableHeaderChange(labelConfig[SOS])}
                  />
                  <tbody>
                    {_.sortBy(arrySOS, 'property')?.map(data => (
                      <TableBody
                        data={data}
                        labelConfig={labelConfig[SOS]}
                        labelMappings={labelMappings[SOS]}
                        bgColor={fillColor(`${outletChannel?.toLowerCase()}bgColor`)}
                        baseBgColor={fillColor(`${outletChannel?.toLowerCase()}baseBgColor`)}
                        title="Share of Shelf"
                      />
                    ))}
                  </tbody>
                  {/* <tfoot>{TableFooter(arrySOS, SOS)}</tfoot> */}
                </table>
              </div>
            </div>
          </div>
        )}

        {SHARE_0F_DISPLAY && (
          <div>
            <div className="fundamental" style={{ marginTop: '15px' }}>
              Share of Display
            </div>
            <div className={`outlet-channel-${outletChannel.toLowerCase()}`}>
              <div className="table-wrap">
                <table>
                  <TableHeader labelConfig={labelConfig[SOA]} />
                  <tbody>
                    {CATEGORY_SOD_TABLE?.map(data => (
                      <TableBody
                        data={data}
                        labelConfig={labelConfig[SOA]}
                        labelMappings={labelMappings[SOA]}
                        bgColor={fillColor(`${outletChannel?.toLowerCase()}bgColor`)}
                        baseBgColor={fillColor(`${outletChannel?.toLowerCase()}baseBgColor`)}
                        title="Share of Display"
                      />
                    ))}
                  </tbody>
                  {/* <tfoot>{TableFooter(CATEGORY_SOD_TABLE)}</tfoot> */}
                </table>
              </div>
            </div>
          </div>
        )}

        {SHARE_0F_AVAILABILITY && (
          <div>
            <div className="fundamental" style={{ marginTop: '15px' }}>
              On Shelf Availability
            </div>
            <div className={`outlet-channel-${outletChannel.toLowerCase()}`}>
              <div className="table-wrap">
                <table>
                  <TableHeader labelConfig={labelConfig[SOA]} />
                  <tbody>
                    {CATEGORY_SOA_TABLE?.map(data => (
                      <TableBody
                        data={data}
                        labelConfig={labelConfig[SOA]}
                        labelMappings={labelMappings[SOA]}
                        bgColor={fillColor(`${outletChannel?.toLowerCase()}bgColor`)}
                        baseBgColor={fillColor(`${outletChannel?.toLowerCase()}baseBgColor`)}
                        title="On Shelf Availability"
                      />
                    ))}
                  </tbody>
                  {/* <tfoot>{TableFooter(CATEGORY_SOA_TABLE)}</tfoot> */}
                </table>
              </div>
            </div>
          </div>
        )}
      </OutletWiseScoreStyled>
    </>
  );
};

export default OutletWiseScore;
