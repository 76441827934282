import React, { PureComponent } from 'react';
import { Tooltip, XAxis, YAxis, Legend, CartesianGrid, Bar, BarChart, ResponsiveContainer, Cell } from 'recharts';
import Badge from '../../Badge';
import { BarchartStyled } from './BarchartStyled';

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={5} y={0} dy={3} textAnchor="end" transform="rotate(-90)" className="vertical-text">
          {payload.value}
        </text>
      </g>
    );
  }
}

const CustomTooltip = ({ active, payload, label }) => {
  console.log(payload, 'payload');
  console.log(label, 'label');

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {/* <p className="label">{`${label} : ${payload[0].value}`}</p> */}
        <div>
          {payload.map(pld => (
            <div>
              <div>{pld.dataKey.replace('percent', '')}</div>
              {/* <p className="label">{`${label} : ${payload[0].value}`}</p> */}

              <div style={{ color: pld.fill }}>{pld.value}%</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

const Barchart = ({
  data,
  isXAxis,
  isYAxis,
  legend,
  cartesianGrid,
  tooltip,
  dimensions,
  xAxisTitle,
  yAxisTitle,
  title,
  barGap,
  barSize,
  needCustomize,
  children,
  aspect,
  outletChannel,
}) => {
  const { height, width } = dimensions;

  return (
    <BarchartStyled height={height} width={width}>
      <div className="title-wrap">
        {title && <div className="title"> {title}</div>}
        {outletChannel && (
          <div className={outletChannel.toLowerCase()}>
            <Badge light label={outletChannel} />
          </div>
        )}
      </div>
      <div className="barchart-wrap">
        <text className="barchart-xaxis">{xAxisTitle}</text>
        <text className="barchart-yaxis"> {yAxisTitle}</text>
        <ResponsiveContainer width="100%" aspect={aspect}>
          <BarChart
            width={width}
            height={height}
            data={data}
            barGap={barGap}
            barSize={barSize}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              // bottom: needCustomize ? 20: 5,
            }}
          >
            {needCustomize && (
              <XAxis
                dataKey="outlet"
                axisLine={false}
                tick={needCustomize ? <CustomizedAxisTick /> : needCustomize}
                fontSize={12}
                fontWeight={600}
                height={120}
                interval={0}
              />
            )}

            {isYAxis && (
              <YAxis
                name="stature"
                domain={[0, 100]}
                type="number"
                tickFormatter={tick => {
                  return `${tick}%`;
                }}
                axisLine={false}
                fontSize={12}
                fontWeight={600}
                allowDataOverflow={false}
              />
            )}

            <Tooltip
              cursor={{ fill: 'transparent' }}
              formatter={name => {
                return `${name}%`;
              }}
            />

            {cartesianGrid && <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} opacity={0.3} />}

            {legend && <Legend margin={{ top: 100 }} />}

            {children}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </BarchartStyled>
  );
};

export default Barchart;
