const SOS = 'ShareOfShelf';
const SOA = 'ShareOfAvailability';

const labelMappings = {
  ShareOfShelf: {
    chart: 'chart',
    category: 'category',
    pointsScored: 'pointsScored',
    gap: 'gap',
  },
  ShareOfAvailability: {
    chart: 'chart',
    pointsScored: 'pointsScored',
    gap: 'gap',
  },
};

const labelConfig = {
  ShareOfShelf: [
    {
      id: 1,
      label: labelMappings[SOS].chart,
      title: 'Chart',
      show: true,
      align: 'text-left',
    },

    {
      id: 2,
      label: labelMappings[SOS].category,
      title: 'Category',
      show: true,
      align: 'text-left',
    },
    {
      id: 3,
      label: labelMappings[SOS].pointsScored,
      title: 'Point Scored',
      show: true,
      align: 'text-right',
    },
    {
      id: 4,
      label: labelMappings[SOS].gap,
      title: 'Gap',
      show: true,
      align: 'text-right',
    },
  ],
  ShareOfAvailability: [
    {
      id: 1,
      label: labelMappings[SOA].chart,
      title: 'Chart',
      show: true,
      align: 'text-left',
    },

    {
      id: 3,
      label: labelMappings[SOA].pointsScored,
      title: 'Point Scored',
      show: true,
      align: 'text-right',
    },
    {
      id: 4,
      label: labelMappings[SOA].gap,
      title: 'Gap',
      show: true,
      align: 'text-right',
    },
  ],
};

const getOutletInformtion = (obj, filterParam) => {
  let data = {};
  for (const [key, value] of Object.entries(obj)) {
    if (key === filterParam) {
      data['outletId'] = value[0]?.outlet_id;
      data['buId'] = value[0]?.bu_id;
    }
  }
  return data;
};

const brandColor = {
  BBSM: '#0e69ff',
  BigMart: '#f37e39',
  Salesways: '#43c187',
  KSM: '',
  DailyGrocery: '',
  KKMart: '',
};

const dimension = {
  height: 250,
  width: 700,
};

export { labelMappings, labelConfig, SOS, SOA, getOutletInformtion, brandColor, dimension };
