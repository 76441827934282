import React from 'react';
import PropTypes from 'prop-types';
import NumberCard from '../components/NumberCard';
import Number from '../components/Number';
import SectionStyled from '../components/Section/SectionStyled';
import MonthlyWrap from './MonthlyWrap';
import { fixedFloatAndComma, fixedFloatAndCommas } from '../../../utils/conversion';
import { MODULES } from '../config';

const propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onModuleClick: PropTypes.func.isRequired,
};

const checkMoneyAbbr = (money) => {
  const moneyLength = money && fixedFloatAndComma(money).replace(/,/g, '').toString().length;
  if (moneyLength > 5 && moneyLength < 8) {
    return {
      number: fixedFloatAndCommas(parseFloat(money) / 100000),
      label: 'Lac',
    };
  }
  if (moneyLength > 7 && moneyLength < 12) {
    return {
      number: fixedFloatAndCommas(parseFloat(money) / 10000000),
      label: 'Cr',
    };
  }
  if (moneyLength > 11) {
    return {
      number: fixedFloatAndCommas(parseFloat(money) / 1000000000),
      label: 'Ar',
    };
  }
  if (moneyLength <= 5) {
    return {
      number: fixedFloatAndCommas(parseFloat(money)),
      label: '',
    };
  }
  return {
    number: 0,
    label: '',
  };
};

const MonthlyDetail = ({ data, onModuleClick }) => {
  const { target, achievement, coverage, active } = data;
  const { number, label } = checkMoneyAbbr(target);
  return (
    <MonthlyWrap id='monthly-detail'>
      <SectionStyled className='custom-display'>
        <div onClick={() => onModuleClick(MODULES.TARGET_ACHIEVEMENT)}>
          <NumberCard title='target' subTitle='(npr)'>
            <Number number={number} label={label} size={32} numberPostfix />
          </NumberCard>
        </div>
        <div onClick={() => onModuleClick(MODULES.TARGET_ACHIEVEMENT)}>
          <NumberCard title='achievement' subTitle='(mtd)'>
            <Number number={fixedFloatAndComma(parseInt(achievement, 10))} label='%' size={32} numberPostfix />
          </NumberCard>
        </div>
        <div onClick={() => onModuleClick(MODULES.PRODUCTIVE_COVERAGE)}>
          <NumberCard title='coverage' subTitle='(outlets)'>
            <Number number={coverage} label='' size={32} />
          </NumberCard>
        </div>
        <NumberCard title='active coverage' subTitle='(mtd)'>
          <Number number={fixedFloatAndComma(parseInt(active, 10))} label='%' size={32} numberPostfix />
        </NumberCard>
        {/* <NumberCard title="sbd" subTitle=""> */}
        {/*  <Number */}
        {/*    number={sbd} */}
        {/*    label="%" */}
        {/*    size={32} */}
        {/*    numberPostfix */}
        {/*  /> */}
        {/* </NumberCard> */}
      </SectionStyled>
    </MonthlyWrap>
  );
};

MonthlyDetail.propTypes = propTypes;

export default MonthlyDetail;
