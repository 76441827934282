import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  breadCrumbConfig, filterConfig, title, createButtonTitle,
} from './config';
import View from './View';
import PageHeader from '../../../base/PageHeader';
import { PanelStyled } from '../../../common/configuration';
import * as queryService from '../../../base/query.service';
import withAlert from '../../../../utils/composition/withAlert';
import history from '../../../../utils/history';
import { PURCHASE_ORDER_CREATE, PURCHASE_ORDER_DETAILS } from '../../../../data/enums/Route';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getPurchaseOrdersList: PropTypes.func.isRequired,
};
const defaultProps = {
  serverResponseWaiting: false,
};
class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        date: { ...queryService.baseQueryParameters.date },
        filter: queryService.baseQueryParameters.filter,
        search: queryService.baseQueryParameters.search,
        pagination: queryService.baseQueryParameters.pagination,
      },
    };
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  loadTableData=() => {
    const { queryParameters, data } = this.state;
    const { getPurchaseOrdersList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getPurchaseOrdersList({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        dateRange: queryParameters.date,
        queryString: queryParameters.search,
      },
    }, {

      handleSuccess: (response) => {
        data.list = response.data.getPurchaseOrdersList ? response.data.getPurchaseOrdersList.rows : [];
        data.total = response.data.getPurchaseOrdersList ? response.data.getPurchaseOrdersList.count : 0;
        this.setState(data);
      },
      handleError: (error) => { this.onAPIRequestFailure(error); },
    });
  }

  handleCreateClick=() => {
    history.push(`/${PURCHASE_ORDER_CREATE}`);
  }

  handleRowClick=(id) => {
    history.push(`/${PURCHASE_ORDER_DETAILS}/${id}`);
  }

  render() {
    const { serverResponseWaiting } = this.props;
    const {
      data, queryParameters,
    } = this.state;
    return (
      <>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              breadCrumb={breadCrumbConfig}
              config={{
                title,
                create: true,
                createButtonTitle,
                search: true,
                filter: true,
                date: true,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              handleCreateClick={this.handleCreateClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange
              }
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <View
            data={data}
            loading={serverResponseWaiting}
            pagination={queryParameters.pagination}
            onRowClick={this.handleRowClick}
            onPageChange={this.basePaginationService.onPageSelect}
          />
        </div>
        {'POQ'}
      </>
    );
  }
}

PurchaseOrder.propTypes = propTypes;

PurchaseOrder.defaultProps = defaultProps;

export default withAlert()(PurchaseOrder);
