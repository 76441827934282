import styled from 'styled-components';

export const FundamentalByOutletStyled = styled.div`
  border: 1px solid #e7e7ed;
  margin: 20px 0;
  max-width: 100vw;
  border-radius: 4px;
  padding-bottom: 25px;

  .legend-wrap {
    padding-top: 0px;
  }

  .fundamental-by-outlet-wrap {
    .recharts-tooltip-item-separator {
      display: none;
    }

    .recharts-tooltip-item-name {
      display: none;
    }
  }
`;
