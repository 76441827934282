import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import BulkDelete from '../../../../../../components/BulkDelete';
import withLoading from '../../../../../../../utils/composition/withLoading';
import { Pagination } from '../../../../../../../components';
import { dataProps, paginationProps } from '../../../../../../../data/enums/Component';
import { PERMISSION_OBJ } from '../../../../../../../data/enums/Permission';
import { TableBody, TableHeader } from './tableConfig';
import { crudBulkDeleteRequestConfig } from './config';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onBulkDelete: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  lastLocation: PropTypes.instanceOf(Object),
};


const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  permission: PERMISSION_OBJ,
  lastLocation: {},
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    onIconClick,
    pagination,
    permission,
    onBulkDelete,
    lastLocation,
    onTableRowClick,
    bulkDeleteSBDTemplate,
  } = props;
  const newDataList = data.list.filter((a) => a.canbeDeleted === true);
  return (

    <Fragment>
      <BulkDelete
        data={newDataList}
        useExternalAPI
        handler={onBulkDelete}
        deleteServerCall={bulkDeleteSBDTemplate}
        crudExternalRequestConfig={crudBulkDeleteRequestConfig}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className='table-wrap'>
            <table>
              {
                TableHeader({
                  dataListLength: newDataList.length,
                  checkedListLength: state.checkedList.length,
                  primaryCheckboxHandler,
                  permission,
                  lastLocation,
                })
              }
              <tbody>
                {data?.list?.map((vgp) => (
                  TableBody({
                    data: vgp,
                    checkedList: state.checkedList,
                    onIconClick,
                    secondaryCheckboxHandler,
                    permission,
                    onTableRowClick,
                  })
                ))}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>

  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
