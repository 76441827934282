import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SBDDetailStyled from '../../../../../../sbd/details/SBDDetailStyled';
import SBDDistribution from './SBDDistribution';
import BasicDetails from './BasicDetails';

const propTypes = {
  handleSwitchChange: PropTypes.func.isRequired,
  onProductCategoryClick: PropTypes.func.isRequired,
  productCategoryList: PropTypes.func.isRequired,
  onHandleSearchInput: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  brandList: PropTypes.arrayOf(PropTypes.object),
  onBrandClick: PropTypes.func.isRequired,
  SKUFamilyList: PropTypes.arrayOf(PropTypes.object),
  cardId: PropTypes.num,
  searchText: PropTypes.string.isRequired,
  update: PropTypes.shape({
    status: PropTypes.bool,
    type: PropTypes.string,
  }),
  sbdData: PropTypes.shape({
    basicData: PropTypes.object,
    sbdSku: PropTypes.array,
  }),
  menu: PropTypes.shape({
    channel: PropTypes.array,
    category: PropTypes.array,
    outlet: PropTypes.array,
  }),
  count: PropTypes.shape({
    brandCount: PropTypes.array,
    categoryCount: PropTypes.array,
  }),
  enableErrorDisplay: PropTypes.bool,
  serverResponseWaiting: PropTypes.bool.isRequired,
  refsObj: PropTypes.object,
};

const defaultProps = {
  brandList: [],
  SKUFamilyList: [],
  enableErrorDisplay: false,
  update: {
    status: false,
    type: 'CREATE',
  },
};
const View = (props) => {
  const {
    cardId, sbdData, menu, onInputChange, onDropDownChange, serverResponseWaiting, update, onProductCategoryClick, count, productCategoryList, brandList, onBrandClick, SKUFamilyList, onHandleSearchInput, searchText,
    handleSwitchChange, refsObj,
    enableErrorDisplay,
  } = props;
  return (
    <SBDDetailStyled>
      <BasicDetails
        sbdData={sbdData}
        menu={menu}
        onInputChange={onInputChange}
        onDropDownChange={onDropDownChange}
        refsObj={refsObj}
        enableErrorDisplay={enableErrorDisplay}
        update={update}
      />
      <SBDDistribution
        cardId={cardId}
        sbdData={sbdData}
        count={count}
        productCategoryList={productCategoryList}
        onProductCategoryClick={onProductCategoryClick}
        brandList={brandList}
        onBrandClick={onBrandClick}
        SKUFamilyList={SKUFamilyList}
        onHandleSearchInput={onHandleSearchInput}
        searchText={searchText}
        handleSwitchChange={handleSwitchChange}
        serverResponseWaiting={serverResponseWaiting}
        update={update}
      />
    </SBDDetailStyled>
  );
};


View.propTypes = propTypes;

View.defaultProps = defaultProps;

export default View;
