import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import {
  breadCrumb, formConfig, newList, PURCHASE_ORDER_VIEW,
} from '../common/config';
import { title } from '../config';
import * as queryService from '../../../../base/query.service';
import withAlert from '../../../../../utils/composition/withAlert';
import PurchaseOrderStyled from '../common/PuchaseOrderStyle';
import { PanelHeader, PanelStyled } from '../../../../common/configuration';
import { BreadCrumb, Button, Icon } from '../../../../../components';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../../data/services';
import { DialogFormWrapper } from '../../../../common';
import CSVUploadForm from '../../../../common/CSVUploadForm';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { getTotalSum, groupBy } from '../../../../common/HelperFunctions';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import history from '../../../../../utils/history';
import { PURCHASE_ORDER } from '../../../../../data/enums/Route';
import { getCSVData } from '../../../../../utils/parser';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  createPurchaseOrder: PropTypes.func.isRequired,
};
const defaultProps = {
  serverResponseWaiting: false,
};

class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channelId: null,
      data: {
        list: [],
      },
      menu: {
        channel: [],
      },
      dialog: {
        type: '',
      },
      queryParameters: {
        date: { ...queryService.baseQueryParameters.date },
        type: PURCHASE_ORDER_VIEW.BRAND,
      },
      order: [],
    };
    const { displayAlert } = props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      displayAlert,
    );
  }

  componentDidMount() {
    this.loadDataForDropDown();
  }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  loadTableData=() => {
  }

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.OUTLET_CHAINS).then((response) => {
      menu.channel = response;
      this.setState({ menu });
    });
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleDropDownChange=(value) => {
    this.setState({
      channelId: value,
    });
  };

  handleUploadClick = (type = '') => {
    this.setState({
      dialog: {
        type,
      },
    });
  };

  handleFileUpload = (event) => {
    event.preventDefault();
    const e = event.target;
    this.processFile(e);
  };

  locationName = (obj) => {
    const { queryParameters } = this.state;
    if (queryParameters.type === PURCHASE_ORDER_VIEW.BRAND) {
      return obj.Brand;
    }
  };

  processFile = async (event) => {
    const { displayAlert } = this.props;
    const csvFormattedData = await getCSVData(event);

    const groupedArray = groupBy(csvFormattedData.list, this.locationName);
    const keys = ['group', 'skuList'];
    const order = groupedArray.map(r => (keys.reduce((o, k, i) => (o[k] = r[i], o), {})));
    if (csvFormattedData.message) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, csvFormattedData.message);
    }
    const filteredOrder = order.filter(list => list.group !== 'undefined');
    this.setState({ order: filteredOrder });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
      },
    });
  };

  handleFormSubmit=() => {
    const { order, data } = this.state;
    data.list = order.filter(list => list.group !== 'undefined');
    this.setState({ data, channelId: Number(order[0].skuList[0]['Channel ID']) });
    this.resetDialog();
  }

  handleButtonCancel=() => {
    this.setState({
      data: {
        list: [],
      },
      channelId: null,
      order: [],
    });
  };

  getInputPayload=() => {
    const { data } = this.state;
    const payloadData = data.list.map(sku => sku.skuList.map(list => list)).flat();

    const orders = payloadData.map(a => ({
      skuId: Number(a['SKU ID']),
      buId: Number(a['BU ID']),
      brandId: Number(a['Brand ID']),
      cases: Number(a.Cases),
      pieces: Number(a.Pieces),
      rateDetails: {
        rlp: Number(a.Rate),
        netPrice: Number(a.Rate),
        rlpWithVat: Number(a['Rate With VAT']),
        netPriceWithVat: Number(a['Rate With VAT']),
      },
      amountDetails: {
        grossAmount: Number(a['Gross Amount']),
      },
    }));

    const amountTotal = payloadData.map(a => ({
      subTotal: (Number(a.Rate) * ((Number(a.Cases) * Number(a['Units Per Case'])) + Number(a.Pieces))),
      taxAmount: ((Number(a.Rate) * ((Number(a.Cases) * Number(a['Units Per Case'])) + Number(a.Pieces))) * 0.13),
      grossAmount: (((Number(a.Cases) * Number(a['Units Per Case']) + Number(a.Pieces)) * Number(a.Rate)) * 1.13),
    }));
    const amountDetails = {
      netAmount: getTotalSum(amountTotal, 'grossAmount'),
      grossAmount: getTotalSum(amountTotal, 'grossAmount'),
      subTotal: getTotalSum(amountTotal, 'subTotal'),
      taxableAmount: getTotalSum(amountTotal, 'subTotal'),
      taxAmount: getTotalSum(amountTotal, 'taxAmount'),
      billDiscount: 0,
      topUpDiscount: 0,
      tradeDiscount: 0,
      discountAmount: 0,
      promotionDiscount: 0,
    };

    return ({
      prId: Number(payloadData[0]['Purchase Requisition ID']),
      buId: Number(payloadData[0]['Brand ID']),
      channelId: Number(payloadData[0]['Channel ID']),
      outletId: Number(payloadData[0]['Outlet ID']),
      distributorId: Number(payloadData[0]['Distributor ID']),
      tradeId: Number(payloadData[0]['Trade ID']),
      orders,
      amountDetails,
    });
  }

  handleButtonSubmit=() => {
    const { createPurchaseOrder, displayAlert } = this.props;
    const input = this.getInputPayload();
    createPurchaseOrder({
      input,
    }, {
      handleSuccess: () => {
        displayAlert(ALERT_TYPE.SUCCESS, `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`);
        setTimeout(() => {
          history.push(`/${PURCHASE_ORDER}`);
        }, 900);
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },
    });
  }

  render() {
    const {
      data, menu, channelId, queryParameters, dialog: { type },
    } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <PurchaseOrderStyled>
        {type && (
        <DialogFormWrapper
          onDialogSubmit={this.handleFormSubmit}
          formConfig={formConfig[type]}
          onDialogCancel={this.resetDialog}
          type={type}
          renderDialog={
            () => (
              <>
                <CSVUploadForm
                  show
                  loading={serverResponseWaiting}
                  handleFileUpload={this.handleFileUpload}
                />
                <a href="https://rosia-sample-files.s3.ap-south-1.amazonaws.com/SAMPLE_PURCHASE_REQUISITION.csv">
                  <span className="sample-csv">
                    <Icon iconName="download" />

Sample CSV
                  </span>
                </a>
              </>
            )
          }
        />
        )}
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumb} />
            <PanelHeader>
              <h2>{`Create ${title}`}</h2>
              <div className="flex m-0">
                <div className="button-wrap">
                  <Button
                    secondary
                    small
                    disabled={serverResponseWaiting}
                    title="Cancel"
                    onClick={() => this.handleButtonCancel()}
                  />
                  <Button
                    primary
                    small
                    disabled={serverResponseWaiting}
                    title="Order"
                    onClick={() => this.handleButtonSubmit()}
                  />
                </div>
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className="section-content pad-48">
          <View
            data={data}
            menu={menu}
            channelId={channelId}
            loading={serverResponseWaiting}
            viewType={queryParameters.type}
            viewTypeConfig={{
              value: queryParameters.type,
              list: newList,
            }}
            onDropDownChange={this.handleDropDownChange}
            onUploadClick={this.handleUploadClick}
            handleViewTypeChange={this.basePaginationService.handleViewTypeChange}
          />
        </div>
      </PurchaseOrderStyled>
    );
  }
}

Create.propTypes = propTypes;

Create.defaultProps = defaultProps;

export default withAlert()(Create);
