import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import SubCampaignForm from './Form';
import history from '../../../../../utils/history';
import { refGenerator } from '../../../../../utils';
import { DialogFormWrapper } from '../../../../common';
import SubCampaignStyled from './SubCampaignStyled';
import { CAMPAIGNS } from '../../../../../data/enums/Route';
import withAlert from '../../../../../utils/composition/withAlert';
import Confirmation from '../../../../common/DialogConfirmation';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { getPermissionForTown } from '../../../../base/permission';
import { findAndReplaceItem } from '../../../../../utils/arrayProcessor';
import { isConfirmationType } from '../../../../common/HelperFunctions';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { handleFormSubmit } from '../../../../../utils/crudResponseProcessor';
import { dropdownChange, debouncer } from '../../../../../utils/formHandlers';
import { errorMessageExceed, errorMessageInChange, formConfig, crudRequest } from './config';

const propTypes = {
  update: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  serverResponseWaiting: PropTypes.bool,
  getStatus: PropTypes.func,
  getDetails: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  update: {
    type: '',
    status: true,
  },
  enableErrorDisplay: false,
  serverResponseWaiting: false,
  getStatus: () => null,
  getDetails: () => null,
};

class SubCampaign extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { data: nextProps.data };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.permission = getPermissionForTown();
    this.state = {
      data: {
        SubCampaign: [],
      },
      subCampaignArr: [],
      delInfo: {
        id: 0,
      },
      dialog: {
        type: '',
        element: '',
      },
      budgetOverflowFlag: false,
    };
    const serverCall = {
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.onCRUDSuccess = this.responseProcessor();
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequest, serverCall);
    this.formReference = refGenerator(['value']);
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  responseProcessor = () => {
    const onAPIRequestSuccess = type => response => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequest[type].message, this.directToMainPage, response);
    };
    return onAPIRequestSuccess;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getValidationStatus = () => {
    const { data } = this.state;
    const { displayAlert } = this.props;
    const totalBudget = data.budget || 0;
    const budgetCalcAll = data.SubCampaign.map(a => a.subCampaignBudget)
      .flat()
      .map(a => {
        if (a.dimension === 'PERCENT') {
          return (a.value / 100) * totalBudget;
        }
        return a.value;
      });
    if (data.SubCampaign.length > 0) {
      const newData = data.SubCampaign[0];
      const budgetCalcCheck = this.overallBudgetValid(budgetCalcAll, 'check');
      const budgetCheck = this.checkForValidation(newData, 'subCampaignBudget', 'check');
      if (data.SubCampaign.length > 0) {
        if ([budgetCheck].includes(true)) {
          return displayAlert(ALERT_TYPE.CUSTOM_DANGER, errorMessageInChange);
        }
        if ([budgetCalcCheck].includes(true)) {
          return displayAlert(ALERT_TYPE.CUSTOM_DANGER, errorMessageExceed);
        }
      }
    }
    return true;
  };

  exportData = () => {
    const { data, subCampaignArr, delInfo } = this.state;
    return { ...data, temp: subCampaignArr, delInfo };
  };

  onHandleInputChange = (data, event, firstParam = '', paramList = []) => {
    const { data: mainData } = this.state;
    const budgetValueTypeStatus = data[paramList[0]][paramList[1]].dimension === 'PERCENT';
    const totalBudget = mainData.budget || 0;
    if (budgetValueTypeStatus) {
      const formattedValueModified = event.formattedValue <= 100 ? event.formattedValue : '';
      data[paramList[0]][paramList[1]].value = formattedValueModified;
      data[paramList[0]][paramList[1]].calculatedValue = (formattedValueModified / 100) * totalBudget;
    } else {
      data[paramList[0]][paramList[1]].value = Number(event.formattedValue);
    }
    this.setState({ data }, () => {
      if (firstParam === 'subCampaignBudget') {
        debouncer(this.checkForValidation, 500)(data, firstParam);
      }
    });
  };

  overallBudgetValid = (newdata, checklabel) => {
    const { data } = this.props;
    const totalBudget = data.budget || 0;
    const sum = newdata.reduce((acc, cur) => acc + cur, 0);
    if (checklabel === 'check') {
      if (sum > totalBudget) {
        return true;
      }
      return false;
    }
  };

  checkForValidation = (newdata, label, checklabel) => {
    const { data } = this.props;
    const totalBudget = data.budget || 0;
    const sum = newdata[label]
      .map(a => {
        if (a.dimension === 'PERCENT') {
          return (a.value / 100) * totalBudget;
        }
        return a.value;
      })
      .reduce((acc, cur) => acc + cur, 0);
    if (checklabel === 'check') {
      if (sum > totalBudget) {
        return true;
      }
      return false;
    }
    if (sum > totalBudget) {
      this.setState({ budgetOverflowFlag: true });
    } else {
      this.setState({ budgetOverflowFlag: false });
    }
  };

  handleIconClick = (type, element = {}) => {
    const { data } = this.state;
    if (type === EVENT_OPERATION.CREATE) {
      element = {
        ...element,
        campaignStart: data.startDate,
        campaignEnd: data.endDate,
      };
    }
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  handleCancel = (name, label, data) => {
    const { subCampaignBudget, subCampaignTarget } = data;
    if (label === 'budgetType') {
      const filterData = [...subCampaignBudget].filter(d => d.budgetType !== name);
      const newData = {
        ...data,
        ...{
          subCampaignBudget: filterData,
        },
      };
      data.subCampaignBudget = newData.subCampaignBudget;
      this.setState({ data });
    } else if (label === 'targetType') {
      const filterData = [...subCampaignTarget].filter(d => d.targetType !== name);
      const newData = {
        ...data,
        ...{
          subCampaignTarget: filterData,
        },
      };
      data.subCampaignTarget = newData.subCampaignTarget;
      this.setState({ data });
    }
  };

  handleAdd = (title, data) => {
    if (title === 'Budget') {
      const newData = {
        ...data,
        subCampaignBudget: [...data.subCampaignBudget, { budgetType: null, dimension: 'AMOUNT', value: null }],
      };
      data.subCampaignBudget = newData.subCampaignBudget;
    } else if (title === 'Target') {
      const newData = {
        ...data,
        subCampaignTarget: [...data.subCampaignTarget, { targetType: null, dimension: 'VALUE', value: null }],
      };
      data.subCampaignTarget = newData.subCampaignTarget;
    }
    this.setState({
      data,
    });
  };

  handleDimensionChange = (data, value, parameterRef = []) => {
    const updatedDetails = dropdownChange(data, parameterRef, value);
    data[parameterRef[0]] = updatedDetails[parameterRef[0]];
    data[parameterRef[0]][parameterRef[1]].value = null;
    if (parameterRef[0] === 'subCampaignBudget') {
      data[parameterRef[0]][parameterRef[1]].calculatedValue = null;
    }
    this.setState({ data: updatedDetails });
  };

  handleFormSubmit = (type, dialogData, error) => {
    if (type === EVENT_OPERATION.DELETE) {
      this.handleRowDelete(dialogData);
    } else {
      this.handleDataUpdate(dialogData);
    }
  };

  handleDataUpdate = dialogData => {
    const { dialog, data, subCampaignArr } = this.state;
    let temp = [...subCampaignArr];
    const modifiedDialogData = dialogData;
    const index = temp.findIndex(t => t.id === modifiedDialogData.id);
    if (modifiedDialogData.id) {
      temp = findAndReplaceItem(temp, modifiedDialogData);
      data.SubCampaign = findAndReplaceItem(data.SubCampaign, modifiedDialogData);
    } else {
      modifiedDialogData.id = 10000 + data.SubCampaign.length + 1;
      data.SubCampaign.push(modifiedDialogData);
      temp.push(modifiedDialogData);
    }
    if (modifiedDialogData.id && modifiedDialogData.id < 10000 && index === -1) {
      temp.push(modifiedDialogData);
    }
    this.setState({ data: data.SubCampaign, subCampaignArr: [...temp] });
  };

  handleRowDelete = dialogData => {
    const { data, dialog, subCampaignArr, delInfo } = this.state;
    const { campaignId } = this.props;
    const temp = [...subCampaignArr];
    if (campaignId && dialog.element.id < 10000) {
      this.onFormSubmit(dialog.type, dialog.element);
      delInfo.id = dialogData.id;
      this.setState({ delInfo });
    }
    const index = data.SubCampaign.findIndex(item => item.id === dialogData.id);
    const indexTemp = temp.findIndex(item => item.id === dialogData.id);
    data.SubCampaign.splice(index, 1);
    temp.splice(indexTemp, 1);
    this.setState({ data, subCampaignArr: [...temp] });
  };

  /** todo configure payload and response as per image upload api * */
  onTableRowClick = (id, update) => {
    if (!update.status) {
      history.push(`/${CAMPAIGNS}/sub-campaign/${id}`);
    }
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  render() {
    const { data, dialog, budgetOverflowFlag } = this.state;
    const { type } = dialog;
    const { serverResponseWaiting, subCampaignTypeList, dimensionList, update } = this.props;
    const campaignsLines = data.SubCampaign || [];
    return (
      <SubCampaignStyled>
        {type && (
          <DialogFormWrapper
            formConfig={formConfig[type]}
            dialogElement={dialog.element}
            onDialogSubmit={this.handleFormSubmit}
            onDialogCancel={this.resetDialog}
            withOutPadding
            type={type}
            renderDialog={({ refsObj, dialogData, enableErrorDisplay, handleDropDownChange, handleInputChange }) => (
              <Fragment>
                {(type === EVENT_OPERATION.UPDATE || type === EVENT_OPERATION.CREATE) && (
                  <SubCampaignForm
                    show
                    update={update}
                    refsObj={refsObj}
                    data={dialogData}
                    campaignDataObj={data}
                    loading={serverResponseWaiting}
                    handleInputChange={handleInputChange}
                    onHandleInputChange={this.onHandleInputChange}
                    handleDropDownChange={handleDropDownChange}
                    handleDimensionChange={this.handleDimensionChange}
                    type={type}
                    subCampaignTypeList={subCampaignTypeList}
                    dimensionList={dimensionList}
                    handleCancel={this.handleCancel}
                    handleAdd={this.handleAdd}
                    handleTargetCancel={this.handleTargetCancel}
                    budgetOverflowFlag={budgetOverflowFlag}
                    enableErrorDisplay={enableErrorDisplay}
                  />
                )}
                {isConfirmationType(type) && Confirmation(type, 1)}
              </Fragment>
            )}
          />
        )}
        <View
          data={campaignsLines}
          update={update}
          onIconClick={this.handleIconClick}
          permission={this.permission}
          onBulkDelete={this.handleBulkDelete}
          onTableRowClick={this.onTableRowClick}
          campaignDataObj={data}
        />
      </SubCampaignStyled>
    );
  }
}

SubCampaign.defaultProps = defaultProps;

SubCampaign.propTypes = propTypes;

export default withAlert()(SubCampaign);
