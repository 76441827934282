import React from 'react';
import PropTypes from 'prop-types';
import SOS from './SOS';
import SOD from './SOD';
import SOSKU from './SOSKU';
import {
  Button,
  Col, CustomSelect, Icon, Input, Row, SingleDatePicker, Tab, TabList, TabPanel, Tabs,
} from '../../../../../../../../components';
import withLoading from '../../../../../../../../utils/composition/withLoading';
import { FUNDAMETALS } from './config';
import { EMPTY } from '../../../../../../../../data/enums/ErrorMessage';
import { EVENT_OPERATION } from '../../../../../../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  menu: PropTypes.instanceOf(Object),
  getDetails: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
};

const defaultProps = {
  data: [],
  menu: {},
};
const View = (props) => {
  const {
    data, menu, skuIds, activeTab, onTabChange, onInputChange, onDropDownChange, readMode, productCategoryList, onBrandClick,
    enableErrorDisplay, refsObj, totalPointValidation, onSBDSelect, onSBDCreateClick, cardId, count, onProductCategoryClick, brandList, onHandleSearchInput,
    searchText, SKUFamilyList, handleSwitchChange
  } = props;
  const channelId = data.basicData.outlet_chain_id;
  const filteredCategoryList = channelId
    ? menu.category.filter((el) => el.Channel.id === channelId) : menu.category;
  const totalSKU = data.sosku && data.sosku.map((a) => a.childUsers) && data.sosku.map((a) => a.childUsers).flat().length;
  return (
    <>
      <div className={`create-top-section ${readMode ? 'disabled' : ''}`}>
        <Row>
          <Col md={3} className='pr-48'>
            <Input
              value={data.basicData.title}
              name='title'
              type='text'
              labelContent='JBP Template Name'
              placeholder='JBP Template Name'
              onChange={(event) => onInputChange(event, 'basicData', ['basicData', 'title'])}
              enableValidation
              enableErrorDisplay={enableErrorDisplay}
              ref={(ref) => (refsObj.title = ref)}
            />
          </Col>
          <Col md={3} className='pr-48'>
            <CustomSelect
              name='channel'
              onChange={(event) => {
                onDropDownChange(event.id, 'outlet_chain_id');
              }}
              options={menu.channel}
              labelContent='Channel'
              placeholder='Select Channel'
              getOptionValue={({ id }) => id}
              getOptionLabel={({ title }) => title}
              value={menu.channel.filter(
                ({ id }) => id === data.basicData.outlet_chain_id,
              )}
              enableValidation
              enableErrorDisplay={enableErrorDisplay}
              ref={(ref) => (refsObj.outlet_chain_id = ref)}
            />
          </Col>
          <Col md={3}>
            <Input
              value={data.basicData.point}
              name='point'
              type='number'
              labelContent='Total Points'
              placeholder='Total Points'
              rule='isFloat'
              argument={{
                min: 1,
              }}
              onChange={(event) => onInputChange(event, 'basicData', ['basicData', 'point'])}
              enableValidation
              enableErrorDisplay={enableErrorDisplay}
              ref={(ref) => (refsObj.point = ref)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Row>
              <Col md={6} className='pr-48'>
                <div className='form-input custom-date'>
                  <label>Start Date</label>
                  <SingleDatePicker
                    date={data.basicData.start_date}
                    name='start_date'
                    onChange={(name, date) => onInputChange({
                      target: {
                        name: 'start_date',
                      },
                      formattedValue: date,
                    }, 'basicData')}
                  />
                </div>
              </Col>
              <Col md={6} className='pr-48'>
                <CustomSelect
                  name='category'
                  onChange={(event) => {
                    const ids = event.map((d) => d.id);
                    onDropDownChange(ids, 'category_id');
                  }}
                  isMulti
                  options={filteredCategoryList}
                  labelContent='Category'
                  placeholder='Select Category'
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ title }) => title}
                  value={menu.category
                    .filter(({ id }) => (data.basicData.category_id ? data.basicData.category_id.includes(id) : false))}
                  enableValidation
                  enableErrorDisplay={enableErrorDisplay}
                  ref={(ref) => (refsObj.category_id = ref)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} className='pr-48'>
                <div className='form-input custom-date'>
                  <label>End Date</label>
                  <SingleDatePicker
                    date={data.basicData.end_date}
                    name='end_date'
                    onChange={(name, date) => onInputChange({
                      target: {
                        name: 'end_date',
                      },
                      formattedValue: date,
                    }, 'basicData')}
                    minDate={data.basicData.start_date}
                  />
                </div>
              </Col>
              <Col md={6} className='pr-48'>
                <CustomSelect
                  name='outlet'
                  onChange={(event) => {
                    onDropDownChange(event?.id, 'outlet_id');
                  }}
                  isClearable
                  options={menu.outlet}
                  labelContent='Outlet'
                  placeholder='Select Outlet'
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ title }) => title}
                  value={menu.outlet.filter(
                    ({ id }) => id === data.basicData.outlet_id,
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <label>Fundamental Weightage</label>
            <Col md={6} className='fundamental-weightage'>
              {data.basicData.fundamentalWeightage.map((a, index) => (
                <div className='sos'>
                  <label>{a.abbreviation}</label>
                  <Input
                    type='number'
                    name='fundamentalWeightage'
                    placeholder='0'
                    value={a.weightage}
                    onChange={(event) => onInputChange(event, 'fundamentalWeightage', ['basicData', 'fundamentalWeightage', `${index}`, 'weightage'])}
                    argument={{
                      min: 1,
                    }}
                    rule='isFloat'
                    decimalWithPrecedingZero
                    enableValidation
                    externalValidation={!totalPointValidation}
                    errorMessage={enableErrorDisplay ? totalPointValidation ? `Sum of weightage should be ${data.basicData.point}` : EMPTY : `Sum of weightage should be ${data.basicData.point}`}
                    enableErrorDisplay={enableErrorDisplay || totalPointValidation}
                    ref={(ref) => (refsObj[`${a.abbreviation}`] = ref)}
                  />
                </div>
              ))}
            </Col>
          </Col>
        </Row>

      </div>
      <div className='create-bottom-section'>
        <Tabs
          selectedIndex={activeTab}
          onSelect={(tabIndex) => onTabChange(tabIndex)}
        >
          <TabList>
            {data.basicData.fundamentalWeightage.map((fundamental) => (
              <Tab key={fundamental.id} disabled={false}>
                {fundamental.abbreviation}
              </Tab>
            ))}
          </TabList>
          <div className='tab-container'>
            {data.basicData.fundamentalWeightage
            && data.basicData.fundamentalWeightage.map((fundamental) => (
              <TabPanel key={fundamental.id}>
                <section id='pending-tab'>
                  <div id='detail-tab'>
                    <div className='create-ui-inner tab-less-height' style={{ padding: '0' }}>
                      {fundamental.abbreviation === FUNDAMETALS.SOS && (
                        <SOS
                          sos={data.sos}
                          readMode={readMode}
                          category={fundamental.category}
                          handleInputChange={onInputChange}
                        />
                      )}
                      {fundamental.abbreviation === FUNDAMETALS.SOD && (
                        <SOD
                          sod={data.sod}
                          readMode={readMode}
                          category={fundamental.category}
                          handleInputChange={onInputChange}
                        />
                      )}
                      {fundamental.abbreviation === FUNDAMETALS.SOSKU && (
                        <>
                          <div className='skuFamily'>SKU Family</div>
                          <SOSKU
                            sosku={data.sosku}
                            cardId={cardId}
                            count={count}
                            skuIds={skuIds}
                            readMode={readMode}
                            brandList={brandList}
                            handleInputChange={onInputChange}
                            productCategoryList={productCategoryList}
                            onBrandClick={onBrandClick}
                            onProductCategoryClick={onProductCategoryClick}
                            onHandleSearchInput={onHandleSearchInput}
                            searchText={searchText}
                            SKUFamilyList={SKUFamilyList}
                            handleSwitchChange={handleSwitchChange}
                          />
                          <Row className={`sbd-template-footer ${readMode ? 'disabled' : ''}`}>
                            <Col md={3}>
                              <CustomSelect
                                name='sbd'
                                onChange={(event) => {
                                  onSBDSelect(event.id, 'jbpTemplateId');
                                }}
                                options={menu.sbd}
                                labelContent='SBD Template'
                                placeholder='Select SBD Template'
                                getOptionValue={({ id }) => id}
                                getOptionLabel={({ title }) => title}
                                value={menu.sbd.filter(
                                  ({ id }) => id === data.jbpTemplateId,
                                )}
                              />
                            </Col>
                            <Col md={1}>
                              <Button
                                primary
                                title='Create'
                                onClick={() => onSBDCreateClick(EVENT_OPERATION.CREATE)}
                              />
                            </Col>
                            <Col>
                              <div className='sosku-info'>
                                <Icon iconName='info-circle-open' />
                                <span>
                                  SKU’s selected above in SOSKU will be reflected while creating new SBD template
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              </TabPanel>
            ))}

          </div>
        </Tabs>
      </div>
    </>
  );
};


View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewErrorAndLoadingScreen = withLoading(View);

export default ViewErrorAndLoadingScreen;
