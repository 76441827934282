import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { detailsLabelConfig, DetailTableBody } from './tableConfig';
import { tableData } from '../../../../../utils/paginatedListUtils';
import { Button } from '../../../../../v4/components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { OrderFulFilmentContext } from '../../config';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  loading: PropTypes.bool.isRequired,
  onOrderStatusChangeClick: PropTypes.func.isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
  replacedId: PropTypes.number.isRequired,
  logisticsDelivery: PropTypes.bool.isRequired,
};

const defaultProps = {
  data: [],
};

const childView = ({ data, loading, replacedId, handleSliderOpen, onOrderStatusChangeClick, logisticsDelivery }) => (
  <Fragment>
    <tr>
      {detailsLabelConfig.map((label) =>
        !label.action ? tableData(label.title, label.align, label.classNames) : <th />,
      )}
    </tr>
    <OrderFulFilmentContext.Consumer>
      {({ permission }) => (
        <Fragment>
          <Fragment>
            {data.map((order, index) => (
              <tr>
                {DetailTableBody({
                  index,
                  data: order,
                  handleSliderOpen,
                })}
              </tr>
            ))}
          </Fragment>

          {permission.update && (
            <tr>
              <td colSpan='75%'>
                <div className='text-right'>
                  {!logisticsDelivery && (
                    <Button
                      small
                      primary
                      disabled={loading}
                      onClick={() => onOrderStatusChangeClick(EVENT_OPERATION.DISPATCHED, replacedId)}
                    >
                      <span>Dispatch</span>
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          )}
        </Fragment>
      )}
    </OrderFulFilmentContext.Consumer>
  </Fragment>
);

childView.propTypes = propTypes;

childView.defaultProps = defaultProps;

export default childView;
