import React from 'react';
import BrandChain from '../../sections/BrandChain/BrandChain';
import FundamentalByMrOutlet from '../../sections/fundamentalByOutlets/FundamentalByMrOutlet';
import FundamentalByOutlet from '../../sections/fundamentalByOutlets/FundamentalByOutlet';
import OutletWiseScore from '../../table/OutletWiseScore/OutletWiseScore';

const Salesways = ({ bu }) => {
  return (
    <>
      <FundamentalByOutlet bu={bu} outletChannel="Salesways" />
      <FundamentalByMrOutlet bu={bu} outletChannel="Salesways" />
      <OutletWiseScore title="Product Category R-Score and Gap Report" bu={bu} outletChannel="Salesways" />
      <BrandChain bu={bu} outletChannel="Salesways" />
    </>
  );
};

export default Salesways;
