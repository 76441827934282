
import { compose } from 'recompose';
import Details from './Details';
import {
  query, mutation, CREATE_QUESTIONNAIRE, EDIT_QUESTIONNAIRE,
} from './API';
import withRequestTracker from '../../../../../../../../utils/composition/withRequestTracker';

const ComposedDetails = compose(
  CREATE_QUESTIONNAIRE,
  EDIT_QUESTIONNAIRE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Details);

export default ComposedDetails;
