import { FORM_CONFIG } from '../../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { PURCHASE_ORDER } from '../../../../../data/enums/Route';
import { breadCrumbConfig, title } from '../config';

const breadCrumb = [
  ...breadCrumbConfig,

  {
    id: 2,
    title,
    path: `/${PURCHASE_ORDER}`,
    active: true,
  },
];

const PURCHASE_ORDER_VIEW = {
  BRAND: 'BRAND',
  // CATALOG: 'CATALOG',
};

const newList = [
  { id: 1, label: 'BRAND' },
  // { id: 2, label: 'CATALOG' },
];

const formConfig = {
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.TITLE]: `Upload ${title}`,
  },
};


export {
  breadCrumb, newList, PURCHASE_ORDER_VIEW, formConfig,
};
