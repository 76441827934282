import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../../components';
import withLoading from '../../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    emails: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.object,
};

const defaultProps = {
  handleInputChange: () => null,
  enableErrorDisplay: true,
  data: {
    id: null,
    email: '',
  },
  refsObj: {},
};

const PurchaseForm = ({ ...props }) => {
  const {
    data, enableErrorDisplay, handleInputChange, refsObj,
  } = props;
  return (
    <Form>
      <Input
        ref={ref => refsObj.emails = ref}
        value={data.emails}
        multiple
        name="emails"
        type="text"
        placeholder="Emails"
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event)}
        labelContent="Emails"
      />
    </Form>
  );
};

PurchaseForm.propTypes = propTypes;
PurchaseForm.defaultProps = defaultProps;

const ViewWithErrorAndLoading = withLoading(PurchaseForm);

export default ViewWithErrorAndLoading;
