import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form, CustomSelect } from '../../../../../components';
import withLoading from '../../../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    countDays: PropTypes.string,
    assignedTo: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  frequency: PropTypes.arrayOf([]).isRequired,
  assignedTo: PropTypes.arrayOf([]).isRequired,
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  handleDropDownChange: () => null,
};

const MTForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    handleInputChange,
    handleDropDownChange,
    enableErrorDisplay,
    frequency,
    assignedTo,
  } = props;

  return (
    <Form>
      <Input
        ref={ref => refsObj.title = ref}
        value={data.title}
        name="title"
        type="text"
        placeholder="Title"
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event)}
        labelContent="Title"
      />
      <CustomSelect
        labelContent="Count Frequency"
        getOptionValue={({ id }) => id}
        getOptionLabel={({ label }) => label}
        options={frequency}
        name="count_days"
        value={frequency && frequency.filter(({ id }) => id === data.count_days)}
        onChange={event => handleDropDownChange(event.id, ['count_days'])
                      }
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        ref={ref => refsObj.count_days = ref}
      />
      <CustomSelect
        labelContent="Assigned To"
        getOptionValue={({ id }) => id}
        getOptionLabel={({ fullName }) => fullName}
        options={assignedTo}
        name="assigned_to"
        value={assignedTo && assignedTo.filter(({ id }) => id === data.assigned_to)}
        onChange={event => handleDropDownChange(event.id, ['assigned_to'])
                      }
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        ref={ref => refsObj.assigned_to = ref}
      />

    </Form>
  );
};

MTForm.propTypes = propTypes;

MTForm.defaultProps = defaultProps;

const ComposedMTForm = withLoading(MTForm);

export default ComposedMTForm;
