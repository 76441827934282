import { FORM_CONFIG } from '../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { mt } from '../../../../data/enums/Route';
import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';

const breadCrumbConfig = [
  mt,
];

const title = 'Inventories';

const formMapper = (element = {}) => ({
  sku: element.sku || '',
  skuId: element.skuId || '',
  chainTitle: element.chainTitle || '',
  retailTitle: element.retailTitle || '',
});

const formConfig = {
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.TITLE]: '',
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
  },
};
const filterConfig = getFilterConfig([FILTER.OUTLET_CHAINS,FILTER.OUTLETS,FILTER.BU,FILTER.FREQUENCY]);

export {
  formConfig,
  breadCrumbConfig,
  title,
  filterConfig
};
