import React from 'react';
import styled from 'styled-components';
import { Input } from '../../../../../components';
import Popover from '../../../../../components/Popover';
import withLoading from '../../../../../utils/composition/withLoading';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { normalPresentor } from '../../../../../utils/date';
import {
  ImageSlider, Button, Icon
} from '../../../../../v4/components';
import { OPERATION } from '../config';

const SKUForm = ({ data, onInputChange, inputCallBack,handleIconClick, handleFreeSKUQuantity }) => {
  const { images, title, pfdtarget, Rates, purchase_order_history, netSaving, promotion, netAmount, tabLabel,stockavailable,balance } = data;
  const skuImages = images.length > 0 ? images?.map((imgList) => imgList.image) : [];
  const getSkuType = () => {
    if (data?.isTopSku) {
      return 'TOP SKU';
    }

    if (data?.isRecommendedSku) {
      return 'RECOMMENDED SKU'
    }

    if (data?.isSBDSku) {
      return 'SBD SKU'
    }

    if (data?.isFocusedSku) {
      return 'FOCUSED SKU'
    }

    return null;
  }
  const getPurchaseHistoryDetails = (purchaseHistoryData = []) => {
    return (
      <div className='purchase-history'>
        <div className='heading'>
          <label>Date</label>
          <label className='text-right'>Quantity</label>
        </div>
        {purchaseHistoryData?.length > 0 && purchaseHistoryData.map((history) => (
          <div key={history.sku_id} className='purchase-detail-row'>
            <div className='item-value'>{normalPresentor(history.date)}</div>
            <div className='item-value text-right'>{history.quantity}</div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <SKUFormStyled className='sku-form'>
      <div className='image-section'>
        <ImageSlider images={skuImages} />
        <div className='collage-assets-section'>
          <label>{'Collage & Assets'}</label>
          {/* change this in UI */}
          {/* {images.map((img)=>
            <img src={img.thumbnail}/>)} */}
        </div>
      </div>
      <div className='detail-section'>
        <div className='sku-target'>
          <h3>{title}</h3>
          {getSkuType() ?
            <span className='sku-type'>{getSkuType()}</span>
            : null
          }
          <span className='target'>{`TARGET ${pfdtarget}`}</span>
          {stockavailable ?
            <span className='in-stock'>{`IN STOCK ${balance}`}</span>
            : null
          }
          <div className='rate-wrap'>
            <div className='rate'>
              <label>MRP</label>
              <span className='rate-value'>{`Rs. ${fixedFloatAndCommas(Rates?.map((rate) => rate.priceDetails.mrp))}`}</span>
            </div>
            <div className='rate'>
              <label>RLP</label>
              <span className='rate-value'>{`Rs. ${fixedFloatAndCommas(Rates?.map((rate) => rate.priceDetails.rlp))}`}</span>
            </div>
            <div className='purchase-history'>
              <div className='simple-popup'>
                <Popover
                  popoverIcon='Purchase History'
                  headerTitle='Purchase History'
                  titleWithIcon
                >
                  <span className='arrow-left' />
                  {purchase_order_history?.length>0?getPurchaseHistoryDetails(purchase_order_history):'N/A'}
                </Popover>
              </div>
            </div>
          </div>
        </div>
        <div className='order-quantity'>
          <h3>Order Quantity</h3>
          <div className='order-quantity-wrap-in' >
            <div className={`form-input-wrapper ${promotion?.filter((a)=>a.status&&(a.scope === 'GROUP'|| (a?.promotionScope==='GROUP' && a?.promotionType==='NORMAL')))?.length>0?'disabled':''}`}>
              <Button iconName='hr' onClick={() => onInputChange({
                formattedValue: data.quantity <= 0 ? 0 : data.quantity - 1,
                target: { name: 'quantity', value: data.quantity <= 0 ? 0 : data.quantity - 1 }
              }, '', [], inputCallBack)} />
              <Input
                value={data.quantity}
                name='quantity'
                type='number'
                onChange={(event) => onInputChange(event, '', [], inputCallBack)}
              />
              <Button iconName='plus'
                onClick={() => onInputChange({
                  formattedValue: data.quantity + 1,
                  target: { name: 'quantity', value: data.quantity + 1 }
                }, '', [], inputCallBack)}/>
            </div>
            <div className='net-saving'>
              <label>Net Saving</label>
              <span className='rate-value'>{fixedFloatAndCommas(netSaving)}</span>
            </div>
            <div className='net-amount'>
              <label>Net Amount</label>
              <span className='rate-value'>{fixedFloatAndCommas(netAmount)}</span>
            </div>
          </div>
          <div className='order-quantity-info'>
            {(promotion?.filter((a)=>a.status&&(a.scope === 'GROUP'|| (a?.promotionScope==='GROUP' && a?.promotionType==='NORMAL')))?.length>0)&&( <span>
              <Icon iconName='info-panel-open' />
              <b>Info:</b>
              <p className='info-title'>{'Please edit Order Quantity from selected Group Promotion.'}</p>
            </span>)}
          </div>
        </div>
        {promotion?.length > 0 && (
          <div className='promotion'>
            <h3>Promotions</h3>
            <ul>
              {promotion.map((a, i) => (<li className={a.status ? 'active' : ''} onClick={() => (a.scope === 'GROUP'|| (a?.promotionScope==='GROUP' && a?.promotionType==='NORMAL'))? handleIconClick(OPERATION.PROMOTION, promotion[i], tabLabel, data) : null}>
                {(a.promotionType === 'BILL') ? (<span className='promo'><b>Bill Discount. </b>{a.title}</span>) :
                  (<span className={`${a.scope === 'GROUP'? 'cursor-pointer promo':'promo' }`}>{a.title}</span>)}
                {(a.scope === 'GROUP'|| (a?.promotionScope==='GROUP' && a?.promotionType==='NORMAL')) && (<Icon iconName='angle-up' className='multi-promo-icon'>
                </Icon>)}
                {a.status && (<Icon iconName='check-circle-full' className='check-circle' />)}</li>))}
            </ul>
          </div>
        )}
        {promotion?.length > 0 && (
          <div className='promotion'>
            {promotion[0].freeSkus?.length > 0 && (<h3>Free SKU</h3>)}
            <ul>
              {promotion.map((p,index) => p.freeSkus?.map((a,i) => (
                <li className=''>
                  {a.title}
                  <Input
                    value={a.quantity}
                    name='quantity'
                    type='number'
                    onChange={(e) => onInputChange(e,'quantity', ['promotion',`${index}`,'freeSkus',`${i}`,'quantity'],handleFreeSKUQuantity)}
                  />
                </li>
              ))
              )}
            </ul>
          </div>
        )}
      </div>
    </SKUFormStyled>
  );
};

const SKUFormStyled = styled.div`

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
    display: flex;
      .image-section{
        width: 25%;
      }
      .detail-section{
        width: 75%;
        margin-left:30px;
        h3{
          line-height: 27px;
          font-size: 18px;
          font-weight: 600;
          margin:0;
        }
        .sku-target{
          h3{
            margin-bottom: 10px;
          }
          .sku-type,.target,.in-stock{
            color: #ffffff;
            font-size: 12px;
            line-height: 15px;
            padding: 3px 8px;
            border-radius: 3px;
          }
          .sku-type{
            background: #FF73C3;
          }
          .target{
            background: #4B9BFF;
            margin-left: 8px;
        }

        .in-stock {
          background: #50D2A0;
          margin-left: 8px;
        }
      }
      .rate-wrap{
        display:flex;
        margin-top: 24px;
        padding-bottom:30px;
        .rate{
          width: 28%;
          border: 1px solid #E7E7ED;
          margin-bottom: 0.5rem;
          border-radius: 8px;
          padding: 8px 16px;
          margin-right: 16px;
          label {
            text-transform: capitalize;
            display: block;
            color: #6B6C7E;
            font-size: 12px;
          }
          .rate-value{
              text-transform: capitalize;
              font-weight: 500;
              font-size: 14px;
              padding-bottom: 4px;
          }
        }
        .purchase-history{
          width: 40%;
          border: 1px solid #E7E7ED;
          margin-bottom: 0.5rem;
          border-radius: 8px;
          padding: 1rem;
          background: #FFF4EC;
          color: #FF8F39;
          font-size: 14px;
        }
      }
      .purchase-history{
        .simple-popup{
          .header-dom{
            font-size:14px;
            line-height:24px;
            color:#FF8F39;
            font-weight:500;
            display:flex;
            .icon{
              margin-left: 55px;
            }
          }
           .filter-dropdown-card {
            top: calc(100% - -30px);
             right: -18px;
            min-width:360px;
            padding:0;
            .arrow-left{
            display:none;
            }
            .filter-label{
              padding:24px;
              span:first-child{
                font-size:13px;
                line-height:14px;
                color:#2E384D;
                font-weight:400;
                text-transform: uppercase;
              }
            }
            .filter-content{
              padding:0;
              max-height:300px;
              overflow:auto;
              &>.purchase-history{
                width:unset;
                margin:0;
                background-color:unset;
                color:unset;
                padding:0;
                border:0;
                padding:24px;
                .heading{
                  display: flex;
                  justify-content: space-between;
                  font-size:12px;
                  color:#B0BAC9;
                  text-transform: uppercase;
                  font-weight:500;
                  margin-bottom:16px;
                }
                .purchase-detail-row{
                  display: flex;
                  justify-content: space-between;
                  font-size:15px;
                  line-height:22px;
                  color:#2E384D;
                  margin-bottom:16px;
                  &:last-child{
                    margin-bottom:0;
                  }
                }
              }
            }
        }
      }
      }

      .order-quantity{
        border-bottom: 1px solid #E7E7ED;
        padding: 30px 0;
        border-top: 1px solid #E7E7ED;
        h3{
          margin:0;
          font-size:16px;
          line-height:19px;
          color:#272833;
          margin-bottom:16px;
        }
        .order-quantity-wrap-in{
          display:flex;
        .form-input-wrapper{
          display: flex;
          align-items: center;
          border-radius:4px;
          width: 215px;
          margin-right:24px;
        button{
          margin-right:0;
          width:54px;
          height:57px;
          span{
              border: 2px solid #A7A9BC;
              border-radius: 50%;
              height: 20px;
              width: 20px;
              display: flex;
              text-align: center;
              justify-content: center;
              align-items: center;
              .icon{
                height: 12px;
                width: 12px;
                color:#A7A9BC;
              }
              &:hover{
                border: 1px solid #0B5FFF;
                .icon{
                  color: #0B5FFF;
                }
              }
            }
          &:first-child{
            border:1px solid #B3CDFF;
            border-radius:4px 0 0 4px;
          }
          &:last-child{
            border:1px solid #B3CDFF;
            border-radius:0 4px 4px 0
          }
    }
          .form-input{
            margin-bottom:0;
            border:0;
            width:104px;
            height:57px;
            border-top:1px solid #B3CDFF;
            border-bottom:1px solid #B3CDFF;
            input{
              border:0;
              background-color:unset;
              height:57px;
              font-size:24px;
              line-height:21px;
              color:#272833;
              text-align: center;
            }
          }
        }
        .net-saving{
          border:1px solid #5ACA75;
          border-radius:4px;
          padding:10px 18px;
          display:flex;
          flex-direction:column;
          min-width:152px;
          height:57px;
          margin-right: 8px;
          label{
            font-size:12px;
            line-height:15px;
            color:#6B6C7E;
          }
          span{
            color: #287D3C;
            font-size:14px;
            line-height:20px;
            font-weight:500;
          }
        }

        .net-amount{
          border:1px solid #F48989;
          border-radius:4px;
          padding:10px 18px;
          display:flex;
          flex-direction:column;
          min-width:152px;
          height:57px;
          label{
            font-size:12px;
            line-height:15px;
            color:#6B6C7E;
          }
          span{
            color: #DA1414;
            font-size:14px;
            line-height:20px;
            font-weight:500;
          }
        }
      }
      .order-quantity-info{
        margin-top: 24px;
         > span {
          display: flex;
          align-items: center;
          border: 1px solid rgba(46, 90, 172, 0.4);
          border-radius: 4px;
          background-color: #eef2fa;
          color: #2e5aac;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          b {
            font-weight: 600;
            margin-right: 4px;
          }
          svg {
            height: 14px;
            width: 14px;
            fill: #2e5aac;
            margin: 0px 8px;
            position: relative;
            top: -2px;
          }
        }
      }
      }

      .promotion{
        padding:30px 0 30px 0;
        h3{
        margin: 0;
        font-size: 16px;
        line-height: 19px;
        color: #272833;
        margin-bottom: 16px;
        }
        ul{
          li{
            border:1px solid #E7E7ED;
            border-radius:4px;
            margin-bottom: 8px;
            padding:18px;
            font-size:14px;
            line-height:21px ;
            color:#272833;
            display:flex;
            justify-content:space-between;
            align-items:center;
            position:relative;
            &.active{
              border:1px solid #5ACA75;
              .check-circle{
                display:block;
              position:absolute;
              color:#287D3C;
              top: -6px;
              right: -8px;
            }
            }
            .multi-promo-icon{
                display: unset;
                transform: rotate(90deg);
                height: 12px;
                cursor: pointer;
              }
            .check-circle{
              display:none;
            }
            .promo{
              width:80%;
            }
            .promo-cost{
              color:#272833;
              font-weight:500;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            .form-input input{
              border:1px solid #80ACFF;
              border-radius:4px;
              background-color:#F0F5FF;
              height:24px;
              min-width:60px;
              width:60px;
              padding:5px;
              font-size:12px;
              color:#272833;
              text-align:right;
            }
          }
        }
      }

`

const SKUFormLoading = withLoading(SKUForm);

export default SKUFormLoading;
