import { compose } from 'recompose';
import { query, mutation, CREATE_JBP_TEMPLATE } from './API';
import Detail from './Detail';
import withRequestTracker from '../../../../../../../../utils/composition/withRequestTracker';

const ComposedDetail = compose(
  CREATE_JBP_TEMPLATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Detail);

export default ComposedDetail;
