import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../../v4/components';
import { tableData } from '../../../../../utils/paginatedListUtils';
import { detailsLabelConfig, DetailTableBody } from './tableConfig';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { OrderFulFilmentContext } from '../../config';

const propTypes = {
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  data: PropTypes.instanceOf(Array),
  loading: PropTypes.bool.isRequired,
  selectedOrders: PropTypes.instanceOf(Array),
  amountDetails: PropTypes.instanceOf(Object),
  onOrderStatusChangeClick: PropTypes.func.isRequired,
  checkBoxStatus: PropTypes.func,
  onSecondaryCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  billDiscountDetail: PropTypes.instanceOf(Array),
  logisticsDelivery: PropTypes.bool.isRequired,
};

const defaultProps = {
  data: [],
  activeLine: {
    id: '',
    sellerId: 0,
  },
  checkBoxStatus: () => null,
  onSecondaryCheckBoxClick: () => null,
  selectedOrders: [],
  amountDetails: {},
  billDiscountDetail: [],
  onIconClick: () => null,
};

const InvoicedDetailChildView = ({
  data,
  loading,
  checkBoxStatus,
  onSecondaryCheckBoxClick,
  billDiscountDetail,
  onOrderStatusChangeClick,
  selectedOrders,
  amountDetails,
  onIconClick,
  logisticsDelivery,
}) => {
  let activeLabel = 0;
  return (
    <OrderFulFilmentContext.Consumer>
      {({ permission }) => (
        <Fragment>
          <tr>
            {detailsLabelConfig.map((label) => {
              if (label.show) activeLabel += 1;
              return label.show ? tableData(label.title, label.align, label.classNames) : null;
            })}
          </tr>
          <Fragment>
            {console.log(data, 'data')}
            {data.map((order) =>
              order.Lines.map((sku, index) => (
                <tr>
                  {DetailTableBody({
                    data: sku,
                    index,
                    checkBoxStatus,
                    selectedOrders,
                    onSecondaryCheckBoxClick,
                    promotionId: order.promotionId,
                    onIconClick,
                    order,
                  })}
                </tr>
              )),
            )}
          </Fragment>
          {amountDetails.billDiscount > 0 && (
            <tr>
              <td colSpan={activeLabel - 2} />
              <td
                className='text-right clickable'
                onClick={() =>
                  onIconClick(EVENT_OPERATION.READ_SUB_TYPE, {
                    billDiscountDetail,
                    callOrderValue: { ...amountDetails },
                  })
                }
              >
                <span className='bill-discount-indicator' />
                Bill Discount(
                <span className='bold'>
                  {fixedFloatAndCommas((amountDetails.billDiscount / amountDetails.subTotal) * 100, 2)}%
                </span>
                )
              </td>
              <td className='text-right'>{fixedFloatAndCommas(amountDetails.billDiscount)}</td>
            </tr>
          )}
          {amountDetails.topUpDiscount > 0 && (
            <tr>
              <td colSpan={activeLabel - 2} />
              <td className='text-right'>
                <span className='top-up-indicator' />
                Top Up Discount
              </td>
              <td className='text-right'>{fixedFloatAndCommas(amountDetails.topUpDiscount)}</td>
            </tr>
          )}
          {amountDetails.tradeDiscount > 0 && (
            <tr>
              <td colSpan={activeLabel - 2} />
              <td className='text-right'>
                <span className='trade-discount-indicator' />
                Trade Discount
              </td>
              <td className='text-right'>{fixedFloatAndCommas(amountDetails.tradeDiscount)}</td>
            </tr>
          )}
          {permission.update && (
            <tr>
              <td colSpan='75%'>
                <div className='text-right'>
                  {!logisticsDelivery && (
                    <Button
                      small
                      primary
                      disabled={loading}
                      onClick={() => onOrderStatusChangeClick(EVENT_OPERATION.DISPATCHED, data.invoiceNumber)}
                    >
                      <span>Dispatch</span>
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          )}
        </Fragment>
      )}
    </OrderFulFilmentContext.Consumer>
  );
};

InvoicedDetailChildView.propTypes = propTypes;

InvoicedDetailChildView.defaultProps = defaultProps;

export default InvoicedDetailChildView;
