import styled from 'styled-components';
import { Col, Row } from 'react-flexbox-grid';
import * as RouteLink from '../../../../data/enums/Route';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Progress from '../../../../components/progress';
import { getLogo } from '../../config';
import { outletChannelPercentage } from './config';

const BrandRscore = ({ data, audit_week, selectedWeek, weekList, date, isCustomWeekSelected }) => {
  const rScore = useSelector(state => state?.RscoreDatas?.rScore);
  const businessRscore = rScore?.filter(element => element.bu === data);
  const outletChannel = [...new Set(businessRscore?.map(item => item.outlet_channel))];

  let history = useHistory();

  const onReport = data => {
    history.push({
      pathname: RouteLink.RSCORE_REPORTS,
      state: { auditWeek: audit_week, selectedWeek: selectedWeek, weekList: weekList, brand: data },
    });
    const selectedDate = {
      ...audit_week,
      ...selectedWeek,
      customDate: date,
      isCustomWeekSelected,
    };
    localStorage.setItem('audit_week', JSON?.stringify(selectedDate));
  };

  const summation = (items, prop) => {
    return items?.reduce(function (a, b) {
      return a + b[prop];
    }, 0);
  };

  const calculatePointScored = (data, brand, key) => {
    const filterBu = rScore?.filter(element => element.hasOwnProperty(key) && element[key] === data);
    const outletChannel = filterBu?.filter(element => element.outlet_channel === brand);
    const sumPointScored = summation(outletChannel, 'point_scored');
    return sumPointScored;
  };

  const calculatePointAllocated = (data, brand) => {
    const filterBu = rScore?.filter(element => element.bu === data);
    const outletChannel = filterBu?.filter(element => element.outlet_channel === brand);
    const sumPointAllocated = summation(outletChannel, 'points_allocated');
    return sumPointAllocated;
  };

  const calculatePercent = businessRscore => {
    const filterBusinessRscore = rScore?.filter(element => element.bu === businessRscore);
    const sumPointAllocated = summation(filterBusinessRscore, 'points_allocated');
    const sumPointScored = summation(filterBusinessRscore, 'point_scored');
    const percent = (+sumPointScored / +sumPointAllocated) * 100;
    return { percent, sumPointAllocated, sumPointScored };
  };

  const fillColor = brand => {
    const brandColors = {
      bbsmbgColor: '#1083f6',
      bbsmbaseBgColor: '#f0f5ff',

      bigmartbgColor: '#f27024',
      bigmartbaseBgColor: '#f9eecf',

      saleswaysbgColor: '#43c187',
      saleswaysbaseBgColor: '#d9f2e7',
    };

    return brandColors[brand];
  };

  return (
    <>
      <BrandGrid>
        <div className="brand-container" onClick={() => onReport(data)}>
          <div className="brand-title">
            <img src={getLogo(data?.split(' ')[0].toLowerCase())} alt={data} />
          </div>
          <div className="brand-desc">{fixedFloatAndCommas(calculatePercent(data)?.percent, 1)}%</div>
          <div className="brand-number">
            {fixedFloatAndCommas(calculatePercent(data)?.sumPointScored, 1)}/
            {fixedFloatAndCommas(calculatePercent(data)?.sumPointAllocated, 1)}
          </div>

          {outletChannel?.sort()?.map(brand => (
            <div className="brand-wrap">
              <div className="brand-chart-title">
                <span className="name">{brand}</span>
                <span className="value">
                  {fixedFloatAndCommas(outletChannelPercentage(
                    calculatePointScored(data, brand, 'bu'),
                    calculatePointAllocated(data, brand),
                  ), 1)}%
                </span>
              </div>
              <Row>
                <div style={{ width: '100%', margin: '8px 4px' }}>
                  <Progress
                    completed={(calculatePointScored(data, brand, 'bu') / calculatePointAllocated(data, brand)) * 100}
                    bgColor={fillColor(`${brand.toLowerCase()}bgColor`)}
                    baseBgColor={fillColor(`${brand.toLowerCase()}baseBgColor`)}
                  />
                </div>
              </Row>
            </div>
          ))}
        </div>
      </BrandGrid>
    </>
  );
};

const BrandGrid = styled.div`
  border: 1px solid #e7e7ed;
  padding: 16px;
  text-align: center;
  border-radius: 4px;
  margin-top: 35px;

  .brand-container {
    width: 320px;
    cursor: pointer;
  }

  .brand-wrap {
    margin: 10px 0;
  }
  .brand-title {
    img {
      display: block;
      height: 30px;
      margin: 0 auto;
      margin-bottom: 50px;
    }

    margin-bottom: 40px;
  }

  .brand-desc {
    font-size: 28px;
    color: #272833;
    font-weight: bold;
  }

  .brand-number {
    font-size: 12px;
    color: #6b6c7e;
    margin: 12px 0px;
  }

  .brand-chart-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      font-size: 14px;
      color: #8993a4;
    }

    .value {
      display: block;
      text-align: right;
      font-size: 12px;
      color: #030913;
    }
  }
`;

export default BrandRscore;
