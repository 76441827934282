import { MT_BU } from '../../../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 2,
    title: 'BU',
    path: `/${MT_BU}`,
    active: true,
  },
];

export { breadCrumb };
