import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '../../../../../components/Grid';
import { SKU_CATALOG_LEVEL } from '../../../../../data/enums';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import {
  AutoComplete, CustomSelect, Input
} from '../../../../../components';
import PromotionAutoComplete from '../../../../../components/AutoComplete/AutoCompleteWithInput';
import {
  CUSTOM_SKU_GROUPS, PROMOTION_CRITERIA_DIMENSION, PROMOTION_CRITERIA_OPERATION
} from '../config';
import {
  moqIdResolver,
  moqIdResolverForApplicableSKUs,
  skuGroupInputToCriteriaResolver,
  customGroupSKUmoqToCriteriaResolver,
} from './promotionCatalog.service';
import { CATALOG_LEVEL } from '../../../../../data/enums/Catalog';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { Icon ,Button } from '../../../../../v4/components';

const propTypes = {
  scope: PropTypes.instanceOf(Object).isRequired,
  onAutoCompleteChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  catalogList: PropTypes.instanceOf(Array),
  catalogChildrenList: PropTypes.instanceOf(Array),
  catalogGroupChildrenList: PropTypes.instanceOf(Array),
  onIconClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  customGroupList: PropTypes.instanceOf(Array),
  onAddCatalogClick: PropTypes.func.isRequired,
  customGroupStatus: PropTypes.bool,
  onCustomGroupCrossClick: PropTypes.func,
};

const defaultProps = {
  onInputChange: () => null,
  catalogList: [],
  catalogChildrenList: [],
  catalogGroupChildrenList: [],
  customGroupList: [],
  customGroupStatus: false,
  onCustomGroupCrossClick: PropTypes.func,
};

// eslint-disable-next-line consistent-return
const View = ({ ...props }) => {
  const {
    scope,
    catalogList,
    onIconClick,
    customGroupList,
    onDropDownChange,
    onAddCatalogClick,
    catalogChildrenList,
    catalogGroupChildrenList,
    onAutoCompleteChange,
    customGroupStatus,
    onCustomGroupCrossClick,
    onInputChange,
    crudMode,
  } = props;

  // eslint-disable-next-line max-len
  const handleScopeAutoCompleteChange = (index) => (value, fieldName) => onAutoCompleteChange(value, fieldName, index);

  const handleNormalSKUChange = (value, fieldName) => {
    const skuListWithCriteria = customGroupSKUmoqToCriteriaResolver(value);
    const baseIndex = 0;

    onAutoCompleteChange(skuListWithCriteria, fieldName, baseIndex);
  };

  const handleCustomGroupSKUChange = (index) => (value, fieldName) => {
    const skuListWithCriteria = customGroupSKUmoqToCriteriaResolver(value);

    onAutoCompleteChange(skuListWithCriteria, fieldName, index);
  };

  const handleCustomGroupChange = (event, index) => {
    const criteria = skuGroupInputToCriteriaResolver(event);

    onAutoCompleteChange(criteria, CUSTOM_SKU_GROUPS.HEADER, index);
  };

  const catalogDetailIdsNormalPromotion = [];
  if (scope.promotionCatalog.length && scope.showSelectedList) {
    if (scope.promotionCatalog[0].catalogInfo.catalogDetails) {
      scope.promotionCatalog[0].catalogInfo.catalogDetails.forEach((promotion) => {
        catalogDetailIdsNormalPromotion.push(promotion.id);
      });
    } else if (scope.promotionCatalog[0].catalogInfo.skuFamily) {
      scope.promotionCatalog[0].catalogInfo.skuFamily.forEach((promotion) => {
        catalogDetailIdsNormalPromotion.push(promotion.id);
      });
    }
  }

  return (
    <div className='scope catalog-wrap'>
      <label>Catalog Type</label>
      <div className='mb-24'>
        <label className='radio-list'>
          <input
            type='radio'
            name='customGroupStatus'
            checked={!customGroupStatus}
            onChange={() =>
              onInputChange({
                target: {
                  name: 'customGroupStatus',
                  value: false,
                },
                formattedValue: false,
              })
            }
          />
          <span>Normal</span>
        </label>
        <label className='radio-list'>
          <input
            type='radio'
            name='customGroupStatus'
            checked={customGroupStatus}
            onChange={() =>
              onInputChange({
                target: {
                  name: 'customGroupStatus',
                  value: true,
                },
                formattedValue: true,
              })
            }
          />
          <span>Group</span>
        </label>
      </div>
      <Row>
        <Col md={12}>
          {!customGroupStatus && scope.promotionCatalog ? (
            <Row>
              <Col md={12} className='mt-24'>
                {scope.promotionCatalog.map((catScope, index) => (
                  <Row key={`cat-scope-${catScope}`}>
                    <Col>
                      {index > 0 && (
                        <div className='suggested-autocomplete-list'>
                          <span className='ic-close' onClick={() => onIconClick('remove', 'promotionCatalog', index)}>
                            Close
                          </span>
                        </div>
                      )}
                    </Col>
                    <Col md={3}>
                      <CustomSelect
                        enableValidation
                        options={catalogList}
                        className='custom-select'
                        placeholder='Select Catalog'
                        labelContent='Product Catalog'
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ title }) => title}
                        onChange={(event) => onDropDownChange(event.id, index)}
                        value={catalogList.filter(({ id }) => id === catScope.catalogInfo.catalogId)}
                      />
                    </Col>
                    <Col md={9}>
                      {
                        // eslint-disable-next-line no-nested-ternary
                        catScope.catalogInfo.catalogId === SKU_CATALOG_LEVEL ? (
                          <PromotionAutoComplete
                            name='catalogDetails'
                            fieldName='catalogDetails'
                            labelContent='Catalog'
                            placeholder='Search'
                            dropDownList={catalogChildrenList}
                            selectedDropDowns={moqIdResolverForApplicableSKUs(
                              catScope.catalogInfo.catalogDetails || [],
                            )}
                            onDropDownSelection={handleNormalSKUChange}
                            customGroupStatus={customGroupStatus}
                          />
                        ) : catScope.catalogInfo.catalogId === CATALOG_LEVEL.SKU_FAMILY ? (
                          <PromotionAutoComplete
                            name='skuFamily'
                            fieldName='skuFamily'
                            labelContent='Catalog'
                            placeholder='Search'
                            dropDownList={catalogChildrenList}
                            selectedDropDowns={moqIdResolverForApplicableSKUs(catScope.catalogInfo.skuFamily || [])}
                            onDropDownSelection={handleNormalSKUChange}
                            customGroupStatus={customGroupStatus}
                          />
                        ) : (
                          <div className='catalog'>
                            <AutoComplete
                              name='catalogInfo'
                              labelContent='Catalog'
                              placeholder='Search'
                              dropDownList={catalogChildrenList}
                              selectedDropDowns={catalogDetailIdsNormalPromotion}
                              onDropDownSelection={handleScopeAutoCompleteChange(index)}
                              customGroupStatus={customGroupStatus}
                            />
                          </div>
                        )
                      }
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          ) : null}
          {customGroupStatus ? (
            <Row>
              <Col md={12} className='mt-24'>
                {customGroupList.length > 0
                  ? customGroupList.map((customGroup, index) => (
                    <Fragment>
                      <div className='config-panel-header'>
                        {`Group ${index + 1}`}
                        <div className='flex m-0'>
                          <Icon iconName='times' primary onClick={() => onCustomGroupCrossClick(index)} />
                        </div>
                      </div>
                      <div className='config-catalog-wrap'>
                        <Row>
                          <Col md={3}>
                            <CustomSelect
                              disabled
                              enableValidation
                              options={PROMOTION_CRITERIA_DIMENSION}
                              className='custom-select'
                              placeholder='Select Dimension'
                              labelContent='Condition'
                              getOptionValue={({ id }) => id}
                              getOptionLabel={({ title }) => title}
                              value={PROMOTION_CRITERIA_DIMENSION.filter(({ id }) => id === 1)}
                            />
                          </Col>
                          <Col md={3}>
                            <CustomSelect
                              disabled
                              enableValidation
                              options={PROMOTION_CRITERIA_OPERATION}
                              labelContent='Criteria'
                              className='custom-select'
                              placeholder='Select Dimension'
                              getOptionValue={({ id }) => id}
                              getOptionLabel={({ title }) => title}
                              enableErrorDisplay
                              onChange={(event) => onDropDownChange(event.value, ['operation', 'max', 'operator'])}
                              value={PROMOTION_CRITERIA_OPERATION.filter(({ id }) => id === 2)}
                            />
                          </Col>
                          <Col md={3}>
                            <label>Value</label>
                            <Input
                              name='test'
                              type='number'
                              placeholder='Value'
                              value={customGroup.criteria.operation.max.value}
                              onChange={(event) => handleCustomGroupChange(event, index)}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className='config-catalog-wrap'>
                        <Row className='mb-24'>
                          <Col md={3}>
                            <CustomSelect
                              enableValidation
                              options={catalogList}
                              className='custom-select'
                              placeholder='Select Catalog'
                              labelContent='Catalog Level'
                              getOptionValue={({ id }) => id}
                              getOptionLabel={({ title }) => title}
                              onChange={(event) => onDropDownChange(event.id, index, 'customGroupDetails')}
                              value={catalogList.filter(({ id }) => id === scope.catalogGroupId[index])}
                            />
                          </Col>
                          <Col md={9}>
                            <PromotionAutoComplete
                              name={CUSTOM_SKU_GROUPS.SKU}
                              fieldName={CUSTOM_SKU_GROUPS.SKU}
                              labelContent='Catalog'
                              dropDownList={catalogGroupChildrenList[index]}
                              dropDownList1={catalogGroupChildrenList[index]}
                              selectedDropDowns={moqIdResolver(customGroupList, index)}
                              onDropDownSelection={handleCustomGroupSKUChange(index)}
                              customGroupStatus={customGroupStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Fragment>
                  ))
                  : null}
                <div className='mt-24'>
                  <Button iconBtnSmall secondary iconName='plus' onClick={() => onAddCatalogClick()} />
                </div>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const PromotionDetailViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default PromotionDetailViewWithErrorAndLoading;
