import React from 'react';
import { Tooltip, XAxis, YAxis, Legend, CartesianGrid, Line, LineChart, ResponsiveContainer } from 'recharts';
import { LinechartStyled } from './LinechartStyled';

const Linechart = ({
  data,
  isXAxis,
  isYAxis,
  title,
  legend,
  cartesianGrid,
  tooltip,
  dimensions,
  color,
  xAxisTitle,
  yAxisTitle,
  children,
  aspect,
  interval
}) => {
  const { height, width } = dimensions;
  return (
    <>
      <LinechartStyled height={height} width={width}>
        {title && <div className="linechart-title"> {title}</div>}
        <div className="linechart-wrap">
          <text className="linechart-xaxis">{xAxisTitle}</text>
          <text className="linechart-yaxis"> {yAxisTitle}</text>
          <ResponsiveContainer width="100%" aspect={aspect}>
            <LineChart
              width={width}
              height={height}
              data={data}
              margin={{
                top: 30,
                right: 20,
                left: 0,
                bottom: 20,
              }}
            >
              {cartesianGrid && <CartesianGrid strokeDasharray="4 4" opacity={0.3} />}
              {isXAxis && (
                <XAxis
                  dataKey="week"
                  axisLine={false}
                  fontSize={15}
                  fontWeight={500}
                  allowDataOverflow={false}
                  interval={interval}
                />
              )}
              {isYAxis && (
                <YAxis
                  type="number"
                  domain={[0, 100]}
                  tickFormatter={tick => {
                    return `${tick}%`;
                  }}
                  axisLine={false}
                  fontSize={12}
                  fontWeight={600}
                  allowDataOverflow={false}
                  dx={-20}
                />
              )}

              {tooltip && (
                <Tooltip
                  cursor={{ fill: 'transparent' }}
                  formatter={name => {
                    return `${name}%`;
                  }}
                />
              )}
              {legend && <Legend />}
              {children}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </LinechartStyled>
    </>
  );
};

export default Linechart;
