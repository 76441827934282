import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_MOQ_RECIEVED_ORDERS_LIST = gql`
query getMOQRecievedOrdersList ($limit: Int, $offset: Int, $filter: FilterInput) {
  getMOQReceivedOrdersList(limit: $limit, offset: $offset, filter: $filter) {
    rows {
      callId: id
      dailyOrdersInfoId
      outletChainId
      channelId
      categoryId
      callTime {
        callStart
      }
      location {
        latitude
        longitude
      }
      images {
        type
        callImages {
          image
        }
      }
      lineCount
      brandCount
      callOrderValue {
        grossAmount
        taxableAmount
        taxAmount
        netAmount
      }
      type
      notes
      remarks
      callDate
      RetailOutlet {
        id
        title
        Category {
          title
          Channel {
            title
          }
        }
        RetailOutletChain {
          retailOutletId: id
          title
        }
      }
      Route {
        routeId: id
        title
      }
      Distributor {
        distributorId: id
        title
      }
      Trade {
        tradeId: id
        title
      }
      User {
        dseId: id
        fullName
      }
      totalOrderCount
    }
    count
  }
}
`;

const GET_MOQ_RECEIVED_ORDER = gql`
query getMOQRecievedOrder ($callId: Int!) {
  getMOQReceivedOrder(callId: $callId) {
    id
    callId
    skuId
    buId
    brandId
    skuBatchRateId
    quantity
    quantityInCases
    rateDetails {
      rlp
      rlpWithVat
    }
    amountDetails {
      grossAmount
      taxableAmount
      taxAmount
      netAmount
      subTotal
    }
    orderDate
    stockStatus
    SKU {
      title
    }
    Brand {
      title
    }
  }
}
`;

const GET_PURCHASE_REQUISITION_ORDERS_LIST = gql`
query getPurchaseRequisitionOrdersList ($limit: Int, $offset: Int, $filter: FilterInput) {
  getPurchaseRequisitionOrdersList(limit: $limit, offset: $offset, filter: $filter) {
    rows {
      id
      BU {
        id
        title
      }
      Channel {
        id
        title
      }
      requisitionDate
      amountDetails {
        grossAmount
        taxableAmount
        taxAmount
        netAmount
      }
    }
    count
  }
}
`;


const EMAIL_PURCHASE = graphql(gql`
    mutation emailPurchaseRequisition($id: Int!,$emails:[String]!) {
      emailPurchaseRequisition(id:$id,emails:$emails)
    }`, {
  props: ({ mutate }) => ({
    emailPurchaseRequisition: variables => mutate({
      variables,
    }),
  }),
});
const DOWNLOAD_PURCHASE = graphql(gql`
mutation downloadReport($input: fileDownloadInput!) {
  downloadReport(input: $input) {
    file {
      path
      name
    }
  }
}`,
{
  props: ({ mutate }) => ({
    downloadReport: variables => mutate({
      variables,
    }),
  }),
});
const PRINT_PURCHASE = graphql(gql`
mutation printPurchaseRequisition($id: Int!) {
  printPurchaseRequisition(id: $id) {
    MTPurchaseRequisitionPrint{   pr_id
    channel_id
    channel
    outlet_id
    outlet
    sku_id
    sku
    brand_id
    brand
    bu_id
    bu
    upc
    rate_details {
      rlp
      rlpWithVat
    }
    cases
    pieces
    amount_details {
      grossAmount
      netAmount
      discountAmount
      rate
    }
    distributor_id
    distributor
    trade_id
    trade}
    totalCases
    totalPcs
    subTotal
    taxableAmount
    vat 
    billDiscount
    tradeDiscount
  }
}
`,
{
  props: ({ mutate }) => ({
    printPurchaseRequisition: variables => mutate({
      variables,
    }),
  }),
});
const query = {
  getMOQRecievedOrdersList: GET_MOQ_RECIEVED_ORDERS_LIST,
  getMOQRecievedOrder: GET_MOQ_RECEIVED_ORDER,
  getPurchaseRequisitionOrdersList: GET_PURCHASE_REQUISITION_ORDERS_LIST,
};

const mutation = {
  emailPurchaseRequisition: EMAIL_PURCHASE,
  downloadReport: DOWNLOAD_PURCHASE,
  printPurchaseRequisition: PRINT_PURCHASE,
};


export {
  query, mutation, EMAIL_PURCHASE, DOWNLOAD_PURCHASE, PRINT_PURCHASE,
};
