import styled from 'styled-components';

export const BarchartStyled = styled.div`
  padding-left: 30px;

  .title-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    .title {
      font-size: 20px;
      font-weight: 700;
    }
    .bbsm {
      margin-bottom: 5px;
      .light {
        background: none;
        border: 1px solid #0e69ff;
        color: #0e69ff;
        font-weight: 500;
        height: 20px;
        border-radius: 5px;
        font-size: 11px;
        letter-spacing: 0.4px;
      }
    }
    .bigmart {
      margin-bottom: 5px;
      .light {
        background: none;
        border: 1px solid #f27024;
        color: #f27024;
        font-weight: 500;
        height: 20px;
        border-radius: 5px;
        font-size: 11px;
        letter-spacing: 0.4px;
      }
    }
    .salesways {
      margin-bottom: 5px;
      .light {
        background: none;
        border: 1px solid #43c187;
        color: #43c187;
        font-weight: 500;
        height: 20px;
        border-radius: 5px;
        font-size: 11px;
        letter-spacing: 0.4px;
      }
    }
  }
  .barchart-wrap {
    position: relative;
    /* height: ${props => props.height + 25}px;
    width: ${props => props.width + 45}px; */
    padding-left: 30px;

    .barchart-xaxis {
      position: absolute;
      left: 0;
      bottom: 60%;
      transform: rotate(-90deg);
      font-weight: 400;
      font-size: 14px;
      color: #6b6c7e;
    }

    .barchart-yaxis {
      position: absolute;
      bottom: 0;
      left: 48%;
      font-weight: 400;
      font-size: 14px;
      color: #6b6c7e;
      /* margin-bottom: -20px; */
    }
  }

  .recharts-cartesian-axis-tick-value {
    tspan {
      fill: #030913;
    }
  }

  .recharts-tooltip-wrapper {
    border: none;
    outline: none;
    fill: red;
    min-width: 129px;
    height: 62px;
    border-radius: 12px !important;
  }

  .recharts-default-tooltip {
    border-radius: 4px;
    border: none !important;
    padding: 8px 12px 8px 12px;
  }

  .recharts-tooltip-label {
    color: #6b6c7e;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 2px;
  }

  .recharts-tooltip-item-list {
    fill: #272833;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 0px;

    .recharts-tooltip-item {
      color: #272833 !important;
    }
  }

  .vertical-text {
    font-size: 12px;
    font-weight: 600;
    fill: #030913;
  }

  .recharts-surface {
    overflow: unset;
  }

  .recharts-layer {
    line {
      display: none;
    }
  }
`;
