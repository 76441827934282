import { compose } from 'recompose';
import subDDetails from './subDDetails';
import { query, mutation, UPDATE_OUTLET_FUNDAMENTAL_TARGET_DETAILS } from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposedsubDDetails = compose(
  UPDATE_OUTLET_FUNDAMENTAL_TARGET_DETAILS,
  withRequestTracker({
    query,
    mutation,
  }),
)(subDDetails);

export default ComposedsubDDetails;
