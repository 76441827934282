import React from 'react';
import { monthList as defaultMonthList } from '../../constants/monthList';

const getMonthList = (months: any, activeMonth: number, isPresentationYearActive: boolean, onClick: (month: any) => void, columnWidth = 4) => {
  const numberOfMonths = 12;
  const rowList = [...Array(numberOfMonths / columnWidth).keys()];
  const columnList = [...Array(columnWidth).keys()];

  return rowList.map((rowElement, rowIndex) => (
    <div key={rowIndex} className='month-date-list-wrap'>
      {columnList.map((columnElement, columnIndex) => (
        <div
          key={columnIndex}
          onClick={() => onClick(months[rowIndex * columnWidth + columnIndex])}
          className={
            isPresentationYearActive && activeMonth === months[rowIndex * columnWidth + columnIndex].value.monthIndex
              ? 'active month-date-list'
              : 'inactive month-date-list'
          }
        >
          {months[rowIndex * columnWidth + columnIndex].title}
        </div>
      ))}
    </div>
  ));
};

const MonthPresentor = ({ ...props }) => {
  const { monthList, onMonthClick, activeMonth, isPresentationYearActive } = props;
  return (
    <div style={{ padding: '5px 16px' }}>
      {getMonthList(monthList, activeMonth, isPresentationYearActive, onMonthClick)}
    </div>
  );
};



MonthPresentor.defaultProps = {
  activeMonth: 1,
  onMonthClick: () => null,
  monthList: defaultMonthList,
  isPresentationYearActive: false,
};

export default MonthPresentor;
