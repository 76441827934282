import { compose } from 'recompose';
import Rscore from './Rscore';
import withRequestTracker from '../../utils/composition/withRequestTracker';
import { query } from './API';

const ComposeRscore = compose(
  withRequestTracker({
    query,
  }),
)(Rscore);

export default ComposeRscore;
