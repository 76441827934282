import { compose } from 'recompose';
import BU from './BU';
import { query } from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedMT = compose(
  withRequestTracker({
    query,
  }),
)(BU);

export default composedMT;
