import React from 'react';
import { useSelector } from 'react-redux';
import { Bar, XAxis } from 'recharts';
import Barchart from '../../../../../components/Chart/BarChart';
import { filterItems } from '../../../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { regex } from './config';
import { FundamentalByOutletStyled } from './FundamentalByOutletStyled';

const FundamentalByOutlet = ({ bu, outletChannel }) => {
  const rScore = useSelector(state => state?.RscoreDatas?.rScore);
  const RSCORE_BU = filterItems(rScore, bu, 'bu');
  const OUTLET_CHANNEL = filterItems(RSCORE_BU, outletChannel, 'outlet_channel');

  const RSCORE_BY_OUTLET = _(OUTLET_CHANNEL)
    .groupBy('outlet')
    .map((item, outlet) => ({
      outlet: outlet.replace(regex, ''),
      pointScored: fixedFloatAndCommas(_.sumBy(item, 'point_scored'), 1),
      points_allocated: fixedFloatAndCommas(_.sumBy(item, 'points_allocated'), 1),
      percent: fixedFloatAndCommas((_.sumBy(item, 'point_scored') / _.sumBy(item, 'points_allocated')) * 100, 1),
    }))
    .value();

  const dimension = {
    height: 0,
    width: 0,
  };

  const brandColor = {
    BBSM: '#0e69ff',
    BigMart: '#f27024',
    Salesways: '#43c187',
  };

  return (
    <>
      <h3>{outletChannel}</h3>
      {RSCORE_BY_OUTLET.length > 0 && (
        <FundamentalByOutletStyled>
          <div className="fundamental-by-outlet-wrap">
            <Barchart
              data={RSCORE_BY_OUTLET}
              dimensions={dimension}
              isXAxis={true}
              isYAxis={true}
              legend={false}
              cartesianGrid={false}
              tooltip={true}
              color={brandColor}
              xAxisTitle="Score"
              yAxisTitle="MR Outlets"
              title="R-score by Outlet"
              // barGap={1000}
              barSize={60}
              needCustomize={true}
              aspect={3}
              barCategoryGap={3}
              outletChannel={outletChannel}
            >
              <XAxis dataKey="outlet" axisLine={false} />

              <Bar
                dataKey="percent"
                fill={brandColor[outletChannel]}
                label={{ position: 'top', fill: '#030913', fontSize: '12px', fontWeight: '600' }}
                isAnimationActive={false}
              ></Bar>
            </Barchart>
          </div>
        </FundamentalByOutletStyled>
      )}
    </>
  );
};

export default FundamentalByOutlet;
