import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { breadCrumbConfig, filterConfig, title } from './config';
import { labelConfig, TableHeader } from './tableConfig';
import { isError, getSortValueAfterValueCheck, fetchFromLocalStorage, storeInLocalStorage, imageSliderListFormatter } from '../../common/HelperFunctions';
import { clone } from '../../../utils/objectProcessor';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';
import { getPermissionForVGPReport } from '../../base/permission';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  GetAuditReport: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  downloadReport: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};
class VGPReport extends Component {
static getDerivedStateFromError(error) {
  // Update state so the next render will show the fallback UI.
  return { hasError: true };
}

constructor(props) {
  super(props);
  queryService.resetBaseQueryParameters();
  this.permission = getPermissionForVGPReport();
  this.filterConfigMenu = clone(filterConfig.menu);
  this.state = {
    data: {
      list: [],
      total: 0,
    },
    display: {
      searchBox: false,
    },
    queryParameters: {
      pagination: queryService.baseQueryParameters.pagination,
      search: queryService.baseQueryParameters.search,
      sort: {},
      filter: queryService.baseQueryParameters.filter,
      date: { ...queryService.baseQueryParameters.date },
    },
    filterMenu: this.filterConfigMenu || {},
    tableInfo: labelConfig,
  };

  const { downloadReport, displayAlert } = props;
  this.basePaginationService = new queryService.QueryClass(
    this.setQueryParameters,
    this.getQueryParameters,
    this.loadTableData,
    downloadReport,
    displayAlert,
  );

  this.getData = () => {
    const { data } = this.state;
    return data;
  };
}

componentDidMount() {
  this.getFilterStateFromStorage();
  TableHeader({ onHeaderClick: this.basePaginationService.handleTableSorting, dynamicHeader: true });
}
setFilterStateInStorage = () => {
  const { filterMenu, queryParameters: { filter, date, search } } = this.state;
  
  storeInLocalStorage(
    storageKeys.FILTER,
    {
      filter,
      filterMenu,
      date,
      search,
    },
    DOMAIN.VGP_REPORT
  );
};
getFilterStateFromStorage = () => {
  const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.VGP_REPORT);
  const newFilterConfig = {
    ...localData || {
      filter: { ...filterConfig.instance },
      filterMenu: { ...filterConfig.menu },
      searchText: '',
    },
  };

  if (!localData) {
    return this.loadTableData();
  }

  this.setState(
    {
      ...this.state,
      queryParameters: {
        ...this.state.queryParameters,
        filter: newFilterConfig.filter,
        searchText: newFilterConfig.searchText,
        date: newFilterConfig.date,
      },
      filterMenu: newFilterConfig.filterMenu,
    },
    () => {
      this.loadTableData();
    },
  );
};


loadTableData = () => {
  const { data, queryParameters } = this.state;
  const { getVGPReport } = this.props;
  const offset = getOffsetFromPagination(queryParameters.pagination);
  this.setFilterStateInStorage();
  getVGPReport(
    {
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        dateRange: queryParameters.date,
      },
    },
    {
      handleSuccess: response => {
        const auditReport = (response?.data?.getVGPReport?.rows) || [];
        data.total = (response?.data?.getVGPReport?.count) || 0;
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        data.list = auditReport.map(audit => {
          if (audit.images) {
            audit.images = [
              ...(imageSliderListFormatter(audit.images.map(a=>({
                src:a,
                thumbnail:a
              })), '', { w: 2400, h: 1100 }) || []),
            ];
          }
          return audit;
        });
        this.setState(data);
      },
      handleError: error => {
        this.onAPIRequestFailure(error);
      },
    },
  );
};

setQueryParameters = (queryParams, callBack = () => null) =>
  this.setState({ queryParameters: queryParams }, callBack);

getQueryParameters = () => {
  const { queryParameters } = this.state;

  return queryParameters;
};

onAPIRequestFailure = error => {
  const { displayAlert } = this.props;
  displayAlert(ALERT_TYPE.DANGER, error);
};

controlDisplay = (label, value) => {
  const { display } = this.state;
  display[label] = value;
  this.setState(display);
};

columnSelectChange = data => {
  this.setState({
    tableInfo: data,
  });
};

render() {
  const { data, queryParameters, display, tableInfo ,filterMenu} = this.state;
  const { serverResponseWaiting } = this.props;
  return (
    <Fragment>
      <div className="section-header">
        <PanelStyled>
          <PageHeader
            display={display}
            breadCrumb={breadCrumbConfig}
            config={{
              title,
              download: true,
              filter: true,
              date: true,
            }}
            downloadConfig={{
              domain: [DOMAIN.VGP_REPORT],
            }}
            filter={{
              date: queryParameters.date,
              menuList:filterMenu,
              onFilterChange: this.basePaginationService.handleFilterChange,
            }}
            queryParameters={queryParameters}
            controlDisplay={this.controlDisplay}
            resetFilter={this.basePaginationService.resetFilter}
            clearSearchText={this.basePaginationService.clearSearchText}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
            handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            handleDownloadClick={this.basePaginationService.handleDownloadClick}
          />
        </PanelStyled>
      </div>
      <div className="section-content table-present">
        <View
          data={data}
          permission={this.permission}
          pagination={queryParameters.pagination}
          onIconClick={() => {}}
          loading={serverResponseWaiting}
          onPageChange={this.basePaginationService.onPageSelect}
          onHeaderClick={this.basePaginationService.handleTableSorting}
        />
      </div>
    </Fragment>
  );
  }
}

VGPReport.propTypes = propTypes;

VGPReport.defaultProps = defaultProps;

export default withAlert()(VGPReport);