import styled from 'styled-components';

export const P3MHistoryStyled = styled.div`
  border: 1px solid #e7e7ed;
  margin: 20px 0;
  padding-bottom: 30px;
  border-radius: 4px;
  .linechart-wrap {
    width: 85%;
  }

  .linechart-title {
    font-size: 20px;
    font-weight: 700;
    color: #272833;
    margin-top: 16px;
  }

  .legend-wrap {
    margin-left: -14%;
  }
`;
