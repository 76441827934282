import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withLoading from '../../../../../utils/composition/withLoading';
import { PanelStyled, PanelHeader } from '../../../../common/configuration';
import OutletDetailWrap from '../../../../sales/route-outlet/outlet/detail/OutletDetailStyled';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import General from './General';
import {
  Tab, TabList, TabPanel, Tabs, BreadCrumb, Badge, Icon, Button, menuAction
} from '../../../../../v4/components';
import { DialogFormWrapper } from '../../../../common';
import { CustomSelect } from '../../../../../components';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';
import { USER_ROLE } from '../../../../../data/enums';

const menuConfigList = [
  {
    title: 'Replace with existing user',
    permissionDerivedBy: 'replace',
    type: EVENT_OPERATION.REPLACE,
  },
];

const dialogFormConfig = { title: 'Choose existing user to replace', buttonName: 'Replace', refsObj: ['userId'], validationRequired: true };

const replaceableUserRole = [USER_ROLE.ASM, USER_ROLE.STL, USER_ROLE.DSE, USER_ROLE.DSM, USER_ROLE.NSM]

const propTypes = {
  type: PropTypes.string,
  billingUsers: PropTypes.instanceOf(Array),
  data: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    roleId: PropTypes.number,
    lastName: PropTypes.string,
    password: PropTypes.string,
    firstName: PropTypes.string,
    phoneNumber: PropTypes.string,
    parentUserId: PropTypes.number,
  }),
  onInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  menu: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  parentUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ),
  classificationTypes:PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  handleButtonCancel: PropTypes.func.isRequired,
  handleButtonSubmit: PropTypes.func.isRequired,
  crudMode: PropTypes.oneOf([EVENT_OPERATION.READ, EVENT_OPERATION.CREATE, EVENT_OPERATION.UPDATE]).isRequired,
  billingPermission: PropTypes.bool.isRequired,
  billingStatus: PropTypes.bool.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  breadCrumb: PropTypes.instanceOf(Object).isRequired,
  resetPassword: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  getHeader: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  enableUniqueFieldError: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    roleId: 0,
    parentUserId: 0,
    billingStatus: true,
  },
  refsObj: {},
  roles: [],
  classificationTypes:[],
  parentUsers: [],
  enableErrorDisplay: true,
  type: EVENT_OPERATION.CREATE,
  onInputChange: () => null,
  handleDropDownChange: () => null,
  billingUsers: [],
  permission: PERMISSION_OBJ,
  enableUniqueFieldError: {},
};

const UserAccountForm = ({ ...props }) => {
  const {
    data,
    roles,
    designations,
    refsObj,
    parentUsers,
    onInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    handleButtonCancel,
    handleButtonSubmit,
    billingUsers,
    crudMode,
    billingPermission,
    handleEditIconClick,
    menu,
    billingStatus,
    breadCrumb,
    permission,
    enableUniqueFieldError,
    getHeader,
    loading,
    resetPassword,
    classificationTypes
  } = props;

  const editableOption = crudMode !== EVENT_OPERATION.CREATE && permission.update;
  const showEditButton = crudMode === EVENT_OPERATION.READ;
  const [dialogConfirmationType, setDialogConfirmationType] = useState('');
  const [replacingUserData, setReplacingUserData] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [replaceByUser, setReplaceByUser] = useState('');
  const [dialogSelectError, setDialogSelectError] = useState(false);

  // eslint-disable-next-line max-len
  const roleInfo = roles.length !== 0 && data.roleId !== 0 && roles.find((d) => d.id === data.roleId).name;
  const header = getHeader();

  const getFilteredUserList = (filter) => {
    const { getFilteredUsers, displayAlert } = props;
    getFilteredUsers({ filter }, {
      handleSuccess: (response) => {
        const filterOptions = response.data.filterUsers.rows || [];
        setFilterOptions([...filterOptions])
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    })
  }

  const onReplaceMenuClick = (type, data) => {
    const { roleId } = data;
    let filter = [{
      column: 'roleId',
      value: [roleId.toString()],
    }];

    if (roleId === USER_ROLE.DSE) {
      const { distributorIds, townIds } = data;

      filter.push(
        {
          column: 'distributorId',
          value: distributorIds.map((id) => id.toString()),
        },
        {
          column: 'townId',
          value: townIds.map((id) => id.toString()),
        }
      )
    }

    getFilteredUserList([...filter])
    setReplacingUserData(data)
    setDialogConfirmationType(type);
  }

  const handleDialogClose = () => {
    setDialogConfirmationType('')
    setReplaceByUser('');
    setDialogSelectError(false)
  }

  const handleDialogSumbit = () => {
    const { replaceUser, displayAlert } = props;
    setDialogSelectError(!replaceByUser.toString())


    replaceUser({ replace: replacingUserData.id, replaceWith: replaceByUser }, {
      handleSuccess: (response) => {
        const replacedByName = response.data.replaceUser.rows[0].fullName
        displayAlert(ALERT_TYPE.SUCCESS, `${replacingUserData.firstName} ${replacingUserData.lastName} has been replaced by ${replacedByName}`)
        handleDialogClose();
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err)
      }
    })

  }

  return (
    <Fragment>
      <div className='section-header'>
        <PanelStyled>
          <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <h2 className='user-title'>
              {crudMode === EVENT_OPERATION.CREATE ? (
                'Create User'
              ) : (
                <span className='badge_border'>
                  {header}
                  {crudMode !== EVENT_OPERATION.UPDATE && <Badge light>{roleInfo || 'badge'}</Badge>}
                </span>
              )}
            </h2>
            <div className='flex m-0'>
              {crudMode !== EVENT_OPERATION.READ && (
                <div className='button-wrap'>
                  <Button secondary small disabled={loading} title='Cancel' onClick={() => handleButtonCancel()} />
                  <Button primary small disabled={loading} title='Save' onClick={() => handleButtonSubmit()} />
                </div>
              )}
              {showEditButton ? (
                <div>
                  {dialogConfirmationType && (
                    <DialogFormWrapper
                      formConfig={dialogFormConfig}
                      handleFormSubmit={handleDialogSumbit}
                      onDialogCancel={handleDialogClose}
                      type={dialogConfirmationType}
                      renderDialog={() => (
                        <>
                          <div>
                            <CustomSelect
                              name='userId'
                              options={filterOptions}
                              value={filterOptions.filter((opt) => opt.id === replaceByUser)}
                              labelContent='Existing user'
                              getOptionLabel={({ fullName }) => fullName}
                              getOptionValue={({ id }) => id}
                              onChange={(e) => setReplaceByUser(e.id)}
                              placeholder='Select existing user'
                              enableValidation
                              ref={(ref) => (dialogFormConfig.refsObj.userId = ref)}
                              enableErrorDisplay={dialogSelectError}
                            />
                            <span>
                              <p>By replacing the existing user the current user will be removed.</p>
                              <p>And data like Route line, Call Roster and User Target will be replaced to the selected user with general details and security will remain as it is.</p>
                            </span>
                          </div>
                        </>
                      )}
                    />
                  )
                  }
                  {permission.update && (
                    <>
                      <Button secondary iconBtnSmall onClick={handleEditIconClick} className='ml-16'>
                        <Icon iconName='pencil' />
                      </Button>
                      {replaceableUserRole.includes(data.roleId) ? <Button
                        className='text-right simple-popup-actions with__border'
                        onClick={(e) => e.stopPropagation()}
                      >
                        {menuAction(
                          menuConfigList,
                          { menuIcon: 'ellipsis-v' },
                          onReplaceMenuClick,
                          data,
                          { ...permission, replace: true },
                          true
                        )}
                      </Button> : null}

                    </>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className='section-content section-tab custom-height'>
        {editableOption ? (
          <OutletDetailWrap>
            <Tabs>
              <TabList>
                <Tab>Details</Tab>
              </TabList>
              <TabPanel>
                <General
                  classificationTypes={classificationTypes}
                  data={data}
                  roles={roles}
                  designations={designations}
                  refsObj={refsObj}
                  parentUsers={parentUsers}
                  onInputChange={onInputChange}
                  enableErrorDisplay={enableErrorDisplay}
                  handleDropDownChange={handleDropDownChange}
                  handleButtonCancel={handleButtonCancel}
                  handleButtonSubmit={handleButtonSubmit}
                  billingUsers={billingUsers}
                  crudMode={crudMode}
                  billingPermission={billingPermission}
                  handleEditIconClick={handleEditIconClick}
                  menu={menu}
                  billingStatus={billingStatus}
                  enableUniqueFieldError={enableUniqueFieldError}
                  resetPassword={resetPassword}
                />
              </TabPanel>
            </Tabs>
          </OutletDetailWrap>
        ) : (
          <General
            classificationTypes={classificationTypes}
            data={data}
            roles={roles}
            designations={designations}
            refsObj={refsObj}
            parentUsers={parentUsers}
            onInputChange={onInputChange}
            enableErrorDisplay={enableErrorDisplay}
            handleDropDownChange={handleDropDownChange}
            handleButtonCancel={handleButtonCancel}
            handleButtonSubmit={handleButtonSubmit}
            billingUsers={billingUsers}
            crudMode={crudMode}
            billingPermission={billingPermission}
            handleEditIconClick={handleEditIconClick}
            menu={menu}
            billingStatus={billingStatus}
            enableUniqueFieldError={enableUniqueFieldError}
          />
        )}
      </div>
    </Fragment >
  );
};

UserAccountForm.propTypes = propTypes;

UserAccountForm.defaultProps = defaultProps;

const UserAccountDetailForm = withLoading(UserAccountForm);

export default withAlert()(UserAccountDetailForm);
