import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import PrintBody from './PurchaseRequisition/details/PrintBody';
import PurchaseForm from './Form';
import MOQWrap from './MOQWrap';
import history from '../../../../utils/history';
import { formatter } from '../../../../utils/date';
import PageHeader from '../../../base/PageHeader';
import { DialogFormWrapper } from '../../../common';
import PurchaseRequistion from './PurchaseRequisition';
import * as queryService from '../../../base/query.service';
import { isError, groupBy } from '../../../common/HelperFunctions';
import MOQReceived from './MOQReceived/MOQReceived';
import withAlert from '../../../../utils/composition/withAlert';
import { PanelStyled } from '../../../common/configuration';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { getPermissionForRScore } from '../../../base/permission';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import {
  breadCrumbConfig, title, createButtonTitle, moqFilter, purchaseRequisitionFilter, formConfig, REQUISITION_VIEW, EmailSuccess,
  EmailFail,
} from './config';
import { PURCHASE_REQUISITION_CREATE, PURCHASE_REQUISITION_DETAILS } from '../../../../data/enums/Route';
import { refGenerator } from '../../../../utils/refGenerator';

const propTypes = {
  getMOQRecievedOrdersList: PropTypes.func.isRequired,
  getMOQRecievedOrder: PropTypes.func.isRequired,
  getPurchaseRequisitionOrdersList: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  emailPurchaseRequisition: PropTypes.func.isRequired,
  printPurchaseRequisition: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};
const MOQ = (props) => {
  const {
    getMOQRecievedOrdersList, getMOQRecievedOrder, getPurchaseRequisitionOrdersList, displayAlert, serverResponseWaiting,
  } = props;

  const [moqData, setMoqData] = useState({ list: [], total: 0 });
  const [dialog, setDialog] = useState({ type: '', element: '' });
  const [enableErrorDisplay, setEnableErrorDisplay] = useState(false);
  const [printInfo, setPrintInfo] = useState({ status: false, id: 0 });
  const [purchaseRequisitionData, setPurchaseRequisitionData] = useState({ list: [], total: 0 });
  const [dataPrint, setDataPrint] = useState({ list: [] });
  const [queryParameters, setQueryParameters] = useState({
    pagination: queryService.baseQueryParameters.pagination,
    search: queryService.baseQueryParameters.search,
    sort: queryService.baseQueryParameters.sort,
    filter: queryService.baseQueryParameters.filter,
    type: REQUISITION_VIEW.BRAND,
    date: { ...queryService.baseQueryParameters.date },
  });

  const [childData, setChildData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const formReference = refGenerator(['emails']);
  const onAPIRequestFailure = (error) => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };
  const loadTableData = useCallback(() => {
    const offset = getOffsetFromPagination(queryParameters.pagination);
    if (activeTab === 0) {
      getMOQRecievedOrdersList({
        offset,
        limit: 50,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      }, {
        handleSuccess: (res) => {
          setMoqData({
            list: res.data.getMOQReceivedOrdersList ? res.data.getMOQReceivedOrdersList.rows : [],
            total: res.data.getMOQReceivedOrdersList ? res.data.getMOQReceivedOrdersList.count : 0,
          });
          if (isError(res)) {
            onAPIRequestFailure(res);
          }
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      });
    }

    if (activeTab === 1) {
      getPurchaseRequisitionOrdersList({
        offset,
        limit: 50,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      }, {
        handleSuccess: (res) => {
          setPurchaseRequisitionData({
            list: res.data.getPurchaseRequisitionOrdersList ? res.data.getPurchaseRequisitionOrdersList.rows : [],
            total: res.data.getPurchaseRequisitionOrdersList ? res.data.getPurchaseRequisitionOrdersList.count : 0,
          });
          if (isError(res)) {
            onAPIRequestFailure(res);
          }
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      });
    }
  }, [activeTab, queryParameters]);

  const handleTabChange = (tabIndex) => {
    setQueryParameters({ ...queryParameters, pagination: queryService.baseQueryParameters.pagination });
    setActiveTab(tabIndex);
  };

  const setParameters = queryParams => setQueryParameters({ ...queryParams });

  const basePaginationService = new queryService.QueryClass(
    setParameters,
    () => (queryParameters),
    loadTableData,
  );


  const handleCreate = (type, element = {}) => {
    history.push(`/${PURCHASE_REQUISITION_CREATE}`);
  };

  const clearSearchText = () => {
    setQueryParameters({ ...queryParameters, search: '' });
  };

  const handleSearch = (value) => {
    setQueryParameters({ ...queryParameters, search: value });
  };

  const handleRowClick = (orderId) => {
    getMOQRecievedOrder({ callId: orderId },
      {
        handleSuccess: (res) => {
          setChildData(res.data.getMOQReceivedOrder);
          if (isError(res)) {
            onAPIRequestFailure(res);
          }
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      });
  };

  const handleDateRangeChange = (field, date) => {
    queryParameters.date.start = formatter(date.start)
      .format('DD MMM YYYY');
    queryParameters.date.end = formatter(date.end)
      .format('DD MMM YYYY');
    queryParameters.pagination.page = 1;

    setQueryParameters({ ...queryParameters });
  };

  useEffect(() => {
    loadTableData();
  }, [activeTab, queryParameters]);

  const onTableRowClick = (idRow) => {
    history.push(`/${PURCHASE_REQUISITION_DETAILS}/${idRow}`);
  };
  const onSampleDownload = (id) => {
    const { downloadReport } = props;
    downloadReport({
      input: {
        domain: 'PURCHASE_REQUISITION',
        filter: {
          filters: [
            {
              column: 'id',
              value: [id.toString()],
            },
          ],
        },
        reportType: 'csv',
      },
    },
    {
      handleSuccess: (response) => {
        const { downloadReport } = response.data;
        window.open(`${window.location.origin}/${downloadReport.file.name}`);
        displayAlert(ALERT_TYPE.SUCCESS, 'Successfully downloaded file');
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },
    });
  };

  useEffect(() => {
    if (printInfo.status === true) {
      window.print();
    }
    window.onafterprint = () => {
      setPrintInfo({ status: false });
    };
  }, [printInfo]);

  const brandName = (obj) => {
    if (queryParameters.type === REQUISITION_VIEW.BRAND) {
      return obj.brand;
    }
  };

  const print = (id) => {
    const { printPurchaseRequisition } = props;
    printPurchaseRequisition({ id },
      {
        handleSuccess: (response) => {
          const res = response.data.printPurchaseRequisition.MTPurchaseRequisitionPrint;
          const groupedArray = groupBy(res, brandName);
          const keys = ['group', 'skuList'];
          const order = groupedArray.map(r => (keys.reduce((o, k, i) => (o[k] = r[i], o), {})));
          const orderWithImage = order.map(list => ({
            ...list,
            skuList: list.skuList.map(sku => sku),
          }));
          setDataPrint({ list: orderWithImage });
          setPrintInfo({ status: true, id });
          if (isError(response)) onAPIRequestFailure(response.errors[0]);
        },
        handleError: (err) => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      });
  };
  const resetDialog = () => {
    setDialog({ type: '', element: '' });
  };
  const getDirectApiCall = (type, element) => {
    switch (type) {
      case EVENT_OPERATION.DOWNLOAD:
        onSampleDownload(element.id);
        break;
      case EVENT_OPERATION.PRINT:
        print(element.id);
        resetDialog();
        break;
      default:
    }
  };

  const handleIconClick = (type, element = {}) => {
    if (type === EVENT_OPERATION.UPDATE) { setDialog({ type, element }); } else {
      getDirectApiCall(type, element);
    }
  };

  const getFormValidationStatus = () => (!Object.values(formReference)
    .find((item) => {
      if (typeof (item) !== 'string') return (item.getValidState() === false);
    }));

  const handleFormSubmit = (type, dialogData) => {
    const { emailPurchaseRequisition } = props;
    const email = dialogData.emails && dialogData.emails.split(',');
    const emailMapped = email && email.map(a => a.replace(/\s+/g, ' ').trim());
    const emailValid = emailMapped && emailMapped.every(a => a.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/));
    const valid = getFormValidationStatus();
    if (emailValid && valid) {
      emailPurchaseRequisition({
        id: dialogData.id,
        emails: emailMapped,
      }, {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, EmailSuccess);
          resetDialog();
        },
        handleError: (err) => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      });
    } else {
      setEnableErrorDisplay(true);
      if (!emailValid && emailValid !== undefined) { displayAlert(ALERT_TYPE.CUSTOM_DANGER, EmailFail); }
    }
  };
  const { type, element } = dialog;
  return (
    <MOQWrap>
      <div className={printInfo.status ? 'no-print' : 'display-block portrait'}>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              breadCrumb={breadCrumbConfig}
              config={{
                title,
                create: true,
                createButtonTitle,
                search: true,
                date: true,
                filter: true,
              }}
              filter={{
                updateMenu: true,
                date: queryParameters.date,
                menuList: (activeTab === 0) ? moqFilter.menu : purchaseRequisitionFilter.menu,
                onFilterChange: basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              handleCreateClick={handleCreate}
              clearSearchText={clearSearchText}
              handleSearchChange={handleSearch}
              handleDateRangeChange={handleDateRangeChange}
              handleDownloadClick={basePaginationService.handleDownloadClick}
            />
          </PanelStyled>
        </div>
        <div className="section-content custom-height section-tab">
        <>
          <Tabs
            selectedIndex={activeTab}
            onSelect={handleTabChange}
          >
            <TabList>
              <Tab>
                <span className="tab-label">MOQ Received</span>
              </Tab>
              <Tab>
                <span className="tab-label">Purchase Requisition</span>
              </Tab>
            </TabList>
            <TabPanel>
              <section id="moq-received-tab">
                <MOQReceived data={moqData} childData={childData} handleRowClick={handleRowClick} pagination={queryParameters.pagination} onPageChange={basePaginationService.onPageSelect} />
              </section>
            </TabPanel>
            <TabPanel>
              <section id="purchase-requisition-tab">
                {type && (
                <DialogFormWrapper
                  formConfig={formConfig[type]}
                  dialogElement={element}
                  onDialogSubmit={handleFormSubmit}
                  onDialogCancel={resetDialog}
                  type={type}
                  renderDialog={({
                    dialogData, handleInputChange,
                  }) => (
              <>
                {type === EVENT_OPERATION.UPDATE && (
                  <PurchaseForm
                    data={dialogData}
                    handleInputChange={handleInputChange}
                    enableErrorDisplay={enableErrorDisplay}
                    refsObj={formReference}
                  />
                )}
              </>
                  )}
                />
                )}
                    <>
                      <PurchaseRequistion
                        data={purchaseRequisitionData}
                        permission={getPermissionForRScore()}
                        pagination={queryParameters.pagination}
                        onPageChange={basePaginationService.onPageSelect}
                        loading={serverResponseWaiting}
                        onTableRowClick={onTableRowClick}
                        onIconClick={handleIconClick}
                      />
                    </>
              </section>
            </TabPanel>
          </Tabs>
        </>
        </div>
      </div>
    </MOQWrap>
  );
};
MOQ.propTypes = propTypes;
MOQ.defaultProps = defaultProps;

export default withAlert()(MOQ);
