import styled from 'styled-components';

export const ImageStyled = styled.div`
  height: 100vh;
  overflow-x: scroll;

  .page-body {
    padding-left: 48px;
    padding-right: 20px;
  }

  .category-type {
    color: #272833;
    font-size: 14px;
    font-weight: 600;
    margin: 25px 0 15px 0;
  }

  .pswp-thumbnail {
    width: 24.7%;
    span {
      color: #6b6c7e;
      font-size: 13px;
      font-weight: 400;
    }
  }

  .time-date {
    display: flex;
    flex-direction: column;
    span {
      box-shadow: none;
      padding: 5px 5px 0px 5px;
      :last-child {
        padding-bottom: 5px;
      }
    }
  }
`;
