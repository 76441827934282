import { refGenerator } from '../../../utils';
import { getTodaysDate } from '../../../utils/date';
import { EVENT_OPERATION } from '../../../v4/constants/EventOperation';
import { FORM_CONFIG } from '../../../v4/constants/formConfig';

const title = 'Outlets';
const breadCrumbConfig = [
  {
    id: 0,
    title,
    path: '#',
    active: false,
  }
];

const CASE_TYPE = [
  {
    title:'Unsuccessful Calls',
    value:'UNSUCCESSFULCALL'
  },{
    title:'Absent DSE',
    value:'ABSENTDSE'
  }];

const LAST_INVOICE_DAYS = [{
  title:'Last 30 Days',
  value:30
}];

const LAST_UNSUCESSFULL_CALLS = [
  {
    title:'Today',
    value:0
  },
  {
    title:'Yesterday',
    value:1
  },
  {
    title:'Last 3 Days',
    value:3
  },{
    title:'Last 7 Days',
    value:7
  },{
    title:'Last 14 Days',
    value:14
  },{
    title:'Last 21 Days',
    value:21
  }
];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Select Team Member',
    [FORM_CONFIG.MAPPER]: (element = {}) => ({
      user_id: element.user_id||'',
      date: element.date || getTodaysDate(),
    }),
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['user_id']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  validationField: ['dse_id'],
  validationRequired: true,
};

export {
  title, breadCrumbConfig, CASE_TYPE, LAST_INVOICE_DAYS, LAST_UNSUCESSFULL_CALLS, formConfig
};
