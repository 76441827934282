import React from 'react'
import PropTypes from 'prop-types'
import { dataProps, paginationProps } from '../../../../data/enums/Component'
import { PERMISSION_OBJ } from '../../../../data/enums/Permission'
import BulkDelete from '../../../components/BulkDelete/BulkDelete';
import { TableBody, TableHeader } from './tableConfig';
import { Pagination } from '../../../../components';
import withLoading from '../../../../utils/composition/withLoading';
import { crudSuccess } from './config';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onBulkDelete: PropTypes.func.isRequired,
  onTableRowClick: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  deletebulkVisibilityAudit: PropTypes.func.isRequired,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: dataProps.defaultValue,
  onPageChange: () => null,
  onCheckBOxClick: () => null,
  onIconClick: () => null,
  PermissionRequest: PERMISSION_OBJ,

}

const View = (props) => {
  const {
    data, onPageChange, onIconClick, pagination, permission, onBulkDelete, onTableRowClick,
    deletebulkVisibilityAudit,
  } = props;


  return (
    <>
      <BulkDelete
        data={data.list}
        handler={onBulkDelete}
        useExternalAPI
        deleteServerCall={deletebulkVisibilityAudit}
        crudExternalRequestConfig={crudSuccess}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap">
            <table>
              {TableHeader({
                dataListLength: data.list.length,
                checkedListLength: state.checkedList.length,
                primaryCheckboxHandler,
                permission,
              })}
              <tbody>
                {data.list.map((rows) => TableBody({
                  data: rows,
                  checkedList: state.checkedList,
                  onIconClick,
                  secondaryCheckboxHandler,
                  permission,
                  onTableRowClick,
                }))}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </>
  )
}

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const VisibilityAuditTable = withLoading(View);

export default VisibilityAuditTable;
