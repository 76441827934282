import { numberPresentor } from '../../../utils/conversion';
import { tableData, Icon } from '../../../v4/components';
import { EVENT_OPERATION } from '../../../v4/constants/EventOperation';

const labelMappings = {
  subd: 'subd',
  month: 'month',
  target: 'target',
  action: 'action',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.subd,
    title: 'Sub D',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.month,
    title: 'Month',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.target,
    title: 'Targets',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.action,
    title: '',
    show: true,
  },
];

const returnTableBody = (data, handleIconClick, displayEdit) => {
  const table = {};
  table[labelMappings.subd] = <td key={`${data.distributorId}-subd`} >{data.title}</td>;
  table[labelMappings.month] = <td key={`${data.distributorId}-monthName`} className='right-align'>{data.date}</td>;
  table[labelMappings.target] = (
    <td key={`${data.distributorId}-target`} className='right-align'>
      {numberPresentor(data.target)}
    </td>
  );
  table[labelMappings.action] = (
    <td key={`${data.distributorId}-action`} className='right-align' onClick={(e) => e.stopPropagation()}>
      <>
        <span className='view' onClick={() => handleIconClick(EVENT_OPERATION.READ, data)}>
          <Icon iconName='view' />
          View
        </span>
        {displayEdit&&(<span onClick={() => handleIconClick(EVENT_OPERATION.UPDATE, data)}>
          <Icon iconName='pencil' />
          Edit
        </span>)}
      </>
    </td>
  );
  return table;
};
const TableHeader = () => (
  <thead>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const TableBody = ({ data, onTableBodyClick, handleIconClick, displayEdit }) => {
  const tableBody = returnTableBody(data, handleIconClick, displayEdit);

  return (
    <tr key={data.distributorId} className='cursor-pointer' onClick={() => onTableBodyClick(data)}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

export { TableHeader, TableBody };
