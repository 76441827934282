import React from 'react';
import BrandChain from '../../sections/BrandChain/BrandChain';
import FundamentalByMrOutlet from '../../sections/fundamentalByOutlets/FundamentalByMrOutlet';
import FundamentalByOutlet from '../../sections/fundamentalByOutlets/FundamentalByOutlet';
import OutletWiseScore from '../../table/OutletWiseScore/OutletWiseScore';

const Bigmart = ({ bu }) => {
  return (
    <div>
      <FundamentalByOutlet bu={bu} outletChannel="BigMart" />
      <FundamentalByMrOutlet bu={bu} outletChannel="BigMart" />
      <OutletWiseScore title="Product Category R-Score and Gap Report" bu={bu} outletChannel="BigMart" />
      <BrandChain bu={bu} outletChannel="BigMart" />
    </div>
  );
};

export default Bigmart;
