import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../../../../utils/paginatedListUtils';
import { Button, CustomSelect, Switch } from '../../../../../../../components';

const labelMappings = {
  fundamental: 'fundamental',
  status: 'status',
  auditCriteria: 'auditCriteria',
  button: 'button',
};
const labelConfig = [
  {
    id: 1,
    label: labelMappings.fundamental,
    title: 'Fundamental',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.auditCriteria,
    title: 'Audit Criteria',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.button,
    title: '',
    show: true,
  },
];
const TableHeader = () => (
  <thead>
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align) : null
      ))}
    </tr>
  </thead>
);

const returnTableBody = (data, index, menu, onInputChange, onDropDownChange, onSaveClick) => {
  const table = {};

  table[labelMappings.fundamental] = (
    <td key={`jbp-${data.fundamentalTypeId}-fundamental`}>{data.title}</td>
  );
  table[labelMappings.auditCriteria] = (
    <td key={`jbp-${data.fundamentalTypeId}-auditCriteria`}>
      <CustomSelect
        small
        className="custom-select"
        placeholder="Select Audit Criteria"
        options={menu.auditList.filter(list => list.fundamentalsId === data.fundamentalTypeId)}
        getOptionValue={({ id }) => id}
        getOptionLabel={({ title }) => title}
        value={menu.auditList.filter(list => list.id === data.auditCriteriaId)}
        onChange={event => onDropDownChange(event.id, [`${index}`, 'auditCriteriaId'])}
      />
    </td>
  );
  table[labelMappings.status] = (
    <td key={`jbp-${data.fundamentalTypeId}-status`}>
      <Switch
        checked={data.active}
        onToggle={(checked) => {
          onInputChange(
            {
              formattedValue: !!checked,
              target: {
                name: 'active',
                type: 'boolean',
                value: !!checked,
              },
            },
            'active',
            [`${index}`, 'active'],
          );
        }}
      />
    </td>
  );
  table[labelMappings.button] = (
    <td key={`jbp-${data.fundamentalTypeId}-button`} className="text-right">
      <Button primary small onClick={() => onSaveClick(data.fundamentalTypeId)}>Save</Button>
    </td>
  );
  return table;
};

const TableBody = ({
  data, index, menu, onInputChange, onDropDownChange, onSaveClick,
}) => {
  const tableBody = returnTableBody(data, index, menu, onInputChange, onDropDownChange,
    onSaveClick);

  return (
    <tr key={`${data.fundamentalTypeId}-table-body`}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  menu: PropTypes.instanceOf(Object).isRequired,
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
};

export {
  TableHeader,
  TableBody,
};
