import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_OUTLET_CHAIN_LIST = gql`
  query getOutletChain($searchText: String) {
    getOutletChain(searchText: $searchText) {
        id
        title
        active
        business_partner_id
        image_url {
          image
          thumbnail
          created_at
        }
    }
  }
`;

const TOGGLE_STATE_OUTLET_CHAIN = graphql(
  gql`
  mutation CreateRetailOutletChain($input: OutletChainInput!) {
    createRetailOutletChain(input: $input) {
      active
      title
    }
  }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      toggleStateOutletChain: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getOutletChainList:GET_OUTLET_CHAIN_LIST,
};

const mutation = {
  toggleStateOutletChain: TOGGLE_STATE_OUTLET_CHAIN,
};

export {
  GET_OUTLET_CHAIN_LIST,TOGGLE_STATE_OUTLET_CHAIN,
};

export { query, mutation };
