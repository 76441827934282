import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, AutoComplete, Row, Col, Icon,
} from '../../../components';
import withLoading from '../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    territoryId: PropTypes.number,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  onAutoCompleteChange: PropTypes.func.isRequired,
  categoryList: PropTypes.instanceOf(Array).isRequired,
  brandList: PropTypes.instanceOf(Array).isRequired,
};

const defaultProps = {
  handleInputChange: () => null,
  enableErrorDisplay: true,
  data: {
    name: '',
    territoryId: 0,
  },
  refsObj: {},
  handleDropDownChange: () => null,
};

const TownForm = ({ ...props }) => {
  const {
    data, enableErrorDisplay, handleInputChange, refsObj, categoryList, brandList, onAutoCompleteChange,
  } = props;


  return (
    <Form>
      <div className="merchandise-dialog">

        <Col md={12} xs={12}>
          <Input
            ref={ref => refsObj.title = ref}
            value={data.title}
            name="title"
            type="text"
            placeholder="Title"
            labelContent="Title"
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={event => handleInputChange(event)}
          />
        </Col>

        <Col md={12} xs={12} className="parent_div">
          <AutoComplete
            name="brandList"
            labelContent="Brand"
            dropDownList={brandList}
            placeholder="Search and Select"
            selectedDropDowns={data.brandIds}
            onDropDownSelection={onAutoCompleteChange}
          />
          <Icon iconName="search" />
        </Col>

        <Col md={12} xs={12} className="parent_div">
          <AutoComplete
            name="categoryList"
            labelContent="Categories"
            dropDownList={categoryList}
            placeholder="Search and Select"
            selectedDropDowns={data.categoryIds}
            onDropDownSelection={onAutoCompleteChange}
          />
          <Icon iconName="search" />
        </Col>

      </div>
    </Form>
  );
};

TownForm.propTypes = propTypes;

TownForm.defaultProps = defaultProps;

const TownViewWithErrorAndLoading = withLoading(TownForm);

export default TownViewWithErrorAndLoading;
