import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import childView from './childView';
import { TableBody } from '../tableConfig';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import AccordionTable from '../../../../../components/CollapsibleTable/AccordionTable';

const propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  onIconClick: PropTypes.func.isRequired,
  activeCallIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  stockStatusInfo: PropTypes.bool,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  onInvoiceClick: PropTypes.func,
  selectedOrders: PropTypes.instanceOf(Array),
  checkBoxStatus: PropTypes.func,
  onPrimaryCheckBoxClick: PropTypes.func,
  onSecondaryCheckBoxClick: PropTypes.func,
  receivedLoading: PropTypes.bool,
};

const defaultProps = {
  stockStatusInfo: false,
  onRowClick: () => null,
  onInvoiceClick: () => null,
  selectedOrders: [],
  checkBoxStatus: () => null,
  onPrimaryCheckBoxClick: () => null,
  onSecondaryCheckBoxClick: () => null,
  receivedLoading: false,
};

const DetailView = ({ ...props }) => {
  const {
    data,
    loading,
    onRowClick,
    onIconClick,
    selectedOrders,
    checkBoxStatus,
    onInvoiceClick,
    activeCallIndex,
    receivedLoading,
    onPrimaryCheckBoxClick,
    onSecondaryCheckBoxClick,
    stockStatusInfo,
  } = props;

  return (
    <tbody>
      {
        <AccordionTable>
          {data.map((received, index) => (
            <tr
              key={received.id}
              trigger={TableBody({
                data: received,
                onRowClick,
              })}
            >
              {received.totalOrderCount > 0 && index === activeCallIndex ? (
                childView({
                  data: received.Orders || [],
                  checkBoxStatus,
                  onPrimaryCheckBoxClick,
                  onSecondaryCheckBoxClick,
                  onIconClick,
                  onInvoiceClick,
                  selectedOrders,
                  loading: loading || receivedLoading,
                  call: received,
                  stockStatusInfo,
                })
              ) : (
                <Fragment />
              )}
            </tr>
          ))}
        </AccordionTable>
      }
    </tbody>
  );
};

DetailView.propTypes = propTypes;

DetailView.defaultProps = defaultProps;

const ReceivedDetailViewWithErrorAndLoading = withErrorAndLoadingScreen(DetailView);

export default ReceivedDetailViewWithErrorAndLoading;
