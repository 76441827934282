import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_CATALOG_DETAIL } from '../../sku/createSku/API';
import { GET_ROUTE_VISIT_FREQUENCY_LIST, GET_USERS_LIST } from '../API';
import { GET_NEW_CHANNEL_LIST } from '../../channelV3.1/API';

const GET_MT_BASIC_DETAIL = gql`
    query getMTStockList($offset: Int, $limit: Int, $filter: FilterInput){
        getMTStockList(offset: $offset, limit: $limit, filter: $filter) {
            count
            rows {
                id
                title
                count_days
                countskus
                assigned_to
            }
        }
    }
`;

const GET_MT_STOCK_COUNT = gql`
    query getMTStockCount($mtstockId: Int!, $buId: Int, $categoryId: Int, $brandId: Int) {
        getMTStockDetails(mtstockId: $mtstockId, buId: $buId, categoryId: $categoryId, brandId: $brandId){
            count
            rows{
                bu{
                    id
                    count
                }
                Category{
                    id
                    count
                }
                Brand{
                    id
                    count
                }
                Sku{
                    skus
                    count
                }
                SkuFamily {
                    skufamilies
                    count
                }
            }
        }
    }
`;

const GET_BRAND_DETAILS = gql`
    query getBrandDetails {
        catalogDetails(catalogId: 3, limit:1000){
            rows {
                id
                title
                parentCatalogDetailId
            }
        }
    }
`;

const GET_SKU_DETAILS = gql`
    query getBrandsSkus($value: Int) {
            catalogLevelDetails(
                catalogId: 3
                catalogDetailId: $value
            ){
                count
                rows{
                    id
                    title
                    SKUs{
                        id
                        title
                    }
                    SkuFamilies{
                        id: catalogDetailId
                        title
                    }

                }
            }
    }
`;

const CREATE_MT_SKUS = graphql(gql`
    mutation createMTStockDetails($input: [MTStockDetailInput]!) {
        createMTStockDetails(input:$input){
            id
            stock_audit_id
            principal_id
            brand_id
            product_category_id
            sku_family_id
            sku_id
        }
    }`, {
  props: ({ mutate }) => ({
    createMTStockDetails: variables => mutate({
      variables,
    }),
  }),
});

const DELETE_MT_SKU = graphql(gql`
    mutation deleteMTStockDetail($skuId: [Int]!, $stockAuditId: Int!) {
        deleteMTStockDetail(skuId:$skuId, stockAuditId:$stockAuditId){
            id
            stock_audit_id
            principal_id
            brand_id
            product_category_id
            sku_family_id
            sku_id
            active
        }
    }`, {
  props: ({ mutate }) => ({
    deleteMTStockDetail: variables => mutate({
      variables,
    }),
  }),
});

const GET_PRODUCT_GROUP_DETAILS = gql`
    query getProductGroupDetails($parentCatalogDetailId:Int) {
        catalogDetails(catalogId:2,parentCatalogDetailId:$parentCatalogDetailId){
            rows {
                id
                title
                catalogId
            }
        }
    }
`;

const GET_SKU_FAMILY_DETAILS = gql`
    query getSKUFamilyDetails($parentCatalogDetailId: Int) {
        catalogDetails(offset:0, limit:1000, catalogId: 7, parentCatalogDetailId:$parentCatalogDetailId){
            rows {
                id
                title
                skus: SKUs{
                    id
                    title
                }
            }
        }
    }
`;

const GET_ASSORTMENT_CATEGORY_COUNT = gql`
    query getAssortmentCategoryCount($channelId: Int){
        categoriesAssortmentCount(channelId: $channelId) {
            rows {
                categoryId
                count
            }
        }
    }
`;

const GET_PRODUCT_HIERARCHY_ASSORTMENT_COUNT = gql`
    query getAssortmentCountByProductHierarchy($categoryId: Int!){
        productHierarchyAssortmentCount(categoryId: $categoryId) {
            brands {
                id
                count
            }
            skuFamilies : productPacks {
                id
                count
            }
            productCategories {
                id
                count
            }
        }
    }
`;

const TOGGLE_CHANNEL_CATEGORY_SKUS = graphql(gql`
    mutation ($categoryId: Int!,
        $skuIds: [Int!],  $status: Boolean!) {
        createCategorySKUAssortment(categoryId: $categoryId, skuIds: $skuIds,
            status: $status
        ){
            rows
            count {
                categories {
                    categoryId
                    count
                }
                brands {
                    id
                    count
                }
                skuFamilies : productPacks {
                    id
                    count
                }
                productCategories {
                    id
                    count
                }
            }
        }
    }`, {
  props: ({ mutate }) => ({
    toggleCategorySKUAssortment: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getBrandDetails: GET_BRAND_DETAILS,
  getSkuDetails: GET_SKU_DETAILS,
  getMTBasicDetail: GET_MT_BASIC_DETAIL,
  getMTStockCount: GET_MT_STOCK_COUNT,
  getCatalogDetail: GET_CATALOG_DETAIL,
  getRouteVisitFrequencyList: GET_ROUTE_VISIT_FREQUENCY_LIST,
  getUsersList: GET_USERS_LIST,
  getCategoryList: GET_NEW_CHANNEL_LIST,
  getProductGroupDetails: GET_PRODUCT_GROUP_DETAILS,
  getSKUFamilyDetails: GET_SKU_FAMILY_DETAILS,
  getAssortmentCategoryCount: GET_ASSORTMENT_CATEGORY_COUNT,
  getAssortmentCountByProductHierarchy: GET_PRODUCT_HIERARCHY_ASSORTMENT_COUNT,
};

const mutation = {
  createMTStockDetails: CREATE_MT_SKUS,
  deleteMTStockDetail: DELETE_MT_SKU,
  toggleCategorySKUAssortment: TOGGLE_CHANNEL_CATEGORY_SKUS,
};

export {
  query, mutation, CREATE_MT_SKUS, DELETE_MT_SKU,
  GET_BRAND_DETAILS, GET_PRODUCT_GROUP_DETAILS,
  TOGGLE_CHANNEL_CATEGORY_SKUS,

};
