import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { breadCrumb } from './config';
import { PanelStyled } from '../../../../../common/configuration';
import PageHeader from '../../../../../base/PageHeader';
import JBP from './jbp';
import SBD from './SBD';
import DetailStyled from './DetailStyle';
import Fundamentals from './fundamentals';
import { has } from '../../../../../../utils/objectPrototypes';
import VGP from './VGP';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
};

const defaultProps = {
  serverResponseWaiting: false,
  location: {
    state: {},
  },
};

class Detail extends Component {
  constructor(props) {
    super(props);
    const { state = {} } = props.location;
    this.state = {
      activeTab: state.activeTab || 0,
      buId: has.call(props.match.params, 'id')
        ? parseInt(props.match.params.id, 10)
        : 0,
      buTitle: has.call(props.match.params, 'title')
        ? props.match.params.title
        : '',
    };
  }

  handleTabChange = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const { activeTab, buId, buTitle } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <DetailStyled>
        <div className="section-panel">
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                breadCrumb={breadCrumb}
                config={{ title: buTitle }}
              />
            </PanelStyled>
          </div>
          <div className="section-content section-tab table-present">
            <Tabs selectedIndex={activeTab} onSelect={tabIndex => this.handleTabChange(tabIndex)}>
              <TabList>
                <Tab>
                  <span className="tab-label">Fundamentals</span>
                </Tab>
                <Tab>
                  <span className="tab-label">JBP Templates</span>
                </Tab>
                <Tab>
                  <span className="tab-label">SBD Templates</span>
                </Tab>
                <Tab>
                  <span className="tab-label">VGP</span>
                </Tab>
              </TabList>
              <div className="tab-container">
                <TabPanel>
                  <section id="pending-tab">
                    <div id="detail-tab">
                      <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                        <Fundamentals buId={buId} loading={serverResponseWaiting} />
                      </div>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel>
                  <section id="pending-tab">
                    <div id="detail-tab">
                      <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                        <JBP
                          id={buId}
                          buTitle={buTitle}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel>
                  <section id="pending-tab">
                    <div id="detail-tab">
                      <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                        <SBD
                          id={buId}
                          buTitle={buTitle}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel>
                  <section id="pending-tab">
                    <div id="detail-tab">
                      <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                        <VGP
                          id={buId}
                          buTitle={buTitle}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </DetailStyled>
    );
  }
}
Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default Detail;
