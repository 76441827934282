import styled from 'styled-components';

export const ReportStyled = styled.div`
  height: 100vh;
  overflow-x: scroll;
  padding: 0 24px 24px 24px;

  .title-summary {
    font-size: 32px;
    font-weight: 700;
    color: #272833;
    margin: 32px 0 24px 0;
  }
`;
