import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button } from '../../../../../components';
import Popover from '../../../../../components/Popover/Popover';
import TableHeader from '../../../../base/TableHeader';
import { dateTimePresenter } from '../../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { SKU_STATUS } from '../../../../sales/orderProcessing/config';

const labelMappings = {
  orderId: 'orderId',
  date: 'date',
  MTOutlets: 'MTOutlets',
  channel: 'channel',
  category: 'category',
  SO: 'SO',
  amount: 'amount',
  notes: 'notes',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.orderId,
    title: 'Order ID',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Date',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 3,
    label: labelMappings.channel,
    title: 'Channel',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 4,
    label: labelMappings.MTOutlets,
    title: 'MT Outlets',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 5,
    label: labelMappings.category,
    title: 'Category',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 6,
    label: labelMappings.SO,
    title: 'SO',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 7,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
    sortable: true,
    className: 'text-right',
    align: true,
  },
  {
    id: 8,
    label: labelMappings.notes,
    title: 'Notes',
    action: true,
    show: true,
    sortable: false,
    className: '',
  },
];

const childLabelMappings = {
  sku: 'sku',
  quantity: 'quantity',
  rate: 'rate',
  stockStatus: 'stockStatus',
  value: 'value',
};

const childLabelConfig = [
  {
    id: 1,
    label: childLabelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 2,
    label: childLabelMappings.quantity,
    title: 'Quantity',
    align: true,
    show: true,
  },
  {
    id: 3,
    label: childLabelMappings.rate,
    title: 'Rate',
    show: true,
    align: true,
  },
  {
    id: 4,
    label: childLabelMappings.stockStatus,
    title: 'Stock Status',
    show: true,
  },
  {
    id: 5,
    label: childLabelMappings.value,
    title: 'Value',
    align: true,
    show: true,
  },
]

const getNotesFooter = (dse, date) => (
  <div>
    <span>{dse}</span>
    <span>{date}</span>
  </div>
);


const returnTableBody = (data, onRowClick) => {
  const table = {};
  table[labelMappings.orderId] = (
    <td
      onClick={() => onRowClick(data.callId)}
      key={`orderId-${data.callId}`}
    >
      {
        <span>
          <span className="tab-label">{data.callId}</span>
          <Badge light label={data.totalOrderCount} />
        </span>
      }
    </td>
  );
  table[labelMappings.date] = (
    <td
      key={`date-${data.callId}`}
      onClick={() => onRowClick(data.callId)}
    >
      {`${dateTimePresenter(data.callTime ? data.callTime.callStart : '')}`}
    </td>
  );

  table[labelMappings.channel] = (
    <td
      key={`channel-${data.callId}`}
      onClick={() => onRowClick(data.callId)}
    >
      {(data.RetailOutlet && data.RetailOutlet.Category && data.RetailOutlet.Category.Channel) ? data.RetailOutlet.Category.Channel.title : ''}
    </td>
  );

  table[labelMappings.MTOutlets] = (
    <td
      key={`mtOutlets-${data.callId}`}
      onClick={() => onRowClick(data.callId)}
    >
      {(data.RetailOutlet && data.RetailOutlet) ? data.RetailOutlet.title : ''}
    </td>
  );
  table[labelMappings.category] = (
    <td
      key={`category-${data.callId}`}
      onClick={() => onRowClick(data.callId)}
    >
      {(data.RetailOutlet && data.RetailOutlet.Category) ? data.RetailOutlet.Category.title : ''}
    </td>
  );

  table[labelMappings.SO] = (
    <td
      key={`so-${data.callId}`}
      onClick={() => onRowClick(data.callId)}
    >
      {data.User ? data.User.fullName : ''}
    </td>
  );
  table[labelMappings.amount] = (
    <td
      className="text-right"
      key={`amount-${data.callId}`}
      onClick={() => onRowClick(data.callId)}
    >
      {data.callOrderValue ? fixedFloatAndCommas(data.callOrderValue.netAmount) : ''}
    </td>
  );
  table[labelMappings.notes] = (
    <td
      key={`pop-over-${data.callId}`}
      onClick={event => event.stopPropagation()}
    >
      <div className="simple-popup">
        <Popover
          popoverIcon={<Button iconName="comments" disabled={!data.notes} />}
          disabled={!data.notes}
          headerTitle="Note"
          footer={getNotesFooter(data.User ? data.User.fullName : '', data.callDate)}
        >
          <span className="arrow-right" />
          {
            <span>
              {data.notes}
            </span>
          }
        </Popover>
      </div>
    </td>
  );

  return table;
};

const TableBody = ({ data, onRowClick }) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, onRowClick);

  return (
    <>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </>
  );
};

const TableHeaderConfig = ({ onHeaderClick }) =>
  (<TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />);


// Child Table Body

const ChildTableBody = (data) => {
  const table = {};
  table[childLabelMappings.sku] = <td key={`${data.id}-sku`}>{data.SKU ? data.SKU.title : ''}</td>;
  table[childLabelMappings.quantity] = <td key={`${data.id}-quantity`} className="text-right">{data.quantityInCases || ''}</td>;
  table[childLabelMappings.rate] = <td key={`${data.id}-rate`} className="text-right">{data.rateDetails ? data.rateDetails.rlp : null}</td>;
  table[childLabelMappings.stockStatus] = (
    <td key={`${data.id}-status`}>
      {data.stockStatus
        && (
          <span className={`stock-label ${data.stockStatus.toLowerCase()}`}>
            <span className="badge_border">
              <Badge light>{SKU_STATUS[data.stockStatus]}</Badge>
            </span>
          </span>
        )}
    </td>);
  table[childLabelMappings.value] = (
    <td key={`${data.id}-amount`} className="text-right">
      {fixedFloatAndCommas(data.amountDetails ? data.amountDetails.netAmount : 0)}
    </td>
  );

  return table;
};

const returnChildTableBody = (data) => {
  const tableBody = ChildTableBody(data);

  return (
    <>
      {childLabelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onRowClick: PropTypes.func,
};

TableBody.defaultProps = {
  onRowClick: () => null,
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func,
};

TableHeaderConfig.defaultProps = {
  onHeaderClick: () => null,
};

export { TableHeaderConfig as TableHeader, TableBody, childLabelConfig, childLabelMappings, returnChildTableBody };
