import styled from 'styled-components';

const DetailStyled = styled.div`
/* Create UI */
.create-top-section{
    background: #FFFFFF;
    padding: 48px;
    padding-bottom: 24px;
    label{
      display: inline-block;
      margin-bottom: 4px;
      color: #272833;
      font-size: 14px;
      font-weight: 600;
    }
    .total-point{
      line-height: 35px;
      font-size: 28px;
      font-weight: 600;
    }
    .pr-48{
      padding-right: 48px;
    }
    .fundamental-weightage{
      padding: 12px;
      border-radius: 4px;
      background: #F7F8F9;
      .sos{
        display: flex;
        margin-bottom: 9px;
        align-items: center;
        justify-content: space-between;
        &:last-child{
        margin-bottom: 0;
        }
        .form-input{
          width: 150px;
        }
        .placeholder{
          margin-bottom: 0;
        }
      }
      .form-error{
        width: 100%;
        text-align: right;
        margin-top: 0;
        margin-bottom: 6px;
}
      }
    }
  
  .create-bottom-section{
    background: #F7F8F9;
    padding: 24px 48px;
    .react-tabs__tab-list {
      padding: 0 0 16px;
      background: #F7F8F9;
    }
    .table-wrap{
      .placeholder{
        width: 100px;
        margin: 0;
      }
      .placeholder::after {
        top: 11px;
        font-size: 14px;
      }
      thead{
        line-height: 21px;
        font-size: 14px;
        color:#6B6C7E;
       span{
         color:#A7A9BC;
         font-size: 10px;
         line-height: 10px;
         svg{
           color:#E7E7ED;
           height: 10.5px;
           width: 10.5px;
           margin: 0 4px;
         }
       }
      }
      tbody {
      tr {
        td {
          &:last-child:not(:first-child) {
            .view{
    margin-right: 40px;
  }
            span {
              cursor: pointer;
              color: #6B6C7E;
              font-size: 14px;
            }

            svg.icon {
              height: 14px;
              width: 14px;
              margin-right: 9px;
            }
          }

          &:first-child{
            border-left:0;
          }
        }
      }

      tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(-90deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #004AD7;
        }
      }

      td:first-child {
        > span {
          position: relative;
          padding-left: 26px;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }

    }
    }
    }
  }
  .sbd-template-footer{
    background: #FFFFFF;
    margin: 0px -48px;
    padding: 24px 48px;
    margin-top: 24px;
    button{
      margin-top: 24px;
    }
    .sosku-info{
      margin-top: 35px;
      color: #A7A9BC;
      font-size: 10px;
      line-height: 10px;
      svg{
        color:#E7E7ED;
        height: 10.5px;
        width: 10.5px;
        margin: 0 4px;
      }
    }
  }
  .skuFamily{
    background: #f1f2f5;
    padding: 16px;
  }
.create-ui-inner{
  padding: 0;
}
`;

export default DetailStyled;
