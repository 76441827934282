import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import { crudSuccess, formConfig, townIdIncludedInUser } from './config';
import { refValidator } from '../../../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { dropdownChange, inputChange } from '../../../../../utils/formHandlers';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../../data/services';
import { ROUTE_DETAILS } from '../../../../../data/enums/Route';
import { OFFSET } from '../../../../../data/enums/GeneralConstants';
import { USER_ROLE } from '../../../../../data/enums';
import { LINE_TYPE } from '../../../../../data/enums/Status';
import { getFromLocalStorage } from '../../../../common/HelperFunctions';
import { JWT } from '../../../../../environment';

const propTypes = {
  getRouteDetail: PropTypes.func.isRequired,
  updateRoute: PropTypes.func.isRequired,
  createRoute: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  getLines: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getTitle: PropTypes.func,
  getassociatedDistributors: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  getTitle: () => null,
  getassociatedDistributors: () => null,
};

const routeData = {
  title: '',
  active: true,
  townId: null,
  assignedLines: [
    {
      id: 0,
      lineId: 0,
      type: LINE_TYPE.DEFAULT,
      assignedTo: '',
      visitType: '',
      deliveryType: '',
      // visitCategory: data.VisitCategory.title,
      status: null,
      visitFrequency: '',
      distributorId: '',
      distributorList: [],
    },
  ],
  enableLineSegmentation: false,
};

class Detail extends Component {
  constructor(props) {
    super(props);
    const routeSegmentation =
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS) &&
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS).routeSegmentation;

    this.state = {
      data: {
        // eslint-disable-next-line react/prop-types
        id: props.id ? props.id : null,
        ...routeData,
      },
      menu: {
        townList: [],
        dseList: [],
      },
      enableFormValidation: false,
      formReference: formConfig.refsObj,
      crudMode: props.id ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
      lines: [],
      linesData: [],
      enableRouteSegmentation: routeSegmentation,
    };
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    const { data } = this.state;
    if (data.id) {
      this.getRouteInfo();
    }
    this.loadDataForDropDown();
  }

  getRouteInfo() {
    const { data } = this.state;
    const { getRouteDetail, displayAlert } = this.props;
    getRouteDetail(
      {
        id: data.id.toString(),
      },
      {
        handleSuccess: response => {
          // eslint-disable-next-line max-len
          const formattedDetail = crudSuccess[EVENT_OPERATION.UPDATE].objectMapper(response.data.routes.rows[0]);
          this.setState(
            {
              data: formattedDetail,
              linesData: formattedDetail.assignedLines
                .map(d => ({
                  id: d.lineId,
                  title: d.title,
                  type: d.type,
                }))
                .filter(d => d.type !== LINE_TYPE.DEFAULT),
            },
            () => {
              this.fetchDistributorList();
            },
          );
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  fetchDistributorList = () => {
    const { data } = this.state;
    return data.assignedLines.map((d, index) => this.getDistributorList(d.assignedTo, index));
  };

  loadDSEList = () => {
    // load all the DSE List.
    const { getUserList } = this.props;

    getUserList(
      {
        offset: OFFSET,
        filter: {
          filters: [
            {
              column: 'role_id',
              value: [USER_ROLE.DSE.toString(), USER_ROLE.BRANCH_HEAD.toString(), USER_ROLE.VERIFIER.toString()],
            },
          ],
        },
      },
      {
        handleSuccess: response => {
          const { menu } = this.state;

          menu.dseList = response.data.users.rows || [];
          this.setState({ menu });
        },
      },
    );
  };

  lineLists = () => {
    const { getLines } = this.props;
    getLines(
      {},
      {
        handleSuccess: response => {
          this.setState({
            lines: response.data.lines.rows.filter(d => d.type !== LINE_TYPE.DEFAULT) || [],
          });
        },
      },
    );
  };

  onCheckboxClick = (e, value) => {
    const { linesData, data, menu } = this.state;

    const filteredDSEList = menu.dseList.filter(user => townIdIncludedInUser(data.townId, user.Town));

    const { id, title } = value;
    if (e.target.checked) {
      if (linesData.length <= filteredDSEList.length - 1) {
        this.setState({
          linesData: [...linesData, ...[{ id, title }]],
          data: {
            ...data,
            assignedLines: [
              ...data.assignedLines.filter(d => d.type !== LINE_TYPE.DEFAULT),
              ...[
                {
                  ...routeData.assignedLines[0],
                  lineId: id,
                  type: LINE_TYPE.NORMAL,
                },
              ],
            ],
          },
        });
      } else {
        const { displayAlert } = this.props;
        const length = filteredDSEList.length;
        const message =
          length > 0 ? `Only ${length} user${length > 1 ? 's are' : 'is'} available` : 'No users available';
        displayAlert(ALERT_TYPE.WARNING, message);
      }
    } else {
      this.setState({
        linesData: linesData.filter(line => line.id !== id),
        data: {
          ...data,
          assignedLines: data.assignedLines.filter(line => line.lineId !== id),
        },
      });
    }
  };

  loadDataForDropDown = () => {
    const { menu, enableRouteSegmentation } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.TOWN).then(response => {
      menu.townList = response;
      this.setState({ menu });
    });
    if (enableRouteSegmentation) {
      this.lineLists();
    }
    this.loadDSEList();
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const updatedDetails = inputChange(data, event, firstParam, paramList);
    this.setState({ data: updatedDetails }, () => {
      const { data: updateData } = this.state;
      if (firstParam === 'enableLineSegmentation' && updateData.enableLineSegmentation === false) {
        this.setState({
          linesData: [],
          data: {
            ...updateData,
            assignedLines: routeData.assignedLines,
          },
        });
      }
    });
  };

  getMappedData = id => {
    const { data } = this.state;
    const mappedAssignedLine =
      id !== data.townId
        ? data.assignedLines.map(d => ({
            ...d,
            status: true,
          }))
        : data.assignedLines.map(d => ({
            ...d,
            assignedTo: '',
            status: true,
          }));
    const newData = {
      ...data,
      assignedLines: mappedAssignedLine,
    };
    return newData;
  };

  getDistributorList = (id, index) => {
    const { data, crudMode } = this.state;
    const { getassociatedDistributors } = this.props;
    getassociatedDistributors(
      {
        userId: id,
      },
      {
        handleSuccess: response => {
          const distributor = response.data.associatedDistributorsForUser;
          const distributorList = distributor.map(a => a.Distributor);
          data.assignedLines[index].distributorList = distributorList;
          data.assignedLines[index].assignedTo = id;
          if (crudMode === EVENT_OPERATION.UPDATE) {
            data.assignedLines[index].status = true;
          }
          this.setState({ data });
          if (distributorList.length === 1) {
            data.assignedLines[index].assignedTo = id;
            data.assignedLines[index].distributorId = distributorList[0].id;
            this.setState({ data });
          }
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  // eslint-disable-next-line no-unused-vars
  handleDropDownChange = (value, parameterRef = [], id) => {
    const { data, crudMode } = this.state;
    const actualLinesData = crudMode === EVENT_OPERATION.CREATE ? data : this.getMappedData(id);
    const updatedData = dropdownChange(actualLinesData, parameterRef, value);
    if (parameterRef[0] === 'Channel') {
      updatedData.Category.id = '';
      updatedData.Category.title = '';
    }
    if (parameterRef[2] === 'assignedTo') {
      this.getDistributorList(value, parameterRef[1]);
    }
    this.setState({ data: updatedData });
  };

  apiRequestTransformer = data => {
    const { crudMode } = this.state;
    const mappedLines = data.assignedLines.map(lineData => ({
      id: lineData.id,
      lineId: lineData.lineId,
      type: lineData.type,
      assignedTo: lineData.assignedTo,
      visitType: lineData.visitType,
      deliveryType: lineData.deliveryType,
      status: lineData.status,
      visitFrequency: lineData.visitFrequency,
      distributorId: lineData.distributorId,
    }));
    const lines =
      crudMode === EVENT_OPERATION.CREATE
        ? mappedLines.map(({ id, ...lines }) => ({
            ...lines,
          }))
        : mappedLines.map(({ title, ...lines }) => ({
            ...lines,
          }));
    return {
      title: data.title,
      townId: data.townId,
      active: true,
      assignedLines: lines,
    };
  };

  handleButtonSubmit = () => {
    const { formReference, data, crudMode, linesData } = this.state;
    const { updateRoute, createRoute, displayAlert, getTitle } = this.props;
    const formValidation = refValidator(formReference);
    if (!formValidation) {
      this.setState({ enableFormValidation: true });
    } else if (data.enableLineSegmentation && linesData.length === 0) {
      displayAlert(ALERT_TYPE.WARNING, 'No Lines Selected');
    } else {
      const formattedData = this.apiRequestTransformer(data);

      if (crudMode === EVENT_OPERATION.CREATE) {
        createRoute(
          { input: formattedData },
          {
            handleSuccess: res => {
              displayAlert(ALERT_TYPE.SUCCESS, 'Route Created');
              window.location.href = `/${ROUTE_DETAILS}/${res.data.createRoute.id}`;
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }

      if (crudMode === EVENT_OPERATION.UPDATE) {
        updateRoute(
          {
            id: data.id,
            input: formattedData,
          },
          {
            handleSuccess: response => {
              // eslint-disable-next-line max-len
              const formattedDetail = crudSuccess[EVENT_OPERATION.UPDATE].objectMapper(response.data.updateRoute);
              this.setState(
                {
                  data: formattedDetail,
                  crudMode: EVENT_OPERATION.READ,
                  linesData: formattedDetail.assignedLines.map(d => ({
                    id: d.lineId,
                    title: d.title,
                    type: d.type,
                  })),
                },
                () => {
                  getTitle(data.title, 'updateTitle');
                  this.fetchDistributorList();
                },
              );
              displayAlert(ALERT_TYPE.SUCCESS, 'Route Updated');
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleButtonCancel = () => {
    const { crudMode, data } = this.state;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: {
          ...routeData,
          id: data.id,
        },
      });
    } else {
      this.setState({
        crudMode: EVENT_OPERATION.READ,
      });
      this.getRouteInfo();
    }
  };

  handleEditIconClick = () => {
    this.setState({ crudMode: EVENT_OPERATION.UPDATE }, () => null);
  };

  render() {
    const { data, menu, crudMode, formReference, enableFormValidation, enableRouteSegmentation, lines, linesData } =
      this.state;

    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
        <View
          data={data}
          menu={menu}
          crudMode={crudMode}
          refsObj={formReference}
          loading={serverResponseWaiting}
          onInputChange={this.handleInputChange}
          enableErrorDisplay={enableFormValidation}
          handleButtonSubmit={this.handleButtonSubmit}
          handleButtonCancel={this.handleButtonCancel}
          onDropDownChange={this.handleDropDownChange}
          handleEditIconClick={this.handleEditIconClick}
          enableRouteSegmentation={enableRouteSegmentation}
          lines={lines}
          onCheckboxClick={this.onCheckboxClick}
          linesData={linesData}
        />
      </Fragment>
    );
  }
}

Detail.propTypes = propTypes;
Detail.defaultProps = defaultProps;

export { Detail };

export default withAlert()(Detail);
