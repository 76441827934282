import styled from 'styled-components';

const ReplenishmentModelWrapper = styled.div`

//radio-btn
.permission-choose{
  display: flex;
   > label{
         font-size: 14px;
         line-height: 21px;
         color: #272833;
         font-weight: 500;
         width: calc(15.77% - 4px);
         margin: 0;
         margin-right: 16px;
         margin-top: 2px;
         padding-left: 4px;
        
    }
    > div{
           margin-bottom: 24px;
           .radio-list{
            display: flex;
            margin-right: 24px;
            cursor: pointer;
            align-items: center;
              input{
                margin: 0px;
                height: 16px;
                width: 16px;
                cursor: pointer;
              }
              .text{
                font-weight: 500;
                line-height: 21px;
              }
           }
      }
   
}

.form-input-wrap {

  &.month-days {
    width: 41%;

    .form-input label {
      width: 39%;
    } 
  }
 
}

.reapeat__days{
  display:flex;
  width: 126%;
  > label {
        width: 13.45%;
        color: #272833;
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
        padding-left: 4px;
  }
  .checkbox-list {
    margin-bottom: 24px;
    margin-top: 8px;
    > div {
      > label{
        margin-right: 10px;
        font-weight : 600;
      }
    }
  }
}

.time {
  display : flex;
  width: 100%;
  margin-bottom: 32px;

  .time-label { 
    width: 209%;
  }

  > label {
    width: calc(166.57% - 10px);
    color: #272833;
    font-size: 14px;
    font-weight: 500;
    margin-top: 13px;
    padding-left: 4px;
  }
  .form-input{
    width:50%
    margin-bottom: 0px;
  }
  .form-select-input{
    > label {
      display: none;
    }
    > div {
          width: 100%;
          margin-bottom: 0px;
    }
    }
    .time__hours{
        display:flex;
        flex-direction: column;
         width:100%;
       .form-input{
          width:calc(100% - 10px);
          margin-right: 10px;
          margin-bottom: 0px;
        }
        .form-select-input{
           margin-bottom: 0px;
           .zindex-2__value-container{
            padding: 2px 13px;
           }
        }
        > label {
          font-size: 10px;
          color: #A7A9BC;
          margin-top: 6px;
          margin-left: 16px;
          line-height: 10px;
        }
    }
}
`;

export default ReplenishmentModelWrapper;
