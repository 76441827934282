
import { compose } from 'recompose';
import Create from './Create';
import {
  query, mutation, CREATE_SBD_TEMPLATE,
} from './API';
import withRequestTracker from '../../../../../../../../utils/composition/withRequestTracker';
import { GET_DETAIL_JBP_TEMPLATE } from '../../jbp/details/API';

const ComposedCreate = compose(
  CREATE_SBD_TEMPLATE,
  withRequestTracker({
    query,
    mutation,
    getDetailsForJBPTemplate: GET_DETAIL_JBP_TEMPLATE,
  }),
)(Create);

export default ComposedCreate;
