import { fixedFloatAndCommas } from '../../utils/conversion';
import { currentYear } from '../../utils/date';

const localStorageRosiaDetail = 'rosia-detail';

const dimension = {
  height: 50,
  width: 300,
};

const weekCustomize = week => {
  return ` Week ${week}`;
};

const fillColor = brand => {
  const brandColors = {
    bbsmbgColor: '#1083f6',
    bbsmbaseBgColor: '#f0f5ff',

    bigmartbgColor: '#f27024',
    bigmartbaseBgColor: '#f9eecf',

    saleswaysbgColor: '#43c187',
    saleswaysbaseBgColor: '#d9f2e7',
  };

  return brandColors[brand];
};

const reverseString = str => str?.split(' ')?.reverse()?.join(' ');

const AuditWiseOutletChannelPercent = AUDIT_PERIOD_WISE => {
  const RscoreP3M = [];
  for (const property in AUDIT_PERIOD_WISE) {
    const periodWise = AUDIT_PERIOD_WISE[property];
    const outlet_channel_percent = _(periodWise)
      .groupBy('outlet_channel')
      .map((item, fundamental) => ({
        fundamental: fundamental,
        percent: fixedFloatAndCommas((_.sumBy(item, 'point_scored') / _.sumBy(item, 'points_allocated')) * 100),
      }))
      .value();
    const outlet = outlet_channel_percent.reduce((acc, obj) => {
      const key = obj['fundamental'];
      return { ...acc, [key]: obj['percent'] };
    }, {});
    RscoreP3M.push({ ...outlet, week: reverseString(property) });
  }
  // const RSCORE_P3M_SORT_WEEKWISE = _.orderBy(RscoreP3M, ['week'], ['asc']);
  const RSCORE_P3M_SORT_WEEKWISE = RscoreP3M?.sort((a, b) => {
    const weekA = a.week.split(' ');
    const weekB = b.week.split(' ');
    if (weekA[0] === weekB[0]) {
      return parseInt(weekA[1]) - parseInt(weekB[1]);
    } else {
      return parseInt(weekA[0]) - parseInt(weekB[0]);
    }
  });
  return RSCORE_P3M_SORT_WEEKWISE;
};

const RSCORE_P3M_SORT_WEEKWISE_KEYS = array => {
  let keys = [];
  for (const item of array) {
    for (const property in item) {
      if (property !== 'week' && !keys?.includes(property)) {
        keys.push(property);
      }
    }
  }
  return keys;
};

const getLogo = bu => {
  const logo = {
    britannia: '/image/bu-icon/britannia.svg',
    kellogg: '/image/bu-icon/kellogg.svg',
    procter: '/image/bu-icon/p&g.svg',
    hfd: '/image/bu-icon/hfd.svg',
    mars: '/image/bu-icon/mars.svg',
    pringles: '/image/bu-icon/pringles.svg',
    ferrero: '/image/bu-icon/ferrero.svg',
    sc: '/image/bu-icon/scJohnson.svg',
  };
  return logo[bu];
};

const trackCompliance = compliance => {
  const complianceClassName =
    compliance >= 80 ? 'compliance-above' : compliance <= 60 ? 'compliance-under' : 'compliance-middle';
  return complianceClassName;
};

const getbuIds = () => {
  const ids =
    JSON.parse(localStorage.getItem(localStorageRosiaDetail))?.UserPrinciple?.length > 0
      ? JSON.parse(localStorage.getItem(localStorageRosiaDetail))?.UserPrinciple?.map(item => item?.id)
      : [];
  return ids;
};

const fundamentalList = {
  SHARE_OF_AVAILABILITY: 'Share of availability',
  ON_SHELF_AVAILABILITY: 'On shelf availability',
  SHARE_OF_SHELF: 'Share of shelf',
  SHARE_OF_DISPLAY: 'Share of display',
};

const isEmptyObject = obj => {
  return JSON.stringify(obj) === '{}';
};

const modifyWeekYearWise = arr => {
  const newData = arr?.map(entry => {
    const modifiedWeek = entry?.week
      ?.replace(currentYear, `${currentYear}/`)
      ?.replace(currentYear - 1, `${currentYear - 1}/`)
      ?.replace(' ', '');
    return {
      ...entry,
      week: modifiedWeek,
    };
  });
  return newData;
};

const capitalizeFirstLetter = letter => letter?.charAt(0).toUpperCase() + letter?.slice(1).toLowerCase();

const capitalizeAllFirstLetter = data =>
  data
    ?.split(' ')
    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');

const getFundamentalTitle = word => {
  const label = word?.split(' ');
  const displayWord = label[2];
  return displayWord;
};

export {
  dimension,
  fundamentalList,
  weekCustomize,
  AuditWiseOutletChannelPercent,
  RSCORE_P3M_SORT_WEEKWISE_KEYS,
  fillColor,
  getLogo,
  trackCompliance,
  getbuIds,
  isEmptyObject,
  modifyWeekYearWise,
  reverseString,
  capitalizeFirstLetter,
  getFundamentalTitle,
  capitalizeAllFirstLetter,
};
