import styled from 'styled-components';

export const LinechartStyled = styled.div`
  padding-left: 30px;
  .linechart-title {
    font-size: 16px;
    font-weight: 600;
    margin: 30px 0;
    color: #8993a4;
  }
  .linechart-wrap {
    position: relative;
    /* height: ${props => props.height + 25}px;
    width: ${props => props.width + 45}px; */
    padding-left: 30px;

    .linechart-xaxis {
      position: absolute;
      left: 0;
      bottom: 50%;
      transform: rotate(-90deg);
      font-weight: 400;
      font-size: 14px;
      color: #6b6c7e;
    }

    .linechart-yaxis {
      position: absolute;
      bottom: 0;
      left: 50%;
      font-weight: 400;
      font-size: 14px;
      color: #6b6c7e;
      /* margin-bottom: -20px; */
    }
  }

  .custom-tooltip {
    background-color: #ffffff;
    height: 62px;
    width: 129px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    padding: 8px 12px 8px 12px;
    outline: none;
    border-radius: 4px;
    .label {
      color: #6b6c7e;
      font-size: 14px;
      font-weight: 400;
    }
    .label-percent {
      color: #272833;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .recharts-cartesian-axis-tick-value {
    tspan {
      fill: #030913;
      font-size: 12px;
      font-weight: 600;
    }
  }

  /* .recharts-cartesian-axis-tick-value {
    tspan {
      fill: #030913;
    }
  } */

  .recharts-tooltip-wrapper {
    border: none;
    outline: none;
    fill: red;
    min-width: 129px;
    height: 62px;
    border-radius: 12px !important;
  }

  .recharts-default-tooltip {
    border-radius: 4px;
    border: none !important;
    padding: 8px 12px 8px 12px;
  }

  .recharts-tooltip-label {
    color: #6b6c7e;
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 2px;
  }

  .recharts-tooltip-item-list {
    fill: #272833;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 0px;

    .recharts-tooltip-item {
      color: #272833 !important;
    }
  }

  .recharts-layer {
    line {
      display: none;
    }
  }

  .recharts-legend-item-text {
    font-size: 15px;
  }
`;
