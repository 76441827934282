import React from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../utils/composition/withLoading';
import { Pagination } from '../../../v4/components';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import ReportTableStyled from '../ReportTableStyled';
import withImageSlider from '../../../utils/composition/withImageSlider';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onHeaderClick: PropTypes.func,
  onIconClick: PropTypes.func,
  tableConfig: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  onHeaderClick: () => null,
  tableConfig: {},
};

const View = ({ ...props }) => {
  const { data, onPageChange, pagination, onHeaderClick, handleSliderOpen } = props;

  return (
    <ReportTableStyled>
      <div className='table-wrap'>
        <table>
          {TableHeader({ onHeaderClick })}
          <tbody>{data.list.map((productivity,index) => TableBody({ data: productivity,handleSliderOpen,index }))}</tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </ReportTableStyled>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const RscoreAuditWithErrorAndLoading = withLoading(View);

const RscoreAuditViewWithImageSlider = withImageSlider(RscoreAuditWithErrorAndLoading);

export default RscoreAuditViewWithImageSlider;
