import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_ROUTE_VISIT_FREQUENCY_LIST } from '../../common/GqlAPI';

const GET_MT_LIST = gql`
    query getMTStockList($offset: Int, $limit: Int, $filter: FilterInput) {
        getMTStockList(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                id
                title
                count_days
                countskus
                assigned_to
            }
            count
        }
    }
`;
const GET_Users = gql`
query getUsers ($limit: Int, $offset: Int, $filter: FilterInput) {
    users(limit: $limit, offset: $offset, filter: $filter) {
      rows {
         id
        fullName
      }
    }
  }
`;

const GET_USERS_LIST = gql`
        query users($offset: Int, $limit: Int, $filter: FilterInput){
            users(offset: $offset, limit: $limit, filter: $filter){
            rows{
                id
                firstName
                lastName
                fullName
                roleId
                parentUserId
            }
            }
        }
`;

const DELETE_MT_STOCK = graphql(gql`
    mutation deleteMTStock($id: Int!) {
        deleteMTStock(id:$id){
            id
            title
            assigned_to
            count_days
            active
        }
    }`, {
  props: ({ mutate }) => ({
    deleteMTStock: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getMTListWithTitle: GET_MT_LIST,
  getRouteVisitFrequencyList: GET_ROUTE_VISIT_FREQUENCY_LIST,
  getUsersList: GET_USERS_LIST,
};

const mutation = {
  deleteMTStock: DELETE_MT_STOCK,
};

export {
  query, GET_USERS_LIST, GET_ROUTE_VISIT_FREQUENCY_LIST, DELETE_MT_STOCK, mutation, GET_MT_LIST,
};
