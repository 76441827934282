import styled from 'styled-components';

export const BrandChainStyled = styled.div`
  .bbsm-title {
    padding-top: 25px;
    font-size: 24px;
    color: #272833;
    font-weight: 700;
  }
  .bbsm-wrap {
    border: 1px solid #e7e7ed;
    border-radius: 6px;
    margin: 20px 0;
    padding-bottom: 25px;

    .bbsm-row {
      display: flex;
      gap: 15px;
      justify-content: flex-start;
      border-radius: 6px;
    }
  }

  .view-images {
    color: #e32c5f;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    margin-top: -18px;
    margin-left: 10px;
  }

  .bbsm-individual {
    width: 46vw;
    height: 70%;
    /* overflow-x: scroll; */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .bbsm-individual::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .bbsm-individual {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;
