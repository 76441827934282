
import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../../../utils/paginatedListUtils';
import { normalPresentor } from '../../../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';

const labelMappings = {
  sn: 'sn',
  sku: 'sku',
  brand: 'brand',
  quantity_cs: 'quantity_cs',
  quantity_ps: 'quantity_ps',
  rate: 'rate',
  amount: 'amount',
  dicount: 'discount',
  net_amount: 'net_amount',
};

const labelConfig = [{
  id: 1,
  label: labelMappings.sn,
  title: 'SN',
  classNames: 'font-bold',
  show: true,
},
{
  id: 2,
  label: labelMappings.sku,
  title: 'SKU',
  classNames: 'font-bold',
  show: true,
},
{
  id: 3,
  label: labelMappings.quantity_cs,
  title: 'Qty (Cs)',
  show: true,
  classNames: 'text-right font-bold',
},
{
  id: 4,
  label: labelMappings.quantity_ps,
  title: 'Qty (Pcs)',
  show: true,
  classNames: 'text-right font-bold',
},
{
  id: 5,
  label: labelMappings.rate,
  title: 'Rate',
  show: true,
  classNames: 'text-right font-bold',
},
{
  id: 6,
  label: labelMappings.amount,
  title: 'Amount',
  show: true,
  classNames: 'text-right font-bold',
},
{
  id: 7,
  label: labelMappings.discount,
  title: 'Discount',
  show: true,
  classNames: 'text-right font-bold',
},
{
  id: 8,
  label: labelMappings.net_amount,
  title: 'Net Amount',
  show: true,
  classNames: 'text-right font-bold',
},
];


const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};
  table[labelMappings.sn] = <td key={`${data.id}- id`}>{data.index + 1 || ''}</td>;
  table[labelMappings.sku] = <td key={`${data.id}- title`}>{data.sku}</td>;
  table[labelMappings.quantity_cs] = (
    <td key={`${data.id}-quantity_cs`} className="text-right">
      <p>{`${data.cases}cs`}</p>
    </td>
  );
  table[labelMappings.quantity_ps] = (
    <td key={`${data.id}-quantity_ps`} className="text-right">
      <p>{`${data.pieces}pcs`}</p>
    </td>
  );
  table[labelMappings.rate] = <td key={`${data.id}-rate`} className="text-right">{fixedFloatAndCommas(data.rate_details && data.rate_details.rlp)}</td>;
  table[labelMappings.amount] = <td key={`${data.id}-amount`} className="text-right">{fixedFloatAndCommas(data.amount_details && data.amount_details.grossAmount)}</td>;
  table[labelMappings.discount] = <td key={`${data.id}-discount`} className="text-right">{fixedFloatAndCommas(data.amount_details && data.amount_details.discountAmount)}</td>;
  table[labelMappings.net_amount] = <td key={`${data.id}-net_amount`} className="text-right font-bold">{fixedFloatAndCommas(data.amount_details && data.amount_details.netAmount)}</td>;

  return table;
};
const returnTitle = (data) => {
  const table = {};
  table[labelMappings.sn] = <td key={`${data.id}- id`} />;
  table[labelMappings.sku] = <td key={`${data.id}- sku`} className="font-bold brand-td">{data.brand}</td>;
  table[labelMappings.quantity_cs] = <td key={`${data.id}- id`} />;
  table[labelMappings.quantity_ps] = <td key={`${data.id}- id`} />;
  table[labelMappings.rate] = <td key={`${data.id}- id`} />;
  table[labelMappings.amount] = <td key={`${data.id}- id`} />;
  table[labelMappings.discount] = <td key={`${data.id}- id`} />;
  table[labelMappings.net_amount] = <td key={`${data.id}- id`} />;
  return table;
};

const TableBody = ({
  data, onRowClick,
}) => {
  const tableBody = data.sku_id > 10000 ? returnTitle(data) : returnTableBody(data);
  return (
    <tr
      className="cursor-pointer"
      key={`prchaseOrder-${data.id}`}
      onClick={() => { onRowClick(data.id); }}
    >
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};


export {
  TableHeader,
  TableBody,
};
