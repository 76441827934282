import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../../../../common/GqlAPI';

const LEDGER_FRAGMENT = gql`
  fragment LedgerFragment on Customer {
    id
    customerId
    title
    address
    panNumber
    phoneNumber
    creditLimit
    creditDay
    outstandingAmount(distributorId: $distributorId, retailOutletId: $retailOutletId)
    transactionValid
    type
    openingBalance
    openingBalanceType
    distributor: Distributor {
      id
      title
    }
    billingLedgerId
  }
`;

const GET_LEDGERS = gql`
  query getLedgers($offset: Int, $limit: Int, $filter: FilterInput, $retailOutletId: Int, $distributorId: Int) {
    customers(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...LedgerFragment
      }
      count
    }
  }
  ${LEDGER_FRAGMENT}
`;

const query = {
  getLedgers: GET_LEDGERS,
};

const mutation = {
  toggleState: TOGGLE_STATE,
};

export {
  query, mutation, GET_LEDGERS, TOGGLE_STATE
};
