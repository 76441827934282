import { compose } from 'recompose';
import Detail from './Detail';
import { query } from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposetDetail = compose(
  withRequestTracker({
    query,
  }),
)(Detail);

export default ComposetDetail;
