import React from 'react';
import PropTypes from 'prop-types';
import BasicDetails from './BasicDetails';
import VGPStyled from '../VGPStyle';
import { AutoComplete } from '../../../../../../../../components';
import withLoading from '../../../../../../../../utils/composition/withLoading';

const propTypes = {
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  brandList: PropTypes.arrayOf(PropTypes.object),
  update: PropTypes.shape({
    status: PropTypes.bool,
    type: PropTypes.string,
  }),
  sbdData: PropTypes.shape({
    basicData: PropTypes.object,
    brandIds: PropTypes.array,
  }),
  menu: PropTypes.shape({
    channel: PropTypes.array,
    category: PropTypes.array,
    auditType: PropTypes.array,
  }),
  enableErrorDisplay: PropTypes.bool,
  serverResponseWaiting: PropTypes.bool.isRequired,
  refsObj: PropTypes.object,
};

const defaultProps = {
  brandList: [],
  enableErrorDisplay: false,
  update: {
    status: false,
    type: 'CREATE',
  },
};
const View = (props) => {
  const { sbdData, menu, update, onInputChange, onDropDownChange, onAutoCompleteChange, brandList, refsObj, enableErrorDisplay, } = props;
  return (
    <VGPStyled className={!update.status ? 'disabled' : null}>
      <BasicDetails
        sbdData={sbdData}
        menu={menu}
        onInputChange={onInputChange}
        onDropDownChange={onDropDownChange}
        refsObj={refsObj}
        enableErrorDisplay={enableErrorDisplay}
        update={update}
      />
      <div className='brand-select'>
        <div className='auto-complete'>
          <AutoComplete
            name='subDIdList'
            labelContent='Brand'
            dropDownList={brandList}
            placeholder='Search'
            selectedDropDowns={sbdData.brandIds}
            onDropDownSelection={onAutoCompleteChange}
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
          />
        </div>
      </div>
    </VGPStyled>
  );
};


View.propTypes = propTypes;

View.defaultProps = defaultProps;


const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
