import React from 'react';
import PropTypes from 'prop-types';
import { normalPresentor } from '../../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { menuAction, tableData } from '../../../../../utils/paginatedListUtils';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const labelMappings = {
  prid: 'prid',
  date: 'date',
  channel: 'channel',
  amount: 'amount',
};

const menuConfigList = [
  {
    title: 'Email',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Download',
    permissionDerivedBy: 'read',
    type: EVENT_OPERATION.DOWNLOAD,
  },
  // {
  //   title: 'Print',
  //   permissionDerivedBy: 'read',
  //   type: EVENT_OPERATION.PRINT,
  // },
];

const labelConfig = [
  {
    id: 2,
    label: labelMappings.prid,
    title: 'PRID',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.channel,
    title: 'Channel',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 5,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align, label.classNames)
        : null))}
    </tr>
  </thead>
);

const returnTableBody = (
  data,
  onIconClick,
  permission,
  withOutIcon,
) => {
  const table = {};

  table[labelMappings.prid] = (
    <td key={`purchase-requisition-${data.id}-prid`}>{data.id}</td>
  );
  table[labelMappings.date] = (
    <td key={`purchase-requisition-${data.id}-date`}>{normalPresentor(data.requisitionDate)}</td>
  );
  table[labelMappings.channel] = (
    <td key={`purchase-requisition-${data.id}-channel`}>{data.Channel ? data.Channel.title : ''}</td>
  );
  table[labelMappings.amount] = (
    <td className="text-right" key={`purchase-requisition-${data.id}-amount`}>{fixedFloatAndCommas(data.amountDetails ? data.amountDetails.netAmount : 0)}</td>
  );
  table[labelMappings.actionHolders] = (
    <td
      className="text-right simple-popup-actions"
      key={`purchase-requisition-${data.id}-action`}
      onClick={e => e.stopPropagation()}
    >
      {menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        data,
        permission,
        withOutIcon,
      )}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
  onIconClick,
  permission,
  onTableRowClick,
  withOutIcon,
}) => {
  const tableBody = returnTableBody(
    data,
    onIconClick,
    permission,
    onTableRowClick,
    withOutIcon,
  );

  return (
    <tr
      key={`user-${data.id}`}
      style={{ cursor: 'pointer' }}
      onClick={() => onTableRowClick(data.id, EVENT_OPERATION.UPDATE)}
    >
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onTableRowClick: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  onToggleActiveStatus: PropTypes.func.isRequired,
  onTableDataClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
