import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../../../../utils/paginatedListUtils';
import { normalPresentor } from '../../../../../../../utils/date';
import { Switch } from '../../../../../../../components';

const labelMappings = {
  jbpTemplate: 'jbpTemplate',
  startDate: 'startDate',
  endDate: 'endDate',
  status: 'status',
};
const labelConfig = [
  {
    id: 1,
    label: labelMappings.jbpTemplate,
    title: 'JBP Template',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.startDate,
    title: 'Start Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.endDate,
    title: 'End Date',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
];

const TableHeader = () => (
  <thead>
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align) : null
      ))}
    </tr>
  </thead>
);
const returnTableBody = (data, onToggleStatus, onRowClick) => {
  const table = {};

  table[labelMappings.jbpTemplate] = (
    <td key={`jbp-${data.id}-jbpTemplate`} onClick={() => onRowClick(data.id)}>{data.title}</td>
  );
  table[labelMappings.startDate] = (
    <td key={`jbp-${data.id}-startDate`} onClick={() => onRowClick(data.id)}>{normalPresentor(data.start_date)}</td>
  );
  table[labelMappings.endDate] = (
    <td key={`jbp-${data.id}-endDate`} onClick={() => onRowClick(data.id)}>{normalPresentor(data.end_date)}</td>
  );
  table[labelMappings.status] = (
    <td key={`jbp-${data.id}-active`}>
      <Switch
        checked={data.active}
        onToggle={(active, e) => { e.stopPropagation(); onToggleStatus(active, e, data.id); }}
      />
    </td>
  );
  return table;
};

const TableBody = ({
  data, onToggleStatus, onRowClick,
}) => {
  const tableBody = returnTableBody(data, onToggleStatus, onRowClick);

  return (
    <tr key={`${data.id}-table-body`} className="cursor-pointer">
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onToggleStatus: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export {
  TableHeader,
  TableBody,
};
