import { FORM_CONFIG } from '../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { mt as mtRoute, MT_SETTINGS } from '../../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { refGenerator } from '../../../../utils';

const breadCrumb = [mtRoute,
  {
    id: 2,
    title: 'Rscore',
    // path: `/${MT_RSCORE}`,
    path: '#',
    active: true,
  }, {
    id: 3,
    title: 'Settings',
    path: `/${MT_SETTINGS}`,
    active: true,
  },
];

const Title = 'Visibility Audit';
const requiredList = ['title', 'frequency', 'assignedTo'];

const formMapper = element => ({
  id: element.id || '',
  title: element.title || '',
  frequency: element.count_days || '',
  assignedTo: element.assigned_to || '',
})

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Create Visibility Audit',
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Edit Visibility Audit',
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'Delete Visibility Audit',
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    [FORM_CONFIG.TITLE]: 'Bulk Delete',
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },

}

const crudSuccess = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      id: [data.id],
    }),
    responseName: 'deleteVisibilityAudit',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({ ids: data.data }),
    responseName: 'deletebulkVisibilityAudit',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};


export {
  breadCrumb as breadCrumbConfig,
  formConfig,
  Title as title,
  crudSuccess,
};
