import { compose } from 'recompose';
import JBP from './JBP';
import withRequestTracker from '../../../../../../../utils/composition/withRequestTracker';
import {
  query, mutation, TOGGLE_JBP_STATUS,
} from './API';

const composedJBP = compose(
  TOGGLE_JBP_STATUS,
  withRequestTracker({
    query,
    mutation,
  }),
)(JBP);

export default composedJBP;
