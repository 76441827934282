/* eslint-disable array-callback-return */
import { maxBy, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Menu } from '../index';
import { getMenuListWithUserOption } from './filterConfig';
import BaseFilter from './BaseFilter';
import { getDataFromLocalStorage } from '../../../data/services';

const Filter = (props: any) => {
  const { onFilterChange } = props;
  const [menuList, setMenuList] = useState(getMenuListWithUserOption({ ...props.menuList }) || {});
  const [selectedFilterGroups, setSelectedFilterGroups] = useState<any[]>([]);
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const getUnselectedFilterGroups = () => {
    const unSelectedList: any[] = [];
    Object.keys(menuList).map(key => {
      if (menuList[key].index === 0 && key !== 'userOption') {
        unSelectedList.push(menuList[key]);
      }
    });
    return unSelectedList;
  };

  /**
   * @return {Array} in ascending order of index.
   */
  const getSelectedFilterGroups = () => {
    const arrayList: any[] = [];
    Object.keys(menuList).map(key => {
      if (menuList[key].index > 0) {
        arrayList.push(menuList[key]);
      }
    });
    const sortedList = sortBy(arrayList, 'index');
    setSelectedFilterGroups(sortedList);
  };

  const handleFilterResetClick = () => {
    Object.keys(menuList).map(key => {
      menuList[key].selectedItemList = [];
      menuList[key].selectedIdList = [];
      menuList[key].index = 0;
    });
    setMenuList(menuList);
    getSelectedFilterGroups();
    onFilterChange(menuList);
  };

  const getHighestIndexValue = () => {
    const arrayList: any[] = [];
    Object.keys(menuList).map(key => arrayList.push(menuList[key]));
    const menu = maxBy(arrayList, 'index');
    return menu.index;
  };

  const handleAddClick = () => {
    const selectedMenuCount = getHighestIndexValue();
    menuList.userOption.index = selectedMenuCount + 1;
    setMenuList(menuList);
    getSelectedFilterGroups();
  };

  const handleFilterChange = () => {
    onFilterChange(menuList);
  };

  const handleFilterGroupSelection = (
    newFilterGroup: any,
    oldFilterGroup: any,
  ) => {
    const newFilterGroupLabel = newFilterGroup.label;
    const oldFilterGroupLabel = oldFilterGroup.label;

    menuList[newFilterGroupLabel].index = menuList[oldFilterGroupLabel].index;
    menuList[oldFilterGroupLabel].index = 0;
    menuList[oldFilterGroupLabel].selectedIdList = [];
    menuList[newFilterGroupLabel].selectedIdList = [];
    menuList[oldFilterGroupLabel].selectedItemList = [];
    menuList[newFilterGroupLabel].selectedItemList = [];
    setMenuList(menuList);
    getSelectedFilterGroups();
  };

  const handleFilterGroupDelete = (filterGroup: any) => {
    const { label } = filterGroup;
    const serverCallRequired: boolean = Boolean(
      menuList[label].selectedIdList.length > 0,
    );
    menuList[label].index = 0;
    menuList[label].selectedIdList = [];
    menuList[label].selectedItemList = [];
    setMenuList(menuList);
    getSelectedFilterGroups();

    if (serverCallRequired) {
      onFilterChange(menuList);
    }
  };

  const getOptionListForBaseFilter = async (reference: any) => {
    // GET OPTION LIST FRO BASE FILTER FROM BACKEND
    const optionList: any[] =
      (await getDataFromLocalStorage(reference).then(res => res)) || [];
    return optionList;
  };

  const onCheckBoxClick =
    (label: string, itemList: any[], idList: any[]) => {
      menuList[label].selectedItemList = itemList;
      menuList[label].selectedIdList = idList;
      setMenuList(menuList);
      onFilterChange(menuList);
    };

  const getPropsSelectedFilterGroups = (menuList: any) => {
    const arrayList: any[] = [];
    Object.keys(menuList).map(key => {
      if (menuList[key].index > 0) {
        arrayList.push(menuList[key]);
      }
    });
    const sortedList = sortBy(arrayList, 'index');
    setSelectedFilterGroups(sortedList);
  };

  useEffect(() => {
    const active = selectedFilterGroups.filter(
      filter => filter.selectedIdList.length > 0,
    ).length;
    setActiveFilterCount(active);
    return () => { };
  }, [selectedFilterGroups, menuList, onCheckBoxClick]);

  useEffect(() => {
    getPropsSelectedFilterGroups(props.menuList)
    setMenuList(getMenuListWithUserOption({ ...props.menuList }))
    return () => { }
  }, [props.menuList])

  const unSelectedFilterGroups = getUnselectedFilterGroups();

  return (
    <>
      <Menu label="filter" value={activeFilterCount} header="">
        <div className="filter-label">
          Filter By
          <Button iconBtnSmall secondary iconName="reload" onClick={handleFilterResetClick} />
        </div>
        <div className="filter-content">
          {selectedFilterGroups.map(filterGroup => (
            <div key={`filter-id-${filterGroup.index}`} id={`filter-id-${filterGroup.id}`}>
              <div className="filter-list-group">
                <BaseFilter
                  filter={filterGroup}
                  selectionList={unSelectedFilterGroups}
                  onFilterChange={handleFilterChange}
                  onFilterGroupSelect={handleFilterGroupSelection}
                  onGroupDelete={handleFilterGroupDelete}
                  getOptionList={getOptionListForBaseFilter}
                  onCheckBoxClick={onCheckBoxClick}
                  selectedIdList={filterGroup.selectedIdList}
                  selectedItemList={filterGroup.selectedItemList}
                  itemsList={[]}
                />
              </div>
            </div>
          ))}
          {selectedFilterGroups.length === 0 && <div className="no-filter-text">No Filters Applied</div>}
          <div className="filter-btn add">
            <Button iconBtnSmall primary iconName="plus" onClick={handleAddClick} />
          </div>
        </div>
      </Menu>
    </>
  );
};

export default Filter;
