import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withAlert from '../../../../../../../utils/composition/withAlert';
import { TableBody, TableHeader } from './tableConfig';
import { ALERT_TYPE } from '../../../../../../../data/enums/AlertType';
import { dropdownChange, inputChange } from '../../../../../../../utils/formHandlers';
import withErrorAndLoadingScreen from '../../../../../../../utils/composition/withLoading';
import FundamentalStyled from './FundamentalStyle';
import { MESSAGE_EVENT_OPERATION } from '../../../../../../../data/enums/SuccessMessage';

const propTypes = {
  buId: PropTypes.number.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getAuditCriteria: PropTypes.func.isRequired,
  getFundamentalDetails: PropTypes.func.isRequired,
  createFundamentalDetails: PropTypes.func.isRequired,
};

class Fundamentals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      menu: {
        auditList: [],
      },
    };
  }

  componentDidMount() {
    this.loadTableData();
    this.loadDropDownList();
  }

  loadTableData=() => {
    const { getFundamentalDetails, buId, displayAlert } = this.props;
    getFundamentalDetails({ buId }, {
      handleSuccess: (res) => {
        this.setState({ data: res.data.getFundamentalDetails || [] });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  loadDropDownList=() => {
    const { getAuditCriteria, displayAlert } = this.props;
    const { menu } = this.state;
    getAuditCriteria({}, {
      handleSuccess: (res) => {
        menu.auditList = res.data.getAuditCriteria || [];
        this.setState({ menu });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  handleToggleStatus=(event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const updatedDetails = inputChange(data, event, firstParam, paramList);

    this.setState({ data: updatedDetails });
  }

  handleDropDownChange = (value, parameterRef = []) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    this.setState({ data: updatedData });
  };

  handleSubmit=(fundamentalId) => {
    const { data } = this.state;
    const { createFundamentalDetails, displayAlert } = this.props;
    const selectedFundamental = data.filter(list => list.fundamentalTypeId === fundamentalId);
    const changedData = selectedFundamental.length && selectedFundamental[0];
    createFundamentalDetails({
      input: {
        principal_id: changedData.principalId,
        audit_criteria_id: changedData.auditCriteriaId,
        fundamental_type_id: changedData.fundamentalTypeId,
        active: changedData.active,
      },
    }, {
      handleSuccess: () => {
        displayAlert(ALERT_TYPE.SUCCESS, `${changedData.title} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
        this.loadTableData();
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  render() {
    const { data, menu } = this.state;
    return (
      <FundamentalStyled>
        <div className="table-wrap">
          <table>
            { TableHeader() }
            <tbody>
              {data.map((fundamental, index) => (
                TableBody({
                  data: fundamental,
                  index,
                  menu,
                  onDropDownChange: this.handleDropDownChange,
                  onInputChange: this.handleToggleStatus,
                  onSaveClick: this.handleSubmit,
                })
              ))}
            </tbody>
          </table>
        </div>
      </FundamentalStyled>
    );
  }
}

Fundamentals.propTypes = propTypes;

const FundamentalsErrorAndLoadingScreen = withErrorAndLoadingScreen(Fundamentals);

export default withAlert()(FundamentalsErrorAndLoadingScreen);
