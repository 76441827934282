import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_AUDIT_LIST = gql`
query GetAuditReport( $limit:Int,
  $offset:Int,  $filter: FilterInput) {
    getAuditReport(limit:$limit,offset:$offset, filter: $filter) {
      rows
      {
      audited_by
      audit_type
      audit_based_type
      outlet
      audited_date
      catalog_title
      images
      }
      count
    }
  }

`;

const GET_RETAIL_OUTLET_FILTER = gql`
query RetailOutletsFilter($chainId: [Int], $onlyChain: Boolean, $onlyOutlet: Boolean) {
  retailOutletsFilter(chainId: $chainId, onlyChain: $onlyChain, onlyOutlet: $onlyOutlet) {
    id
    title
  }
}

`;

const GET_RETAIL_OUTLET_CHAIN_FILTER = gql`
query RetailOutletsFilter($chainId: [Int], $onlyChain: Boolean, $onlyOutlet: Boolean) {
  retailOutletsFilter(chainId: $chainId, onlyChain: $onlyChain, onlyOutlet: $onlyOutlet) {
    id
    title
  }
}
`;

const query = {
  GetAuditReport: GET_AUDIT_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export {
  DOWNLOAD_REPORT, GET_RETAIL_OUTLET_FILTER,GET_RETAIL_OUTLET_CHAIN_FILTER
};

export { query, mutation };
