import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SBDStyled from './SBDStyle';
import * as queryService from '../../../../../../base/query.service';
import { ALERT_TYPE } from '../../../../../../../data/enums/AlertType';
import withAlert from '../../../../../../../utils/composition/withAlert';
import { crudResponseProcessor, handleFormSubmit } from '../../../../../../../utils/crudResponseProcessor';
import { DialogWrapper } from '../../../../../../common';
import { Button, Search } from '../../../../../../../components';
import { EVENT_OPERATION } from '../../../../../../../data/enums/EventOperation';
import SBDView from './View';
import {
  crudRequest as crudRequestConfig, sucessMessage,
} from './config';
import {
  apiFilterProcessor,
  getOffsetFromPagination,
} from '../../../../../../../utils/api';
import history from '../../../../../../../utils/history';
import { MT_BU_DETAILS } from '../../../../../../../data/enums/Route';
import { isError } from '../../../../../../common/HelperFunctions';
import withErrorAndLoadingScreen from '../../../../../../../utils/composition/withLoading';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  deleteSBDTemplate: PropTypes.func.isRequired,
  bulkDeleteSBDTemplate: PropTypes.func.isRequired,
  getRscoreSBDTemplateList: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};
const defaultProps = {
  serverResponseWaiting: false,
};
class SBD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        filter: queryService.baseQueryParameters.filter,
      },
      data: {
        list: [],
        total: 0,
      },
    };
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    const serverCall = {
      [EVENT_OPERATION.DELETE]: props.deleteSBDTemplate,
      [EVENT_OPERATION.BULK_DELETE]: props.bulkDeleteSBDTemplate,
    };
    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        this.setState(data);
        displayAlert(ALERT_TYPE.SUCCESS, sucessMessage);
        if (
          type === EVENT_OPERATION.DELETE
          || type === EVENT_OPERATION.BULK_DELETE
        ) {
          this.loadTableData();
        }
      }, this.getData,
      ['title'],
    );
    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess,
      this.onAPIRequestFailure,
      crudRequestConfig,
      serverCall,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getRscoreSBDTemplateList, id } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getRscoreSBDTemplateList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
        buId: id,
      },
      {
        handleSuccess: (response) => {
          data.list = (response.data.getRscoreSBDTemplateList && response.data.getRscoreSBDTemplateList.rows) || [];
          data.total = (response.data.getRscoreSBDTemplateList && response.data.getRscoreSBDTemplateList.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleCreateClick=() => {
    const { id, buTitle } = this.props;
    history.push({ pathname: `/${MT_BU_DETAILS}/${buTitle}/${id}/sbd-create`, state: { id, title: buTitle } });
  }

  onTableRowClick = (idRow) => {
    const { id, buTitle } = this.props;
    history.push({ pathname: `/${MT_BU_DETAILS}/${buTitle}/${id}/sbd-details/${idRow}`, state: { id, title: buTitle } });
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

    render() {
      const { queryParameters, data } = this.state;
      const { serverResponseWaiting ,bulkDeleteSBDTemplate} = this.props;
      return (
        <DialogWrapper
          onDialogSubmit={this.onFormSubmit}
          render={({ onDialogItemClick }) => (
            <SBDStyled>
              <div className="top-section">
                <span>{`Total ${data.list && data.list.length} SBD Tempaltes.`}</span>
                <div className="button-section">
                  <Search
                    handleSearchInput={this.basePaginationService.handleSearchInputChange}
                  />
                  <Button
                    primary
                    iconName="plus"
                    small
                    title={EVENT_OPERATION.CREATE.toLocaleLowerCase()}
                    onClick={() => this.handleCreateClick(EVENT_OPERATION.CREATE)}
                    className="create-btn"
                  />
                </div>
              </div>
              <SBDView
                data={data}
                permission={this.permission}
                pagination={queryParameters.pagination}
                loading={serverResponseWaiting}
                onPageChange={this.basePaginationService.onPageSelect}
                onBulkDelete={this.handleBulkDelete}
                onIconClick={onDialogItemClick}
                onTableRowClick={this.onTableRowClick}
                bulkDeleteSBDTemplate={bulkDeleteSBDTemplate}
              />
            </SBDStyled>
          )}
        />
      );
    }
}

SBD.propTypes = propTypes;
SBD.defaultProps = defaultProps;

const SBDWithErrorAndLoadingScreen = withErrorAndLoadingScreen(SBD);

export default withAlert()(SBDWithErrorAndLoadingScreen);
