
import { compose } from 'recompose';
import VGP from './VGP';
import {
  query, mutation, DELETE_QUESTIONNAIRE,
} from './API';
import withRequestTracker from '../../../../../../../utils/composition/withRequestTracker';

const ComposedVGP = compose(
  DELETE_QUESTIONNAIRE,
  withRequestTracker({
    query,
    mutation,
  }),
)(VGP);

export default ComposedVGP;
