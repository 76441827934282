import styled from 'styled-components';

const DetailsStyled = styled.div`
.disable{
cursor:not-allowed;
  .outlet-detail {
    pointer-events:none;
  }
}
.image-upload{
  display:flex;
  &>.images{
    width:70%;
    display:flex;
    gap:16px;
    .attachment-item{
      margin-top:0;
      .thumbnail-img{
        margin:0;
      }
    }
    &>.col-md-1:last-child{
      order:-1;
    }
    .custom-file-upload-wrap{
      top:0;
      overflow:unset;
      cursor:pointer;
      .custom-file-upload{
        height:40px;
        width:40px;
        line-height:40px;
        text-align:center;
        padding:0;
        .icon{
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  &>label{
    width:30%;
    font-size: 14px;
    line-height: 34px;
    color: #272833;
    font-weight: 500;
  }
}
`;
export default DetailsStyled;
