import { compose } from 'recompose';
import VGPReport from './VGPReport';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation
} from './API';

const ComposedProductivity = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(VGPReport);

export default ComposedProductivity;
