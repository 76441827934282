import styled from 'styled-components';

const FocusedDetailsStyled = styled.div`
.sbd-bottom-wrap{
  .sbd-detail-table{
    .popover_td{
      color:#0b5fff;
    }
  }
}

`;
export default FocusedDetailsStyled;
