import gql from 'graphql-tag';

const GET_PURCHASE_ORDER_LIST = gql`
query getPurchaseOrdersList($limit: Int, $offset: Int, $filter: FilterInput){
  getPurchaseOrdersList(limit:$limit, offset:$offset, filter:$filter){
    rows{
      id
      purchaseOrderDate
      Channel{
        id
        title
      }
      amountDetails{
        grossAmount
      }
    }
    count
  }
}
`;

const query = {
  getPurchaseOrdersList: GET_PURCHASE_ORDER_LIST,
};

export {
  query,
};
