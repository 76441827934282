import styled from 'styled-components';

const ContactDialogWrapper = styled.div`
  .contact {
    position: relative;
    .eye {
      position: absolute;
      top: 9px;
      right: 14px;
    }
  }
  .txt-color {
    color: #6b6c7e;
  }

  .modal-wrapper {
    .modal-inner {
      width: 610px;
      max-width: unset;
      max-height: 915px;
      .with-out-padding {
        padding: 0;
        .evolve-dialog__body {
          padding-bottom: 24px;
          border-radius: 0 0 4px 4px;

          .table-wrap {
            height: 400px;
            overflow: auto;
            table {
              border-radius: 0 0 4px 4px;
              position: relative;
              thead {
                tr {
                  height: 40px;
                }
              }
              tr {
                height: 56px;
                th,
                td {
                  width: 25%;
                  padding: 0;
                  &:first-child {
                    padding: 0 12px 0 24px;
                  }

                  img {
                    margin-left: 6px;
                    position: absolute;
                  }
                }
                th {
                  position: sticky;
                  top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .auto-complete-pop-up {
    margin-bottom:24px;
    .filter-dropdown-card {
      top: 68%;
      left: unset;
      right: 0;
      width: 69%;
    }
    .filter-item {
      span > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        position: relative;
      }
      label {
        width: calc(166px - 10px);
        width: calc(31% - 10px);
        margin-right: 10px;
        position: relative;
    
        font-weight: 500;
      }
      .form-input {
        width: calc(100% - 166px);
        width: calc(100% - 31%);
        -webkit-flex: 1;
        -ms-flex: 1;
        margin-bottom:0;
        flex: 1;
        margin-bottom:0;
        position: relative;
        & + span {
          position: absolute;
          right: 11px;
          top: 10px;
        }
      }
      .dropdown {
        position: absolute;
        right: 8px;
        top: 10px;
      }
    }
  }
`;

export default ContactDialogWrapper;
