import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DetailForm from './Form';
import SRNStyled from '../../SRNStyled';
import LedgerAdd from '../../../../components/LedgerAdd';
import { compareList } from '../../../../../utils/arrayProcessor';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import withAlert from '../../../../../utils/composition/withAlert';
import withLoading from '../../../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  getDetails: PropTypes.func,
  getStatus: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  formConfig: PropTypes.shape({
    mapper: PropTypes.func,
    refsObj: PropTypes.instanceOf(Object),
    title: PropTypes.string,
    validationRequired: PropTypes.bool,
  }),
  distributorList: PropTypes.instanceOf(Array),
  handleDialogSubmit: PropTypes.func,
  outletList: PropTypes.instanceOf(Array),
  handleIconClick: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  distributorServices: PropTypes.instanceOf(Object),
  handleInputChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  invoiceNumberStatus: PropTypes.bool,
  subDUser: PropTypes.bool,
  pending: PropTypes.bool,
  showDialog: PropTypes.bool,
  loadingInvoiceStatus: PropTypes.bool,
  customerList: PropTypes.instanceOf(Array),
  invoiceNumberList: PropTypes.instanceOf(Array),
  srnRouteLineList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  distributorList: [],
  getStatus: () => null,
  getDetails: () => null,
  enableErrorDisplay: false,
  showDialog: false,
  formConfig: {
    mapper: () => ({}),
    refsObj: {},
    title: '',
    validationRequired: true,
  },
  outletList: [],
  handleIconClick: () => null,
  handleDropDownChange: () => null,
  distributorServices: {},
  handleInputChange: () => null,
  handleDialogSubmit: () => null,
  invoiceNumberStatus: false,
  loadingInvoiceStatus: false,
  subDUser: false,
  pending: false,
  customerList: [],
  invoiceNumberList: [],
  srnRouteLineList: [],
};

class SummaryDetails extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data } = nextProps;
    const { backupData } = prevState;

    if (!compareList(data, backupData)) {
      return {
        data: nextProps.data,
        backupData: nextProps.data,
      };
    }
    return data;
  }

  constructor(props) {
    super(props);
    this.state = {
      backupData: props.data,
    };
  }

  render() {
    const {
      subDUser,
      formConfig,
      outletList,
      enableErrorDisplay,
      distributorList,
      loading,
      distributorServices,
      invoiceNumberStatus,
      pending,
      customerList,
      handleDropDownChange,
      handleInputChange,
      invoiceNumberList,
      handleIconClick,
      handleDialogSubmit,
      srnRouteLineList,
      showDialog,
      loadingInvoiceStatus,
      data,
    } = this.props;
    return (
      <SRNStyled>
        {showDialog && (
          <LedgerAdd
            onCancel={handleIconClick}
            onSubmit={handleDialogSubmit}
            townId={data.RetailOutlet.townId}
            id={data.RetailOutlet.id}
            billingUser
            search
          />
        )}
        <DetailForm
          show
          pending={pending}
          subDUser={subDUser}
          data={data}
          customerList={customerList}
          enableErrorDisplay={enableErrorDisplay}
          handleInputChange={handleInputChange}
          handleDropDownChange={handleDropDownChange}
          outletList={outletList}
          distributorList={distributorList}
          refsObj={formConfig[FORM_CONFIG.REFS_OBJ]}
          invoiceNumberList={invoiceNumberList}
          distributorServices={distributorServices}
          invoiceNumberStatus={invoiceNumberStatus}
          loading={loading}
          srnRouteLineList={srnRouteLineList}
          loadingInvoiceStatus={loadingInvoiceStatus}
          handleIconClick={handleIconClick}
        />
      </SRNStyled>
    );
  }
}

SummaryDetails.propTypes = propTypes;

SummaryDetails.defaultProps = defaultProps;

export default withAlert()(withLoading(SummaryDetails));
