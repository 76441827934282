import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Line, ResponsiveContainer } from 'recharts';
import Linechart from '../../../../../components/Chart/LineChart';
import { filterItems } from '../../../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import CustomLegend from '../../../components/Legend/CustomLegend';
import { modifyWeekYearWise, reverseString } from '../../../config';
import { brandColor, data, dimension } from './config';
import { P3MHistoryStyled } from './P3MHistoryStyled';

const P3MHistory = ({ brand }) => {
  const P3MRscore = useSelector(state => state?.RscoreDatas?.P3MRscore);
  const RSCORE_BU = filterItems(P3MRscore, brand, 'bu');
  const AUDIT_PERIOD_WISE = _(RSCORE_BU).groupBy('audit_period').value();

  const RscoreP3M = [];
  for (const property in AUDIT_PERIOD_WISE) {
    const periodWise = AUDIT_PERIOD_WISE[property];
    const outlet_channel_percent = _(periodWise)
      .groupBy('outlet_channel')
      .map((item, fundamental) => ({
        fundamental,
        percent: fixedFloatAndCommas((_.sumBy(item, 'point_scored') / _.sumBy(item, 'points_allocated')) * 100, 1),
      }))
      .value();
    const outlet = outlet_channel_percent.reduce((acc, obj) => {
      const key = obj['fundamental'];
      return { ...acc, [key]: obj['percent'] };
    }, {});
    RscoreP3M.push({ ...outlet, week: reverseString(property) });
  }

  // sorting week wise
  const RSCORE_P3M_SORT_WEEKWISE = RscoreP3M?.sort((a, b) => {
    const weekA = a.week.split(' ');
    const weekB = b.week.split(' ');
    if (weekA[0] === weekB[0]) {
      return parseInt(weekA[1]) - parseInt(weekB[1]);
    } else {
      return parseInt(weekA[0]) - parseInt(weekB[0]);
    }
  });

  // const RSCORE_P3M_SORT_WEEKWISE = weekWiseSort?.map(obj => {
  //   return {
  //     ...obj,
  //     BBSM: obj.hasOwnProperty('BBSM') ? obj.BBSM : '0',
  //     BigMart: obj.hasOwnProperty('BigMart') ? obj.BigMart : '0',
  //     Salesways: obj.hasOwnProperty('Salesways') ? obj.Salesways : '0'
  //   };
  // });

  let RSCORE_P3M_SORT_WEEKWISE_KEYS = [];
  for (const item of RSCORE_P3M_SORT_WEEKWISE) {
    for (const property in item) {
      if (property !== 'week' && !RSCORE_P3M_SORT_WEEKWISE_KEYS?.includes(property)) {
        RSCORE_P3M_SORT_WEEKWISE_KEYS.push(property);
      }
    }
  }

  return (
    <>
      <P3MHistoryStyled>
        <div className="linechart-wrap">
          <ResponsiveContainer width="100%" aspect={3}>
            <Linechart
              data={modifyWeekYearWise(RSCORE_P3M_SORT_WEEKWISE)}
              dimensions={dimension}
              isXAxis={true}
              isYAxis={true}
              legend={false}
              cartesianGrid={true}
              tooltip={true}
              color={brandColor}
              xAxisTitle="Score"
              yAxisTitle="Weeks"
              title="R-Score P3M History"
              aspect={2.5}
              interval={0}
            >
              {RSCORE_P3M_SORT_WEEKWISE_KEYS?.map(item => (
                <Line
                  type="monotone"
                  dataKey={item}
                  stroke={brandColor[item]}
                  strokeWidth={1.5}
                  fill={brandColor[item]}
                  isAnimationActive={false}
                />
              ))}
            </Linechart>
          </ResponsiveContainer>
          <CustomLegend data={data} />
        </div>
      </P3MHistoryStyled>
    </>
  );
};

export default P3MHistory;
