import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { has } from '../../../../../../../../utils/filter';
import View from './View';
import { MT_BU_DETAILS } from '../../../../../../../../data/enums/Route';
import { PanelHeader, PanelStyled } from '../../../../../../../common/configuration';
import {
  formMapper, breadCrumbConfig, getPayload, title,
} from './config';
import { BreadCrumb, Button, Icon } from '../../../../../../../../components';
import { ALERT_TYPE } from '../../../../../../../../data/enums/AlertType';
import withAlert from '../../../../../../../../utils/composition/withAlert';
import { clone } from '../../../../../../../../utils/objectProcessor';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../../../../../data/services';
import {
  EVENT_OPERATION,
  EVENT_OPERATION_MAPPER,
} from '../../../../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../../../../data/enums/SuccessMessage';
import { isError } from '../../../../../../../common/HelperFunctions';
import { refGenerator, refValidator } from '../../../../../../../../utils/refGenerator';
import history from '../../../../../../../../utils/history';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object).isRequired,
  getMTSBDTemplateDetails: PropTypes.func.isRequired,
  getBrandDetails: PropTypes.func.isRequired,
};

const defaultProps = {
  location: {
    state: {},
  },
  serverResponseWaiting: false,
};

class Detail extends Component {
  constructor(props) {
    super(props);
    const { state = {} } = props.location;
    const id = has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0;
    const vgpId = has.call(props.match.params, 'extraId') ? parseInt(props.match.params.extraId, 10) : 0;
    this.state = {
      id,
      vgpId,
      update: {
        type: EVENT_OPERATION.CREATE,
        status: true,
      },
      sbdData: {
        basicData: formMapper(id),
        brandIds: [],
      },
      menu: {
        channel: [],
        category: [],
        auditType: [{
          title:'SHELF',
          label:'Shelf'
        },{
          title:'DISPLAY',
          label:'Display'
        }],
      },
      enableErrorDisplay: false,
      backUpSKUFamily: [],
    };
    this.formReference = refGenerator(['question', 'channel_id', 'sbdcategory', 'start_date', 'end_date']);
  }

  componentDidMount() {
    const {
      vgpId, update, id,
    } = this.state;
    this.onBUSelect(id);
    this.getChannelList();
    this.getCategoryListing();
    this.getBrandList();
    if (vgpId) {
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.getCategoryList();
      this.setState(
        {
          vgpId,
          update,
        }, () => {
          this.getQuestionnaireDetails();
        },
      );
    }
  }

  getQuestionnaireDetails = () => {
    const { id, vgpId } = this.state;
    const { getQuestionnaireDetail } = this.props;
    getQuestionnaireDetail({
      id: vgpId
    },{
      handleSuccess:(res)=>{
        const result = res?.data?.getQuestionnaireDetail;
        this.setState({
            sbdData: {
            basicData: formMapper(id,result),
            brandIds: result?.brandIds?.map(a=>a.id),
          },
        })
      },
      handleError:(err)=>{
        this.onAPIRequestFailure(err)
      },
    })
  }

  onBUSelect = (id) => {
    this.setState({
      buId: id,
    });
  } ;


  handleDropDownChange = (value, name) => {
    const { sbdData, menu } = this.state;
    sbdData.basicData[name] = value;
    this.setState(sbdData);
    switch (name) {
      case 'channel_id':
        sbdData.basicData.sbdcategory = [];
        this.setState({ sbdData, menu });
        this.getCategoryList();
        break;
      case 'sbdcategory':
        this.setState({ sbdData, menu });
        break;
      default:
    }
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { sbdData: { basicData } } = this.state;
    if (firstParam) {
      if (paramList.length > 1) {
        paramList.reduce((acc, value, index, list) => {
          if (index === list.length - 1) {
            return (acc[value] = event.formattedValue);
          }
          return acc[value];
        }, basicData);
      } else {
        basicData[firstParam][event.target.name] = event.formattedValue;
      }
    } else {
      basicData[event.target.name] = event.formattedValue;
    }
    this.setState({ basicData });
  };

  onAutoCompleteChange=(value, fieldName)=>{
    const { sbdData: { basicData } } = this.state;
    this.setState({sbdData: {
      basicData,
      brandIds: value,
    }})
  }

  getChannelList = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CHANNEL).then((response) => {
      menu.channel = response;
      this.setState({ menu });
    });
  }

 getCategoryList=() => {
   const { menu } = this.state;
   getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY).then((response) => {
     menu.category = response;
     this.setState({ menu });
   });
 }

 onAPIRequestFailure = error => {
  const { displayAlert } = this.props;
  displayAlert(ALERT_TYPE.DANGER, error);
};

getBrandList = () => {
  const { id } = this.state;
  const { getBrandListUnderBU } = this.props;
  getBrandListUnderBU({buId:id}, {
    handleSuccess: (response) => {
      const brandList = response?.data?.getBrandListUnderBU || [];
      if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
      this.setState({ brandList });
    },
    handleError: (error) => { this.onAPIRequestFailure(error); },
  });
};

getCategoryListing = () => { // bu List
  getDataFromLocalStorage(CLIENT_STORAGE_TABLE.PRINCIPAL).then(
    (response) => {
      const categoryList = [{ categories: response }];
      this.setState({ categoryList });
    },
  );
};

 onCategorySelect = (categoryId) => {
   this.setState({
     showBrands: true,
     categoryId,
   });
 }


handleSearchInput = (text) => {
  const { skuFamilyList } = this.state;
  const regex = new RegExp(text, 'i');
  const bySkus = skuFamilyList.filter(i => i.skus
    .filter(s => s.title.search(regex) > -1).length > 0);
  const bySKUFamilies = skuFamilyList.filter(p => p.title.search(regex) > -1);
  const union = [...bySKUFamilies, ...bySkus];
  const result = union.filter((item, index) => union.indexOf(item) === index);
  this.setState({ SKUFamilyList: result, searchText: text });
};

handleButtonCancel = (vgpId) => {
  const { update } = this.state;
  if (vgpId) {
    update.type = EVENT_OPERATION.UPDATE;
    update.status = false;
    this.setState(update);
    this.getQuestionnaireDetails();
  } else {
    this.setState({
      sbdData: { basicData: formMapper({}), sbdSku: [] },
    });
  }
};

handleButtonSubmit = () => {
  const { id, sbdData, update, vgpId } = this.state;
  const { createQuestionnaire, displayAlert, location: { state }, editQuestionnaire } = this.props;
  const formValidation = refValidator(this.formReference) && sbdData?.brandIds?.length>0;
  if (!formValidation) {
    this.setState({ enableErrorDisplay: true });
  } else {
    if(vgpId){
      editQuestionnaire({
        id: vgpId,
        input: getPayload(sbdData, id),
        }, {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `Questionnaire ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            update.type = EVENT_OPERATION.UPDATE;
            update.status = false;
            this.setState(update);
          },
          handleError: (err) => {
            displayAlert(ALERT_TYPE.DANGER, err);
          },
      });
    }else{
      createQuestionnaire({
        input: getPayload(sbdData, id),
        }, {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `Questionnaire ${MESSAGE_EVENT_OPERATION.CREATE}`);
            setTimeout(() => {
              history.push(`/${MT_BU_DETAILS}/${state.title}/${state.id}`, { activeTab: 3 });
            }, 900);
          },
          handleError: (err) => {
            displayAlert(ALERT_TYPE.DANGER, err);
          },
      });
    }
  }
};

getHeader = () => {
  const { update, sbdData: { basicData } } = this.state;
  const crudMode = update.status
    ? update.type===EVENT_OPERATION.CREATE?`${EVENT_OPERATION_MAPPER[update.type].toLowerCase()} ${title}`:`${EVENT_OPERATION_MAPPER[update.type].toLowerCase()} ${basicData.question}`
    : `${basicData.question}`;
  return (
    <>
      <span>
        {crudMode}
      </span>
    </>
  );
};

render() {
  const {
     sbdData, menu, update, vgpId, brandList, enableErrorDisplay
  } = this.state;
  const { serverResponseWaiting, location: { state } } = this.props;
  const createBreadCrumb = {
    id: 3,
    title: 'VGP',
    path: `/${MT_BU_DETAILS}/${state.title}/${state.id}`,
    active: true,
  };
  const header = this.getHeader();
  return (
    <>
      <div className="section-header">
        <PanelStyled>
          <BreadCrumb list={[...breadCrumbConfig, createBreadCrumb]} />
          <PanelHeader>
            <h2>{header}</h2>
            <div className="flex m-0">
              {update.status && (
              <div>
                <Button
                  small
                  secondary
                  disabled={serverResponseWaiting}
                  onClick={() => this.handleButtonCancel(vgpId)}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  small
                  primary
                  disabled={serverResponseWaiting}
                  onClick={() => this.handleButtonSubmit()}
                >
                  <span>Save</span>
                </Button>
              </div>
              )}
              {!update.status && (
              <Button
                secondary
                iconBtnSmall
                disabled = {update.type === EVENT_OPERATION.UPDATE && update.status}
                onClick={() => {
                  this.setState({
                    update: {
                      type: EVENT_OPERATION.UPDATE,
                      status: true,
                    },
                  });
                }}
                className="ml-16"
              >
                <Icon iconName="pencil" />
              </Button>
              )}
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className={`section-content  ${update.type === 'UPDATE'
            && 'update'}`}
      >
        <View
          sbdData={sbdData}
          menu={menu}
          update={update}
          onInputChange={this.handleInputChange}
          onDropDownChange={this.handleDropDownChange}
          onAutoCompleteChange={this.onAutoCompleteChange}
          brandList={brandList}
          refsObj={this.formReference}
          enableErrorDisplay={enableErrorDisplay}
          loading={serverResponseWaiting}
        />
      </div>
    </>
  );
}
}
Detail.propTypes = propTypes;

export default withAlert()(Detail);
