import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../utils/paginatedListUtils';
import { detailsLabelConfig, DetailTableBody } from './tableConfig';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  onIconClick: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: [],
  onIconClick: () => null,
  permission: PERMISSION_OBJ,
};

const StockAdjustmentChildView = ({ data, onIconClick, permission }) => (
  <Fragment>
    <tr>
      {detailsLabelConfig.map((label) => (label.show ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
    {data.map((order) => (
      <tr>
        {DetailTableBody({
          data: order,
          onIconClick,
          permission,
        })}
      </tr>
    ))}
  </Fragment>
);

StockAdjustmentChildView.propTypes = propTypes;

StockAdjustmentChildView.defaultProps = defaultProps;

export default StockAdjustmentChildView;
