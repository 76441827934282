import { breadCrumbConfig } from '../../inventory/config';
import {
  MT_RSCORE,
  MT_FUNDAMENTALS,
  VISIBILITY_AUDIT,
} from '../../../../../data/enums/Route';

const title = 'Settings';
const urlIdToRoute = [
  {
    id: 11,
    title: 'Fundamentals',
    path: `/${MT_FUNDAMENTALS}`,
    active: true,
  },
  {
    id: 21,
    title: 'Visibility Audit Routes',
    path: `/${VISIBILITY_AUDIT}`,
    active: true,
  },
];
const data = [
  {
    mainTitle: 'General',
    icon: 'cog',
    dataList: [{ title: 'Fundamentals' }],
  },
  {
    mainTitle: 'User',
    icon: 'user',
    dataList: [{ title: 'Visibility Audit Routes' }],
  },
];
const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Rscore',
    // path: `/${MT_RSCORE}`,
    path: '#',
    active: true,
  },
];

export {
  breadCrumb as breadCrumbConfig, title, urlIdToRoute, data,
};
