import React from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from '../../../v4/components';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { menuAction, tableHeaderWithCheckBox } from '../../../v4/components';
import { getFileType } from '../../common/HelperFunctions';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  brand: 'brand',
  actionHolder: 'actionHolder',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.actionHolder,
    title: '',
    show: true,
  },
];

const menuConfigList = [
  {
    title: 'Download',
    permissionDerivedBy: 'read',
    type: EVENT_OPERATION.DOWNLOAD,
  },
  {
    title: 'Delete',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

const returnTableBody = (
  data,
  checkedList,
  onIconClick,
  onSecondaryCheckBoxClick,
  permission,
  update,
  withOutIcon = true,
) => {
  const table = {};
  table[labelMappings.checkbox] = (
    <td key={`presentation-${data.id}-check`} onClick={(e) => e.stopPropagation()}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.title] = (
    <td key={`${data}-title`} className='img-title'>
      <img src={getFileType(data.type)} alt='img - icon' />
      {data.title}
    </td>
  );
  table[labelMappings.brand] = <td key={`${data}-sub_campaign_type`}>{data.brand.title}</td>;
  table[labelMappings.actionHolder] = (
    <td
      key={`${data}-actionHolders`}
      className={
        checkedList.includes(data.id) ? 'disabled text-right simple-popup-actions' : 'text-right simple-popup-actions'
      }
      onClick={(e) => e.stopPropagation()}
    >
      {menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        data,
        { read: true, delete: true },
        withOutIcon,
      )}
    </td>
  );

  return table;
};

const TableBody = ({ data, checkedList, onIconClick, secondaryCheckboxHandler, permission }) => {
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission);
  return <tr key={data.id}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
