import { useEffect, useState } from 'react';
import { ReportStyled } from './ReportStyled';
import P3MHistory from './sections/P3MHistory/P3MHistory';
import RecentPastWeek from './sections/RecentPastWeek/RecentPastWeek';
import FundamentalsEachOutlet from './sections/RScoreByOutlet/FundamentalsEachOutlet';
import TitleWrap from './sections/TitleWrap/TitleWrap';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ALERT_TYPE } from '../../../v4/constants/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import {
  rScoreFail,
  rScoreP3MFail,
  rScoreP3MReq,
  rScoreP3MSuccess,
  rScoreP3WFail,
  rScoreP3WSuccess,
  rScoreReq,
  rScoreSuccess,
} from '../redux/actions';
import { formatter, getCurrentDay, getP3MStartDay, getP3WStartDay } from '../../../utils/date';
import MrBrands from './sections/fundamentalByMrBrands/MrBrands';
import P3MCategory from './sections/P3MCategory/P3MCategory';
import BBSM from './brands/bbsm/BBSM';
import Bigmart from './brands/Bigmart/Bigmart';
import { Loading } from '../../../v4/components';
import Salesways from './brands/salesways/Salesways';
import { getbuIds } from '../config';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
};

const Report = props => {
  const { getAllRscore, displayAlert } = props;
  const location = useLocation();
  const dispatch = useDispatch();

  const auditWeekInfo = JSON.parse(localStorage.getItem('audit_week'));

  const brand = location?.state?.brand;
  const auditWeek = location?.state?.auditWeek;
  const selectedWeek = location?.state?.selectedWeek;
  const weekList = location?.state?.weekList;
  const [isCustomWeekSelected, setIsCustomWeekSelected] = useState(
    auditWeekInfo?.hasOwnProperty('isCustomWeekSelected') ? auditWeekInfo?.isCustomWeekSelected : false,
  );

  const [week, setWeek] = useState({
    startDate: auditWeek?.firstDate,
    endDate: auditWeek?.lastDate,
  });
  const [date, setDate] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getBusinessRscoreData();
  }, [week]);

  useEffect(() => {
    getBusinessRscoreDataP3M();
    getBusinessRscoreDataP3W();
  }, []);

  const buIds = getbuIds();

  const onAPIRequestFailure = error => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const handleDateRangeChange = (label, changeDate) => {
    setIsCustomWeekSelected(true);
    setDate({
      firstDate: formatter(changeDate.start).format('DD MMM YYYY'),
      lastDate: formatter(changeDate.end).format('DD MMM YYYY'),
    });
    setWeek({
      startDate: formatter(changeDate.start).format('DD MMM YYYY'),
      endDate: formatter(changeDate.end).format('DD MMM YYYY'),
    });
    const selectedDate = {
      startDate: changeDate.start,
      endDate: changeDate.end,
    };
    localStorage.setItem('audit_week', JSON?.stringify(selectedDate));
  };

  const getBusinessRscoreData = () => {
    setIsLoading(true);
    getAllRscore(
      {
        filter: {
          dateRange: {
            start: week?.startDate,
            end: week?.endDate,
          },
        },
        buIds,
      },
      {
        handleSuccess: res => {
          dispatch(rScoreReq());
          const response = res?.data?.getRscoreData;
          dispatch(rScoreSuccess(response));
          setIsLoading(false);
        },
        handleError: error => {
          onAPIRequestFailure(error);
          dispatch(rScoreFail(error));
        },
      },
    );
  };

  const getBusinessRscoreDataP3M = () => {
    getAllRscore(
      {
        filter: {
          dateRange: {
            start: getP3MStartDay(),
            end: getCurrentDay(),
          },
        },
        buIds,
      },
      {
        handleSuccess: res => {
          dispatch(rScoreP3MReq());
          const response = res?.data?.getRscoreData;
          dispatch(rScoreP3MSuccess(response));
        },
        handleError: error => {
          onAPIRequestFailure(error);
          dispatch(rScoreP3MFail(error));
        },
      },
    );
  };

  const getBusinessRscoreDataP3W = () => {
    setIsLoading(true);
    getAllRscore(
      {
        filter: {
          dateRange: {
            start: getP3WStartDay(),
            end: getCurrentDay(),
          },
        },
        buIds,
      },
      {
        handleSuccess: res => {
          dispatch(rScoreP3MReq());
          const response = res?.data?.getRscoreData;
          dispatch(rScoreP3WSuccess(response));
          setIsLoading(false);
        },
        handleError: error => {
          onAPIRequestFailure(error);
          dispatch(rScoreP3WFail(error));
          setIsLoading(false);
        },
      },
    );
  };

  const getWeek = week => {
    setWeek({ startDate: week?.startDate, endDate: week?.endDate });
    const selectedDate = {
      ...week,
      ...selectedWeek,
    };

    localStorage.setItem('audit_week', JSON?.stringify(selectedDate));
  };

  return (
    <>
      <ReportStyled>
        <TitleWrap
          audit_week={auditWeek}
          selected_week={selectedWeek}
          weekList={weekList}
          getWeek={getWeek}
          brand={brand}
          handleDateRangeChange={handleDateRangeChange}
          date={date}
          setDate={setDate}
          setIsCustomWeekSelected={setIsCustomWeekSelected}
          isCustomWeekSelected={isCustomWeekSelected}
        />
        <div className="title-summary"> Summary</div>
        <RecentPastWeek brand={brand} />
        <P3MHistory brand={brand} />
        <FundamentalsEachOutlet brand={brand} />
        <MrBrands brand={brand} />
        <P3MCategory brand={brand} />
        <BBSM bu={brand} />
        <Bigmart bu={brand} />
        <Salesways bu={brand} />
      </ReportStyled>
    </>
  );
};

Report.propTypes = propTypes;

export default withAlert()(Report);
