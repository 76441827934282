import React from 'react';
import PropTypes from 'prop-types';
import { CheckBox, menuAction, tableData } from '../../../../v4/components';
import { normalPresentor } from '../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { ImageColumn } from '../../../../utils/tableUtils';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { headerLabelConfig } from '../../../common/HelperFunctions';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  date: 'date',
  outlet: 'outlet',
  collector: 'collector',
  bank: 'bank',
  amount: 'amount',
  alias: 'alias',
  remarks: 'remarks',
  userRole: 'userRole',
  dse: 'dse',
  chequeNumber: 'chequeNumber',
  distributor: 'distributor',
  route: 'route',
  images: 'images',
  actionHolder: 'actionHolder',
};

const labelConfig = () => [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.collector,
    title: 'Collector',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.bank,
    title: 'Bank',
    show: true,
  },
  {
    id: 11,
    label: labelMappings.chequeNumber,
    title: 'Cheque No.',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.alias,
    title: 'Alias',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.remarks,
    title: 'Remarks',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.userRole,
    title: 'User Role',
    show: false,
  },
  {
    id: 10,
    label: labelMappings.dse,
    title: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
    show: false,
  },
  {
    id: 11,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: false,
  },
  {
    id: 12,
    label: labelMappings.route,
    title: 'Route',
    show: false,
  },
  {
    id: 13,
    label: labelMappings.images,
    title: 'Image',
    show: true,
  },
];

const menuConfigList = {
  RECEIVED: [
    {
      title: 'RECONCILE',
      permissionDerivedBy: 'update',
      type: EVENT_OPERATION.RECONCILED,
    },
    {
      title: 'DISHONOR',
      permissionDerivedBy: 'update',
      type: EVENT_OPERATION.DISHONORED,
    },
  ],
  PENDING: [
    {
      title: 'UPDATE',
      permissionDerivedBy: 'update',
      type: EVENT_OPERATION.UPDATE,
    },
    {
      title: 'REMOVE',
      permissionDerivedBy: 'update',
      type: EVENT_OPERATION.DISCARDED,
    },
  ],
};

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, tableConfig, permission }) => (
  <thead>
    <tr>
      {tableConfig.map((label) =>
        label.show
          ? label.label === labelMappings.checkbox
            ? dataListLength > 0 &&
            permission &&
            permission.update && (
              <th key={`cheque-${label.id}-checkboxheader`}>
                <CheckBox
                  checked={dataListLength === checkedListLength}
                  onCheckBoxClick={(e) => primaryCheckboxHandler(e)}
                />
              </th>
            )
            : tableData(label)
          : null,
      )}
    </tr>
  </thead>
);

const returnTableBody = (
  data,
  onIconClick,
  handleSliderOpen,
  checkedList,
  onSecondaryCheckBoxClick,
  checked,
  section,
  permission,
  pendingAction,
) => {
  const table = {};
  table[labelMappings.checkbox] = checkedList && permission && permission.update && (
    <td onClick={(e) => e.stopPropagation()} key={`cheque-${data.id}-id`}>
      <CheckBox checked={checked} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.date] = <td key={`cheque-${data.id}-date`}>{normalPresentor(data.date)}</td>;
  table[labelMappings.outlet] = <td key={`cheque-${data.id}-outlet`}>{data.outlet.title}</td>;
  table[labelMappings.collector] = <td key={`cheque-${data.id}-collector`}>{data.collector.fullName}</td>;
  table[labelMappings.bank] = <td key={`cheque-${data.id}-bank`}>{data.details.bank}</td>;
  table[labelMappings.amount] = <td key={`cheque-${data.id}-amount`}>{fixedFloatAndCommas(data.amount)}</td>;
  table[labelMappings.alias] = (
    <td key={`cheque-${data.id}-alias`}>
      {section === 'received' ? (
        <div className={`alias-td ${permission && permission.create ? 'alias' : 'disabled'}`}>
          {data.paymentDetails.length !== 0 ? (
            <div className='count' onClick={() => onIconClick(EVENT_OPERATION.READ, data)}>
              {data.paymentDetails.length}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div className='alias-td'>
          {data.paymentDetails.length !== 0 && (
            <div className='count' onClick={() => onIconClick(EVENT_OPERATION.READ, data)}>
              {data.paymentDetails.length}
            </div>
          )}
        </div>
      )}
    </td>
  );
  table[labelMappings.remarks] = (
    <td key={`cash-${data.id}-remarks`}>{data.billingRemarks ? data.billingRemarks : data.remarks}</td>
  );
  table[labelMappings.userRole] = <td key={`cheque-${data.id}-userRole`}>{data.collector.role.name}</td>;
  table[labelMappings.dse] = (
    <td key={`cash-${data.id}-dse`}>
      {data.outlet.route && data.outlet.route.user && data.outlet.route.user.fullName}
    </td>
  );
  table[labelMappings.chequeNumber] = <td key={`cheque-${data.id}-chequeNumber`}>{data.details.chequeNumber}</td>;
  table[labelMappings.distributor] = <td key={`cheque-${data.id}-distributor`}>{data.distributor.title}</td>;
  table[labelMappings.route] = <td key={`cash-${data.id}-route`}>{data.outlet.route && data.outlet.route.title}</td>;
  table[labelMappings.images] = (
    <ImageColumn
      keyTitle={`rp-image-${data.id}`}
      imageList={data.details.image || []}
      onImageClick={handleSliderOpen}
      key={`rp-image-${data.id}`}
    />
  );
  table[labelMappings.actionHolder] = (
    <td key={`${data.id}-action`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {menuAction(
        pendingAction ? menuConfigList.PENDING : menuConfigList.RECEIVED,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        data,
        permission,
      )}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
  onIconClick,
  tableConfig,
  handleSliderOpen,
  checkedList,
  secondaryCheckboxHandler,
  section,
  permission,
  pendingAction,
}) => {
  const checked = checkedList ? checkedList.includes(data.id) : null;
  const tableBody = returnTableBody(
    data,
    onIconClick,
    handleSliderOpen,
    checkedList,
    secondaryCheckboxHandler,
    checked,
    section,
    permission,
    pendingAction,
  );

  return (
    <tr key={`cheque-${data.id}`} className={checked ? 'active' : ''}>
      {tableConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object),
  checkedList: PropTypes.instanceOf(Array),
  secondaryCheckboxHandler: PropTypes.func,
  section: PropTypes.string,
  onIconClick: PropTypes.func,
  handleSliderOpen: PropTypes.func.isRequired,
  pendingAction: PropTypes.bool,
};

TableBody.defaultProps = {
  permission: PERMISSION_OBJ,
  checkedList: [],
  secondaryCheckboxHandler: () => null,
  section: '',
  onIconClick: () => null,
  pendingAction: false,
};

export {
  TableHeader, TableBody, labelConfig
};
