import React, { Fragment } from 'react';
import {
  BreadCrumb,
  Button,
  Badge,
  Search,
  Switch,
  Collapsible,
} from '../../../../components';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { breadCrumb } from './config';
import CustomSelect from '../../../../components/CustomSelect';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import MTDetailStyled from './MTDetailStyled';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import BasicDetail from './BasicDetail';
import AssortmentPanelStyled from '../../channelAssortment/details/AssortmentPanelStyled';

const MTDetailView = (props) => {
  const {
    onIconClick,
    categoryList,
    brandList,
    basicDetail,
    frequency,
    assignedTo,
    data,
    onHandleCategoryChange,
    productCategoryList,
    onProductCategoryClick,
    onBrandClick,
    SKUFamilyList,
    count,
    skuAssortedList,
    onToggle,
    onHandleSearchInput,
    searchText,
    currentSkuFamilySkus,
  } = props;

  const {
    category, productCategories, brands, skuFamilies, skuFamiliesList,
  } = count;
  const { categoryId, productCategoryId, brandId } = data;

  const totalSkuIds = SKUFamilyList.map(item => item.skus.map(sku => sku.id)).flat() || [];
  const getCount = (countItems, id, key, type) => {
    const countItem = countItems.find(item => item[key] === id) || {};
    if (type === 'badge') {
      return countItem.count ? (
        <Badge light badge label={countItem.count} className="badge" />
      ) : (
        ''
      );
    }
    return countItem.count ? countItem.count : 0;
  };

  return (
    <MTDetailStyled>
      <div className="section-header">
        <PanelStyled>
          <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <div className="flex items-center justify-between m-0 flex-1">
              <h2>{basicDetail.title || ''}</h2>
              <div className="flex m-0">
                <Button
                  secondary
                  iconBtnSmall
                  onClick={() => onIconClick(EVENT_OPERATION.UPDATE)}
                  iconName="pencil"
                />
                <Button
                  secondary
                  iconBtnSmall
                  onClick={() => onIconClick(EVENT_OPERATION.DELETE)}
                  iconName="trash"
                />
              </div>
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className="section-content">
        <div className="create-ui">
          <BasicDetail
            basicDetail={basicDetail}
            frequency={frequency}
            assignedTo={assignedTo}
          />
          <div className="create-ui-inner tab-less-height">
            <section className="assortment-detail">
              <div className="select-section-wrap">
                <div className="select-section">
                  <CustomSelect
                    small
                    enableValidation
                    options={categoryList}
                    className="custom-select"
                    placeholder="Select BU"
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ id, title }) => (
                      <span>
                        {title}
                        {getCount(category, id, 'id', 'badge')}
                      </span>
                    )}
                    enableErrorDisplay={false}
                    onChange={event => onHandleCategoryChange(event.id)}
                    value={
                      categoryList.filter(({ id }) => id === categoryId) || []
                    }
                    isSearchable={false}
                  />
                  {categoryId
                    && getCount(category, categoryId, 'categoryId', 'badge')}
                </div>
              </div>
              {categoryId && (
                <AssortmentPanelStyled>
                  <div className="panel-section">
                    <div className="panel one">
                      <p className="p-title">Product Category</p>
                      <ul>
                        {productCategoryList.map(group => (
                          <li
                            key={group.id}
                            onClick={() => onProductCategoryClick(group.id)}
                            className={
                              parseInt(productCategoryId, 10) === group.id
                                ? 'active'
                                : 'inactive'
                            }
                            title={group.title}
                          >
                            <p>{group.title}</p>
                            {getCount(
                              productCategories,
                              group.id,
                              'id',
                              'badge',
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                    {productCategoryId && (
                      <div className="panel two">
                        <p className="p-title">Brands</p>
                        <ul>
                          {brandList.map(brand => (
                            <li
                              key={brand.id}
                              onClick={() => onBrandClick(brand.id)}
                              className={
                                parseInt(data.brandId, 10) === brand.id
                                  ? 'active'
                                  : 'inactive'
                              }
                              title={brand.title}
                            >
                              <p>{brand.title}</p>
                              {getCount(brands, brand.id, 'id', 'badge')}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {brandId && (
                      <div className="panel three">
                        <div className="panel-title">
                          <p className="p-title">SKU Family / SKUs</p>
                          <Search
                            searchText={searchText}
                            clearText={!searchText}
                            handleSearchInput={onHandleSearchInput}
                          />
                        </div>
                        <div className="div-thead">
                          <span>SKU</span>
                          <div className="status">
                            <span>Status</span>
                            <Switch
                              checked={totalSkuIds.every(r => skuAssortedList.includes(r))}
                              onToggle={(e) => {
                                onToggle(e, totalSkuIds);
                              }}
                            />
                          </div>
                        </div>
                        <div className="div-tbody">
                          {SKUFamilyList.map(skuFamily => (
                            <Collapsible
                              key={skuFamily.id}
                              trigger={
                                <>
                                  <span>
                                    <span className="text">
                                      {skuFamily.title}
                                      {getCount(
                                        skuFamiliesList,
                                        skuFamily.id,
                                        'skufamilies',
                                        'badge',
                                      )}
                                    </span>
                                  </span>
                                  <div onClick={e => e.stopPropagation()}>
                                    <Switch
                                      checked={
                                        getCount(
                                          skuFamiliesList,
                                          skuFamily.id,
                                          'skufamilies',
                                          'count',
                                        ) === skuFamily.skus.length
                                      }
                                      onToggle={(e) => {
                                        onToggle(
                                          e,
                                          skuFamily.skus.map(sku => sku.id),
                                        );
                                      }}
                                    />
                                  </div>
                                </>
                              }
                            >
                              {skuFamily.skus.map(sku => (
                                <div className="collapsible-inner" key={sku.id}>
                                  <span>{sku.title}</span>
                                  
                                  <Switch
                                    checked={skuAssortedList.includes(sku.id)}
                                    onToggle={(e) => {
                                      onToggle(e, [sku.id]);
                                    }}
                                  />
                                </div>
                              ))}
                            </Collapsible>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </AssortmentPanelStyled>
              )}
            </section>
          </div>
        </div>
      </div>
    </MTDetailStyled>
  );
};

export default withErrorAndLoadingScreen(MTDetailView);
