import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { Input, CustomSelect } from '../../../../../components';

const propTypes = {
  basicDetail: PropTypes.objectOf(Object).isRequired,
};

const BasicDetail = ({ basicDetail: { title, count_days, assigned_to }, frequency, assignedTo }) => (
  <section className="sbd-top-wrap disabled">
        <div className="flex">
        <Input name="title" type="text" value={title} labelContent="Title" />
        <div className="custom-date">
          <label>Frequency (in days)</label>
          <CustomSelect
            onChange={() => {}}
            value={frequency && frequency.filter(freq => freq.id === count_days)}
            getOptionValue={({ id }) => id}
            getOptionLabel={({ label }) => label}
            name="frequency"
          />
        </div>
        <div className="custom-date">
          <label>Assigned To</label>
          <CustomSelect
            onChange={() => {}}
            value={assignedTo && assignedTo.filter(ass => ass.id === assigned_to)}
            getOptionValue={({ id }) => id}
            getOptionLabel={({ firstName }) => firstName}
            name="assignedTo"
          />
        </div>
        </div>
  </section>
);

BasicDetail.propTypes = propTypes;

export default BasicDetail;
