import { css } from 'styled-components';
import Colors from '../../utils/theme/color';

// TODO handle global concerns
const base = css`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #272833;
  }

    body ::-webkit-scrollbar  {
    width: 15px;
}

body ::-webkit-scrollbar-thumb {
    height: auto;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
    background-color: #C1C1C1;
}

body ::-moz-scrollbar-thumb {
  
  height: auto;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
    scrollbar-color : #C1C1C1;
}


  button, input {
    font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .demo-section {
    max-width: 1170px;
    margin: 0 auto;
    padding-top: 50px;
    &.button-wrap{
      margin:unset;
      padding-top:unset;
      max-width:unset;
      height:100vh;
      padding:50px;
      overflow-y:auto;
    }
  }

  .demo-content-list {
    margin-bottom: 50px;
  }

  .demo-header h2 {
    margin-top: 0;
  }

  .demo-header p {
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 60px;
  }

  .demo-section .bg-light-grey {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f1f1;
    height: 170px;
  }

  .demo-section h6 {
    font-size: 14px;
    margin: 10px 0 20px;
  }

  .demo-alert-container {
    margin-bottom: 40px;
  }

  .demo-checkbox-container {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  /*Table UI*/

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    border: 1px solid #e7e7ed;

    svg.icon {
      color: #6B6C7E;
    }

    th, td {
      padding: 20px 16px;
      text-align: left;
      vertical-align: middle;
      color: #272833;
      border-bottom: 1px solid #e7e7ed;

      &:first-child {
        border-left: 1px solid #e7e7ed;
        padding-left: 48px;
      }

      &:last-child {
        border-right: 1px solid #e7e7ed;
        padding-right: 48px;
      }
    }

    th {
      background: #f1f2f5;
      color: #6b6c7e;
      font-weight: 600;
      transition: all 0.3s ease-in-out;
      padding: 16px;
    }

    tr {
      &:hover, &:focus {
        background: #F0F5FF;
        transition: all 0.3s ease-in-out;

        .actions > span {
          opacity: 1;
          pointer-events: auto;
        }
      }

    }

    .actions > span {
      opacity: 0;
      pointer-events: none;
      color: #6b6c7e;
      display: inline-block;
      margin-right: 32px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover, &:focus {
        color: #0056E0;
        transition: all 0.3s ease-in-out;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .table-wrap.promotion tbody tr {
    cursor: pointer;
  }

  .table-wrap.prn tbody tr {
    cursor: pointer;
}
/*SelectedStatus UI*/
.selected-status {
  
  background: #f0f5ff;
  border-bottom: 1px solid #0b5fff;
width:100%;
  
  .content-center {
      height:54px;
  padding: 14px 48px;
    display:flex;
    justify-content:space-between;
    .del-btn {
    cursor: pointer;
    background: transparent;
    width: 32px;
    height: 32px;
    padding: 6px;
    border-radius: 4px;
    -webkit-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    color: #6B6C7E;

    &:hover{
        background: #f1f2f5;
    }
    }
    .text{
     margin:0;
     margin-left:4%;
     font-size:14px;
     color: #272833;;
     span{
       margin:0 4px;
     }
    }

       &>div{
      display:flex;
        flex:1;
        text-align:right;
        align-items: center;
        &:nth-child(2),
        &.button-section{
          display:block;
        }
    }
  }
}

  
  /*Modal UI */

  .modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    opacity: 1;
    transition: opacity 0.3s;
  }

  .modal-wrapper.active {
    opacity: 1;
    transition: opacity 0.3s;
  }

  .modal-inner {
    max-width: 395px;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 1100;
  }

  /* .error-input:last-child {
      margin-bottom: 0 !important;    // because of the issue in Vendor section of Detail in GRN
  } */

  .modal-wrapper .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #393a4a !important;
    pointer-events: none;
    opacity: 0.8;
    transition: opacity 0.3s;
    left: 0 !important;
    width: 100% !important;
  }

  body.overflow {
    overflow: hidden;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-24:last-child {
    margin-bottom: 0;
  }

  .custom-select > div {
    margin-bottom: 24px;
    background: #FAFBFC;
    min-height: 40px;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .form-input {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  /*Target Detail*/

  .target-detail-header ul {
    display: flex;
    flex-wrap: wrap;
    background: #f1f2f5;
    padding: 16px;
    font-size: 12px;
    color: #6b6c7e;
    font-weight: 600;
  }

  .target-detail-header ul li {
    width: 50%;
  }

  ul.target-list {
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
    border-bottom: 1px solid #f6f6f6;
    border-left: 1px solid #f6f6f6;
    border-right: 1px solid #f6f6f6;
    font-size: 12px;
  }

  ul.target-list li {
    width: 50%;
  }

  .target-button-wrap {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }

  .target-trigger {
    cursor: pointer;
  }

  .target-trigger:hover,
  .target-trigger:focus {
    background: #e8f1fa;
  }

  .target-trigger .target-list li:first-child {
    position: relative;
  }

  .target-trigger .target-list input[type="text"] {
    background: transparent;
    padding: 0;
    border: none;
    border-bottom: 1px solid #C1C7D0;
    border-radius: 0;
    height: auto;
    width: auto;
    min-width: auto;
    font-size: 12px;
  }

  .target-trigger .target-list li:first-child:after {
    content: "";
    position: absolute;
    left: 250px;
    background: url("/image/icons/ic_dropdown.svg") no-repeat center;
    width: 15px;
    height: 15px;
    background-size: 5px;
    transform: rotate(90deg);
  }

  .target-nested ul li:first-child {
    padding-left: 15px;
  }

  .target-nested .target-collapsible-list .target-collapsible-list ul li:first-child {
    padding-left: 30px;
  }

  .target-nested .target-collapsible-list .target-collapsible-list .target-collapsible-list ul li:first-child {
    padding-left: 45px;
  }

  .target-nested .target-collapsible-list .target-collapsible-list .target-collapsible-list .target-collapsible-list ul li:first-child {
    padding-left: 60px;
  }

  .target-nested .target-collapsible-list .target-collapsible-list .target-collapsible-list .target-collapsible-list .target-collapsible-list ul li:first-child {
    padding-left: 75px;
  }

  .target-nested .target-collapsible-list .target-collapsible-list .target-collapsible-list .target-collapsible-list .target-collapsible-list .target-collapsible-list ul li:first-child {
    padding-left: 90px;
  }

  .target-nested {
    background: aliceblue;
  }

  /*CreateUI page*/

  .flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px -32px;
  }

  .w-half {
    width: 50%;
    padding: 0 16px;
    margin-bottom: 32px;
  }

  .pswp-thumbnails {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px -32px;
  }

  .pswp-thumbnail {
    width: 33.33%;
    padding: 0 16px;
    margin-bottom: 32px;
    cursor: pointer;
    transition: all 0.32s ease-in-out;
  }

  .pswp-thumbnail:hover,
  .pswp-thumbnail:focus {
    transform: translateY(-10px);
    transition: all 0.32s ease-in-out;
  }

  .pswp-thumbnail img {
    display: block;
    background: #ddd;
    height: 240px;
    object-fit: cover;
    width: 100%;
    border-radius: 4px 4px 0 0;
  }

  .pswp-thumbnail span {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    background: #fff;
    padding: 14px 16px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px 0 rgba(39, 40, 51, 0.12);
    font-size: 14px;
    color: #272833;
  }

  /*Button UI*/

  tr .pending {
    color: #6B6C7E;
    text-transform: uppercase;
    font-size: 12px;
    display: inline-block;
    border: 1px solid #CDCED9;
    padding: 4px 8px;
    text-align: center;
    font-weight: 600;
  }

  tr .category-list {
    border: 1px solid #ccc;
    color: #6B6C7E;
    font-weight: 600;
    padding: 4px 8px;
    margin: 0 4px 4px 0;
    display: inline-block;
  }

  tr .category-list-modified {
    display: inline-block;
  }

  button.success {
    padding: 6px 12px;
    color: #fff;
    background: #50D2A0;
    border-color: #50D2A0;
    transition: all .32s ease-in-out;
    font-size: 14px;
    line-height: 21px;
  }

  button.success:hover,
  button.success:focus {
    background: #0db681;
    border-color: #0db681;
    transition: all .32s ease-in-out;
  }

  button.danger {
    padding: 6px 12px;
    color: #fff;
    background: #FF5F5F;
    border-color: #FF5F5F;
    transition: all .32s ease-in-out;
    font-size: 14px;
    line-height: 21px;
  }

  button.danger:hover,
  button.danger:focus {
    background: #d95353;
    border-color: #d95353;
    transition: all .32s ease-in-out;
  }

  /*Dropdown UI*/

  .merchandise-dialog .filter-dropdown-card {
    left: 0;
    right: 0 !important;
    width: 100%;
    min-width: unset !important;
  }

  .merchandise-dialog .suggested-autocomplete-list-wrap {
    margin-top: 16px;
  }

  .merchandise-dialog .suggested-autocomplete-list {
    display: inline-block;
    border: 1px solid #90b8fa;
    padding: 4px 20px 4px 4px;
    font-size: 12px;
    margin: 0 8px 8px 0;
    color: #8995a6;
    position: relative;
  }

  .merchandise-dialog .ic-close {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 14px;
    height: 14px;
    opacity: 0.3;
  }

  .merchandise-dialog .ic-close:hover {
    opacity: 1;
    cursor: pointer;
  }

  .merchandise-dialog .ic-close:before,
  .merchandise-dialog .ic-close:after {
    position: absolute;
    top: 0;
    left: 7px;
    content: ' ';
    height: 13px;
    width: 2px;
    background-color: #333;
  }

  .merchandise-dialog .ic-close:before {
    transform: rotate(45deg);
  }

  .merchandise-dialog .ic-close:after {
    transform: rotate(-45deg);
  }

  .filter-dropdown-card .autocomplete-list {
    overflow-y: scroll;
    max-height: 200px;
    padding: 4px 0;
  }

  .filter-dropdown-card .autocomplete-list > div {
    cursor: pointer;
    padding: 10px 16px;
    border-bottom: 1px solid #dde1e6;
    font-size: 14px;
  }

  .filter-dropdown-card .autocomplete-list > div:last-child {
    margin-bottom: 0;
  }

  .item.is-dragging {
    box-shadow: 0 2px 4px 0 rgba(39, 40, 51, 0.12) !important;
    padding: 16px !important;
    border-radius: 4px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #272833 !important;
    background: rgba(11, 95, 255, 0.2) !important;
  }

  .item.is-dragging > div {
    margin-right: 16px !important;
    top: -2px !important;
  }

  .bill-discount-indicator {
    border: 3px solid #51D2A0;
    border-radius: 6px;
    display: inline-block;
    margin-right: 6px;
  }

  .top-up-indicator {
    border: 3px solid #FFB46E;
    border-radius: 6px;
    display: inline-block;
    margin-right: 6px;
  }

  .free-sku-indicator, .trade-discount-indicator {
    border: 3px solid #3178ff;
    border-radius: 6px;
    display: inline-block;
    margin-right: 6px;
  }
  .focused-sku-indicator {
    background-color: #4B9BFF;
    display:inline-block;
    border-radius:50%;
    text-align: center;
    font-size:10px;
    color: #FFFFFF;
    width: 20px;
    margin-left: 9px;
  }
  .focused-sku-indicator:after {
    content:'F';
    display:inline-block;
    line-height:0;
    font-size: 10px;
    font-weight: 600;
    padding-top: 50%;
    padding-bottom: 50%;
  }
  
  .thumbnail-overlay {
    top: 0px;
    position: absolute;
    display: block;
    background: rgba(0, 0, 0, 0.4);
    width: 32px;
    height: 32px;
    color: #f0f5ff;
    padding: 7px 12px 7px 6px;
    margin: 0px 4px;
    font-size: 12px;
    text-align:left;
    line-height:18px;
    border-radius:4px;
  }

  .thumbnail-img {
    pointer-events: all !important
    cursor:pointer !important
    height: 32px;
    width: 32px;
    border-radius: 4px;
    margin: 0px 4px;
  }
  .pswp {
    pointer-events: all !important;
  }

  .relative {
    position: relative;
  }

  .disabled-btn {
    opacity: 0.4;
    cursor: no-drop;
    pointer-events: none;
  }

  .margin-left-16 {
    margin-left: 16px;
  }


  //Base Styles Here
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .scrollable-body {
    max-height: calc(100vh - 200px);
    overflow: scroll;
  }

  .collapsible-body-tr td.collapsible-body-td {
    padding: 0px;
  }

  .accent-font {
    color: ${Colors.grey.G1100};
  }

  .disabled {
    cursor: not-allowed;
    /* pointer-events: none !important;*/

    > * {
      pointer-events: none ;
    }
  }

  .disabled-overlay {
    background: rgba(0, 0, 0, 0.8);
  }

  .status-label {
    border: 1px solid transparent;
    padding: 4px 8px 5px;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    border-radius: 2px;
    min-width: 73px;
    height: 24px;
    line-height:15px;
    text-align: center;
    white-space: nowrap;
    &.active {
      border-color: #47c464; 
      color: #287d3c;
    }
    &.passive {
      border-color: #ff8f39;
      color: #b95000;
    }
  }

  .css-bgvzuu-indicatorSeparator {
    display: none;
  }

  .css-16pqwjk-indicatorContainer {
    color: #6B6C7E !important;
  }


`;

export default base;
