import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import MTView from './View';
import MTDialog from './components/MTDialog';
import PageHeader from '../../base/PageHeader';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { getPermissionForSbd } from '../../base/permission';
import withAlert from '../../../utils/composition/withAlert';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { MTCONFIG } from '../../common/DomainConfig';
import { breadCrumb as breadCrumbConfig, filterConfig } from './config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MT_DETAILS } from '../../../data/enums/Route';
import { crudResponseProcessor } from '../../../utils/crudResponseProcessor';
import { crudRequestConfig } from './components/MTDialog/config';
import { USER_ROLE } from '../../../data/enums';


const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getMTListWithTitle: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class MT extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 1,
      },
      display: {
        searchBox: false,
      },
      dialog: {
        type: '',
        element: '',
      },
      queryParameters: {
        sort: queryService.baseQueryParameters.sort,
        date: { ...queryService.baseQueryParameters.date },
        filter: queryService.baseQueryParameters.filter,
        search: queryService.baseQueryParameters.search,
        pagination: queryService.baseQueryParameters.pagination,
      },
      frequency: [],
      assignedTo: [],
    };
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.setData = (data) => {
      this.setState({ data });
    };
    this.permission = getPermissionForSbd();

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
  }

  componentDidMount() {
    this.loadTableData();
    this.getFrequencyAndUsers();
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getMTListWithTitle, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getMTListWithTitle({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        // dateRange: queryParameters.date,
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (response) => {
        data.list = (response.data.getMTStockList && response.data.getMTStockList.rows) || [];
        data.total = (response.data.getMTStockList && response.data.getMTStockList.count) || 0;
        this.setState(data);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  loadUsers = () => {
    const { options } = this.state;
    const { getUsers, displayAlert } = this.props;
    getUsers({
      offset: 0,
      limit: 500,
      filter: {
        filters: [
          {
            column: 'role_id',
            value: [
              USER_ROLE.DSE.toString(),
            ],
          },
        ],
      },
    }, {
      handleSuccess: (response) => {
        const option = (response.data.users && response.data.users.rows) || [];
        this.setState({ options: option });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  loadFrequencies = () => {
    const { frequencyOptions } = this.state;
    const { routeVisitFrequencyList, displayAlert } = this.props;
    routeVisitFrequencyList({
      offset: 0,
      limit: 500,
      filter: {
      },
    }, {
      handleSuccess: (response) => {
        const frequencyList = (response.data.routeVisitFrequencyList && response.data.routeVisitFrequencyList.rows) || [];
        const options = frequencyList.map(row => ({ id: row.numberOfDays, label: row.label }));
        this.setState({ frequencyOptions: options });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  }

  getFrequencyAndUsers = () => {
    const { assignedTo, queryParameters } = this.state;
    const { getRouteVisitFrequencyList, getUsersList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getRouteVisitFrequencyList({
    }, {
      handleSuccess: (response) => {
        const { data: { routeVisitFrequencyList } } = response;
        const options = routeVisitFrequencyList.rows.map(row => ({ id: row.numberOfDays, label: row.label }));
        this.setState({ frequency: options });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });


    getUsersList({
      offset: 0,
      limit: 500,
      filter: {
        // filters: [
        //   {
        //     column: 'role_id',
        //     value: [
        //       USER_ROLE.DSE.toString(),
        //     ],
        //   },
        // ],
      },
    }, {
      handleSuccess: (response) => {
        const { data: { users } } = response;
        this.setState({ assignedTo: users.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  handleIconClick = (type, element) => {
    const { history } = this.props;
    const id = element ? element.id || '' : '';

    switch (type) {
      case EVENT_OPERATION.DETAILS:
        history.push(`/${MT_DETAILS}/${id}`);
        break;
      case EVENT_OPERATION.CREATE:
      case EVENT_OPERATION.UPDATE:
      case EVENT_OPERATION.DELETE:
        this.setState({
          dialog: {
            type,
            element,
          },
        });
        break;
      default:
        history.push(`/${MT_DETAILS}/${id}`);
    }
  };

  handleSubmit = (type, response) => {
    const { displayAlert } = this.props;
    const { setData, getData } = this;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    if (type === 'CREATE') {
      response.countskus = 0;
    }
    if (type === 'DELETE') {
      response.ids = [response.id];
    }
    crudResponseProcessor(setData, getData, ['title', 'count_days', 'assigned_to'])(type)(response);
  };

  handleClose = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  handleApiFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    //this.handleClose();
  };


  render() {
    const {
      data, queryParameters, display, dialog, options, frequency, assignedTo,
    } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: MTCONFIG.title,
                create: this.permission.create,
                download: false,
                filter: false,
                search: true,
                filter: true,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleCreateClick={this.handleIconClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>

        <div className="section-content table-present">
          <MTView
            data={data}
            permission={this.permission}
            loading={serverResponseWaiting}
            pagination={queryParameters.pagination}
            onIconClick={this.handleIconClick}
            onBulkDelete={this.handleSubmit}
            onPageChange={this.basePaginationService.onPageSelect}
          />
          {
            dialog.type && (
              <div className="sbd-modal">
                <MTDialog
                  type={dialog.type}
                  element={dialog.element}
                  onSubmit={this.handleSubmit}
                  onClose={this.handleClose}
                  onApiFailure={this.handleApiFailure}
                  options={options}
                  frequency={frequency}
                  assignedTo={assignedTo}
                />
              </div>
            )
          }
        </div>
      </Fragment>
    );
  }
}
MT.propTypes = propTypes;

MT.defaultProps = defaultProps;

export default withAlert()(MT);
