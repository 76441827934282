import { breadCrumbConfig } from '../../config';
import { MOQ } from '../../../../../../data/enums/Route';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 3,
    title: 'MOQ',
    path: `/${MOQ}`,
    active: true,
  },
];
const REQUISITION_VIEW = {
  BRAND: 'BRAND',
};
const newList = [
  { id: 1, label: 'BRAND' },
];
const printEnums = {
  titleOffset: 52,
};
export {
  breadCrumb as breadCrumbConfig, REQUISITION_VIEW, newList, printEnums,
};
