import { compose } from 'recompose';
import MOQ from './MOQ';
import {
  query, mutation, EMAIL_PURCHASE, DOWNLOAD_PURCHASE, PRINT_PURCHASE,
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposetMOQ = compose(
  EMAIL_PURCHASE, DOWNLOAD_PURCHASE, PRINT_PURCHASE,
  withRequestTracker({
    query,
    mutation,
  }),
)(MOQ);

export default ComposetMOQ;
