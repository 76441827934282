import { refGenerator } from '../../../../../../utils';
import { FORM_CONFIG } from '../../../../../../data/enums/config';
import LEDGERS from '../../../../../../data/enums/Ledgers';

const detailedCustomerMapper = (element) => ({
  name: element.Customer ? element.Customer.name : '',
  id: element.Customer ? element.Customer.id : '',
  address: element.Customer ? element.Customer.address : '',
  panNumber: element.Customer ? element.Customer.panNumber : '',
  phoneNumber: element.Customer ? element.Customer.phoneNumber : '',
  city: element.Customer ? element.Customer.city : '',
  state: element.Customer ? element.Customer.state : '',
  country: element.Customer ? element.Customer.country : '',
  creditLimit: element.Customer ? element.Customer.creditLimit : 0,
  creditDay: element.Customer ? element.Customer.creditDay : 0,
});

const customerFormConfig = (element) => ({
  [FORM_CONFIG.MAPPER]: () => detailedCustomerMapper(element),
  [FORM_CONFIG.TITLE]: 'Create/Update Ledgers',
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['id']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
});
/** todo customer details form config */

const cashLedger = {
  id: LEDGERS.CASH.ledger_id,
  name: LEDGERS.CASH.name,
};

const requiredFields = {
  billing: ['customerId', 'remarks'],
  nonBilling: ['invoiceNumber'],
};

const restUrl = '/api/v1/ledgers/getOutstandingValue/';
const billingHeaders = {
  Authorization: 'Bearer asdfghjklasdfghjkl-12',
  Accept: 'application/json',
  'Content-Type': 'application/json',
};
export {
  requiredFields, customerFormConfig, cashLedger, restUrl, billingHeaders
};
