import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import Form from './Form';
import UploadForm from './UploadForm';
import { DialogFormWrapper, DialogWrapper } from '../../common';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { formConfig, breadCrumbConfig, crudSuccess as crudRequestConfig, set } from './config';
import { FORM_CONFIG } from '../../../data/enums/config';
import withAlert from '../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { TARGET_ACHIEVEMENT_DETAILS, OUTLET_TARGET_SUBD } from '../../../data/enums/Route';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { isError } from '../../common/HelperFunctions';
import Input from '../../../components/Input/Text';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { DOWNLOAD_DOMAIN } from '../../../data/enums/GraphQL';
import BrandDialogWrapper from './BrandDialogWrapper';
import { Icon } from '../../../v4/components';
import { getStartOfCurrentMonth } from '../../../utils/date';
import { MESSAGE_EVENT_OPERATION } from '../../../v4/constants/successMessage';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getOutletTarget: PropTypes.func.isRequired,
  createOutletTarget: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getBUSettings: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  downloadReport: PropTypes.func,
  getSubDBrandTarget: PropTypes.func,
  getUserBrandTarget: PropTypes.func,
  history: PropTypes.instanceOf(Object),
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
  getSubDBrandTarget: () => null,
  getUserBrandTarget: () => null,
  history: {},
};

class TargetAchievement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 1,
      },
      dialog: {
        type: '',
        element: '',
      },
      pagination: {
        page: 0,
        limit: 50,
      },
      // eslint-disable-next-line react/no-unused-state
      date: {
        start: new Date(),
        end: new Date(),
      },
      settings: {
        salesTeamWise: null,
        brandWise: null,
        subDWise: null,
      },
      brandDialogData: [
        {
          id: 0,
          brandName: 'xxxxx',
          target: 0,
          monthName: 'xxxxx',
          year: 'xxxx',
          total: 0,
        },
      ],
      csvUploadData: [],
    };

    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: props.upload,
      [EVENT_OPERATION.CREATE]: props.createOutletTarget,
      [EVENT_OPERATION.UPDATE]: props.updateBUTarget,
    };

    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        let message = crudRequestConfig[type].message;
        if (type === EVENT_OPERATION.UPLOAD) {
          message = data;
          setTimeout(() => {
            this.resetDialog();
          }, 1000);
        } else {
          this.setState(data);
        }
        this.getList();
        displayAlert(ALERT_TYPE.SUCCESS, message);
      },
      this.getData,
      ['target'],
    );

    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.getList();
    this.loadBUSettings();
  }

  getCSVHeader = async event => {
    return new Promise((resolve, reject) => {
      if (typeof FileReader != 'undefined') {
        const myReader = new FileReader();
        myReader.onload = e => {
          const content = myReader.result;
          const csvHeader = content.split(/[\r\n]+/)[1];
          const csvHeaderFiltered = csvHeader.replace(/&/g, '').replace(/</g, '').replace(/"/g, '').split(',');
          resolve({ data: csvHeaderFiltered, isError: false, message: '' });
        };
        myReader.onerror = e => {
          reject({
            data: [],
            isError: true,
            message: 'Error while processing CSV file',
          });
        };
        myReader.readAsText(event.files[0]);
      } else {
        reject({
          data: [],
          isError: true,
          message: 'This browser does not support HTML5.',
        });
      }
    });
  };
  processFile = async event => {
    const csvHeaderInfo = await this.getCSVHeader(event);
    this.setState({ csvUploadData: csvHeaderInfo.data });
  };
  handleFileUpload = (event, type, dialogData) => {
    const { dialog } = this.state;
    dialog.element.file = event.target.files[0];
    const e = event.target;
    this.setState({ dialog }, () => {
      this.processFile(e);
    });
  };

  handleButtonSubmit = (type, dialogData) => {
    // debugger;
    const { displayAlert, createOutletTarget } = this.props;
    const { dialog, csvUploadData, data } = this.state;
    const dataListDate = data?.list.map(a => {
      const year = `${a.year}/${a.month}`;
      return year;
    });
    const csvUploadDate = `${csvUploadData[0]}/${csvUploadData[1]}`;
    const compareYear = dataListDate.some(b => b === csvUploadDate);
    if (type === EVENT_OPERATION.UPLOAD) {
      if (compareYear) {
        dialog.type = EVENT_OPERATION.REPLACE;
        this.setState(dialog);
        return;
      }
      dialog?.element?.file !== undefined
        ? this.onFormSubmit(type, dialog.element)
        : displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Please upload file.');
      return;
    }
    if (type === EVENT_OPERATION.REPLACE) {
      dialog.type = EVENT_OPERATION.UPLOAD;
      this.onFormSubmit(dialog.type, dialog.element);
      return;
    }
    if (type === EVENT_OPERATION.CREATE) {
      // debugger;
      createOutletTarget(
        {
          input: {
            start_date: dialogData.startDate || getStartOfCurrentMonth(),
            end_date: dialogData.endDate,
            title: dialogData.title,
          },
        },
        {
          handleSuccess: () => {
            const { displayAlert } = this.props;
            displayAlert(ALERT_TYPE.SUCCESS, ` ${dialogData.title} ${MESSAGE_EVENT_OPERATION.CREATE}`);
            this.getList();
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );

      return;
    }
    this.onFormSubmit(type, dialogData);
  };
  loadBUSettings = () => {
    const { getBUSettings } = this.props;
    getBUSettings(
      {},
      {
        handleSuccess: res => {
          const settings = res.data.settings.targets;
          this.setState({ settings });
          localStorage.setItem('settings', JSON.stringify(settings));
        },
        handleFailure: err => {},
      },
    );
  };

  getList = () => {
    const { getOutletTarget, displayAlert } = this.props;
    const { data } = this.state;

    getOutletTarget(
      {},
      {
        handleSuccess: response => {
          data.list =
            (response.data.listOutletFundamentalTarget && response.data.listOutletFundamentalTarget.rows) || [];
          data.total =
            (response.data.listOutletFundamentalTarget && response.data.listOutletFundamentalTarget.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ data });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handlePaginationChange = pagination => {
    this.setState({ pagination }, () => this.getList());
  };

  handleIconClick = (type, element) => {
    this.updateDialog(type, element);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    const { dialog } = this.state;
    if (dialog.type === EVENT_OPERATION.UPLOAD) {
      setTimeout(() => {
        this.resetDialog();
      }, 1000);
    }
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onFilterChange = (field, value) => {
    if (field === 'date') {
      this.setState({ date: value }, () => this.getList());
    } else {
      this.setState({ filter: field }, () => this.getList());
    }
  };

  updateDialog = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
      csvUploadData: [],
    });
  };

  onTableBodyClick = data => {
    const { history } = this.props;
    set('outletTarget', data);
    history.push({
      pathname: `/${OUTLET_TARGET_SUBD}`,
      state: { id: data.id },
    });
  };

  handleCSVUpload = type => {
    this.updateDialog(EVENT_OPERATION.UPLOAD, { name: type });
  };

  isConfirmationType = type =>
    type === EVENT_OPERATION.DELETE || type === EVENT_OPERATION.BULK_DELETE || type === EVENT_OPERATION.UPDATE_STATUS;

  handleBrandDialog = id => {
    const { getUserBrandTarget, getSubDBrandTarget, displayAlert } = this.props;
    const { settings } = this.state;
    if (settings.salesTeamWise) {
      getUserBrandTarget(
        {
          buTargetId: id,
        },
        {
          handleSuccess: response => {
            this.setState({ brandDialogData: response.data.userBrandTargetsPerMonth });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
    if (settings.subDWise) {
      this.setState({
        brandDialog: id,
      });
      getSubDBrandTarget(
        {
          buTargetId: id,
        },
        {
          handleSuccess: response => {
            this.setState({ brandDialogData: response.data.subdBrandTargetsPerMonth });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  onSampleDownload = entity => {
    const { downloadReport, displayAlert } = this.props;
    downloadReport(
      {
        input: {
          domain: entity,
          reportType: 'csv',
        },
      },
      {
        handleSuccess: response => {
          const { downloadReport } = response.data;
          window.open(`${window.location.origin}/${downloadReport.file.name}`);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  render() {
    const { data, dialog, settings, pagination, brandDialogData } = this.state;

    const { type } = dialog;

    const { serverResponseWaiting } = this.props;

    const sampleUrl = checkCase => (
      <a onClick={() => this.onSampleDownload(checkCase)}>
        <span className="sample-csv">
          {' '}
          <Icon iconName="download" />
          Sample.csv
        </span>
      </a>
    );

    const sampleCase = setting => {
      if (setting.salesTeamWise && setting.brandWise) {
        return sampleUrl(DOWNLOAD_DOMAIN.USER_BRAND_TARGET_SAMPLE);
      }
      if (setting.salesTeamWise && !setting.brandWise) {
        return sampleUrl(DOWNLOAD_DOMAIN.USER_TARGET_SAMPLE);
      }
      if (setting.subDWise && setting.brandWise) {
        return sampleUrl(DOWNLOAD_DOMAIN.DISTRIBUTOR_BRAND_TARGET_SAMPLE);
      }
      if (setting.subDWise && !setting.brandWise) {
        return sampleUrl(DOWNLOAD_DOMAIN.DISTRIBUTOR_TARGET_SAMPLE);
      }
    };
    const formTitle =
      type === EVENT_OPERATION.CREATE || type === EVENT_OPERATION.UPLOAD
        ? ''
        : `${dialog.element.monthName}, ${dialog.element.year}`;

    return (
      <BrandDialogWrapper>
        {type && (
          <div className="targets-wrapper">
            <DialogFormWrapper
              type={type}
              dialogElement={dialog.element}
              formConfig={formConfig[type]}
              onDialogCancel={this.resetDialog}
              onDialogSubmit={this.handleButtonSubmit}
              fileUploadRefs={formConfig[FORM_CONFIG.REFS_OBJ]}
              formTitle={type !== EVENT_OPERATION.REPLACE && formTitle}
              controlDialogClose={type === EVENT_OPERATION.UPLOAD}
              submitClickedControl
              renderDialog={({
                dialogData,
                enableErrorDisplay,
                handleInputChange,
                refsObj,
                fileUploadRefs,
                handleFileUpload,
                handleDateChange,
              }) => (
                <Fragment>
                  {(type === EVENT_OPERATION.CREATE ||
                    type === EVENT_OPERATION.UPDATE ||
                    type === EVENT_OPERATION.READ) && (
                    <>
                      <Form
                        show
                        type={type}
                        refsObj={refsObj}
                        data={dialogData}
                        enableErrorDisplay={enableErrorDisplay}
                        handleInputChange={handleInputChange}
                        handleDateChange={handleDateChange}
                        brandDialogData={brandDialogData}
                      />
                    </>
                  )}
                  {type === EVENT_OPERATION.UPLOAD && (
                    <>
                      <UploadForm
                        show
                        refsObj={fileUploadRefs}
                        data={dialogData}
                        loading={serverResponseWaiting}
                        handleFileUpload={e => this.handleFileUpload(e, type, dialogData)}
                        enableErrorDisplay={enableErrorDisplay}
                      />
                      {sampleCase(settings)}
                    </>
                  )}
                  {type === EVENT_OPERATION.REPLACE && (
                    <>
                      <p>
                        Are you sure you want to <b>replace</b> the month target according to uploaded data.{' '}
                      </p>
                    </>
                  )}
                </Fragment>
              )}
            />
          </div>
        )}
        <DialogWrapper
          title={brandDialogData[0] && `${brandDialogData[0].monthName}, ${brandDialogData[0].year}`}
          footer
          withOutPadding
          renderDialog={() => (
            <>
              {brandDialogData.length !== 0 ? (
                <Fragment>
                  <Input
                    disabled
                    type="text"
                    name="monthly"
                    labelContent="Monthly Target"
                    value={fixedFloatAndCommas(brandDialogData[0].total)}
                  />
                  <div className="table-wrap">
                    <table>
                      <thead>
                        <tr>
                          <th>Brand</th>
                          <th>Brand Target</th>
                        </tr>
                      </thead>
                      <tbody>
                        {brandDialogData.map(brand => (
                          <tr>
                            <td>{brand.brandName}</td>
                            <td>{fixedFloatAndCommas(brand.target)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Fragment>
              ) : (
                <h2 className="text-center">No Brand Target</h2>
              )}
            </>
          )}
          render={({ onDialogItemClick }) => (
            <View
              data={data}
              settings={settings}
              pagination={pagination}
              breadCrumb={breadCrumbConfig}
              loading={serverResponseWaiting}
              onIconClick={onDialogItemClick}
              onTableBodyClick={this.onTableBodyClick}
              onPageChange={this.handlePaginationChange}
              onCSVUpload={this.handleCSVUpload}
              onHandleBrandDialog={this.handleBrandDialog}
              handleIconClick={this.handleIconClick}
            />
          )}
        />
      </BrandDialogWrapper>
    );
  }
}

TargetAchievement.propTypes = propTypes;

TargetAchievement.defaultProps = defaultProps;

export default withAlert()(TargetAchievement);
