import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../utils/paginatedListUtils';
import { normalPresentor } from '../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../utils/conversion';

const labelMappings = {
  poId: 'poId',
  date: 'date',
  channel: 'channel',
  amount: 'amount',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.poId,
    title: 'POID',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.channel,
    title: 'Channel',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
  },
];


const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.poId] = (
    <td key={`${data.id}-id`}>{data.id}</td>
  );
  table[labelMappings.date] = (
    <td key={`${data.id}-date`}>
      {normalPresentor(data.purchaseOrderDate)}
    </td>
  );
  table[labelMappings.channel] = (
    <td key={`${data.id}-id`}>{data.Channel ? data.Channel.title : ''}</td>
  );
  table[labelMappings.amount] = (
    <td key={`${data.id}-id`}>{fixedFloatAndCommas(data.amountDetails && data.amountDetails.grossAmount)}</td>
  );

  return table;
};

const TableBody = ({
  data, onRowClick,
}) => {
  const tableBody = returnTableBody(data);

  return (

    <tr
      className="cursor-pointer"
      key={`prchaseOrder-${data.id}`}
      onClick={() => { onRowClick(data.id); }}
    >
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};


export {
  TableHeader,
  TableBody,
};
