import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from './View';
import MapDialog from './MapDialog';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import { imageSliderListFormatter, generateRequestMessage, isError } from '../../../common/HelperFunctions';
import { DialogWrapper } from '../../../common';
import PageHeader from '../../../base/PageHeader';
import GeoLocationStyled from './GeoLocationStyled';
import * as queryService from '../../../base/query.service';
import { PanelStyled } from '../../../common/configuration';
import withAlert from '../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import ModalWrapper from '../../../common/DialogFormWrapper';
import { APPROVAL_PROCESS } from '../../../../data/enums/Support';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import confirmationGenerator from '../../../common/DialogConfirmation';
import { breadCrumbConfig, title, formConfig, filterConfig, updateHistoryConfig } from './config';
import { getPermissionForApprovalRequest } from '../../../base/permission';
import { formConfig as confirmConfig } from '../../../components/RequestBulkSelect/config';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { normalPresentor } from '../../../../utils/date';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../../common/HelperFunctions';
import { clone } from '../../../../utils/objectProcessor';
import { storageKeys } from '../../../sales/orderProcessing/config';
import { DOMAIN } from '../../../../data/enums/config';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getApprovalRequests: PropTypes.func.isRequired,
  bulkUpdateApprovalRequest: PropTypes.func.isRequired,
  geolocationUpdateHistory: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  downloadReport: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};
class GeoLocation extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      dialog: {
        type: '',
        element: {},
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      updateHistory: [],
    };
    const { downloadReport, displayAlert } = props;
    this.permission = getPermissionForApprovalRequest();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.GEO_LOCATION
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.GEO_LOCATION);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };


  setQueryParameters = (queryParams, callBack = () => null) => {
    this.setState({ queryParameters: queryParams }, callBack);
  };

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  handleRowClick = data => {
    this.setState({
      dialog: {
        element: data,
      },
    });
  };

  getUpdateHistory = id => {
    const { geolocationUpdateHistory, displayAlert } = this.props;
    geolocationUpdateHistory(
      { outletId: id },
      {
        handleSuccess: response => {
          this.setState({ updateHistory: response.data.geolocationUpdateHistory || [] });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  addImageTitle = (imgList, roTitle, imgTitle) =>
    imgList.map((img, index) => {
      img.title = `${roTitle}( ${imgTitle} ) ${
        index + 1
      }                                                                 `;
      return img;
    });

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getApprovalRequests, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getApprovalRequests(
      {
        offset,
        type: APPROVAL_PROCESS.OUTLET_GEOLOCATION_CHANGE_APPROVAL.type,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: response => {
          const geolocationList = (response.data.approvalRequests && response.data.approvalRequests.rows) || [];
          data.total = (response.data.approvalRequests && response.data.approvalRequests.count) || 0;
          data.list = geolocationList.map(geo => {
            if (geo.geolocationChangeData.image) {
              const roImagesRef = geo.geolocationChangeData.RetailOutlet.imageUrl;
              const roTitle = geo.geolocationChangeData.RetailOutlet.title;
              const roImages = this.addImageTitle(
                (roImagesRef && roImagesRef.length && roImagesRef) || [],
                roTitle,
                'Retail Outlet',
              );
              const geoImages = this.addImageTitle(geo.geolocationChangeData.image, roTitle, 'Geo Location');
              geo.geolocationChangeData.image = [
                ...(imageSliderListFormatter([...geoImages, ...roImages], '', { w: 2400, h: 1100 }) || []),
              ];
            }
            if (geo.unsuccessfulOutletImage) {
              geo.unsuccessfulOutletImage = [
                ...(imageSliderListFormatter(geo.unsuccessfulOutletImage, '', { w: 2400, h: 1100 }) || []),
              ];
            }
            return geo;
          });
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleRequest = (status, checkedList, checkStatus = () => null) => {
    const { bulkUpdateApprovalRequest, displayAlert } = this.props;
    bulkUpdateApprovalRequest(
      {
        ids: checkedList,
        processId: APPROVAL_PROCESS.OUTLET_GEOLOCATION_CHANGE_APPROVAL.processId,
        status,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, generateRequestMessage(status));
          this.loadTableData();
          checkStatus();
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  handleClose = () => {
    this.setState({ dialog: { type: '', element: '' } });
  };

  onActionClick = (status, checkedList, param) => {
    if (param === 'history') {
      this.getUpdateHistory(checkedList.geolocationChangeData.outletId);
    }
    this.setState({
      dialog: {
        type: status,
        element: checkedList,
      },
    });
  };

  render() {
    const { data, queryParameters, dialog, updateHistory,filterMenu } = this.state;
    const { element, type } = dialog;
    const { serverResponseWaiting } = this.props;
    const dialogTitle = element.geolocationChangeData ? element.geolocationChangeData.RetailOutlet.title : '';
    return (
      <GeoLocationStyled>
        <DialogWrapper
          onDialogSubmit={this.onFormSubmit}
          formConfig={formConfig}
          refsObj={this.formReference}
          titleWithouttype
          title={dialogTitle}
          withOutPadding
          renderDialog={({
            onDialogSubmit,
            handleDialogInputChange,
            handleDialogDropDownChange,
            dialogData,
            enableErrorDisplay,
          }) => (
            <MapDialog
              show
              currentData={dialogData}
              outlets={data.list}
              refsObj={this.formReference}
              onFormSubmit={onDialogSubmit}
              loading={serverResponseWaiting}
              enableErrorDisplay={enableErrorDisplay}
              handleInputChange={handleDialogInputChange}
              handleDropDownChange={handleDialogDropDownChange}
            />
          )}
          render={({ onDialogItemClick }) => (
            <>
              <div className="section-header">
                <PanelStyled>
                  <PageHeader
                    breadCrumb={breadCrumbConfig}
                    config={{
                      title,
                      create: false,
                      download: true,
                      filter: true,
                      search: true,
                      date: true,
                    }}
                    filter={{
                      date: queryParameters.date,
                      menuList: filterMenu,
                      onFilterChange: this.basePaginationService.handleFilterChange,
                    }}
                    downloadConfig={{
                      domain: DOWNLOAD_DOMAIN.GEOLOCATION,
                    }}
                    handleDownloadClick={this.basePaginationService.handleDownloadClick}
                    handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                    queryParameters={queryParameters}
                    resetFilter={this.basePaginationService.resetFilter}
                    clearSearchText={this.basePaginationService.clearSearchText}
                    handleSearchChange={this.basePaginationService.handleSearchInputChange}
                  />
                </PanelStyled>
              </div>
              <div className="section-content table-present">
                {type && (
                  <ModalWrapper
                    type={type}
                    dialogData={element}
                    formConfig={type === EVENT_OPERATION.VIEW ? updateHistoryConfig : confirmConfig}
                    onDialogCancel={this.handleClose}
                    onDialogSubmit={this.handleRequest}
                    formTitle={`${dialogTitle} ${type === EVENT_OPERATION.VIEW ? '- Update History' : ''}`}
                    renderDialog={() => (
                      <>
                        {type === EVENT_OPERATION.VIEW ? (
                          <div className="table">
                            <table>
                              <thead>
                                <td>Date</td>
                                <td>Requested By</td>
                              </thead>
                              <tbody>
                                {updateHistory.map(list => (
                                  <tr>
                                    <td>{normalPresentor(list.updatedDate)}</td>
                                    <td>{list.requestedBy}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          confirmationGenerator(type)
                        )}
                      </>
                    )}
                  />
                )}
                <View
                  data={data}
                  permission={this.permission}
                  pagination={queryParameters.pagination}
                  loading={serverResponseWaiting}
                  onPageChange={this.basePaginationService.onPageSelect}
                  onIconClickRow={onDialogItemClick}
                  handleRequest={this.handleRequest}
                  handleRowClick={this.handleRowClick}
                  onIconClick={this.onActionClick}
                />
              </div>
            </>
          )}
        />
      </GeoLocationStyled>
    );
  }
}

GeoLocation.propTypes = propTypes;

GeoLocation.defaultProps = defaultProps;

export default withAlert()(GeoLocation);
