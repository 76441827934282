import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, BulkDelete } from '../../../v4/components';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import PresentationStyle from './PresentationStyle';
import { TableBody, TableHeader } from './tableConfig';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onIconClick: PropTypes.func,
  onBulkDelete: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onIconClick: () => null,
  permission: PERMISSION_OBJ,
};

const View = (props) => {
  const { data, pagination, onPageChange, onBulkDelete, permission, onIconClick } = props;
  return (
    <PresentationStyle>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.PRESENTATION}
        handler={onBulkDelete}
        render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className='table-wrap'>
            <table>
              {TableHeader({
                dataListLength: data.list.length,
                checkedListLength: checkedList.length,
                primaryCheckboxHandler,
                permission: { read: true, delete: true },
              })}
              <tbody>
                {data.list.map((presentation) =>
                  TableBody({
                    data: presentation,
                    checkedList: checkedList,
                    onIconClick,
                    secondaryCheckboxHandler,
                    permission,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </PresentationStyle>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

export default View;
