import gql from 'graphql-tag';

const GET_FUNDAMENTALS_LIST = gql`
    query getFundamentals($limit: Int, $offset: Int, $filter:FilterInput) {
      getFundamentals(offset: $offset, limit: $limit, filter:$filter) {
            rows {
                id
                title
                abbreviation
            }
            count
        }
    }
`;
const query = {
  getFundamentalsList: GET_FUNDAMENTALS_LIST,
};

export {
  query, GET_FUNDAMENTALS_LIST,
};
