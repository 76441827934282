import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, TabList, TabPanel, Tabs
} from '../../../../../v4/components';
import withErrorAndLoading from '../../../../../utils/composition/withLoading';
import General from './General';
import Permission from './Permission';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    active: PropTypes.bool,
    address: PropTypes.string,
    Town: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    servicesUsed: PropTypes.shape({
      billing: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
      delivery: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
      logistic: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
    }),
  }).isRequired,
  menu: PropTypes.shape({
    townList: PropTypes.instanceOf(Array),
  }).isRequired,
  enableErrorDisplay: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  refsObj: PropTypes.shape(Object).isRequired,
  crudMode: PropTypes.string.isRequired,
  handleButtonSubmit: PropTypes.func.isRequired,
  handleButtonCancel: PropTypes.func.isRequired,
  breadCrumb: PropTypes.instanceOf(Array).isRequired,
  getLatLng: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  srnService: PropTypes.bool.isRequired,
  vendorMapSection: PropTypes.bool.isRequired,
  srnChanged: PropTypes.func.isRequired,
  vendors: PropTypes.arrayOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object),
  params: PropTypes.shape(Object).isRequired,
  callHolidaySection: PropTypes.bool.isRequired,
  handleSkuSync: PropTypes.func.isRequired,
  marketPlace: PropTypes.bool,
  marketChanged: PropTypes.func.isRequired,
  getHeader: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onUploadClick: PropTypes.func.isRequired,
  handleDialogOkClick: PropTypes.func.isRequired,
  handleDialogCancelClick: PropTypes.func.isRequired,
  labels: PropTypes.arrayOf(Object).isRequired,
  skuSync: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  enableErrorDisplay: false,
  marketPlace: false,
  permission: PERMISSION_OBJ,
};

const View = ({ ...props }) => {
  const { userGroupDetails ,onSwitchChange,selectedSkus,update ,onDropDownChange,onInputChange,enableErrorDisplay ,refsObj,data ,onTabChange,activeTab ,userGroupList ,permissionEnable } = props;
  return (
    <Fragment>
      <div className='section-content custom-height section-tab'>
        <>
          <Tabs selectedIndex={activeTab} onSelect={(tabIndex) => onTabChange(tabIndex)}>
            <TabList>
              <Tab>
                <span className='tab-label'>Details</span>
              </Tab>
              { (update.type === EVENT_OPERATION.CREATE || !permissionEnable ) ||
              <Tab>
                <span className='tab-label'>Permissions</span>
              </Tab>
              }
            </TabList>
            <div className='route-tab-content'>
              <TabPanel >
                <section id='detail-tab' className={!update.status ? 'disabled' : null}>
                  <General
                    details ={userGroupDetails}
                    onInputChange={onInputChange}
                    onDropDownChange={onDropDownChange}
                    enableErrorDisplay={enableErrorDisplay}
                    refsObj={refsObj}
                    userGroupList={userGroupList}
                  />
                </section>
              </TabPanel>
              { (update.type === EVENT_OPERATION.CREATE || !permissionEnable) ||
              <TabPanel>
                <section id='permission-tab' className={!update.status ? 'disabled' : null}>
                  <Permission
                    data={data}
                    onSwitchChange={onSwitchChange}
                    selectedSkus={selectedSkus}
                  />
                </section>
              </TabPanel>
              }
            </div>
          </Tabs>
        </>
        )
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const GeneralInfo = withErrorAndLoading(View);

export default GeneralInfo;
