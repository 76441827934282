import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from '../../../../../components';
import { AccordionTable } from '../../../../../components/CollapsibleTable'
import VisibilityAuditDetailWrapper from './VisibilityAuditDetailWrapper'


const tableData = (childUser, collapsible = false, handleToggle) => (
  <>
    <td>{collapsible ? <span>{childUser.title}</span> : childUser.title}</td>
    <td>
      <Switch
        checked={childUser.status}
        onToggle={(checked, event) => {
          event.stopPropagation();
          handleToggle('brand', checked, childUser);
        }}
      />
    </td>
  </>
);
const getCollapsibleComponent = (dataList, handleToggle) => (
  <AccordionTable>
    {
      dataList.map(principal => (
        <tr
          key={principal.id}
          trigger={
            <>
              <td><span>{principal.title}</span></td>
              <td>
                <Switch
                  checked={principal.status}
                  onToggle={(checked, event) => {
                    event.stopPropagation();
                    handleToggle('principal', checked, principal);
                  }}
                />
              </td>
              <td>{`${principal.childCategory.filter(child => child.status).length}/${principal.childCategory.length}`}</td>
            </>}
          isChildPresent={!principal.childCategory}
          simpleChild={(
            <tr className="active" key={principal.id}>
              {tableData(principal)}
            </tr>)}
        >
          <AccordionTable>
            {
              principal.childCategory.map(category => (
                <tr
                  key={category.id}
                  trigger={
                    <>
                      <td><span>{category.title}</span></td>
                      <td>
                        <Switch
                          checked={category.status}
                          onToggle={(checked, event) => {
                            event.stopPropagation();
                            handleToggle('productCategory', checked, category, { principal: principal.id });
                          }}
                        />
                      </td>
                      <td>{`${category.childBrands.filter(child => child.status).length}/${category.childBrands.length}`}</td>
                    </>}
                  isChildPresent={!category.childBrands}
                  simpleChild={(
                    <tr className="active" key={category.id}>
                      {tableData(category, false, handleToggle)}
                    </tr>)}
                >
                  {category.childBrands && category.childBrands.length !== 0 && category.childBrands.map(brand => (
                    <tr className="active" key={brand.id}>
                      {tableData(brand, false, handleToggle)}
                    </tr>
                  ))}
                </tr>
              ))}
          </AccordionTable>
        </tr>
      ))
    }
  </AccordionTable>
);

const View = (props) => {
  const { data, onToggle } = props;

  return (
    <VisibilityAuditDetailWrapper>
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th>Brands</th>
              <th>Permission</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {getCollapsibleComponent(data, onToggle)}
          </tbody>
        </table>
      </div>
    </VisibilityAuditDetailWrapper>

  )
}

View.propTypes = {
  data: PropTypes.array,
}

View.defaultProps = {
  data: []
}

export default View;
