import { FILTER } from '../../../../data/enums/filter';
import { mt } from '../../../../data/enums/Route';
import { getFilterConfig } from '../../../../utils/filter';

const title = 'Purchase Order';

const createButtonTitle = 'Create Purchase Order';

const breadCrumbConfig = [
  mt,
];

const filterConfig = getFilterConfig([FILTER.CHANNEL]);

export {
  title, createButtonTitle, breadCrumbConfig, filterConfig,
};
