import { compose } from 'recompose';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import Report from './Report';
import { query } from './API';

const ComposeReport = compose(
  withRequestTracker({
    query,
  }),
)(Report);

export default ComposeReport;
