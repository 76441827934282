import { SRN } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';
import { summaryDetailsMapper, totalPriceMapper } from '../create/config';
import { STOCK_TYPE } from '../../../common/DomainConfig';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { getTodaysDate } from '../../../../utils/date';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'SRN',
    path: `/${SRN}`,
    active: true,
  },
];

const orderFormatter = (orders = []) => {
  const updatedOrders = orders.map((item) => ({
    ...item,
    returnQuantity: item.quantity,
  }));

  return updatedOrders;
};

const dataMapper = (data = {}) => ({
  id: data.id || null,
  srnInvoiceNumber: data.SRNInvoiceNumber || '',
  lines: data.SRNDetails || [],
  salesInvoiceNumber: data.invoiceNumber || '',
  srnAmount: data.srnAmount,
  Distributor: data.Distributor || { serviceUsed: {} },
  reason: data.reason || '',
  srnDate: data.srnDate || getTodaysDate(),
  Customer: {
    id: data.Customer ? data.Customer.id : null,
    name: data.Customer ? data.Customer.name : '',
    address: data.Customer ? data.Customer.address : '',
    panNumber: data.Customer ? data.Customer.panNumber : '',
  },
  RetailOutlet: {
    id: data.RetailOutlet ? data.RetailOutlet.id : '',
    title: data.RetailOutlet ? data.RetailOutlet.title : '',
    Customers: data.RetailOutlet ? data.RetailOutlet.Customers : [],
  },
  orders: orderFormatter(data.SRNDetails) || [],
  billDiscountDetails: [],
  amount: totalPriceMapper(data.srnAmount ? data.srnAmount || {} : {}),
});

// const formMapper = (element, distributorId) => {
//   const orderDetails = element.Orders
//     ? orderFormatter(element.Orders) : { orders: [], totalValidOrders: 0 };

//   return {
//     ...summaryDetailsMapper(element, distributorId),
//     uuid: element.uuid || '',
//     orders: orderDetails.orders || [],
//     totalValidOrders: orderDetails.totalValidOrders || 0,
//     amount: totalPriceMapper(element.amountDetails ? element.amountDetails || {} : {}),
//     billDiscountDetails: element.billDiscountDetail ? element.billDiscountDetail || [] : [],
//     approveId: element.approveId || null,
//   };
// };
const getFormattedSkuLines = (list, oldBillStatus) =>
  list.map((item) => {
    const { amount, ...srnLineAmount } = item.amountDetails;
    return {
      orderId: oldBillStatus ? null : item.orderId,
      skuId: item.skuId,
      skuBatchId: item.skuBatchId,
      stockType: item.stockType,
      returnQuantity: item.returnQuantity,
      quantity: item.quantity,
      srnLineAmount: {
        ...srnLineAmount,
        rate: Number(srnLineAmount.rate),
        discountAmount: Number(srnLineAmount.discountAmount),
        promotionDiscount: Number(srnLineAmount.promotionDiscount),
      },
    };
  });

const getPayload = (data) => ({
  invoiceNumber: data.details.salesInvoiceNumber,
  srnInvoiceNumber: data.details.srnInvoiceNumber,
  reason: data.details.reason,
  distributorId: data.details.Distributor.id,
  retailOutletId: data.details.RetailOutlet.id,
  customerId: data.details.Customer ? data.details.Customer.id : null,
  srnLines: getFormattedSkuLines(data.orders, data.oldBillStatus),
  srnDate: data.details.srnDate,
  srnAmount: {
    ...data.amount,
    discountAmount: Number(data.amount.discountAmount),
    promotionDiscount: Number(data.amount.promotionDiscount),
    billDiscount: Number(data.amount.billDiscount),
    tradeDiscount: Number(data.amount.tradeDiscount),
  },
  isOldBill: data.isOldBill,
  type: data.srnType,
  lineId: data.details.lineId,
});

const crudSuccess = {
  [EVENT_OPERATION.APPROVE]: {
    objectMapper: (data) => ({
      id: data.id,
      input: getPayload(data),
    }),
    responseName: 'approveSalesReturn',
    message: `SRN ${MESSAGE_EVENT_OPERATION.APPROVE}`,
  },
  [EVENT_OPERATION.CANCEL]: {
    objectMapper: (data) => ({ id: data.id }),
    responseName: 'cancelSalesReturn',
    message: ' SRN rejected successfully!',
  },
};
export {
  breadCrumb, crudSuccess as crudRequestConfig, dataMapper
};
