import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react';

const Progress = ({ completed, bgColor, baseBgColor, isLabelVisible }) => {
  return (
    <>
      <ProgressBar
        completed={completed}
        bgColor={bgColor}
        baseBgColor={baseBgColor}
        height="10px"
        width="100%"
        margin="0 8px 0 0"
        isLabelVisible={false}
        maxCompleted={100}
      />
    </>
  );
};

export default Progress;
