import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';
import { CheckBox } from '../../../../components';
import { menuAction, tableHeaderWithCheckBox } from '../../../../utils/paginatedListUtils';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  frequency: 'frequency',
  assignedUser: 'assignedUser',
};


const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.frequency,
    title: 'Frequency',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.assignedUser,
    title: 'Assigned User',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];


const TableHeader = ({
  dataListLength,
  checkedListLength,
  primaryCheckboxHandler,
  permission,
}) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings
  );

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

const returnTableBody = (
  data,
  checkedList,
  onIconClick,
  onSecondaryCheckBoxClick,
  permission,
) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td key={`visibility-audit-${data.id}-check`} onClick={e => e.stopPropagation()}>
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
      />
    </td>
  );
  table[labelMappings.title] = (
    <td key={`visibility-audit-${data.id}-title`}>{data.title}</td>
  );
  table[labelMappings.frequency] = (
    <td key={`visibility-audit-${data.id}-frequency`}>{data.count_days}</td>
  );
  table[labelMappings.assignedUser] = (
    <td key={`visibility-audit-${data.id}-assignedUser`}>{data.assigned_to}</td>
  );
  table[labelMappings.actionHolders] = (
    <td
      key={`visibility-audit-${data.id}-action`}
      className={
        checkedList.includes(data.id)
          ? 'disabled text-right simple-popup-actions'
          : 'text-right simple-popup-actions'
      }
      onClick={(e) => e.stopPropagation()}
    >
      {menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        data,
        permission
      )}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  permission,
  onTableRowClick,
}) => {
  const tableBody = returnTableBody(
    data,
    checkedList,
    onIconClick,
    secondaryCheckboxHandler,
    permission
  );
  return (
    <tr
      key={data.id}
      style={{ cursor: 'pointer' }}
      onClick={() => onTableRowClick(data.id, EVENT_OPERATION.UPDATE)}
    >
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};


TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
  onTableRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
