import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Input, AutoComplete, Row, Col, Icon,CustomSelect,Button,
} from '../../../../components';
import { PanelCard } from '../../../../v4/components';
import withErrorAndLoading from '../../../../utils/composition/withLoading';
import FormContentStyled from '../../../../components/global-css/FormContentStyled';

const propTypes = {
};

const defaultProps = {
};

const View = ({ ...props }) => {
  const { handleInputChange,enableErrorDisplay ,refsObj,data ,handleAddRemoveClick,update,menu ,onAutoCompleteChange ,onDropDownChange } = props;
  const handleAutoCompleteChange = (index) => (value, fieldName) => onAutoCompleteChange(value, index, fieldName);
  const categoryIds =data?.brandsCategoryInfo.map((a) =>a?.categoryId);
  const  filteredList = menu.categoryList.filter(function(item) {
    return categoryIds.indexOf(item.id) === -1
  });
  return (
    <div className='merchandise-detail '>
      <div className='flex'>
        <div className='w-half'>
          <FormContentStyled className='merchandise-dialog'>
            <div className='form-input-wrap w-100'>
              <Input
                ref={(ref) => refsObj.title = ref}
                value={data.title}
                name='title'
                type='text'
                placeholder='Title'
                labelContent='Title'
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={(event) => handleInputChange(event,'title')}
              />
            </div>
          </FormContentStyled>
        </div>
      </div>
      <div className='categories-brand'>
        <PanelCard cardTitle='Categories & Brands'>
          <section className='categories-brand_wrapper'>
            {data?.brandsCategoryInfo?.map((cb, index) => (
              <div className='brandsCategoryInfo-wrapper'>
                <div className='form-wrapper'>
                  <div className='form-in'>
                    <div className='auto-complete'>
                      <AutoComplete
                        name='brandIds'
                        labelContent='Brand'
                        dropDownList={menu.brandList}
                        placeholder='Search and Select'
                        selectedDropDowns={cb.brandIds}
                        onDropDownSelection={handleAutoCompleteChange(index)}
                        enableValidation
                        enableErrorDisplay={enableErrorDisplay}
                        ref={(ref) => (refsObj.brandIds = ref)}
                      />
                      <Icon iconName='search' />
                    </div>
                    <CustomSelect
                      labelContent='Categories'
                      className='custom-select'
                      options={filteredList}
                      placeholder='Select Categories'
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ title }) => title}
                      onChange={(event) => onDropDownChange(event.id, ['brandsCategoryInfo',index,'categoryId'])}
                      value={menu.categoryList.filter(({ id }) => id === cb.categoryId)}
                    />
                  </div>
                </div>
                {index >= 0 && (
                  <div className='config-icon'>
                    <div className='flex m-0'>
                      <Icon
                        iconName='times'
                        primary
                        onClick={() => handleAddRemoveClick('remove', 'brandsCategoryInfo', index)}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </section>
          <div className='add'>
            <Button
              iconBtnSmall
              secondary
              iconName='plus'
              onClick={() => handleAddRemoveClick('add', 'brandsCategoryInfo')}
            />
          </div>
        </PanelCard>
      </div>
    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const GeneralInfo = withErrorAndLoading(View);

export default GeneralInfo;
