import React, { Fragment } from 'react';
import withImageSlider from '../../../utils/composition/withImageSlider';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import {
  Tab, TabList, TabPanel, Tabs, Badge
} from '../../../v4/components';
import PropTypes from 'prop-types';
import { PanelStyled } from '../../common/configuration';
import PageHeader from '../../base/PageHeader';
import {
  title, tabMapper
} from './config';
import { Row, Col } from '../../../components/Grid';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import Pending from './pending';
import Planned from './planned';
import Completed from './completed';
import history from '../../../utils/history';
import { TAS_OUTLET, CALL_PLAN, } from '../../../data/enums/Route';

const propTypes = {
  pending: dataProps.value,
  planned: dataProps.value,
  completed: dataProps.value,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  activeTab: PropTypes.number,
  onTabChange: PropTypes.func,
  columnSelectChange: PropTypes.func,
  handleFilterChange: PropTypes.func,
  queryParameters: PropTypes.instanceOf(Object),
  basePaginationService: PropTypes.instanceOf(Object),
  onHeaderClick: PropTypes.func,

};

const defaultProps = {
  activeTab: 0,
  pending: dataProps.defaultValue,
  reconcile: dataProps.defaultValue,
  dishonore: dataProps.defaultValue,
  onPageChange: () => null,
  received: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onButtonClick: () => null,
  onTabChange: () => null,
  columnSelectChange: () => null,
  handleFilterChange: () => null,
  queryParameters: {},
  basePaginationService: {},
  onHeaderClick: () => null,
};
const View = ({ ...props }) => {

  const handleAddButtonClick = () => {
    history.push(`/${TAS_OUTLET}`);
  };

  const handleTableRowClick = (id) => {
    history.push(`/${CALL_PLAN}/${id}`, { callPlanStatus: tabMapper[activeTab] });
  };

  const {
    activeTab,
    onTabChange,
    tableConfig,
    columnSelectChange,
    pending,
    planned,
    completed,
    dishonore,
    queryParameters,
    handleFilterChange,
    basePaginationService,
    handleSliderOpen,
    onButtonClick,
    handleCreateClick,
    onHeaderClick,
    filterMenu,
  } = props;
  const tablewithAction = [
    ...tableConfig,
  ];

  return (
    <Fragment>
      <div className='section-header'>
        <PanelStyled>
          <PageHeader
            createTitle='Outlets'
            config={{
              title,
              create: true,
              download: false,
              filter: true,
              upload: false,
              date: true,
              search: true,
              columSelect: false,
            }}

            filter={{
              date: queryParameters.date,
              onFilterChange: handleFilterChange,
              menuList: filterMenu,
            }}
            labelConfig={tableConfig}
            queryParameters={queryParameters}
            columnSelectChange={columnSelectChange}
            handleCreateClick={handleAddButtonClick}
            resetFilter={basePaginationService.resetFilter}
            clearSearchText={basePaginationService.clearSearchText}
            handleSearchChange={basePaginationService.handleSearchInputChange}
            handleDateRangeChange={basePaginationService.handleDateRangeChange}
          />
        </PanelStyled>
      </div>
      <div className='section-content section-tab table-present '>
        <Tabs selectedIndex={activeTab} onSelect={(tabIndex) => onTabChange(tabIndex)}>
          <TabList>
            <Tab>
              <span className='tab-label'>Pending</span>
              <Badge light label={pending.total} />
            </Tab>
            <Tab>
              <span className='tab-label'>Planned</span>
              <Badge light label={planned.total} />
            </Tab>
            <Tab>
              <span className='tab-label'>Completed</span>
              <Badge light label={completed.total} />
            </Tab>
          </TabList>
          <div className='tab-container'>
            <Row>
              <Col sm={12}>
                <TabPanel>
                  <section id='pending-tab'>
                    <div id='detail-tab'>
                      <div className='create-ui-inner tab-less-height' style={{ padding: '0' }}>
                        <Pending
                          tableConfig={tablewithAction}
                          pending={pending}
                          pagination={queryParameters.pagination}
                          onPageChange={basePaginationService.onPageSelect}
                          handleSliderOpen={handleSliderOpen}
                          fetchAllData={onButtonClick}
                          onTableRowClick={handleTableRowClick}
                          onHeaderClick={onHeaderClick}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel>
                  <section id='completed-tab'>
                    <div id='detail-tab'>
                      <div className='create-ui-inner tab-less-height' style={{ padding: '0' }}>
                        <Planned
                          tableConfig={tablewithAction}
                          planned={planned}
                          pagination={queryParameters.pagination}
                          onPageChange={basePaginationService.onPageSelect}
                          handleSliderOpen={handleSliderOpen}
                          fetchAllData={onButtonClick}
                          onTableRowClick={handleTableRowClick}
                          onHeaderClick={onHeaderClick}

                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel>
                  <section id='completed-tab'>
                    <div id='detail-tab'>
                      <div className='create-ui-inner tab-less-height' style={{ padding: '0' }}>
                        <Completed
                          tableConfig={tablewithAction}
                          completed={completed}
                          onPageChange={basePaginationService.onPageSelect}
                          handleSliderOpen={handleSliderOpen}
                          onTableRowClick={handleTableRowClick}
                          onHeaderClick={onHeaderClick}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
              </Col>
            </Row>
          </div>
        </Tabs>
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ChequeViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

const ChequeViewWithImageSlider = withImageSlider(ChequeViewWithErrorAndLoading);

export default ChequeViewWithImageSlider;
