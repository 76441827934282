import { fixedFloatAndCommas } from './conversion';

const addEdit = (mode, data) => (mode ? `Edit ${data}` : data);

const uniqueArrayElement = (array) => {
  return Array.from(new Set([...array]));
};

const uniqueArrayElementString = (array) => {
  return uniqueArrayElement(array).toString().toLowerCase();
};

const getPercentage = (dividend = 1, divisor) => Number(fixedFloatAndCommas((dividend * 100) / (divisor || 1)));

export {
  addEdit, uniqueArrayElementString, uniqueArrayElement, getPercentage
};
