import { EVENT_OPERATION } from '../../constants/EventOperation';

const confirmationGenerator = (type: string, number: number) => {
  const starter = 'Are you sure you want to ';
  switch (type) {
    case EVENT_OPERATION.UPDATE:
      return (
        <span>
          {starter}
          {number ? `update ${number} records` : 'update this record'}?
        </span>
      );
    case EVENT_OPERATION.DELETE:
      return (
        <span>
          {starter}
          delete this record?
        </span>
      );
    case EVENT_OPERATION.REMOVE:
      return (
        <span>
          {starter}
          <span>{EVENT_OPERATION.REMOVE.toLowerCase()} </span>
          this record?
        </span>
      );
    case EVENT_OPERATION.BULK_DELETE:
      return (
        <span>
          {starter}
          delete {number || ' all these'} records?
        </span>
      );
    case EVENT_OPERATION.CREATE_GROUP:
      return (
        <span>
          {starter}
          create a SKU group ?
        </span>
      );
    case EVENT_OPERATION.SAVE:
      return (
        <span>
          {starter}
          save this report ?
        </span>
      );

    default:
      return (
        <span>
          {starter}
          change the status&nbsp;
          {type.toLowerCase() !== 'update_status' && (
            <>
              to&nbsp;
              <b>{type.replace('_', ' ').toLowerCase()}</b>
              &nbsp;
            </>
          )}
          ?
        </span>
      );
  }
};

export default confirmationGenerator;
