import gql from 'graphql-tag';

const GET_BU_LIST = gql`
    query getCatalogDetail($limit: Int, $offset: Int, $catalogId: Int, $parentCatalogDetailId:Int) {
        catalogDetails(offset: $offset, limit: $limit, catalogId: $catalogId,parentCatalogDetailId: $parentCatalogDetailId) {
            rows {
                id
                title
                parentCatalogDetail:ParentCatalogDetail{
                  id
                  title
                }
                catalogId
                parentCatalogDetailId
                skus:SKUs{
                  id
                  title
                }
            }
            count
        }
    }
`;
const query = {
  getBuList: GET_BU_LIST,
};

export {
  query, GET_BU_LIST,
};
