import { ReportTableStyled } from './ReportTableStyled';
import { TableBody, TableHeader } from './tableConfig';

const ReportTable = ({ title, datas, labelMappings, labelConfig, baseBgColor, bgColor }) => {
  return (
    <>
      <ReportTableStyled>
        <h2> {title}</h2>
        <div className="table-wrap">
          <table>
            <TableHeader labelConfig={labelConfig} />
            <tbody>
              {datas?.map(data => (
                <TableBody
                  data={data}
                  labelConfig={labelConfig}
                  labelMappings={labelMappings}
                  baseBgColor={baseBgColor}
                  bgColor={bgColor}
                  title={title}
                />
              ))}
            </tbody>
          </table>
        </div>
      </ReportTableStyled>
    </>
  );
};

export default ReportTable;
