
import { breadCrumbConfig } from '../../MTSetting/config';
import { MT_SETTINGS } from '../../../../../../data/enums/Route';

const title = 'Fundamentals';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 2,
    title: 'Settings',
    path: `/${MT_SETTINGS}`,
    active: true,
  },

];

export { breadCrumb as breadCrumbConfig, title };
