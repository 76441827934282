import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getCurrentDay } from '../../../utils/date';
import { ImageColumn } from '../../../utils/tableUtils';
import { Badge, menuAction, tableData, CheckBox } from '../../../v4/components';
import { OUTLET_DETAILS } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const labelMappings = {
  checkbox: 'checkbox',
  outletName: 'outletName',
  route: 'route',
  lastInvoiced: 'lastInvoiced',
  status: 'status',
  reason: 'reason',
  image: 'image',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.outletName,
    title: 'Outlet Name',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.lastInvoiced,
    title: 'Last Invoiced',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.reason,
    title: 'Reason',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.image,
    title: 'Images',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const menuConfigList = [
  {
    title: 'Approve',
    // permissionDerivedBy: 'approve',
    type: EVENT_OPERATION.APPROVED,
  },
  {
    title: 'Reject',
    // permissionDerivedBy: 'reject',
    type: EVENT_OPERATION.REJECTED,
  },
];

const returnTableBody = (
  data,
  checkedList,
  onSecondaryCheckBoxClick,
  handleSliderOpen,
  onIconClick,
  permission,
  withOutIcon,
) => {
  const lastInvoicedDate = data.outletDeleteData.RetailOutlet.lastInvoicedDate;
  const today = moment(new Date());
  const lastDate = moment(lastInvoicedDate);
  const lastInvoicedWeek = today.diff(lastDate, 'week');
  const lastInvoicedWeekFun = (lastInvoicedWeek) => {
    let date;
    if (lastInvoicedWeek === 0) {
      date = 'Few days ago';
    } else if (lastInvoicedWeek === 1) {
      date = `${lastInvoicedWeek} week ago`;
    } else if (lastInvoicedWeek > 1) {
      date = `${lastInvoicedWeek} weeks ago`;
    } else {
      return 'N/A';
    }
    return date;
  };
  const lastInvoiceDate = lastInvoicedWeekFun(lastInvoicedWeek);
  const table = {};
  table[labelMappings.checkbox] = checkedList && permission && permission.update && (
    <td onClick={(e) => e.stopPropagation()} key={`${data.id}-check`}>
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
        disabled={data.status !== 'PENDING'}
      />
    </td>
  );
  table[labelMappings.outletName] = (
    <Link to={`/${OUTLET_DETAILS}/${data.outletDeleteData.outletId}`}>
      <td key={`${data.id}-id`}>{data.outletDeleteData.RetailOutlet.title}</td>
    </Link>
  );
  table[labelMappings.route] = (
    <td key={`${data.id}-route`}>
      {data.outletDeleteData.RetailOutlet.Route ? data.outletDeleteData.RetailOutlet.Route.title : ''}
    </td>
  );
  table[labelMappings.lastInvoiced] = <td key={`${data.id}-lastInvoiced`}>{lastInvoiceDate}</td>;
  table[labelMappings.status] = (
    <td key={`${data.id}- status`}>
      <span className={data.status.toLowerCase()}>
        <span className='badge_border'>
          <Badge light>{data.status.replace('_', ' ')}</Badge>
        </span>
      </span>
    </td>
  );
  table[labelMappings.reason] = <td key={`${data.id}-reason`}>{data.outletDeleteData.remarks}</td>;
  table[labelMappings.image] = (
    <ImageColumn
      keyTitle={`rp-image-${data.id}`}
      imageList={data.outletDeleteData.image || []}
      onImageClick={handleSliderOpen}
    />
  );
  table[labelMappings.actionHolders] = (
    <td
      key={`${data.id}- action`}
      className={
        checkedList.includes(data.id) ? 'disabled text-right simple-popup-actions' : 'text-right simple-popup-actions'
      }
      onClick={(e) => e.stopPropagation()}
    >
      {data.status === 'PENDING' &&
        menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data.id, permission, withOutIcon)}
    </td>
  );

  return table;
};

const TableHeader = ({ checkedListLength, primaryCheckboxHandler, permission, dataList }) => {
  const checkPending = dataList.filter((item) => item.status === 'PENDING');
  const checkPendingLength = checkPending.length;
  return (
    <thead>
      <tr>
        {labelConfig.map((label) =>
          label.show
            ? label.label === labelMappings.checkbox
              ? permission &&
              permission.update && (
                <th key={`cheque-${label.id}-checkboxheader`}>
                  <CheckBox
                    checked={checkPendingLength !== 0 && checkPendingLength === checkedListLength}
                    onCheckBoxClick={(e) => primaryCheckboxHandler(e)}
                  />
                </th>
              )
              : tableData(label)
            : null,
        )}
      </tr>
    </thead>
  );
};

const TableBody = ({
  data,
  checkedList,
  secondaryCheckboxHandler,
  handleSliderOpen,
  onIconClick,
  permission,
  withOutIcon,
}) => {
  const tableBody = returnTableBody(
    data,
    checkedList,
    secondaryCheckboxHandler,
    handleSliderOpen,
    onIconClick,
    permission,
    withOutIcon,
  );

  return <tr key={data.id}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

export { TableHeader, TableBody };
