import React, { Component } from 'react';
import {
  Redirect, Router, Route, Switch
} from 'react-router-dom';
import history from './utils/history';
import { Login, Base } from './views';
import { errorPage as Error } from './styleguide';
import ScrollToTop from './views/common/ScrollToTop';
import { ErrorPage } from './v4/components';
import ForgotPassword from './views/login/resetPassword';
import { checkAuthentication } from './utils/authUtils';
import AlertBaseComponent from './v4/components/Alert/AlertBaseComponent';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuthentication() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  render() {
    return (
      <div>
        <Router history={history}>
          <Switch>
            <Route exact path='/' render={() => <Redirect to='/login' />} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgot-password/:token' component={ForgotPassword} />
            <ScrollToTop>
              <PrivateRoute path='/' component={Base} />
            </ScrollToTop>
            <Route exact path='`/new/:page`' component={Error} />
            <Route component={ErrorPage} />
          </Switch>
        </Router>
        <AlertBaseComponent/>
      </div>
    );
  }
}

export default App;
