import gql from 'graphql-tag';
import { GET_MT_LIST } from '../API';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';

const GET_OUTLET_CHAIN = gql`
  query getOutletChain($searchText: String){
    getOutletChain(searchText: $searchText){
      id
      title
      active
    }
  }
`;

const GET_OUTLET_BY_CHAIN_ID = gql`
  query getRetailOutletByChainId($outletChainId: Int!){
    getRetailOutletByChainId(outletChainId: $outletChainId){
      id
      title
      active
    }
  }
`;

const GET_INVENTORY_LIST = gql`
  query getInventoryList(
    $limit: Int
    $offset: Int
    $filter: FilterInput
    ){
      getInventoryList(
        limit: $limit
        offset: $offset
        filter: $filter
        ){
          rows{
            sku
            updatedDate
            currentQuantity
            lastQuantity
            currentpcs
            increased
            currentcases
            changedcases
            changedpcs
            skuId
            chainTitle
            retailTitle
            retailId
            chainId
          }
          count
        }
    }
`;

const GET_INVENTORIES_BATCH = gql`
  query ListInventoriesBatch(
    $skuId: Int!,$retailId: Int!
    ){
      ListInventoriesBatch(
        skuId: $skuId, retailId:$retailId
        ){
          batch
          manufactureDate:manufacture_date
          expiryDate:expiry_date
          cases
          pieces
        }
    }
`;

const query = {
  getStockList: GET_MT_LIST,
  getOutletChain: GET_OUTLET_CHAIN,
  getInventoryList: GET_INVENTORY_LIST,
  getBatchList: GET_INVENTORIES_BATCH,
  getRetailOutletByChainId: GET_OUTLET_BY_CHAIN_ID,
 
};
const mutation = {
  downloadReport: DOWNLOAD_REPORT,
}

export {
  query,mutation, GET_OUTLET_CHAIN, GET_OUTLET_BY_CHAIN_ID,DOWNLOAD_REPORT
};
