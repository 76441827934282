import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../../../../../components';

const propTypes = {
  readMode: PropTypes.bool,
  category: PropTypes.string,
  sos: PropTypes.instanceOf(Object).isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

const defaultProps = {
  readMode: false,
  category: '',
};

const SOS = (props) => {
  const {
    sos, readMode, category, handleInputChange,
  } = props;

  return (
    <div className="table-wrap">
      <table>
        <thead>
          <th>{category}</th>
          <th>Weightage</th>
          <th>JBP Share</th>
        </thead>
        <tbody>

          {sos.map((list, i) => (
            <tr>
              <td>{list.title}</td>
              <td>
                <div className="placeholder" data-placeholder="%">
                  <Input
                    type="number"
                    placeholder="0"
                    disabled={readMode}
                    argument={{ max: 100 }}
                    value={list.weightage}
                    onChange={e => handleInputChange(e, 'sos', ['sos', `${i}`, 'weightage'], 'percentCheck')}
                  />
                </div>
              </td>
              <td>
                <div className="placeholder" data-placeholder="%">
                  <Input
                    type="number"
                    placeholder="0"
                    disabled={readMode}
                    argument={{ max: 100 }}
                    value={list.jbpshare}
                    onChange={e => handleInputChange(e, 'sos', ['sos', `${i}`, 'jbpshare'], 'percentCheck')}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

SOS.propTypes = propTypes;
SOS.defaultProps = defaultProps;

export default SOS;
