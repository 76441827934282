import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import Form from './Form';
import { getUser } from '../../../data/services';
import PageHeader from '../../base/PageHeader';
import { DialogFormWrapper } from '../../common';
import WarehouseStyled from './WarehouseStyled';
import * as queryService from '../../base/query.service';
import { refGenerator } from '../../../utils/refGenerator';
import { PanelStyled } from '../../common/configuration';
import withAlert from '../../../utils/composition/withAlert';
import { getOffsetFromPagination, apiFilterProcessor } from '../../../utils/api';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { breadCrumbConfig, formConfig, title } from './config';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  toggleState: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  getWarehouseLevels: PropTypes.func.isRequired,
  getWarehouseLevelDetails: PropTypes.func.isRequired,
  editWarehouseLevelDetail: PropTypes.func.isRequired,
  createWarehouseLevelDetail: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class WarehouseMgmt extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.userInfo = getUser();
    this.state = {
      binId: {},
      selectedId: null,
      selectedCardId: null,
      distributorId: this.userInfo.Distributor.length === 1 ? this.userInfo.Distributor[0].id : null,
      distributorList: [],
      wareHouseDetailList: [],
      levels: {
        list: [],
        count: '',
      },
      dialog: {
        type: '',
        element: '',
        cardTitle: '',
      },
      createFormWarehouse: {
        id: null,
        title: '',
        levelIndication: '',
        parent: { id: null, title: '' },
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
      },
    };

    this.formReference = refGenerator(['title']);
  }

  componentDidMount() {
    const { distributorId } = this.state;
    if (
      ADMIN_ROLE_GROUP.includes(this.userInfo.roleId) ||
      DISTRIBUTOR_ASSOCIATED_ROLES.includes(this.userInfo.roleId)
    ) {
      this.getDistributorsList();
    } else {
      this.getWarehouseLevelsList(distributorId);
    }
  }

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getDistributorsList = () => {
    const { getDistributors } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          this.setState({ distributorList: response.data.distributors.rows });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  getWarehouseLevelsList = distributorId => {
    const { levels, queryParameters } = this.state;
    const { getWarehouseLevels } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getWarehouseLevels(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [
            ...apiFilterProcessor(queryParameters.filter),

            {
              column: 'distributor_id',
              value: [distributorId.toString() || ''],
            },
          ],
          sort: [{ sortBy: 'id', order: 'ASC' }],
        },
      },
      {
        handleSuccess: response => {
          levels.list = response.data.getWarehouseLevels.rows || [];
          this.setState(levels);
          this.loadTableData(distributorId, levels.list[0] ? levels.list[0].id : null, 0);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  loadTableData = (distributorId, levelId, cardId, queryString) => {
    const { queryParameters } = this.state;
    const { getWarehouseLevelDetails } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getWarehouseLevelDetails(
      {
        levelId,
        distributorId,
        parentId: cardId,
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          queryString,
          filters: [...apiFilterProcessor(queryParameters.filter)],
        },
      },
      {
        handleSuccess: response => {
          const wareHouseWithLevelId = {
            levelId,
            list: response.data.getWarehouseLevelDetails.rows || [],
            count: response.count,
          };

          const { wareHouseDetailList } = this.state;
          const baseIndex = wareHouseDetailList.findIndex(el => el.levelId === levelId);
          if (baseIndex < 0) {
            wareHouseDetailList.push(wareHouseWithLevelId);
          } else {
            wareHouseDetailList[baseIndex] = { ...wareHouseWithLevelId };
            wareHouseDetailList.splice(baseIndex + 1);
          }

          this.setState({ wareHouseDetailList, selectedId: levelId });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  handleDistributorSelect = distributorId => {
    this.setState(
      {
        distributorId,
      },
      () => {
        this.getWarehouseLevelsList(distributorId);
      },
    );
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
        cardTitle: '',
      },
    });
  };

  updatedBinId = binId => {
    const { selectedCardId } = this.state;
    const lastElement = Object.keys(binId)[Object.keys(binId).length - 1];
    let i = selectedCardId + 1;
    for (i; i <= lastElement; i += 1) {
      delete binId[i];
    }
    this.setState({ binId });
  };

  handleWareHouseDetailsCardClick = (list) => {
    const { distributorId, createFormWarehouse, levels, binId } = this.state;
    const obj = Object.fromEntries([[list.levelId, list.id]]);
    this.setState(
      {
        selectedCardId: list.levelId,
        createFormWarehouse: {
          ...createFormWarehouse,
          parent: {
            id: list.id,
            title: list.title,
          },
        },
      },
      () => this.updatedBinId({ ...binId, ...obj }),
    );

    const index = levels.list.findIndex(level => level.id === list.levelId);
    const levelList = levels.list[index + 1];
    this.loadTableData(distributorId, levelList ? levelList.id : null, list.id);
  };

  handleIconClick = (type, element, cardTitle, list) => {
    const { selectedId, createFormWarehouse } = this.state;
    const levelId = list.length > 0 ? list[0].levelId : selectedId;
    const parentId = list.length > 0 ? list[0].parent : createFormWarehouse.parent;
    this.setState({
      selectedId: levelId,
      dialog: {
        type,
        element,
        cardTitle,
      },
      createFormWarehouse: {
        id: element.id,
        title: element.title,
        levelIndication: element.levelIndication,
        parent: parentId,
      },
    });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { createFormWarehouse } = this.state;
    if (firstParam) {
      if (paramList.length > 1) {
        paramList.reduce((acc, value, index, list) => {
          if (index === list.length - 1) {
            return (acc[value] = event.formattedValue);
          }
          return acc[value];
        }, createFormWarehouse);
      } else {
        createFormWarehouse[firstParam][event.target.name] = event.formattedValue;
      }
    } else {
      createFormWarehouse[event.target.name] = event.formattedValue;
    }
    this.setState({ createFormWarehouse });
  };

  handleSubmit = () => {
    const { createWarehouseLevelDetail, displayAlert, editWarehouseLevelDetail, toggleState } = this.props;

    const { dialog, createFormWarehouse, distributorId, selectedId } = this.state;
    const parentId = createFormWarehouse.parent ? createFormWarehouse.parent.id : null;
    const { element, cardTitle, type } = dialog;

    if (type === 'CREATE') {
      createWarehouseLevelDetail(
        {
          input: {
            title: createFormWarehouse.title,
            levelIndication: createFormWarehouse.levelIndication,
            parentId,
            distributorId,
            levelId: selectedId,
          },
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.CREATE}`);
            this.loadTableData(distributorId, selectedId, parentId);
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );
    }
    if (type === 'UPDATE') {
      editWarehouseLevelDetail(
        {
          id: element.id,
          input: {
            title: createFormWarehouse.title,
            levelIndication: createFormWarehouse.levelIndication,
            parentId,
            distributorId,
            levelId: selectedId,
          },
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            this.loadTableData(distributorId, selectedId, parentId);
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );
    }
    if (type === 'DELETE') {
      toggleState(
        {
          ids: [element.id],
          type: STATEFUL_ENTITIES.WAREHOUSE,
          active: false,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.DELETE}`);
            this.loadTableData(distributorId, selectedId, parentId);
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );
    }
  };

  handleSearchInput = (text, cardTitle, data) => {
    const { distributorId } = this.state;
    const searchData = data.length > 0 ? { levelId: data[0].levelId, parentId: data[0].parentId } : null;
    this.loadTableData(distributorId, searchData.levelId, searchData.parentId, text);
  };

  render() {
    const { serverResponseWaiting } = this.props;
    const { dialog, distributorList, levels, distributorId, wareHouseDetailList, createFormWarehouse, binId } =
      this.state;
    const { type, cardTitle, element } = dialog;
    return (
      <WarehouseStyled>
        <div>
          {type && (
            <DialogFormWrapper
              formTitle={cardTitle}
              type={type}
              dialogElement={element}
              formConfig={formConfig[type]}
              onDialogCancel={this.resetDialog}
              onDialogSubmit={this.handleSubmit}
              refsObj={this.formReference}
              renderDialog={({ enableErrorDisplay, refsObj }) => (
                <Fragment>
                  {type !== EVENT_OPERATION.DELETE ? (
                    <Form
                      show
                      type={type}
                      refsObj={refsObj}
                      data={createFormWarehouse}
                      enableErrorDisplay={enableErrorDisplay}
                      handleInputChange={this.handleInputChange}
                      title={cardTitle}
                      levels={levels.list}
                    />
                  ) : (
                    <div>Are you sure you want to delete this record?</div>
                  )}
                </Fragment>
              )}
            />
          )}

          <Fragment>
            <div className="section-header">
              <PanelStyled>
                <PageHeader
                  breadCrumb={breadCrumbConfig}
                  config={{
                    title,
                    create: false,
                    download: false,
                    filter: false,
                    search: false,
                  }}
                />
              </PanelStyled>
            </div>
          </Fragment>
        </div>
        <div className="section-content">
          <View
            wareHouseLevelList={levels.list}
            distributorList={distributorList}
            handleIconClick={this.handleIconClick}
            wareHouseDetailList={wareHouseDetailList}
            onDropDownChange={this.handleDistributorSelect}
            loading={serverResponseWaiting}
            levels={levels.list}
            distributorId={distributorId}
            onWareHouseDetailsCardClick={this.handleWareHouseDetailsCardClick}
            handleSearchInput={this.handleSearchInput}
            binId={binId}
          />
        </div>
      </WarehouseStyled>
    );
  }
}

WarehouseMgmt.propTypes = propTypes;

WarehouseMgmt.defaultProps = defaultProps;

export default withAlert()(WarehouseMgmt);
