import styled from 'styled-components';

export const FundamentalStyled = styled.div`
  border: 1px solid #e7e7ed;
  margin: 20px 0;
  padding-bottom: 42px;
  border-radius: 4px;

  .barchart-yaxis {
    margin-bottom: -20px;
  }

  .recharts-tooltip-item-separator {
    display: none;
  }

  .recharts-tooltip-item-name {
    display: none;
  }
`;
