import styled from 'styled-components';

const DataNotFound = () => {
  return (
    <>
      <DataNotFoundStyled>
        <div className="data-not-found-wrap">No data found.</div>
      </DataNotFoundStyled>
    </>
  );
};

export default DataNotFound;

const DataNotFoundStyled = styled.div`
  .data-not-found-wrap {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #6b6c7e;
  }
`;
