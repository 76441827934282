
import { breadCrumbConfig } from '../../inventory/config';

const title = 'BU';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Rscore',
    path: '#',
    active: true,
  },

];

export { breadCrumb as breadCrumbConfig, title };
