import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Detail from './detail';
import { TableHeader } from './tableConfig';
import { Pagination } from '../../../../v4/components';
import { LIMIT, PAGE } from '../../../../data/enums/GeneralConstants';
import DispatchStyled from './DispatchStyled';
import CTAccordionStyled from '../../../../components/CollapsibleTable/CTAccordionStyled';

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    list: PropTypes.array,
    total: PropTypes.number,
  }),
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  onPageChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  selectedOrders: PropTypes.instanceOf(Array),
  onOrderStatusChangeClick: PropTypes.func.isRequired,
  onHeaderClick: PropTypes.func,
};

const defaultProps = {
  data: {
    list: [],
    total: 0,
    limit: LIMIT,
    page: PAGE,
  },
  activeLine: {
    id: '',
    sellerId: 0,
  },
  loading: false,
  selectedOrders: [],
  onHeaderClick: () => null,
};

const DispatchedView = ({ ...props }) => {
  const {
    data,
    loading,
    activeLine,
    onPageChange,
    onRowClick,
    selectedOrders,
    onOrderStatusChangeClick,
    onHeaderClick,
  } = props;

  return (
    <DispatchStyled>
      <div className='tab-section dispatched'>
        <CTAccordionStyled>
          <div className='table-wrap'>
            <table>
              {TableHeader({ onHeaderClick })}

              <Fragment>
                <Detail
                  data={data.list}
                  activeLine={activeLine}
                  selectedOrders={selectedOrders}
                  loading={loading}
                  onRowClick={onRowClick}
                  onOrderStatusChangeClick={onOrderStatusChangeClick}
                />
              </Fragment>
            </table>
          </div>
        </CTAccordionStyled>
        <Pagination currentPage={data.page} limit={data.limit} totalItems={data.total} onPageChange={onPageChange} />
      </div>
    </DispatchStyled>
  );
};

DispatchedView.propTypes = propTypes;

DispatchedView.defaultProps = defaultProps;

export default DispatchedView;
