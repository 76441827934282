import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { OUTLET_CHAIN } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Outlet Chain',
    path: `/${OUTLET_CHAIN}`,
    active: true,
  },
];
const title = 'Outlet Chain ';

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => {
      const dataList={
        title:data?.title,
        business_partner_id: data?.business_partner_id,
        image_url:data?.image_url || [],
      }
      return ({input:dataList})},
    responseName: 'createOutletChain',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => {
      const dataList={
        id:data?.id,
        title:data?.title,
        business_partner_id: data?.business_partner_id,
        image_url:data?.image_url || [],
      }
      return ({input:dataList})},
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};
const formMapper = (element) => ({
  id: element.id || '',
  title: element.title || '',
  business_partner_id:element?.business_partner_id || '',
  images_url:element?.images_url || [],
});

export {
  crudSuccess, breadCrumb as breadCrumbConfig,formMapper,title
};
