import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { ROUTE_FRAGMENT } from '../../API';
import { query as userQuery } from '../../../../configuration/user/userAccount/API';

const GET_ROUTE_DETAIL = gql`
  query getRetailOutlet($id: String) {
    routes(filter: { filters: [{ column: "id", value: [$id] }, { column: "active", value: ["true", "false"] }] }) {
      rows {
        ...RouteFragment
        routeLock
      }
    }
  }
  ${ROUTE_FRAGMENT}
`;

const ASSOCIATED_DISTRIBUTOR = gql`
  query getassociatedDistributors($userId: Int!) {
    associatedDistributorsForUser(userId: $userId) {
      Distributor {
        title
        id
      }
    }
  }
`;

const GET_LINE_DETAIL = gql`
  query getLines($limit: Int) {
    lines(limit: $limit, filter: { filters: [{ column: "active", value: ["true"] }] }) {
      rows {
        id
        title
        type
      }
    }
  }
`;

const UPDATE_ROUTE = graphql(
  gql`
    mutation ($id: Int!, $input: RouteInput!) {
      updateRoute(id: $id, input: $input) {
        ...RouteFragment
      }
    }
    ${ROUTE_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateRoute: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_ROUTE = graphql(
  gql`
    mutation ($input: RouteInput!) {
      createRoute(input: $input) {
        ...RouteFragment
      }
    }
    ${ROUTE_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createRoute: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getRouteDetail: GET_ROUTE_DETAIL,
  getUserList: userQuery.getUserWithTownList,
  getassociatedDistributors: ASSOCIATED_DISTRIBUTOR,
  getLines: GET_LINE_DETAIL,
};

const mutation = {
  updateRoute: UPDATE_ROUTE,
  createRoute: CREATE_ROUTE,
};

export { UPDATE_ROUTE, CREATE_ROUTE };

export { query, mutation };
