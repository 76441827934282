import styled from 'styled-components';

const VGPStyled = styled.div`
 .top-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 48px;
    .button-section{
      display: flex;
      .create-btn{
        margin-left: 8px;
      }
    }
  }
  /* Create UI */
  .create-top-section{
    background: #FFFFFF;
    padding: 48px;
    padding-bottom: 24px;
    label{
      display: inline-block;
      margin-bottom: 4px;
      color: #272833;
      font-size: 14px;
      font-weight: 600;
    }
    .total-point{
      line-height: 35px;
      font-size: 28px;
      font-weight: 600;
    }
    .pr-48{
      padding-right: 48px;
    }
    .datepicker-wrapper .filter-dropdown-card {
      z-index:99;
    }
  }
  .brand-select{
    padding: 24px 48px;
    .filter-item{
      width: 30%;
    }
    .filter-dropdown-card{
      right: unset;
      min-width: 25%;
    }
    .autocomplete-list {
      > div {
        margin-bottom: 5px;
        cursor: pointer;
        font-weight: 600;
        color: #6b6c7e;
        font-size: 14px;
        transition: 0.2s linear;

        &:hover,
        &:focus {
          color: #272833;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .suggested-autocomplete-list-wrap{
      padding-top: 18px;
      width: 30%;
      .suggested-autocomplete-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border:1px solid #e7e7ed;
        border-radius:4px;
        margin-bottom: 8px;
        background: #e7e7ed;

        .text{
          padding: 8px 16px;
          font-size:14px;
          line-height:21px;
          color: #272833;
        }
        .ic-close {
            cursor: pointer;
            margin-right: 16px;
            width: 12px;
            height: 12px;
            overflow: hidden;
            text-indent: -999px;
            background: url("/image/icons/ic_close.svg");
            background-size: 12px;
          }
      }
    }
  }
`;

export default VGPStyled;
