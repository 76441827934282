import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon, tableData } from '../../../v4/components';
import { ImageColumn } from '../../../utils/tableUtils';
import { USER_ROLE } from '../../../data/enums/UserRole';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const labelMappings = {
  date: 'date',
  fullName: 'fullName',
  userRole: 'userRole',
  phoneNumber: 'phoneNumber',
  checkIn: 'checkIn',
  checkOut: 'checkOut',
  location: 'location',
  agendas: 'agendas',
  checkInImage: 'checkInImage',
  checkOutImage: 'checkOutImage',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.fullName,
    title: 'Full Name',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.userRole,
    title: 'User Roles',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.phoneNumber,
    title: 'Phone Number',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.checkIn,
    title: 'Check In ',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.checkOut,
    title: 'Check Out',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.location,
    title: 'Location',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.agendas,
    title: 'Agendas',
    show: true,
    classNames: 'text-center',
  },
  {
    id: 9,
    label: labelMappings.checkInImage,
    title: 'Check In Image',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.checkOutImage,
    title: 'Check Out Image',
    show: true,
  },
];

const returnTableBody = (
  data,
  handleSliderOpen,
  permission,
  handleIconClick,
  handleData,
) => {
  const isUserDSE = data.User.Role.id === USER_ROLE.DSE;
  const table = {};
  table[labelMappings.date] = <td key={`${data.id}-date`}>{data.logDate}</td>;
  table[labelMappings.fullName] = <td key={`${data.id}-fullName`}>{data.User.fullName}</td>;
  table[labelMappings.userRole] = <td key={`${data.id}-userRole`}>{data.User.Designation !== null ? data.User.Designation.title : data.User.Role.name}</td>;
  table[labelMappings.phoneNumber] = <td key={`${data.id}-phoneNumber`}>{data.User.phoneNumber}</td>;
  table[labelMappings.checkIn] = (
    <td key={`${data.id}-checkIn`}>
      {moment(data.checkinTime).format('hh:mm A') === 'Invalid date' ? ' ' : moment(data.checkinTime).format('hh:mm A')}
    </td>
  );
  table[labelMappings.checkOut] = (
    <td key={`${data.id}-checkOut`}>
      {moment(data.checkoutTime).format('hh:mm A') === 'Invalid date'
        ? ' '
        : moment(data.checkoutTime).format('hh:mm A')}
    </td>
  );
  table[labelMappings.location] = (
    <td key={`${data.id}-phoneNumber`} className="location-td">
      <img
        src={'/image/marker.svg'}
        alt='marker'
        onClick={() => handleIconClick(EVENT_OPERATION.READ, handleData(data, EVENT_OPERATION.READ))}
      />
      <span>{data.checkinAddress}</span>
    </td>
  );
  table[labelMappings.agendas] = (
    <td key={`${data.id}-agendas`} className='text-center'>
      {!isUserDSE && (
        <Icon
          iconName='forms'
          onClick={() => handleIconClick(EVENT_OPERATION.UPDATE, handleData(data, EVENT_OPERATION.UPDATE))}
        />
      )}
    </td>
  );
  table[labelMappings.checkInImage] = (
    <ImageColumn
      keyTitle={`rp-images-${data.id}`}
      imageList={data.checkinImages || []}
      onImageClick={handleSliderOpen}
      key={`${data.id}-checkInImage`}
    />
  );
  table[labelMappings.checkOutImage] = (
    <ImageColumn
      keyTitle={`rp-images-${data.id}`}
      imageList={data.checkoutImages || []}
      onImageClick={handleSliderOpen}
      key={`${data.id}-checkOutImage`}
    />
  );

  return table;
};

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const TableBody = ({
  data,
  handleSliderOpen,
  permission,
  handleIconClick,
  handleData,
}) => {
  const tableBody = returnTableBody(
    data,
    handleSliderOpen,
    permission,
    handleIconClick,
    handleData,
  );
  return (
    <tr key={`${data.id}-tr`}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  permission: PropTypes.instanceOf(Object),
  handleSliderOpen: PropTypes.func.isRequired,
  handleIconClick: PropTypes.func.isRequired,
  handleData: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

export {
  TableHeader, TableBody, labelConfig
};
