import { ActionTypes } from './actionType';

const rScoreSuccess = data => {
  return {
    type: ActionTypes.SEND_RSCORE_SUC,
    payload: data,
  };
};

const rScoreReq = () => {
  return {
    type: ActionTypes.SEND_RSCORE_REQ,
  };
};

const rScoreFail = error => {
  return {
    type: ActionTypes.SEND_RSCORE_FAIL,
    payload: error,
  };
};

const rScoreP3MSuccess = data => {
  return {
    type: ActionTypes.SEND_P3M_RSCORE_SUC,
    payload: data,
  };
};

const rScoreP3MReq = () => {
  return {
    type: ActionTypes.SEND_P3M_RSCORE_REQ,
  };
};

const rScoreP3MFail = error => {
  return {
    type: ActionTypes.SEND_P3M_RSCORE_FAIL,
    payload: error,
  };
};

const rScoreP3WSuccess = data => {
  return {
    type: ActionTypes.SEND_P3W_RSCORE_SUC,
    payload: data,
  };
};

const rScoreP3WReq = () => {
  return {
    type: ActionTypes.SEND_P3W_RSCORE_REQ,
  };
};

const rScoreP3WFail = error => {
  return {
    type: ActionTypes.SEND_P3W_RSCORE_FAIL,
    payload: error,
  };
};

export {
  rScoreSuccess,
  rScoreReq,
  rScoreFail,
  rScoreP3MSuccess,
  rScoreP3MReq,
  rScoreP3MFail,
  rScoreP3WSuccess,
  rScoreP3WReq,
  rScoreP3WFail,
};
