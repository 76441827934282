import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { OUTLET_FRAGMENT, OUTLET_IMAGE_FRAGMENT } from '../API';

const GET_OUTLET_DETAIL = gql`
  query getRetailOutlet($id: String) {
    retailOutlets(
      filter: { filters: [{ column: "id", value: [$id] }, { column: "active", value: ["true", "false"] }] }
    ) {
      rows {
        ...OutletFragment
        Route {
          id
          title
        }
        Distributor {
          servicesUsed {
            billing {
              status
            }
          }
          id
        }
        contactInfo {
          name
          phoneNumber
        }
        socialContacts {
          landline
          viber
          whatsapp
        }
        phoneNumberVerified
        additionalInfo{
          outletCode
        }
      RetailOutletChain {
        id
        title
      }
      }
    }
  }
  ${OUTLET_FRAGMENT}
`;

const GET_OUTLET_IMAGES = gql`
  query getRetailOutlet($id: String) {
    retailOutlets(filter: { filters: [{ column: "id", value: [$id] }] }) {
      rows {
        imageUrl {
          ...OutletImageFragment
        }
      }
    }
  }
  ${OUTLET_IMAGE_FRAGMENT}
`;

const UPDATE_OUTLET = graphql(
  gql`
    mutation ($id: Int!, $input: RetailOutletInput!) {
      updateRetailOutlet(id: $id, input: $input) {
        ...OutletFragment
        address
        Route {
          id
          title
        }
        contactInfo {
          name
          phoneNumber
        }
        additionalInfo{
          outletCode
        }
        socialContacts {
          landline
          viber
          whatsapp
        }
        phoneNumberVerified
      }
    }
    ${OUTLET_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateOutlet: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_OUTLET = graphql(
  gql`
    mutation ($input: RetailOutletInput!) {
      createRetailOutlet(input: $input) {
        ...OutletFragment
        address
        Route {
          id
          title
        }
        contactInfo {
          name
          phoneNumber
        }
        additionalInfo{
          outletCode
        }
        socialContacts {
          landline
          viber
          whatsapp
        }
      }
    }
    ${OUTLET_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createOutlet: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_OUTLET_CHAIN = graphql(
  gql`
    mutation($input: OutletChainInput!) {
      createRetailOutletChain(input: $input) {
        id
        title
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      createOutletChain: variables => mutate({
        variables,
      }),
    }),
  },
);

const RETAIL_OUTLET_HISTORY = gql`
  query getRetailOutletHistory($outletId: Int!) {
    getRetailOutletHistory(outletId: $outletId) {
      outletid
      phonenumber
      userid
      name
      updatedtime
      isverified
    }
  }
`;

const ORDER_HISTORY = gql`
  query orderHistory($limit: Int, $offset: Int, $filter: FilterInput) {
    invoiceHistory(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        invoiceDate
        orderIds
        amountDetails {
          netAmount
          subTotal
          grossAmount
        }
        invoicedOrders: InvoiceOrders {
          quantity
          SKU {
            id
            title
            Rates {
              priceDetails {
                rlp
              }
            }
            brand {
              id
              title
              SKUs {
                id
                title
              }
            }
          }
        }
      }
      count
    }
  }
`;

const getRetailOutletChainList = gql`
query getOutletChain($searchText: String) {
  getOutletChain(searchText: $searchText){
    id
    title
  }
}
`


const query = {
  getOutletDetail: GET_OUTLET_DETAIL,
  getOutletImage: GET_OUTLET_IMAGES,
  getRetailOutletHistory: RETAIL_OUTLET_HISTORY,
  getOrderHistory: ORDER_HISTORY,
  getRetailOutletChainList: getRetailOutletChainList
};

const mutation = {
  updateOutlet: UPDATE_OUTLET,
  createOutlet: CREATE_OUTLET,
  createOutletChain: CREATE_OUTLET_CHAIN,
};

export { UPDATE_OUTLET, CREATE_OUTLET,CREATE_OUTLET_CHAIN, ORDER_HISTORY };

export { query, mutation };
