import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Input } from '../../components';
import { EVENT_OPERATION } from '../../data/enums/EventOperation';
import ChangePhoto from './changePhoto/ChangePhoto';
import { IMAGE_UPLOAD_DOMAIN } from '../../data/enums/GraphQL';
import withErrorAndLoadingScreen from '../../utils/composition/withLoading';
import CustomSelect from '../../components/CustomSelect';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  showEditButton: PropTypes.func.isRequired,
  handleImagesFetch: PropTypes.func.isRequired,
  images: PropTypes.string,
};

const defaultProps = {
  data: '',
  images: '',
};

const View = ({ ...props }) => {
  const { data, onIconClick, showEditButton, handleImagesFetch, images } = props;

  return (
    <div className={!showEditButton ? '' : 'disabled'}>
      <div className='config-wrap'>
        <div className='config-panel-content'>
          <Row>
            <Col md={3} className='mt-10'>
              {data.map((d) => (
                <>
                  <ChangePhoto
                    image={images}
                    getImages={(image) => {
                      handleImagesFetch(image);
                    }}
                    domain={IMAGE_UPLOAD_DOMAIN.PROFILE}
                    showEditButton={showEditButton}
                    data={d}
                  />
                </>
              ))}
            </Col>
            <Col md={7}>
              <div className='config-pannel-header'>
                <label>Personal Information</label>
              </div>

              {data.map((d) => (
                <>
                  <Input
                    disabled
                    type='text'
                    name='fullName'
                    placeholder='Full Name'
                    labelContent='Full Name'
                    value={d.fullName}
                  />
                  <Input disabled type='email' name='email' placeholder='Email' labelContent='Email' value={d.email} />
                  <Input
                    disabled
                    type='number'
                    name='phoneNumber'
                    placeholder='Phone'
                    labelContent='Phone'
                    value={d.phoneNumber}
                  />
                  <Input
                    disabled
                    type='text'
                    name='userGroup'
                    placeholder='User Group'
                    labelContent='User Group'
                    value={d.Role.name}
                  />
                   {d?.UserPrinciple?.length !== 0 && (
                     <CustomSelect
                      name='buList'
                      options={d?.UserPrinciple}
                      labelContent='Business Unit'
                      placeholder='Business Unit'
                      isMulti
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ title }) => title}
                      value={d?.UserPrinciple}
                      disabled
                    />
                   )}
                  {d.Distributor.length !== 0 && (
                    <CustomSelect
                      name='collection'
                      options={d.Distributor}
                      labelContent='SUB D'
                      placeholder='SUB D'
                      getOptionValue={({ value }) => value}
                      getOptionLabel={({ title }) => title}
                      value={d.Distributor}
                      disabled
                    />
                  )}
                </>
              ))}
            </Col>
          </Row>
        </div>
        <div className='config-panel-content'>
          <Row>
            <Col md={3} />
            <Col md={7}>
              <div className='config-pannel-header'>
                <label>Security</label>
              </div>
              <div className='security'>
                <Input
                  disabled
                  name='password'
                  type='password'
                  placeholder='Password'
                  labelContent='Password'
                  value='123456'
                />
              </div>
              {!showEditButton && (
                <div className='change-pw' onClick={() => onIconClick(EVENT_OPERATION.UPDATE, {})}>
                  Change Password
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const UserProfileViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default UserProfileViewWithErrorAndLoading;
