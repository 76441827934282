import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { GET_CATALOG_DETAIL_LIST } from '../../details/API';

const BRAND_COVERAGE_USER_TARGET_FRAGMENT = gql`
  fragment BrandCoverageUserTargetFragment on BrandCoverageUserTarget {
    id
    userId
    name
    target
    brandId
    brandGroup
    brandCoverageTargetId
    brandCoverageUserTargetId
    startDate
    endDate
    role
    isChildPresent
    childUsers: ChildUsers {
      id
      userId
      name
      target
      brandId
      brandGroup
      brandCoverageTargetId
      brandCoverageUserTargetId
      startDate
      endDate
      role
      isChildPresent
      childUsers: ChildUsers {
        id
        userId
        name
        target
        brandId
        brandGroup
        brandCoverageTargetId
        brandCoverageUserTargetId
        startDate
        endDate
        role
        isChildPresent
        childUsers: ChildUsers {
          id
          userId
          name
          target
          brandId
          brandGroup
          brandCoverageTargetId
          brandCoverageUserTargetId
          startDate
          endDate
          role
          isChildPresent
          childUsers: ChildUsers {
            id
            userId
            name
            target
            brandId
            brandGroup
            brandCoverageTargetId
            brandCoverageUserTargetId
            startDate
            endDate
            role
            isChildPresent
            childUsers: ChildUsers {
              id
              userId
              name
              target
              brandId
              brandGroup
              brandCoverageTargetId
              brandCoverageUserTargetId
              startDate
              endDate
              role
            }
          }
        }
      }
    }
  }
`;

const GET_USER_BRAND_COVERAGE_TARGET_LIST = gql`
  query getbrandCoverageUserTargetsList($startDate: String!, $endDate: String!, $followSalesHierarchy: Boolean) {
    brandCoverageUserTargetsList(
      startDate: $startDate
      endDate: $endDate
      followSalesHierarchy: $followSalesHierarchy
    ) {
      ...BrandCoverageUserTargetFragment
    }
  }
  ${BRAND_COVERAGE_USER_TARGET_FRAGMENT}
`;

const GET_USER_BRAND_COVERAGE_TARGET = gql`
  query getbrandCoverageUserTargets($startDate: String!, $endDate: String!, $userId: Int!) {
    brandCoverageUserTargets(startDate: $startDate, endDate: $endDate, userId: $userId) {
      target
      brandId
      brandName: brandGroup
    }
  }
`;

const CREATE_OR_EDIT_BRAND_COVERAGE_USER_TARGET = graphql(
  gql`
    mutation ($startDate: String!, $endDate: String!, $input: BrandCoverageUserTargetInput!) {
      createOrEditBrandCoverageUserTarget(startDate: $startDate, endDate: $endDate, input: $input) {
        ...BrandCoverageUserTargetFragment
      }
    }
    ${BRAND_COVERAGE_USER_TARGET_FRAGMENT}
  `,
  {
    props: ({ mutate }) => ({
      createOrEditBrandCoverageUserTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getbrandCoverageUserTargets: GET_USER_BRAND_COVERAGE_TARGET,
  getbrandCoverageUserTargetsList: GET_USER_BRAND_COVERAGE_TARGET_LIST,
  getCatalogDetailList: GET_CATALOG_DETAIL_LIST,
};

const mutation = {
  createOrEditBrandCoverageUserTarget: CREATE_OR_EDIT_BRAND_COVERAGE_USER_TARGET,
};

export {
  query, mutation, CREATE_OR_EDIT_BRAND_COVERAGE_USER_TARGET
};
