import styled from 'styled-components';

export const OutletWiseScoreStyled = styled.div`
  border: 1px solid #e7e7ed;
  margin: 20px 0;
  border-radius: 4px;

  .title-wrap {
    display: flex;
    align-items: center;
    h2 {
      color: #272833;
      font-size: 20px;
      font-weight: 700;
      padding: 0 15px;
    }
    .bbsm {
      .light {
        background: none;
        border: 1px solid #0e69ff;
        color: #0e69ff;
        font-weight: 500;
        height: 20px;
        border-radius: 5px;
        font-size: 11px;
        letter-spacing: 0.4px;
      }
    }
    .bigmart {
      .light {
        background: none;
        border: 1px solid #f27024;
        color: #f27024;
        font-weight: 500;
        height: 20px;
        border-radius: 5px;
        font-size: 11px;
        letter-spacing: 0.4px;
      }
    }
    .salesways {
      .light {
        background: none;
        border: 1px solid #43c187;
        color: #43c187;
        font-weight: 500;
        height: 20px;
        border-radius: 5px;
        font-size: 11px;
        letter-spacing: 0.4px;
      }
    }
  }

  .compliance-above {
    background-color: #5aca75;
    color: #ffffff;
    font-weight: 500;
    width: 65px;
    height: 20px;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 1;
  }

  .compliance-under {
    background-color: #da1414;
    color: #ffffff;
    font-weight: 500;
    width: 65px;
    height: 20px;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 1;
  }

  .compliance-middle {
    background-color: #ff8f39;
    color: #ffffff;
    font-weight: 500;
    width: 65px;
    height: 20px;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 1;
  }

  .fundamental {
    color: #8993a4;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 15px 0 18px;
  }

  .compliance-green {
    text-align: center;
    display: inline-block;
    .light {
      border: 1px solid;
      color: #47c464;
      min-width: 64px;
      padding: 3px 4px 3px 4px;
      border-radius: 2px;
      display: block;
      font-size: 12px;
      letter-spacing: 0.7px;
      font-weight: 400;
      height: 18px;
      line-height: 11.5px;
      text-align: center;
    }
  }

  .compliance-yellow {
    text-align: center;
    display: inline-block;
    .light {
      color: #e5ad12;
      border: 1px solid;
      min-width: 64px;
      padding: 3px 4px 3px 4px;
      border-radius: 2px;
      display: block;
      font-size: 12px;
      letter-spacing: 0.7px;
      font-weight: 400;
      height: 18px;
      line-height: 11.5px;
      text-align: center;
    }
  }

  .table-wrap {
    margin-top: 25px;

    .light {
      background-color: #287d3c;
      color: #ffffff;
      font-weight: 500;
      width: 60px;
      height: 20px;
      font-size: 11px;
      letter-spacing: 0.4px;
      line-height: 1;
    }

    table {
      thead {
        tr {
          height: 36px;
        }
      }
      tbody {
        tr {
          height: 34px;
        }
      }
    }
  }

  .table-footer {
    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .text-styling {
      padding-right: 12px;
      font-weight: 600;
      font-size: 13px;
    }
  }

  .td-right {
    text-align: right;
  }
  .td-middle {
    text-align: center;
  }
  .text-middle {
    text-align: center;
  }

  .modal-wrap {
    position: relative;
    display: flex;
    align-items: center;
    .progress-bar {
      width: 150px;
    }
    .icon-wrap {
      img {
        cursor: pointer;
      }
    }
  }

  .tooltip-wrap {
    bottom: 20px;
    left: 100px;
    opacity: 0;
    z-index: 99;
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    -webkit-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    transition: all 150ms linear;
    font-weight: 500;
    visibility: hidden;
    pointer-events: none;
    background-color: #ffffff;
    position: absolute;
    min-width: 10vw;
    padding: 5px 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    line-height: 0px;
    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        font-size: 12px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        padding-left: 5px;
      }
    }
  }

  .info-button {
    padding-left: 8px;
  }

  .info-button:hover + .tooltip-wrap {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;
