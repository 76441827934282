import { Badge } from '../../../../../components';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { tableData } from '../../../../../v4/components';
import { trackCompliance } from '../../../config';

const labelMappings = {
  mrOutlet: 'mrOutlet',
  noOfOutlet: 'noOfOutlet',
  pointsAllocated: 'pointsAllocated',
  pointScored: 'pointScored',
  gap: 'gap',
  compliance: 'compliance',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.mrOutlet,
    title: 'MR Outlet',
    show: true,
    // align: 'text-left',
  },
  {
    id: 1,
    label: labelMappings.noOfOutlet,
    title: 'No. of Audited Outlet',
    show: true,
    align: 'text-right',
  },
  {
    id: 2,
    label: labelMappings.pointsAllocated,
    title: 'Points Allocated',
    show: true,
    align: 'text-right',
  },
  {
    id: 3,
    label: labelMappings.pointScored,
    title: 'Point Scored',
    show: true,
    align: 'text-right',
  },
  {
    id: 4,
    label: labelMappings.gap,
    title: 'Gap',
    show: true,
    align: 'text-right',
  },
  {
    id: 5,
    label: labelMappings.compliance,
    title: 'Compliance',
    show: true,
    align: 'text-middle',
  },
];

const returnTableBody = data => {
  const table = {};
  table[labelMappings.mrOutlet] = (
    <td key={`${data.id}-${labelMappings.mrOutlet}`}>
      <div className="mr-outlet">
        <div className={data?.outlet.toLowerCase()}></div>
        <span>{data?.outlet}</span>
      </div>
    </td>
  );
  table[labelMappings.pointsAllocated] = (
    <td key={`${data.id}-${labelMappings.pointsAllocated}`} className="td-right">
      {data?.points_allocated}
    </td>
  );
  table[labelMappings.noOfOutlet] = (
    <td key={`${data.id}-${labelMappings.noOfOutlet}`} className="td-right">
      {data?.outlet_length}
    </td>
  );
  table[labelMappings.pointScored] = (
    <td key={`${data.id}-${labelMappings.pointScored}`} className="td-right">
      {data?.pointScored}
    </td>
  );
  table[labelMappings.gap] = (
    <td key={`${data.id}-${labelMappings.gap}`} className="td-right">
      {fixedFloatAndCommas(data?.points_allocated - data?.pointScored)}
    </td>
  );
  table[labelMappings.compliance] = (
    <td key={`${data.id}-${labelMappings.compliance}`} className="td-middle">
      {/* {data?.percent}% */}
      <Badge label={`${data?.percent}%`} className={trackCompliance(data?.percent)} />
    </td>
  );

  return table;
};
const TableHeader = () => (
  <thead>
    <tr>{labelConfig.map(label => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const TableBody = ({ data }) => {
  const tableBody = returnTableBody(data);

  return (
    <tr key={data.distributorId} className="cursor-pointer">
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

export { TableHeader, TableBody };
