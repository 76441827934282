import React from 'react';
import PropTypes from 'prop-types';
import { Input,CustomSelect} from '../../../../../../components';
import {CheckBox} from  '../../../../../../v4/components'
import withErrorAndLoadingScreen from '../../../../../../utils/composition/withLoading';
;
import {
  NOTIFICATION_ALERT_MESSAGE, PLATFORM_TYPE, week, weekDays,periodType
} from '../../../../../../data/enums/Notification';
import FormContentStyled from '../../../../../../components/global-css/FormContentStyled';
import ReplenishmentModelWrapper from './replenishmentModelWrapper'

const propTypes = {
  data: PropTypes.objectOf(Object),
  onInputChange: PropTypes.func.isRequired,
};

const defaultProps = {
  data: {},
};

const monthDaysValidation = (e) => {
  const today = new Date();
  const lastMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
  const lastDate = lastMonth.getDate()
  const num = Number(e.target.value)
  let events = e 
  if(e.target.value) {
    if(num <1 ) {
      events = {...e,target: {...e.target, value: 1}}
    } else if(num > Number(lastDate)) {
       events = {...e,target: {...e.target, value: Number(lastDate) }}
    }
 }     
 
 return events
}

              

const ReplenishmentModelView = ({ data, onInputChange,onRadioButtonChange }) => 
  
(
  <ReplenishmentModelWrapper>
  <div className='panel-section create-ui-inner'>
    <FormContentStyled>
      <h3>General Settings</h3>
      <div className='form-input-wrap'>
        <Input
          value={data.stockDaysHolding?data.stockDaysHolding.powerSKU:0}
          name='powerSKU'
          type='number'
          rule='matches'
          labelContent='Days of Stock Holding for Power 
          SKU:'
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'stockDaysHolding', ['stockDaysHolding','powerSKU']);
          }}
        />
        <Input
          value={data.stockDaysHolding?data.stockDaysHolding.nonPowerSKU:0}
          name='nonPowerSKU'
          type='number'
          rule='matches'
          labelContent='Days of Stock Holding for Non 
          Power SKU:'
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'stockDaysHolding', ['stockDaysHolding','nonPowerSKU']);
          }}
        />
        <Input
          value={data.availabilityDays}
          name='availabilityDays'
          type='number'
          rule='matches'
          labelContent='Availability Days:'
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'availabilityDays', ['availabilityDays']);
          }}
        />
        <Input
          value={data.averageDays}
          name='averageDays'
          type='number'
          rule='matches'
          labelContent='Average Days:'
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'averageDays', ['averageDays']);
          }}
        />
      </div>
    </FormContentStyled>
    <FormContentStyled>
      <h3>Sufficiency Days</h3>
      <div className='form-input-wrap'>
        <Input
          value={data.sufficiencyDays ? data.sufficiencyDays.min : 0}
          name='min'
          type='number'
          rule='matches'
          labelContent='Minimum Days'
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'sufficiencyDays', ['sufficiencyDays', 'min']);
          }}
        />
        <Input
          value={data.sufficiencyDays ? data.sufficiencyDays.max : 0}
          name='max'
          type='number'
          rule='matches'
          labelContent='Maximum Days'
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'sufficiencyDays', ['sufficiencyDays', 'max']);
          }}
        />
      </div>
    </FormContentStyled>
    <FormContentStyled>
      <h3>Sufficiency</h3>
      <div className='form-input-wrap'>
        <Input
          value={data.sufficiencyMatrix.under}
          name='under'
          type='number'
          rule='matches'
          labelContent={`Below ${data.sufficiencyDays.min === '' ? 'x' : data.sufficiencyDays.min} Days`}
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'sufficiencyMatrix', ['sufficiencyMatrix', 'under']);
          }}
        />
        <Input
          value={data.sufficiencyMatrix.ideal}
          name='ideal'
          type='number'
          rule='matches'
          labelContent={`${data.sufficiencyDays.min === '' ? 'x' : data.sufficiencyDays.min} - 
          ${data.sufficiencyDays.max === '' ? 'x' : data.sufficiencyDays.max} days`}
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'sufficiencyMatrix', ['sufficiencyMatrix', 'ideal']);
          }}
        />
        <Input
          value={data.sufficiencyMatrix.over}
          name='over'
          type='number'
          rule='matches'
          labelContent={`${data.sufficiencyDays.max === '' ? 'x' : data.sufficiencyDays.max} and above`}
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'sufficiencyMatrix', ['sufficiencyMatrix', 'over']);
          }}
        />
      </div>
    </FormContentStyled>
    <FormContentStyled>
      <h3>Score Weightage</h3>
      <div className='form-input-wrap'>
        <Input
          value={data.sufficiencyWeightage.availability}
          name='availability'
          type='number'
          rule='matches'
          labelContent='Availability:'
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'sufficiencyWeightage', ['sufficiencyWeightage', 'availability']);
          }}
        />
        <Input
          value={data.sufficiencyWeightage.sufficiency}
          name='sufficiency'
          type='number'
          rule='matches'
          labelContent='Sufficiency:'
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'sufficiencyWeightage', ['sufficiencyWeightage', 'sufficiency']);
          }}
        />
        <Input
          value={data.sufficiencyWeightage.overStock}
          name='overStock'
          type='number'
          rule='matches'
          labelContent='Overstock:'
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'sufficiencyWeightage', ['sufficiencyWeightage', 'overStock']);
          }}
        />
      </div>
    </FormContentStyled>
    <FormContentStyled>
      <h3>Sufficiency</h3>
      <div className='form-input-wrap'>
        <Input
          value={data.sufficiencyMatrix.under}
          name='under'
          type='number'
          rule='matches'
          labelContent={`Below ${data.sufficiencyDays.min === '' ? 'x' : data.sufficiencyDays.min} Days`}
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'sufficiencyMatrix', ['sufficiencyMatrix', 'under']);
          }}
        />
        <Input
          value={data.sufficiencyMatrix.ideal}
          name='ideal'
          type='number'
          rule='matches'
          labelContent={`${data.sufficiencyDays.min === '' ? 'x' : data.sufficiencyDays.min} - 
          ${data.sufficiencyDays.max === '' ? 'x' : data.sufficiencyDays.max} days`}
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'sufficiencyMatrix', ['sufficiencyMatrix', 'ideal']);
          }}
        />
        <Input
          value={data.sufficiencyMatrix.over}
          name='over'
          type='number'
          rule='matches'
          labelContent={`${data.sufficiencyDays.max === '' ? 'x' : data.sufficiencyDays.max} and above`}
          argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
          placeholder='0'
          enableErrorDisplay={false}
          enableValidation
          onChange={(e) => {
            onInputChange(e, 'sufficiencyMatrix', ['sufficiencyMatrix', 'over']);
          }}
        />
      </div>
    </FormContentStyled>
    <FormContentStyled>
      <h3>Replenishment Frequency</h3>
      <div className='permission-choose'>
            <label>Frequency</label>
            <div>
              <label className='radio-list'>
                <input
                  type='radio'
                  name='repeatedTimes'
                  value='DAILY'
                  onChange={(e) => onRadioButtonChange(e)}
                  checked={data.repeatedTimes ? data.repeatedTimes.daily : false}
                />
                <span className='text'>Daily</span>
              </label>
              <label className='radio-list'>
                <input
                  type='radio'
                  name='repeatedTimes'
                  value='Weekly'
                  onChange={(e) => onRadioButtonChange(e)}
                  checked={data.repeatedTimes ? data.repeatedTimes.weekly : false}
                />
                <span className='text'>Weekly</span>
              </label>
              <label className='radio-list'>
                <input
                  type='radio'
                  name='repeatedTimes'
                  value='Fortnightly'
                  onChange={(e) => onRadioButtonChange(e,"fortnightly")}
                  checked={data.repeatedTimes ? data.repeatedTimes.fortnightly : false}
                />
                <span className='text'>Fortnightly</span>
              </label>
              <label className='radio-list'>
                <input
                  type='radio'
                  name='repeatedTimes'
                  value='Trimonthly'
                  onChange={(e) => onRadioButtonChange(e,"trimonthly")}
                  checked={data.repeatedTimes ? data.repeatedTimes.trimonthly : false}
                />
                <span className='text'>Thrice a Month</span>
              </label>
              <label className='radio-list'>
                <input
                  type='radio'
                  name='repeatedTimes'
                  value='Monthly'
                  onChange={(e) => onRadioButtonChange(e,"monthly")}
                  checked={data.repeatedTimes ? data.repeatedTimes.monthly : false}
                />
                <span className='text'>Monthly</span>
              </label>
            </div>
          </div>
           {data.repeatedTimes.weekly && 
            (<div className='reapeat__days'>
              <label>Repeat Days</label>
              <div className='checkbox-list'>
                {week.map((d, index) => (
                  <CheckBox
                    key={d}
                    checked={data.repeatDays.includes(weekDays[d])}
                    name={d}
                    className='checkbox'
                    labelText={d.slice(0, 3).toUpperCase()}
                    onCheckBoxClick={(e) => {
                      onInputChange(
                        {
                          formattedValue: weekDays[d],
                          target: {
                            name: parseInt(data.repeatDays.length, 10),
                            value: weekDays[d],
                            checked: !!e.target.checked,
                          },
                        },
                        'repeatDays',
                        [],
                      );
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          <div className='form-input-wrap month-days'>
          {!data.repeatedTimes.daily && !data.repeatedTimes.weekly && <Input
            className='month-input'
            value={data.dayOfTheMonth}
            name='dayOfTheMonth'
            type='number'
            rule="matches"
            argument={/^([1-9]|[0-2][0-9]|[3][0])$/}
            labelContent='Day of the Month'
            placeholder='0'
            enableErrorDisplay={false}
            enableValidation
            onChange={(e) => {
              const event = monthDaysValidation(e)
              onInputChange(event, "dayOfTheMonth")}}
          />}
        <div className='time'>
            <label className='time-label'>Time</label>
            <div className='time__hours'>
              <Input
                value={data.times.hours}
                type='number'
                onChange={(event) => onInputChange(event, 'times', ['times', 'hours'])}
                argument={{
                  max: 12,
                }}
              />
              <label>HH</label>
            </div>
            <div className='time__hours'>
              <Input
                value={data.times.minutes}
                type='number'
                placeholder='MM'
                onChange={(event) => onInputChange(event, 'times', ['times', 'minutes'])}
                allowMultiZeroBeforeNumber
                argument={{
                  max: 60,
                }}
              />
              <label>MM</label>
            </div>
            <div className='time__hours'>
              <CustomSelect
                name='period'
                className='custom-select'
                options={periodType}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                value={periodType.filter(({ title }) => title === data.times.period)}
                onChange={(event) => { onInputChange({...event, formattedValue :event.title, target: {
                  name: "period",
                  value: event.title,
                  checked: true,
                }, }, 'times', ['times', 'period'])}}
              />
              <label>AM/PM</label>
            </div>
          </div>
        
        </div>
    </FormContentStyled>
  </div>
  </ReplenishmentModelWrapper>
);

ReplenishmentModelView.propTypes = propTypes;

ReplenishmentModelView.defaultProps = defaultProps;

export default withErrorAndLoadingScreen(ReplenishmentModelView);
