import { compose } from 'recompose';
import MT from './MT';
import { query, mutation, DELETE_MT_STOCK } from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const composedMT = compose(
  DELETE_MT_STOCK,
  withRequestTracker({
    query,
    mutation,
  }),
)(MT);

export default composedMT;
