import Switch from 'react-switch';

const ToggleSwitch = ({ ...props }) => {
  const { id, checked, className, disabled, labelContent, onToggle, name } = props;
  return (
    <>
      <div className='form-input switch'>
        <label className='custom-control-label-text'>
          {labelContent !== undefined ? <span>{labelContent}</span> : ''}
        </label>
        <Switch
          onChange={(checkStatus: any, event: any) => onToggle(checkStatus, event)}
          checked={checked}
          uncheckedIcon={false}
          checkedIcon={false}
          id={id}
          className={className}
          name={name}
          onColor="#0E69FF"
          offColor="#A7A9BC"
          handleDiameter={20}
          disabled={disabled}
          height={24}
          width={40}
        />
      </div>
    </>
  );
};

export default ToggleSwitch;
