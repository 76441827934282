import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { CustomSelect } from '../../../../components';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { Icon ,Button} from '../../../../v4/components';

const propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string,
    repetition: PropTypes.bool,
    userId: PropTypes.number,
    userTitle: PropTypes.string,
    routeId: PropTypes.number,
    lineId: PropTypes.number,
    visitFrequencyId: PropTypes.number,
  }).isRequired,
  onDialogSubmit: PropTypes.func,
  onDialogClose: PropTypes.func,
  crudMode: PropTypes.string.isRequired,
  routeVisitFrequencyList: PropTypes.arrayOf(Object),
  routeList: PropTypes.arrayOf(Object),
};

const defaultProps = {
  onDialogSubmit: () => null,
  onDialogClose: () => null,
  routeVisitFrequencyList: [],
  routeList: [],
};

class Dialog extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);

    const { data } = props;

    this.state = {
      refsObj: {
        route: null,
        line: null,
      },
      visitFrequency: {
        id: null,
        title: '',
      },
      data: {
        date: '2020-01-01',
        repetition: !!data.repetition,
        userId: data.userId || null,
        userTitle: data.userTitle || '',
        routeId: data.routeId || null,
        lineId: data.lineId || null,
        visitFrequencyId: data.visitFrequencyId || null,
        assignedRoute: data.assignedRoute || true,
        distributorList: data.distributorList,
        subDId: data.subDId ||null,
      },
      crudMode: props.crudMode || EVENT_OPERATION.CREATE,
      enableErrorDisplay: false,
    };
  }

  componentDidMount() {}

  handleButtonSubmit = () => {
    const { data } = this.state;
debugger
    if (!data.routeId || data.lineId===undefined) {
      this.setState({ enableErrorDisplay: true });
      return
    }else{
    const { onDialogSubmit } = this.props;
    onDialogSubmit(data);
}
    // call the server with data
  };

  getDefaultLineId = (route, userId) => {
    if (route.RouteLines.length === 1) {
      return route.RouteLines[0].id || null;
    }

    const userLine = route.RouteLines.find(line => line.assignedTo === userId);

    return userLine ? userLine.id : null;
  };

  // route drop down change.
  handleDropDownChange = route => {
    const { data } = this.state;
    data.routeId = route.id;
    // data.visitFrequencyId = route.VisitFrequency.id;
    data.lineId = this.getDefaultLineId(route, data.userId);

    const selectedLine = route.RouteLines.find(el => el.id === data.lineId);
    data.visitFrequencyId = (selectedLine && selectedLine.VisitFrequency && selectedLine.VisitFrequency.id) || null;
    data.lineId = selectedLine && selectedLine.lineId;
    this.setState({
      data,
      visitFrequency: route.VisitFrequency,
    });
  };

  handleLineDropDownChange = (route, line) => {
    const { data } = this.state;

    if (!(route && line)) {
      data.lineId = null;
      data.visitFrequencyId = null;
    } else {
      data.lineId = line.lineId;
      const selectedLine = route.RouteLines.find(el => el.lineId === line.lineId);
      data.visitFrequencyId = selectedLine.VisitFrequency.id || null;
    }

    this.setState({ line });
  };

  getVisitFrequencyTitle = (line = null) => {
    let title = '-';

    if (line) {
      title = line.VisitFrequency.title;
    }

    return title;
  };

  handleRepeatButtonClick = (value = false) => {
    const { data } = this.state;
    data.repetition = value;
    this.setState({ data });
  };

  handleIconClose = () => {
    const { onDialogClose } = this.props;
    onDialogClose();
  };

  handleRouteAssignmentClick = (value = true) => {
    const { data } = this.state;
    data.assignedRoute = value;
    data.routeId = null;
    data.visitFrequencyId = null;
    data.lineId = null;
    this.setState({ data });
  };

  getVisitFrequencyClassName = line => {
    return line ? `btn status_${line.VisitFrequency.id}` : 'btn';
  };

  checkIfUsersRoute = (route, userId) => {
    const assignedUserList = route.RouteLines.map(el => el.assignedTo);

    return assignedUserList.includes(userId);
  };

  render() {
    const { data, refsObj, enableErrorDisplay, crudMode } = this.state;

    const { routeList, subDDetails, onDropDownClick,subDId,dseList,dseId } = this.props;

    // const filteredRouteList = routeList.filter(route => {
    //   const assignedUserList = route.RouteLines.map(line => line.assignedTo);

    //   return data.assignedRoute ? assignedUserList.includes(data.userId) : !assignedUserList.includes(data.userId);
    // });

    const selectedRoute = routeList.find(route => route.id === data.routeId);

    const selectedRouteLineList = selectedRoute ? selectedRoute.RouteLines.map(a=>({
      ...a,
      title:a?.Line?.title
    })) : [];

    const selectedLine = selectedRoute ? selectedRoute.RouteLines.find(el => el.lineId === data.lineId) : null;

    // Filter DropDown Options:
    // assigned cha vane dekhaunu pardaina
    // single option cha vane dehaunu pardaina
    

    return (
      <Fragment>
        <div className="roster-popup-inner">
          <div className="header">
            <h3>{crudMode === EVENT_OPERATION.UPDATE ? 'Edit Assigned Route' : 'Assign Route'}</h3>
            <Icon className="cursor-pointer" iconName="times" onClick={() => this.handleIconClose()} />
          </div>
          <div className="body">
            <div className="user-title">
              <span className="sub-title">{subDDetails.title}</span>
              <h4>{data.userTitle}</h4>
            </div>
            <div className="user-title">
                  <span className="sub-title">Sub D</span>
                  <CustomSelect
                    className="custom-select"
                    placeholder="Select Sub D"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => id}
                    options={data.distributorList}
                    value={data.distributorList.filter(({ id }) => id === subDId)}
                    onChange={e => onDropDownClick(e,'subd')}
                  />
                  <span className="sub-title">DSE</span>
                  <CustomSelect
                    className="custom-select"
                    placeholder="Select DSE"
                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                    getOptionValue={({ id }) => id}
                    options={dseList}
                    value={dseList.filter(({ id }) => id === dseId)}
                    onChange={e => onDropDownClick(e,'dse')}
                  />
              <div className="route-frequency">
                <div className="route">
                  <span>Route</span>
                  <CustomSelect
                    ref={ref => {
                      refsObj.route = ref;
                      return null;
                    }}
                    clearable={false}
                    className="custom-select"
                    placeholder="Select Route"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => id}
                    options={routeList}
                    enableValiation
                    enableErrorDisplay={enableErrorDisplay}
                    value={routeList.filter(({ id }) => id === data.routeId)}
                    onChange={route => this.handleDropDownChange(route)}
                  />
                </div>
                {selectedRouteLineList.length > 0 ? (
                  <div className="frequency">
                    <span>Frequency</span>
                    <div className={this.getVisitFrequencyClassName(selectedLine)}>
                      {this.getVisitFrequencyTitle(selectedLine)}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="repeat-block">
                <div className="inline-radio-label">
                  <div>
                    <input
                      type="radio"
                      name="relatedType"
                      checked={data.repetition}
                      onChange={() => this.handleRepeatButtonClick(true)}
                    />
                    <span>Repeat</span>
                  </div>
                </div>
                <div className="inline-radio-label">
                  <div>
                    <input
                      type="radio"
                      name="relatedType"
                      checked={!data.repetition}
                      onChange={() => this.handleRepeatButtonClick(false)}
                    />
                    <span>Once</span>
                  </div>
                </div>
              </div>
              {selectedRouteLineList.length > 1 && !this.checkIfUsersRoute(selectedRoute, data.userId) ? (
                <div className="route-frequency">
                  <div className="route">
                    <span>Line</span>
                    <CustomSelect
                      ref={ref => {
                        refsObj.line = ref;
                        return null;
                      }}
                      className="custom-select"
                      placeholder="Select Line"
                      getOptionLabel={({ title }) => title}
                      getOptionValue={({ lineId }) => lineId}
                      options={selectedRouteLineList}
                      enableValidation
                      enableErrorDisplay={enableErrorDisplay}
                      value={selectedRouteLineList.filter(({ lineId }) => lineId === data.lineId)}
                      onChange={line => this.handleLineDropDownChange(selectedRoute, line)}
                    />
                  </div>
                </div>
              ) : (
                <Fragment />
              )}
            </div>
            </div>
          
          {crudMode === EVENT_OPERATION.UPDATE && !data.repetition ? (
            <div className="edit-text">
              <Icon iconName="exclamation-circle" />
              <span className="ml-4">This change will assign route for the selected date only.</span>
            </div>
          ) : null}
          <div className={data.routeId && data.lineId ? 'assign-btn' : 'assign-btn disabled'}>
            <Button title="Assign" primary onClick={() => this.handleButtonSubmit()} />
          </div>
          </div>
      </Fragment>
    );
  }
}

Dialog.propTypes = propTypes;

Dialog.defaultProps = defaultProps;

export default Dialog;
