import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../utils/paginatedListUtils';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { normalPresentor } from '../../../../utils/date';

const labelMappings = {
  sku: 'sku',
  lastUpdatedDate: 'lastUpdatedDate',
  quantity: 'quantity',
  outlet:'outlet',
  Chain:'chain'
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.lastUpdatedDate,
    title: 'Last Updated Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.quantity,
    title: 'Quantity',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.Chain,
    title: 'Chain',
    show: true,
  },
];

const TableHeader = () => (
  <thead>
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align) : null
      ))}
    </tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.sku] = (
    <td key={`inventory-${data.id}-sku`}>{data.sku}</td>
  );
  table[labelMappings.lastUpdatedDate] = (
    <td key={`inventory-${data.id}-lastUpdatedDate`}>{normalPresentor(data.updatedDate)}</td>
  );
  table[labelMappings.quantity] = (
    <td key={`inventory-${data.id}-quantity`}>
      <div>{`${data.currentcases} cs / ${data.currentpcs} pcs`}</div>
      <p className={`quantity ${data.increased ? 'positive' : 'negative'}`}>{`${data.increased ? '+' : '-'} ${data.changedcases} cs / ${data.changedpcs} pcs`}</p>
    </td>
  );
  table[labelMappings.outlet] = (
    <td key={`inventory-${data.id}-outlet`}>
      <div>{`${data.retailTitle}`}</div>
    </td>
  );
  table[labelMappings.Chain] = (
    <td key={`inventory-${data.id}-chain`}>
      <div>{`${data.chainTitle}`}</div>
    </td>
  );
  return table;
};

const TableBody = ({
  data, onRowClick,
}) => {
  const tableBody = returnTableBody(data);

  return (
    <tr className="cursor-pointer" onClick={() => onRowClick(EVENT_OPERATION.READ, data)}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export {
  TableHeader,
  TableBody,
};
