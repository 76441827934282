import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, CustomSelect, Input, Row, SingleDatePicker,
} from '../../../../../../../../components';

const propTypes = {
  basicData: PropTypes.objectOf(Object).isRequired,
  onInputChange: PropTypes.func,
  onDropDownChange: PropTypes.func,
};
const defaultProps = {
  onInputChange: () => null,
  onDropDownChange: () => null,
};

const BasicDetails = (props) => {
  const {
    sbdData: {
      basicData: {
        title, start_date, end_date, outlet_id, sbdcategory, channel_id,
      },
    }, menu: { channel, category, outlet }, onInputChange, onDropDownChange, refsObj, enableErrorDisplay, update,
  } = props;
  const channelId = channel_id;
  const filteredCategoryList = channelId
    ? category.filter(el => el.Channel.id === channelId) : category;
  return (
    <div className="create-top-section">
      <Row className={!update.status ? 'disabled' : null}>
        <Col md={4} className="pr-48">
          <Input
            value={title}
            name="title"
            type="text"
            labelContent="SBD Template Name"
            placeholder="SBD Template Name"
            onChange={event => onInputChange(event)}
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
            ref={ref => (refsObj.title = ref)}
          />
        </Col>
        <Col md={4} className="pr-48">
          <CustomSelect
            name="channel"
            onChange={(event) => {
              onDropDownChange(event.id, 'channel_id');
            }}
            options={channel}
            labelContent="Channel"
            placeholder="Select Channel"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            value={channel.filter(
              ({ id }) => id === channel_id,
            )}
            ref={ref => (refsObj.channel_id = ref)}
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
          />
        </Col>
        <Col md={4} />
        <Col md={4} className="pr-48">
          <div className="form-input custom-date">
            <label>Start Date</label>
            <SingleDatePicker
              date={start_date}
              name="start_date"
              onChange={(name, date) => onInputChange({
                target: {
                  name: 'start_date',
                },
                formattedValue: date,
              })}
            />
          </div>
        </Col>
        <Col md={4} className="pr-48">
          <CustomSelect
            name="category"
            onChange={(event) => {
              const ids = event.map(d => d.id);
              onDropDownChange(ids, 'sbdcategory');
            }}
            isMulti
            options={filteredCategoryList}
            labelContent="Category"
            placeholder="Select Category"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            value={category
              .filter(({ id }) => (sbdcategory ? sbdcategory.includes(id) : false))}
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
            ref={ref => (refsObj.sbdcategory = ref)}
          />
        </Col>
        <Col md={4} />
        <Col md={4} className="pr-48">
          <div className="form-input custom-date">
            <label>End Date</label>
            <SingleDatePicker
              date={end_date}
              name="end_date"
              onChange={(name, date) => onInputChange({
                target: {
                  name: 'end_date',
                },
                formattedValue: date,
              })}
              minDate={start_date}
            />
          </div>
        </Col>
        <Col md={4} className="pr-48">
          <CustomSelect
            name="outlet"
            onChange={(event) => {
              onDropDownChange(event.id, 'outlet_id');
            }}
            options={outlet}
            labelContent="Outlet"
            placeholder="Select Outlet"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            value={outlet.filter(
              ({ id }) => id === outlet_id,
            )}
          />
        </Col>
        <Col md={4} />
      </Row>
    </div>
  );
};

BasicDetails.propTypes = propTypes;
BasicDetails.defaultProps = defaultProps;

export default BasicDetails;
