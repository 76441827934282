import { compose } from 'recompose';
import UserDetails from './UserDetails';
import { query, mutation, UPDATE_USER_FUNDAMENTAL_TARGET_DETAILS } from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposedUserDetails = compose(
  UPDATE_USER_FUNDAMENTAL_TARGET_DETAILS,
  withRequestTracker({
    query,
    mutation,
  }),
)(UserDetails);

export default ComposedUserDetails;
