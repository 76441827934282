import { Badge } from '../../../../../components';
import Progress from '../../../../../components/progress';
import { ArrayOfObjSum } from '../../../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { tableData } from '../../../../../v4/components';
import {
  capitalizeAllFirstLetter,
  capitalizeFirstLetter,
  fundamentalList,
  getFundamentalTitle,
  trackCompliance,
} from '../../../config';

const returnTableBody = (data, labelMappings, bgColor, baseBgColor, title) => {
  const table = {};
  table[labelMappings.chart] = (
    <td key={`${data.id}-${labelMappings.chart}`} className="progress-wrap modal-wrap">
      <div className="progress-bar">
        <Progress
          completed={(data?.pointScoredSum / data?.pointsAllocatedSum) * 100}
          bgColor={bgColor}
          baseBgColor={baseBgColor}
        />
      </div>

      <div className={`${title?.replaceAll(' ', '-')} icon-wrap`}>
        <img src="/image/icons/info.svg" alt="info" className="info-button" />
        <div className="tooltip-wrap">
          {title !== capitalizeAllFirstLetter(fundamentalList.ON_SHELF_AVAILABILITY) && (
            <div>
              <span>{`Allocated ${getFundamentalTitle(title)} :`}</span>
              <p>{fixedFloatAndCommas(data?.allocated, 1)}</p>
            </div>
          )}

          <div>
            {title === capitalizeAllFirstLetter(fundamentalList.ON_SHELF_AVAILABILITY)
              ? 'Required SKU :'
              : `Required ${getFundamentalTitle(title)} :`}
            <p>{fixedFloatAndCommas(data?.requiredJBP, 1)}</p>
          </div>
          <div>
            <span>
              {title === capitalizeAllFirstLetter(fundamentalList.ON_SHELF_AVAILABILITY)
                ? 'Audited SKU :'
                : `Audited ${getFundamentalTitle(title)} :`}
            </span>
            <p>{fixedFloatAndCommas(data?.audited, 1)}</p>
          </div>
        </div>
      </div>
    </td>
  );

  table[labelMappings.category] = (
    <td key={`${data.id}-${labelMappings.category}`} className="td-left">
      {capitalizeFirstLetter(data?.property)}
    </td>
  );

  table[labelMappings.pointsAllocated] = (
    <td key={`${data.id}-${labelMappings.pointsAllocated}`} className="td-right">
      {fixedFloatAndCommas(data?.pointsAllocatedSum, 1)}
    </td>
  );

  table[labelMappings.pointsScored] = (
    <td key={`${data.id}-${labelMappings.pointsScored}`} className="td-right">
      {fixedFloatAndCommas(data?.pointScoredSum, 1)}
    </td>
  );

  table[labelMappings.gap] = (
    <td key={`${data.id}-${labelMappings.gap}`} className="td-right">
      {fixedFloatAndCommas(data?.pointsAllocatedSum - data?.pointScoredSum, 1)}
    </td>
  );

  table[labelMappings.bonusAwarded] = (
    <td key={`${data.id}-${labelMappings.bonusAwarded}`} className="td-right">
      {fixedFloatAndCommas(data?.bonusAwarded, 1) ?? fixedFloatAndCommas(data?.bonus_point, 1)}
    </td>
  );

  table[labelMappings.compliance] = (
    <td key={`${data.id}-${labelMappings.compliance}`} className="td-middle">
      <Badge
        label={`${fixedFloatAndCommas((data?.pointScoredSum / data?.pointsAllocatedSum) * 100, 1) || 0}%`}
        className={trackCompliance((data?.pointScoredSum / data?.pointsAllocatedSum) * 100)}
      />
    </td>
  );

  return table;
};
const TableHeader = ({ labelConfig }) => (
  <thead>
    <tr>{labelConfig.map(label => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const TableBody = ({ data, labelMappings, labelConfig, bgColor, baseBgColor, title }) => {
  const tableBody = returnTableBody(data, labelMappings, bgColor, baseBgColor, title);

  return <tr key={data.distributorId}>{labelConfig.map(label => (label.show ? tableBody[label.label] : null))}</tr>;
};

const TableFooter = (tableDatas, SOS) => {
  const totalPointAllocatedSum = ArrayOfObjSum(tableDatas, 'pointsAllocatedSum');
  const totalPointScoredSum = ArrayOfObjSum(tableDatas, 'pointScoredSum');
  const totalBonusAwardedSum = ArrayOfObjSum(tableDatas, 'bonusAwarded');

  return (
    <tr className="table-footer">
      <td className="text-styling">Total</td>
      {SOS && <td className="text-styling"></td>}
      <td className="text-styling text-right">{fixedFloatAndCommas(totalPointAllocatedSum, 1)}</td>
      <td className="text-styling text-right">{fixedFloatAndCommas(totalPointScoredSum, 1)}</td>
      <td className="text-styling text-right">
        {fixedFloatAndCommas(totalPointAllocatedSum - totalPointScoredSum, 1)}
      </td>
      <td className="text-styling text-right">{fixedFloatAndCommas(totalBonusAwardedSum, 1)}</td>
      <td className="text-styling text-middle" style={{ paddingRight: '48px' }}></td>
    </tr>
  );
};

export { TableHeader, TableBody, TableFooter };
