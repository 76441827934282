import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import InvoicedDetailChildView from './childView';
import { TableBody } from '../tableConfig';
import AccordionTable from '../../../../../components/CollapsibleTable/AccordionTable';

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Array),
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  onRowClick: PropTypes.func.isRequired,
  selectedOrders: PropTypes.instanceOf(Array),
  onOrderStatusChangeClick: PropTypes.func.isRequired,
  checkBoxStatus: PropTypes.func,
  onIconClick: PropTypes.func,
  onPrimaryCheckBoxClick: PropTypes.func,
  onSecondaryCheckBoxClick: PropTypes.func,
};

const defaultProps = {
  data: [],
  activeLine: {
    id: '',
    sellerId: 0,
  },
  loading: false,
  selectedOrders: [],
  checkBoxStatus: () => null,
  onIconClick: () => null,
  onPrimaryCheckBoxClick: () => null,
  onSecondaryCheckBoxClick: () => null,
};

const InvoicedDetailView = ({ ...props }) => {
  const {
    data,
    loading,
    onRowClick,
    activeLine,
    onOrderStatusChangeClick,
    selectedOrders,
    onIconClick,
    checkBoxStatus,
    onPrimaryCheckBoxClick,
    onSecondaryCheckBoxClick,
  } = props;

  return (
    <tbody>
      {
        <AccordionTable>
          {data.map((invoiced) => (
            <tr
              key={invoiced.id}
              trigger={TableBody({
                data: invoiced,
                onRowClick,
              })}
            >
              {invoiced.orderIds.length > 0 ? (
                InvoicedDetailChildView({
                  data: invoiced.Orders || [],
                  checkBoxStatus,
                  onPrimaryCheckBoxClick,
                  onSecondaryCheckBoxClick,
                  onOrderStatusChangeClick,
                  selectedOrders,
                  activeLine,
                  amountDetails: invoiced.amountDetails || {},
                  onIconClick,
                  loading,
                  billDiscountDetail: invoiced.billDiscountDetail || [],
                  logisticsDelivery: invoiced.Distributor.servicesUsed.logistic.status,
                })
              ) : (
                <Fragment />
              )}
            </tr>
          ))}
        </AccordionTable>
      }
    </tbody>
  );
};

InvoicedDetailView.propTypes = propTypes;

InvoicedDetailView.defaultProps = defaultProps;

export default InvoicedDetailView;
