import React from 'react';
import PropTypes from 'prop-types';
import PanelCard from '../../../../../components/Cards/PanelCard';
import { Row, Col } from '../../../../../components';
import { normalPresentor } from '../../../../../utils/date';
import PurchaseOrderStyled from '../common/PuchaseOrderStyle';
import { TableHeaderConfig, TableBody } from '../common/tableConfig';
import withLoading from '../../../../../utils/composition/withLoading';
import ViewType from '../../../../components/ViewType';

const propTypes = {
  id: PropTypes.number,
  viewType: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  viewTypeConfig: PropTypes.instanceOf(Object).isRequired,
  handleViewTypeChange: PropTypes.func.isRequired,
};

const defaultProps = {
  id: null,
  viewType: '',
  data: {},
};

const View = (props) => {
  const {
    id, data, viewType, viewTypeConfig, handleViewTypeChange,
  } = props;
  let sn = 0;
  return (
    <PurchaseOrderStyled>
      <div>
        <PanelCard
          cardTitle="details"
          className="disabled"
        >
          <Row>
            <Col md={2}>
              <div>
                <label>POID</label>
                <div className="config-panel-value">{data.details.id}</div>
              </div>
            </Col>
            <Col md={2}>
              <div>
                <label>Date</label>
                <div className="config-panel-value">{normalPresentor(data.details.purchaseOrderDate)}</div>
              </div>
            </Col>
            <Col md={2}>
              <div>
                <label>Channel</label>
                <div className="config-panel-value">{data.details.channel}</div>
              </div>
            </Col>
          </Row>
        </PanelCard>
      </div>
      <div className="sku-section">
        <div className="sku-header">
          <span>SKU</span>
          <div className="group-by">
            <span>Group By:</span>
            <ViewType
              handleDropDownChange={handleViewTypeChange}
              list={viewTypeConfig.list}
              value={viewTypeConfig.value}
              displayLabel={false}
            />
          </div>
        </div>
        <div className="upload-section disabled">
          <div className="table-wrap">
            <table>
              <TableHeaderConfig
                viewType={viewType}
              />
              <tbody>
                {data.list.map((sku, index) => (
                  <>
                    <tr key={`${index}-sku`}>
                      <td>{sku.group}</td>
                      <td colSpan="4" />
                    </tr>
                    { sku.skuList.map((list) => {
                      sn += 1;
                      return (
                        TableBody({
                          sn,
                          data: list,
                          viewType,
                          id,
                        })
                      );
                    })}
                </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </PurchaseOrderStyled>
  );
};

View.propTypes = propTypes;
View.defaultProps = defaultProps;

const ViewWithErrorAndLoading = withLoading(View);
export default ViewWithErrorAndLoading;
