import React from 'react';
import { PropTypes } from 'prop-types';
import RouteView from './RouteView';
import LeaveView from './LeaveView';
import { getWeekDay, checkIfTodayOrGreater } from '../date';
import { DialogContext } from '../config';
import { Button } from '../../../../v4/components';
import { CALENDAR_PREVIEW } from '../../../../data/enums';

const propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  date: PropTypes.string.isRequired,
  activeMonth: PropTypes.number,
  weekEndList: PropTypes.instanceOf(Array),
  event: PropTypes.shape({
    routeList: PropTypes.instanceOf(Array),
    leave: PropTypes.shape({
      status: PropTypes.string,
    }),
    holiday: PropTypes.instanceOf(Array),
  }),
};

const defaultProps = {
  event: {
    routeList: [],
    leave: {
      status: 'PENDING',
    },
    holiday: [],
  },
  activeMonth: 1,
  weekEndList: [],
};

const classNameImporter = (date, holiday, weekEndList = [], userId, { displayDialog, activeDate, activeUser }) => {
  const weekDay = getWeekDay(date);

  const holidayBackground = holiday ? 'holiday' : '';

  const weekEndBackground = weekEndList.includes(weekDay) ? 'weekend' : '';

  const activeBorder = date === activeDate && displayDialog && userId === activeUser ? 'active-border' : '';

  return `calendar-day ${holidayBackground} ${weekEndBackground} ${activeBorder}`;
};

const getButtonClassName = (date, userId, { displayDialog, activeDate, activeUser }) => {
  const active = date === activeDate && displayDialog && userId === activeUser;

  return active ? 'active-button' : '';
};

const displayAddButton = (date) => checkIfTodayOrGreater(date);

const displayRosterComponent = (date, userId, contextData) =>
  contextData.activeDate === date && contextData.activeUser === userId;

function WeeklyUnit({
  user,
  date,
  weekEndList,
  event = {
    routeList: [],
    leave: null,
    holiday: null,
  },
  routeList
}) {
  return (
    <DialogContext.Consumer>
      {(value) => (
        <div
          className={classNameImporter(date, event.holiday, weekEndList, user.id, {
            activeDate: value.activeDate,
            displayDialog: value.displayDialog,
            activeUser: value.activeUser,
          })}
        >
          <RouteView
            name={CALENDAR_PREVIEW.WEEKLY}
            date={date}
            routeList={event.routeList || []}
            user={user}
            removeRosterLine={value.removeRosterLine}
            onEditIconClick={value.handleRosterLineIconClick}
          />
          {event.leave ? <LeaveView leaveInfo={event.leave} /> : null}
          {displayAddButton(date) ? (
            <Button
              primary
              className={getButtonClassName(date, user.id, {
                activeDate: value.activeDate,
                displayDialog: value.displayDialog,
                activeUser: value.activeUser,
              })}
              iconName='plus'
              onClick={() =>
                value.handleRosterComponentClick({
                  date,
                  userId: user.id,
                  userTitle: user.title,
                  selectedRouteList: event.routeList || [],
                })
              }
            />
          ) : null}
          {displayRosterComponent(date, user.id, value) ? value.rosterComponent : null}
        </div>
      )}
    </DialogContext.Consumer>
  );
}

WeeklyUnit.propTypes = propTypes;

WeeklyUnit.defaultProps = defaultProps;

export { WeeklyUnit };

export default WeeklyUnit;
