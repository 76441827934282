import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withErrorAndLoading from '../../../../../utils/composition/withLoading';
import {
  CustomSelect, Input
} from '../../../../../components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { RouteContext } from '../../config';
import { townIdIncludedInUser } from './config';
import FormContentStyled from '../../../../../components/global-css/FormContentStyled';
import RouteDetailInfo from './RouteDetailInfo';
import { Switch, Button, CheckBox } from '../../../../../v4/components';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    VisitType: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    DeliveryType: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    VisitFrequency: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    VisitCategory: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    Town: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  }).isRequired,
  menu: PropTypes.shape({
    townList: PropTypes.instanceOf(Array),
  }).isRequired,
  enableErrorDisplay: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  refsObj: PropTypes.shape({
    routeName: PropTypes.string,
    channel: PropTypes.string,
    category: PropTypes.string,
    contactName: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
  crudMode: PropTypes.string.isRequired,
  handleButtonSubmit: PropTypes.func.isRequired,
  handleButtonCancel: PropTypes.func.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  handleEditIconClick: PropTypes.func,
  enableRouteSegmentation: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  lines: PropTypes.instanceOf(Array),
  linesData: PropTypes.instanceOf(Array),
};

const defaultProps = {
  enableErrorDisplay: false,
  handleEditIconClick: () => null,
  lines: [],
  linesData: [],
  loading: false,
};

const View = ({ ...props }) => {
  const {
    menu,
    data,
    refsObj,
    loading,
    crudMode,
    onInputChange,
    onDropDownChange,
    enableErrorDisplay,
    handleButtonSubmit,
    handleButtonCancel,
    handleEditIconClick,
    enableRouteSegmentation,
    lines,
    onCheckboxClick,
    linesData,
  } = props;

  const showEditButton = crudMode !== EVENT_OPERATION.CREATE;

  const disableEditButton = crudMode === EVENT_OPERATION.UPDATE || data.routeLock;

  const hideEditButton = crudMode !== EVENT_OPERATION.UPDATE;

  const filteredDSEList = menu.dseList.filter((user) => townIdIncludedInUser(data.townId, user.Town));

  const checkedLineSegmentation = data.enableLineSegmentation;

  return (
    <RouteContext.Consumer>
      {({ permission }) => (
        <>
          {crudMode !== EVENT_OPERATION.READ && (
            <div className='button-wrap'>
              <Button small secondary title='Cancel' disabled={loading} onClick={() => handleButtonCancel()} />
              <Button small primary title='Save' disabled={loading} onClick={() => handleButtonSubmit()} />
            </div>
          )}
          <div className='route-btn-wrap '>
            {hideEditButton
              ? showEditButton &&
              permission &&
              permission.update && (
                <div className='text-right ml-16'>
                  <Button
                    secondary
                    iconBtnSmall
                    iconName='pencil'
                    disabled={disableEditButton}
                    onClick={() => handleEditIconClick()}
                  />
                </div>
              )
              : ''}
          </div>
          <div className='create-ui '>
            <div className={`create-ui-inner tab-less-height ${crudMode === EVENT_OPERATION.READ ? 'disabled' : ''}`}>
              <FormContentStyled>
                <h3 className=''>Route Info</h3>
                <div>
                  <div className='form-input-wrap'>
                    {data.id && (
                      <Input
                        name='id'
                        type='number'
                        disabled
                        value={data.id.toString()}
                        labelContent='ID'
                        enableErrorDisplay={false}
                        onChange={(event) => onInputChange(event, 'id', ['id'])}
                      />
                    )}

                    <Input
                      name='title'
                      type='text'
                      enableValidation
                      value={data.title}
                      labelContent='Route Name'
                      placeholder='Route Name'
                      ref={(ref) => (refsObj.title = ref)}
                      enableErrorDisplay={enableErrorDisplay}
                      onChange={(event) => onInputChange(event, 'title', ['title'])}
                    />
                    <CustomSelect
                      enableValidation
                      labelContent='Town'
                      className='custom-select'
                      options={menu.townList}
                      placeholder='Select Town'
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ title }) => title}
                      enableErrorDisplay={enableErrorDisplay}
                      onChange={(event) => onDropDownChange(event.id, ['townId'], data.townId)}
                      value={menu.townList.filter(({ id }) => id === data.townId)}
                      ref={(ref) => (refsObj.town = ref)}
                    />
                    {enableRouteSegmentation && (
                      <>
                        <Switch
                          checked={checkedLineSegmentation}
                          labelContent='Route Segmentation'
                          onToggle={(checked) => {
                            onInputChange(
                              {
                                formattedValue: !!checked,
                                target: {
                                  name: 'active',
                                  type: 'boolean',
                                  value: !!checked,
                                },
                              },
                              'enableLineSegmentation',
                              ['enableLineSegmentation'],
                            );
                          }}
                        />
                        {checkedLineSegmentation
                          ? lines.map(({ id, title }) => (
                            <CheckBox
                              className='line-checkbox'
                              key={id}
                              labelText={title}
                              name={title}
                              onCheckBoxClick={(e) => {
                                onCheckboxClick(e, { id, title });
                              }}
                              checked={
                                linesData.map((line) => line.id).includes(id) ||
                                data.assignedLines.map((assignedLine) => assignedLine.lineId).includes(id)
                              }
                            />
                          ))
                          : ''}
                      </>
                    )}
                  </div>
                </div>
                {!checkedLineSegmentation ? (
                  <RouteDetailInfo
                    refsObj={refsObj}
                    filteredDSEList={filteredDSEList}
                    menu={menu}
                    data={data}
                    onDropDownChange={onDropDownChange}
                    enableErrorDisplay={enableErrorDisplay}
                    className='no-line-segmented'
                  />
                ) : (
                  ''
                )}
              </FormContentStyled>
              <FormContentStyled>
                {checkedLineSegmentation &&
                  linesData.length > 0 &&
                  linesData.map((line, index) => (
                    <Fragment key={line.id}>
                      <h3 className='inner-h3-header'>{line.title}</h3>
                      <RouteDetailInfo
                        refsObj={refsObj}
                        filteredDSEList={filteredDSEList}
                        menu={menu}
                        data={data}
                        onDropDownChange={onDropDownChange}
                        enableErrorDisplay={enableErrorDisplay}
                        index={index}
                        className='line-segmented'
                      />
                    </Fragment>
                  ))}
              </FormContentStyled>
            </div>
          </div>
        </>
      )}
    </RouteContext.Consumer>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const GeneralInfo = withErrorAndLoading(View);

export default GeneralInfo;
