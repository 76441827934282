import React from 'react';
import PropTypes from 'prop-types';
import DispatchedDetailChildView from './childView';
import { TableBody } from '../tableConfig';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import AccordionTable from '../../../../../components/CollapsibleTable/AccordionTable';

const propTypes = {
  loading: PropTypes.bool,
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  data: PropTypes.instanceOf(Array),
  handleSliderOpen: PropTypes.func,
  onRowClick: PropTypes.func.isRequired,
  selectedOrders: PropTypes.instanceOf(Array),
  onOrderStatusChangeClick: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
};

const defaultProps = {
  data: [],
  activeLine: {
    id: '',
    sellerId: 0,
  },
  selectedOrders: [],
  loading: false,
  handleSliderOpen: () => null,
};

const DispatchedDetailView = ({ ...props }) => {
  const { data, loading, onRowClick, activeLine, onIconClick, handleSliderOpen, onOrderStatusChangeClick } = props;
  return (
    <tbody>
      {
        <AccordionTable>
          {data.map((dispatched) => (
            <tr
              key={dispatched.id}
              trigger={TableBody({
                data: dispatched,
                onRowClick,
              })}
            >
              {dispatched.orderIds.length > 0
                ? DispatchedDetailChildView({
                  data: dispatched.Orders || [],
                  orderDetails: {
                    dispatchNumber: dispatched.dispatchNumber,
                    invoiceId: dispatched.invoiceId,
                    orderType: dispatched.orderType,
                  },
                  onOrderStatusChangeClick,
                  activeLine,
                  amountDetails: dispatched.amountDetails || {},
                  onIconClick,
                  loading,
                  handleSliderOpen,
                  billDiscountDetail: dispatched.billDiscountDetail || [],
                  logisticsDelivery: dispatched.Distributor.servicesUsed.logistic.status,
                })
                : null}
            </tr>
          ))}
        </AccordionTable>
      }
    </tbody>
  );
};

DispatchedDetailView.propTypes = propTypes;

DispatchedDetailView.defaultProps = defaultProps;

const DispatchedDetailViewWithErrorAndLoading = withErrorAndLoadingScreen(DispatchedDetailView);

export default DispatchedDetailViewWithErrorAndLoading;
