
import { MT_BU, mt } from '../../../../../../../../data/enums/Route';
import { getCurrentDay } from '../../../../../../../../utils/date';

const title = 'VGP';
const errorMessageSKU = 'Atleast one SKU should be selected.';
const breadCrumb = [mt,
  {
    id: 1,
    title: 'Rscore',
    path: '#',
    active: true,
  },
  {
    id: 2,
    title: 'BU',
    path: `/${MT_BU}`,
    active: true,
  }];

const formMapper = (id, element = {}) => ({
  question: element.question || '',
  channel_id: element.channel_id || null,
  sbdcategory: element.sbdcategory|| element?.categoryIds?.map((a)=>a.id) || null,
  principal_id: id || 0,
  audit_type: element.audit_type || null,
  start_date: element.start_date || element?.start || getCurrentDay(),
  end_date: element.end_date || element?.end ||getCurrentDay(),
});

const getPayload = (data, pid) => {
  const { audit_type, principal_id, sbdcategory, channel_id, end_date, start_date, question } = data.basicData;
  return ({
    active: true,
    audit_type: audit_type || null,
    brandIds: data.brandIds,
    categoryIds: sbdcategory || null,
    channel_id: channel_id || null,
    principal_id: principal_id || pid || null,
    question: question || null,
    start: start_date || getCurrentDay(),
    end: end_date || getCurrentDay(),
  });
};

export {
  formMapper, breadCrumb as breadCrumbConfig, getPayload, title, errorMessageSKU,
};
