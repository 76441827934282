import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const REPLENISHMENT_FRAGMENT = gql`
  fragment ReplenishmentFragment on StockGroupDetail {
    replenishmentConfig {
      stockDaysHolding {
        powerSKU
        nonPowerSKU
      }
      availabilityDays
      averageDays
      sufficiencyMatrix {
        under
        ideal
        over
      }
      sufficiencyDays {
        max
        min
      }
      sufficiencyWeightage {
        availability
        sufficiency
        overStock
      }
    }
  }
`;

const GET_REPLENISHMENT_GROUP_DETAIL = gql`
  query getReplenishmentGroupDetail($id: Int!) {
    getReplenishmentGroupDetail(id: $id) {
      ...ReplenishmentFragment
      replenishmentFrequency {
        day
        date
        time
        type
        dayOfTheMonth
      }
    }
  }
  ${REPLENISHMENT_FRAGMENT}
`;

const UPDATE_REPLINESHMENT_GROUP = graphql(
  gql`
    mutation ($id: Int!, $input: ReplenishmentConfigInput!,$frequencyInput:ReplenishmentFrequencyInput!) {
      updateReplenishmentModel(id: $id, input: $input,frequencyInput:$frequencyInput) {
        replenishmentConfig {
          stockDaysHolding {
            powerSKU
            nonPowerSKU
          }
          availabilityDays
          averageDays
          sufficiencyMatrix {
            under
            ideal
            over
          }
          sufficiencyDays {
            max
            min
          }
          sufficiencyWeightage {
            availability
            sufficiency
            overStock
          }
        }
        replenishmentFrequency {
          day
          date
          time
          type
          dayOfTheMonth
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateReplenishmentModel: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getReplenishmentGroupDetail: GET_REPLENISHMENT_GROUP_DETAIL,
};
const mutation = {
  updateReplenishmentModel: UPDATE_REPLINESHMENT_GROUP,
};

export {
  query, mutation, UPDATE_REPLINESHMENT_GROUP
};
