import gql from 'graphql-tag';

const GET_PURCHASE_ORDER_DETAIL = gql`
  query getPurchaseOrderDetails($id: Int!){
    getMTPurchaseOrderDetail(
      id: $id
      ){
        id
        purchaseOrderDate
        Channel{
          id
          title
        }
        Order{
          Brand{
            id
            title
          }
          poId
          SKU{
            title
            
          }
          quantity
          quantityInCases
          rateDetails{
            rlp
            rlpWithVat
          }
          amountDetails{
            grossAmount
          }
        }
      }
  }
`;

const query = {
  getPurchaseOrderDetails: GET_PURCHASE_ORDER_DETAIL,
};
export { query };
