import { compose } from 'recompose';
import PurchaseOrder from './PurchaseOrder';
import { query } from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedPurchaseOrder = compose(
  withRequestTracker({
    query,
  }),
)(PurchaseOrder);

export default ComposedPurchaseOrder;
