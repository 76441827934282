import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import OutletChainView from './View';
import { DialogWrapper } from '../../common';
import { refGenerator } from '../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import {
  crudRequest as crudRequestConfig, breadCrumbConfig, formConfig, title,
} from './config';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import * as queryService from '../../base/query.service';
import { getPermissionForMerchandisingDevices } from '../../base/permission';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import OutletChainStyled from './OutletChainStyled';
import history from '../../../utils/history';
import { OUTLET_CHAIN_CREATE,OUTLET_CHAIN_DETAILS} from '../../../data/enums/Route';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';
import { imageSliderListFormatter } from '../../common/HelperFunctions';

const propTypes = {
  getOutletChainList: PropTypes.func.isRequired,
  createOutletChain: PropTypes.func.isRequired,
  updateOutletChain: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class OutletChain extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      showDialog: false,
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        // date: { ... queryService.baseQueryParameters.date },
      },
      menu: {
        categoryList: [],
        brandList: [],
      },
    };
    this.permission = getPermissionForMerchandisingDevices();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    const serverCall = {
      [EVENT_OPERATION.DELETE]: props.toggleStateOutletChain,
    };

    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      this.setState({data:data,showDialog:false});
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      this.loadTableData();
    },  this.getData);

    this.onFormSubmit = (operationType, data) => {
      handleFormSubmit(
        this.onCRUDSuccess,
        this.onAPIRequestFailure,
        crudRequestConfig,
        serverCall,
      )(operationType, data);
    };

    this.onDialogShowHide = (show) => {
      this.setState({showDialog:show});
    }

    this.formReference = refGenerator(formConfig.validationField);
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const {queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        date,
        search,
      },
      DOMAIN.POSMs
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.POSMs);
    const newFilterConfig = {
      ...localData || {
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
      },
      () => {
        this.loadTableData();
      },
    );
  };


  loadTableData = () => {
    const { data } = this.state;
    const { getOutletChainList } = this.props;
    this.setFilterStateInStorage();
    getOutletChainList(
      {
        "searchText":null
      },
      {
        handleSuccess: response => {
          const filteredList =response?.data?.getOutletChain?.filter((item)=>item?.active);
          data.total = (filteredList?.length) || 0;
          data.list = filteredList.map(outlet => {
            if (outlet.image_url) {
              outlet.image_url = [
                ...(imageSliderListFormatter( outlet.image_url , '', {
                  w: 2400,
                  h: 1100,
                }) || []),
              ];
            }
            return outlet;
          });
          // if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };


  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.setState({showDialog:true});
  };

  onIconClick = type => {
    history.push(`/${OUTLET_CHAIN_CREATE}`);
  };
  onTableRowClick = (type, id) => {
    history.push(`/${OUTLET_CHAIN_DETAILS}/${id}`);
  };
  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  render() {
    const {
      data, queryParameters, menu, display,showDialog
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <OutletChainStyled>
        <div className="merchandising-config-panel">
          <DialogWrapper
            onDialogSubmit={this.onFormSubmit}
            onDialogShowHide={this.onDialogShowHide}
            title=" Outlet Chain"
            controlFromParent= {true}
            showDialog={showDialog}
            formConfig={formConfig}
            refsObj={this.formReference}
            render={({ onDialogItemClick }) => (
              <Fragment>
                <div className="section-header">
                  <PanelStyled>
                    <PageHeader
                      display={display}
                      breadCrumb={breadCrumbConfig}
                      config={{
                        title,
                        create: this.permission.create,
                        download: false,
                        filter: false,
                        search: false,
                      }}
                      queryParameters={queryParameters}
                      controlDisplay={this.controlDisplay}
                      handleCreateClick={this.onIconClick}
                      resetFilter={this.basePaginationService.resetFilter}
                      clearSearchText={this.basePaginationService.clearSearchText}
                      handleSearchChange={this.basePaginationService.handleSearchInputChange}
                      handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                      handleDownloadClick={this.basePaginationService.handleDownloadClick}
                    />
                  </PanelStyled>
                </div>
                <div className="section-content table-present">
                  <OutletChainView
                    data={data}
                    pagination={queryParameters.pagination}
                    loading={serverResponseWaiting}
                    onPageChange={this.basePaginationService.onPageSelect}
                    onBulkDelete={this.handleBulkDelete}
                    permission={this.permission}
                    onIconClick={onDialogItemClick}
                    onTableRowClick={this.onTableRowClick}
                  />
                </div>
              </Fragment>
            )}
          />
        </div>
      </OutletChainStyled>
    );
  }
}

OutletChain.propTypes = propTypes;

OutletChain.defaultProps = defaultProps;

export default withAlert()(OutletChain);
