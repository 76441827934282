import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { TableBody, TableHeader } from './PrintTableConfig';
import withLoading from '../../../../../../utils/composition/withLoading';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';
import { normalPresentor, getCurrentDay } from '../../../../../../utils/date';
import numberToWords from '../../../../../../utils/numberToTextConverter';


const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    emails: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  printButtonClicked: PropTypes.bool,
  dataPrint: PropTypes.shape({
    list: PropTypes.array,
  }),
};

const defaultProps = {
  handleInputChange: () => null,
  enableErrorDisplay: true,
  printButtonClicked: false,
  data: {
    id: null,
    email: '',
  },
  refsObj: {},
  dataPrint: {
    list: [],
  },
};

const PrintBody = ({ ...props }) => {
  const {
    printButtonClicked, dataPrint, purchaseRequisitionData, dataList,
  } = props;
  return (
    <div className={printButtonClicked ? 'display-block portrait visibility-shown-print' : 'no-print'}>
      {dataList && dataList.map((ceItem, key) => (
        <div className="print-body print">
          <div className="purchase-info">
            <Row>
              <Col md={4} xs={4} sm={4}>
                <div className="left">
                  <p className="pr-id">
                    PRID:
                    <span className="font-bold">{purchaseRequisitionData.details.id}</span>
                  </p>
                  <p className="pr-date">
                PR Date:
                    <span className="font-bold">{normalPresentor(purchaseRequisitionData.details.date)}</span>
                  </p>
                </div>
              </Col>
              <Col md={4} xs={4} sm={4}>
                <div className="center">
                  <h2>{purchaseRequisitionData.details.channel}</h2>
                  <p>Purchase Order</p>
                </div>
              </Col>
              <Col md={4} xs={4} sm={4}>
                <div className="right">
                  <p>
                  Date:
                    {' '}
                    <span className="font-bold">
                      {}
                      {getCurrentDay()}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="table-wrap">
            <table>
              {
            TableHeader()
          }
              <tbody>
                {
                ceItem.list.map(sku => (
                  TableBody({ data: sku })
                ))}
                {ceItem.remainingLines.length ? ceItem.remainingLines.map(i => (
                  <tr className="blank-tr">
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                )) : null}
              </tbody>
              <tfoot className="foot-three">
                <tr>
                  <td colSpan="3" className="text-right ">In Words</td>
                  <td className="text-right font-bold inword" colSpan="5">
                    {numberToWords((dataPrint.details.taxableAmount + dataPrint.details.vat) || 0)}
                  </td>
                </tr>
              </tfoot>
              <tfoot className="foot_one">
                <tr>
                  <td colSpan="2" className="text-right ">Total Quantity</td>
                  <td className="text-right font-bold">{ dataPrint.details.totalCases}</td>
                  <td className="text-right font-bold">{dataPrint.details.totalPcs}</td>
                  <td colSpan="3" className="text-right ">Sub Total</td>
                  <td className="text-right font-bold">{fixedFloatAndCommas(dataPrint.details.subTotal)}</td>
                </tr>
                <tr>
                  <td colSpan="7" className="text-right ">Bill Discount</td>
                  <td className="text-right font-bold">{ fixedFloatAndCommas(dataPrint.details.billDiscount)}</td>
                </tr>
                <tr>
                  <td colSpan="7" className="text-right ">Trade Discount</td>
                  <td className="text-right font-bold">{ fixedFloatAndCommas(dataPrint.details.tradeDiscount)}</td>
                </tr>
                <tr>
                  <td colSpan="7" className="text-right ">Taxable Amount</td>
                  <td className="text-right font-bold">{ fixedFloatAndCommas(dataPrint.details.taxableAmount)}</td>
                </tr>
                <tr>
                  <td colSpan="7" className="text-right ">VAT (13%)</td>
                  <td className="text-right font-bold">{ fixedFloatAndCommas(dataPrint.details.vat)}</td>
                </tr>
              </tfoot>
              <tfoot className="foot_two">
                <tr>
                  <td colSpan="7" className="text-right font-bold">Total</td>
                  <td className="text-right font-bold">{ fixedFloatAndCommas(dataPrint.details.taxableAmount + dataPrint.details.vat)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};

PrintBody.propTypes = propTypes;
PrintBody.defaultProps = defaultProps;

const ViewWithErrorAndLoading = withLoading(PrintBody);

export default ViewWithErrorAndLoading;
