import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import OutletView from './View';
import { DialogWrapper } from '../../../common';
import PageHeader from '../../../base/PageHeader';
import { CSVUploadForm as UploadForm } from '../../../../v4/components';
import { fetchFromLocalStorage, isError, storeInLocalStorage } from '../../../common/HelperFunctions';
import { PanelStyled } from '../../../common/configuration';
import * as queryService from '../../../base/query.service';
import { DOMAIN, FORM_CONFIG } from '../../../../data/enums/config';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { OUTLET_CREATE, OUTLET_DETAILS } from '../../../../data/enums/Route';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import OutletWrapStyled from './OutletWrapStyled';
import { getPermissionForOutlet, getPermissionToCreateOutlet } from '../../../base/permission';
import { formConfig, filterConfig, breadCrumbConfig, crudRequestConfig, menuConfigList } from './config';
import { crudResponseProcessor } from '../../../../utils/crudResponseProcessor';
import { PROGRAM } from '../../../../data/enums/Permission';
import { clone } from '../../../../utils/objectProcessor';
import { Icon } from '../../../../v4/components';
import { storageKeys } from '../../orderProcessing/config';

const TYPE_TO_DOWNLOAD_URL = {
  [EVENT_OPERATION.UPLOAD]: 'https://rosia-sample-files.s3.ap-south-1.amazonaws.com/sampleOutletMT.csv',
  [EVENT_OPERATION.UPLOADED]: 'https://rosia-sample-files.s3.ap-south-1.amazonaws.com/SampleTownCategoryChange.csv',
  [EVENT_OPERATION.DEBOARD_OUTLET]:'https://rosia-sample-files.s3.ap-south-1.amazonaws.com/deboard_outlet_sample.csv',
};

const propTypes = {
  getOutletList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired,
  downloadReport: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class Outlet extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        // date: { ... queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
    };

    this.enableOutletCreation = getPermissionToCreateOutlet();
    this.permission = getPermissionForOutlet();

    const { downloadReport, displayAlert } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;

    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.RETAIL_OUTLET
    );
  };

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.RETAIL_OUTLET);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };


  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  /**
   * @param crudType: CREATE, READ, UPDATE, DELETE
   * @param data
   * @returns {Promise<void>}
   */
  handleFormSubmit = async (crudType, data) => {
    const { upload, toggleState } = this.props;

    // server call management.
    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: upload,
      [EVENT_OPERATION.DELETE]: toggleState,
      [EVENT_OPERATION.UPLOADED]: upload,
      [EVENT_OPERATION.DEBOARD_OUTLET]: upload,
    };

    // generate data management
    const serverRequestData = await crudRequestConfig[EVENT_OPERATION[crudType]].objectMapper(data);

    // generate success Message
    const successMessage = crudRequestConfig[EVENT_OPERATION[crudType]].message;

    serverCall[EVENT_OPERATION[crudType]](serverRequestData, {
      handleSuccess: () => {
        // considered only upload case for data.
        const { displayAlert } = this.props;
        displayAlert(ALERT_TYPE.SUCCESS, successMessage);
        this.loadTableData();
      },
      handleError: err => {
        this.onAPIRequestFailure(err);
      },
    });
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleAddButtonClick = () => {
    const { history } = this.props;

    history.push(`/${OUTLET_CREATE}`);
  };

  handleTableRowClick = (type, id) => {
    const { history } = this.props;
    history.push(`/${OUTLET_DETAILS}/${id}`);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getOutletList, displayAlert } = this.props;

    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getOutletList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [...apiFilterProcessor(queryParameters.filter)],
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.retailOutlets && response.data.retailOutlets.rows) || [];
          data.total = (response.data.retailOutlets && response.data.retailOutlets.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleBulkDelete = (type, response) => {
    const { displayAlert } = this.props;
    const { data } = this.state;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    crudResponseProcessor(
      modifiedData => {
        this.setState({ data: modifiedData });
      },
      () => data,
    )(type)(response);
  };

  render() {
    const { data, queryParameters, display, filterMenu } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <OutletWrapStyled>
        <DialogWrapper
          header="Add Outlet"
          fileConfig={formConfig}
          refsObj={formConfig.ref}
          fileUploadRefs={formConfig[FORM_CONFIG.REFS_OBJ]}
          onDialogSubmit={this.handleFormSubmit}
          renderDialog={({ handleFileUploadChange, dialogData, enableErrorDisplay, fileUploadRefs, type }) => (
            <Fragment>
              <UploadForm
                show
                refsObj={fileUploadRefs}
                data={dialogData}
                loading={serverResponseWaiting}
                handleFileUpload={handleFileUploadChange}
                enableErrorDisplay={enableErrorDisplay}
              />
              <a href={TYPE_TO_DOWNLOAD_URL[type]}>
                <span className="sample-csv">
                  <Icon iconName="download" />
                  Sample CSV
                </span>
              </a>
            </Fragment>
          )}
          render={({ onDialogItemClick }) => (
            <Fragment>
              <div className="section-header">
                <PanelStyled>
                  <PageHeader
                    config={{
                      title: 'Outlet',
                      csvUpload: this.permission.create,
                      create: this.permission.create,
                      date: false,
                      filter: true,
                      search: true,
                      download: true,
                    }}
                    downloadConfig={{
                      domain: PROGRAM.OUTLET,
                    }}
                    filter={{
                      date: queryParameters.date,
                      menuList: filterMenu,
                      onFilterChange: this.basePaginationService.handleFilterChange,
                    }}
                    breadCrumb={breadCrumbConfig}
                    display={display}
                    queryParameters={queryParameters}
                    handleUploadClick={onDialogItemClick}
                    handleCreateClick={this.handleAddButtonClick}
                    resetFilter={this.basePaginationService.resetFilter}
                    clearSearchText={this.basePaginationService.clearSearchText}
                    handleSearchChange={this.basePaginationService.handleSearchInputChange}
                    handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                    handleDownloadClick={this.basePaginationService.handleDownloadClick}
                    controlDisplay={this.controlDisplay}
                    menuConfigList={menuConfigList}
                  />
                </PanelStyled>
              </div>
              <div className="section-content table-present">
                <OutletView
                  data={data}
                  loading={serverResponseWaiting}
                  onIconClick={onDialogItemClick}
                  permission={this.permission}
                  pagination={queryParameters.pagination}
                  onTableRowClick={this.handleTableRowClick}
                  onPageChange={this.basePaginationService.onPageSelect}
                  onBulkDelete={this.handleBulkDelete}
                />
              </div>
            </Fragment>
          )}
        />
      </OutletWrapStyled>
    );
  }
}

Outlet.propTypes = propTypes;

Outlet.defaultProps = defaultProps;

export { Outlet };

export default withAlert()(Outlet);
