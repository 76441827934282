import { refGenerator } from '../../../utils'
import { EVENT_OPERATION } from '../../../v4/constants/EventOperation'
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';
import { FORM_CONFIG } from '../../../v4/constants/formConfig';
import { salesForce as salesForceRoute } from '../../../data/enums/Route';

const breadCrumb = [salesForceRoute];
const Title = 'Distributor Sales Target';
const requiredList = ['distributorId', 'month', 'target'];
const filter = getFilterConfig([FILTER.SUB_D]);

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Create Target',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Edit Target',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.TITLE]: 'View Target',
  },
}

export {
  breadCrumb as breadCrumbConfig,
  formConfig,
  Title as title,
  filter as filterConfig,
};
