const sosColor = ['#a2cefa', '#fac6a7', '#c3e6cf'];
const soaColor = ['#00378f', '#612d0e', '#1a4d36'];
const sodColor = ['#0e69ff', '#f27024', '#43c187'];

const dimension = {
  height: 380,
  width: 1300,
};

const brandColor = {
  bbsm: '#0e69ff',
  bigmart: '#f27024',
  saleways: '#43c187',
};

const SOS = 'Share of Shelf';
const SOD = 'Share of Display';
const OSA = 'On Shelf Availability';

export { sosColor, soaColor, sodColor, dimension, brandColor, SOS, SOD, OSA };
