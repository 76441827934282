import { compose } from 'recompose';
import Create from './Create';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import {
  mutation, CREATE_PURCHASE_ORDER,
} from './API';

const ComposedCreate = compose(
  CREATE_PURCHASE_ORDER,
  withRequestTracker({
    mutation,
  }),
)(Create);

export default ComposedCreate;
