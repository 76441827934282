import React, { useEffect, useState } from 'react';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { OutletStyled } from './OutletStyled';
import {
  breadCrumbConfig, title, formConfig
} from './config';
import View from './View';
import withAlert from '../../../utils/composition/withAlert';
import { dropdownChange } from '../../../utils/formHandlers';
import { ALERT_TYPE } from '../../../v4/constants/AlertType';
import { USER_ROLE } from '../../../data/enums';
import { DialogFormWrapper } from '../../common';
import Form from './Form';
import { CALL_PLAN } from '../../../data/enums/Route';
import history from '../../../utils/history';
import { FORM_CONFIG } from '../../../data/enums/config';
import { refGenerator } from '../../../utils';
import { refValidator } from '../../../utils/refGenerator';
import { getTodaysDate } from '../../../utils/date';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../data/services';

const initialFilter = {
  cast_type:'UNSUCCESSFULCALL',
  distributor_id:[],
  route_id:[],
  dse_id:[],
  outlet_id:0,
  unsuccesful_days:null,
  invoiced_days:0,
  current_date:getTodaysDate(),
};

const initialMenu = {
  distributorList: [],
  routeList: [],
  dseList: [],
  outletList: [],
  unsucessfulList:[],
}

const Outlets = (props) => {
  const { displayAlert, serverResponseWaiting, getRouteList, getUserList, getOutletListPlan, createOutletSavePlan, getAbsentDse } = props;
  const [data, setData] = useState({ list:[] });
  const [menu, setMenu] = useState(initialMenu);
  const [outletFilter, setOutletFilter] = useState(initialFilter);
  const [tasList, setTasList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [removedList, setRemovedList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState([]);
  const [showMapView, setShowMapView] = useState(false);
  const [queryParameters, setQueryParameters] = useState({
    pagination: queryService.baseQueryParameters.pagination,
  });
  const [enableErrorDisplay,setEnableErrorDisplay] = useState(false)
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });

  const refsObj =refGenerator(formConfig.validationField);

  const onAPIRequestFailure = (error) => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const loadDistributorList = () => {
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.SUB_D).then((response) => {
      menu.distributorList = response;
      setMenu({ ...menu });
    });
  };
  const fetchRouteFilter = (filterValue=[]) => {
    if(filterValue?.length>0) {
      return ({ filters: [{ column: 'assigned_dse', value: filterValue?.map((a)=>a.toString()) }] });
    }else{
      return (
        {}
      );
    }
  }
  const loadRouteList = (filterValue) => {
    getRouteList(
      {
        offset: 0,
        filter: fetchRouteFilter(filterValue),
      },
      {
        handleSuccess: (res) => {
          menu.routeList = [...((res.data.routes && (res.data.routes.rows || [])) || [])];
          setMenu({ ...menu });
        },
        handleError: (err) => onAPIRequestFailure(err),
      },
    );
  };

  const loadDSEList = () => {
    getUserList(
      {
        offset: 0,
        filter:  {
          filters: [
            {
              column: 'role_id',
              value: [USER_ROLE.DSE.toString(), USER_ROLE.BRANCH_HEAD.toString()],
            },
            {
              column: 'distributor_id',
              value: outletFilter?.distributor_id?.map((a)=>a.toString()),
            },
          ]
        },
      },
      {
        handleSuccess: (response) => {
          menu.dseList = response.data.users.rows || [];
          setMenu({ ...menu });
        },
        handleError: (err) => onAPIRequestFailure(err),
      },
    );
  };

  const loadAbsentDSEList = () => {
    getAbsentDse(
      {
        input: {
          distributor_id: outletFilter?.distributor_id,
          date: outletFilter?.current_date,
        }
      },{
        handleSuccess: (response) => {
          menu.dseList = response.data.getAbsentDse.rows || [];
          setMenu({ ...menu });
        },
        handleError: (err) => onAPIRequestFailure(err),
      },)
  }

  const loadTASList = () => {
    const { id ,roleId } = JSON.parse(localStorage.getItem('rosia-detail'));
    let filters = [{
      column: 'role_id',
      value: [USER_ROLE.TAS.toString()],
    }];
    if(roleId === 14){
      filters.push(
        {
          column: 'parent_user_id',
          value: [id.toString()]
        })
    }
    getUserList(
      {
        offset: 0,
        filter: {
          filters: filters
        },
      },
      {
        handleSuccess: (response) => {
          setTasList(response.data.users.rows || []);
        },
        handleError: (err) => onAPIRequestFailure(err),
      },
    );
  };

  const handleDropDownChange = (value, parameterRef = []) => {
    if(parameterRef[0]==='cast_type'){
      setOutletFilter({
        distributor_id:[],
        route_id:[],
        dse_id:[],
        outlet_id:0,
        unsuccesful_days:null,
        invoiced_days:0,
        current_date:getTodaysDate(),
        cast_type:value
      })
      if(value==='UNSUCCESSFULCALL'){
        loadDSEList();
        loadRouteList();
      }else{
        loadAbsentDSEList();
        loadRouteList();
      }
    }else{
      const updatedData = dropdownChange(outletFilter, parameterRef, value);
      setOutletFilter(updatedData);
    }
  };

  const handleAutoCompleteChange=(value, feildName) => {
    setOutletFilter({ ...outletFilter,[feildName]: value });
    if(feildName==='distributor_id'){
      outletFilter.dse_id=[];
      outletFilter.route_id=[];
      setOutletFilter(outletFilter);
      if(outletFilter.cast_type==='UNSUCCESSFULCALL'){
        loadDSEList();
      }else{
        loadAbsentDSEList();
      }
    }
    if(feildName==='dse_id'){
      outletFilter.route_id=[];
      setOutletFilter(outletFilter);
      loadRouteList(outletFilter?.dse_id)
    }
  }

  const handleCurrentDateChange = (date,param) => {
    setOutletFilter({ ...outletFilter, current_date: date });
    if(outletFilter.cast_type==='UNSUCCESSFULCALL'){
      loadDSEList();
    }else{
      loadAbsentDSEList();
    }
  };

  const handleButtonReset = () =>{
    setOutletFilter({
      cast_type:'UNSUCCESSFULCALL',
      distributor_id:[],
      route_id:[],
      dse_id:[],
      outlet_id:0,
      unsuccesful_days:null,
      invoiced_days:0,
      current_date:getTodaysDate(),
    });
    setData({ list:[] })
    loadDSEList();
    loadRouteList();
    setSelectedOutlet([]);
    setCheckedList([]);
    setRemovedList([]);
  };

  const handleButtonApply = () =>{
    const formValidation = refValidator(refsObj);
    setCheckedList([]);
    if (!formValidation) {
      setEnableErrorDisplay(true)
    } else {
      getOutletListPlan({
        input: {
          cast_type:outletFilter.cast_type||'UNSUCCESSFULCALL',
          distributor_id:outletFilter.distributor_id||[],
          route_id:outletFilter.route_id||[],
          dse_id:outletFilter.dse_id||0,
          outlet_id:outletFilter.outlet_id||0,
          unsuccesful_days:outletFilter.unsuccesful_days||0,
          invoiced_days:outletFilter.invoiced_days||0,
          current_date:outletFilter.current_date||getTodaysDate(),
        }
      },{
        handleSuccess: (res) => {
          const responseList =[...((res.data.getlistOutletPlan && (res.data.getlistOutletPlan.rows || [])) || [])];
          const filteredData= responseList.filter((cv)=>{
            return !selectedOutlet.find((e)=>{
              return e.id == cv.id;
            });
          });
          data.list = filteredData;
          setData({ ...data });
          displayAlert(ALERT_TYPE.SUCCESS,`${outletFilter.cast_type==='UNSUCCESSFULCALL'?'Unsuccessful Call':'Absent DSE'} Filter Applied`)
        },
        handleError: (err) => onAPIRequestFailure(err),
      })
    }

  };

  const handleAddOutlet = (list,label) => {
    if(label==='add') {
      data.list = data.list.filter((a)=>a.id!==list.id)
      setSelectedOutlet([...selectedOutlet,list]);
      setData({ ...data });
    } else {
      const arr = selectedOutlet.filter((a)=>![list].includes(a));
      data.list= [...data.list, list]
      setSelectedOutlet([...arr]);
      setData({ ...data });
    }
  }

  const handleAddSelected = () => {
    data.list = data.list.filter((a)=>!checkedList.includes(a));
    setSelectedOutlet([...selectedOutlet,...checkedList]);
    setData({ ...data });
    setCheckedList([]);
  }

  const handleRemoveSelected = () => {
    const arr = selectedOutlet.filter((a)=>!removedList.includes(a));
    data.list= [...data.list,...removedList]
    setSelectedOutlet([...arr]);
    setData({ ...data });
    setRemovedList([]);
  }

  const handlePrimaryCheckboxClick = (e,label) => {
    if (e.target.checked) {
      if(label==='add') {
        setCheckedList(data.list.filter((a)=>!a.isplanned));
      } else {
        setRemovedList(selectedOutlet);
      }
    } else {
      if(label==='add') {
        setCheckedList([]);
      } else {
        setRemovedList([]);
      }
    }
  };

  const handleSecondaryCheckboxClick = (list,label) => {
    const index = label==='add'?checkedList.indexOf(list):removedList.indexOf(list);
    if (index > -1) {
      if(label==='add') {
        checkedList.splice(index, 1);
        setCheckedList([...checkedList]);
      } else {
        removedList.splice(index, 1)
        setRemovedList([...removedList]);
      }
    } else {
      if(label==='add') {
        setCheckedList([...checkedList, list]);
      } else {
        setRemovedList([...removedList,list]);
      }
    }
  };

  const createOutletPlan = (type,element) => {
    createOutletSavePlan({
      input: {
        user_id: element.user_id,
        dse_id:selectedOutlet.map((outlet)=>outlet.dse_id),
        outlet_id: selectedOutlet.map((outlet)=>outlet.id),
        cast_type: outletFilter.cast_type,
        local_date:element.date||getTodaysDate()
      }
    },{
      handleSuccess: () => {
        displayAlert(ALERT_TYPE.SUCCESS, `${selectedOutlet.length} Outlets added to Call Plan.`);
        setTimeout(() => {
          history.push(`/${CALL_PLAN}`);
        }, 1200);
      },
      handleError: (error) => {
        onAPIRequestFailure(error);
      },
    })
  }

  const handleAddCallPlan = (type,element) => {
    const { roleId, id } = JSON.parse(localStorage.getItem('rosia-detail'));
    if(roleId===USER_ROLE.TAS){
      createOutletPlan(type,{ user_id:id });
    }else{
      handleIconClick(type,element);
    }
  }

  const toggleMapView = (toggle) => {
    setShowMapView(toggle);
  };

  const handleIconClick = (type, element = {}) => {
    loadTASList();
    setDialog({
      type,
      element,
    });
  }

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
  };

  useEffect(() => {
    loadDistributorList();
    loadDSEList();
    loadRouteList();
  }, []);


  return (
    <OutletStyled>
      {dialog.type && (
        <DialogFormWrapper
          formConfig={formConfig[dialog.type]}
          dialogElement={dialog.element}
          onDialogSubmit={createOutletPlan}
          onDialogCancel={resetDialog}
          type={dialog.type}
          renderDialog={({ dialogData, handleDropDownChange, refsObj, handleDateChange, enableErrorDisplay }) => (
            <Form
              type={dialog.type}
              data={dialogData}
              tasList={tasList}
              refsObj={refsObj}
              loading={serverResponseWaiting}
              handleDateChange={handleDateChange}
              enableErrorDisplay={enableErrorDisplay}
              handleDropDownChange={handleDropDownChange}
            />
          )}
        />
      )}
      <div className='section-header'>
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title:`${title} Plan`,
            }}
            queryParameters={queryParameters}
          />
        </PanelStyled>
      </div>
      <div className='section-content'>
        <View
          data={data}
          menu={menu}
          showMapView={showMapView}
          checkedList={checkedList}
          removedList={removedList}
          outletFilter={outletFilter}
          toggleMapView={toggleMapView}
          selectedOutlet={selectedOutlet}
          loading={serverResponseWaiting}
          onAddOutlet={handleAddOutlet}
          onButtonApply={handleButtonApply}
          onButtonReset={handleButtonReset}
          onAddCallPlan={handleAddCallPlan}
          onAddSelected={handleAddSelected}
          onRemoveSelected={handleRemoveSelected}
          onDropDownChange={handleDropDownChange}
          onDateChange={handleCurrentDateChange}
          onPrimaryCheckboxClick={handlePrimaryCheckboxClick}
          onSecondaryCheckboxClick={handleSecondaryCheckboxClick}
          enableErrorDisplay={enableErrorDisplay}
          refsObj={refsObj}
          handleAutoComplete={handleAutoCompleteChange}
        />
      </div>
    </OutletStyled>
  );
};

export default withAlert()(Outlets);
