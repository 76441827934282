import styled from 'styled-components';

const DetailsStyled = styled.div`
.font-bold{
  font-weight:600 !important;
}
.section-content{
  .config-panel-card{
    &.card_one{
      .info{
      label{
        font-size:14px;
        line-height:21px;
        color:#272833;
        margin-bottom:10px;
        font-weight:500;
      }
      p{
        font-size:16px;
        line-height:24px;
        color:#6B6C7E;
        margin:0;
      }
    }
    }
    &.card_two{
      .config-panel-title{
        display:none;
      }
      .config-panel-contents{
        padding:0;
        caption{
          display:flex;
          justify-content:space-between;
          padding:10px 24px;
          align-items: center;
          background-color:#F1F2F5;
          border-bottom:1px solid #CDCED9;
          span{
            font-size:14px;
            line-height:21px;
            color:#272833;
            font-weight:500;
          }
          &>div{
            border:0;
            padding:0;
            .zindex-2__control{
              border:0;
              background-color:unset;
              cursor:pointer;
              .zindex-2__value-container--has-value{
                align-items:end;
                justify-content: flex-end;
                .zindex-2__single-value{
                  font-weight:400;
                  font-size:12px;
                }
              }
            }
          }
        }
        .table-wrap{
          max-height:500px;
          overflow:auto;
          table{
            thead{
              th{
                font-size:14px;
                line-height:21px;
                font-weight:500;
                color:#6B6C7E;
              }
            }
            tbody{
              td{
                p{
                  margin:0;
                }
              }
              .brand{
                td{
                  font-size:14px;
                line-height:21px;
                font-weight:500;
                color:#6B6C7E;
                padding-top:26px;
                padding-bottom:10px;
                }
                &:hover{
                  background:unset;
                }
              }
            }
          }
        }
      }
}
  }
}


.no-print{
  display:none;
}
.print-body{
      .purchase-info{
        padding:10px 10px 20px 10px;
        border:1px solid #000000;
        border-bottom:0;
        .left{
          h4,p{
            margin:0;
            color:#000000;
          }
          h4{
            margin-bottom:4px;
            font-size:16px;
            color:#000000;
          }
          p{
            font-size:11px;
            font-weight:500;
            color:#000000;
            span{
              font-weight:400;
              padding-left:4px;
            }
          }
        }
        .center{
          text-align:center;
          h2{
            margin:0;
            font-size:18px;
            color:#000000;
          }
          p{
            margin:0;
            font-size:12px;
            color:#000000;
          }
        }
        .right{
          text-align:right;
          p{
            margin:0;
            font-size:11px;
            font-weight:500;
            color:#000000;
            span{
              font-weight:400;
            }
          }
        }
      }
    .table-wrap{     
        height:unset !important;
          caption{
              padding:10px 24px;
              span{
                font-size:12px;
                line-height:16px;
                color:#272833;
                font-weight:500;
              }
          }
          table {
            border:1px solid #000000;
            thead{
              border:2px solid #000000;
              tr{
                height:24px;
              th{
              padding:7px 12px;
              border:0;
              border-right:2.5px solid #000000;
              font-size:10px;
                line-height:12px;
                font-weight:500;
                color:#000000;

                &:last-child{
                  border-right:0;
                }
                }
                }
            }
            tbody{
              tr:first-child{
                height:40px;
              }
            }
            tfoot,
            tbody{
              tr{
                height:30px;
                  td.brand-td{
                    vertical-align:bottom;
                    font-size:11px;
                  }
            td{
              border:0;
              border-right:1px solid #000000;
              padding:7px 12px;
              font-size:10px;
                line-height:12px;
                white-space:nowrap;
                color:#000000;
                &.sku{
                  width:150px;
                  white-space:normal;
                  }
                &:last-child{
                  border-right:0;
                }
                p{
                margin:0;
                font-size:10px;
                line-height:12px;
                color:#000000;
                }
                }
              }
              }
              tfoot{
                tr{
                    height:25px;
                  td{
                    border:0;
                    padding: 0px 12px;
                    line-height:10px;
                  }
                  }
                &.foot_one{
                  border-top:1px solid #000000;
                  border-bottom:1px solid #000000;
                }
                &.foot-three{
                      border-top:1px solid #000000;
                      td{
                        padding:6px 8px;
                        vertical-align:top;
                      }
                      .inword{
                        white-space:normal;
                        text-align:left;
                        line-height:14px;
                        font-style:italic;
                      }
                }
              }
        }
    }
}
  .visibility-shown-print{
          visibility: hidden !important;
      }
    @media print {
      border
        .print_display_none{
            display:none ;
        }
        /* .no-print{
          display:block;
        }
        .portrait{
          display:none;
        } */
        .visibility-shown-print{
            visibility: visible !important;
        }
        .portrait{
            padding:0 ;
          border-top:0
          }
        @page {
          margin:0;
            size: A4 portrait !important;
            counter-increment: page;
            counter-reset: page 1;
        }
        .print-body{
          height:100%;
          page-break-after: always;
          padding:20px;
        }
    }


`;
export default DetailsStyled;
