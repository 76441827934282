import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextStyled } from '../../components/Input/TextStyled';
import { Icon } from '../../v4/components';
import { createScriptLoadMap } from '../configuration/arc/services/googleMapScript';
import marker from '../configuration/arc/services/marker';

const propTypes = {
  data: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  draggable: PropTypes.bool,
  zoom: PropTypes.number,
  onDrag: PropTypes.func.isRequired,
  showSearchInput: PropTypes.bool.isRequired,
};

const defaultProps = {
  data: {
    latitude: 0,
    longitude: 0,
  },
  draggable: false,
  zoom: 14,
};

class Map extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      center: {
        lat: data.latitude,
        lng: data.longitude,
      },
      google: window.google,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      center: {
        lat: nextProps.data.latitude,
        lng: nextProps.data.longitude,
      },
    };
  }

  componentDidMount() {
  const {staticKey}= this.props
  if(staticKey){
    this.loadMap()
  }else{
    createScriptLoadMap().then((a) => {
      this.renderMarkers(a);
    });}
  }

  loadMap = () => {
    const { google } = this.state;
    const { data, zoom } = this.props;
    const map = new google.maps.Map(document.getElementById('map'), {
      center: {
        lat: data.latitude,
        lng: data.longitude,
      },
      zoom,
      styles: [],
      mapTypeControl: false,
    });
    const input = document.getElementById('pac-input');
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.bindTo('bounds', map);
    this.loadMarkerToMap(map, autocomplete);
  };

  renderMarkers = (a) => {
    const { data } = this.props;
    const input = document.getElementById('pac-input');
    const autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.bindTo('bounds', map);
      this.loadMarkerToMap(a, autocomplete);
    window.globalMap.setCenter({ lat: data.latitude, lng: data.longitude });
    window.globalMap.setZoom(15);
  };
  componentDidUpdate(prevProps, prevState) {
    const { center } = this.state;
    const { center: prevCenter } = prevState;

    if (center.lat !== prevCenter.lat || center.lng !== prevCenter.lng) {
      createScriptLoadMap().then((a) => {
        this.renderMarkers(a);
      });
    }
  }

  getIconAttributes = iconColor => ({
    path: markerIconPath,
    fillColor: iconColor,
    fillOpacity: 0.8,
    strokeWeight: 2,
    anchor: new google.maps.Point(googleMapPoint1, googleMapPoint2),
  });
  getIconAttributes = iconColor => {
    const googleMapPoint1 = 30;
    const googleMapPoint2 = 50;
    const fillOpacity = 0.8;
    const strokeWeight = 2;
    const markerIconPath =
  'M24,4 C16.26,4 10,10.26 10,18 C10,28.5 24,44 24,44 C24,44 38,28.5 38,18 C38,10.26 31.74,4 24,4 Z M24,23 C21.24,23 19,20.76 19,18 C19,15.24 21.24,13 24,13 C26.76,13 29,15.24 29,18 C29,20.76 26.76,23 24,23 Z';
    return({
    path: markerIconPath,
    fillColor: iconColor,
    fillOpacity,
    strokeWeight,
    anchor: new google.maps.Point(googleMapPoint1, googleMapPoint2),
  })};

  onMarkerDrag = e => {
    const { onDrag } = this.props;
    const latLng = {
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    };
    onDrag(latLng);
  };

  loadMarkerToMap = (map, autocomplete) => {
    const { google } = this.state;
    const { data, draggable } = this.props;

    // eslint-disable-next-line no-new

    const image = new google.maps.MarkerImage(
      `${process.env.PUBLIC_URL}/image/icons/ic_geolocation_red.svg`,
      new google.maps.Size(71, 71),
      new google.maps.Point(0, 0),
      new google.maps.Point(23, 45),
      new google.maps.Size(45, 45),
    );

    const marker = new google.maps.Marker({
      position: {
        lat: data.latitude,
        lng: data.longitude,
      },
      map,
      icon: image,
      class: 'hello',
      draggable,
      iconSize: (100, 100),
    });
    this.searchAddress(map, marker, autocomplete);
    marker.addListener('dragend', this.onMarkerDrag);
  };

  searchAddress = (map, marker, autocomplete) => {
    const { onDrag, zoom } = this.props;

    autocomplete.addListener('place_changed', () => {
      marker.setVisible(false);
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert(`No details available for input: '${place.name}'`);
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(zoom); // Why 17? Because it looks good.
      }
      marker.setPosition(place.geometry.location);

      onDrag({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });
      marker.setVisible(true);
    });
  };

  loadMap = () => {
    const { google } = this.state;
    const { data, zoom } = this.props;
    const map = new google.maps.Map(document.getElementById('map'), {
      center: {
        lat: data.latitude,
        lng: data.longitude,
      },
      zoom,
      styles: [],
      mapTypeControl: false,
    });
    const input = document.getElementById('pac-input');
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.bindTo('bounds', map);
    this.loadMarkerToMap(map, autocomplete);
  };

  render() {
    const { showSearchInput } = this.props;
    return (
      <div className="map-wrapper-inner relative">
        {showSearchInput && (
          <div className="absolute">
            <Icon iconName="search" iconHeight="14px" iconWidth="14px" />
            <TextStyled type="text" id="pac-input" placeholder="Search" />
          </div>
        )}
        <div id="map" style={{ height: '50vh' }} />
      </div>
    );
  }
}

Map.defaultProps = defaultProps;

Map.propTypes = propTypes;

export default Map;
