import { FORM_CONFIG } from '../../../../../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../../../../../data/enums/EventOperation';
import { getCurrentDay } from '../../../../../../../../utils/date';
import { trimWhiteSpace } from '../../../../../../../common/HelperFunctions';

const title = 'JBP Templates';

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Confirmation Dialog',
  },
};

const getInitialData = (id, configuredFundamentals = [], element = {}) => ({
  title: element.title || '',
  start_date: element.start_date || getCurrentDay(),
  end_date: element.end_date || getCurrentDay(),
  principal_id: id,
  outlet_chain_id: element.outlet_chain_id || null,
  category_id: element.category_id || [],
  outlet_id: element.outlet_id || null,
  point: element.point || 0,

  fundamentalWeightage: configuredFundamentals,
});

const getPayload = (data, skuIds) => ({
  basicData: {
    ...data.basicData,
    outlet_id: data?.basicData?.outlet_id||null,
    active: true,
    title: trimWhiteSpace(data.basicData.title),
    fundamentalWeightage: data.basicData.fundamentalWeightage.map((a) => ({
      fundamental_type_id: a.id,
      weightage: Number(a.weightage),
    })),
  },
  sod: data.sod ? data.sod.map((a) => ({
    merchandise_device_id: a.merchandise_device_id,
    catalog_id: a.catalog_id,
    catalog_detail_id: a.catalog_detail_id,
    count: a.count || 0,
  })) : [],
  sos: data.sos ? data.sos.map((a) => ({
    catalog_id: a.catalog_id,
    catalog_detail_id: a.catalog_detail_id,
    weightage: a.weightage || 0,
    jbpshare: a.jbpshare || 0,
  })) : [],
  sosku: data.sosku ? skuIds.map((a) => ({
    sku_family_id: a.sku_family_id,
    product_category_id: a.product_category_id,
    brand_id: a.brand_id,
    status: a.active,
  })) : [],
  sbdTemplateId: data.sbdTemplateId,
});

const ERROR_MSG = {
  WEIGHTAGE: 'Total SOS weightage % should be equal to 100%',
  COUNT: 'SOD count should not be 0',
  SKU_STATUS: 'Select at least on SKU from SOSKU',
};

const FUNDAMETALS = {
  SOS: 'SOS',
  SOD: 'SOD',
  SOSKU: 'SOSKU',
};

export {
  title, getPayload, getInitialData, ERROR_MSG, FUNDAMETALS, formConfig,
};
