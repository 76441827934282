import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import View from './View';
import { breadCrumbConfig, filter as filterConfig, title, formConfig } from './config';
import { imageSliderListFormatter, isError } from '../../common/HelperFunctions';
import { DialogWrapper } from '../../common';
import PageHeader from '../../base/PageHeader';
import { getCurrentDay } from '../../../utils/date';
import AttendanceStyled from './AttendanceStyled';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import withAlert from '../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { AttendanceMap, Agendas } from './AttendanceDialog';
import { DOWNLOAD_DOMAIN } from '../../../data/enums/GraphQL';
import { getPermissionForLeaveRequest } from '../../base/permission';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { clone } from '../../../utils/objectProcessor';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getAttendanceList: PropTypes.func.isRequired,
  downloadReport: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class Attendance extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      dialog: {
        type: '',
        element: {},
      },
      queryParameters: {
        sort: queryService.baseQueryParameters.sort,
        date: { start: getCurrentDay(), end: getCurrentDay() },
        search: queryService.baseQueryParameters.search,
        filter: queryService.baseQueryParameters.filter,
        pagination: queryService.baseQueryParameters.pagination,
      },
      filterMenu: this.filterConfigMenu || {},
    };

    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
    this.permission = getPermissionForLeaveRequest();
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.ATTENDANCE
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.ATTENDANCE);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  setQueryParameters = (queryParams, callBack = () => null) => {
    this.setState({ queryParameters: queryParams }, callBack);
  };

  loadTableData = () => {
    const { getAttendanceList, displayAlert } = this.props;
    const { data, queryParameters } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getAttendanceList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          const attendanceList = (response.data.listAttendances && response.data.listAttendances.rows) || [];
          data.total = (response.data.listAttendances && response.data.listAttendances.count) || 0;
          data.list = attendanceList.map(attendance => {
            if (attendance.checkinImages) {
              attendance.checkinImages = [
                ...(imageSliderListFormatter(attendance.checkinImages, '', {
                  w: 2400,
                  h: 1100,
                }) || []),
              ];
            }
            if (attendance.checkoutImages) {
              attendance.checkoutImages = [
                ...(imageSliderListFormatter(attendance.checkoutImages, '', {
                  w: 2400,
                  h: 1100,
                }) || []),
              ];
            }
            return attendance;
          });
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleData = (data, type) => {
    this.setState({
      dialog: {
        type,
        element: data,
      },
    });
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  render() {
    const { data, queryParameters, display, dialog ,filterMenu} = this.state;
    const { serverResponseWaiting } = this.props;
    const { element } = dialog;
    const user = element.User;
    const userTitle = user ? user.fullName : '';
    const RoleTitle = user ? user.Role.name : '';
    return (
      <AttendanceStyled>
        <DialogWrapper
          onDialogSubmit={this.onFormSubmit}
          formConfig={formConfig}
          titleWithouttype
          title={userTitle}
          subTitle={RoleTitle}
          refsObj={this.formReference}
          withOutPadding
          footer
          renderDialog={({
            onDialogSubmit,
            handleDialogInputChange,
            handleDialogDropDownChange,
            type,
            enableErrorDisplay,
          }) => (
            <>
              {type === EVENT_OPERATION.UPDATE ? (
                <Agendas
                  show
                  data={element.Agendas}
                  refsObj={this.formReference}
                  onFormSubmit={onDialogSubmit}
                  loading={serverResponseWaiting}
                  enableErrorDisplay={enableErrorDisplay}
                  handleInputChange={handleDialogInputChange}
                  handleDropDownChange={handleDialogDropDownChange}
                />
              ) : (
                <AttendanceMap
                  show
                  data={element}
                  refsObj={this.formReference}
                  onFormSubmit={onDialogSubmit}
                  loading={serverResponseWaiting}
                  enableErrorDisplay={enableErrorDisplay}
                  handleInputChange={handleDialogInputChange}
                  handleDropDownChange={handleDialogDropDownChange}
                />
              )}
            </>
          )}
          render={({ onDialogItemClick }) => (
            <>
              <div className="section-header">
                <PanelStyled>
                  <PageHeader
                    config={{
                      title,
                      date: true,
                      filter: true,
                      search: true,
                      download: true,
                    }}
                    filter={{
                      date: queryParameters.date,
                      menuList: filterMenu,
                      onFilterChange: this.basePaginationService.handleFilterChange,
                    }}
                    downloadConfig={{
                      domain: DOWNLOAD_DOMAIN.ATTENDANCE,
                    }}
                    breadCrumb={breadCrumbConfig}
                    display={display}
                    queryParameters={queryParameters}
                    handleUploadClick={this.onDialogItemClick}
                    handleCreateClick={this.handleAddButtonClick}
                    resetFilter={this.basePaginationService.resetFilter}
                    clearSearchText={this.basePaginationService.clearSearchText}
                    handleSearchChange={this.basePaginationService.handleSearchInputChange}
                    handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                    handleDownloadClick={this.basePaginationService.handleDownloadClick}
                    controlDisplay={this.controlDisplay}
                  />
                </PanelStyled>
              </div>
              <div className="section-content table-present">
                <View
                  data={data}
                  permission={this.permission}
                  loading={serverResponseWaiting}
                  pagination={queryParameters.pagination}
                  handleData={this.handleData}
                  onPageChange={this.basePaginationService.onPageSelect}
                  onIconClick={onDialogItemClick}
                />
              </div>
            </>
          )}
        />
      </AttendanceStyled>
    );
  }
}

Attendance.propTypes = propTypes;

Attendance.defaultProps = defaultProps;

export default withAlert()(Attendance);
