import styled from 'styled-components';

const DetailsStyled = styled.div`
.disable{
cursor:not-allowed;
  .merchandise-detail {
    pointer-events:none;
  }
}
.config-panel-contents{
  padding:24px !important;
  .form-wrapper{
    width: 674px;
    display:flex;
    flex-wrap:wrap;
  }
}
.config-panel-card{
  margin-top:48px;
  .brandsCategoryInfo-wrapper{
    display:flex;
    margin-bottom:24px;
    .form-wrapper{
      display: flex;
    flex-wrap: wrap;
    flex: 1;
    background-color: #F1F2F5;
    margin-right: 24px;
    border-radius:4px;
    .form-in{
      display: flex;
    padding: 24px;
    flex:1;
    .form-select-input {
      width: 325px;
      width: 34%;
    margin-right: 24px;
    }
        .auto-complete{
          flex: 1;
          position: relative;
          &>span{
            position: absolute;
              top: 31px;
              left: 10px;
          }
          .filter-dropdown-card {
            right:unset;
          }
          .form-input{
            margin-right: 30px;
          }
          input{
                padding-left:30px;
              }
        }
    }
    }
    .auto-complete,
    .config-icon{
      svg{
        cursor:pointer;
        fill: #6B6C7E;
      }
    }
    .error-message{
    svg{
        fill: #da1414;
      }
  }
  }
}
.suggested-autocomplete-list-wrap {
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 24px;
    display: flex;
    flex-wrap:wrap;
    .suggested-autocomplete-list{
    margin-top: 4px;
    border: 1px solid #E7E7ED;
    height: 40px;
    background-color: #FFF;
    margin-right: 4px;
    border-radius: 4px;
    .text{
      font-size: 16px;
      border:0;
      background:unset;
    }
    .ic-close{
    margin-left:0;
    margin-right: 18px;
    }
    }
  }
  .autocomplete-list {
    > div {
      margin-bottom: 5px;
      cursor: pointer;
      font-weight: 600;
      color: #6b6c7e;
      font-size: 14px;
      transition: 0.2s linear;

      &:hover,
      &:focus {
        color: #272833;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .suggested-autocomplete-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 4px;
    .text {
      padding: 8px 16px;
      border-radius: 4px;
      background: #e7e7ed;
      border: 1px solid #e7e7ed;
      font-size: 14px;
      flex: 1;
    }
    .ic-close {
      cursor: pointer;
      display: inline-block;
      width: 12px;
      height: 12px;
      overflow: hidden;
      text-indent: -999px;
      background: url('/image/icons/ic_close.svg');
      background-size: 12px;
      margin-left: 18px;
    }
  }
`;
export default DetailsStyled;
