import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withAlert from '../../../utils/composition/withAlert';
import View from './View';
import { PanelStyled } from '../../common/configuration';
import PageHeader from '../../base/PageHeader';
import { breadCrumbConfig, title, crudSuccess, crudSuccess as crudRequestConfig, filterConfig } from './config';
import * as queryService from '../../base/query.service';
import { PUSH_MESSAGE_CREATE } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { DialogWrapper } from '../../common';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { getPermissionForPromotion } from '../../base/permission';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import PromotionForm from '../promotion/Form';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { clone } from '../../../utils/objectProcessor';
import history from '../../../utils/history';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  toggleState: PropTypes.func.isRequired,
  messagesList: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class PushMessage extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      checkedList: [],
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      showBulkSelect: true,
    };
    this.permission = getPermissionForPromotion();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );

    const serverCall = {
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };

    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        props.displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      },
      () => this.getData(),
    );

    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.PUSH_MESSAGE
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.PUSH_MESSAGE);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  directToCreatePage = () => {
    const { history } = this.props;
    history.push(`/${PUSH_MESSAGE_CREATE}`);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleBulkDelete = (type, response) => {
    const { displayAlert } = this.props;
    const { data } = this.state;
    displayAlert(ALERT_TYPE.SUCCESS, crudSuccess[type].message);
    crudResponseProcessor(
      modifiedData => {
        this.setState({ data: modifiedData });
      },
      () => data,
    )(type)(response);
  };

  onReplicateClick = id => {
    history.push(`/${PUSH_MESSAGE_CREATE}/${id}`);
  };

  getCheckedListData = checkedList => {
    this.setState(
      {
        checkedList,
      },
      () => {
        this.setState({
          showBulkSelect: true,
        });
      },
    );
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { messagesList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    messagesList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.messages && response.data.messages.rows) || [];
          data.total = (response.data.messages && response.data.messages.count) || 0;
          this.setState(data);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  render() {
    const { queryParameters, checkedList, data, showBulkSelect ,filterMenu} = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <DialogWrapper
          onDialogSubmit={this.onFormSubmit}
          refsObj={this.formReference}
          title={title}
          replicateRedirect
          replicateRedirectUrl={this.onReplicateClick}
          renderDialog={({
            onDialogSubmit,
            handleDialogInputChange,
            handleDialogDropDownChange,
            dialogData,
            enableErrorDisplay,
          }) => (
            <Fragment>
              <PromotionForm
                show
                data={dialogData}
                checkedList={checkedList}
                onFormSubmit={onDialogSubmit}
                loading={serverResponseWaiting}
                enableErrorDisplay={enableErrorDisplay}
                handleInputChange={handleDialogInputChange}
                handleDropDownChange={handleDialogDropDownChange}
                refsObj={this.formReference}
              />
            </Fragment>
          )}
          render={({ onDialogItemClick }) => (
            <Fragment>
              <div className="section-header">
                <PanelStyled>
                  <PageHeader
                    breadCrumb={breadCrumbConfig}
                    config={{
                      title,
                      pushMesssage: true,
                      filter: true,
                      search: true,
                    }}
                    filter={{
                      // date: queryParameters.date,
                      menuList:filterMenu,
                      onFilterChange: this.basePaginationService.handleFilterChange,
                    }}
                    queryParameters={queryParameters}
                    controlDisplay={this.controlDisplay}
                    handleCreateClick={this.directToCreatePage}
                    resetFilter={this.basePaginationService.resetFilter}
                    clearSearchText={this.basePaginationService.clearSearchText}
                    handleSearchChange={this.basePaginationService.handleSearchInputChange}
                  />
                </PanelStyled>
              </div>
              <div className="section-content">
                <View
                  data={data}
                  permission={this.permission}
                  pagination={queryParameters.pagination}
                  onIconClick={onDialogItemClick}
                  loading={serverResponseWaiting}
                  onPageChange={this.basePaginationService.onPageSelect}
                  onBulkDelete={this.handleBulkDelete}
                  onReplicateTypeClick={this.handleMultiReplicateType}
                  getCheckedListData={this.getCheckedListData}
                  showBulkSelect={showBulkSelect}
                />
              </div>
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

PushMessage.propTypes = propTypes;

PushMessage.defaultProps = defaultProps;

export default withAlert()(PushMessage);
