import React from 'react';
import PropTypes from 'prop-types';
import { normalPresentor } from '../../../utils/date';
import { STATUS } from '../../../data/enums/Status';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import TableHeader from '../../base/TableHeader';

// todo update the configurations.
const labelMappings = {
  date: 'date',
  srnDate: 'srnDate',
  status: 'status',
  amount: 'totalNetValue',
  ledger: 'customer_name',
  reason: 'reason',
  stockType: 'type',
  distributor: 'distributor',
  retailOutlet: 'outlet',
  actionHolders: 'actionHolders',
  invoiceNumber: 'invoiceNumber',
  SRNInvoiceNumber: 'SRNInvoiceNumber',
};

const returnTableBody = (data) => {
  const table = {};
  table[labelMappings.invoiceNumber] = <td key={`srn-${data.id}-invoice`}>{data.invoiceNumber}</td>;

  table[labelMappings.date] = <td key={`srn-${data.id}-date`}>{normalPresentor(data.date)}</td>;

  table[labelMappings.srnDate] = <td key={`srn-${data.id}-srnDate`}>{normalPresentor(data.srnDate)}</td>;

  table[labelMappings.SRNInvoiceNumber] = <td key={`srn-${data.id}-srnInvoice`}>{data.SRNInvoiceNumber}</td>;

  table[labelMappings.status] = (
    <td key={`srn-${data.id}-approve`}>
      {data.approved ? <span>{STATUS.APPROVED}</span> : <span>{STATUS.PENDING}</span>}
    </td>
  );

  table[labelMappings.distributor] = (
    <td key={`srn-${data.id}-distributor`}>{data.Distributor ? data.Distributor.title : ''}</td>
  );

  table[labelMappings.retailOutlet] = (
    <td key={`srn-${data.id}-outlet`}>{data.RetailOutlet ? data.RetailOutlet.title : ''}</td>
  );

  table[labelMappings.amount] = (
    <td key={`srn-${data.id}-amount`} className='text-right'>
      {fixedFloatAndCommas(data.srnAmount ? data.srnAmount.netAmount || 0 : 0)}
    </td>
  );

  table[labelMappings.ledger] = <td key={`srn-${data.id}-legder`}>{data.Customer ? data.Customer.name : ''}</td>;

  table[labelMappings.reason] = <td key={`srn-${data.id}-reason`}>{data.reason}</td>;

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.invoiceNumber,
    title: 'Sales Invoice Number',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Created Date',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 3,
    label: labelMappings.srnDate,
    title: 'SRN Date',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.SRNInvoiceNumber,
    title: 'SRN Invoice Number',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 5,
    label: labelMappings.status,
    title: 'Status',
    show: false,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 6,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: false,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 7,
    label: labelMappings.retailOutlet,
    title: 'Retail Outlet',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 8,
    label: labelMappings.amount,
    title: 'Amount',
    align: true,
    show: true,
    sortable: true,
    className: 'text-right sorting',
  },
  {
    id: 9,
    label: labelMappings.ledger,
    title: 'Ledger',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 10,
    label: labelMappings.reason,
    title: 'Remarks',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 11,
    label: labelMappings.actionHolders,
    title: '',
    show: false,
    sortable: false,
  },
];

const TableBody = (data, onRowClick) => {
  const tableBody = returnTableBody(data);

  return (
    <tr key={`srn-${data.id}`} onClick={() => onRowClick(EVENT_OPERATION.UPDATE, data.id)}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

const TableHeaderConfig = ({ onHeaderClick }) => (
  <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />
);

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export { TableHeaderConfig as TableHeader, TableBody };
