import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_FUNDAMENTAL_DETAILS_LIST = gql`
  query getFundamentalDetails($buId: Int!){
      getFundamentalDetails(buId:$buId){
        id
        title
        principalId : principal_id
        auditCriteriaId : audit_criteria_id
        fundamentalTypeId : fundamental_type_id
        active
        BUtitle
      }
    }
`;

const CREATE_FUNDAMENTAL_DETAILS = graphql(
  gql` mutation createFundamentalDetails($input: FundamentalDetailsInput) {
    createFundamentalDetails(input:$input){
      id
      title
      principalId: principal_id
      auditCriteriaId: audit_criteria_id
      fundamentalTypeId: fundamental_type_id
      active
    }
}`, {
    props: ({ mutate }) => ({
      createFundamentalDetails: variables => mutate({
        variables,
      }),
    }),
  },
);

const GET_AUDIT_CRITERIA = gql`
  query getAuditCriteria{
    getAuditCriteria{
      id
      title
      active
      catalog_id
      fundamentalsId: fundamentals_id
    }
  }
`;

const query = {
  getFundamentalDetails: GET_FUNDAMENTAL_DETAILS_LIST,
  getAuditCriteria: GET_AUDIT_CRITERIA,
};

const mutation = {
  createFundamentalDetails: CREATE_FUNDAMENTAL_DETAILS,
};

export {
  query,
  mutation,
  CREATE_FUNDAMENTAL_DETAILS,
};
