import { compose } from 'recompose';
import Fundamentals from './Fundamentals';
import withRequestTracker from '../../../../../../../utils/composition/withRequestTracker';
import { query, mutation, CREATE_FUNDAMENTAL_DETAILS } from './API';

const composedFundamentals = compose(
  CREATE_FUNDAMENTAL_DETAILS,
  withRequestTracker({
    query,
    mutation,
  }),
)(Fundamentals);

export default composedFundamentals;
