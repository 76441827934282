import React from 'react';
import {
  Link, Route, Switch, Redirect
} from 'react-router-dom';

import Navigation from '../navigation';
import BaseWrapper from './BaseStyled';
import { ErrorPage } from '../../components/index';
import {
  DemoAlert,
  DemoBadge,
  DemoButton,
  DemoCheckbox,
  DemoColorPalette,
  DemoIcon,
  errorPage as Error,
} from '../../styleguide';
import NavigationStyled from '../navigation/NavigationStyled';

import * as RoutePage from '..';
import * as RouteLink from '../../data/enums/Route';
import { getUser } from '../../data/dao';
import Main from '../configuration/arc/Main';
import TTMain from '../configuration/targetTools/Main';
import { BaseContext } from '../../utils/context';
import { userRoleForTASDashboard } from '../login/config';
import { USER_ROLE } from '../../data/enums';

const Base = ({ match: { url }, history }) => {
  const userDetails = getUser();
  const isTASUser = Object.values(userRoleForTASDashboard).includes(userDetails.roleId);
  const isExternalUser = userDetails?.Role?.id === USER_ROLE.VIRTUAL_USER;
  const dasboardUrl = isExternalUser
    ? `/${RouteLink.RSCORE}`
    : isTASUser
      ? `/${RouteLink.TAS_DASHBOARD}`
      : `/${RouteLink.DASHBOARD}`;
  return (
    <BaseWrapper className='main-wrapper'>
      <NavigationStyled className='side-nav'>
        <div className='sidebar-navigation'>
          <div className='sidebar-inner'>
            <div className='logo-wrap' onClick={() => history.push(dasboardUrl)}>
              <a title='Rosia V3'>
                <img src='/image/evolve-logo.svg' alt='Rosia V3' />
              </a>
            </div>
            <Navigation />
            <div className='hidden styleguide-menu'>
              <Link to={`${url}color-palette`}>Color Palette</Link>
              <Link to={`${url}icons`}>Icons</Link>
              <Link to={`${url}alerts`}>Alerts</Link>
              <Link to={`${url}button`}>Button</Link>
              <Link to={`${url}badge`}>Badge</Link>
              <Link to={`${url}checkbox`}>Checkbox</Link>
              <Link to={`${url}form`}>Form</Link>
              <Link to={`${url}${RouteLink.SKU}`}>SKU</Link>
              <Link to={`${url}${RouteLink.TERRITORY}`}>Territory</Link>
              <Link to={`${url}pagination`}>Pagination</Link>
              <Link to={`${url}dummy`}>Dummy</Link>
              <Link to={`${url}${RouteLink.TOWN}`}>Town</Link>
            </div>
          </div>
        </div>
      </NavigationStyled>
      <div className='section-panel'>
        <BaseContext.Provider value={history.location}>
          <Switch>
            <Route exact path={`${url}form`} component={RoutePage.Form} />
            <Route exact path={`${url}dummy`} component={RoutePage.Dummy} />
            <Route exact path={`${url}icons`} component={DemoIcon} />
            <Route exact path={`${url}badge`} component={DemoBadge} />
            <Route exact path={`${url}new/:page`} component={Error} />
            <Route exact path={`${url}alerts`} component={DemoAlert} />
            <Route exact path={`${url}button`} component={DemoButton} />
            <Route exact path={`${url}checkbox`} component={DemoCheckbox} />
            <Route exact path={`${url}pagination`} component={RoutePage.SamplePagination} />
            <Route exact path={`${url}color-palette`} component={DemoColorPalette} />
            <Route exact path={`${url}${RouteLink.DASHBOARD}`} component={RoutePage.Dashboard} />
            <Route exact path={`${url}${RouteLink.DSE_DETAIL}/:id/route-id/:routeId`} component={RoutePage.DSEDetail} />
            <Route exact path={`${url}${RouteLink.BU}`} component={RoutePage.BusinessUnit} />
            <Route exact path={`${url}${RouteLink.DSR}`} component={RoutePage.Town} />
            <Route exact path={`${url}${RouteLink.TOWN}`} component={RoutePage.Town} />
            <Route exact path={`${url}${RouteLink.CHANNEL}`} component={RoutePage.Channel} />
            <Route exact path={`${url}${RouteLink.CHANNEL_DETAILS}/:id`} component={RoutePage.ChannelDetails} />
            <Route exact path={`${url}${RouteLink.TERRITORY}`} component={RoutePage.Territory} />
            <Route exact path={`${url}${RouteLink.USER_ROLE}`} component={RoutePage.UserRole} />
            <Route exact path={`${url}${RouteLink.USER_ROLE_CREATE}`} component={RoutePage.UserRoleDetails} />
            <Route exact path={`${url}${RouteLink.USER_ROLE_DETAILS}/:id`} component={RoutePage.UserRoleDetails} />
            <Route exact path={`${url}${RouteLink.USER_ACCOUNT}`} component={RoutePage.UserAccount} />
            <Route exact path={`${url}${RouteLink.USER_CREATE}`} component={RoutePage.UserAccountDetail} />
            <Route exact path={`${url}${RouteLink.USER_ACCOUNT}/:id`} component={RoutePage.UserAccountDetail} />
            <Route exact path={`${url}${RouteLink.DESIGNATION}`} component={RoutePage.Designation} />
            <Route exact path={`${url}${RouteLink.SKU}`} component={RoutePage.Sku} />
            <Route exact path={`${url}${RouteLink.SKU_CREATE}`} component={RoutePage.CreateSku} />
            <Route exact path={`${url}${RouteLink.SKU_DETAILS}/:id`} component={RoutePage.CreateSku} />
            <Route exact path={`${url}${RouteLink.GRN}`} component={RoutePage.Grn} />
            <Route exact path={`${url}${RouteLink.GRN_DETAILS}/:type/:id`} component={RoutePage.CreateGrn} />
            <Route exact path={`${url}${RouteLink.GRN_CREATE}`} component={RoutePage.CreateGrn} />
            <Route exact path={`${url}${RouteLink.GRN_DETAILS}/:id`} component={RoutePage.CreateGrn} />
            <Route exact path={`${url}${RouteLink.ORDER_FULFILMENT}`} component={RoutePage.OrderDeliveryProcessing} />
            <Route exact path={`${url}${RouteLink.PROMOTION}`} component={RoutePage.Promotion} />
            <Route exact path={`${url}${RouteLink.PROMOTION_DETAILS}/:id`} component={RoutePage.PromotionDetails} />
            <Route exact path={`${url}${RouteLink.PROMOTION}/create`} component={RoutePage.PromotionDetails} />
            <Route exact path={`${url}${RouteLink.ORDER_INVOICE}`} component={RoutePage.SalesInvoice} />
            <Route exact path={`${url}${RouteLink.TARGET_ACHIEVEMENT}`} component={RoutePage.TargetAchievement} />
            <Route
              exact
              path={`${url}${RouteLink.TARGET_ACHIEVEMENT_DETAILS}/:id`}
              component={RoutePage.TargetAchievementDetails}
            />
            <Route exact path={`${url}${RouteLink.TARGET_COVERAGE}`} component={RoutePage.TargetCoverage} />
            <Route exact path={`${url}${RouteLink.TARGET_COVERAGE_DETAILS}/:id`} component={RoutePage.CoverageDetail} />
            <Route exact path={`${url}${RouteLink.TARGET_BRAND_COVERAGE}`} component={RoutePage.BrandCoverageTarget} />
            <Route
              exact
              path={`${url}${RouteLink.TARGET_BRAND_COVERAGE_DETAILS}/:id`}
              component={RoutePage.BrandCoverageDetail}
            />
            <Route exact path={`${url}${RouteLink.OUTLET}`} component={RoutePage.Outlet} />
            <Route exact path={`${url}${RouteLink.OUTLET_CREATE}`} component={RoutePage.OutletDetail} />
            <Route exact path={`${url}${RouteLink.OUTLET_DETAILS}/:id`} component={RoutePage.OutletDetail} />
            <Route exact path={`${url}${RouteLink.OUTLET_CHAIN}`} component={RoutePage.OutletChain} />
            <Route exact path={`${url}${RouteLink.OUTLET_CHAIN_CREATE}`} component={RoutePage.OutletChainDetails} />
            <Route exact path={`${url}${RouteLink.OUTLET_CHAIN_DETAILS}/:id`} component={RoutePage.OutletChainDetails} />
            <Route exact path={`${url}${RouteLink.ROUTE}`} component={RoutePage.Route} />
            <Route exact path={`${url}${RouteLink.ROUTE_CREATE}`} component={RoutePage.RouteDetail} />
            <Route exact path={`${url}${RouteLink.ROUTE_DETAILS}/:id`} component={RoutePage.RouteDetail} />
            <Route exact path={`${url}${RouteLink.SUB_D}`} component={RoutePage.SubD} />
            <Route exact path={`${url}${RouteLink.SUB_D_CREATE}`} component={RoutePage.CreateSubD} />
            <Route exact path={`${url}${RouteLink.SUB_D_DETAILS}/:id`} component={RoutePage.SubDDetail} />
            <Route exact path={`${url}${RouteLink.STOCK_LEDGER}`} component={RoutePage.StockLedger} />
            <Route exact path={`${url}${RouteLink.MERCHANDISE}`} component={RoutePage.MerchandiseDevice} />
            <Route exact path={`${url}${RouteLink.MERCHANDISE_CREATE}`} component={RoutePage.MerchandiseDetails} />
            <Route exact path={`${url}${RouteLink.MERCHANDISE_DETAILS}/:id`} component={RoutePage.MerchandiseDetails} />
            <Route exact path={`${url}${RouteLink.CATALOG}`} component={RoutePage.Catalog} />
            <Route exact path={`${url}${RouteLink.CATALOG}/:id`} component={RoutePage.CatalogDetailList} />
            <Route
              exact
              path={`${url}${RouteLink.CATALOG}/:catalogId/create`}
              component={RoutePage.CatalogListDetails}
            />
            <Route
              exact
              path={`${url}${RouteLink.CATALOG}/:catalogId/details/:id`}
              component={RoutePage.CatalogListDetails}
            />
            <Route exact path={`${url}${RouteLink.SRN}`} component={RoutePage.Srn} />
            <Route exact path={`${url}${RouteLink.PRN}`} component={RoutePage.Prn} />
            <Route exact path={`${url}${RouteLink.PSR}`} component={RoutePage.Psr} />
            <Route exact path={`${url}${RouteLink.PRODUCTIVITY}`} component={RoutePage.Productivity} />
            <Route exact path={`${url}${RouteLink.TAS_PRODUCTIVITY}`} component={RoutePage.TASProductivity} />
            <Route exact path={`${url}${RouteLink.RSCORE_AUDIT}`} component={RoutePage.RSCOREAUDIT} />
            <Route exact path={`${url}${RouteLink.DISTRIBUTION_COVERAGE}`} component={RoutePage.distributionCoverage} />
            <Route exact path={`${url}${RouteLink.PRN_CREATE}`} component={RoutePage.CreatePrn} />
            <Route exact path={`${url}${RouteLink.PRN_DETAILS}/:id`} component={RoutePage.PrnDetails} />
            <Route exact path={`${url}${RouteLink.SRN_CREATE}`} component={RoutePage.CreateSrn} />
            <Route exact path={`${url}${RouteLink.SRN_DETAILS}/:id`} component={RoutePage.SrnDetails} />
            <Route exact path={`${url}${RouteLink.SRN_PENDING}/:id`} component={RoutePage.SRNPendingDetails} />
            <Route exact path={`${url}${RouteLink.LEAVE_MANAGEMENT}`} component={RoutePage.LeaveManagement} />
            <Route exact path={`${url}${RouteLink.CALL_ROSTER}`} component={RoutePage.CallRoster} />
            <Route exact path={`${url}${RouteLink.SALES_FORCE_ROSTER}`} component={RoutePage.SalesForceRoster} />
            <Route exact path={`${url}${RouteLink.SBD}`} component={RoutePage.Sbd} />
            <Route exact path={`${url}${RouteLink.MT}`} component={RoutePage.MT} />
            <Route exact path={`${url}${RouteLink.MT_BU}`} component={RoutePage.MtBu} />
            <Route exact path={`${url}${RouteLink.MT_SETTINGS}`} component={RoutePage.MtSetting} />
            <Route exact path={`${url}${RouteLink.MT_FUNDAMENTALS}`} component={RoutePage.MtFundamentals} />
            <Route exact path={`${url}${RouteLink.MT_DETAILS}/:id`} component={RoutePage.MTDetails} />
            <Route exact path={`${url}${RouteLink.MT_INVENTORIES}`} component={RoutePage.MTInventories} />
            <Route exact path={`${url}${RouteLink.MT_BU}`} component={RoutePage.MtBu} />
            <Route exact path={`${url}${RouteLink.MT_BU_DETAILS}/:title/:id`} component={RoutePage.BUDetails} />
            <Route exact path={`${url}${RouteLink.MT_BU_DETAILS}/:title/:id/jbp-create`} component={RoutePage.JBPCreate} />
            <Route exact path={`${url}${RouteLink.MT_BU_DETAILS}/:title/:id/jbp-detail/:jbpId`} component={RoutePage.JBPCreate} />
            <Route exact path={`${url}${RouteLink.MT_BU_DETAILS}/:title/:id/sbd-create`} component={RoutePage.SBDCreate} />
            <Route exact path={`${url}${RouteLink.MT_BU_DETAILS}/:title/:id/sbd-details/:extraId`} component={RoutePage.SBDCreate} />
            <Route exact path={`${url}${RouteLink.MT_BU_DETAILS}/:title/:id/vgp-create`} component={RoutePage.VGPDetails} />
            <Route exact path={`${url}${RouteLink.MT_BU_DETAILS}/:title/:id/vgp-details/:extraId`} component={RoutePage.VGPDetails} />
            <Route exact path={`${url}${RouteLink.VISIBILITY_AUDIT}`} component={RoutePage.VisibilityAudit} />
            <Route exact path={`${url}${RouteLink.VISIBILITY_AUDIT}/:id`} component={RoutePage.VisibilityAuditDetail} />
            <Route exact path={`${url}${RouteLink.MOQ}`} component={RoutePage.MOQ} />
            <Route exact path={`${url}${RouteLink.PURCHASE_REQUISITION_CREATE}`} component={RoutePage.PurchaseRequisition} />
            <Route exact path={`${url}${RouteLink.PURCHASE_REQUISITION_DETAILS}/:id`} component={RoutePage.PurchaseRequisitionDetails} />
            <Route exact path={`${url}${RouteLink.PURCHASE_ORDER}`} component={RoutePage.PurchaseOrder} />
            <Route exact path={`${url}${RouteLink.PURCHASE_ORDER_CREATE}`} component={RoutePage.CreatePurchaseOrder} />
            <Route exact path={`${url}${RouteLink.PURCHASE_ORDER_DETAILS}/:id`} component={RoutePage.PurchaseOrderDetails} />
            <Route exact path={`${url}${RouteLink.SBD_DETAILS}/:id`} component={RoutePage.SbdDetails} />
            <Route exact path={`${url}${RouteLink.FOCUSED_SKU}`} component={RoutePage.FocusedSku} />
            <Route exact path={`${url}${RouteLink.FOCUSED_SKU_DETAILS}/:id`} component={RoutePage.FocusedSkuDetails} />
            <Route exact path={`${url}${RouteLink.OPENING_BALANCE}`} component={RoutePage.OpeningBalance} />
            <Route exact path={`${url}${RouteLink.STOCK_ADJUSTMENT}`} component={RoutePage.StockAdjustment} />
            <Route exact path={`${url}${RouteLink.SUB_D_ASSORTMENT}`} component={RoutePage.SubDAssortment} />
            <Route
              exact
              path={`${url}${RouteLink.SUB_D_ASSORTMENT_DETAILS}/:id`}
              component={RoutePage.SubDAssortmentDetails}
            />
            <Route exact path={`${url}${RouteLink.CHANNEL_ASSORTMENT}`} component={RoutePage.ChannelAssortment} />
            <Route
              exact
              path={`${url}${RouteLink.CHANNEL_ASSORTMENT_DETAILS}/:id`}
              component={RoutePage.ChannelAssortmentDetails}
            />
            <Route exact path={`${url}${RouteLink.REPLENISHMENT}`} component={RoutePage.Replenishment} />
            <Route
              exact
              path={`${url}${RouteLink.REPLENISHMENT_DETAILS}/:id`}
              component={RoutePage.ReplenishmentDetails}
            />
            <Route
              exact
              path={`${url}${RouteLink.SUBD_STOCK_AVAILABILITY}`}
              component={RoutePage.SubDStockAvailability}
            />
            <Route
              exact
              path={`${url}${RouteLink.SUBD_REPLENISHMENT_REPORT}`}
              component={RoutePage.SubDReplenishmentReport}
            />
            <Route
              exact
              path={`${url}${RouteLink.STOCK_REPLACEMENT_REPORT}`}
              component={RoutePage.StockReplacementReport}
            />
            <Route exact path={`${url}${RouteLink.ARC}`} component={Main} />
            <Route exact path={`${url}${RouteLink.TARGET_TOOLS}`} component={TTMain} />
            <Route exact path={`${url}${RouteLink.ASM_REPORT}`} component={RoutePage.ASMReport} />
            <Route exact path={`${url}${RouteLink.STL_REPORT}`} component={RoutePage.STLReport} />
            <Route exact path={`${url}${RouteLink.DSE_REPORT}`} component={RoutePage.DSEReport} />
            <Route exact path={`${url}${RouteLink.TAS_REPORT}`} component={RoutePage.TASReport} />
            <Route exact path={`${url}${RouteLink.USER_CLUSTER}`} component={RoutePage.UserCluster} />
            <Route exact path={`${url}image-upload`} component={RoutePage.ImageUploadTestPage} />
            <Route exact path={`${url}${RouteLink.PRODUCTIVE_COVERAGE}`} component={RoutePage.ProductiveCoverage} />
            <Route
              exact
              path={`${url}${RouteLink.PRODUCTIVE_COVERAGE_DETAIL}/:id`}
              component={RoutePage.ProductiveCoverageDetails}
            />
            <Route exact path={`${url}${RouteLink.SKU_COVERAGE}`} component={RoutePage.SKUCoverage} />
            <Route exact path={`${url}${RouteLink.SKU_DISTRIBUTION}/:id`} component={RoutePage.SKUDistribution} />
            <Route
              exact
              path={`${url}${RouteLink.SKU_DISTRIBUTION_DETAILS}/:id`}
              component={RoutePage.SKUDistributionDetails}
            />
            <Route exact path={`${url}${RouteLink.BRAND_COVERAGE}`} component={RoutePage.BrandCoverage} />
            <Route exact path={`${url}${RouteLink.CALLAGE}`} component={RoutePage.Callage} />
            <Route
              exact
              path={`${url}${RouteLink.TARGET_ACHIEVEMENT_REPORT}`}
              component={RoutePage.TargetAchievementReport}
            />
            <Route exact path={`${url}testSelect`} component={RoutePage.testSelect} />
            <Route exact path={`${url}${RouteLink.RECEIPTS}`} component={RoutePage.Receipt} />
            <Route exact path={`${url}${RouteLink.RECEIPTS}/create`} component={RoutePage.ReceiptDetails} />
            <Route exact path={`${url}${RouteLink.CHEQUES}`} component={RoutePage.Cheques} />
            <Route exact path={`${url}${RouteLink.CASH}`} component={RoutePage.Cash} />
            <Route exact path={`${url}${RouteLink.POST_DATED_CHEQUES}`} component={RoutePage.PostDatedCheques} />
            <Route exact path={`${url}${RouteLink.PFD_SALES_TARGET}`} component={RoutePage.PFDSalesTarget} />
            <Route exact path={`${url}${RouteLink.CLASSIFICATION}`} component={RoutePage.Classification} />
            <Route exact path={`${url}${RouteLink.OUTSTANDINGS}`} component={RoutePage.Outstandings} />
            <Route exact path={`${url}${RouteLink.ORDER_SUMMARY_REPORT}`} component={RoutePage.OrderSummaryReport} />
            <Route exact path={`${url}${RouteLink.BRAND_COVERAGE}/:id`} component={RoutePage.BrandDistribution} />
            <Route
              exact
              path={`${url}${RouteLink.BRAND_COVERAGE}/:id/details/:id`}
              component={RoutePage.BrandDistributionDetails}
            />
            <Route exact path={`${url}${RouteLink.PROFILE}`} component={RoutePage.UserProfile} />
            <Route exact path={`${url}${RouteLink.ROUTE_SEGMENTATION}`} component={RoutePage.RouteSegmentation} />
            <Route
              exact
              path={`${url}${RouteLink.ROUTE_SEGMENTATION_DETAILS}/:id`}
              component={RoutePage.RouteSegmentationDetail}
            />
            <Route exact path={`${url}${RouteLink.PUSH_MESSAGE}`} component={RoutePage.PushMessage} />
            <Route exact path={`${url}${RouteLink.PUSH_MESSAGE_CREATE}`} component={RoutePage.PushMessageDetails} />
            <Route
              exact
              path={`${url}${RouteLink.PUSH_MESSAGE_DETAILS}/:id`}
              component={RoutePage.PushMessageDetails}
            />
            <Route exact path={`${url}${RouteLink.PUSH_MESSAGE_CREATE}/:id`} component={RoutePage.PushMessageDetails} />
            <Route exact path={`${url}${RouteLink.WAREHOUSE_LEVELS}`} component={RoutePage.WarehouseLevels} />
            <Route exact path={`${url}${RouteLink.WAREHOUSE}`} component={RoutePage.Warehouse} />
            <Route exact path={`${url}${RouteLink.CATEGORY_CHANGE_REQUEST}`} component={RoutePage.CategoryRequest} />
            <Route exact path={`${url}${RouteLink.ROUTE_TRANSFER}`} component={RoutePage.RouteTransfer} />
            <Route exact path={`${url}${RouteLink.GEO_LOCATION}`} component={RoutePage.GeoLocation} />
            <Route exact path={`${url}${RouteLink.DE_BOARD_OUTLET}`} component={RoutePage.DeBoardOutlet} />
            <Route exact path={`${url}${RouteLink.TIME_SPEND_ON_ROUTE}`} component={RoutePage.TimeSpendOnRoute} />
            <Route exact path={`${url}${RouteLink.ATTENDANCE}`} component={RoutePage.Attendance} />
            <Route exact path={`${url}${RouteLink.MERCHANDISE_REPORT}`} component={RoutePage.MerchandiseReport} />
            <Route exact path={`${url}${RouteLink.LOCATION_HIERARCHY}`} component={RoutePage.LocationHierarchy} />
            <Route
              exact
              path={`${url}${RouteLink.LOCATION_HIERARCHY_DETAILS}`}
              component={RoutePage.LocationHierarchyDetails}
            />
            <Route exact path={`${url}${RouteLink.SYSTEM_ALERT}`} component={RoutePage.SystemAlert} />
            <Route exact path={`${url}${RouteLink.SYSTEM_ALERT_CREATE}`} component={RoutePage.SystemAlertDetails} />
            <Route
              exact
              path={`${url}${RouteLink.SYSTEM_ALERT_DETAILS}/:id`}
              component={RoutePage.SystemAlertDetails}
            />
            <Route exact path={`${url}${RouteLink.CAMPAIGNS}`} component={RoutePage.Campaigns} />
            <Route exact path={`${url}${RouteLink.CAMPAIGNS_DETAILS}/:id`} component={RoutePage.CampaignsDetails} />
            <Route exact path={`${url}${RouteLink.CAMPAIGNS}/create`} component={RoutePage.CampaignsDetails} />
            <Route exact path={`${url}${RouteLink.SUB_CAMPAIGN}/:id`} component={RoutePage.SubCampaignDetails} />
            <Route exact path={`${url}${RouteLink.GRN_REPORT}`} component={RoutePage.GRNReport} />
            <Route exact path={`${url}${RouteLink.PRESENTATION}`} component={RoutePage.Presentation} />
            <Route exact path={`${url}${RouteLink.ICO}`} component={RoutePage.ICO} />
            <Route
              exact
              path={`${url}${RouteLink.DISTRIBUTOR_SALES_TARGET}`}
              component={RoutePage.DistributorSalesTarget}
            />
            <Route
              exact
              path={`${url}${RouteLink.DISTRIBUTOR_SALES_TARGET_DETAILS}/:id`}
              component={RoutePage.DistributorSalesTargetDetails}
            />
            <Route exact path={`${url}${RouteLink.CALL_PLAN}`} component={RoutePage.CallPlan} />
            <Route exact path={`${url}${RouteLink.CALL_PLAN}/:id`} component={RoutePage.CallPlanDetails} />
            <Route exact path={`${url}${RouteLink.TAS_OUTLET}`} component={RoutePage.Outlets} />
            <Route exact path={`${url}${RouteLink.TAS_DASHBOARD}`} component={RoutePage.TASDashboard} />
            <Route exact path={`${url}${RouteLink.TAS_USERDETAIL_DASHBOARD}`} component={RoutePage.Report} />
            <Route
              exact
              path={`${url}${RouteLink.TAS_USERDETAIL_DASHBOARD}/:id`}
              component={RoutePage.tasUserDetails}
            />
            <Route exact path={`${url}${RouteLink.TAS_CALLAGE}`} component={RoutePage.tasCallage} />
            <Route exact path={`${url}${RouteLink.TAS_PFD_SALES_TARGET}`} component={RoutePage.tasPFDReports} />
            <Route exact path={`${url}${RouteLink.OUTLET_TARGET}`} component={RoutePage.OutletTargets} />
            <Route exact path={`${url}${RouteLink.OUTLET_TARGET_SUBD}`} component={RoutePage.OutletTargetsSubd} />
            {/* <Route exact path={`${url}${RouteLink.FUNDAMENTAL_USER_TARGET}`} component={RoutePage.fundamentalUserTargets} />
            <Route exact path={`${url}${RouteLink.FUNDAMENTAL_USER_TARGET_DETAILS}`} component={RoutePage.fundamentalUserTargetsDetails} /> */}
            <Route exact path={`${url}${RouteLink.DATA_MASK}`} component={RoutePage.dataMask} />

            <Route exact path={`${url}${RouteLink.RSCORE}`} component={RoutePage.Rscore} />
            <Route exact path={`${url}${RouteLink.RSCORE_REPORTS}`} component={RoutePage.RscoreReports} />
            <Route exact path={`${url}${RouteLink.VGP_REPORT}`} component={RoutePage.VGPReport} />
            <Route exact path={`${url}${RouteLink.FUNDAMENTAL_WISE_IMAGE}`} component={RoutePage.rScorefundamentalImages} />

            <Route
              exact
              path={`${url}${RouteLink.OUTLET_TARGET_SUBD_DETAILS}/:id`}
              component={RoutePage.OutletTargetsSubdDetails}
            />
            <Route exact path={`${url}${RouteLink.USER_TARGET}`} component={RoutePage.UserTargets} />
            <Route exact path={`${url}${RouteLink.USER_TARGET_DETAILS}/:id`} component={RoutePage.UserTargetsDetails} />

            <Route exact path={`${url}${RouteLink.ERR_BASE}/:id`} component={ErrorPage} />
            <Route render={() => <Redirect to={`${url}${RouteLink.ERR_BASE}/404`} />} />
          </Switch>
        </BaseContext.Provider>
      </div>
    </BaseWrapper>
  );
};

export default Base;
