const SOS = 'ShareOfShelf';
const SOA = 'ShareOfAvailability';

const labelMappings = {
  ShareOfShelf: {
    chart: 'chart',
    category: 'category',
    pointsAllocated: 'pointsAllocated',
    pointsScored: 'pointsScored',
    gap: 'gap',
    bonusAwarded: 'bonusAwarded',
    compliance: 'compliance',
  },
  ShareOfAvailability: {
    chart: 'chart',
    pointsAllocated: 'pointsAllocated',
    pointsScored: 'pointsScored',
    gap: 'gap',
    bonusAwarded: 'bonusAwarded',
    compliance: 'compliance',
  },
};

const labelConfig = {
  ShareOfShelf: [
    {
      id: 1,
      label: labelMappings[SOS].chart,
      title: 'Chart',
      show: true,
      align: 'text-left',
    },
    {
      id: 2,
      label: labelMappings[SOS].category,
      title: 'Category',
      show: true,
      align: 'text-left',
    },
    {
      id: 3,
      label: labelMappings[SOS].pointsAllocated,
      title: 'Points Allocated',
      show: true,
      align: 'text-right',
    },
    {
      id: 4,
      label: labelMappings[SOS].pointsScored,
      title: 'Points Scored',
      show: true,
      align: 'text-right',
    },
    {
      id: 5,
      label: labelMappings[SOS].gap,
      title: 'Gap',
      show: true,
      align: 'text-right',
    },
    {
      id: 6,
      label: labelMappings[SOS].bonusAwarded,
      title: 'Bonus Awarded',
      show: true,
      align: 'text-right',
    },
    {
      id: 7,
      label: labelMappings[SOS].compliance,
      title: 'Compliance',
      show: true,
      align: 'text-middle',
    },
  ],
  ShareOfAvailability: [
    {
      id: 1,
      label: labelMappings[SOA].chart,
      title: 'Chart',
      show: true,
      align: 'text-left',
    },
    {
      id: 3,
      label: labelMappings[SOA].pointsAllocated,
      title: 'Points Allocated',
      show: true,
      align: 'text-right',
    },
    {
      id: 4,
      label: labelMappings[SOA].pointsScored,
      title: 'Points Scored',
      show: true,
      align: 'text-right',
    },
    {
      id: 5,
      label: labelMappings[SOA].gap,
      title: 'Gap',
      show: true,
      align: 'text-right',
    },
    {
      id: 6,
      label: labelMappings[SOA].bonusAwarded,
      title: 'Bonus Awarded',
      show: true,
      align: 'text-right',
    },
    {
      id: 7,
      label: labelMappings[SOA].compliance,
      title: 'Compliance',
      show: true,
      align: 'text-middle',
    },
  ],
};

const legendBBSM = [
  {
    title: 'POINT SCORED',
    color: '#0b5fff',
  },
  {
    title: 'GAP',
    color: '#f0f5ff',
  },
];

const legendBigMart = [
  {
    title: 'POINT SCORED',
    color: '#f27024',
  },
  {
    title: 'GAP',
    color: '#f9eecf',
  },
];

const legendSalesways = [
  {
    title: 'POINT SCORED',
    color: '#43c187',
  },
  {
    title: 'GAP',
    color: '#d9f2e7',
  },
];

const getLegend = outlet_channel => {
  const data = {
    BBSM: legendBBSM,
    BigMart: legendBigMart,
    Salesways: legendSalesways,
  };
  return data[outlet_channel];
};

const tableHeaderChange = (array) => {
  const newArray = array?.map(obj => (obj.label === 'category' ? { ...obj, title: 'Brand' } : obj));
  return newArray;
};

export { labelMappings, labelConfig, SOS, SOA, getLegend, tableHeaderChange };
