import React from 'react';
import { Button, CheckBox } from '../../../v4/components';
import { normalPresentor } from '../../../utils/date';
import SubCampaignCard from '../../components/Card/SubCampaignCard';
import { SCOPE_LEVEL } from './config';
import SubCampaignStyle from './SubCampaignStyle';
import TopDetail from './TopDetail';

const View = ({ ...props }) => {
  const {
    data,
    typeList,
    onButtonClick,
    dropdownToggle,
    showLevel,
    getLevelValue,
    selectedList,
    selectedColumn,
    onCheckboxChange,
    columnList,
    onIconClick,
    setWrapperRef,
    cardData,
    onColumnDetailsCardClick,
    binId,
    onActionClick,
    scopeHierarcy,
  } = props;

  const getScopeView = () =>
    Object.keys(scopeHierarcy).map((item) => {
      if (scopeHierarcy[item] === SCOPE_LEVEL.CATALOG) {
        return columnList.CATALOG.map((columnDetail, index) => (
          <SubCampaignCard
            selectedId={binId[columnDetail.label]}
            parentList={columnDetail}
            data={columnDetail.list}
            handleIconClick={onIconClick}
            onItemClick={onColumnDetailsCardClick}
            title={columnDetail.title}
            onActionClick={onActionClick}
            cardIndex={index}
            selectedColumn={columnList}
            scopeHierarcy={scopeHierarcy}
          />
        ));
      }
      return columnList.CLASSIFICATION.map(
        (columnDetail, index) =>
          columnList.CLASSIFICATION && (
            <SubCampaignCard
              selectedId={binId[columnDetail.label]}
              parentList={columnDetail}
              data={columnDetail.list}
              handleIconClick={onIconClick}
              onItemClick={onColumnDetailsCardClick}
              title={columnDetail.title}
              onActionClick={onActionClick}
              cardIndex={index}
              selectedColumn={columnList}
              scopeHierarcy={scopeHierarcy}
            />
          ),
      );
    });
  return (
    <SubCampaignStyle>
      <div className='detail-section'>
        <TopDetail label='Title' value={data.title} />
        <TopDetail label='Sub Campaign Type' value={data.subCampaignType} />
        <TopDetail label='Budget' value={data.subCampaignBudget} subType='budgetType' icon />
        <TopDetail label='Target' value={data.subCampaignTarget} subType='targetType' icon />
        <TopDetail label='Start Date' value={normalPresentor(data.startDate)} />
        <TopDetail label='End Date' value={normalPresentor(data.endDate)} />
      </div>
      <div className='column-section'>
        <div className='top-column'>
          <Button small secondary onClick={() => onButtonClick()} iconName='plus' title='Add Scope' />
          {dropdownToggle && (
            <div className='dropdown' ref={(node) => setWrapperRef(node)}>
              <ul className='dropdown-menu'>
                {typeList.map((list, index) => (
                  <li
                    key={index}
                    onClick={() => getLevelValue(index, list.type)}
                    className={selectedColumn.id === index ? 'active' : ''}
                  >
                    {list.type.toLowerCase()}
                  </li>
                ))}
              </ul>
              {showLevel && (
                <div className='type-level'>
                  <ul>
                    {selectedList.map((d) => (
                      <>
                        <li>
                          <CheckBox
                            labelText={d.title}
                            onCheckBoxClick={() => {
                              onCheckboxChange(d.label, d);
                            }}
                            checked={columnList.ids.includes(d.label)}
                          />
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>

        <div className='card-wrapper'>{getScopeView()}</div>
      </div>
    </SubCampaignStyle>
  );
};

export default View;
