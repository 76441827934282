import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import {
  title, breadCrumb, REQUISITION_VIEW, newList, formConfig,
} from './config';
import withAlert from '../../../../../utils/composition/withAlert';
import * as queryService from '../../../../base/query.service';
import { PanelHeader, PanelStyled } from '../../../../common/configuration';
import { BreadCrumb, Button } from '../../../../../components';
import { getStartOfMonth, getTodaysDate } from '../../../../../utils/date';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../../data/services';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { DialogFormWrapper } from '../../../../common';
import Dialog from './Form';
import { getTotalSum, groupBy, imageSliderListFormatter } from '../../../../common/HelperFunctions';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import history from '../../../../../utils/history';
import { MOQ } from '../../../../../data/enums/Route';
import RequisitionStyled from './RequisitionStyle';
import withImageSlider from '../../../../../utils/composition/withImageSlider';
import { extractSingleItemFromList } from '../../../../../utils/arrayProcessor';
import { checkDocument } from 'apollo-utilities';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getBuList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
  createPurchaseRequisitionOrder: PropTypes.func.isRequired,
  editOrdersForPurchaseRequisition: PropTypes.func.isRequired,
  getOrdersForPurchaseRequisition: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class PurchaseRequisition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantityValidation: false,
      data: {
        list: [],
      },
      menu: {
        channel: [],
        bu: [],
      },
      dialog: {
        type: '',
        element: '',
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        date: { ...queryService.baseQueryParameters.date },
        type: REQUISITION_VIEW.BRAND,
      },
      date: {
        start: getStartOfMonth(),
        end: getTodaysDate(),
      },
      select: {
        channelId: null,
        buId: null,
      },
      dataListLength:0,
      checkedList:[],
      checkedListLength:0,
      allItemsBeforeManuplation:[],
      selectedData:[],
      dataToOrder:[],
    };

    const { displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      displayAlert,
    );
  }

  componentDidMount() {
    this.loadDataForDropDown();
  }

  loadTableData=() => {
    const { select, data, date } = this.state;
    const { getOrdersForPurchaseRequisition, displayAlert } = this.props;

    getOrdersForPurchaseRequisition({
      channelId: select.channelId,
      buId: select.buId,
      dateRange: date,
    }, {
      handleSuccess: (res) => {
        const response = res.data.getOrdersForPurchaseRequisition || [];
        const allItemsBeforeManuplation = response;
        const groupedArray = groupBy(response, this.locationName);
        const keys = ['group', 'skuList'];
        const order = groupedArray.map(r => (keys.reduce((o, k, i) => (o[k] = r[i], o), {})));
        const orderWithImage = order.map(list => ({
          ...list,
          skuList: list.skuList.map(sku => ({
            ...sku,
            orderDetails: sku.orderDetails.map(order => ({
              ...order,
              images: order.images.map((img) => {
                if (img.callImages) {
                  img.callImages = [...(imageSliderListFormatter(img.callImages) || [])];
                }
                return img;
              }),
            })),
          })),
        }));
        data.list = orderWithImage;
        this.setState({ data,dataListLength:response.length,allItemsBeforeManuplation});
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },
    });
  }
prepareData = (selectedData) => {
 const {dataToOrder} = this.state;
  const groupedArray = groupBy(selectedData, this.locationName);
        const keys = ['group', 'skuList'];
        const order = groupedArray.map(r => (keys.reduce((o, k, i) => (o[k] = r[i], o), {})));
        const orderWithImage = order.map(list => ({
          ...list,
          skuList: list.skuList.map(sku => ({
            ...sku,
            orderDetails: sku.orderDetails.map(order => ({
              ...order,
              images: order.images.map((img) => {
                if (img.callImages) {
                  img.callImages = [...(imageSliderListFormatter(img.callImages) || [])];
                }
                return img;
              }),
            })),
          })),
        }));
        dataToOrder.list = orderWithImage;
        this.setState({ dataToOrder});
}
  
  loadDataForDropDown = () => {
    const { getBuList, displayAlert } = this.props;
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.PARENT_OUTLETS).then((response) => {
      menu.channel = response;
      this.setState({ menu });
    });
    getBuList(
      {
        includeSku: false,
        catalogId: 1,
        offset: 0,
        limit: 1000,
      },
      {
        handleSuccess: (response) => {
          menu.bu = (response.data.catalogDetails
              && response.data.catalogDetails.rows)
            || [];
          this.setState({ menu });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  setQueryParameters = (queryParams, callBack = () => null) => {
    this.setState({ queryParameters: queryParams }, callBack);
  };

  handleDropDownChange = (value, name) => {
    const { select } = this.state;
    select[name] = value;
    this.setState(select);
    if (name === 'buId') {
      this.loadTableData();
    } else {
      select.buId = null;
      this.setState({ select, data: { list: [] } });
    }
  }

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  handleIconClick = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
      quantityValidation: false,
    });
  };

  handleFormSubmit=(type, dialogData) => {
    const { editOrdersForPurchaseRequisition, displayAlert } = this.props;
    const input = dialogData.orderDetails.map(order => ({
      orderId: order.orderId,
      quantity: order.cases * order.upc + order.pieces,
    }));
    if (input[0].quantity === 0) {
      this.setState({ quantityValidation: true });
    } else {
      this.setState({ quantityValidation: false });
      editOrdersForPurchaseRequisition({
        input,
      }, {
        handleSuccess: () => {
          this.loadTableData();
          displayAlert(ALERT_TYPE.SUCCESS, `Order ${MESSAGE_EVENT_OPERATION.UPDATE}`);
          this.resetDialog();
        },
        handleError: (err) => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      });
    }
  }

  locationName = (obj) => {
    const { queryParameters } = this.state;
    if (queryParameters.type === REQUISITION_VIEW.BRAND) {
      return obj.Brand.title;
    }
  };
  extractSingleItemFromList = (list = [], attribute = 'skuId') => (list.map(item => item[attribute]));
  handlePrimaryCheckboxClick = (e) => {
    const { allItemsBeforeManuplation } = this.state;
    let checkedList = [];
    if (e.target.checked) {
      checkedList = this.extractSingleItemFromList(allItemsBeforeManuplation);
      this.setState({ checkedList,checkedListLength:checkedList.length });
    } else {
      this.setState({ checkedList: [],checkedListLength:0 });
    }
    const selData = allItemsBeforeManuplation.filter(x=> checkedList.includes(x.skuId))
    this.setState({selectedData:selData});
  };

  handleSecondaryCheckboxClick = (id) => {
    const { checkedList,allItemsBeforeManuplation } = this.state;
    const index = checkedList.indexOf(id);
    if (index > -1) {
      checkedList.splice(index, 1);
    } else {
      checkedList.push(id);
    }
    this.setState({ checkedList,checkedListLength:checkedList.length });
    const selData = allItemsBeforeManuplation.filter(x=> checkedList.includes(x.skuId))
    this.setState({selectedData:selData});
  };

  handleOrderSubmit=() => {
    const { dataToOrder, select,selectedData } = this.state;
    this.prepareData(selectedData);
    const { createPurchaseRequisitionOrder, displayAlert } = this.props;
    const orderIds = dataToOrder.list.map(sku => sku.skuList.map(list => list.orderDetails.map(order => order.orderId)).flat()).flat();
    const amountDetails = dataToOrder.list.map(sku => sku.skuList.map(list => list.amountDetails)).flat();
    const outletChainId = dataToOrder.list.map(sku => sku.skuList.map(list => list.outletChainId)).flat()[0];
    const totalAmountDetails = {
      rate: getTotalSum(amountDetails, 'rate'),
      grossAmount: getTotalSum(amountDetails, 'grossAmount'),
      taxableAmount: getTotalSum(amountDetails, 'taxableAmount'),
      netAmount: getTotalSum(amountDetails, 'netAmount'),
      taxAmount: getTotalSum(amountDetails, 'taxAmount'),
      discountAmount: getTotalSum(amountDetails, 'discountAmount'),
      billDiscount: getTotalSum(amountDetails, 'billDiscount'),
      promotionDiscount: getTotalSum(amountDetails, 'promotionDiscount'),
      topUpDiscount: getTotalSum(amountDetails, 'topUpDiscount'),
      subTotal: getTotalSum(amountDetails, 'subTotal'),
      tradeDiscount: getTotalSum(amountDetails, 'tradeDiscount'),
    };
    createPurchaseRequisitionOrder(
      {
        input: {
          buId: select.buId,
          channelId: select.channelId,
          amountDetails: totalAmountDetails,
          orderIds,
          outletId: outletChainId,
        },
      }, {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`);
          setTimeout(() => {
            history.push(`/${MOQ}`);
          }, 900);
        },
        handleError: (err) => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  }

  handleButtonCancel=() => {
    history.push(`/${MOQ}`);
  }

  resetQntValidation=() => {
    this.setState({ quantityValidation: false });
  }

  render() {
    const {
      quantityValidation, data, menu, date, select, queryParameters, dialog,dataListLength,checkedList,checkedListLength
    } = this.state;
    const { type, element } = dialog;
    const { serverResponseWaiting, handleSliderOpen } = this.props;
    return (
      <>
        <div className="section-header">

          <PanelStyled>
            <BreadCrumb list={breadCrumb} />
            <PanelHeader>
              <h2>
                {`Create ${title}`}
              </h2>
              <div className="flex m-0">
                {(
                  <div>
                    <Button
                      small
                      secondary
                      disabled={serverResponseWaiting}
                      onClick={() => this.handleButtonCancel()}
                    >
                      <span>Cancel</span>
                    </Button>
                    <Button
                      small
                      primary
                      disabled={checkedListLength === 0}
                      onClick={() => this.handleOrderSubmit()}
                    >
                      <span>Order</span>
                    </Button>
                  </div>
              )}
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className="section-content">
          <RequisitionStyled>
            {type && (
            <DialogFormWrapper
              formConfig={formConfig[type]}
              dialogElement={element}
              onDialogSubmit={this.handleFormSubmit}
              onDialogCancel={this.resetDialog}
              withOutPadding
              type={type}
              renderDialog={({
                dialogData, handleInputChange, refsObj,
              }) => (
              <>
                {type && (
                  <Dialog
                    data={dialogData}
                    handleInputChange={handleInputChange}
                    handleSliderOpen={handleSliderOpen}
                    quantityValidation={quantityValidation}
                    refsObj={refsObj}
                    resetQntValidation={this.resetQntValidation}
                  />
                )}
              </>
              )}
            />
            )}
            <View
              data={data}
              date={date}
              dataListLength={dataListLength}
              menu={menu}
              select={select}
              viewType={queryParameters.type}
              loading={serverResponseWaiting}
              viewTypeConfig={{
                value: queryParameters.type,
                list: newList,
              }}
              onIconClick={this.handleIconClick}
              onDropDownChange={this.handleDropDownChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleViewTypeChange={this.basePaginationService.handleViewTypeChange}
              primaryCheckboxHandler={this.handlePrimaryCheckboxClick}
              checkedList={checkedList}
              onSecondaryCheckBoxClick={this.handleSecondaryCheckboxClick}
              checkedListLength={checkedListLength}
            />
          </RequisitionStyled>
        </div>
      </>
    );
  }
}

PurchaseRequisition.propTypes = propTypes;

PurchaseRequisition.defaultProps = defaultProps;

const PurchaseRequisitionWithImageSlider = withImageSlider(PurchaseRequisition);

export default withAlert()(PurchaseRequisitionWithImageSlider);
