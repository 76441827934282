import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RosterView from './View';
import { getTodaysDate } from '../../../utils/date';
import RosterStyled from './RosterStyled';
import { BreadCrumb,Button } from '../../../v4/components';
import DialogProcessor from './RosterDialogProcessor';
import * as rosterService from './Common/roster.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { CALENDAR_PREVIEW, USER_ROLE } from '../../../data/enums';
import { LIMIT, OFFSET } from '../../../data/enums/GeneralConstants';
import { MAX_LIMIT_OFFSET_FILTER } from '../../../data/enums/filter';
import { PanelHeader, PanelStyled } from '../../common/configuration';
import { HEADER_ICONS, breadCrumbConfig as breadCrumb,title } from './config';
import { getUser } from '../../../data/services';
import {
  getDateList,
  getCurrentWeek,
  getCurrentMonth,
  getWeekStartDayFromWeekEnds,
  WEEK_DAYS,
  weekEndSundayResolver,
} from './date';
import { ADMIN_ROLE_GROUP,DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';

const propTypes = {
  create: PropTypes.func,
  replicate: PropTypes.func,
  update: PropTypes.func,
  getSubDList: PropTypes.func.isRequired,
  getSubDHolidays: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  getRouteList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getRouteVisitFrequencyList: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  create: () => null,
  replicate: () => null,
  update: () => null,
  serverResponseWaiting: false,
};

/**
 * Holiday: WeekEnd and Calendar Holiday
 * data: DSE Roster and LEave details
 */
class Roster extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.userInfo = getUser();
    const weekStartDay = WEEK_DAYS.SUNDAY;
    const today = getTodaysDate();
    const week = getCurrentWeek(today, weekStartDay);
    const month = getCurrentMonth();

    this.state = {
      week: {
        start: week.start,
        end: week.end,
      },
      month: {
        start: month.start,
        end: month.end,
      },
      printMode: false,
      weekStartDay,
      calendarPreview: CALENDAR_PREVIEW.WEEKLY,
      subDId: 0,
      subDDetails: {
        id: 3,
        title: '',
        address: '',
        callRosterLocked: false,
      },
      townIdList: [],
      userId: null,
      weekEndList: [WEEK_DAYS.SATURDAY],
      holidayList: [],
      userList: [],
      subDList: [],
      routeList: [],
      leaveList: [],
      rosterList: [],
      routeVisitFrequencyList: [],
      displayReplicationDialog: false,
      callRosterPermission:{},
    };
  }

  componentDidMount() {
      this.loadRouteVisitFrequencyList();
      this.loadSubDRouteAndUserList();
    // this.loadFullRosterDataFirstTime();
    const permissions= JSON.parse(localStorage.getItem('-rosia-permission'));
    const callRosterPermission =permissions.find(a=>a.program === title).permission;
    this.setState({callRosterPermission});
    window.onafterprint = this.handleAfterPrint;
  }

  handleAfterPrint = () => {
    this.setState({ printMode: false });
  };

  loadSubDRouteAndUserList = () => {
    const { getSubDList } = this.props;
    const { subDDetails } = this.state;
    getSubDList(
      {
        ...MAX_LIMIT_OFFSET_FILTER,
      },
      {
        handleSuccess: res => {
          const adminExist = ADMIN_ROLE_GROUP.includes(this.userInfo.roleId) ||  DISTRIBUTOR_ASSOCIATED_ROLES.includes(this.userInfo.roleId);
          const subDList = res.data.distributors.rows;
          adminExist && subDList.splice(0, 0, {id:0,title:'All'});
          this.setState({ subDList });
          if (subDList?.length > 0) {
            // auto select on first sub D
            const selectedSubD = subDList[0];
            const townIdList = selectedSubD?.Towns?.map(town => town?.id?.toString());
            const subDId = adminExist ? 0 : selectedSubD?.id ; // subDList[0].id;
            subDDetails.title = selectedSubD?.title;
            subDDetails.id = selectedSubD?.id;
            subDDetails.address = selectedSubD?.address || 'Katmandu';
            subDDetails.callRosterLocked = selectedSubD?.callRosterLocked;

            this.setState(
              {
                townIdList,
                subDId,
                subDDetails,
              },
              () => {
                this.loadFullDataAfterSubDTownSelection();
              },
            );
          }
        },
      },
    );
  };

  loadFullDataAfterSubDTownSelection = () => {
    this.loadRouteList();
    this.loadUserList();
    this.loadFullRosterDataFirstTime();
  };

  loadFullRosterDataFirstTime = () => {
    const { calendarPreview } = this.state;
    if (calendarPreview === CALENDAR_PREVIEW.WEEKLY) {
      this.loadHolidayForWeeklyView(this.loadRosterData, true);
    } else {
      this.loadHolidayListForMonthlyView();
      // this.loadRosterData();
    }
  };

  loadFullRosterData = () => {
    const { calendarPreview } = this.state;
    if (calendarPreview === CALENDAR_PREVIEW.WEEKLY) {
      this.loadHolidayForWeeklyView(this.loadRosterData);
    } else {
      this.loadHolidayListForMonthlyView();
      this.loadRosterData();
    }
  };

  loadHolidayForWeeklyView = (callBack = () => null, updateWeek = false) => {
    const { getSubDHolidays } = this.props;

    const { week, subDId } = this.state;
    const dateRange = updateWeek ? rosterService.weekExtension(week) : week;

    // load holidayList on the basis of weekly and Monthly View.
    getSubDHolidays(
      {
        filter: {
          filters: [
            {
              column: 'distributor_id',
              value: [subDId.toString()],
            },
          ],
          dateRange: {
            start: dateRange.start,
            end: dateRange.end,
            filterBy: 'date',
          },
        },
      },
      {
        handleSuccess: response => {
          const { weekEnds, calendarHolidays } = response.data.holidayDetails;

          const activeWeekEnds = weekEndSundayResolver(weekEnds[0] || []);

          const weekStartDay = getWeekStartDayFromWeekEnds(activeWeekEnds);

          // updateWeek based upon weekEnd
          const today = getTodaysDate();

          const currentWeek = updateWeek ? getCurrentWeek(today, weekStartDay) : week;

          // reset the week over here.

          const holidayEachDay = rosterService.holidayResolverPerDay(calendarHolidays);

          this.setState(
            {
              weekStartDay,
              week: currentWeek,
              weekEndList: activeWeekEnds,
              holidayList: holidayEachDay,
            },
            () => {
              callBack();
            },
          );
        },
        handleError: () => {},
      },
    );
  };

  loadHolidayListForMonthlyView = (callBack = () => null) => {
    const { getSubDHolidays } = this.props;

    const { month, subDId } = this.state;

    // load holidayList on the basis of weekly and Monthly View.
    getSubDHolidays(
      {
        filter: {
          filters: [
            {
              column: 'distributor_id',
              value: [subDId.toString()],
            },
          ],
          dateRange: {
            start: month.start,
            end: month.end,
            filterBy: 'date',
          },
        },
      },
      {
        handleSuccess: response => {
          const { weekEnds, calendarHolidays } = response.data.holidayDetails;

          const activeWeekEnds = weekEndSundayResolver(weekEnds[0] || []);

          const weekStartDay = getWeekStartDayFromWeekEnds(activeWeekEnds);

          const holidayEachDay = rosterService.holidayResolverPerDay(calendarHolidays);

          this.setState({
            weekStartDay,
            weekEndList: activeWeekEnds,
            holidayList: holidayEachDay,
          });
        },
        handleError: () => {},
      },
    );
    // Load holidays over here.
  };

  loadUserList = () => {
    // load all the DSE List.
    const { getUserList } = this.props;

    const { subDId } = this.state;

    getUserList(
      {
        offset: OFFSET,
        filter: {
          filters: [
            {
              column: 'distributor_id',
              value: [subDId ? subDId.toString() : '990909'],
            },
            {
              column: 'role_id',
              value: [USER_ROLE.DSE.toString()],
            },
          ],
        },
      },
      {
        handleSuccess: response => {
          const userList = response.data.users.rows;
          this.setState({ userList });
        },
        handleError: () => {},
      },
    );
  };

  loadRouteList = () => {
    // Load Route List. on the basis of Town Filter
    const { getRouteList } = this.props;

    const { townIdList } = this.state;

    if (!townIdList?.length) {
      return;
    }

    getRouteList(
      {
        offset: 0,
        filter: {
          filters: [
            {
              column: 'town_id',
              value: townIdList.map(String),
            },
          ],
        },
      },
      {
        handleSuccess: res => {
          const routeList = res.data.routes.rows;
          this.setState({ routeList });
        },
      },
    );
  };

  loadRouteVisitFrequencyList = () => {
    const { getRouteVisitFrequencyList } = this.props;

    getRouteVisitFrequencyList(
      {},
      {
        handleSuccess: res => {
          const routeVisitFrequencyList = res.data.routeVisitFrequencyList.rows;
          this.setState({ routeVisitFrequencyList });
        },
        handleFailure: () => {},
      },
    );
  };

  setCurrentWeekAndLoadData = () => {
    const { weekStartDay } = this.state;
    const today = getTodaysDate();
    const week = getCurrentWeek(today, weekStartDay);
    const month = getCurrentMonth();

    this.setState(
      {
        week,
        month,
      },
      () => this.loadFullRosterData(),
    );
  };

  onDseCalendarPick = (dseId, date) => {
    alert(dseId + date);
  };

  loadRosterData = () => {
    const { getList } = this.props;

    const { subDId, calendarPreview, week, month, userId } = this.state;

    if (!subDId || (calendarPreview === CALENDAR_PREVIEW.MONTHLY && !userId)) {
      return;
    }

    const filter = {};

    if (calendarPreview === CALENDAR_PREVIEW.MONTHLY) {
      filter.filters = [
        {
          column: 'user_id',
          value: [userId.toString()],
        },
        {
          column: 'distributor_id',
          value: [subDId.toString()],
        },
      ];
      filter.dateRange = {
        ...month,
        filterBy: 'date',
      };
    } else {
      filter.dateRange = {
        ...week,
        filterBy: 'date',
      };
      filter.filters = [
        {
          column: 'distributor_id',
          value: [subDId.toString()],
        },
      ];
    }

    getList(
      {
        filter,
      },
      {
        handleSuccess: response => {
          const { rows, leaves } = response.data.callRoster;
          const leaveListPerDay = rosterService.leaveResolverPerDay(leaves);

          this.setState({
            rosterList: rows,
            leaveList: leaveListPerDay,
          });
        },
      },
    );
  };

  handleHeaderIconClick = icon => {
    // type: weekly, monthly, today, print, save, lock
    switch (icon) {
      case HEADER_ICONS.TODAY:
        this.closeReplicationDialog();
        this.setCurrentWeekAndLoadData();
        break;
      case HEADER_ICONS.WEEKLY:
        this.closeChildrenDialog();
        this.setState({ calendarPreview: CALENDAR_PREVIEW.WEEKLY }, () => {
          this.resetRosterListAndLoadData();
        });
        break;
      case HEADER_ICONS.MONTHLY:
        this.closeChildrenDialog();
        this.setState({ calendarPreview: CALENDAR_PREVIEW.MONTHLY }, () => this.resetRosterListAndLoadData());
        break;
      case HEADER_ICONS.PRINT:
        this.closeChildrenDialog();
        this.setState({ printMode: true }, () => {
          window.print();
        });
        break;
      case HEADER_ICONS.LOCK:
        // read the current subD status;
        this.toggleRosterLockStatus();
        break;
      case HEADER_ICONS.REPLICATION:
        this.setState({ displayReplicationDialog: true });
        break;
      default:
        break;
    }
  };

  handleMonthChange = (start, end) => {
    const { month } = this.state;
    month.start = start;
    month.end = end;
    this.setState(
      {
        month,
        rosterList: [],
        leaveList: [],
      },
      () => this.loadRosterData(),
    );
  };

  handleWeekChange = (start, end) => {
    const { week } = this.state;
    week.start = start;
    week.end = end;
    this.setState(
      {
        week,
        rosterList: [],
        leaveList: [],
      },
      () => this.loadFullRosterData(),
    );
  };

  handleSubDSelect = subD => {
    const townIdList = subD?.Towns?.map(town => town?.id.toString());
    const subDId = subD?.id;

    const { subDDetails } = this.state; // subDList[0].id;
    subDDetails.title = subD?.title;
    subDDetails.id = subD?.id;
    subDDetails.address = subD?.address || 'Katmandu';
    subDDetails.callRosterLocked = subD?.callRosterLocked;

    this.setState(
      {
        subDId,
        townIdList,
        subDDetails,
        leaveList: [],
        rosterList: [],
        weekEndList: [],
        holidayList: [],
      },
      () => {
        this.loadFullDataAfterSubDTownSelection();
      },
    );
  };

  getCalendarHeader = () => {
    const { subDId, subDList } = this.state;
    const selectedSubD = subDList.find(subD => subD.id === subDId);

    const subDTitle = selectedSubD ? selectedSubD.title : '-';

    return <span>{subDTitle}</span>;
  };

  handleReplicationDialogSubmit = (sourceDateRange, destinationDateRange) => {
    const { subDId } = this.state;
    const { replicate, displayAlert,bulkReplicateCallRoster } = this.props;
    this.closeReplicationDialog();
    if(subDId !== 0){
        replicate(
          {
            input: {
              distributorId: subDId,
              sourceDateRange,
              destinationDateRange,
            },
          },
          {
            handleSuccess: () => {
              displayAlert(ALERT_TYPE.SUCCESS, 'Roster replicated');
            },
            handleError: () => {
              displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Operation Failed');
            },
          },   
        );
    }
    else{
      bulkReplicateCallRoster(
        {
            sourceDateRange,
            destinationDateRange,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, 'Roster bulk replicated');
          },
          handleError: () => {
            displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Operation Failed');
          },
        },   
      );
      
    }
  };

  closeReplicationDialog = () => {
    this.setState({ displayReplicationDialog: false });
  };

  handleUserClick = (userId, loadData = false) => {
    this.setState({ userId }, () => {
      if (loadData) {
        this.loadRosterData();
      }
    });
  };

  resetRosterListAndLoadData = () => {
    this.setState(
      {
        userId: null,
        rosterList: [],
        leaveList: [],
      },
      () => {
        this.loadRosterData();
      },
    );
  };

  closeChildrenDialog = () => {};

  toggleRosterLockStatus = () => {
    // call the server
    // update the toggle state.
    const { toggleState, displayAlert } = this.props;
    const { subDDetails, subDList } = this.state;

    subDDetails.callRosterLocked = !subDDetails.callRosterLocked;

    const data = {
      ids: [subDDetails.id],
      type: 'Distributor',
      active: subDDetails.callRosterLocked,
      fieldName: 'call_roster_lock',
    };

    toggleState(
      { ...data },
      {
        handleSuccess: () => {
          displayAlert(
            ALERT_TYPE.SUCCESS,
            `Routes successfully ${subDDetails.callRosterLocked ? 'Locked' : 'Unlocked'}`,
          );

          const updatedSubDList = subDList.map(subD => {
            if (subD.id === subDDetails.id) {
              subD.callRosterLocked = subDDetails.callRosterLocked;
            }

            return subD;
          });

          this.setState({
            subDList: updatedSubDList,
            subDDetails,
          });
        },
        handleError: () => {
          displayAlert(ALERT_TYPE.DANGER, 'Failed Operation');
        },
      },
    );
  };

  render() {
    const {
      routeVisitFrequencyList,
      calendarPreview,
      week,
      month,
      rosterList,
      weekStartDay,
      weekEndList,
      holidayList,
      subDList,
      subDId,
      userId,
      routeList,
      leaveList,
      userList,
      subDDetails,
      printMode,
      displayReplicationDialog,
      callRosterPermission,
    } = this.state;

    const { serverResponseWaiting, toggleState, create, update } = this.props;

    const dateArray = getDateList(week.start, week.end);

    const calendarHeader = this.getCalendarHeader();

    return (
      <DialogProcessor
        subDId={subDId}
        userId={userId}
        userList={userList}
        create={create}
        update={update}
        routeList={routeList}
        onUserClick={this.handleUserClick}
        loadData={this.loadRosterData}
        removeRosterLine={toggleState}
        calendarPreview={calendarPreview}
        routeVisitFrequencyList={routeVisitFrequencyList}
        displayReplicationDialog={displayReplicationDialog}
        onReplicationDialogSubmit={this.handleReplicationDialogSubmit}
        onReplicationDialogCancel={this.closeReplicationDialog}
        closeChildDialog={closeDialog => (this.closeChildrenDialog = closeDialog)}
      >
        <RosterStyled>
          <div className="section-header">
            <PanelStyled>
              <BreadCrumb list={breadCrumb} />
              <PanelHeader>
                <h2>Call Roster</h2>
                {subDDetails.callRosterLocked ? (
                  <Button
                    secondary
                    iconBtnSmall
                    iconName="lock"
                    onClick={() => this.handleHeaderIconClick(HEADER_ICONS.LOCK)}
                  />
                ) : (
                  <Button
                    secondary
                    iconBtnSmall
                    iconName="unlock"
                    onClick={() => this.handleHeaderIconClick(HEADER_ICONS.LOCK)}
                  />
                )}
              </PanelHeader>
            </PanelStyled>
          </div>
          <div className="section-content">
            <div className="roster-container">
              <RosterView
                printMode={printMode}
                activeUserId={userId}
                week={week}
                month={month}
                subDDetails={subDDetails}
                userList={userList}
                activeSubD={subDId}
                subDList={subDList}
                routeList={routeList}
                rosterData={rosterList}
                dateArray={dateArray}
                weekEndList={weekEndList}
                holidayList={holidayList}
                leaveList={leaveList}
                weekStartDay={weekStartDay}
                loading={serverResponseWaiting}
                calendarHeader={calendarHeader}
                calendarPreview={calendarPreview}
                onSubDSelect={this.handleSubDSelect}
                onMonthChange={this.handleMonthChange}
                onWeekChange={this.handleWeekChange}
                onCalendarClick={this.onDseCalendarPick}
                onHeaderIconClick={this.handleHeaderIconClick}
                callRosterPermission={callRosterPermission}
              />
              <div className="roster-legend-wrap">
                <div className="inner">
                  <ul>
                    <li>
                      <span className="daily">Daily</span>
                    </li>
                    <li>
                      <span className="weekly">Weekly</span>
                    </li>
                    <li>
                      <span className="fortnightly">Fortnightly</span>
                    </li>
                    <li>
                      <span className="monthly">Monthly</span>
                    </li>
                    <li>
                      <span className="once">Once</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span className="leave-pending">Leave pending</span>
                    </li>
                    <li>
                      <span className="on-leave">On Leave</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </RosterStyled>
      </DialogProcessor>
    );
  }
}

Roster.propTypes = propTypes;

Roster.defaultProps = defaultProps;

export default withAlert()(Roster);
