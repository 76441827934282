import { refGenerator } from '../../../utils';
import { FILTER } from '../../../data/enums/filter';
import { getFilterConfig } from '../../../utils/filter';
import { FORM_CONFIG } from '../../../data/enums/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { inventory as inventoryRoute } from '../../../data/enums/Route';
import { fileParser } from '../../../utils/parser';
import { UPLOADABLES } from '../../../data/enums/GraphQL';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';

const breadCrumb = [inventoryRoute];

const title = 'Stock Adjustment';
const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      distributorId: element.distributorId || '',
      skuId: element.skuId || 0,
      skuTitle: element.skuTitle || '',
      skuBatchId: element.SKUBatch ? element.SKUBatch.id : '',
      reason: '',
      quantity: element.quantity || 0,
      balance: element.balance || 0,
      damages: 0,
      expiry: 0,
      shortages: 0,
      damagedStock: element.damagedStock,
      expiredStock: element.expiredStock,
      shortageStock: element.shortageStock,
    }),
    [FORM_CONFIG.TITLE]: 'Stock Adjust',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['reason', 'quantity', 'skuTitle']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      file: element.file || {},
    }),
    [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPLOAD} ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
};

const crudRequestConfig = {
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => {
      const {
        skuTitle, balance, damagedStock, expiredStock, shortageStock, ...payload
      } = data;
      return { input: payload };
    },
    responseName: 'createStockAdjustment',
    message: 'Stock adjusted successfully!',
  },
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file).then((file) => ({
        input: {
          file,
          type: UPLOADABLES.STOCKADJUSTMENT,
        },
      }));

      return fileData;
    },
    responseName: 'importCSV',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

const filter = getFilterConfig([FILTER.BRAND, FILTER.SUB_BRAND_FORM]);

export {
  formConfig, crudRequestConfig, breadCrumb as breadCrumbConfig, filter as filterConfig
};
