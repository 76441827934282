import React from 'react'
import PropTypes from 'prop-types';
import { AccordionTable } from '../../../../../components/CollapsibleTable';
import CTAccordionStyled from '../../../../../components/CollapsibleTable/CTAccordionStyled';
import withAlert from '../../../../../utils/composition/withAlert'
import { TableBody, TableHeader } from './tableConfig';
import ChildView from './ChildView';
import { Pagination } from '../../../../../components';

const MOQReceived = (props) => {
  const { data, childData, handleRowClick, pagination, onPageChange } = props;

  return (
    <div className="section-content table-present">
      <CTAccordionStyled>
        <div className="table-wrap">
          <table>
            {
              TableHeader(() => { })
            }
            <tbody>
              <AccordionTable>
                {data.list.map(moq => (
                  <tr
                    key={moq.callId}
                    trigger={
                      TableBody({
                        data: moq,
                        onRowClick: handleRowClick,
                      })
                    }
                  >
                    {moq.totalOrderCount > 0 ? (<ChildView data={childData} />) : <> </>}
                  </tr>
                ))}
              </AccordionTable>
            </tbody>
          </table>
        </div>
      </CTAccordionStyled>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </div>
  )
}

MOQReceived.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  childData: PropTypes.instanceOf(Array).isRequired,
  handleRowClick: PropTypes.func.isRequired,
};


MOQReceived.defaultProps = {

};

export default withAlert()(MOQReceived);
