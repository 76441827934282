import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../../utils/paginatedListUtils';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';

const labelMappings = {
  bu: 'bu',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.title,
    title: 'BU',
    show: true,
  },
];

const returnTableBody = (data) => {
  const table = {};
  table[labelMappings.title] = <td key={`${data.title}`}>{data.title}</td>;
  return table;
};

const TableHeader = () => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? tableData(label.title, label.align, label.classNames)
        : null))}
    </tr>
  </thead>
);

const TableBody = ({ data, onRowClick, permission }) => {
  const tableBody = returnTableBody(data, permission);
  return (
    <tr onClick={() => onRowClick(data.id, data.title)} key={`${data.id}-tr`} className="cursor-pointer">
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  permission: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody, labelConfig };
