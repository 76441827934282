import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { detailsLabelConfig, DetailTableBody } from './tableConfig';
import { tableData } from '../../../../v4/components';

const propTypes = {
  data: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: [],
};

const SubDTargetDetailChildView = ({ data, onInputChange, button }) => (
  <Fragment>
    {data.map((order,index) => (
      <tr>
        {DetailTableBody({
          data: order,
          onInputChange,
          index,
          button
        })}
      </tr>
    ))}
  </Fragment>
);

SubDTargetDetailChildView.propTypes = propTypes;

SubDTargetDetailChildView.defaultProps = defaultProps;

export default SubDTargetDetailChildView;
