import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { OUTLET_FRAGMENT, OUTLET_IMAGE_FRAGMENT } from '../../API';

const GET_OUTLET_DETAIL = gql`
    query getRetailOutlet($id: String) {
        retailOutlets(filter: {
            filters: [
                {
                    column: "id",
                    value: [$id]
                }
            ]
        }) {
            rows {
                ...OutletFragment
                Route {
                    id
                    title
                }
                contactInfo {
                    name
                    phoneNumber
                }
                RetailOutletChain {
                  id
                  title
                }

            }
        }
    }
    ${OUTLET_FRAGMENT}
`;

const GET_OUTLET_IMAGES = gql`
  query getOutletImageList($outletId: Int!) {
  getOutletImageList(outletId: $outletId) {
    ...OutletImageFragment 
    }
  }
  ${OUTLET_IMAGE_FRAGMENT}
`;

const UPDATE_OUTLET = graphql(
  gql`
    mutation ($id: Int!, $input: RetailOutletInput!) {
      updateRetailOutlet(id: $id, input: $input) {
        ...OutletFragment
        address
        Route {
          id
          title
        }
        contactInfo {
          name
          phoneNumber
        }
      }
    }
    ${OUTLET_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateOutlet: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_OUTLET = graphql(
  gql`
    mutation ($input: RetailOutletInput!) {
      createRetailOutlet(input: $input) {
        ...OutletFragment
        address
        Route {
          id
          title
        }
        contactInfo {
          name
          phoneNumber
        }
      }
    }
    ${OUTLET_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createOutlet: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_OUTLET_CHAIN = graphql(
    gql`
      mutation($input: OutletChainInput!) {
        createRetailOutletChain(input: $input) {
          id
          title
      }
    }
    `,
    {
      props: ({ mutate }) => ({
        createOutletChain: variables => mutate({
          variables,
        }),
      }),
    },
  );

const query = {
  getOutletDetail: GET_OUTLET_DETAIL,
  getOutletImage: GET_OUTLET_IMAGES,
};

const mutation = {
  updateOutlet: UPDATE_OUTLET,
  createOutlet: CREATE_OUTLET,
  createOutletChain: CREATE_OUTLET_CHAIN
};

export {
  UPDATE_OUTLET,
  CREATE_OUTLET,
  CREATE_OUTLET_CHAIN
};

export { query, mutation };
