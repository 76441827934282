import React from 'react';
import {
  Tooltip,
  ComposedChart,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Line,
  Area,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { Badge } from '../../../v4/components';
import { ComposedchartStyled } from './ComposedchartStyled';

const Composedchart = ({
  data,
  isXAxis,
  isYAxis,
  title,
  legend,
  cartesianGrid,
  tooltip,
  dimensions,
  color,
  xAxisTitle,
  yAxisTitle,
}) => {
  const { height, width } = dimensions;

  return (
    <div>
      <ComposedchartStyled height={height} width={width}>
        {title && (
          <div className="title-wrap">
            {title && <span> {title}</span>}
            <div className="compliance-green">
              <span className="badge_border">
                <Badge light label="BBSM Anamnagar" />
              </span>
            </div>
          </div>
        )}
        <div className="barchart-wrap">
          {xAxisTitle && <text className="barchart-xaxis">{xAxisTitle}</text>}
          {yAxisTitle && <text className="barchart-yaxis"> {yAxisTitle}</text>}
          <ResponsiveContainer width="100%" aspect={2}>
            <ComposedChart
              data={data}
              height={height}
              width={width}
              margin={{
                right: 30,
                top: 15,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#00ACFF" stopOpacity={0.4} />
                  <stop offset="95%" stopColor="#00ACFF" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#00ACFF" stopOpacity={0.4} />
                  <stop offset="95%" stopColor="#00ACFF" stopOpacity={0} />
                </linearGradient>
              </defs>
              {isXAxis && <XAxis dataKey="week" axisLine={false} fontSize={12} fontWeight={600} fill="#030913" />}

              {isYAxis && <YAxis domain={[0, 100]} tick={false} axisLine={false} />}

              {tooltip && <Tooltip />}
              {cartesianGrid && <CartesianGrid strokeDasharray="3 3" />}
              {legend && <Legend />}
              <Area
                type="monotone"
                dataKey="percent"
                fillOpacity={0.7}
                fill="url(#colorUv)"
                stroke="#0E69FF"
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                dataKey="percent"
                stroke="#0E69FF"
                strokeWidth={2}
                label={{ position: 'top', fontSize: '12px', fontWeight: '500' }}
                fill="#0E69FF"
                isAnimationActive={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </ComposedchartStyled>
    </div>
  );
};

export default Composedchart;
