import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import {
  getPayload, title, getInitialData, ERROR_MSG, FUNDAMETALS, formConfig,
} from './config';
import { breadCrumb } from '../../config';
import { BreadCrumb, Button, Icon } from '../../../../../../../../components';
import { has } from '../../../../../../../../utils/filter';
import history from '../../../../../../../../utils/history';
import withAlert from '../../../../../../../../utils/composition/withAlert';
import { refGenerator, refValidator } from '../../../../../../../../utils/refGenerator';
import { PanelHeader, PanelStyled } from '../../../../../../../common/configuration';
import { MT_BU_DETAILS } from '../../../../../../../../data/enums/Route';
import { ALERT_TYPE } from '../../../../../../../../data/enums/AlertType';
import { MESSAGE_EVENT_OPERATION } from '../../../../../../../../data/enums/SuccessMessage';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../../../../../data/services';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../../../../../data/enums/EventOperation';
import { getTotalSum, isError } from '../../../../../../../common/HelperFunctions';
import DetailStyled from './DetailStyle';
import { DialogFormWrapper } from '../../../../../../../common';
import { clone } from '../../../../../../../../utils/objectProcessor';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  match: PropTypes.instanceOf(Object).isRequired,
  displayAlert: PropTypes.func.isRequired,
  createJBPTemplate: PropTypes.func.isRequired,
  getRetailOutlet: PropTypes.func.isRequired,
  getSBDTemplateList: PropTypes.func.isRequired,
  getValuesForJBPTemplate: PropTypes.func.isRequired,
  getDetailsForJBPTemplate: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Detail extends Component {
  constructor(props) {
    const id = has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0;
    const jbpId = has.call(props.match.params, 'jbpId') ? parseInt(props.match.params.jbpId, 10) : 0;
    super(props);
    this.state = {
      id,
      buTitle: has.call(props.match.params, 'title') ? props.match.params.title : '',
      jbpId,
      activeTab: 0,
      crudMode: jbpId ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
      data: {
        basicData: getInitialData(id),
        sos: [],
        sod: [],
        sosku: [],
        jbpTemplateId: 0,
      },
      menu: {
        channel: [],
        category: [],
        outlet: [],
        sbd: [],
      },
      cardId: {
        categoryId: '',
        productCategoryId: '',
        brandId: '',
        skuId: '',
      },
      count: {
        brandCount: [],
        categoryCount: [],
      },
      update: {
        type: EVENT_OPERATION.CREATE,
        status: true,
      },
      enableErrorDisplay: false,
      totalPointValidation: false,
      dialog: {
        type: '',
      },
      backBrandCount: [],
      backCategoryCount: [],
      productCategoryList: [],
      filteredBrandList: [],
      SKUFamilyList: [],
      skuFamilyList: [],
      searchText: '',
      sbdData: {
        sbdSku: [],
      },
    };
    this.formReference = refGenerator(['title', 'outlet_chain_id', 'point', 'category_id', 'SOS', 'SOD', 'SOSKU']);
  }

  componentDidMount() {
    const { jbpId } = this.state;
    this.getBrandList();
    this.loadDataForDropDown();
    if (jbpId) {
      this.getJBPDetails();
      this.getCategoryList();
      this.getMTJBPDetails();
      this.setState({
        update:{
          type : EVENT_OPERATION.UPDATE,
          status : false
        }
    })
    } else {
      this.loadTableData();
    }
  }

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getBrandList = () => {
    const { getBrandDetails } = this.props;
    getBrandDetails({}, {
      handleSuccess: (response) => {
        const brandList = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState({ brandList });
      },
      handleError: (error) => { this.onAPIRequestFailure(error); },
    });
  };

  getJBPDetails=() => {
    const { displayAlert, getDetailsForJBPTemplate } = this.props;
    const { id, jbpId } = this.state;
    getDetailsForJBPTemplate({
      buId: id,
      jbpTemplateId: jbpId,
    }, {
      handleSuccess: (res) => {
        const response = res.data.getDetailsForJBPTemplate || {};
        const configuredFundamentals = response.configuredFundamentals && response.configuredFundamentals.map((item) => {
          const obj = response.basicData.fundamentalWeightage.find(o => o.fundamental_type_id === item.id);
          return { ...item, ...obj };
        });
        this.setState({
          data: {
            ...response,
            basicData: getInitialData(id, configuredFundamentals, response.basicData),
          } || {},
        }, () => {
          this.getOutletList();
        });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  loadTableData=() => {
    const { getValuesForJBPTemplate, displayAlert } = this.props;
    const { id } = this.state;

    getValuesForJBPTemplate({
      buId: id,
    }, {
      handleSuccess: (res) => {
        const response = res.data.getvaluesForJBPTemplateCreation;
        const configuredFundamentals = response.configuredFundamentals.map(a => ({ ...a, weightage: 0 }));
        const updatedSOS = response.sos.map(a => ({ ...a, weightage: 0 }));
        const updatedSOD = response.sod.map(a => ({ ...a, count: 0 }));
        this.setState({
          data: {
            ...response,
            basicData: getInitialData(id, configuredFundamentals),
            sos: updatedSOS,
            sod: updatedSOD,
          } || {},
        });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CHANNEL).then((response) => {
      menu.channel = response;
      this.setState({ menu });
    });
    this.getSBDTemplate();
  }

  getCategoryList = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY).then((response) => {
      menu.category = response;
      this.setState({ menu });
    });
  }

  createJBP =(data, skuIds, redirectJBP = false) => {
    const {
      jbpId, crudMode, buTitle, id,
    } = this.state;
    const { createJBPTemplate, displayAlert } = this.props;
    createJBPTemplate({
      id: crudMode === EVENT_OPERATION.CREATE ? 0 : jbpId,
      input: getPayload(data, skuIds),
    }, {
      handleSuccess: (res) => {
        displayAlert(ALERT_TYPE.SUCCESS, `JBP Template ${crudMode === EVENT_OPERATION.CREATE ? MESSAGE_EVENT_OPERATION.CREATE : MESSAGE_EVENT_OPERATION.UPDATE}`);
        if (redirectJBP) {
          const createJBPId = res.data.createJBPTemplate.id;
          setTimeout(() => {
            history.push(`/${MT_BU_DETAILS}/${buTitle}/${id}/sbd-create`, { title: buTitle, id, createJBPId });
          }, 900);
        } else {
          setTimeout(() => {
            history.push(`/${MT_BU_DETAILS}/${buTitle}/${id}`, { activeTab: 1 });
          }, 900);
        }
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },
    });
  }

  handleButtonSubmit = (redirectJBP = false) => {
    const {
      data, totalPointValidation, sbdData
    } = this.state;
    const { displayAlert } = this.props;
    const formValidation = refValidator(this.formReference);
    if (!formValidation || totalPointValidation) {
      this.setState({ enableErrorDisplay: true });
    } else {
      const skuIds = sbdData.sbdSku;
      const indexOfSOS = data.basicData.fundamentalWeightage.findIndex(t => t.abbreviation === FUNDAMETALS.SOS);
      if (indexOfSOS > -1) {
        const sosWeightageSum = getTotalSum(data.sos, 'weightage');
        const totalSOSWeightage = sosWeightageSum / 100 * data.basicData.fundamentalWeightage[indexOfSOS].weightage;
        if (totalSOSWeightage !== Number(data.basicData.fundamentalWeightage[indexOfSOS].weightage)) {
          displayAlert(ALERT_TYPE.CUSTOM_DANGER, ERROR_MSG.WEIGHTAGE);
        } else {
          this.createJBP(data, skuIds, redirectJBP);
        }
      } else {
        this.createJBP(data, skuIds, redirectJBP);
      }
    }
  }

  pushArr= (x, event, familyId) => {
    const { sbdData, cardId } = this.state;
      const sbdSkuArr = {
        product_category_id: cardId.productCategoryId,
        brand_id: cardId.brandId,
        sku_family_id: familyId,
        active: event.formattedValue,
      };
      this.sbdSkuArrUnique(sbdData.sbdSku, sbdSkuArr);
    
  }

  sbdSkuArrUnique =(array, item) => {
    const i = array.findIndex(_item => _item.sku_family_id === item.sku_family_id);
    if (i > -1) array[i] = item;
    else array.push(item);
  }

  handleSwitchChange = (event, label, paramList = [], familyId, childId, checked) => {
    const {
      SKUFamilyList, cardId, count,
    } = this.state;

    if (label === 'parent') {
      if (event.formattedValue) {
        if (count.brandCount.filter(x => x.id === cardId.brandId).length > 0) {
          for (let i = 0; i < count.brandCount.length; i++) {
            if (count.brandCount[i].id === cardId.brandId) {
              count.brandCount[i].count = count.brandCount[i].count + SKUFamilyList.filter(x => x.id === familyId).length;
            }
          }
        } else {
          count.brandCount.push({
            count: SKUFamilyList.filter(x => x.id === familyId).length,
            id: cardId.brandId,
          });
        }
        if (count.categoryCount.filter(x => x.id === cardId.productCategoryId).length > 0) {
          for (let i = 0; i < count.categoryCount.length; i++) {
            if (count.categoryCount[i].id === cardId.productCategoryId) {
              count.categoryCount[i].count = count.categoryCount[i].count + SKUFamilyList.filter(x => x.id === familyId).length;
            }
          }
        } else {
          count.categoryCount.push({
            count: SKUFamilyList.filter(x => x.id === familyId).length,
            id: cardId.productCategoryId,
          });
        }
      }
      if (!event.formattedValue) {
        if (count.brandCount.filter(x => x.id === cardId.brandId).length > 0) {
          for (let i = 0; i < count.brandCount.length; i++) {
            if (count.brandCount[i].id === cardId.brandId) {
              count.brandCount[i].count = count.brandCount[i].count - SKUFamilyList.filter(x => x.id === familyId).length;
            }
          }
        }
        if (count.categoryCount.filter(x => x.id === cardId.productCategoryId).length > 0) {
          for (let i = 0; i < count.categoryCount.length; i++) {
            if (count.categoryCount[i].id === cardId.productCategoryId) {
              count.categoryCount[i].count = count.categoryCount[i].count - SKUFamilyList.filter(x => x.id === familyId).length;
            }
          }
        }
      }
      SKUFamilyList[paramList[1]].status = checked;
      SKUFamilyList[paramList[1]].status = event.formattedValue;
      SKUFamilyList[paramList[1]].skus.map(a => a.status = event.formattedValue);
      this.pushArr(SKUFamilyList[paramList[1]], event, familyId);
    }
    this.setState(count);
    this.setState({ SKUFamilyList });
  };

 getSBDTemplate=() => {
   const { menu, id } = this.state;
   const { getSBDTemplateList, displayAlert } = this.props;
   getSBDTemplateList({
     limit: 1000,
     offset: 0,
     filter: {},
     buId: id,
   }, {
     handleSuccess: (response) => {
       menu.sbd = response.data.getRscoreSBDTemplateList ? response.data.getRscoreSBDTemplateList.rows || [] : [];
       this.setState({ menu });
     },
     handleError: (err) => {
       displayAlert(ALERT_TYPE.DANGER, err);
     },
   });
 }

 getOutletList = () => {
   const { getRetailOutlet, displayAlert } = this.props;
   const { menu, data } = this.state;
   const ids = data.basicData.category_id.map(a => a.toString());
   getRetailOutlet({
     limit: 1000,
     offset: 0,
     filter: {
       filters: {
         column: 'category_id',
         value: ids,
       },
     },
   }, {
     handleSuccess: (response) => {
       menu.outlet = response.data.retailOutlets ? response.data.retailOutlets.rows || [] : [];
       this.setState({ menu });
     },
     handleError: (err) => {
       displayAlert(ALERT_TYPE.DANGER, err);
     },
   });
 };

 getProductGroupList = (id) => {
  const { getProductGroupDetails } = this.props;
  getProductGroupDetails({ parentCatalogDetailId: id }, {
    handleSuccess: (response) => {
      const productCategoryList = (response.data.catalogDetails
       && response.data.catalogDetails.rows) || [];
       if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
      this.setState({ productCategoryList });
    },
    handleError: (error) => { this.onAPIRequestFailure(error); },
  });
};

retainData=() => {
  const {productCategoryList, sbdData, count, SKUFamilyList } = this.state;
  const pushedCategory = [];
  const pushedBrand = [];
  if (sbdData.sbdSku) {
    for (let i = 0; i < sbdData.sbdSku.length; i++) {
      if (!pushedCategory.includes(sbdData.sbdSku[i].product_category_id)) {
        pushedCategory.push(sbdData.sbdSku[i].product_category_id);
        const countFilter = count.categoryCount.filter(x => x.id === sbdData.sbdSku[i].product_category_id);
        if (countFilter.length > 0) {
          countFilter[0].count = countFilter[0].count + sbdData.sbdSku.filter(x => x.product_category_id === sbdData.sbdSku[i].product_category_id && x.active === true).length;
          countFilter[0].count = countFilter[0].count - sbdData.sbdSku.filter(x => x.product_category_id === sbdData.sbdSku[i].product_category_id && x.active === false).length;
        } else {
          const cd = {};
          cd.id = sbdData.sbdSku[i].product_category_id;
          cd.count = sbdData.sbdSku.filter(x => x.product_category_id === sbdData.sbdSku[i].product_category_id && x.active === true).length;
          count.categoryCount.push(
            cd,
          );
        }
      }
      if (!pushedBrand.includes(sbdData.sbdSku[i].brand_id)) {
        pushedBrand.push(sbdData.sbdSku[i].brand_id);
        const countFilter = count.brandCount.filter(x => x.id === sbdData.sbdSku[i].brand_id);
        if (countFilter.length > 0) {
          countFilter[0].count = countFilter[0].count + sbdData.sbdSku.filter(x => x.brand_id === sbdData.sbdSku[i].brand_id && x.active === true).length;
          countFilter[0].count = countFilter[0].count - sbdData.sbdSku.filter(x => x.brand_id === sbdData.sbdSku[i].brand_id && x.active === false).length;
        } else {
          const cd = {};
          cd.id = sbdData.sbdSku[i].brand_id;
          cd.count = sbdData.sbdSku.filter(x => x.brand_id === sbdData.sbdSku[i].brand_id && x.active === true).length;
          count.brandCount.push(
            cd,
          );
        }
      }
      for (let i = 0; i < SKUFamilyList.length; i++) {
        for (let j = 0; j < SKUFamilyList[i].skus.length; j++) {
          for (let k = 0; k < sbdData.sbdSku.length; k++) {
            if (SKUFamilyList[i].skus[j].id === sbdData.sbdSku[k].sku_id && sbdData.sbdSku[k].active === true) {
              SKUFamilyList[i].skus[j].status = true;
            }
            if (SKUFamilyList[i].skus[j].id === sbdData.sbdSku[k].sku_id && sbdData.sbdSku[k].active === false) {
              SKUFamilyList[i].skus[j].status = false;
            }
          }
        }
      }
      for (let i = 0; i < SKUFamilyList.length; i++) {
        if (SKUFamilyList[i].skus.length === SKUFamilyList[i].skus.filter(x => x.status === true).length) {
          SKUFamilyList[i].status = true;
        }
      }
    }
  }
  this.setState({ sbdData, productCategoryList, count });
}

getMTJBPDetails=(pId = 0, bId = 0) => {
  const { getMTJBPTemplateDetails } = this.props;
  const { id, count, update, jbpId, sbdData } = this.state;
  getMTJBPTemplateDetails({
    buId: id,
    jbpTemplateId: jbpId,
    categoryId: pId,
    brandId: bId,
  }, {
    handleSuccess: (res) => {
      const response = res.data.getMTJBPTemplateDetails.rows && res.data.getMTJBPTemplateDetails.rows[0];
      count.categoryCount = response && response.Category;
      count.brandCount = response && response.Brand;
      if (update.type === 'UPDATE') {
        const backUpCategoryCount = response && clone(response.Category);
        const backUpBrandCount = response && clone(response.Brand);
        this.setState({
          backBrandCount: backUpBrandCount, backCategoryCount: backUpCategoryCount,
        });
      }
      const backUpSKU = clone(response.SkuFamily);
      response.SkuFamily.forEach(family => {
        const matchingSbdData = sbdData.sbdSku.find(sbd => sbd.sku_family_id === family.id);
        
        if (matchingSbdData) {
            family.status = matchingSbdData.active;
        }
    });
      this.setState({
        count,
        SKUFamilyList: response.SkuFamily,
        skuFamilyList: response.SkuFamily,
        backUpSKUFamily: backUpSKU,
      }, () => {
        this.getOutletList();
        this.retainData();
      });
    },
    handleError: (error) => { this.onAPIRequestFailure(error); },
  });
}

  handleDropDownChange = (value, name) => {
    const { data, menu } = this.state;
    data.basicData[name] = value;
    this.setState(data);
    switch (name) {
      case 'outlet_chain_id':
        data.basicData.category_id = [];
        data.basicData.outlet_id = null;
        menu.outlet = [];
        this.setState({ data, menu });
        this.getCategoryList();
        break;
      case 'category_id':
        data.basicData.outlet_id = null;
        menu.outlet = [];
        this.setState({ data, menu });
        if (data.basicData.category_id.length) {
          this.getOutletList();
        }
        break;
      default:
    }
  };

  handleSearchInput = (text) => {
    const { skuFamilyList } = this.state;
    const regex = new RegExp(text, 'i');
    const bySkus = skuFamilyList.filter(i => i.skus
      .filter(s => s.title.search(regex) > -1).length > 0);
    const bySKUFamilies = skuFamilyList.filter(p => p.title.search(regex) > -1);
    const union = [...bySKUFamilies, ...bySkus];
    const result = union.filter((item, index) => union.indexOf(item) === index);
    this.setState({ SKUFamilyList: result, searchText: text });
  };

  handleSBDSelect =(value, name) => {
    const { data } = this.state;
    data[name] = value;
    this.setState(data);
  }

  handleTabChange = (tabIndex) => {
    const { data, id } =this.state;
    this.setState({ activeTab: tabIndex });
   const indexOfSOS = data.basicData.fundamentalWeightage.findIndex(t => t.abbreviation === FUNDAMETALS.SOSKU);
    if(tabIndex===indexOfSOS){
      this.getProductGroupList(id)
    }
  };


  handleInputChange = (event, firstParam = '', paramList = [], percentCheck = '') => {
    const { data } = this.state;
    if (firstParam) {
      if (paramList.length > 1) {
        paramList.reduce((acc, value, index, list) => {
          if (index === list.length - 1) {
            if (percentCheck) {
              return (acc[value] = event.formattedValue <= 100 ? event.formattedValue : '');
            }
            return (acc[value] = event.formattedValue);
          }
          return acc[value];
        }, data);
      } else {
        data[firstParam][event.target.name] = event.formattedValue;
      }
    } else {
      data[event.target.name] = event.formattedValue;
    }
    if (firstParam === 'fundamentalWeightage' || event.target.name === 'point') {
      const sum = getTotalSum(data.basicData.fundamentalWeightage, 'weightage');
      if (sum === data.basicData.point) {
        this.setState({ totalPointValidation: false });
      } else {
        this.setState({ totalPointValidation: true });
      }
    }
    this.setState({ data });
  };

  handlePrimarySwitch=(event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    if (firstParam) {
      if (paramList.length > 1) {
        paramList.reduce((acc, value, index, list) => {
          if (index === list.length - 1) {
            return (acc[value] = event.formattedValue);
          }
          return acc[value];
        }, data);
        data[paramList[0]][paramList[1]].childUsers.map(a => a.status = event.formattedValue);
      }
    }
    this.setState({ data });
  }

  handleFormSubmit = () => {
    const { id, buTitle } = this.state;
    const redirectJBP = true;
    this.handleButtonSubmit(redirectJBP);
  };

  handleSBDCreate = (type) => {
    this.setState({
      dialog: {
        type,
      },
    });
  };

  handleButtonCancel = () => {
    const { crudMode, backCategoryCount, backBrandCount, backUpSKUFamily, sbdData } = this.state;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.loadTableData();
    } else {
      this.getJBPDetails();
      const backUpBranchCount = clone(backBrandCount);
      const backUpCategoryCount = clone(backCategoryCount);
      const backUpSKU = clone(backUpSKUFamily);
      sbdData.sbdSku = [];
      this.setState({ 
        crudMode: EVENT_OPERATION.READ, 
        cardId: {
          categoryId: '',
          productCategoryId: '',
          brandId: '',
          skuId: '',
        },
        count: { 
          brandCount: backUpBranchCount, 
          categoryCount: backUpCategoryCount 
        },
        SKUFamilyList: backUpSKU ,
        sbdData
      });
    }
  };

  handleEditIconClick = () => {
    this.setState({ crudMode: EVENT_OPERATION.UPDATE });
  };

  getHeader = () => {
    const { crudMode, data } = this.state;
    const header = crudMode === EVENT_OPERATION.UPDATE ? `  ${data.basicData.title} ` : title;
    if (crudMode === EVENT_OPERATION.READ) return ` ${data.basicData.title} `;
    return (
      <span>
        {`${EVENT_OPERATION_MAPPER[crudMode].toLowerCase()} ${header}`}
      </span>
    );
  };

  handleProductCategoryClick = (groupId) => {
    const { cardId, brandList } = this.state;
    cardId.productCategoryId = groupId;
    cardId.brandId = '';
    const newBrandList = brandList
      .filter(brand => parseInt(brand.parentCatalogDetailId, 10) === groupId);
    this.getMTJBPDetails(cardId.productCategoryId);
    this.setState({ cardId, filteredBrandList: newBrandList });
  };

  handleBrandClick = (brandId) => {
    const { cardId } = this.state;
    cardId.brandId = brandId;
    this.getMTJBPDetails(cardId.productCategoryId, cardId.brandId);
    this.setState({ cardId });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
      },
    });
  };

  render() {
    const {
      id, buTitle, data, menu, activeTab, enableErrorDisplay, totalPointValidation, crudMode, dialog: { type }, productCategoryList, cardId, count, filteredBrandList, searchText, SKUFamilyList, update
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const createBreadCrumb = {
      id: 2,
      title: `${buTitle}`,
      path: `/${MT_BU_DETAILS}/${buTitle}/${id}`,
      active: true,
    };
    const readMode = crudMode === EVENT_OPERATION.READ;
    
    return (
      <DetailStyled>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={[...breadCrumb, createBreadCrumb]} />
            <PanelHeader>
              <h2>{this.getHeader()}</h2>
              <div className="flex m-0">
                {!readMode && (
                <div className="button-wrap">
                  <Button
                    secondary
                    small
                    disabled={serverResponseWaiting}
                    title="Cancel"
                    onClick={() => this.handleButtonCancel()}
                  />
                  <Button
                    primary
                    small
                    disabled={serverResponseWaiting}
                    title="Save"
                    onClick={() => this.handleButtonSubmit()}
                  />
                </div>
                )}
                {readMode ? (
                  <div>
                    <Button
                      secondary
                      iconBtnSmall
                      onClick={() => this.handleEditIconClick()}
                      className="ml-16"
                    >
                      <Icon iconName="pencil" />
                    </Button>
                  </div>
                ) : ''
                }
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className="section-content">
          {type && (
          <DialogFormWrapper
            formConfig={formConfig[type]}
            onDialogSubmit={this.handleFormSubmit}
            onDialogCancel={this.resetDialog}
            type={type}
            renderDialog={() => (
              <>
                {type && (
                  <div>
                    {'Are you sure you want to '}
                    <b>Save JBP Template</b>
                    {' '}
                    {'and'}
                    {' '}
                    <b>Create SBD Template</b>
                    ?
                  </div>
                )}
              </>
            )}
          />
          )}
          <View
            data={data}
            menu={menu}
            count={count}
            cardId={cardId}
            readMode={readMode}
            activeTab={activeTab}
            refsObj={this.formReference}
            loading={serverResponseWaiting}
            enableErrorDisplay={enableErrorDisplay}
            totalPointValidation={totalPointValidation}
            onSBDSelect={this.handleSBDSelect}
            onTabChange={this.handleTabChange}
            onInputChange={this.handleInputChange}
            onSBDCreateClick={this.handleSBDCreate}
            onDropDownChange={this.handleDropDownChange}
            handlePrimarySwitch={this.handlePrimarySwitch}
            productCategoryList={productCategoryList}
            brandList={filteredBrandList}
            searchText={searchText}
            onHandleSearchInput={this.handleSearchInput}
            onProductCategoryClick={this.handleProductCategoryClick}
            SKUFamilyList={SKUFamilyList}
            onBrandClick={this.handleBrandClick}
            handleSwitchChange={this.handleSwitchChange}
            update={update}
          />
        </div>
      </DetailStyled>
    );
  }
}
Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default withAlert()(Detail);
