import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as queryService from '../../../../../../base/query.service';
import { ALERT_TYPE } from '../../../../../../../data/enums/AlertType';
import withAlert from '../../../../../../../utils/composition/withAlert';
import { crudResponseProcessor, handleFormSubmit } from '../../../../../../../utils/crudResponseProcessor';
import { DialogWrapper } from '../../../../../../common';
import { Button, Search } from '../../../../../../../components';
import { EVENT_OPERATION } from '../../../../../../../data/enums/EventOperation';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../../../../utils/api';
import history from '../../../../../../../utils/history';
import withErrorAndLoadingScreen from '../../../../../../../utils/composition/withLoading';
import { crudRequest as crudRequestConfig, filterConfig, successMessage } from './config';
import View from './View';
import { MT_BU_DETAILS } from '../../../../../../../data/enums/Route';
import VGPStyled from './VGPStyle';
import { isError } from '../../../../../../common/HelperFunctions';
import { Filter } from '../../../../../../../v4/components';
import { clone } from '../../../../../../../utils/objectProcessor';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  deleteQuestionnaire: PropTypes.func.isRequired,
  getQuestionnaire: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};
const defaultProps = {
  serverResponseWaiting: false,
};
class VGP extends Component {
  constructor(props) {
    super(props);
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        filter: queryService.baseQueryParameters.filter,
      },
      filter: {
        ...filterConfig.instance,
      },
      data: {
        list: [],
        total: 0,
      },
      filterMenu: this.filterConfigMenu || {},
    };
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    const serverCall = {
      [EVENT_OPERATION.DELETE]: props.deleteQuestionnaire,
    };

    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        this.setState(data);
        displayAlert(ALERT_TYPE.SUCCESS, successMessage);
        if (
          type === EVENT_OPERATION.DELETE
        ) {
          this.loadTableData();
        }
      }, this.getData,
      ['title'],
    );
    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess,
      this.onAPIRequestFailure,
      crudRequestConfig,
      serverCall,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getQuestionnaire,id } =this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getQuestionnaire(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [{column: "principal_id", value: [id?.toString()]},...apiFilterProcessor(queryParameters.filter)],
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: (response) => {
          data.list = response?.data?.getQuestionnaire?.rows || [];
          data.total = response?.data?.getQuestionnaire?.count || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleCreateClick=() => {
    const { id, buTitle } = this.props;
    history.push({ pathname: `/${MT_BU_DETAILS}/${buTitle}/${id}/vgp-create`, state: { id, title: buTitle } });
  }

  onTableRowClick = (idRow) => {
    const { id, buTitle } = this.props;
    history.push({ pathname: `/${MT_BU_DETAILS}/${buTitle}/${id}/vgp-details/${idRow}`, state: { id, title: buTitle } });
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);
  render() {
    const { queryParameters, data, filterMenu, filter } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
        <DialogWrapper
          onDialogSubmit={this.onFormSubmit}
          render={({ onDialogItemClick }) => (
            <VGPStyled>
              <div className="top-section">
              <span>{`Total ${data.list && data.list.length} Questions.`}</span>
                <div className="button-section">
                <Filter
                  metaData={filter}
                  menuList={filterMenu}
                  onFilterChange={this.basePaginationService.handleFilterChange}
                />
                  <Button
                    primary
                    iconName="plus"
                    small
                    title={EVENT_OPERATION.CREATE.toLocaleLowerCase()}
                    onClick={() => this.handleCreateClick(EVENT_OPERATION.CREATE)}
                    className="create-btn"
                  />
                </div>
              </div>
              <View
                data={data}
                permission={this.permission}
                pagination={queryParameters.pagination}
                loading={serverResponseWaiting}
                onPageChange={this.basePaginationService.onPageSelect}
                onBulkDelete={this.handleBulkDelete}
                onIconClick={onDialogItemClick}
                onTableRowClick={this.onTableRowClick}
              />
            </VGPStyled>
          )}
        />
      );
    }
}

VGP.propTypes = propTypes;
VGP.defaultProps = defaultProps;

const VGPWithErrorAndLoadingScreen = withErrorAndLoadingScreen(VGP);

export default withAlert()(VGPWithErrorAndLoadingScreen);
