import {
  compose,
} from 'recompose';
import MTDialog from './MTDialog';
import {
  mutation,
  CREATE_MT,
  UPDATE_MT,
  DELETE_MT,
  BULKDELETE_MT,
} from './API';


import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposedMTDialog = compose(
  CREATE_MT,
  UPDATE_MT,
  DELETE_MT,
  BULKDELETE_MT,
  withRequestTracker({
    mutation,
  }),
)(MTDialog);

export default ComposedMTDialog;
