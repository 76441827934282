import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import {
  CustomSelect, Pagination, Search, Filter,
} from '../../../../components';
import { TableBody, TableHeader } from './tableConfig';
import withLoading from '../../../../utils/composition/withLoading';

const propTypes = {
  menu: PropTypes.instanceOf(Object),
  select: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
};

const defaultProps = {
  menu: {},
  select: {},
};

const View = (props) => {
  const {
    data,
    menu,
    select,
    pagination,
    onRowClick,
    onPageChange,
    onFilterChange,
    onDropDownChange,
    handleSearchChange,
  } = props;
  return (
    <>
      <Row className="select-section">
        
       
        <Col md="3" />
        <Col className="mt-24 mr-16">
          {(select.outletChain !== '' && select.outlet !== '' && select
            .stockCount !== '') && (
            <Search
              handleSearchInput={handleSearchChange}
            />
          )}
        </Col>
        {/* <Col className="mt-24">
          <Filter
            // metaData={filter}
            // menuList={filterConfig.menu}
            onFilterChange={onFilterChange}
          />
        </Col> */}
      </Row>
      {data.list.length > 0 && (
        <>
          <div className="table-wrap">
            <table>
              { TableHeader() }
              <tbody>
                {data.list.map(outstandings => (
                  TableBody({
                    data: outstandings,
                    onRowClick,
                  })
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            currentPage={pagination.page}
            limit={pagination.limit}
            totalItems={data.total}
            onPageChange={onPageChange}
          />
      </>
      )}
  </>
  );
};

View.defaultProps = defaultProps;

View.propTypes = propTypes;

const ViewWithErrorAndLoading = withLoading(View);

export default withLoading(ViewWithErrorAndLoading);
