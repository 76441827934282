import { compose } from 'recompose';
import OutletChain from './OutletChain';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
   query, mutation,TOGGLE_STATE_OUTLET_CHAIN
} from './API';

const ComposedOutletChain = compose(
  TOGGLE_STATE_OUTLET_CHAIN,
  withRequestTracker({
    query,
    mutation,
  }),
)(OutletChain);

export default ComposedOutletChain;
