import styled from 'styled-components';

export const RecentPastWeekStyled = styled.div`
  border: 1px solid #e7e7ed;
  margin: 20px 0;
  max-width: 95vw;
  overflow: auto;
  border-radius: 4px;

  .cursor-pointer {
    height: 38px;
  }
  .table-wrap {
    margin-top: 40px;
    .light {
      background-color: #287d3c;
      color: #ffffff;
      font-weight: 500;
      width: 60px;
      height: 20px;
      font-size: 11px;
      letter-spacing: 0.4px;
    }
    tr {
      height: 40px;
    }
  }

  .barchart-yaxis {
    margin-bottom: -14px;
  }

  .compliance-above {
    background-color: #5aca75;
    color: #ffffff;
    font-weight: 500;
    width: 65px;
    height: 20px;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 1;
  }

  .compliance-under {
    background-color: #da1414;
    color: #ffffff;
    font-weight: 500;
    width: 65px;
    height: 20px;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 1;
  }

  .compliance-middle {
    background-color: #ff8f39;
    color: #ffffff;
    font-weight: 500;
    width: 65px;
    height: 20px;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 1;
  }

  .mr-outlet {
    display: flex;
    align-items: center;
    gap: 12px;
    .bbsm {
      height: 12px;
      width: 12.63px;
      background-color: #0e69ff;
      border-radius: 100%;
      color: #030913;
    }

    .bigmart {
      height: 12px;
      width: 12.63px;
      background-color: #f27024;
      border-radius: 100%;
      color: #030913;
    }
    .salesways {
      height: 12px;
      width: 12.63px;
      background-color: #43c187;
      border-radius: 100%;
      color: #43c187;
    }
  }

  .recharts-tooltip-item-separator {
    display: none;
  }

  .recharts-tooltip-item-name {
    display: none;
  }

  .td-right {
    text-align: right;
  }
  .td-middle {
    text-align: center;
  }
  .text-middle {
    text-align: center;
  }
`;
