import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SKU_STATUS } from '../../config';
import { INSTOCK, SECONDARY } from '../../../../../data/enums/GeneralConstants';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { PROMOTION_SCOPE } from '../../../../../data/enums/GraphQL';
import Popover from '../../../../../components/Popover/Popover';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { Icon, Button, Menu, CheckBox } from '../../../../../v4/components';

const detailLabelMappings = {
  id: 'id',
  sku: 'sku',
  quantity: 'quantity',
  rate: 'rate',
  status: 'status',
  promotion: 'promotion',
  value: 'value',
  checkbox: 'checkbox',
  menu: 'menu',
};

const detailsLabelConfig = [
  {
    id: 1,
    label: detailLabelMappings.checkbox,
    title: '',
    show: true,
    action: true,
  },
  {
    id: 2,
    label: detailLabelMappings.id,
    title: 'Order ID',
    show: true,
  },
  {
    id: 3,
    label: detailLabelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 4,
    label: detailLabelMappings.quantity,
    title: 'Quantity',
    align: true,
    show: true,
  },
  {
    id: 5,
    label: detailLabelMappings.rate,
    title: 'Rate',
    align: true,
    show: true,
  },
  {
    id: 6,
    label: detailLabelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 7,
    label: detailLabelMappings.promotion,
    title: 'Promotion',
    classNames: 'text-center',
    show: true,
  },
  /* {
    id: 8,
    label: detailLabelMappings.discount,
    title: 'Discount',
    align: true,
    show: true,
  }, */
  {
    id: 9,
    label: detailLabelMappings.value,
    title: 'Value',
    align: true,
    show: true,
  },
  {
    id: 10,
    label: detailLabelMappings.menu,
    title: '',
    align: true,
    show: true,
  },
];

const getMenuHeader = (disabledStatus = false) => <Button iconName='ellipsis-v' disabled={disabledStatus} />;

const returnDetailTableBody = (
  data,
  index,
  checkBoxStatus,
  onSecondaryCheckBoxClick,
  onIconClick,
  order,
  permission,
  callOrderValue,
  stockStatusInfo,
) => {
  const table = {};
  const disabledMenu = data.freeSku || !permission.update;
  const disableSplit =
    (data.Promotion && data.Promotion.scope && data.Promotion.scope !== PROMOTION_SCOPE.SINGLE) || false;
  const stockStatusTitle = (
    <span className={`${SKU_STATUS[data.inStock]} stock-label`}>{SKU_STATUS[data.inStock]}</span>
  );
  table[detailLabelMappings.checkbox] = (
    <td key={`${data.id}-checkbox`} className={callOrderValue.billDiscount ? 'disabled' : ''}>
      {/* <CheckBox
        disabled={data.inStock !== INSTOCK}
        checked={checkBoxStatus(SECONDARY, data.id)}
        onCheckBoxClick={e => onSecondaryCheckBoxClick(data.id)}
      /> */}
      {order.promotionId ? (
        index === 0 ? (
          <CheckBox
            disabled={order.skuStatus !== INSTOCK}
            checked={checkBoxStatus(SECONDARY, data.id)}
            onCheckBoxClick={(e) => onSecondaryCheckBoxClick(order.Lines)}
          />
        ) : null
      ) : (
        <CheckBox
          disabled={order.skuStatus !== INSTOCK}
          checked={checkBoxStatus(SECONDARY, data.id)}
          onCheckBoxClick={(e) => onSecondaryCheckBoxClick(order.Lines)}
        />
      )}
    </td>
  );
  table[detailLabelMappings.id] = <td key={`${data.id}-id`}>{data.id}</td>;
  table[detailLabelMappings.sku] = (
    <td key={`${data.id}-sku`}>
      {data.billDiscountSku && <span className='bill-discount-indicator' />}
      {data.freeSku && <span className='free-sku-indicator' />}
      {data.SKU ? data.SKU.title : ''}
      {data.focusedSku && <span className='focused-sku-indicator' />}
    </td>
  );
  table[detailLabelMappings.quantity] = (
    <td key={`${data.id}-quantity`} className='text-right'>
      {data.quantity}
    </td>
  );
  table[detailLabelMappings.rate] = (
    <td key={`${data.id}-rate`} className='text-right'>
      {data.rateDetails ? data.rateDetails.rlp : null}
    </td>
  );
  table[detailLabelMappings.status] = (
    <td key={`${data.id}-popup`}>
      {stockStatusInfo ? (
        <div className='simple-popup'>
          <Popover popoverIcon={stockStatusTitle} headerTitle={SKU_STATUS[data.inStock]}>
            {data.SKU ? data.SKU.title : ''}
            <br />
            <b>{`Quantity : ${data.stockBalance === null ? 0 : data.stockBalance}`}</b>
          </Popover>
        </div>
      ) : (
        stockStatusTitle
      )}
    </td>
  );
  table[detailLabelMappings.promotion] =
    index === 0 && order.promotionId ? (
      <td key={`${data.id}-image`} className='text-center'>
        <img
          src='/image/icons/Promotion.svg'
          style={{ cursor: 'pointer' }}
          onClick={() => onIconClick(EVENT_OPERATION.READ, order)}
        />
      </td>
    ) : (
      <td key={`${data.id}-image`} />
    );
  /* table[detailLabelMappings.discount] = (
    <td key={`${data.id}-`} className="text-right">
      {fixedFloatAndCommas(data.amountDetails.discountAmount)}
    </td>
  ); */
  table[detailLabelMappings.value] = (
    <td key={`${data.id}-amount`} className='text-right'>
      {fixedFloatAndCommas(data.amountDetails.subTotal || 0)}
    </td>
  );
  table[detailLabelMappings.menu] = (
    <td key={`${data.id}-menu`} className='text-right simple-popup-actions'>
      <Menu label='' header={getMenuHeader(disabledMenu)} disabled={disabledMenu}>
        <div className='filter-content text-left'>
          <ul>
            <li onClick={() => onIconClick(EVENT_OPERATION.SPLIT, data)} className={disableSplit ? 'disabled-btn' : ''}>
              <span>
                <Icon iconName='cut' />
                Split
              </span>
            </li>
            <li onClick={() => onIconClick(EVENT_OPERATION.UPDATE, data)}>
              <span>
                <Icon iconName='pencil' />
                Edit
              </span>
            </li>
            {permission.delete && (
              <li onClick={() => onIconClick(EVENT_OPERATION.DELETE, { id: data.id, callId: data.callId })}>
                <span>
                  <Icon iconName='times-circle' />
                  Cancel
                </span>
              </li>
            )}
          </ul>
        </div>
      </Menu>
    </td>
  );
  return table;
};

const DetailTableBody = ({
  data,
  index,
  order,
  checkBoxStatus,
  onSecondaryCheckBoxClick,
  onIconClick,
  permission,
  callOrderValue,
  stockStatusInfo,
}) => {
  const tableBody = returnDetailTableBody(
    data,
    index,
    checkBoxStatus,
    onSecondaryCheckBoxClick,
    onIconClick,
    order,
    permission,
    callOrderValue,
    stockStatusInfo,
  );

  return <Fragment>{detailsLabelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

DetailTableBody.propTypes = {
  onSecondaryCheckBoxClick: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
  order: PropTypes.instanceOf(Object),
  checkBoxStatus: PropTypes.func,
  onIconClick: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
  callOrderValue: PropTypes.instanceOf(Object),
  stockStatusInfo: PropTypes.bool,
};

DetailTableBody.defaultProps = {
  onSecondaryCheckBoxClick: () => null,
  data: {},
  checkBoxStatus: () => null,
  order: {},
  permission: PERMISSION_OBJ,
  onIconClick: () => null,
  callOrderValue: {},
  stockStatusInfo: false,
};

export { DetailTableBody, detailsLabelConfig };
