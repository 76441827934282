import React from 'react';
import { Badge, tableData } from '../../../../v4/components';
import { STATUS } from '../../../../data/enums';
import { normalPresentor } from '../../../../utils/date';
import { ImageColumn } from '../../../../utils/tableUtils';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';

const labelMappings = {
  SRNInvoiceNumber: 'SRNInvoiceNumber',
  date: 'date',
  invoiceNumber: 'invoiceNumber',
  status: 'status',
  retailOutlet: 'outlet',
  stockType: 'type',
  ledger: 'customer_name',
  remarks: 'remarks',
  image: 'image',
};
const labelConfig = [
  {
    id: 1,
    label: labelMappings.invoiceNumber,
    title: 'Sales Invoice Number',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.SRNInvoiceNumber,
    title: 'SRN Invoice Number',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.retailOutlet,
    title: 'Retail Outlet',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.stockType,
    title: 'Stock Type',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.ledger,
    title: 'Ledger',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.remarks,
    title: 'Remarks',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.image,
    title: 'Images',
    show: true,
  },
];

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const returnTableBody = (data, onRowClick, handleSliderOpen) => {
  const table = {};
  table[labelMappings.invoiceNumber] = (
    <td onClick={() => onRowClick(EVENT_OPERATION.APPROVE, data.id)} key={`srn-${data.id}-invoice`}>
      {data.invoiceNumber}
    </td>
  );

  table[labelMappings.date] = (
    <td onClick={() => onRowClick(EVENT_OPERATION.APPROVE, data.id)} key={`srn-${data.id}-date`}>
      {normalPresentor(data.date)}
    </td>
  );

  table[labelMappings.SRNInvoiceNumber] = (
    <td onClick={() => onRowClick(EVENT_OPERATION.APPROVE, data.id)} key={`srn-${data.id}-srnInvoice`}>
      {data.SRNInvoiceNumber}
    </td>
  );

  table[labelMappings.status] = (
    <td onClick={() => onRowClick(EVENT_OPERATION.APPROVE, data.id)} key={`srn-${data.id}-approve`}>
      <span className={data.approved ? STATUS.APPROVED.toLowerCase() : STATUS.PENDING.toLowerCase()}>
        <span className='badge_border'>
          <Badge light>{data.approved ? STATUS.APPROVED : STATUS.PENDING}</Badge>
        </span>
      </span>
    </td>
  );

  table[labelMappings.retailOutlet] = (
    <td onClick={() => onRowClick(EVENT_OPERATION.APPROVE, data.id)} key={`srn-${data.id}-outlet`}>
      {data.RetailOutlet ? data.RetailOutlet.title : ''}
    </td>
  );

  table[labelMappings.stockType] = (
    <td
      onClick={() => onRowClick(EVENT_OPERATION.APPROVE, data.id)}
      key={`srn-${data.id}-stockType`}
      className='text-left'
    >
      {data.type}
    </td>
  );

  table[labelMappings.ledger] = (
    <td onClick={() => onRowClick(EVENT_OPERATION.APPROVE, data.id)} key={`srn-${data.id}-legder`}>
      {data.Customer ? data.Customer.name : ''}
    </td>
  );

  table[labelMappings.remarks] = (
    <td onClick={() => onRowClick(EVENT_OPERATION.APPROVE, data.id)} key={`srn-${data.id}-remarks`}>
      {data.reason}
    </td>
  );

  table[labelMappings.image] = (
    <ImageColumn
      keyTitle={`rp-image-${data.id}`}
      imageList={data.image || []}
      onImageClick={handleSliderOpen}
      key={`rp-image-${data.id}`}
    />
  );

  return table;
};

const TableBody = (data, onRowClick, handleSliderOpen) => {
  const tableBody = returnTableBody(data, onRowClick, handleSliderOpen);

  return <tr key={`srn-${data.id}`}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

export { TableHeader, TableBody };
