import { compose } from 'recompose';
import BulkDelete from './BulkDelete';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { TOGGLE_STATE } from '../../common/GqlAPI';
import { DELETE_WAREHOUSE_LEVEL_DETAIL } from '../../inventory/warehouseLevels/API';
import { BULK_DELETE_VISIBILITY_AUDIT } from '../../configuration/MT/visibilityAudit/API';


const ComposedBulkDelete = compose(
  TOGGLE_STATE,
  DELETE_WAREHOUSE_LEVEL_DETAIL,
  withRequestTracker({
    mutation: {
      toggleState: TOGGLE_STATE,
      deleteWarehouseLevels: DELETE_WAREHOUSE_LEVEL_DETAIL,
      deletebulkVisibilityAudit: BULK_DELETE_VISIBILITY_AUDIT,

    },
  }),
)(BulkDelete);

export default ComposedBulkDelete;
