import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const CREATE_MT = graphql(gql`
    mutation ($input: MTStockInput!) {
      createMTStock(input: $input)
        {
           id
            title
            assigned_to
            count_days
            active
        }
    }`, {
  props: ({ mutate }) => ({
    createMTStock: variables => mutate({
      variables,
    }),
  }),
});

const UPDATE_MT = graphql(gql`
    mutation ($input: MTStockInput!, $id: Int!) {
        editMTStock(input: $input, id: $id)
        {
            id
            title
            assigned_to
            count_days
            active
        }
    }`, {
  props: ({ mutate }) => ({
    editMTStock: variables => mutate({
      variables,
    }),
  }),
});

const DELETE_MT = graphql(gql`
mutation ($id: Int!){
  deleteMTStock(id:$id){
    id
    title
    active
  }
  }`, {
  props: ({ mutate }) => ({
    deleteMTStock: variables => mutate({
      variables,
    }),
  }),
});

const BULKDELETE_MT = graphql(gql`
mutation ($ids: [Int]!){
  deleteMTStock(ids:$ids)
  }`, {
  props: ({ mutate }) => ({
    bulkDeleteMTStock: variables => mutate({
      variables,
    }),
  }),
});

const mutation = {
  createMTStock: CREATE_MT,
  editMTStock: UPDATE_MT,
  deleteMTStock: DELETE_MT,
  bulkDeleteMTStock: BULKDELETE_MT,
};

export {
  mutation, CREATE_MT, UPDATE_MT, DELETE_MT, BULKDELETE_MT,
};
