import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { tableData, Icon } from '../../../v4/components';

// todo update the configurations.
const labelMappings = {
  id: 'id',
  month: 'month',
  target: 'target',
  allocated: 'allocated',
  unAllocated: 'unAllocated',
  actionHolders: 'actionHolders',
  brandTarget: 'brandTarget',
};

const returnTableBody = (data, handleIconClick) => {
  const table = {};
  table[labelMappings.month] = <td key={`${data.id}-monthName`}>{`${data.monthName}, ${data.year} `}</td>;
  table[labelMappings.target] = (
    <td key={`${data.id}-target`} className='right-align'>
      {data.target.toLocaleString()}
    </td>
  );
  table[labelMappings.brandTarget] = (
    <td key={`${data.id}-brandTarget`} className='right-align' onClick={(e) => e.stopPropagation()}>
      <>
        <span className='view' onClick={() => handleIconClick(EVENT_OPERATION.READ, data)}>
          <Icon iconName='view' />
          View
        </span>
        <span onClick={() => handleIconClick(EVENT_OPERATION.UPDATE, data)}>
          <Icon iconName='pencil' />
          Edit
        </span>
      </>
    </td>
  );
  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.month,
    title: 'Month',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.target,
    title: 'Targets',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.brandTarget,
    title: '',
    show: true,
  },
];

const TableBody = ({ data, onTableBodyClick, handleIconClick }) => {
  const tableBody = returnTableBody(data, handleIconClick);

  return (
    <tr key={data.id} className='cursor-pointer' onClick={() => onTableBodyClick(data)}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

TableBody.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
  onTableBodyClick: PropTypes.func.isRequired,
  handleIconClick: PropTypes.func.isRequired,
};

export { TableHeader, TableBody };
