import { apolloClient, errorProcessor } from './common';
import { GET_RETAIL_OUTLET_CHAIN_FILTER, GET_RETAIL_OUTLET_FILTER } from '../../views/reports/rscoreAudit/API';
import { fetchFromLocalStorage } from '../../views/common/HelperFunctions';
import { storageKeys } from '../../views/sales/orderProcessing/config';
import { DOMAIN } from '../../data/enums/config';
import { GET_OUTLET_LIST } from '../../views/sales/route-outlet/outlet/API';
const getRetailOutletFromServer = () =>{
  const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.AUDIT_REPORT);
  return(apolloClient
    .query({
      query: GET_RETAIL_OUTLET_FILTER,
      variables: {
        onlyOutlet: true,
        chainId: localData?.filter?.chain_id || null,
      },
    }))

    .then((res) => res.data.retailOutletsFilter)
    .catch((err) => errorProcessor(err));}

const getRetailOutletChainFromServer = () =>
  apolloClient
    .query({
      query: GET_RETAIL_OUTLET_CHAIN_FILTER,
      variables: {
        onlyChain: true,
      },
    })

    .then((res) => res.data.retailOutletsFilter)
    .catch((err) => errorProcessor(err));

const getFilteredRetailOutletFromServer = () =>{
  const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.VGP_REPORT);

  return( apolloClient
    .query({
      query: GET_OUTLET_LIST,
      variables: {
        offset: 0,
        filter: localData?.filter?.channel_id?.length>0?{
          filters: [
            {
              column: 'channel_id',
              value: localData?.filter?.channel_id?.map((a)=>a?.toString()) || null,
            }
          ],
        }:{}
      },
    })

    .then((res) => res.data.retailOutlets.rows)
    .catch((err) => errorProcessor(err)))
}

export {
  getRetailOutletFromServer, getRetailOutletChainFromServer, getFilteredRetailOutletFromServer
};
