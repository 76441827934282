import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { CHANGE_PAYMENT_STATUS } from '../BulkSelect/API';

const LEDGER_FRAGMENT = gql`
  fragment LedgerFragment on Customer {
    customerId
    title
    outstandingAmount(distributorId: $distributorId, retailOutletId: $retailOutletId)
    billingLedgerId
  }
`;

const GET_LEDGERS = gql`
  query getLedgers($offset: Int, $limit: Int, $filter: FilterInput, $retailOutletId: Int, $distributorId: Int) {
    customers(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...LedgerFragment
      }
      count
    }
  }
  ${LEDGER_FRAGMENT}
`;

const SPLIT_PAYMENT = graphql(
  gql`
    mutation ($id: Int!, $input: [PaymentSplitInput]!) {
      splitPayment(id: $id, input: $input) {
        id
        outletId
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      splitPayment: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_PAYMENT = graphql(
  gql`
    mutation ($paymentId: Int!, $amount: Float, $remarks: String, $date: String) {
      updatePayment(paymentId: $paymentId, amount: $amount, remarks: $remarks, date: $date) {
        id
        amount
        remarks
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updatePayment: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getLedgers: GET_LEDGERS,
};

const mutation = {
  splitPayment: SPLIT_PAYMENT,
  changePaymentStatus: CHANGE_PAYMENT_STATUS,
  updatePayment: UPDATE_PAYMENT,
};

export {
  query, GET_LEDGERS, mutation, SPLIT_PAYMENT, UPDATE_PAYMENT
};
