import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, CustomSelect, Input, Row, SingleDatePicker,
} from '../../../../../../../../components';

const propTypes = {
  basicData: PropTypes.objectOf(Object).isRequired,
  onInputChange: PropTypes.func,
  onDropDownChange: PropTypes.func,
};
const defaultProps = {
  onInputChange: () => null,
  onDropDownChange: () => null,
};

const BasicDetails = (props) => {
  const { sbdData: { basicData: { question, start_date, end_date, sbdcategory, channel_id, audit_type, }, }, menu: { channel, category, auditType }, onInputChange, onDropDownChange, refsObj, enableErrorDisplay } = props;
  const channelId = channel_id;
  const filteredCategoryList = channelId
    ? category.filter((el) => el.Channel.id === channelId) : category;
  return (
    <div className='create-top-section'>
      <Row>
        <Col md={4} className='pr-48'>
          <CustomSelect
            name='channel'
            onChange={(event) => {
              onDropDownChange(event.id, 'channel_id');
            }}
            options={channel}
            labelContent='Channel'
            placeholder='Select Channel'
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            value={channel.filter(
              ({ id }) => id === channel_id,
            )}
            ref={(ref) => (refsObj.channel_id = ref)}
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
          />
        </Col>
        <Col md={4} className='pr-48'>
          <CustomSelect
            name='category'
            onChange={(event) => {
              const ids = event.map((d) => d.id);
              onDropDownChange(ids, 'sbdcategory');
            }}
            isMulti
            options={filteredCategoryList}
            labelContent='Category'
            placeholder='Select Category'
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            value={category
              .filter(({ id }) => (sbdcategory ? sbdcategory.includes(id) : false))}
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
            ref={(ref) => (refsObj.sbdcategory = ref)}
          />
        </Col>
        <Col md={4} />
        <Col md={4} className='pr-48 mb-24'>
          <div className='form-input custom-date'>
            <label>Start Date</label>
            <SingleDatePicker
              date={start_date}
              name='start_date'
              onChange={(name, date) => onInputChange({
                target: {
                  name: 'start_date',
                },
                formattedValue: date,
              })}
            />
          </div>
        </Col>
        <Col md={4} className='pr-48'>
          <div className='form-input custom-date'>
            <label>End Date</label>
            <SingleDatePicker
              date={end_date}
              name='end_date'
              onChange={(name, date) => onInputChange({
                target: {
                  name: 'end_date',
                },
                formattedValue: date,
              })}
              minDate={start_date}
            />
          </div>
        </Col>
        <Col md={4} />
        <Col md={4} className='pr-48'>
          <Input
            value={question}
            name='question'
            type='text'
            labelContent='Question'
            placeholder='Question'
            onChange={(event) => onInputChange(event)}
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
            ref={(ref) => (refsObj.question = ref)}
          />
        </Col>
        <Col md={4} className='pr-48'>
          <CustomSelect
            name='audit'
            onChange={(event) => {
              onDropDownChange(event.title, 'audit_type');
            }}
            options={auditType}
            labelContent='Audit Type'
            placeholder='Select Audit Type'
            getOptionValue={({ title }) => title}
            getOptionLabel={({ label }) => label}
            value={auditType.filter(
              ({ title }) => title === audit_type,
            )}
            ref={(ref) => (refsObj.audit_type = ref)}
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
          />
        </Col>
      </Row>
    </div>
  );
};

BasicDetails.propTypes = propTypes;
BasicDetails.defaultProps = defaultProps;

export default BasicDetails;
