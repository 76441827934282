import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { data } from './config';
import {
  dataProps,
  paginationProps,
} from '../../../../../data/enums/Component';
import { Icon } from '../../../../../components';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';

const propTypes = {
};

const defaultProps = {
};

const SettingView = ({ ...props }) => {
  const { onIconClick } = props;
  return (
    <Fragment>
      {data.map((cardData, i) => (
        <div className='settings__card'>
          <h3 className='settings__card--header'>
            <Icon iconName={cardData.icon} />
            <span className='main-title'>{`${cardData.mainTitle} Settings`}</span>
          </h3>
          <ul className='settings__card--body'>
            {cardData.dataList.map((d, index) => (
              <li onClick={() => onIconClick(`${i + 1}${index + 1}`)}>
                <span className='title'>{d.title}</span><Icon iconName='angle-right' />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </Fragment>
  );
};

SettingView.propTypes = propTypes;

SettingView.defaultProps = defaultProps;

const SettingViewWithErrorAndLoading = withErrorAndLoadingScreen(SettingView);

export default SettingViewWithErrorAndLoading;
