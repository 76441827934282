import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import PromotionForm from './Form';
import { DialogFormWrapper, DialogWrapper } from '../../common';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { refGenerator } from '../../../utils/refGenerator';
import withAlert from '../../../utils/composition/withAlert';
import { getPermissionForPromotion, getPermissionForPromotionReoprtDownload } from '../../base/permission';
import { PROMOTION_DETAILS, PROMOTION } from '../../../data/enums/Route';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import {
  breadCrumbConfig,
  form as formConfig,
  crudSuccess,
  title,
  crudRequest as crudRequestConfig,
  filterConfig,
  multipleReplicateForm,
} from './config';
import { clone } from '../../../utils/objectProcessor';
import { PROGRAM } from '../../../data/enums/Permission';
import PromotionStyled from './PromotionStyled';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';
import ExtendPromotionForm from './ExtendPromotionForm';
import { EVENT_OPERATION } from '../../../v4/constants/EventOperation';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getPromotionList: PropTypes.func.isRequired,
  replicatePromotion: PropTypes.func.isRequired,
  replicatePromotions: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Promotion extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      checkedList: [],
      type: '',
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      showBulkSelect: true,
      dialog:{
        type:'',
        element:'',
      }
    };
    this.permission = getPermissionForPromotion();

    const { downloadReport, displayAlert } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );

    this.formReference = refGenerator(formConfig.validationField);

    const serverCall = {
      [EVENT_OPERATION.REPLICATE]: props.replicatePromotion,
    };

    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      },
      this.getStateData,
      [],
    );

    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;

    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.PROMOTION
    );
  };

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.PROMOTION);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  getStateData = () => {
    const { data } = this.state;

    return data;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;

    displayAlert(ALERT_TYPE.DANGER, error);
  };

  loadTableData = () => {
    const { getPromotionList } = this.props;
    const { data, queryParameters } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    // all urls for search, filter and pagination
    this.setFilterStateInStorage();
    getPromotionList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.promotions && response.data.promotions.rows) || [];
          data.total = (response.data.promotions && response.data.promotions.count) || 0;
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onPromotionItemClick = (type, id) => {
    const { history } = this.props;
    if (type === EVENT_OPERATION.CREATE) {
      history.push(`/${PROMOTION}/create`);
    } else {
      history.push(`/${PROMOTION_DETAILS}/${id}`);
    }
  };

  handleBulkDelete = (type, response) => {
    const { displayAlert } = this.props;
    const { data } = this.state;
    displayAlert(ALERT_TYPE.SUCCESS, crudSuccess[type].message);
    crudResponseProcessor(
      modifiedData => {
        this.setState({ data: modifiedData });
      },
      () => data,
    )(type)(response);
  };

  handleMultiReplicateType = type => {
    this.setState({
      type,
    });
  };

  getCheckedListData = checkedList => {
    this.setState(
      {
        checkedList,
      },
      () => {
        this.setState({
          showBulkSelect: true,
        });
      },
    );
  };

  multipelReplicateFormSubmit = (value, details) => {
    const { checkedList, showBulkSelect } = this.state;
    const { replicatePromotions, displayAlert } = this.props;
    replicatePromotions(
      {
        input: {
          id: checkedList,
          startDate: details.startDate,
          endDate: details.endDate,
        },
      },
      {
        handleSuccess: response => {
          const { replicatePromotions } = response.data;
          const { data } = this.state;
          this.setState(
            {
              data: {
                list: [...data.list, ...replicatePromotions],
                total: data.total + replicatePromotions.length,
              },
              showBulkSelect: !showBulkSelect,
            },
            () => {
              this.setState({
                showBulkSelect: true,
              });
            },
          );
          const promotionType = checkedList.length > 1 ? 'Multiple Promotions' : 'Promotion';
          displayAlert(ALERT_TYPE.SUCCESS, `${promotionType} replicate successfully`);
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  handleReplicateAllClick = (type, element ) => {
      this.setState({
        dialog: {
          type,
          element,
        },
      });
  };

  resetDialog = () =>{
    this.setState({
      dialog: {
        type:'',
        element:'',
      },
    });
  };

  handleReplicateAllSubmit =(type,value) =>{
    const {extendPromotions,displayAlert} =this.props;
    const {queryParameters} =this.state;
    extendPromotions(
      {
        input:{
          from:queryParameters.date,
          to:{
            start:value.startDate,
            end: value.endDate,
          }
        }
      },
      {
        handleSuccess: response => {
          this.loadTableData();
          displayAlert(ALERT_TYPE.SUCCESS, `All promotions extended successfully`);
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    )

  }  
  render() {
    const { data, display, queryParameters, checkedList, type, showBulkSelect, filterMenu,dialog } = this.state;
    const { serverResponseWaiting } = this.props;
    const checkMultipleReplicate = type === EVENT_OPERATION.REPLICATE;
    return (
      <PromotionStyled>
            {dialog.type && (
            <DialogFormWrapper
              type={dialog.type}
              dialogElement={dialog.element}
              formConfig={multipleReplicateForm[dialog.type]}
              onDialogCancel={this.resetDialog}
              onDialogSubmit={this.handleReplicateAllSubmit}
              refsObj={this.formReference}
              renderDialog={({ enableErrorDisplay, dialogData,handleDateChange }) => (
                <Fragment>
                  {dialog.type===EVENT_OPERATION.REPLICATE_ALL&&(
                    <ExtendPromotionForm
                    data={dialogData}
                    totalCount={data.total}
                    enableErrorDisplay={enableErrorDisplay}
                    handleDateChange={handleDateChange}
                    />
                  )}
              </Fragment>
              )}
            />
          )}  
        <DialogWrapper
          onDialogSubmit={checkMultipleReplicate ? this.multipelReplicateFormSubmit : this.onFormSubmit}
          formConfig={checkMultipleReplicate ? multipleReplicateForm[checkMultipleReplicate] : formConfig}
          refsObj={this.formReference}
          title={title}
          renderDialog={({
            onDialogSubmit,
            handleDialogInputChange,
            handleDialogDropDownChange,
            dialogData,
            enableErrorDisplay,
          }) => (
            <Fragment>
              <PromotionForm
                show
                data={dialogData}
                checkedList={checkedList}
                onFormSubmit={onDialogSubmit}
                loading={serverResponseWaiting}
                enableErrorDisplay={enableErrorDisplay}
                handleInputChange={handleDialogInputChange}
                handleDropDownChange={handleDialogDropDownChange}
                refsObj={this.formReference}
              />
            </Fragment>
          )}
          render={({ onDialogItemClick }) => (
            <Fragment>
              <div className="section-header">
                <PanelStyled>
                  <PageHeader
                    display={display}
                    breadCrumb={breadCrumbConfig}
                    config={{
                      title,
                      create: this.permission.create,
                      download: getPermissionForPromotionReoprtDownload(),
                      filter: true,
                      date: true,
                      search: true,
                      replicateAll:true,
                    }}
                    filter={{
                      date: queryParameters.date,
                      menuList: filterMenu,
                      onFilterChange: this.basePaginationService.handleFilterChange,
                    }}
                    downloadConfig={{
                      domain: PROGRAM.PROMOTIONAL_SKU,
                    }}
                    queryParameters={queryParameters}
                    controlDisplay={this.controlDisplay}
                    handleCreateClick={this.onPromotionItemClick}
                    resetFilter={this.basePaginationService.resetFilter}
                    clearSearchText={this.basePaginationService.clearSearchText}
                    handleSearchChange={this.basePaginationService.handleSearchInputChange}
                    handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                    handleDownloadClick={this.basePaginationService.handleDownloadClick}
                    handleMultiReplicateType={this.handleReplicateAllClick}
                  />
                </PanelStyled>
              </div>
              <div className="section-content table-present">
                <View
                  data={data}
                  permission={this.permission}
                  pagination={queryParameters.pagination}
                  onIconClick={onDialogItemClick}
                  loading={serverResponseWaiting}
                  onPageChange={this.basePaginationService.onPageSelect}
                  onBulkDelete={this.handleBulkDelete}
                  onPromotionItemClick={this.onPromotionItemClick}
                  onReplicateTypeClick={this.handleMultiReplicateType}
                  getCheckedListData={this.getCheckedListData}
                  showBulkSelect={showBulkSelect}
                />
              </div>
            </Fragment>
          )}
        />
      </PromotionStyled>
    );
  }
}
Promotion.propTypes = propTypes;

Promotion.defaultProps = defaultProps;

export default withAlert()(Promotion);
