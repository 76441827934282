import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as queryService from '../../../base/query.service';
import { COVERAGE_TYPE } from '../../../../data/enums/GraphQL';
import {
  COVERAGE_PARENT_DETAIL_MAPPER,
  getFixedFilterParam,
  TableBody,
  TableHeader,
  filterConfig,
  productiveCoverageLink,
} from './tableConfig';
import { apiFilterProcessor } from '../../../../utils/api';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../base/PageHeader';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import CoverageView from '../View';
import withAlert from '../../../../utils/composition/withAlert';
import { reports } from '../../../../data/enums/Route';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  displayAlert: PropTypes.func.isRequired,
  getProductiveCoverage: PropTypes.func.isRequired,
};

const defaultProps = {
  location: {
    state: {},
  },
  serverResponseWaiting: false,
};

class Detail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    const { state = {} } = props.location;
    this.state = {
      data: {
        list: [],
        coverage: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
        type: COVERAGE_PARENT_DETAIL_MAPPER[state.type || COVERAGE_TYPE.CATEGORY],
      },
    };
    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
    this.id = state.id;
    this.breadCrumbConfig = [
      {
        ...reports,
        state,
      },
      productiveCoverageLink,
    ];
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { queryParameters, data } = this.state;
    const { getProductiveCoverage } = this.props;
    getProductiveCoverage(
      {
        type: queryParameters.type,
        filter: {
          filters: [
            ...apiFilterProcessor(queryParameters.filter),
            ...getFixedFilterParam(queryParameters.type, this.id),
          ],
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: response => {
          const { getCoverageDetails = {} } = response.data;
          data.list = (getCoverageDetails && getCoverageDetails.details) || [];
          data.coverage = getCoverageDetails.coverage || 0;
          this.setState({ data });
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const { data, queryParameters, display } = this.state;
    const { serverResponseWaiting, location } = this.props;
    const { state = {} } = location;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={this.breadCrumbConfig}
              config={{
                title: `${state.title} ${data.coverage ? `: ${fixedFloatAndCommas(data.coverage)}%` : ''}`,
                create: false,
                download: false,
                filter: false,
                search: true,
                date: true,
                singleDate: false,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <CoverageView
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            handleRowClick={this.handleTableRowClick}
            loading={serverResponseWaiting}
            viewType={queryParameters.type}
            TableHeader={TableHeader}
            TableBody={TableBody}
            onPageChange={this.basePaginationService.onPageSelect}
          />
        </div>
      </Fragment>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default withAlert()(Detail);
