const regex = /BBSM|Big Mart|Saleways|Salesways/gi;

const dimension = {
  height: 500,
  width: 1450,
};

const SOS = 'Share of Shelf';
const SOD = 'Share of Display';
const OSA = 'On Shelf Availability';

const brandColor = {
  BBSM: {
    sos: '#a2cefa',
    soa: '#3381ff',
    sod: '#0046B8',
  },
  BigMart: {
    sos: '#fac6a7',
    soa: '#f27024',
    sod: '#612d0e',
  },
  Salesways: {
    sos: '#b3e6cf',
    soa: '#43c187',
    sod: '#1a4d36',
  },
};

const legendBBSM = [
  {
    title: SOS,
    color: '#a2cefa',
  },
  {
    title: OSA,
    color: '#3381ff',
  },
  {
    title: SOD,
    color: '#0046B8',
  },
];

const legendBigMart = [
  {
    title: SOS,
    color: '#fac6a7',
  },
  {
    title: OSA,
    color: '#f27024',
  },
  {
    title: SOD,
    color: '#612d0e',
  },
];

const legendSalesways = [
  {
    title: SOS,
    color: '#b3e6cf',
  },
  {
    title: OSA,
    color: '#43c187',
  },
  {
    title: SOD,
    color: '#1a4d36',
  },
];

const getLegend = outlet_channel => {
  const data = {
    BBSM: legendBBSM,
    BigMart: legendBigMart,
    Salesways: legendSalesways,
  };
  return data[outlet_channel];
};

export { regex, dimension, SOS, SOD, OSA, brandColor, getLegend };
