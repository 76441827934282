import styled from 'styled-components';

const OrderProcessingWrap = styled.div`
  position: relative;

  .subtitle {
    position: absolute;
    top: -49px;
    left: 24px;
    font-size: 18px;
    font-weight: normal;
    color: #6b6c7e;
  }

  ul.list li {
    padding-right: 24px;
  }

  section {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .container-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }

  .tab-container {
    overflow-x: hidden;
  }

  .tab-section {
    table {
      th {
        &:nth-child(2) {
          width: 8%;
        }

        &:last-child {
          width: 5%;
        }
      }

      tr {
        &:hover {
          background: #f0f5ff;
        }

        &.active {
          background: #f0f5ff;

          td {
            border-bottom: 1px solid #0c5fff;
          }
        }
      }
      td.distributor{
            min-width:100px;
            .span-wrap{
              display:flex;
              span:first-child{
              margin-right:10px;
              margin-bottom: 4px;
              display: inline-block;
            }
            }
          }

      th:first-child,
      td:first-child {
        padding-left: 48px;
      }

      th:last-child,
      td:last-child {
        padding-right: 48px;
      }

      .collapsible-body-tr td.collapsible-body-td {
        padding: 0 !important;
      }
    }

    .split-form-wrap {
      margin: -40px -24px 0;
      padding: 16px 24px 0;

      table {
        th {
          &:first-child {
            padding: 13px 24px;
          }
        }
        tbody {
          tr td:first-child {
            padding: 13px 24px !important;
          }

          tr td:last-child {
            padding: 13px 24px !important;
          }
        }
      }
    }

    .collapsible-body-tr {
      &:hover,
      &:focus {
        background: transparent;
      }

      table {
        box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
        border: none;

        tbody {
          tr:last-child {
            border-top: 1px solid #e7e7ed;
          }
        }

        tr {
          th {
            background: transparent;
            width: auto;
          }

          th,
          td {
            border-bottom: none;
          }
        }
      }
    }
  }

  table th:first-child,
  table td:first-child {
    width: 13%;
  }

  .tab-section {
    table thead tr th:first-child {
      padding-left: 70px;
    }
  }

  table tbody {
    tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(180deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #004ad7;
        }
      }
      td:first-child {
        > span {
          position: relative;
          padding-left: 26px;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }
    }
  }

  .add-btn {
    margin-top: 16px;
    padding: 0 24px;
    margin-bottom: -8px;
  }

  .stock-label {
    border: 1px solid transparent;
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    border-radius: 2px;
    padding: 1px 3px;

    &.IN {
      border-color: #47c464;
      color: #287d3c;
    }

    &.OUT {
      border-color: rgba(218, 20, 20, 0.4);
      color: #da1414;
    }
    &.INSUFFICIENT {
      border-color: #ff8f39;
      color: #b95000;
    }
  }

  .split-form-container {
    .table-wrap {
      max-height: 350px;
      overflow-y: auto;
    }

    .stats {
      margin: 16px -24px;
      padding: 0 24px 16px;
      border-bottom: 1px solid #e7e7ee;
    }

    .stock {
      margin: 0px -24px;
      padding: 0 24px 16px;
      border-bottom: 1px solid #e7e7ee;
    }
    .split-header {
      label {
        font-size: 14px;
        font-weight: 600;
        display: block;
        color: #272833;
        line-height: 21px;
        margin-bottom: 4px;
      }

      span {
        display: inline-block;
        font-size: 14px;
        color: #6b6c7e;
      }

      p {
        margin: 0;
        padding-bottom: 16px;
        font-size: 14px;
        line-height: 21px;
        color: #6b6c7e;
      }
    }

    .split-body {
      margin: 0 -24px;

      .table-paginate {
        text-align: right;
        padding: 10px 0;
      }
    }
  }

  .tab-section .promotion-popup {
    table {
      thead tr th {
        &:first-child {
          padding-left: 24px;
        }
        &:last-child {
          padding-right: 24px;
        }
      }
      tbody tr td {
        &:first-child {
          padding-left: 24px;
        }
        &:last-child {
          padding-right: 24px;
        }
      }
      tfoot tr td {
        &:first-child {
          padding-left: 24px;
        }
        &:last-child {
          padding-right: 24px;
        }
      }
    }
  }

  .promotion-popup {
    margin: -8px -24px -24px;
    padding: 0 24px;

    h3 {
      font-size: 14px;
      line-height: 21px;
      margin-top: 0;
      margin-bottom: 4px;
    }

    .table {
      margin: 16px -24px 0;
    }
  }
  .clickable {
    cursor: pointer;
  }
`;

export default OrderProcessingWrap;
