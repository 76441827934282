import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import withAlert from '../../../../../utils/composition/withAlert';
// import sampleData from '../../details/data';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { DialogFormWrapper, DialogWrapper } from '../../../../common';
import View from './View';
import UserDetailsStyled from './UserDetailsStyled';
import { Input, SingleDatePicker } from '../../../../../components';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import sampleData from './data';
import { EVENT_OPERATION } from '../../../../../v4/constants/EventOperation';
import Form from './Form';
import { formConfig } from './config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getUserTargetDetails: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  updateUserFundamentalTargetDetail: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
  dialogData: PropTypes.instanceOf(Object),
};

const defaultProps = {
  serverResponseWaiting: false,
  location: {},
  dialogData: {},
};

class UserDetails extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      data: sampleData,
      titleWithouttype: false,
      detailId: parseInt(match.params.id, 10),
      dialog: {
        type: '',
        element: '',
      },
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: false };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const { getUserTargetDetails, displayAlert } = this.props;
    const { detailId } = this.state;

    getUserTargetDetails(
      {
        targetId: detailId,
      },
      {
        handleSuccess: response => {
          this.setState({
            data: response.data.getUserTargetDetails || [],
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleIconClick = (id, targetData = {}, type) => {
    this.setState({
      dialog: {
        type,
        element: targetData,
      },
    });
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onFormSubmit = (type, dialogData) => {
    const { updateUserFundamentalTargetDetail, displayAlert } = this.props;
    const { dialog } = this.state;

    updateUserFundamentalTargetDetail(
      {
        input: {
          ulpo: +dialogData?.ulpo,
          tls: +dialogData?.tls,
          sbd: +dialogData?.sbd,
          billCuts: +dialogData?.bill_cuts,
          dgp: +dialogData?.dgp,
          activeOutlets: +dialogData?.active_outlets,
          productivity: +dialogData?.productivity,
          targetId: dialog?.element?.userTargetId,
          userId: dialog?.element?.id,
        },
      },
      {
        handleSuccess: res => {
          this.getList();
          displayAlert(ALERT_TYPE.SUCCESS, `${MESSAGE_EVENT_OPERATION.UPDATE}`);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  onDialogCancel = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  render() {
    const { data, brandDialogData, titleWithouttype, dialog } = this.state;
    const { serverResponseWaiting, location } = this.props;
    const { state = {} } = location;
    const title = state.title;
    const details = state.details;

    return (
      <UserDetailsStyled>
        {dialog?.type && (
          <div className="targets-wrapper">
            <DialogFormWrapper
              type={dialog?.type}
              dialogElement={dialog?.element}
              formConfig={formConfig[dialog?.type]}
              onDialogCancel={this.onDialogCancel}
              onDialogSubmit={this.onFormSubmit}
              formTitle={dialog?.element?.user}
              submitClickedControl
              renderDialog={({ dialogData, enableErrorDisplay, handleInputChange, refsObj, handleDateChange }) => (
                <>
                  {(dialog?.type === EVENT_OPERATION.UPDATE || dialog?.type === EVENT_OPERATION.READ) && (
                    <>
                      <Form
                        show
                        type={dialog?.type}
                        refsObj={refsObj}
                        data={dialogData}
                        enableErrorDisplay={enableErrorDisplay}
                        handleInputChange={handleInputChange}
                        handleDateChange={handleDateChange}
                        details={details}
                      />
                    </>
                  )}
                </>
              )}
            />
          </div>
        )}

        <View
          data={data}
          onIconClick={this.onDialogItemClick}
          loading={serverResponseWaiting}
          handleIconClick={this.handleIconClick}
          detailTitle={title}
        />
      </UserDetailsStyled>
    );
  }
}

UserDetails.propTypes = propTypes;

UserDetails.defaultProps = defaultProps;

export default withAlert()(UserDetails);
