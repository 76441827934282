import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_ROUTE_VISIT_FREQUENCY_LIST } from '../../../common/GqlAPI';

const GET_VISIBILITY_AUDIT_LIST = gql`
query getVisibilityAuditList($limit:Int, $offset:Int,$filter:FilterInput){
  getVisibilityAuditList(limit: $limit, offset: $offset, filter: $filter ){
    rows{
      id, 
      title, 
      assigned_to, 
      count_days
    }
    count
  }
  }
`;


const GET_USERS_LIST = gql`
query users($offset: Int, $limit: Int, $filter: FilterInput){
  users(offset: $offset, limit: $limit, filter: $filter){
  rows{
      id
      firstName
      lastName
      fullName
      roleId
      parentUserId
    }
  }
}
`;

const GET_VISIBILITY_AUDIT_BASIC_DETAIL = gql`
query getVisibilityAuditbasicbyId($mtvisibilityAuditId: Int!){
  getVisibilityAuditbasicbyId (mtvisibilityAuditId: $mtvisibilityAuditId){
    id
    title
    assigned_to
    count_days
    active
  }
}

`;

const CREATE_VISIBILITY_AUDIT = graphql(
  gql`
    mutation($input: MTVisibilityAuditInput!) {
      createVisibilityAudit(input: $input) {
        id
        title
        assigned_to
        count_days 
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createVisibilityAudit: variables => mutate({
        variables,
      }),
    }),
  },
);

const UPDATE_VISIBILITY_AUDIT = graphql(
  gql`
  mutation($id: Int!, $input: MTVisibilityAuditInput!) {
    editVisibilityAudit(id: $id, input: $input) {
      id
      title
      assigned_to
      count_days 
    }
  }
`,
  {
    props: ({ mutate }) => ({
      editVisibilityAudit: variables => mutate({
        variables,
      }),
    }),
  },
);

const DELETE_VISIBILITY_AUDIT = graphql(gql`
mutation ($id: Int!){
  deleteVisibilityAudit(id: $id){
    id
    title
    active
  }
  }`, {
  props: ({ mutate }) => ({
    deleteVisibilityAudit: variables => mutate({
      variables,
    }),
  }),
});

const BULK_DELETE_VISIBILITY_AUDIT = graphql(gql`
mutation ($ids: [Int]!){
  deletebulkVisibilityAudit(ids:$ids)
  }`, {
  props: ({ mutate }) => ({
    deletebulkVisibilityAudit: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getVisibilityAudit: GET_VISIBILITY_AUDIT_LIST,
  getRouteVisitFrequencyList: GET_ROUTE_VISIT_FREQUENCY_LIST,
  getUsersList: GET_USERS_LIST,
  getVisibilityAuditbasicbyId: GET_VISIBILITY_AUDIT_BASIC_DETAIL,
};

const mutation = {
  editVisibilityAudit: UPDATE_VISIBILITY_AUDIT,
  createVisibilityAudit: CREATE_VISIBILITY_AUDIT,
  deleteVisibilityAudit: DELETE_VISIBILITY_AUDIT,
  deletebulkVisibilityAudit: BULK_DELETE_VISIBILITY_AUDIT,
};


export {
  query,
  mutation,
  CREATE_VISIBILITY_AUDIT,
  UPDATE_VISIBILITY_AUDIT,
  DELETE_VISIBILITY_AUDIT,
  BULK_DELETE_VISIBILITY_AUDIT,
};
