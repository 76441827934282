
import { compose } from 'recompose';
import OutletChainDetails from './Detail';
import {
  query, mutation,CREATE_OUTLET,UPDATE_OUTLET
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedOutletChainDetails = compose(
  UPDATE_OUTLET,
  CREATE_OUTLET,
  withRequestTracker({
    query,
    mutation,
  }),
)(OutletChainDetails);

export default composedOutletChainDetails;

