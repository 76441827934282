import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_CATALOG_DETAILS_HIERARCHY = gql`
query getvaluesForVisibilityAudit($mtvisibilityAuditId:Int){
  getvaluesForVisibilityAudit(mtvisibilityAuditId: $mtvisibilityAuditId){
    id
    title
    status
    childCategory {
      id
      title
      status
      childBrands {
        id
        title
        status
        principal_id
        product_category_id
      }
    }
  }
}
`;

const CREATE_VISIBILITY_AUDIT_DETAILS = graphql(
  gql`
    mutation($input: [MTVisibilityAuditDetailInput]!) {
      createVisibilityAuditDetails(input: $input) {
        id
        visibility_audit_id
        product_category_id
        principal_id
        brand_id 
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createVisibilityAuditDetails: variables => mutate({
        variables,
      }),
    }),
  },
);

const DELETE_VISIBILITY_AUDIT_DETAIL = graphql(
  gql`
    mutation($brandId: [Int]!, $visibilityAuditId: Int!) {
      deleteVisibilityAuditDetail(brandId: $brandId,visibilityAuditId:$visibilityAuditId ) {
        id
        visibility_audit_id
        product_category_id
        principal_id
        brand_id 
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteVisibilityAuditDetail: variables => mutate({
        variables,
      }),
    }),
  },
);

const query = {
  getCatalogDetailsHierarchy: GET_CATALOG_DETAILS_HIERARCHY,
};

const mutation = {
  createVisibilityAuditDetails: CREATE_VISIBILITY_AUDIT_DETAILS,
  deleteVisibilityAuditDetail: DELETE_VISIBILITY_AUDIT_DETAIL,
};


export {
  query, mutation, CREATE_VISIBILITY_AUDIT_DETAILS, DELETE_VISIBILITY_AUDIT_DETAIL,
};
