import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_OUTLET_CHAIN, GET_OUTLET_BY_CHAIN_ID } from '../../../../../inventory/API';
import { GET_NEW_CHANNEL_LIST } from '../../../../../../channelV3.1/API';

const GET_DETAILS_SBD_TEMPLATE = gql`
query getMTSBDTemplateDetails($sbdTemplateId: Int!,$buId:Int,$categoryId:Int,$brandId:Int){
    getMTSBDTemplateDetails(sbdTemplateId:$sbdTemplateId,buId:$buId,categoryId:$categoryId,brandId:$brandId){
        rows{
            basicSBDDetails{
                id
                title
                active
                end_date
                tags
                numberofSkus
                start_date
                sbdcategory:category_id
                outlet_id
                channel_id
            }
            Category{
                id
                count
            }
            Brand{
                id
                count
            }
            SkuFamily{
                id
                title
                skus{
                    id
                    title
                    status
                }
                status
            }
        }
        count
  }
}
`;


const GET_BRAND_DETAILS = gql`
    query getBrandDetails {
        catalogDetails(catalogId: 3, limit:1000){
            rows {
                id
                title
                parentCatalogDetailId
            }
        }
    }
`;


const GET_PRODUCT_GROUP_DETAILS = gql`
    query getProductGroupDetails($parentCatalogDetailId:Int) {
        catalogDetails(catalogId:2,parentCatalogDetailId:$parentCatalogDetailId){
            rows {
                id
                title
                catalogId
            }
        }
    }
`;

const GET_SKU_FAMILY_DETAILS = gql`
    query getSKUFamilyDetails($parentCatalogDetailId: Int) {
        catalogDetails(offset:0, limit:1000, catalogId: 7, parentCatalogDetailId:$parentCatalogDetailId){
            rows {
                id
                title
                skus: SKUs{
                    id
                    title
                    status
                }
            }
        }
    }
`;
const CREATE_SBD_TEMPLATE = graphql(gql`
    mutation createSBDTemplate($id: Int,$input: SBDTemplateInput!){
        createSBDTemplate(id: $id,input: $input){
        id 
        title
      }
    }`, {
  props: ({ mutate }) => ({
    createSBDTemplate: (variables) => mutate({
      variables,
    }),
  }),
});
const GET_RETAIL_OUTLETS = gql`
query retailoutlets($limit:Int, $offset: Int, $filter: FilterInput) {
  retailOutlets(limit:$limit, offset:$offset, filter:$filter){
    rows{
      title
      id
    }
    count
  }
}`;


const query = {
  getBrandDetails: GET_BRAND_DETAILS,
  getOutletChain: GET_OUTLET_CHAIN,
  getRetailOutletByChainId: GET_OUTLET_BY_CHAIN_ID,
  getCategoryList: GET_NEW_CHANNEL_LIST,
  getProductGroupDetails: GET_PRODUCT_GROUP_DETAILS,
  getSKUFamilyDetails: GET_SKU_FAMILY_DETAILS,
  getMTSBDTemplateDetails: GET_DETAILS_SBD_TEMPLATE,
  getRetailOutlet: GET_RETAIL_OUTLETS,
};

const mutation = {
  createSBDTemplate: CREATE_SBD_TEMPLATE,
};

export {
  query, mutation,
  GET_BRAND_DETAILS, GET_PRODUCT_GROUP_DETAILS,
  CREATE_SBD_TEMPLATE, GET_RETAIL_OUTLETS,GET_DETAILS_SBD_TEMPLATE
};
