import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DialogBody, DialogFooter, DialogHeader } from '../../components';
import { has } from '../../utils/objectPrototypes';
import { FORM_CONFIG } from '../../data/enums/config';
import { EVENT_OPERATION } from '../../data/enums/EventOperation';
import { Icon, Button } from '../../v4/components';
import { OPERATION } from '../tas/callplan/callPlanDetails/config';

const propTypes = {
  type: PropTypes.string,
  enableReset: PropTypes.bool,
  activeStatus: PropTypes.bool,
  onDialogSubmit: PropTypes.func,
  onDialogCancel: PropTypes.func,
  renderDialog: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleRadioButtonChange: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  getState: PropTypes.func,
  updateState: PropTypes.func,
  dialogData: PropTypes.instanceOf(Object),
  dialogElement: PropTypes.instanceOf(Object),
  formConfig: PropTypes.shape({
    mapper: PropTypes.func,
    refsObj: PropTypes.instanceOf(Object),
    title: PropTypes.string,
    validationRequired: PropTypes.bool,
  }),
  getExtraFooterElements: PropTypes.func,
  withOutPadding: PropTypes.bool,
  controlDialogClose: PropTypes.bool,
  extraFooterCallBack: PropTypes.func,
  formTitle: PropTypes.string,
  handleMultipleUpload: PropTypes.func,
  disableOk:PropTypes.bool,
  disableBackIcon: PropTypes.bool,
  enableNoOrderBtn:PropTypes.bool,
  submitClickedControl:PropTypes.bool,
  loading:PropTypes.bool,
  footerRequired: PropTypes.bool,
  secondaryTitle: PropTypes.string,
};

const defaultProps = {
  type: '',
  enableReset: true,
  dialogData: {},
  activeStatus: false,
  renderDialog: () => null,
  enableErrorDisplay: false,
  onDialogSubmit: () => null,
  onDialogCancel: () => null,
  handleFormSubmit: () => null,
  handleFileUpload: () => null,
  handleInputChange: () => null,
  handleDateChange: () => null,
  handleDropDownChange: () => null,
  handleRadioButtonChange: () => null,
  handleMultipleUpload: () => null,
  dialogElement: {},
  formConfig: {
    mapper: () => ({}),
    refsObj: {},
    title: '',
    validationRequired: true,
  },
  getState: () => null,
  withOutPadding: false,
  updateState: () => null,
  submitClickedControl:false,
  controlDialogClose: false,
  getExtraFooterElements: () => <span />,
  extraFooterCallBack: () => null,
  formTitle: '',
  disableOk: false,
  disableBackIcon: false,
  enableNoOrderBtn:false,
  loading:false,
  footerRequired: true,
  secondaryTitle: '',
};

class ModalWrapper extends Component {
  constructor() {
    super();
    this.state = {
      submitClicked: false,
      uploadInvalid: false,
    };
  }

  handleSubmit = () => {
    const {
      type,
      dialogElement,
      dialogData,
      onDialogSubmit,
      onDialogCancel,
      handleFormSubmit,
      enableReset,
      activeStatus,
    } = this.props;
    if (this.checkValidationStatus()) {
      handleFormSubmit();
    } else {
      const data = activeStatus ? dialogElement : dialogData;
      onDialogSubmit(type, data);
      if (enableReset) onDialogCancel();
    }
  };

  checkValidationStatus = () => {
    const { formConfig } = this.props;
    return has.call(formConfig, FORM_CONFIG.VALIDATION_REQUIRED) ? formConfig.validationRequired : false;
  };

  disableOnInvalidCSV = () => {
    // for csv date
    this.setState({ uploadInvalid: true });
  };

  render() {
    const { submitClicked, uploadInvalid } = this.state;
    const {
      formConfig: { title },
      formConfig: { buttonName },
      formConfig: { refsObj },
      formConfig: { getExtraFooterElements = () => <span /> },
      handleInputChange,
      handleDropDownChange,
      handleFileUpload,
      dialogData,
      onDialogCancel,
      renderDialog,
      enableErrorDisplay,
      handleMultipleUpload,
      getState,
      updateState,
      type,
      controlDialogClose,
      extraFooterCallBack,
      formTitle,
      withOutPadding,
      footerRequired,
      secondaryTitle,
      location,
      handleDateChange,
      handleRadioButtonChange,
      onNoOrderClick,
      disableOk,
      disableBackIcon,
      enableNoOrderBtn,
      submitClickedControl,
      loading,
    } = this.props;

    const { handleSubmit, disableOnInvalidCSV } = this;
    const newProps = {
      refsObj,
      getState,
      dialogData,
      updateState,
      handleFileUpload,
      handleInputChange,
      handleDropDownChange,
      enableErrorDisplay,
      handleMultipleUpload,
      disableOnInvalidCSV,
      handleDateChange,
      handleRadioButtonChange,
    };
        return (
      <Fragment>
        <Fragment>
          <div className="modal-wrapper">
            <div className={`modal-inner ${type ? type.toLowerCase() : ''}`}>
              <DialogHeader>
                {(type === OPERATION.NO_ORDER||type===OPERATION.PROMOTION) ? <div> 
                  {disableBackIcon?null:<img src='/image/icons/back.svg' alt='Back' onClick={() => onNoOrderClick(OPERATION.ADD_ORDER, dialogData)} style={{ cursor: 'pointer' }} />}
                  <h2 style={{ marginLeft: '12px', cursor: 'pointer' }}>{formTitle ? `${title} ${formTitle}` : title} {secondaryTitle && (<span>{secondaryTitle}</span>)}</h2> </div> : <h2>{formTitle ? `${title} ${formTitle}` : title} {secondaryTitle && (<span>{secondaryTitle}</span>)}</h2>}
                <Button className={location ? 'disabled-btn' : ''}>
                <Icon iconName="times" onClick={e => onDialogCancel(e)} style={{ cursor: 'pointer' }} />
                </Button>
               
              </DialogHeader>
              <DialogBody
                type={
                  type === EVENT_OPERATION.UPLOADED || type === EVENT_OPERATION.UPLOAD || type === EVENT_OPERATION.DEBOARD_OUTLET
                    ? ' dialog'
                    : withOutPadding
                      ? 'with-out-padding'
                      : ''
                }
              >
                {renderDialog(newProps)}
              </DialogBody>
              {!footerRequired ? <Fragment /> : (

              <DialogFooter>
                {getExtraFooterElements(extraFooterCallBack)}
                {type !== OPERATION.CALL_ANALYSIS ?
                  (<Button secondary onClick={() => onDialogCancel()} className={location ? 'disabled-btn' : ''}>
                    <span>Cancel</span>
                  </Button>) : <></>
                }
                {type === OPERATION.ADD_ORDER &&  enableNoOrderBtn && dialogData.quantity ===0 ? (
                  <Button
                    secondary
                    className='no-order-btn'
                    onClick={() => onNoOrderClick(OPERATION.NO_ORDER, dialogData)}>
                    <span>No Order</span>
                  </Button>
                ) : <Fragment />}
                {type !== EVENT_OPERATION.READ &&
                  type !== EVENT_OPERATION.READ_SUB_TYPE &&
                  type !== EVENT_OPERATION.VIEW ? (
                  <Button
                    primary
                    className={submitClicked ? 'disabled-btn' : ''}
                    disabled={submitClicked || uploadInvalid || disableOk || loading}
                    onClick={e => {
                      if (controlDialogClose) this.setState({ submitClicked: submitClickedControl ? false: true });
                      handleSubmit(e);
                    }}
                  >
                    <span>{buttonName || 'Ok'}</span>
                  </Button>
                 
                  ) : (
                  <Fragment />
                )}
                </DialogFooter>
              )}
            </div>
            <div className="overlay" />
          </div>
        </Fragment>
      </Fragment>
    );
  }
}

ModalWrapper.propTypes = propTypes;

ModalWrapper.defaultProps = defaultProps;

export default ModalWrapper;
