import styled from 'styled-components';

const SBDStyled = styled.div`
 .top-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 48px;
    .button-section{
      display: flex;
      .create-btn{
        margin-left: 8px;
      }
    }
  }
  /* Create UI */
  .create-top-section{
    background: #FFFFFF;
    padding: 48px;
    padding-bottom: 24px;
    label{
      display: inline-block;
      margin-bottom: 4px;
      color: #272833;
      font-size: 14px;
      font-weight: 600;
    }
    .total-point{
      line-height: 35px;
      font-size: 28px;
      font-weight: 600;
    }
    .pr-48{
      padding-right: 48px;
    }
    .fundamental-weightage{
      padding: 12px;
      border-radius: 4px;
      background: #F7F8F9;
      .sos{
        display: flex;
        margin-bottom: 9px;
        align-items: center;
        justify-content: space-between;
        &:last-child{
        margin-bottom: 0;
        }
        .form-input{
          width: 150px;
        }
        .placeholder{
          margin-bottom: 0;
        }
      }
    }
    .datepicker-wrapper .filter-dropdown-card {
      z-index:99;
    }
  }
  .sbd-bottom-wrap .sbd-detail-table {
    &>table{
      thead {
        th:last-child{
          width:10px;
          padding-left:0;
        }
      }
    }
    .collapsible-body-tr{
        .collapsible-body-td:last-child {
          padding-right:0 !important;
        }
      }
  }
  .create-bottom-section{
    background: #F7F8F9;
    padding: 24px 48px;
    .react-tabs__tab-list {
      padding: 0 0 16px;
      background: #F7F8F9;
    }
    .table-wrap{
      .placeholder{
        width: 100px;
      }
      .placeholder::after {
        top: 11px;
        font-size: 14px;
      }
      thead{
        line-height: 21px;
        font-size: 14px;
        color:#6B6C7E;
       span{
         color:#A7A9BC;
         font-size: 10px;
         line-height: 10px;
         svg{
           color:#E7E7ED;
           height: 10.5px;
           width: 10.5px;
           margin: 0 4px;
         }
       }
      }
      tbody {
      tr {
        td {
          &:last-child:not(:first-child) {
            .view{
            margin-right: 40px;
            }
            span {
              cursor: pointer;
              color: #6B6C7E;
              font-size: 14px;
            }

            svg.icon {
              height: 14px;
              width: 14px;
              margin-right: 9px;
            }
          }

          &:first-child{
            border-left:0;
          }
        }
      }

      tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(-90deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #004AD7;
        }
      }

      td:first-child {
        > span {
          position: relative;
          padding-left: 26px;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }

    }
    }
    }
  }

  .panel-section{
    display:flex;
    .panel{
      background-color:#FFFFFF;
      border-radius: 4px;
        .p-title{
          margin:0;
          padding:24px ;
          font-size:10px;
          line-height:10px;
          color:#6B6C7E;
          text-transform:uppercase;
        }
        ul{
          height: calc(100vh - 285px);
          overflow-y: auto;
          padding: 0 12px;
          li{
            padding:9px 12px;
            font-size:12px;
            line-height:15px;
            font-weight:500;
            color:#6B6C7E;
            display:flex;
            justify-content:space-between;
            align-items:center;
            margin-bottom:12px;
            text-transform:capitalize;
            .count{
              display:inline-block;
              height:18px;
              line-height:18px;
              text-align:center;
              width:25px;
              background-color:#F1F2F5;
              border-radius:100px;
              font-size: 9px;
              position: relative;
              right: 8px;
              top: 2px;
            }
            p{
            margin:0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 125px;
            }
            .light{
             margin-right:16px;
            }
          
            &.active{
              background-color:#F7F8F9;
              color:#272833;
              border-radius: 4px;
              position:relative;
              &:after {
                  content: '';
                  position: absolute;
                  top: 13px;
                  right: 12px;
                  display: block;
                  width: 12px;
                  height: 12px;
                  border-top: 2px solid #0b5fff;
                  border-left: 2px solid #0b5fff;
                  -webkit-transform: rotate(135deg);
                  -ms-transform: rotate(135deg);
                  transform: rotate(135deg);
                  border-radius: 2px 2px 0 2px;
              }
            }
            &:hover{
              cursor:pointer;
            }
            &.inactive{
              &:hover{
                color: #0b5fff;
              }
            }
          }
        }
      &.two,
      &.one{
        width:215px;
        display:inline-block;
        margin-right:4px;
      }

      /* third panel styling only */
      &.three{
        flex:1;
        .panel-title{
          padding:14px 24px;
          display:flex;
          align-items:center;
          justify-content:space-between;
          .p-title{
            margin:0;
            padding:0;
          }
        }
        .div-tbody .collapsible-inner,
        .div-tbody .header-wrap,
        .div-thead{
          border-bottom:1px solid #E7E7ED;
          display:flex;
          padding:12px 24px;
          align-items:center;
          justify-content:space-between;
          background-color:#F1F2F5;
          font-size:12px;
          line-height:15px;
          color:#6B6C7E;
          font-weight:500;
          .status{
            display:flex;
            align-items:center;
            .form-input{
              margin-left:12px;
            }
          }
        }

        .div-tbody{
          height: calc(100vh - 358px);
          overflow-y: auto;
          background-color:#FBFBFC;
          .header-wrap{
              background-color:#FFFFFF;
              cursor:pointer;
              padding:15px 24px;
            }
              &:hover{
                background: #F0F5FF;
              }
          .header{
            .collapsible-inner,
            .header-wrap{
              background-color:#FFFFFF;
              padding:15px 24px;
            }
            .header-wrap{
              cursor:pointer;
              &:hover{
                background: #F0F5FF;
              }
                &>span{
                  position: relative;
                &::before{      
                    content:'';   
                    width: 0; 
                    height: 0; 
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid #6B6C7E;
                    transform: rotate(0deg);
                    transition: all 0.23s ease-in-out;
                    position: absolute;
                     top: 6px;
                      left: 0;
                  }
                  .text{
                    margin-left:30px;
                  } .badge{
                    margin-left: 8px;
                  }

                }
            }
           
            &.active{
              margin:16px 0;
              transition: all 0.4s ease-out;
              box-shadow: 0 4px 8px 0 rgba(39,40,51,0.12);
                .header-wrap{
                 background-color:#F0F5FF;
                 border-bottom:1px solid #0B5FFF;
                 span::before{
                  transform: rotate(180deg);
                  transition: all 0.23s ease-in-out;
                  border-top:6px solid #0B5FFF;
                  top:5px;
                 }
                }
                .collapsible-inner{
                  &>span{
                    margin-left:30px;
                  }
                  /* &:hover{
                    background: #F0F5FF;
                  } */
                }
            }

            &.inactive{
              margin:0;
              transition: all 0.4s ease-in;
              top:28px;
            }
          }
        }
/* only for table inside third panel */
.table-wrap{
    max-height: unset;
    height: calc(100vh - 286px);
    overflow-y: auto;
    border-radius: 0 0 4px 4px;
        table{
          border:0;
          td,
          th{
            &:first-child{
              padding:7px 12px 7px 24px;
              border-left:0;
            }
            &:last-child{
              padding:7px 24px 7px 12px;
              border-right:0;
            }
          }
          .switch{
            span{
              position:relative;
              top:-7px;
            }
          &>div{
            display:inline-block;
            margin-left:16px;
          }
          }
        }
      }
    }
    }
  }
  .table-wrap{
    tbody{
      td{
        /* &.tags{
          width:20%;
        } */
        &>span{
          display:inline-block;
          border:1px solid #A7A9BC;
          border-radius:2px ;
          padding:4px 8px;
          color:#6B6C7E;
          font-size:12px;
          margin-right:4px;
          margin-bottom:8px;
          position: relative;
          top: 4px;
        }
      }
    }
  }
`;

export default SBDStyled;
