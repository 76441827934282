import React from 'react';
import { useSelector } from 'react-redux';
import { Bar, Cell, ResponsiveContainer, XAxis } from 'recharts';
import Barchart from '../../../../../components/Chart/BarChart';
import { filterItems } from '../../../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { brandColor, dimension, OSA, soaColor, SOD, sodColor, SOS, sosColor } from './config';
import { MrBrandStyled } from './MrBrandStyled';
import { TableBody, TableHeader } from './tableConfig';

const MrBrands = ({ brand }) => {
  const rScore = useSelector(state => state?.RscoreDatas?.rScore);
  const RSCORE_BU = filterItems(rScore, brand, 'bu');

  const MR_BRAND = _(RSCORE_BU).groupBy('outlet_channel').value();

  const getOutletNumber = (outletChannelType, fundamentalType) => {
    const filteredOutletChannel = filterItems(RSCORE_BU, outletChannelType, 'outlet_channel');
    const filteredFundamentalType = filterItems(filteredOutletChannel, fundamentalType, 'fundamental');
    const outletWise = _(filteredFundamentalType).groupBy('outlet').value();
    return Object.keys(outletWise).length;
  };

  let outletArray = [];
  for (const property in MR_BRAND) {
    const outletChannelWise = MR_BRAND[property];

    const outlet_channel_percent = _(outletChannelWise)
      .groupBy('fundamental')
      .map((item, fundamental) => ({
        fundamental: fundamental
          ?.replace('Share of availability', 'On Shelf Availability')
          .replace('Share of shelf', 'Share of Shelf')
          .replace('Share of display', 'Share of Display'),
        percent: fixedFloatAndCommas((_.sumBy(item, 'point_scored') / _.sumBy(item, 'points_allocated')) * 100, 1),
      }))
      .value();

    const outlet = outlet_channel_percent.reduce((acc, obj) => {
      const key = obj['fundamental'];
      return { ...acc, [key]: obj['percent'] };
    }, {});
    outletArray.push({ ...outlet, outlet_channel: property });
  }

  let tableArray = [];
  for (const outletChannel in MR_BRAND) {
    const FundamentalWise = _(MR_BRAND[outletChannel]).groupBy('fundamental').value();
    for (const fundamental in FundamentalWise) {
      let pointScoredSum = 0;
      let pointsAllocatedSum = 0;
      let percent = 0;
      let fundamentalLength = 0;
      FundamentalWise[fundamental]?.map(item => {
        pointScoredSum = pointScoredSum + item?.point_scored;
        pointsAllocatedSum = pointsAllocatedSum + item?.points_allocated;
        percent = fixedFloatAndCommas((pointScoredSum / pointsAllocatedSum) * 100, 1);
        fundamentalLength = FundamentalWise[fundamental]?.length;
      });

      const data = {
        outletChannel,
        fundamental,
        fundamentalLength,
        pointScoredSum,
        pointsAllocatedSum,
        percent,
      };

      tableArray.push(data);
    }
  }

  return (
    <>
      {outletArray.length > 0 && (
        <MrBrandStyled>
          <div className="chart-wrap">
            <Barchart
              data={_.sortBy(outletArray, 'outlet_channel')}
              dimensions={dimension}
              isXAxis={true}
              isYAxis={true}
              legend={false}
              cartesianGrid={true}
              tooltip={true}
              color={brandColor}
              xAxisTitle="Score"
              yAxisTitle="Modern Trade Accounts"
              title="Fundamental by Modern Trade Accounts"
              barGap={5}
              barSize={100}
              aspect={4}
            >
              <XAxis dataKey="outlet_channel" axisLine={false} fontSize={12} fontWeight={600} />

              {outletArray?.some(obj => obj.hasOwnProperty(SOS)) && (
                <Bar
                  dataKey="Share of Shelf"
                  label={{ position: 'top', fill: '#030913', fontSize: '12px', fontWeight: '500' }}
                  isAnimationActive={false}
                >
                  {sosColor.map((entry, index) => (
                    <Cell cursor="pointer" fill={sosColor[index]} key={`cell-${index}`} />
                  ))}
                </Bar>
              )}

              {outletArray?.some(obj => obj.hasOwnProperty(OSA)) && (
                <Bar
                  dataKey="On Shelf Availability"
                  label={{ position: 'top', fill: '#030913', fontSize: '12px', fontWeight: '500' }}
                  isAnimationActive={false}
                >
                  {soaColor.map((entry, index) => (
                    <Cell cursor="pointer" fill={soaColor[index]} key={`cell-${index}`} />
                  ))}
                </Bar>
              )}

              {outletArray?.some(obj => obj.hasOwnProperty(SOD)) && (
                <Bar
                  dataKey="Share of Display"
                  label={{ position: 'top', fill: '#030913', fontSize: '12px', fontWeight: '500' }}
                  isAnimationActive={false}
                >
                  {sosColor?.map((entry, index) => (
                    <Cell cursor="pointer" fill={sodColor[index]} key={`cell-${index}`} />
                  ))}
                </Bar>
              )}
            </Barchart>
          </div>
          <div className="table-wrap">
            <table>
              <TableHeader />
              <tbody>
                {_.sortBy(tableArray, 'outletChannel')?.map(data => (
                  <TableBody data={data} getOutletNumber={getOutletNumber}/>
                ))}
              </tbody>
            </table>
          </div>
        </MrBrandStyled>
      )}
    </>
  );
};

export default MrBrands;
