import styled from 'styled-components';

export const ComposedchartStyled = styled.div`
  padding-left: 30px;

  .title-wrap {
    display: flex;
    align-items: center;
    padding-top: 18px;
    gap: 10px;
    span {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 40px;
    }
    .compliance-green {
      text-align: center;
      display: inline-block;
      .light {
        border: none;
        span {
          color: #2e5aac;
          border: 1px solid;
          min-width: 109px;
          padding: 3px 4px 3px 4px;
          border-radius: 2px;
          display: block;
          font-size: 10px;
          letter-spacing: 0.7px;
          font-weight: 600;
          height: 16px;
          line-height: 10px;
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
  }

  .recharts-cartesian-axis-tick-value {
    tspan {
      fill: #030913;
    }
  }

  .barchart-wrap {
    position: relative;
    /* height: ${props => props.height + 25}px;
    width: ${props => props.width + 45}px; */
    padding-left: 30px;

    .barchart-xaxis {
      position: absolute;
      left: 0;
      bottom: 60%;
      transform: rotate(-90deg);
      font-weight: 400;
      font-size: 16px;
      color: #6b6c7e;
    }

    .barchart-yaxis {
      position: absolute;
      bottom: 0;
      left: 50%;
      font-weight: 400;
      font-size: 16px;
      color: #6b6c7e;
    }
  }
`;
