import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { DialogWrapper } from '../../common';
import PageHeader from '../../base/PageHeader';
import { getUserRole } from '../../../data/dao';
import { isAdminUser,isTasUser } from '../../dashboard/config';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { breadCrumbConfig, filterConfig, title } from './config';
import SubDTargetAchievementView from './SubDTargetAchievementView';
import BrandDialogWrapper from '../../salesForce/target-Achievement/BrandDialogWrapper';
import TargetAchieveStyled from './TargetAchieveStyled';
import { NamedDateFilter } from '../../../components';
import { getCurrentDay, getStartOfCurrentMonth } from '../../../utils/date';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getUserTarget: PropTypes.func.isRequired,
  getSubDTarget: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  downloadReport: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class TargetAchievementReport extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    const dateRange = {
      start: getStartOfCurrentMonth(),
      end: getCurrentDay(),
    };
    const { match } = props;
    this.userRole = getUserRole();
    this.adminUser = isAdminUser(this.userRole);
    this.tasUser = isTasUser(this.userRole)
    this.state = {
      data: [],
      id: parseInt(match.params.id, 10),
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      dateRange,
      labelName: 'MTD',
    };
    const { displayAlert, downloadReport } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getList,
      downloadReport,
      displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount() {
    this.getList();
  }

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  getList = () => {
    const { getUserTarget, displayAlert, getSubDTarget } = this.props;
    const { queryParameters, dateRange } = this.state;
  
    this.adminUser||this.tasUser
      ? getUserTarget(
          {
            filter: { filters: { ...queryParameters.filter }, dateRange },
          },
          {
            handleSuccess: response => {
              this.setState({
                data: response.data.getUserWiseTargetAchievement || [],
              });
            },
            handleError: error => {
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        )
      : getSubDTarget(
          {
            filter: { filters: { ...queryParameters.filter }, dateRange },
          },
          {
            handleSuccess: response => {
              this.setState({
                data:
                  (response.data.getSubDUserTargetAchievement && [response.data.getSubDUserTargetAchievement]) || [],
              });
            },
            handleError: error => {
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        );
  };

  onDropDownChange = event => {
    const { label, value } = event;
    this.setState(
      {
        labelName: label,
        dateRange: value,
      },
      () => {
        this.getList();
      },
    );
  };

  render() {
    const { data, brandDialogData = [], queryParameters, labelName } = this.state;
    const { settings } = this;
    const { serverResponseWaiting } = this.props;
    return (
      <BrandDialogWrapper>
        <DialogWrapper
          title=""
          subTitle=""
          footer
          withOutPadding
          renderDialog={() => (
            <>
              {brandDialogData?.length !== 0 ? (
                <>
                  <div className="table-wrap">
                    <table>
                      <thead>
                        <tr>
                          <th>Brand</th>
                          <th>Brand Target</th>
                        </tr>
                      </thead>
                      <tbody>
                        {brandDialogData.map(brand => (
                          <tr style={{ height: 41 }}>
                            <td>{brand.brandName}</td>
                            <td>{fixedFloatAndCommas(brand.target)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <h2 className="text-center">No Brand Target</h2>
              )}
            </>
          )}
          render={({ onDialogItemClick }) => (
            <TargetAchieveStyled>
              <div className="section-panel">
                <PanelStyled>
                  <PageHeader
                    breadCrumb={breadCrumbConfig}
                    config={{
                      title,
                      create: false,
                      download: false,
                      filter: false,
                      upload: false,
                      date: false,
                      search: false,
                    }}
                    downloadConfig={{
                      domain: title,
                    }}
                    filter={{
                      date: queryParameters.date,
                      menuList: filterConfig.menu,
                      onFilterChange: this.basePaginationService.handleFilterChange,
                    }}
                    queryParameters={queryParameters}
                    controlDisplay={this.controlDisplay}
                    handleCreateClick={this.handleIconClick}
                    resetFilter={this.basePaginationService.resetFilter}
                    clearSearchText={this.basePaginationService.clearSearchText}
                    handleSearchChange={this.basePaginationService.handleSearchInputChange}
                    handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                    handleDownloadClick={this.basePaginationService.handleDownloadClick}
                  >
                    <NamedDateFilter onDropDownChange={event => this.onDropDownChange(event)} labelName={labelName} />
                  </PageHeader>
                </PanelStyled>
                {this.adminUser ? (
                  <View
                    data={data}
                    settings={settings}
                    onIconClick={onDialogItemClick}
                    handleBrandDialog={this.handleBrandDialog}
                    loading={serverResponseWaiting}
                  />
                ) : (
                  <SubDTargetAchievementView
                    data={data}
                    settings={settings}
                    onIconClick={onDialogItemClick}
                    handleBrandDialog={this.handleBrandDialog}
                    loading={serverResponseWaiting}
                  />
                )}
              </div>
            </TargetAchieveStyled>
          )}
        />
      </BrandDialogWrapper>
    );
  }
}

TargetAchievementReport.propTypes = propTypes;

TargetAchievementReport.defaultProps = defaultProps;

export default withAlert()(TargetAchievementReport);
