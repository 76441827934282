import styled from 'styled-components';

export const TitleWrapStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;

  .brand-img {
    img {
      height: 30px;
      margin-right: 15px;
    }
    span {
      margin-right: 15px;
    }
  }

  .brandImg-week,
  .title__number {
    display: flex;
    align-items: center;
    .header-number {
      font-size: 32px;
      font-weight: 700;
      margin-right: -65px;
    }
    .title__composedChart {
      width: 300px;
    }
  }
`;
