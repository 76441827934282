import styled from 'styled-components';

export const RscoreStyled = styled.div`
  padding: 0 30px;
  height: 100vh;
  overflow: scroll;

  .header-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .header-number {
      font-size: 32px;
      font-weight: 700;
      margin-right: -65px;
    }
    .header-composedChart {
      width: 300px;
    }
  }

  .filter-select__option {
    font-size: 16px;
    font-weight: 400;
    color: #272833;
  }

  .brandRScore-wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }

  .header {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter-select__option--is-selected {
    color: white;
  }

  .test{
    border: 1px solid black;
  }
`;
