import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { breadCrumb, newList, PURCHASE_ORDER_VIEW } from '../common/config';
import withAlert from '../../../../../utils/composition/withAlert';
import * as queryService from '../../../../base/query.service';
import { PanelHeader, PanelStyled } from '../../../../common/configuration';
import { BreadCrumb } from '../../../../../components';
import { has } from '../../../../../utils/objectPrototypes';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { groupBy } from '../../../../common/HelperFunctions';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getPurchaseOrderDetails: PropTypes.func.isRequired,
};
const defaultProps = {
  serverResponseWaiting: false,
};
class Detail extends Component {
  constructor(props) {
    const id = has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0;
    super(props);
    this.state = {
      id,
      data: {
        list: [],
        details: {
          id: null,
          date: '',
          channe: '',
        },
      },
      queryParameters: {
        date: { ...queryService.baseQueryParameters.date },
        type: PURCHASE_ORDER_VIEW.BRAND,
      },
    };
    const { displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      displayAlert,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  loadTableData=() => {
    this.getPurchaseOrderDetail();
  }

  locationName = (obj) => {
    const { queryParameters } = this.state;
    if (queryParameters.type === PURCHASE_ORDER_VIEW.BRAND) {
      return obj.Brand ? obj.Brand.title : '';
    }
  };

  getPurchaseOrderDetail=() => {
    const { id, data } = this.state;
    const { getPurchaseOrderDetails, displayAlert } = this.props;
    getPurchaseOrderDetails({
      id,
    }, {
      handleSuccess: (res) => {
        const response = (res.data.getMTPurchaseOrderDetail
          && res.data.getMTPurchaseOrderDetail)
        || [];
        const groupedArray = groupBy(response && response.Order, this.locationName);
        const keys = ['group', 'skuList'];
        const order = groupedArray.map(r => (keys.reduce((o, k, i) => (o[k] = r[i], o), {})));
        data.list = order;
        data.details = {
          id: response ? response.id : null,
          date: response ? response.purchaseOrderDate : '',
          channel: response ? response.Channel ? response.Channel.title : '' : '',
        };
        this.setState({ data });
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },
    });
  }

  render() {
    const { id, data, queryParameters } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumb} />
            <PanelHeader>
              <h2>{`#${id}`}</h2>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className="section-content pad-48">
          <View
            id={id}
            data={data}
            loading={serverResponseWaiting}
            viewType={queryParameters.type}
            viewTypeConfig={{
              value: queryParameters.type,
              list: newList,
            }}
            handleViewTypeChange={this.basePaginationService.handleViewTypeChange}
          />
        </div>
      </>
    );
  }
}

Detail.propTypes = propTypes;
Detail.defaultProps = defaultProps;

export default withAlert()(Detail);
