import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Detail from './detail';
import { TableHeader } from './tableConfig';
import { Pagination } from '../../../../v4/components';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { LIMIT, PAGE } from '../../../../data/enums/GeneralConstants';
import DeliverStyled from './DeliverStyled';
import CTAccordionStyled from '../../../../components/CollapsibleTable/CTAccordionStyled';

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    list: PropTypes.array,
    total: PropTypes.number,
  }),
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  onPageChange: PropTypes.func.isRequired,
  onHeaderClick: PropTypes.func,
  onRowClick: PropTypes.func.isRequired,
  selectedOrders: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {
    list: [],
    total: 0,
    limit: LIMIT,
    page: PAGE,
  },
  activeLine: {
    id: '',
    sellerId: 0,
  },
  loading: false,
  selectedOrders: [],
  onHeaderClick: () => null,
};

const View = ({ ...props }) => {
  const { data, loading, activeLine, onPageChange, onRowClick, selectedOrders, onHeaderClick } = props;

  return (
    <DeliverStyled>
      <div className='tab-section delivery'>
        <CTAccordionStyled>
          <div className='table-wrap'>
            <table>
              {TableHeader({ onHeaderClick })}

              <Fragment>
                <Detail
                  data={data.list}
                  activeLine={activeLine}
                  selectedOrders={selectedOrders}
                  loading={loading}
                  onRowClick={onRowClick}
                />
              </Fragment>
            </table>
          </div>
        </CTAccordionStyled>
        <Pagination currentPage={data.page} limit={data.limit} totalItems={data.total} onPageChange={onPageChange} />
      </div>
    </DeliverStyled>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const DispatchedViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default DispatchedViewWithErrorAndLoading;
