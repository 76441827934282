import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Input
} from '../../../../components';
import withErrorAndLoading from '../../../../utils/composition/withLoading';
import FormContentStyled from '../../../../components/global-css/FormContentStyled';
import GRNDamageImageUpload from '../../../inventory/grn/createGrn/table/GRNDamageImageUpload'


const propTypes = {
};

const defaultProps = {
};

const View = ({ ...props }) => {
  const { handleInputChange,enableErrorDisplay ,refsObj,data ,getImages} = props;
  return (
    <div className='outlet-detail '>
      <div className='flex'>
        <div className='w-half'>
          <FormContentStyled className='outlet-dialog'>
            <div className='form-input-wrap w-100'>
              <Input
                ref={(ref) => refsObj.title = ref}
                value={data.title}
                name='title'
                type='text'
                placeholder='Title'
                labelContent='Title*'
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={(event) => handleInputChange(event,'title')}
              />
                    <Input
                ref={(ref) => refsObj.title = ref}
                value={data.business_partner_id}
                name='business_partner_id'
                type='text'
                placeholder='Business Partner Id'
                labelContent='Business Partner Id*'
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={(event) => handleInputChange(event,'business_partner_id')}
              />
              <div className="image-upload">
                <label>Image Upload</label>
                <div className="images">
                <GRNDamageImageUpload images={data?.image_url || []} getImages={(childMethod) => getImages(childMethod)} />
                </div>
              </div>
            </div>
           
          </FormContentStyled>
        </div>
      </div>
    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const GeneralInfo = withErrorAndLoading(View);

export default GeneralInfo;
