import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const USER_COVEARGE_TARGET_FRAGMENT = gql`
  fragment OutletCoverageUserTargetFragment on OutletCoverageUserTarget {
    id
    userId
    name
    target
    outletCoverageTargetId
    outletCoverageUserTargetId
    startDate
    endDate
    total
    role
    isChildPresent
    childUsers: ChildUsers {
      id
      name
      target
      isChildPresent
      role
      outletCoverageTargetId
      outletCoverageUserTargetId
      startDate
      endDate
      childUsers: ChildUsers {
        id
        userId
        name
        target
        isChildPresent
        role
        outletCoverageTargetId
        outletCoverageUserTargetId
        startDate
        endDate
        childUsers: ChildUsers {
          id
          userId
          name
          target
          isChildPresent
          role
          outletCoverageTargetId
          outletCoverageUserTargetId
          startDate
          endDate
          childUsers: ChildUsers {
            id
            name
            userId
            target
            isChildPresent
            role
            outletCoverageTargetId
            outletCoverageUserTargetId
            startDate
            endDate
            childUsers: ChildUsers {
              id
              userId
              name
              target
              isChildPresent
              role
              outletCoverageTargetId
              outletCoverageUserTargetId
              startDate
              endDate
            }
          }
        }
      }
    }
  }
`;

const GET_USER_COVERAGE_TARGET = gql`
  query getoutletCoverageUserTargets($outletCoverageTargetId: Int!, $followSalesHierarchy: Boolean) {
    outletCoverageUserTargets(
      outletCoverageTargetId: $outletCoverageTargetId
      followSalesHierarchy: $followSalesHierarchy
    ) {
      ...OutletCoverageUserTargetFragment
    }
  }
  ${USER_COVEARGE_TARGET_FRAGMENT}
`;

const CREATE_OR_EDIT_OUTLET_COVERAGE_USER_TARGET = graphql(
  gql`
    mutation ($input: OutletCoverageUserTargetInput!) {
      createOrEditOutletCoverageUserTarget(input: $input) {
        ...OutletCoverageUserTargetFragment
      }
    }
    ${USER_COVEARGE_TARGET_FRAGMENT}
  `,
  {
    props: ({ mutate }) => ({
      createOrEditOutletCoverageUserTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getoutletCoverageUserTargets: GET_USER_COVERAGE_TARGET,
};

const mutation = {
  createOrEditOutletCoverageUserTarget: CREATE_OR_EDIT_OUTLET_COVERAGE_USER_TARGET,
};

export {
  query, mutation, USER_COVEARGE_TARGET_FRAGMENT, CREATE_OR_EDIT_OUTLET_COVERAGE_USER_TARGET
};
