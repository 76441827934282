import React from 'react';
import PropTypes from 'prop-types';
import DeliveredDetailChildView from './childView';
import { TableBody } from '../tableConfig';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import AccordionTable from '../../../../../components/CollapsibleTable/AccordionTable';

const propTypes = {
  loading: PropTypes.bool,
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  data: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func.isRequired,
  selectedOrders: PropTypes.instanceOf(Array),
  handleSliderOpen: PropTypes.func.isRequired,
  onIconClick: PropTypes.func,
  onOrderStatusChangeClick: PropTypes.func,
};

const defaultProps = {
  data: [],
  activeLine: {
    id: '',
    sellerId: 0,
  },
  selectedOrders: [],
  loading: false,
  onOrderStatusChangeClick: () => null,
  onIconClick: () => null,
};

const DeliveredDetailView = ({ ...props }) => {
  const { data, onRowClick, onIconClick, activeLine, handleSliderOpen, onOrderStatusChangeClick } = props;

  return (
    <tbody>
      {
        <AccordionTable>
          {data.map((dispatched) => (
            <tr
              key={dispatched.id}
              trigger={TableBody({
                data: dispatched,
                onRowClick,
              })}
            >
              {dispatched.orderIds.length > 0
                ? DeliveredDetailChildView({
                  data: dispatched.Orders || [],
                  onOrderStatusChangeClick,
                  activeLine,
                  amountDetails: dispatched.amountDetails || {},
                  onIconClick,
                  orderType: dispatched.orderType,
                  handleSliderOpen,
                  billDiscountDetail: dispatched.billDiscountDetail || [],
                })
                : null}
            </tr>
          ))}
        </AccordionTable>
      }
    </tbody>
  );
};

DeliveredDetailView.propTypes = propTypes;

DeliveredDetailView.defaultProps = defaultProps;

const DeliveredDetailViewWithErrorAndLoading = withErrorAndLoadingScreen(DeliveredDetailView);

export default DeliveredDetailViewWithErrorAndLoading;
