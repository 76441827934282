import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableHeader } from './tableConfig';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import { PanelStyled } from '../../common/configuration/index';
import TargetWrapper from './TargetWrapper';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { UPLOADABLES } from '../../../data/enums/Uploadables';
import PageHeader from '../../base/PageHeader';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  breadCrumb: PropTypes.arrayOf(Object),
  onButtonClick: PropTypes.func,
  onFilterChange: PropTypes.func,
  onCSVUpload: PropTypes.func,
  onTableBodyClick: PropTypes.func,
  onHandleBrandDialog: PropTypes.func.isRequired,
  handleIconClick: PropTypes.func,
  settings: PropTypes.shape({
    salesTeamWise: PropTypes.bool,
    subDWise: PropTypes.bool,
    brandWise: PropTypes.bool,
  }).isRequired,
};

const defaultProps = {
  breadCrumb: [],
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  onCSVUpload: () => null,
  data: dataProps.defaultValue,
  onFilterChange: () => null,
  pagination: paginationProps.defaultValue,
  onTableBodyClick: () => null,
  handleIconClick: () => null,
};

const View = ({ ...props }) => {
  const {
    data,
    breadCrumb,
    onTableBodyClick,
    onCSVUpload,
    onIconClick,
    settings,
    onHandleBrandDialog,
    handleIconClick
  } = props;

  const uploadEntity = () => {
    if (settings.salesTeamWise && settings.brandWise) {
      return UPLOADABLES.USER_BRAND_TARGET;
    }
    if (settings.salesTeamWise && !settings.brandWise) {
      return UPLOADABLES.USER_TARGET;
    }
    if (settings.subDWise && settings.brandWise) {
      return UPLOADABLES.SUB_D_BRAND_TARGET;
    }
    if (settings.subDWise && !settings.brandWise) {
      return UPLOADABLES.SUB_D_TARGET;
    }
  };

  return (
    <Fragment>
      <div className='section-header'>
        <PanelStyled>
          <PageHeader
            display={{}}
            breadCrumb={breadCrumb}
            config={{
              title: 'Targets',
              create: true,
              download: false,
              filter: false,
              upload: true,
              date: false,
            }}
            handleUploadClick={() => {
              onCSVUpload(uploadEntity());
            }}
            handleCreateClick={handleIconClick}
          />
        </PanelStyled>
      </div>
      <div className='section-content'>
        <TargetWrapper>
          <div className='table-wrap'>
            <table>
              <TableHeader />
              <tbody>
                {data.list.map((targetData) => (
                  <TableBody
                    key={targetData.id}
                    data={targetData}
                    brandWise={settings.brandWise}
                    onTableBodyClick={onTableBodyClick}
                    onIconClick={onIconClick}
                    onHandleBrandDialog={onHandleBrandDialog}
                    handleIconClick={handleIconClick}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </TargetWrapper>
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
