/* global google */
/* eslint-disable */
import React, { Component } from 'react';
import MapViewStyled from './MapViewStyled';
import RoutesList from './RoutesList/RoutesList';
import { mapStyles } from '../../../dashboard/data';
import colors from '../../../configuration/arc/enums/colors';
import _ from 'lodash';
import withLoading from '../../../../utils/composition/withLoading';
import { Loading } from '../../../../v4/components';
import { createScriptLoadMap } from '../../../configuration/arc/services/googleMapScript';
class MapView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: {},
      infoWindow: new google.maps.InfoWindow(),
      allMarkers: [],
      routeStateIds: [],
      loading: false,
    };
    this.template = [
      ` <svg viewBox="0 0 22 41" width="22px" height="41px" xmlns="http://www.w3.org/2000/svg">,
                <path d="M 11,41 c -2,-20 -10,-22 -10,-30 a 10,10 0 1 1 20,0 c 0,8 -8,10 -10,30 z" fill="{{ color }}" stroke="#ffffff" stroke-width="1.5"/>,
            </svg>
            `,
    ].join('\n');
  }

  componentDidMount() {
    createScriptLoadMap().then(a => {
      this.renderMarkers(a);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let routePrevIds = prevProps.data.list.map(route => route.id);

    if (!_.isEqual(_.sortBy(routePrevIds), _.sortBy(prevState.routeStateIds))) {
      this.setState({ routeStateIds: routePrevIds });
      this.createRouteMarkers();
    }
  }

  renderMarkers = a => {
    const { data, loadingMapView } = this.props;
    window.globalMap.setCenter({
      lat: 27.700769,
      lng: 85.30014,
    });
    window.globalMap.setZoom(12);
    this.setState({ map: a }, () => {
      setTimeout(
        () => {
          loadingMapView(false);
        },
        data.total < 500 ? 2500 : null,
      );
    });
  };

  onRouteCheck = (e, routeId) => {
    const { data } = this.props;
    data.list.forEach(route => {
      if (route.id === routeId) {
        route.check = e.target.checked;
        this.handleMarkerVisibility(routeId, route.check);
      }
    });
    let routesNotChecked = data.list.find(route => route.check === true);
    if (!routesNotChecked) {
      this.handleMarkerVisibility(null);
    }
    this.setState({ data });
  };

  handleMarkerVisibility = (routeId, show) => {
    const { allMarkers } = this.state;
    const { data } = this.props;
    let prevCheckedRoutesIds = [];

    if (!routeId) {
      if (allMarkers.length > 300) {
        this.setState({ loading: true });
        setTimeout(() => {
          this.setState({ loading: false });
        }, Math.floor(allMarkers.length / 50) * 100);
      }
      allMarkers.forEach(marker => marker.setVisible(true));
      return;
    }

    data.list.forEach(route => {
      if (route.check && routeId !== route.id) {
        prevCheckedRoutesIds.push(route.id);
      }
    });

    routeId &&
      allMarkers.forEach(marker => {
        if (marker.routeId === routeId) {
          marker.setVisible(show);
        } else if (!prevCheckedRoutesIds.includes(marker.routeId)) {
          marker.setVisible(false);
        }
      });
  };

  handleMarkerClick = (marker, name, route) => {
    const { infoWindow, map } = this.state;
    infoWindow.close();
    const content = `<div>Outlet: ${name}</div><div>Route: ${route}</div>`;
    infoWindow.setContent(content);
    infoWindow.open(map, marker);
  };

  createRouteMarkers = () => {
    let allRouteMarkers = [];
    const { map, allMarkers } = this.state;
    const { data } = this.props;
    let iconColorIndex = 0;
    allMarkers.forEach(marker => marker.setMap(null));
    data.total < 500 &&
      data.list.forEach(route => {
        if (iconColorIndex === colors.length - 1) {
          iconColorIndex = 0;
        }

        route.RetailOutlets &&
          route.RetailOutlets.forEach(outlet => {
            const marker = new google.maps.Marker({
              position: {
                lat: parseFloat(outlet.geoLocation.latitude),
                lng: parseFloat(outlet.geoLocation.longitude),
              },
              map,
              icon: {
                url:
                  'data:image/svg+xml;charset=UTF-8;base64,' +
                  btoa(this.template.replace('{{ color }}', `${colors[iconColorIndex]}`)),
                scaledSize: new google.maps.Size(20, 20),
              },
              optimized: false,
              routeId: outlet.routeId,
            });
            marker.addListener('click', () => this.handleMarkerClick(marker, outlet.title, route.title));
            allRouteMarkers.push(marker);
          });
        route.color = colors[iconColorIndex];
        route.RetailOutlets.length &&
          map.setCenter({
            lat: parseFloat(route.RetailOutlets[0].geoLocation.latitude),
            lng: parseFloat(route.RetailOutlets[0].geoLocation.longitude),
          });
        iconColorIndex++;
      });
    this.setState({ allMarkers: allRouteMarkers });
  };

  render() {
    const { loading } = this.state;
    const { filter, data, routeCardClick } = this.props;
    return (
      <MapViewStyled>
        <div className="flex-container">
          <div className="mapParam">
            <div className="map-wrapper-inner relative">
              <div id="map" />
            </div>
          </div>
          <RoutesList data={data} filter={filter} onRouteCheck={this.onRouteCheck} routeCardClick={routeCardClick} />
        </div>
        {loading && <Loading title={'Rendering markers...'} />}
      </MapViewStyled>
    );
  }
}

export default withLoading(MapView);
