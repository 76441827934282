import React from 'react';
import withLoading from '../../../utils/composition/withLoading';
import FilteredOutlet from './components/FilteredOutlet';
import SelectedOutlet from './components/SelectedOutlet';
import SelectFilter from './components/SelectFilter';


const View = (props) => {
  const { data, menu, showMapView, checkedList, removedList, toggleMapView, outletFilter, selectedOutlet, onDropDownChange, onDateChange, onButtonReset, onButtonApply, onAddOutlet, onAddSelected, onAddCallPlan, onRemoveSelected, onPrimaryCheckboxClick, onSecondaryCheckboxClick,enableErrorDisplay,refsObj,handleAutoComplete } = props;
  return (
    <div className='outlet-wrap'>
      <div className='filtered-outlet'>
        <div className='filter-outlet-wrap'>
          <SelectFilter
            refsObj={refsObj}
            menu={menu}
            outletFilter={outletFilter}
            onDropDownChange={onDropDownChange}
            handleAutoComplete={handleAutoComplete}
            onDateChange={onDateChange}
            onButtonReset={onButtonReset}
            onButtonApply={onButtonApply}
            enableErrorDisplay={enableErrorDisplay}
          />
          <FilteredOutlet
            data={data}
            checkedList={checkedList}
            onAddOutlet={onAddOutlet}
            onAddSelected={onAddSelected}
            onPrimaryCheckboxClick={onPrimaryCheckboxClick}
            onSecondaryCheckboxClick={onSecondaryCheckboxClick}
          />
        </div>
      </div>
      <SelectedOutlet
        data={data}
        showMapView={showMapView}
        removedList={removedList}
        toggleMapView={toggleMapView}
        selectedOutlet={selectedOutlet}
        onAddOutlet={onAddOutlet}
        onAddCallPlan={onAddCallPlan}
        onRemoveSelected={onRemoveSelected}
        onPrimaryCheckboxClick={onPrimaryCheckboxClick}
        onSecondaryCheckboxClick={onSecondaryCheckboxClick}
      />
    </div>
  );
};

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
