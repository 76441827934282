import styled from 'styled-components';

export const P3MCategoryStyled = styled.div`
  border: 1px solid #e7e7ed;
  margin: 20px 0;
  padding-bottom: 42px;
  border-radius: 4px;

  .title-category {
    font-size: 22px;
    font-weight: 700;
    padding: 15px 12px 0px 30px;
  }

  .title-fundamental {
    font-size: 16px;
    font-weight: 600;
    color: #8993a4;
    padding: 30px 0 20px 30px;
  }

  .linechart-title {
    background-color: #f1f2f5;
    padding: 10px 8px;
    color: #272833;
    font-size: 14px;
  }

  .chart-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .data-not-found {
    color: grey;
    font-size: 15px;
    width: 20%;
    text-align: center;
  }

  .legend-wrap {
    margin-top: 15px;
  }
`;
