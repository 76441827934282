import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { breadCrumbConfig, title, filterConfig } from './config';
import CategoryRequestWrapper from './CategoryRequestWrapper';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { getPermissionForApprovalRequest } from '../../base/permission';
import withAlert from '../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { APPROVAL_PROCESS } from '../../../data/enums/Support';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { DOWNLOAD_DOMAIN } from '../../../data/enums/GraphQL';
import { generateRequestMessage } from '../../common/HelperFunctions';
import ModalWrapper from '../../common/DialogFormWrapper';
import { formConfig } from '../../components/RequestBulkSelect/config';
import confirmationGenerator from '../../common/DialogConfirmation';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { clone } from '../../../utils/objectProcessor';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  approvalRequests: PropTypes.func.isRequired,
  bulkUpdateApprovalRequest: PropTypes.func.isRequired,
  downloadReport: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};
class CategoryRequest extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      dialog: {
        type: '',
        checkedList: '',
      },
    };
    const { downloadReport, displayAlert } = props;
    this.permission = getPermissionForApprovalRequest();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.CATEGORY_CHANGE
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.CATEGORY_CHANGE);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  handleClose = () => {
    this.setState({ dialog: { type: '', checkedList: '' } });
  };

  loadTableData = () => {
    const { approvalRequests, displayAlert } = this.props;
    const { data, queryParameters } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    approvalRequests(
      {
        type: APPROVAL_PROCESS.OUTLET_CATEGORY_CHANGE_APPROVAL.type,
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.approvalRequests && response.data.approvalRequests.rows) || [];
          data.total = (response.data.approvalRequests && response.data.approvalRequests.count) || 0;

          this.setState({ data });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onActionClick = (status, checkedList, checkStatus) => {
    this.setState({
      dialog: {
        type: status,
        checkedList,
      },
    });
  };

  handleRequest = (status, checkedList, checkStatus = () => null) => {
    const { bulkUpdateApprovalRequest, displayAlert } = this.props;

    bulkUpdateApprovalRequest(
      {
        ids: checkedList,
        processId: APPROVAL_PROCESS.OUTLET_CATEGORY_CHANGE_APPROVAL.processId,
        status,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, generateRequestMessage(status));
          this.loadTableData();
          checkStatus();
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  render() {
    const { queryParameters, data, dialog,filterMenu } = this.state;
    const { type, checkedList } = dialog;
    const { serverResponseWaiting } = this.props;
    return (
      <>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              breadCrumb={breadCrumbConfig}
              config={{
                title,
                create: false,
                download: true,
                date: true,
                filter: true,
                search: true,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              downloadConfig={{
                domain: DOWNLOAD_DOMAIN.CATEGORY_CHANGE,
              }}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              queryParameters={queryParameters}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content table-present">
          {type && (
            <ModalWrapper
              type={type}
              dialogData={checkedList}
              formConfig={formConfig}
              onDialogCancel={this.handleClose}
              onDialogSubmit={this.handleRequest}
              renderDialog={() => <>{confirmationGenerator(type)}</>}
            />
          )}
          <CategoryRequestWrapper>
            <View
              data={data}
              permission={this.permission}
              loading={serverResponseWaiting}
              handleRequest={this.handleRequest}
              onIconClick={this.onActionClick}
              pagination={queryParameters.pagination}
              onPageChange={this.basePaginationService.onPageSelect}
            />
          </CategoryRequestWrapper>
        </div>
      </>
    );
  }
}

CategoryRequest.propTypes = propTypes;

CategoryRequest.defaultProps = defaultProps;

export default withAlert()(CategoryRequest);
