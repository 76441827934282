import { FILTER } from '../../../../../../../data/enums/filter';
import { getFilterConfig } from '../../../../../../../utils/filter';
import { EVENT_OPERATION } from '../../../../../../../v4/constants/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../../../v4/constants/successMessage';

const title = 'Question';

export const successMessage = 'Questionnaire is deleted sucessfully';

export const filterConfig = getFilterConfig([FILTER.BRAND, FILTER.CHANNEL, FILTER.CATEGORY]);

export const crudRequest = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      id: data.id,
    }),
    responseName: 'deleteQuestionnaire',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};

export const crudBulkDeleteRequestConfig = {
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (element) => ({
      id: [...element.data],
      type: 'vgpDeleteBulk',
      active: false,
    }),
    responseName: 'bulkDeleteVGPTemplate',
    message: `${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};
