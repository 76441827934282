import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from './Form';
import View from './View';
import InventoryStyled from './InventoryStyle';
import { breadCrumbConfig, formConfig, title } from './config';
import PageHeader from '../../../base/PageHeader';
import * as queryService from '../../../base/query.service';
import { PanelStyled } from '../../../common/configuration';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { DialogFormWrapper } from '../../../common';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import { filterConfig } from './config';
import { PROGRAM } from '../../../../data/enums/Permission';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getStockList: PropTypes.func.isRequired,
  getBatchList: PropTypes.func.isRequired,
  getOutletChain: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  getInventoryList: PropTypes.func.isRequired,
  getRetailOutletByChainId: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Inventories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogData: [],
      data: {
        list: [],
        total: 0,
      },
      menu: {
        outletChainList: [],
        outletList: [],
        stockCountList: [],
      },
      dialog: {
        type: '',
        element: '',
      },
      queryParameters: {
        sort: queryService.baseQueryParameters.sort,
        date: { ...queryService.baseQueryParameters.date },
        filter: queryService.baseQueryParameters.filter,
        search: queryService.baseQueryParameters.search,
        pagination: queryService.baseQueryParameters.pagination,
      },
      select: {
        outletChain: '',
        outlet: '',
        stockCount: '',
      },
    };
    const { displayAlert, downloadReport } = props;
    this.basePaginationService = new queryService.QueryClass(
     
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
  }

  componentDidMount() {
    this.getOutletChainList();
    this.loadTableData();
  }

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  loadTableData = () => {
    const { getInventoryList } = this.props;
    const { data, select, queryParameters } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getInventoryList({
      limit: queryParameters.pagination.limit,
      offset,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (response) => {
        data.list = (response.data.getInventoryList && response.data.getInventoryList.rows) || [];
        data.total = (response.data.getInventoryList && response.data.getInventoryList.count) || 0;
        this.setState(data);
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  getOutletChainList = () => {
    const { menu } = this.state;
    const { getOutletChain } = this.props;
    getOutletChain({
      searchText: '',
    }, {
      handleSuccess: (response) => {
        menu.outletChainList = response.data.getOutletChain;
        this.setState({ menu });
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  };

  getOutletList = (id) => {
    const { getRetailOutletByChainId } = this.props;
    const { menu } = this.state;
    getRetailOutletByChainId({
      outletChainId: id,
    }, {
      handleSuccess: (response) => {
        menu.outletList = response.data.getRetailOutletByChainId || [];
        this.setState({ menu });
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  };

  getStockCount = () => {
    const { getStockList } = this.props;
    const { menu } = this.state;
    getStockList({
      offset: 0,
      limit: 1000,
      filter: {},
    }, {
      handleSuccess: (response) => {
        menu.stockCountList = response.data.getMTStockList.rows;
        this.setState({ menu });
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  };

  getInventoryBatchList = (skuId,retailId) => {
    const { getBatchList } = this.props;
    getBatchList({
      skuId,
      retailId
    }, {
      handleSuccess: (response) => {
        this.setState({
          dialogData: response.data.ListInventoriesBatch || [],
        });
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  }

  resetState=() => {
    const { select } = this.state;
    select.stockCount = '';
    this.setState({
      select,
      data:
        {
          list: [],
          total: 0,
        },
    });
  }

  handleDropDownChange = (value, name) => {
    const { select } = this.state;
    select[name] = value;
    this.setState(select);
    switch (name) {
      case 'outletChain':
        this.getOutletList(value);
        this.resetState();
        break;
      case 'outlet':
        this.getStockCount();
        this.resetState();
        break;
      case 'stockCount':
        this.loadTableData();
        break;
      default:
    }
  };

  handleRowClick = (type, element = {}) => {
    this.getInventoryBatchList(element.skuId, element.retailId);
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  render() {
    const {
      data, menu, select, queryParameters, dialog, dialogData,
    } = this.state;
    const { type, element } = dialog;
    const { serverResponseWaiting } = this.props;
    return (
      <InventoryStyled>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              breadCrumb={breadCrumbConfig}
              config={{
                title,
                filter: true,
                search: true,
                download: true,
              }}
              downloadConfig={{
                domain: PROGRAM.STOCK_COUNT,
              }}
              filter={{
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={
                    this.basePaginationService.handleSearchInputChange
                  }
              queryParameters={queryParameters}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
            />
          </PanelStyled>
        </div>
        <div className="section-content section-select table-present">
          {type && (
          <DialogFormWrapper
            formConfig={formConfig[type]}
            dialogElement={element}
            onDialogSubmit={this.handleFormSubmit}
            onDialogCancel={this.resetDialog}
            withOutPadding
            footerRequired={false}
            type={type}
            formTitle={element.sku}
            secondaryTitle={`${element.chainTitle}/${element.retailTitle}`}
            renderDialog={() => (
              <>
                {type && (
                  <Form
                    data={dialogData}
                  />
                )}
              </>
            )}
          />
          )}
          <View
            data={data}
            menu={menu}
            select={select}
            loading={serverResponseWaiting}
            pagination={queryParameters.pagination}
            onRowClick={this.handleRowClick}
            onDropDownChange={this.handleDropDownChange}
            onPageChange={this.basePaginationService.onPageSelect}
            handleSearchChange={this.basePaginationService.handleSearchInputChange}
          />
        </div>
      </InventoryStyled>
    );
  }
}

Inventories.propTypes = propTypes;

Inventories.defaultProps = defaultProps;

export default withAlert()(Inventories);
