import { Fragment } from 'react';
import { dateTimePresenter, normalPresentor } from '../../../utils/date';

export const getThumbnailContent = item => (
  <Fragment>
    <img src={item?.thumbnail} width={1200} height={900} />
    <div className="time-date">
      <span>{dateTimePresenter(item?.date)}</span>
    </div>
  </Fragment>
);

export const getThumbnailContentSOSKU = item => {
  return (
    <Fragment>
      <img src={item?.thumbnail} width={1200} height={900} />
      <div className="time-date">
        <span>{item?.title}</span>
        <span>{normalPresentor(item?.audited_at)}</span>
      </div>
    </Fragment>
  );
};

export const outletImageTransformer = (imageList, date) => {
  const modifyImg = imageList?.map(image => ({
    src: `${image}`,
    thumbnail: `${image}`,
    w: 1200,
    h: 900,
    ...(date && { date: date || '-' }),
  }));
  return modifyImg;
};

export const outletImageTransformerSOSKU = (imageList, date) => {
  const modifyImg = imageList?.map(item => ({
    src: `${item?.images.length > 0 && item?.images[0]}`,
    thumbnail: `${item?.images.length > 0 && item?.images[0]}`,
    w: 1200,
    h: 900,
    title: item?.sku_family,
    audited_at: item?.audited_at,
    ...(date && { date: date || '-' }),
  }));
  return modifyImg;
};

export const options = {
  index: 3,
  escKey: false,
  timeToIdle: 4000,
};
