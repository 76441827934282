import React, { useEffect, useState } from 'react';
import RscoreReportDropDown from './components/RscoreReportDropDown';
import { RscoreStyled } from './RscoreStyled';
import { dimension, getbuIds, isEmptyObject, weekCustomize } from './config';
import BrandRscore from './components/BrandRscore';
import moment from 'moment';
import Composedchart from '../../components/Chart/ComposedChart';
import PropTypes from 'prop-types';
import withAlert from '../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../v4/constants/AlertType';
import { currentWeekNo, currentYear, formatter, getCurrentDay, getP3MStartDay, getP3WStartDay } from '../../utils/date';
import { useDispatch, useSelector } from 'react-redux';
import {
  rScoreFail,
  rScoreP3MFail,
  rScoreP3MReq,
  rScoreP3MSuccess,
  rScoreP3WFail,
  rScoreP3WSuccess,
  rScoreReq,
  rScoreSuccess,
} from './redux/actions';
import { ArrayOfObjSum } from '../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../utils/conversion';
import { Loading } from '../../v4/components';
import DataNotFound from './components/DataNotFound/DataNotFound';

const currentWeek = weekCustomize(currentWeekNo);

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const Rscore = props => {
  const { getAllRscore, displayAlert } = props;

  const auditWeek = JSON.parse(localStorage.getItem('audit_week'));
  const dispatch = useDispatch();
  const buIds = getbuIds();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState({
    week: auditWeek?.week ? auditWeek?.week : currentWeek || '',
    week_no: auditWeek?.week_no ? auditWeek?.week_no : currentWeekNo || '',
  });
  const [date, setDate] = useState({});
  // to retain selected custom date
  const [isCustomWeekSelected, setIsCustomWeekSelected] = useState(
    auditWeek?.hasOwnProperty('isCustomWeekSelected') ? auditWeek?.isCustomWeekSelected : false,
  );

  useEffect(() => {
    getBusinessRscoreData();
    return () => {
      dispatch(rScoreSuccess([]));
    };
  }, [selectedWeek, date]);

  useEffect(() => {
    getSelectedCustomDate();
    getBusinessRscoreDataP3W();
    getBusinessRscoreDataP3M();
  }, []);

  const getSelectedCustomDate = () => {
    auditWeek?.hasOwnProperty('customDate')
      ? setDate({ firstDate: auditWeek?.firstDate, lastDate: auditWeek?.lastDate })
      : setDate({});
  };

  const businessRscore = useSelector(state => state?.RscoreDatas?.businessRscore);
  const P3WRscore = useSelector(state => state?.RscoreDatas?.P3WRscore);
  const WEEKLY_WISE_RSCORE = useSelector(state => state?.RscoreDatas?.rScore);
  const P3WRSCORE_TO_CHART = _(P3WRscore)
    .groupBy('audit_period')
    .map((item, week) => ({
      week: week.replace(currentYear, ''),
      pointScored: fixedFloatAndCommas(_.sumBy(item, 'point_scored')),
      points_allocated: fixedFloatAndCommas(_.sumBy(item, 'points_allocated')),
      percent: fixedFloatAndCommas((_.sumBy(item, 'point_scored') / _.sumBy(item, 'points_allocated')) * 100),
    }))
    .value();

  const POINT_SCORED_SUM = ArrayOfObjSum(WEEKLY_WISE_RSCORE, 'point_scored');
  const POINT_ALLOCATED_SUM = ArrayOfObjSum(WEEKLY_WISE_RSCORE, 'points_allocated');
  const totalPercent = (POINT_SCORED_SUM / POINT_ALLOCATED_SUM) * 100;

  const onAPIRequestFailure = error => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const onSelectChange = e => {
    setDate({});
    setIsCustomWeekSelected(false);
    setSelectedWeek({ week: e.week, week_no: e?.week_no });
  };

  const weekList = Array(currentWeekNo)
    .fill(0)
    .map((item, index) => {
      return {
        week: `Week ${index + 1}`,
        week_no: index + 1,
      };
    });

  const calculateDateFromWeekNumAndYear = (year, week) => {
    const firstDate = moment().day('Sunday').year(year).week(week).format('DD MMM YYYY');
    const lastDate = moment(firstDate).add(6, 'days').format('DD MMM YYYY');
    const dateRange = { firstDate, lastDate };
    return dateRange;
  };

  const audit_week = isEmptyObject(date) ? calculateDateFromWeekNumAndYear(currentYear, selectedWeek?.week_no) : date;

  const getBusinessRscoreDataP3W = () => {
    setIsLoading(true);
    getAllRscore(
      {
        filter: {
          dateRange: {
            start: getP3WStartDay(),
            end: getCurrentDay(),
          },
        },
        buIds,
      },
      {
        handleSuccess: res => {
          dispatch(rScoreP3MReq());
          const response = res?.data?.getRscoreData;
          dispatch(rScoreP3WSuccess(response));
          setIsLoading(false);
        },
        handleError: error => {
          onAPIRequestFailure(error);
          dispatch(rScoreP3WFail(error));
          setIsLoading(false);
        },
      },
    );
  };

  const getBusinessRscoreDataP3M = () => {
    setIsLoading(true);
    getAllRscore(
      {
        filter: {
          dateRange: {
            start: getP3MStartDay(),
            end: getCurrentDay(),
          },
        },
        buIds,
      },
      {
        handleSuccess: res => {
          dispatch(rScoreP3MReq());
          const response = res?.data?.getRscoreData;
          dispatch(rScoreP3MSuccess(response));
          setIsLoading(false);
        },
        handleError: error => {
          onAPIRequestFailure(error);
          dispatch(rScoreP3MFail(error));
          setIsLoading(false);
        },
      },
    );
  };

  const getBusinessRscoreData = () => {
    setIsLoading(true);
    getAllRscore(
      {
        filter: {
          dateRange: {
            start: audit_week?.firstDate,
            end: audit_week?.lastDate,
          },
        },
        buIds,
      },

      {
        handleSuccess: res => {
          dispatch(rScoreReq());
          const response = res?.data?.getRscoreData;
          dispatch(rScoreSuccess(response));
          setIsLoading(false);
        },
        handleError: error => {
          onAPIRequestFailure(error);
          dispatch(rScoreFail(error));
          setIsLoading(false);
        },
      },
    );
  };

  const handleDateRangeChange = (label, changeDate) => {
    setDate({
      firstDate: formatter(changeDate.start).format('DD MMM YYYY'),
      lastDate: formatter(changeDate.end).format('DD MMM YYYY'),
    });
    setSelectedWeek({});
    setIsCustomWeekSelected(true);
  };

  return (
    <>
      {isLoading && <Loading title="Loading data... Please wait!" />}

      <RscoreStyled>
        <div className="rscore-container">
          <div className="header">
            <div className="header-title">
              <h2>
                <RscoreReportDropDown
                  currentWeek={currentWeek}
                  weekList={weekList.reverse()}
                  onSelectChange={onSelectChange}
                  selectedWeek={selectedWeek}
                  audit_week={audit_week}
                  date={date}
                  handleDateRangeChange={handleDateRangeChange}
                  isCustomWeekSelected={isCustomWeekSelected}
                />
              </h2>
            </div>

            <div className="header-wrap">
              {P3WRSCORE_TO_CHART?.length > 0 && (
                <div className="header-number">
                  {totalPercent > 0 ? (
                    fixedFloatAndCommas(totalPercent, 1)
                  ) : (
                    <div style={{ fontSize: '15px' }}> {WEEKLY_WISE_RSCORE?.length > 0 ?? <>Loading... </>}</div>
                  )}
                  {totalPercent > 0 ? '%' : ''}
                </div>
              )}

              <div className="header-composedChart">
                {P3WRSCORE_TO_CHART?.length > 0 && (
                  <Composedchart
                    data={_.sortBy(P3WRSCORE_TO_CHART, 'week')}
                    dimensions={dimension}
                    isXAxis={true}
                    isYAxis={true}
                    legend={false}
                    cartesianGrid={false}
                    tooltip={false}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="brandRScore-wrap">
            {businessRscore?.length > 0 &&
              businessRscore
                ?.sort()
                ?.map(data => (
                  <BrandRscore
                    data={data}
                    audit_week={audit_week}
                    selectedWeek={selectedWeek}
                    weekList={weekList}
                    date={date}
                    isCustomWeekSelected={isCustomWeekSelected}
                  />
                ))}
            {businessRscore?.length === 0 && (
              <div style={{ width: '100%' }}>
                <DataNotFound />
              </div>
            )}
          </div>
        </div>
      </RscoreStyled>
    </>
  );
};

Rscore.propTypes = propTypes;
Rscore.defaultProps = defaultProps;

export default withAlert()(Rscore);
