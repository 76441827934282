import styled from 'styled-components';

const TargetAchieveStyled = styled.div`
  .filter-wrap {
    .form-select-input {
      margin: 0;
      label {
        display: none;
      }
      .select-css {
        .zindex-2__control {
          width: 120px;
          min-height: 32px;
          height: 32px;
          .zindex-2__single-value {
            font-size: 14px;
            color: #6b6c7e;
            top: 42%;
          }
          .zindex-2__indicators {
            .icon {
              color: #6b6c7e;
              position: relative;
              top: -1px;
            }
          }
        }
      }
    }
  }

  table tr th:first-child,
  table tr td:first-child,
  table tr th:last-child,
  table tr td:last-child,
  table tr th:nth-child(2),
  table tr td:nth-child(2),
  table tr th:nth-child(4),
  table tr td:nth-child(4),
  table tr th:nth-child(3),
  table tr td:nth-child(3) {
    width: unset;
    width: 16%;
    border-right: 0;
  }
  .table-wrap > table > tbody {
    /* for gap only pad */
    & > .inActive,
    & > .active {
      & > td {
        &:first-child {
          width: 39%;
        }
      }
    }

    & > .active + .collapsible-body-tr {
      & > .collapsible-body-td {
        border-bottom: 0;
        & > table {
          & > tbody {
            & > .inActive,
            & > .active {
              & > td {
                &:first-child {
                  width: 39%;
                }
              }
            }

            /* --2step nested */

            & > .active + .collapsible-body-tr {
              border: 0;
              & > .collapsible-body-td {
                & > table {
                  & > tbody {
                    & > .inActive,
                    & > .active {
                      td {
                        &:first-child {
                          width: 39%;
                        }
                      }
                    }

                    & > .active + .collapsible-body-tr {
                      & > .collapsible-body-td {
                        & > table {
                          & > tbody {
                            & > tr {
                              &.active,
                              &.inActive {
                                td {
                                  &:first-child {
                                    width: 39%;
                                  }
                                }
                              }
                            }

                            & > .active + .collapsible-body-tr {
                              & > .collapsible-body-td {
                                & > table {
                                  & > tbody {
                                    tr {
                                      td {
                                        &:first-child {
                                          width: 39%;
                                        }
                                      }
                                    }
                                    & > .active + .collapsible-body-tr {
                                      & > .collapsible-body-td {
                                        padding:0 !important;
                                        &>table{
                                          &>tbody{
                                            & > tr {
                                              &.active,
                                              &.inActive {
                                                td {
                                                  &:first-child {
                                                    width: 39%;
                                                  padding-left: calc(144px + 48px + 48px) !important;
                                                  }
                                                }
                                              }
                                            }
                                            & > .active + .collapsible-body-tr {
                                      & > .collapsible-body-td {
                                        padding:0 !important;
                                        &>table{
                                          &>tbody{
                                            & > tr {
                                              &.active,
                                              &.inActive {
                                                td {
                                                  &:first-child {
                                                    width: 39%;
                                                    padding-left: calc(144px + 48px + 48px + 40px) !important;
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                          }
                                        }
                                      }
                                    }

                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            /* --2step nested  end*/
          }
        }
      }
    }

    /* for gap only pad end */
  }
  .tr-head ~ tr {
    td {
      &:last-child {
        text-align: right;
      }
    }
  }
  .tr-head {
    height: 64px;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    background: #f0f5ff;
    th {
      border-bottom: 1px solid #0c5fff !important;
    }
  }
`;

export default TargetAchieveStyled;
