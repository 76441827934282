import React, { Fragment } from 'react';
import childView from './childView';
import { TableBody } from '../tableConfig';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import AccordionTable from '../../../../../components/CollapsibleTable/AccordionTable';

const DetailView = ({ ...props }) => {
  const { data, loading, onRowClick, activeCallIndex, replacedLoading, handleSliderOpen, onOrderStatusChangeClick } =
    props;

  return (
    <tbody>
      {
        <AccordionTable>
          {data.map((replaced, index) => (
            <tr
              key={replaced.id}
              trigger={TableBody({
                data: replaced,
                onRowClick,
              })}
            >
              {replaced.totalLineCount && index === activeCallIndex ? (
                childView({
                  data: replaced.Orders || [],
                  handleSliderOpen,
                  replacedId: replaced.id,
                  loading: loading || replacedLoading,
                  onOrderStatusChangeClick,
                  logisticsDelivery: replaced.Distributor.servicesUsed.logistic.status,
                })
              ) : (
                <Fragment />
              )}
            </tr>
          ))}
        </AccordionTable>
      }
    </tbody>
  );
};

const ReceivedDetailViewWithErrorAndLoading = withErrorAndLoadingScreen(DetailView);

export default ReceivedDetailViewWithErrorAndLoading;
