import React from 'react';
import { useSelector } from 'react-redux';
import { Bar, XAxis } from 'recharts';
import Barchart from '../../../../../components/Chart/BarChart';
import { filterItems } from '../../../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import CustomLegend from '../../../components/Legend/CustomLegend';
import { brandColor, dimension, getLegend, OSA, regex, SOD, SOS } from './config';
import { FundamentalByOutletStyled } from './FundamentalByOutletStyled';

const FundamentalByMrOutlet = ({ bu, outletChannel }) => {
  const rScore = useSelector(state => state?.RscoreDatas?.rScore);

  const RSCORE_BU = filterItems(rScore, bu, 'bu');

  const OUTLET_CHANNEL = filterItems(RSCORE_BU, outletChannel, 'outlet_channel');

  const RSCORE_BY_OUTLET = _(OUTLET_CHANNEL).groupBy('outlet').value();

  const FUNDAMENTAL_MR_OUTLET = [];
  for (const property in RSCORE_BY_OUTLET) {
    const periodWise = RSCORE_BY_OUTLET[property];

    const outlet_channel_percent = _(periodWise)
      .groupBy('fundamental')
      .map((item, fundamental) => ({
        fundamental: fundamental
          ?.replace('Share of availability', 'On Shelf Availability')
          ?.replace('Share of display', 'Share of Display')
          ?.replace('Share of shelf', 'Share of Shelf'),
        percent: fixedFloatAndCommas((_.sumBy(item, 'point_scored') / _.sumBy(item, 'points_allocated')) * 100, 1),
      }))
      .value();
    const outlet = outlet_channel_percent.reduce((acc, obj) => {
      const key = obj['fundamental'];
      return { ...acc, [key]: obj['percent'] };
    }, {});
    FUNDAMENTAL_MR_OUTLET.push({ ...outlet, outlet: property.replace(regex, '') });
  }

  return (
    <>
      {FUNDAMENTAL_MR_OUTLET.length > 0 && (
        <FundamentalByOutletStyled>
          <Barchart
            data={FUNDAMENTAL_MR_OUTLET}
            dimensions={dimension}
            isXAxis={true}
            isYAxis={true}
            legend={false}
            cartesianGrid={false}
            tooltip={true}
            color={brandColor}
            xAxisTitle="Score"
            yAxisTitle="MR Outlets"
            title="Fundamentals By  MR Outlets"
            barGap={0}
            barSize={45}
            needCustomize={true}
            aspect={3}
            outletChannel={outletChannel}
          >
            <XAxis dataKey="outlet" axisLine={false} />

            {FUNDAMENTAL_MR_OUTLET.some(obj => obj.hasOwnProperty(SOS)) && (
              <Bar
                dataKey="Share of Shelf"
                fill={brandColor[outletChannel].sos}
                label={{ position: 'top', fill: '#030913', fontSize: '12px', fontWeight: '600' }}
                cursor="pointer"
                isAnimationActive={false}
              ></Bar>
            )}

            {FUNDAMENTAL_MR_OUTLET.some(obj => obj.hasOwnProperty(OSA)) && (
              <Bar
                dataKey="On Shelf Availability"
                fill={brandColor[outletChannel].soa}
                label={{ position: 'top', fill: '#030913', fontSize: '12px', fontWeight: '600' }}
                cursor="pointer"
                isAnimationActive={false}
              ></Bar>
            )}

            {FUNDAMENTAL_MR_OUTLET.some(obj => obj.hasOwnProperty(SOD)) && (
              <Bar
                dataKey="Share of Display"
                fill={brandColor[outletChannel].sod}
                label={{ position: 'top', fill: '#030913', fontSize: '12px', fontWeight: '600' }}
                cursor="pointer"
                isAnimationActive={false}
              ></Bar>
            )}
          </Barchart>
          <CustomLegend data={getLegend(outletChannel)} />
        </FundamentalByOutletStyled>
      )}
    </>
  );
};

export default FundamentalByMrOutlet;
