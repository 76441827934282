
import React from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from '../../../../../../../components';
import { EVENT_OPERATION } from '../../../../../../../data/enums/EventOperation';
import { menuAction, defaultMenuConfigList, tableData } from '../../../../../../../utils/paginatedListUtils';
import { PERMISSION_OBJ } from '../../../../../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  numberofSkus: 'numberofSkus',
  start_date: 'start_date',
  end_date: 'end_date',
  tags: 'tags',
  actionHolders: 'actionHolders',
};

const menuConfigList = [
  defaultMenuConfigList[EVENT_OPERATION.DELETE],
];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'SBD Template',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.numberofSkus,
    title: 'No. of SKUs',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.start_date,
    title: 'Start Date',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.end_date,
    title: 'End Date',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.tags,
    title: 'Tags',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const tableHeaderWithCheckBox = (dataListLength, checkedListLength, primaryCheckboxHandler, permission,
  labelConfig, labelMappings, newTitleObj) => (
    <thead className="">
      <tr>
        {labelConfig.map(label => (label.show
          ? label.label === (labelMappings.checkbox || 'checkbox')
            ? permission.delete && (
              <th key={label.id}>
                <CheckBox
                  checked={dataListLength > 0 && (dataListLength === checkedListLength)}
                  onCheckBoxClick={e => primaryCheckboxHandler(e)}
                />
              </th>
            )
            : tableData(label.title, label.align, label.classNames, label.dynamicTitle, label.id, newTitleObj)
          : null))}
      </tr>
    </thead>
);

const TableHeader = ({
  dataListLength, checkedListLength, primaryCheckboxHandler, permission,
}) => (tableHeaderWithCheckBox(dataListLength, checkedListLength, primaryCheckboxHandler, permission,
  labelConfig, labelMappings));

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.checkbox] = (permission.delete && (
    <td
      key={`sbd-${data.id}-check`}
      onClick={e => e.stopPropagation()}
    >
      <CheckBox
        className={data.canbeDeleted || 'disabled'}
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
      />
    </td>
  ));
  table[labelMappings.title] = <td key={`sbd-${data.sbdtemplate}-title`}>{data.title}</td>;
  table[labelMappings.numberofSkus] = <td key={`sbd-${data.sbdtemplate}-numberofSkus`}>{data.numberofSkus}</td>;
  table[labelMappings.start_date] = <td key={`sbd-${data.sbdtemplate}-start_date`}>{data.start_date}</td>;
  table[labelMappings.end_date] = <td key={`sbd-${data.sbdtemplate}-end_date`}>{data.end_date}</td>;
  table[labelMappings.tags] = <td key={`${data.sbdtemplate}-tags`} className="tags">{data.tags.map(a => <span>{a}</span>)}</td>;
  table[labelMappings.actionHolders] = (
    <td
      key={`${data.sbdtemplate}-action`}
      className="text-right simple-popup-actions"
      onClick={e => e.stopPropagation()}
    >
      {menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        data,
        permission,
      )}
    </td>
  );

  return table;
};

const TableBody = ({
  data, checkedList, onIconClick, secondaryCheckboxHandler, permission,
  onTableRowClick,
}) => {
  const tableBody = returnTableBody(data, checkedList,
    onIconClick, secondaryCheckboxHandler, permission);
  return (
    <tr
      key={data.id}
      style={{ cursor: 'pointer' }}
      onClick={() => onTableRowClick(data.id, EVENT_OPERATION.UPDATE)}
    >
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
  onTableRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export {
  TableHeader,
  TableBody,
};
