import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ImageColumn } from '../../../../utils/tableUtils';
import { Badge, menuAction, tableData, CheckBox } from '../../../../v4/components';
import { OUTLET_DETAILS } from '../../../../data/enums/Route';
import { menuConfigList } from '../../../../data/enums/Support';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { normalPresentor } from '../../../../utils/date';

const labelMappings = {
  checkbox: 'checkbox',
  outlet_name: 'outletName',
  route: 'route',
  town: 'town',
  status: 'status',
  lastUpdatedDate: 'lastUpdatedDate',
  updateCount: 'updateCount',
  image: 'image',
  unSuccessfulImage: 'unSuccessfulImage',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.outlet_name,
    title: 'Outlet Name',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.town,
    title: 'Town',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.lastUpdatedDate,
    title: 'Last Updated Date',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 7,
    label: labelMappings.updateCount,
    title: 'Update Count',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 8,
    label: labelMappings.image,
    title: 'Images',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.unSuccessfulImage,
    title: 'Unsuccessful Image',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const TableHeader = ({ checkedListLength, primaryCheckboxHandler, permission, dataList }) => {
  const checkPending = dataList.filter((item) => item.status === 'PENDING');
  const checkPendingLength = checkPending.length;
  return (
    <thead>
      <tr>
        {labelConfig.map((label) => label.show
          ? label.label === labelMappings.checkbox
            ? permission &&
            permission.update && (
              <th key={`cheque-${label.id}-checkboxheader`}>
                <CheckBox
                  checked={checkPendingLength !== 0 && checkPendingLength === checkedListLength}
                  onCheckBoxClick={(e) => primaryCheckboxHandler(e)}
                />
              </th>
            )
            : tableData(label)
          : null,
        )}
      </tr>
    </thead>
  );
};

const returnTableBody = (
  data,
  checkedList,
  onSecondaryCheckBoxClick,
  handleSliderOpen,
  onIconClick,
  handleRowClick,
  withOutIcon,
  permission,
) => {
  const table = {};
  table[labelMappings.checkbox] = permission && (
    <td key={`${data.id}-checkbox`} onClick={(e) => e.stopPropagation()}>
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
        disabled={data.status !== 'PENDING'}
      />
    </td>
  );
  table[labelMappings.outlet_name] = (
    <td key={`${data.id}-outlet_name`}>
      <Link to={`/${OUTLET_DETAILS}/${data.geolocationChangeData.outletId}`}>
        {data.geolocationChangeData.RetailOutlet.title}
      </Link>
    </td>
  );
  table[labelMappings.route] = (
    <td key={`${data.id}-route`}>
      {data.geolocationChangeData.RetailOutlet.Route && data.geolocationChangeData.RetailOutlet.Route.title}
    </td>
  );
  table[labelMappings.town] = (
    <td key={`${data.id}-town`}>
      {data.geolocationChangeData.RetailOutlet.Town && data.geolocationChangeData.RetailOutlet.Town.title}
    </td>
  );
  table[labelMappings.status] = (
    <td key={`${data.id}-status`}>
      {' '}
      <span className={data.status.toLowerCase()}>
        <span className='badge_border'>
          <Badge light>{data.status}</Badge>
        </span>
      </span>
    </td>
  );
  table[labelMappings.lastUpdatedDate] = (
    <td key={`${data.id}-lastUpdatedDate`} className='text-right'>
      {data.lastUpdatedDate ? normalPresentor(data.lastUpdatedDate) : ''}
    </td>
  );
  table[labelMappings.updateCount] = (
    <td
      key={`${data.id}-updateCount`}
      className='text-right'
      onClick={(e) => {
        e.stopPropagation();
        onIconClick(EVENT_OPERATION.VIEW, data, 'history');
      }}
    >
      {data.updateCount}
    </td>
  );
  table[labelMappings.image] = (
    <ImageColumn
      keyTitle={`rp-image-${data.id}`}
      imageList={data.geolocationChangeData.image || []}
      onImageClick={handleSliderOpen}
    />
  );
  table[labelMappings.unSuccessfulImage] = (
    <ImageColumn
      keyTitle={`rp-unSuccessfulImage-${data.id}`}
      imageList={data.unsuccessfulOutletImage || []}
      onImageClick={handleSliderOpen}
    />
  );
  table[labelMappings.actionHolders] = (
    <td
      key={`${data.id}-action`}
      onClick={(e) => e.stopPropagation()}
      className={
        checkedList.includes(data.id) ? 'disabled text-right simple-popup-actions' : 'text-right simple-popup-actions'
      }
    >
      {data.status === 'PENDING' &&
        menuAction(
          menuConfigList,
          { menuIcon: 'ellipsis-v' },
          onIconClick,
          data.id,
          // withOutIcon,
          permission,
        )}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  handleSliderOpen,
  onIconClickRow,
  handleRowClick,
  withOutIcon,
  permission,
}) => {
  const tableBody = returnTableBody(
    data,
    checkedList,
    secondaryCheckboxHandler,
    handleSliderOpen,
    onIconClick,
    handleRowClick,
    withOutIcon,
    permission,
  );
  return (
    <tr
      key={data.id}
      onClick={() => onIconClickRow(EVENT_OPERATION.READ, data, handleRowClick(data))}
      style={{ cursor: 'pointer' }}
    >
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  checkedList: PropTypes.instanceOf(Array),
  onIconClick: PropTypes.func.isRequired,
  onIconClickRow: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
  withOutIcon: PropTypes.bool.isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
