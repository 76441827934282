import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../../../components';
import withLoading from '../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MonthPicker, SingleDatePicker } from '../../../../../v4/components';
import { dateFormat, get } from './config';
import moment from 'moment';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  type: PropTypes.string,
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  type: '',
};

const TargetAchievementForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay, type, handleDateChange, targetDetail } = props;

  const startDate = dateFormat(targetDetail.start_date);
  const endDate = dateFormat(targetDetail.end_date);

  const permission = get('rosia-bu-settings');

  const filterPermission = (program, field) => {
    const filter = permission?.fundamental_targets?.find(a => a.targetType === program)?.parameters;
    return filter?.filter(m => m.alias === field)[0];
  };

  const ulpo = filterPermission('OUTLETTARGET', 'ULPO');
  const tls = filterPermission('OUTLETTARGET', 'TLS');
  const sbd = filterPermission('OUTLETTARGET', 'SBD');
  const dgp = filterPermission('OUTLETTARGET', 'DGP');
  const bill_cuts = filterPermission('OUTLETTARGET', 'Bill Cuts');

  return (
    <Form>
      <>
        <div className="month-flex">
          <div className="single-date-picker one disable">
            <label>Start Date</label>
            <SingleDatePicker date={startDate} onSelect={e => handleDateChange(e, 'duration')} disabled />
          </div>

          <div className="single-date-picker one disable">
            <label>End Date</label>
            <SingleDatePicker date={endDate} onSelect={e => handleDateChange(e, 'duration')} disabled />
          </div>
        </div>

        {ulpo?.status ? (
          <Input
            ref={ref => (refsObj.ulpo = ref)}
            value={data.ulpo}
            name="ulpo"
            type="number"
            argument={{ min: 0 }}
            placeholder="0"
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={event => handleInputChange(event)}
            labelContent="ULPO"
            disabled={type === EVENT_OPERATION.READ}
            decimalWithPrecedingZero
          />
        ) : null}

        {/* <Input
          ref={ref => (refsObj.ulpo = ref)}
          value={data.ulpo}
          name="ulpo"
          type="number"
          argument={{ min: 0 }}
          placeholder="0"
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={event => handleInputChange(event)}
          labelContent="ULPO"
          disabled={type === EVENT_OPERATION.READ}
          decimalWithPrecedingZero
        /> */}

        {tls?.status && (
          <Input
            ref={ref => (refsObj.tls = ref)}
            value={data.tls}
            name="tls"
            type="number"
            argument={{ min: 0 }}
            placeholder="0"
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={event => handleInputChange(event)}
            labelContent="TLS"
            disabled={type === EVENT_OPERATION.READ}
            decimalWithPrecedingZero
          />
        )}

        {sbd?.status && (
          <Input
            ref={ref => (refsObj.sbd = ref)}
            value={data.sbd}
            name="sbd"
            type="number"
            argument={{ min: 0 }}
            placeholder="0"
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={event => handleInputChange(event)}
            labelContent="SBD"
            disabled={type === EVENT_OPERATION.READ}
            decimalWithPrecedingZero
          />
        )}

        {dgp?.status && (
          <Input
            ref={ref => (refsObj.dgp = ref)}
            value={data.dgp}
            name="dgp"
            type="number"
            argument={{ min: 0 }}
            placeholder="0"
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={event => handleInputChange(event)}
            labelContent="DGP"
            disabled={type === EVENT_OPERATION.READ}
            decimalWithPrecedingZero
          />
        )}

        {bill_cuts?.status && (
          <Input
            ref={ref => (refsObj.bill_cuts = ref)}
            value={data.bill_cuts}
            name="bill_cuts"
            type="number"
            argument={{ min: 0 }}
            placeholder="0"
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={event => handleInputChange(event)}
            labelContent="Bill Cuts"
            disabled={type === EVENT_OPERATION.READ}
            decimalWithPrecedingZero
          />
        )}

        <Input
          ref={ref => (refsObj.sales = ref)}
          value={data.sales}
          name="sales"
          type="number"
          argument={{ min: 0 }}
          placeholder="0"
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={event => handleInputChange(event)}
          labelContent="Sales"
          disabled={type === EVENT_OPERATION.READ}
          decimalWithPrecedingZero
        />
      </>
    </Form>
  );
};

TargetAchievementForm.propTypes = propTypes;

TargetAchievementForm.defaultProps = defaultProps;

const TargetAchievementFormWithErrorAndLoading = withLoading(TargetAchievementForm);

export default TargetAchievementFormWithErrorAndLoading;
