import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_OUTLET_CHAIN_LIST = gql`
  query getOutletChain($searchText: String) {
    getOutletChain(searchText: $searchText) {
        id
        title
        active
        business_partner_id
        image_url {
          image
          thumbnail
          created_at
        }
    }
  }
`;


const CREATE_OUTLET = graphql(
  gql`
  mutation CreateRetailOutletChain($input: OutletChainInput!) {
    createRetailOutletChain(input: $input) {
      id,
      active
      title
      business_partner_id
      image_url{
        image
        thumbnail
        created_at
      }
    }
  }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createOutletChain: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);
const UPDATE_OUTLET = graphql(
  gql`
  mutation CreateRetailOutletChain($input: OutletChainInput!) {
    createRetailOutletChain(input: $input) {
      id,
      active
      title
      business_partner_id
      image_url{
        image
        thumbnail
        created_at
      }
    }
  }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateOutletChain: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getOutletChainList:GET_OUTLET_CHAIN_LIST,
};

const mutation = {
  createOutletChain: CREATE_OUTLET,
  updateOutletChain: UPDATE_OUTLET,
};

export {
  GET_OUTLET_CHAIN_LIST, CREATE_OUTLET,UPDATE_OUTLET,
};

export { query, mutation };
