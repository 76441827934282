const dimension = {
  height: 300,
  width: 600,
};

const brandColor = {
  BBSM: '#0e69ff',
  BigMart: '#f37e39',
  Salesways: '#43c187',
  KSM: '',
  DailyGrocery: '',
  KKMart: '',
};

const data = [
  {
    title: 'BBSM',
    color: '#0e69ff',
  },
  {
    title: 'BigMart',
    color: '#f27024',
  },
  {
    title: 'Salesways',
    color: '#43c187',
  },
];

export { dimension, brandColor, data };
