import { FORM_CONFIG } from '../../../../data/enums/config';
import { refGenerator } from '../../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { summaryDetailsMapper } from './summaryDetails/config';
import { PRN } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';
import { STOCK_TYPE } from '../../../common/DomainConfig';

const title = 'Create Purchase Return';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'PRN',
    path: `/${PRN}`,
    active: true,
  },
];

/* const summaryDetailsMapper = element => ({
  id: element.id || '',
  date: element.date || getTodaysDate(),
  salesInvoiceNumber: element.invoiceNumber || '',
  salesReturnInvoiceNumber: element.salesReturnInvoiceNumber || '',
  RetailOutlet: {
    id: element.RetailOutlet ? element.RetailOutlet.id : '',
    title: element.RetailOutlet ? element.RetailOutlet.title : '',
    Customers: element.RetailOutlet ? element.RetailOutlet.Customers : []
  },
  Distributor: { id: element.Distributor ? element.Distributor.id : '' },
  reason: element.reason || '',
  cashStatus: element.cashStatus || false,
}); */

const totalPriceMapper = (element) => ({
  billDiscount: element.billDiscount || 0,
  tradeDiscount: element.tradeDiscount || 0,
  subTotal: element.subTotal || 0,
  total: element.total || 0,
  taxAmount: element.taxAmount || 0,
  taxableAmount: element.taxableAmount || 0,
});

const detailsFormConfig = {
  [FORM_CONFIG.MAPPER]: (element) => summaryDetailsMapper(element, element.distributorId),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPDATE} PRN`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['outletId', 'reason']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};
const formattedLines = (lines) =>
  lines
    .filter((item) => item.updatedQuantity > 0)
    .map((item) => {
      item.stockType = STOCK_TYPE.SALEABLE.value;
      item.grnDetailId = item.id || null;
      item.referenceQuantity = item.quantity || 0;
      item.updatedQuantityReference = item.updatedQuantity || 0;

      return item;
    });

const formMapper = (element, distributorId = 0) => ({
  ...summaryDetailsMapper(element, distributorId),
  lines: element.lines ? formattedLines(element.lines) || [] : [],
  amount: element.amount ? element.amount : totalPriceMapper({}),
  updatedAmount: element.updatedAmount ? element.updatedAmount : totalPriceMapper({}),
});

const formattedSkuLines = (list) =>
  list.map((item) => {
    const prnLineAmount = {
      rate: Number(item.updatedPriceDetails.rate),
      discount: Number(item.updatedPriceDetails.discount),
      netAmount: item.updatedPriceDetails.netAmount,
      grossAmount: item.updatedPriceDetails.grossAmount,
    };
    const modifiedItem = {
      prnLineAmount,
      skuId: item.skuId,
      stockType: item.stockType,
      skuBatchId: item.skuBatchId,
      grnDetailId: item.grnDetailId || item.id || null,
      quantity: item.updatedQuantity,
      damages: item.damages,
      expiry: item.expiry || 0,
      shortages: item.shortages,
    };

    return modifiedItem;
  });

const getPayload = (data) => ({
  type: data.prnType,
  reason: data.reason,
  prnAmount: {
    ...data.amount,
    billDiscount: Number(data.amount.billDiscount),
    tradeDiscount: Number(data.amount.tradeDiscount),
  },
  vendorId: data.vendorId,
  isOldBill: data.oldBillStatus,
  distributorId: data.distributorId,
  prnLines: formattedSkuLines(data.lines),
  grnInvoiceNumber: data.grnInvoiceNumber,
  prnInvoiceNumber: data.prnInvoiceNumber,
  prnDate: data.prnDate,
});

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({ input: getPayload(data) }),
    responseName: 'createPurchaseReturn',
    message: `Purchase Return ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
};

export {
  crudSuccess,
  breadCrumb as breadCrumbConfig,
  title,
  detailsFormConfig,
  formMapper,
  formattedLines,
  summaryDetailsMapper,
  totalPriceMapper,
};
