import React from 'react';
import PropTypes from 'prop-types';
import { getTotalSum } from '../../../common/HelperFunctions';

const Form = (props) => {
  const { data } = props;
  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            <th>Batch</th>
            <th>Manufacturing Date</th>
            <th>Expiry Date</th>
            <th>Cases (cs)</th>
            <th>Pieces (pcs)</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr>
              <td>{item.batch}</td>
              <td>{item.manufactureDate}</td>
              <td>{item.expiryDate}</td>
              <td>{`${item.cases} cs`}</td>
              <td>{`${item.pieces} pcs`}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td colSpan="2" />
            <td>{`${getTotalSum(data, 'cases')} cs`}</td>
            <td>{`${getTotalSum(data, 'pieces')} pcs`}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

Form.propTypes = {
  data: PropTypes.instanceOf(Object),
};

Form.defaultProps = {
  data: {},
};

export default Form;
