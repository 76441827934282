
import PropTypes from 'prop-types';
import TableHeader from '../../base/TableHeader';
import { getUpdatedLabel } from '../../common/HelperFunctions';

const labelMappings = {
  outlet: 'outlet',
  product_group: 'product_group',
  brand: 'brand',
  question: 'question',
  qn_type: 'qn_type',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
    className: 'sorting',
    sortable: false,
  },
  {
    id: 2,
    label: labelMappings.product_group,
    title: 'Category',
    show: true,
    className: 'sorting',
    sortable: false,
  },
  {
    id: 3,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
    className: 'sorting',
    sortable: false,
  },
  {
    id: 4,
    label: labelMappings.question,
    title: 'Question Description',
    show: true,
    className: 'sorting',
    sortable: false,
  },
  {
    id: 5,
    label: labelMappings.qn_type,
    title: 'Type of Question',
    show: true,
    className: 'sorting',
    sortable: false,
  },
];

const TableHeaderConfig = ({ onHeaderClick, dynamicHeader, }) => (
  <TableHeader
    headerDetails={dynamicHeader ? getUpdatedLabel(labelConfig, 'Name') : labelConfig}
    onHeaderClick={onHeaderClick}
  />
);

const returnTableBody = (data, index) => {
  const table = {};
  table[labelMappings.outlet] = <td key={`${index}-Audit-${data.outlet}-${data.question}`} >{data.outlet}</td>;
  table[labelMappings.question] = (
    <td key={`${index}-Audit-${data.question}--${data.question}`}>
      {data.question}
    </td>
  );
  table[labelMappings.product_group] = (
    <td key={`${index}-Audit-${data.product_group}--${data.catalog_title}`}>
      {data.product_group}
    </td>
  );

  table[labelMappings.brand] = (
    <td key={`${index}-Audit-${data.brand}--${data.catalog_title}`}>
      {data.brand}
    </td>
  );
  table[labelMappings.qn_type] = (
    <td key={`${index}-Audit-${data.qn_type}-${data.catalog_title}`}>
      {data.qn_type}
    </td>
  );

  return table;
};

const TableBody = ({ data, index }) => {
  const tableBody = returnTableBody(data, index);

  return (
    <tr key={`Audit-${index}-${data.outlet}-details`}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
  dynamicHeader: PropTypes.bool,
};

TableHeaderConfig.defaultProps = {
  dynamicHeader: false,
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeaderConfig as TableHeader, TableBody, labelConfig
};
