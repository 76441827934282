
import PropTypes from 'prop-types';
import { ImageColumn } from '../../../utils/tableUtils';
import TableHeader from '../../base/TableHeader';
import { getUpdatedLabel, headerLabelConfig } from '../../common/HelperFunctions';

const labelMappings = {
  outlet: 'outlet',
  audited_by: 'audited_by',
  audited_date: 'audited_date',
  catalog_title: 'catalog_title',
  audit_type: 'audit_type',
  audit_based_type: 'audit_based_type',
  image: 'image',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
    className: 'sorting',
    sortable: true,

  },
  {
    id: 2,
    label: labelMappings.catalog_title,
    title: 'Catalog Title',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 3,
    label: labelMappings.audited_by,
    title: 'Audited By',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 4,
    label: labelMappings.audited_date,
    title: 'Audit Date',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 5,
    label: labelMappings.audit_type,
    title: 'Audit Type',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 6,
    label: labelMappings.audit_based_type,
    title: 'Audit Based Type',
    className: 'sorting',
    show: true,
    sortable: true,
  },{
    id: 7,
    label: labelMappings.image,
    title: 'Images',
    show: true,
  },
];

const TableHeaderConfig = ({ onHeaderClick, dynamicHeader, }) => (
  <TableHeader
    headerDetails={dynamicHeader ? getUpdatedLabel(labelConfig, 'Name') : labelConfig}
    onHeaderClick={onHeaderClick}
  />
);

const returnTableBody = (data, handleSliderOpen, index) => {
  const table = {};
  table[labelMappings.outlet] = <td key={`${index}-Audit-${data.outlet}-${data.catalog_title}`} >{data.outlet}</td>;
  table[labelMappings.catalog_title] = (
    <td key={`${index}-Audit-${data.catalog_title}--${data.catalog_title}`}>
      {data.catalog_title}
    </td>
  );
  table[labelMappings.audited_by] = (
    <td key={`${index}-Audit-${data.audited_by}--${data.catalog_title}`}>
      {data.audited_by}
    </td>
  );

  table[labelMappings.audited_date] = (
    <td key={`${index}-Audit-${data.audited_date}--${data.catalog_title}`}>
      {data.audited_date}
    </td>
  );
  table[labelMappings.audit_type] = (
    <td key={`${index}-Audit-${data.audit_type}-${data.catalog_title}`}>
      {data.audit_type}
    </td>
  );
  table[labelMappings.audit_based_type] = (
    <td key={`${index}-Audit-${data.audit_based_type}--${data.catalog_title}`}>
      {data.audit_based_type}
    </td>
  );
  table[labelMappings.image] = (
    <ImageColumn
      keyTitle={`${index}-rp-image--${data.catalog_title}`}
      imageList={data.images || []}
      onImageClick={handleSliderOpen}
    />
  );

  return table;
};

const TableBody = ({ data, handleSliderOpen, index }) => {
  const tableBody = returnTableBody(data, handleSliderOpen, index);

  return (
    <tr key={`Audit-${index}-${data.outlet}-details`}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
  dynamicHeader: PropTypes.bool,
};

TableHeaderConfig.defaultProps = {
  dynamicHeader: false,
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeaderConfig as TableHeader, TableBody, labelConfig
};
