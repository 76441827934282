import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../../../components';
import { TableBody, TableHeader } from './tableConfig';
import withLoading from '../../../../../utils/composition/withLoading';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { dataProps, paginationProps } from '../../../../../data/enums/Component';


const PurchaseRequisition = (props) => {
  const {
    data, onPageChange, onIconClick, pagination, permission, onTableRowClick,
  } = props;
  return (
    <>
      <div className="table-wrap">
        <table>
          {
            TableHeader()
          }
          <tbody>
            {data.list.map(requ => (
              TableBody({
                data: requ,
                onTableRowClick,
                permission,
                onIconClick,
                withOutIcon: true,
              })
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </>
  );
};

PurchaseRequisition.propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onBulkDelete: PropTypes.func.isRequired,
  onTableRowClick: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
};

PurchaseRequisition.defaultProps = {
  data: dataProps.defaultValue,
  pagination: dataProps.defaultValue,
  onPageChange: () => null,
  onCheckBOxClick: () => null,
  onIconClick: () => null,
  PermissionRequest: PERMISSION_OBJ,
};

const PurchaseRequistionTable = withLoading(PurchaseRequisition);

export default PurchaseRequistionTable;
