import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_VGP_REPORT_LIST = gql`
  query getVGPReport($limit: Int, $offset: Int, $filter: FilterInput) {
    getVGPReport(limit: $limit, offset: $offset, filter: $filter) {
      count
      rows {
        transaction_date
        customer
        outlet
        outlet_id
        channel
        channel_id
        product_group
        brand
        question
        qn_type
        answer
        score
        updated_by
      }
    }
  }
`;

const query = {
  getVGPReport: GET_VGP_REPORT_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export { query, mutation };
