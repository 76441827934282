import React from 'react';
import PropTypes from 'prop-types';
import { ImageColumn } from '../../../utils/tableUtils';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { tableHeaderWithCheckBox, menuAction } from '../../../v4/components';

const labelMappings = {
  checkbox: 'checkbox',
  id: 'id',
  title: 'title',
  bp_id: 'business_partner_id',
  images:'images',
  actionHolders: 'actionHolders',
};

const menuConfigList = [
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.bp_id,
    title: 'Business Partner Id',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.images,
    title: 'Images',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission, lastLocation }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
    { 5: lastLocation && lastLocation.title },
  );

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission,handleSliderOpen) => {
  const table = {};
    table[labelMappings.id] = <td key={`outlet_chain-${data.id}-id`}>{data.id}</td>;
  table[labelMappings.title] = <td key={`outlet_chain-${data.id}-title`}>{data.title}</td>;
  table[labelMappings.bp_id] = <td key={`outlet_chain-${data.id}-bp_id`}>{data.business_partner_id}</td>;
  table[labelMappings.images] = <>
      <ImageColumn
      keyTitle={`rp-images-${data.id}`}
      imageList={data?.image_url || []}
      onImageClick={handleSliderOpen}
      key={`${data.id}-image`}
    />
  </>;
  
  table[labelMappings.actionHolders] = (
    <td key={`outlet_chain-${data.id}-action`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        {
          ...data
        },
        permission,
      )}
    </td>
  );

  return table;
};

const TableBody = ({ data, checkedList, onIconClick, secondaryCheckboxHandler, onTableRowClick,permission ,handleSliderOpen}) => {
  const tableBody = returnTableBody(data, checkedList,onIconClick, secondaryCheckboxHandler, permission,handleSliderOpen);

  return <tr key={data.id} style={{ cursor: 'pointer' }} onClick={() => onTableRowClick(EVENT_OPERATION.UPDATE, data.id)}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
  onTableRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
