import styled from 'styled-components';

const InventoryStyled = styled.div`
.select-section{
  padding: 0 48px;
}
.mr-16{
  margin-right: 16px;
}
.quantity{
  font-size: 8px;
  text-align: right;
  width: 79px;
  line-height: 10px;
  margin: 2px;
  
  .negative{
   color: #DA1414;
  }
  .positive{
   color: #32A84E;
  }
}
/* Form Styling */
.modal-wrapper{
  .modal-inner{
    max-width:750px
  }
  .evolve-dialog__header{
    h2{
      display: flex;
      flex-direction: column;
      span{
        font-size: 12px;
        color: #6B6C7E;
        font-weight: 500;
      }
    }
  }
  
  .evolve-dialog__body{
    .table{
      tfoot{
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        color:#272833;
      }
    }
  }
}
`;

export default InventoryStyled;
