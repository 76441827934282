import { compose } from 'recompose';
import SubDDetail from './Detail';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query,
  mutation,
  CREATE_SUB_D,
  UPDATE_SUB_D,
  DOWNLOAD_REPORT,
  UPLOAD,
  TOGGLE_STATE,
  SYNC_SKU_TO_BILLING,
} from './API';

const ComposedRoute = compose(
  CREATE_SUB_D,
  UPDATE_SUB_D,
  SYNC_SKU_TO_BILLING,
  DOWNLOAD_REPORT,
  UPLOAD,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(SubDDetail);

export default ComposedRoute;
