import React from 'react';
import PropTypes from 'prop-types';
import {
  CustomSelect, DatePicker,
} from '../../../../../components';
import withLoading from '../../../../../utils/composition/withLoading';
import { REQUISITION_VIEW } from './config';
import { TableBody, TableHeaderConfig } from './tableConfig';
import ViewType from '../../../../components/ViewType';

const propTypes = {
  viewType: PropTypes.string,
  dataListLength: PropTypes.number,
  checkedListLength: PropTypes.number,
  date: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  menu: PropTypes.instanceOf(Object),
  select: PropTypes.instanceOf(Object),
  onDropDownChange: PropTypes.func.isRequired,
  handleViewTypeChange: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  viewTypeConfig: PropTypes.shape({
    value: PropTypes.any,
    list: PropTypes.instanceOf(Array),
  }),
  onIconClick: PropTypes.func.isRequired,
  checkedList: PropTypes.instanceOf(Array),
  onSecondaryCheckBoxClick: PropTypes.func.isRequired,
};

const defaultProps = {
  viewType: REQUISITION_VIEW.BRAND,
  dataListLength: 0,
  checkedListLength: 0,
  date: {},
  data: {},
  menu: {},
  select: {},
  viewTypeConfig: {
    value: '',
    list: [],
  },
  checkedList: [],
  onSecondaryCheckBoxClick: () => null,
};
const View = (props) => {
  const {
    menu, onDropDownChange, data, date, handleViewTypeChange,
    viewTypeConfig,
    handleDateRangeChange, select,
    viewType, onIconClick, dataListLength, primaryCheckboxHandler, checkedList, onSecondaryCheckBoxClick, checkedListLength,
  } = props;
  let sn = 0;
  return (
    <>
      <div className="top-section">
        <div className="select-section">
          <CustomSelect
            name="channel"
            onChange={(event) => {
              onDropDownChange(event.id, 'channelId');
            }}
            options={menu.channel}
            placeholder="Select Channel"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            value={menu.channel.filter(
              ({ id }) => id === select.channelId,
            )}
          />

          <CustomSelect
            name="bu"
            onChange={(event) => {
              onDropDownChange(event.id, 'buId');
            }}
            options={menu.bu}
            placeholder="Select BU"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            disabled={select.channelId === null}
            value={menu.bu.filter(
              ({ id }) => id === select.buId,
            )}
          />
        </div>
        <div className="date-picker">
          <DatePicker
            date={date}
            onChange={handleDateRangeChange}
          />
        </div>
      </div>
      <div className="table-wrap">
        <table>
          {data.list.length > 0 && (
            <>
              <thead className="sku-header">
                <th>SKU</th>
                <th colSpan="9" />
                <th className="group-by">
                  <span>Group By:</span>
                  <ViewType
                    handleDropDownChange={handleViewTypeChange}
                    list={viewTypeConfig.list}
                    value={viewTypeConfig.value}
                    displayLabel={false}
                  />

                </th>
              </thead>
              <TableHeaderConfig
                viewType={viewType}
                dataListLength={dataListLength}
                primaryCheckboxHandler={primaryCheckboxHandler}
                checkedListLength={checkedListLength}
              />
            </>
          )}
          <>
            <tbody>

              {data.list.map((sku, index) => (
                <>
                  <tr key={`${index}-sku`}>
                    <td />
                    <td>{sku.group}</td>
                    <td colSpan="9" />
                  </tr>
                  { sku.skuList.map((a) => {
                    sn += 1;
                    return (
                      TableBody({
                        sn,
                        data: a,
                        viewType,
                        onIconClick,
                        checkedList,
                        onSecondaryCheckBoxClick,
                      })
                    );
                  })}
                </>
              ))}
            </tbody>
          </>
        </table>
      </div>
    </>
  );
};
View.defaultProps = defaultProps;

View.propTypes = propTypes;

export default withLoading(View);
