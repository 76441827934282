import PropTypes from 'prop-types';
import React from 'react';
import { Input } from '../../../../../components';
import Popover from '../../../../../components/Popover';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { getDurationInInteger ,formatToReadableDate } from '../../../../../utils/date';
import TableHeader from '../../../../base/TableHeader';
import { OPERATION } from '../config';
import { labelMappings } from './config';

const getPurchaseHistoryDetails = (purchaseHistoryData = []) => {
  return (
    <div className='purchase-history'>
      <div className='heading'>
        <label>Date</label>
        <label className='text-right'>Quantity</label>
      </div>
      {purchaseHistoryData?.length > 0 && purchaseHistoryData.map((history) => (
        <div key={history.sku_id} className='purchase-detail-row'>
          <div className='item-value'>{formatToReadableDate(history?.date)}</div>
          <div className='item-value text-right'>{history.quantity}</div>
        </div>
      ))}
    </div>
  )
}

const isPromotionAvailable = (isPromotionAvailable) => {
  return isPromotionAvailable ? <img src='/image/icons/Promotion.svg' style={{ cursor: 'pointer' }} /> : null;
}

const getAvailablePromotions = (promotions = []) => {
  return (
    <ul className='available-promotions'>
      {promotions.length > 0 && promotions.map((promotion) => (
        <li key={promotion.id} className='item-value'>{promotion.title}</li>
      ))}
    </ul>
  )
}

const returnTableBody = (data, handleCallTargetChange, isPlanned, showSBDSkuTag) => {
  const table = {};
  const lastInvoicedTimeInterval = data?.last_invoiced_date === null ?  null : data?.last_invoiced_date ? getDurationInInteger(data.last_invoiced_date) : 90;
  const skutitle = (title, showTag,stockavailable) => (
    <>
      {title}
      {showTag ?
        <span className='sbd-sku'>SBD</span>
        : null
      }
      {stockavailable?
        <span className='sbd-sku in-stock-sku'>{`IN STOCK ${data.balance}`}</span>
        : null
      }
    </>
  )
  table[labelMappings.brand] = <td key={`${data.id}- brand`}>{data?.brand?.title}</td>;
  table[labelMappings.sku] = <td key={`${data.id}- sku`}>
    <div className='simple-popup'>
      <Popover
        popoverIcon={skutitle(data.title, Boolean(data.isSBDSku && showSBDSkuTag),data?.stockavailable)}
        disabled={!data.title}
        headerTitle='Purchase History'
      >
        <span className='arrow-left' />
        {data?.purchase_order_history?.length>0?getPurchaseHistoryDetails(data?.purchase_order_history):'N/A'}
      </Popover>
    </div>
  </td>;
  table[labelMappings.rlp] = <td key={`${data.id}- rlp`}>{data?.Rates[0]?.priceDetails?.rlp}</td>;
  table[labelMappings.lastInvoicedQuantity] = <td key={`${data.id}- last_invoiced_quantity`} className='text-center'>{data?.last_invoiced_quantity || '-'}</td>;
  table[labelMappings.lastInvoiced] = <td className={`${lastInvoicedTimeInterval >= 90 || lastInvoicedTimeInterval ===null ? 'red' : 'green'}`} key={`${data.id}- last-invoiced`}>{`${lastInvoicedTimeInterval > 90  ? '90+ days': lastInvoicedTimeInterval ===null ? 'N/A' : `${lastInvoicedTimeInterval} days`}`}</td>;
  table[labelMappings.promotion] = <td key={`${data.id}- promotion`} className='text-center'>
    <div className='simple-popup promotion'>
      <Popover
        popoverIcon={isPromotionAvailable(data?.ispromotionavailable)}
        disabled={!data.ispromotionavailable}
        headerTitle='Available Promotions'
      >
        <span className='arrow-up' />
        {getAvailablePromotions(data?.promotion || [])}
      </Popover>
    </div>
  </td>;
  table[labelMappings.callTarget] = (
    <td className='text-center' key={`${data.id}- action`} onClick={(e) => e.stopPropagation()}>
      {isPlanned ? <p>{data?.pfdtarget ?? '-'}</p> :
        <Input value={data.callTarget} type='number' min={0} onChange={(event) => handleCallTargetChange(event, data.id)} placeholder='0' />
      }
    </td>
  );

  return table;
};

const labelConfig = [
  {
    id: 2,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
    className: 'sorting',
    sortable: true
  },
  {
    id: 3,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
    className: 'sorting',
    sortable: true
  },
  {
    id: 4,
    label: labelMappings.rlp,
    title: 'RLP',
    show: true,
    sortable: false
  },
  {
    id: 5,
    label: labelMappings.lastInvoicedQuantity,
    title: 'Last Invoiced Quantity',
    show: true,
    sortable: false
  },
  {
    id: 6,
    label: labelMappings.lastInvoiced,
    title: 'Last Invoiced',
    show: true,
    sortable: false
  },
  {
    id: 7,
    label: labelMappings.promotion,
    title: 'Promotion',
    show: true,
    className: 'sorting text-center',
    sortable: true
  },
  {
    id: 8,
    label: labelMappings.callTarget,
    title: 'Call Target',
    show: true,
    sortable: false,
    classNames: 'text-center'
  },
];

const TableBody = ({ data, handleCallTargetChange, isPlanned, handleRowClick, showSBDSkuTag }) => {
  const tableBody = returnTableBody(data, handleCallTargetChange, isPlanned, showSBDSkuTag);
  return (
    <tr key={data.id} className={`${data.quantity > 0 ? 'order-added' : ''}`} style={{ cursor: 'pointer' }} onClick={() => handleRowClick(OPERATION.ADD_ORDER, data)}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

const TableHeaderConfig = ({ onHeaderClick }) => (
  <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />
);

TableHeaderConfig.propTypes = {
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
};

TableHeaderConfig.defaultProps = {
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeaderConfig as TableHeader, TableBody };
