import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_BRAND_LIST = gql`
query getBrandListUnderBU($buId: Int!) {
  getBrandListUnderBU(buId: $buId) {
    title: brand
    id: brand_id
  }
}
`;

const GET_QUESTIONNAIRE_DETAILS = gql`
  query getQuestionnaireDetail($id: Int!) {
    getQuestionnaireDetail(id: $id) {
      id
      question
      start
      end
      active
      principal_id
      principal
      channel_id
      channel
      audit_type
      categoryIds {
        id
        category_id
        category
      }
      brandIds {
        id
        brand_id
        brand
      }
    }
  }
`;

const CREATE_QUESTIONNAIRE = graphql(gql`
  mutation CreateQuestionnaire($input: QuestionnaireInput!) {
    createQuestionnaire(input: $input) {
      id
      question
      start
      end
      active
      principal_id
      channel_id
    }
  }`, {
  props: ({ mutate }) => ({
    createQuestionnaire: (variables) => mutate({
      variables,
    }),
  }),
});

const EDIT_QUESTIONNAIRE = graphql(gql`
mutation EditQuestionnaire($id: Int!, $input: QuestionnaireInput!) {
  editQuestionnaire(id: $id, input: $input) {
    active
    channel_id
    end
    id
    principal_id
    question
    start
  }
}`, {
  props: ({ mutate }) => ({
    editQuestionnaire: (variables) => mutate({
      variables,
    }),
  }),
});

const query = {
  getBrandListUnderBU: GET_BRAND_LIST,
  getQuestionnaireDetail: GET_QUESTIONNAIRE_DETAILS,
};

const mutation = {
  createQuestionnaire: CREATE_QUESTIONNAIRE,
  editQuestionnaire: EDIT_QUESTIONNAIRE,
};

export {
  query, mutation,
  CREATE_QUESTIONNAIRE,
  EDIT_QUESTIONNAIRE,
};
