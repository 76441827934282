import React from 'react';
import PropTypes from 'prop-types';
import { normalPresentor } from '../../../utils/date';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { Badge, tableHeaderWithCheckBox, menuAction, CheckBox } from '../../../v4/components';

const labelMappings = {
  checkbox: 'checkbox',
  attribute: 'attribute',
  threshold: 'threshold',
  title: 'title',
  startDate: 'startDate',
  endDate: 'endDate',
  time: 'time',
  status: 'status',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: ' ',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.attribute,
    title: 'Measure',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.threshold,
    title: 'Threshold',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.startDate,
    title: 'Start Date',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.endDate,
    title: 'End Date',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.time,
    title: 'Time',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const menuConfigList = [
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

const returnTableBody = (data, checkedList, onSecondaryCheckBoxClick, permission, onIconClick) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td key={`${data.id}-checkbox`} onClick={(e) => e.stopPropagation()}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.attribute] = <td key={`${data.id}-attribute`}>{data.attribute}</td>;
  table[labelMappings.title] = <td key={`${data.id}-title`}>{data.title}</td>;
  table[labelMappings.threshold] = <td key={`${data.id}-threshold`}>{data.threshold.value}</td>;
  table[labelMappings.startDate] = (
    <td key={`cash-${data.id}-messageCategory`}>
      {normalPresentor(data.messageSchedule && data.messageSchedule.startDate)}
    </td>
  );
  table[labelMappings.endDate] = (
    <td key={`cash-${data.id}-endDate`}>{normalPresentor(data.messageSchedule && data.messageSchedule.endDate)}</td>
  );
  table[labelMappings.time] = <td key={`cash-${data.id}-time`}>{data.messageSchedule && data.messageSchedule.time}</td>;
  table[labelMappings.status] = (
    <td key={`${data.id}-status`}>
      {data.status && (
        <span className={data.status.toLowerCase()}>
          <span className='badge_border'>
            <Badge light>{data.status}</Badge>
          </span>
        </span>
      )}
    </td>
  );
  table[labelMappings.actionHolders] = (
    <td
      key={`${data.id}-actions`}
      className={
        checkedList.includes(data.id) ? 'disabled text-right simple-popup-actions' : 'text-right simple-popup-actions'
      }
      onClick={(e) => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );
  return table;
};

const TableBody = ({ data, checkedList, secondaryCheckboxHandler, permission, onIconClick, onRowClick }) => {
  const tableBody = returnTableBody(data, checkedList, secondaryCheckboxHandler, permission, onIconClick);

  return (
    <tr key={data.id} onClick={() => onRowClick(data.id)}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  checkedList: PropTypes.instanceOf(Array),
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
