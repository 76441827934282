import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BreadCrumb, Icon } from '../../../../v4/components';
import { paginationProps } from '../../../../data/enums/Component';
import { PanelStyled, PanelHeader } from '../../../common/configuration/index';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { breadCrumbConfig } from './config';
import TargetDetailWrapper from './TargetDetailWrapper';
import { numberPresentor } from '../../../../utils/conversion';
import AccordionTable from '../../../../components/CollapsibleTable/AccordionTable';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import PageHeader from '../../../base/PageHeader';


const propTypes = {
  data: PropTypes.instanceOf(Object),
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  handleBrandDialog: PropTypes.func,
  handleDownloadClick: PropTypes.func,
  settings: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: {},
  handleBrandDialog: () => null,
  pagination: paginationProps.defaultValue,
};

const View = ({ ...props }) => {
  const { data, onIconClick, settings, handleBrandDialog, handleDownloadClick } = props;

  const brandTarget = (id, targetData = {}) => (
    <td onClick={(e) => e.stopPropagation()}>
      <span className='view' onClick={() => onIconClick(EVENT_OPERATION.READ, {}, handleBrandDialog(id, targetData))}>
        <Icon iconName='view' />
        View
      </span>
      <span onClick={() => onIconClick(EVENT_OPERATION.UPDATE, {}, handleBrandDialog(id, targetData))}>
        <Icon iconName='pencil' />
        Edit
      </span>
    </td>
  );

  const tableData = (childUser, collapsible = false) => (
    <>
      <td>{collapsible ? <span>{childUser.name}</span> : childUser.name}</td>
      <td>{childUser.role}</td>
      <td>{numberPresentor(childUser.target)}</td>
      {brandTarget(childUser.id, childUser)}
    </>
  );

  const getCollapsibleComponent = (dataList, settings = {}) => (
    <AccordionTable>
      {dataList.map((user) => (
        <tr
          key={user.id}
          trigger={
            <>
              <td>
                <span>{settings.salesTeamWise ? user.name : user.title}</span>
              </td>
              <td>{user.role}</td>
              <td>{numberPresentor(user.target)}</td>
              <td onClick={(e) => e.stopPropagation()}>
                <span
                  className='view'
                  onClick={() => onIconClick(EVENT_OPERATION.READ, {}, handleBrandDialog(user.id, user))}
                >
                  <Icon iconName='view' />
                  View
                </span>
                <span onClick={() => onIconClick(EVENT_OPERATION.UPDATE, {}, handleBrandDialog(user.id, user))}>
                  <Icon iconName='pencil' />
                  Edit
                </span>
              </td>
            </>
          }
          isChildPresent={!user.isChildPresent}
          simpleChild={
            <tr className='active' key={user.id}>
              {tableData(user)}
            </tr>
          }
        >
          <AccordionTable>
            {user.childUsers.map((childUser1) => (
              <tr
                key={childUser1.id}
                trigger={tableData(childUser1, true)}
                isChildPresent={!childUser1.isChildPresent}
                simpleChild={
                  <tr className='active' key={childUser1.id}>
                    {tableData(childUser1)}
                  </tr>
                }
              >
                <AccordionTable>
                  {childUser1.childUsers.map((childUser2) => (
                    <tr
                      key={childUser2.id}
                      trigger={tableData(childUser2, true)}
                      isChildPresent={!childUser2.isChildPresent}
                      simpleChild={
                        <tr className='active' key={childUser2.id}>
                          {tableData(childUser2)}
                        </tr>
                      }
                    >
                      <AccordionTable>
                        {childUser2.childUsers.map((childUser3) => (
                          <tr
                            key={childUser3.id}
                            trigger={tableData(childUser3, true)}
                            isChildPresent={!childUser3.isChildPresent}
                            simpleChild={
                              <tr className='active' key={childUser3.id}>
                                {tableData(childUser3)}
                              </tr>
                            }
                          >
                            <AccordionTable>
                              {childUser3.childUsers.map((childUser4) => (
                                <tr
                                  key={childUser4.id}
                                  trigger={tableData(childUser4, true)}
                                  isChildPresent={!childUser4.isChildPresent}
                                  simpleChild={
                                    <tr className='active' key={childUser4.id}>
                                      {tableData(childUser4)}
                                    </tr>
                                  }
                                >
                                  <AccordionTable>
                                    {childUser4.childUsers.map((childUser5) => (
                                        <tr
                                            key={childUser5.id}
                                            trigger={tableData(childUser5, true)}
                                            isChildPresent={!childUser5.isChildPresent}
                                            simpleChild={
                                              <tr className='active' key={childUser5.id}>
                                                {tableData(childUser5)}
                                              </tr>
                                            }
                                        >
                                          {childUser5.isChildPresent &&
                                              childUser5.childUsers.length !== 0 &&
                                              childUser5.childUsers.map((childUser6) => (
                                                  <tr className='active' key={childUser6.id}>
                                                    {tableData(childUser6)}
                                                  </tr>
                                              ))}
                                        </tr>
                                    ))}
                                  </AccordionTable>
                                </tr>
                              ))}
                            </AccordionTable>
                          </tr>
                        ))}
                      </AccordionTable>
                    </tr>
                  ))}
                </AccordionTable>
              </tr>
            ))}
          </AccordionTable>
        </tr>
      ))}
    </AccordionTable>
  );

  return (
    <Fragment>
      <div className='section-header'>
        <PanelStyled>
          <BreadCrumb list={breadCrumbConfig} /> 
          <PanelHeader>
            <h2>{data[0] && data[0].monthName && data[0].year && `${data[0].monthName}, ${data[0].year}`}</h2>
            <PageHeader
            display={{}}
            config={{
              create: false,
              download: true,
              filter: false,
              upload: false,
              date: false,
            }}
            handleDownloadClick={ handleDownloadClick }
          />
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className='section-content'>
        <TargetDetailWrapper>
          <div className='table-wrap'>
            {settings.salesTeamWise && (
              <table>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Role</th>
                    <th>Target</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{getCollapsibleComponent(data, settings)}</tbody>
              </table>
            )}
            {settings.subDWise && (
              <table>
                <thead>
                  <tr>
                    <th>Sub D</th>
                    <th>Target</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {data.map((subD) => (
                    <tr key={subD.id}>
                      <td>{subD.title}</td>
                      <td>{numberPresentor(subD.target)}</td>
                      {brandTarget(subD.id)}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </TargetDetailWrapper>
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
