import gql from 'graphql-tag';

const GET_ALL_RSCORE = gql`
  query GetRscoreData($filter: FilterInput, $buIds: [Int]) {
    getRscoreData(filter: $filter, buIds: $buIds) {
      bu
      bu_id
      fundamental_id
      fundamental
      fundamental_type
      outlet_channel_id
      outlet_channel
      outlet_id
      outlet
      audit_period
      category
      brand
      points_allocated
      point_scored
      bonus_point
      gap_point
      compliance
      category_id
      month
      images
      audited_date
    }
  }
`;

const GET_SOSKU_IMAGES = gql`
  query GetSOSKUImages($buId: Int, $outletId: Int, $filter: FilterInput) {
    getSOSKUImages(buId: $buId, outletId: $outletId, filter: $filter) {
      images
      brand
      market_catalog
      sku_family
      audited_at
    }
  }
`;

const query = {
  getAllRscore: GET_ALL_RSCORE,
  getSOSKUImages: GET_SOSKU_IMAGES,
};

export {
  query, GET_ALL_RSCORE, GET_SOSKU_IMAGES
};
