import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../../base/TableHeader';
import { REQUISITION_VIEW } from './config';
import { normalPresentor } from '../../../../../utils/date';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { menuAction } from '../../../../../utils/paginatedListUtils';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { CheckBox } from '../../../../../components';

const labelMappings = {
  checkbox: 'checkbox',
  sn: 'sn',
  sku: 'sku',
  lastPurchasedDate: 'lastPurchasedDate',
  p3mOfftake: 'p3mOfftake',
  p1mOfftake: 'p1mOfftake',
  stock: 'stock',
  quantity: 'quantity',
  rate: 'rate',
  amount: 'amount',
  actionHolders: 'actionHolders',
};


const viewBasedHeaderConfig = {
  [REQUISITION_VIEW.BRAND]: [
    {
      id: 1,
      label: labelMappings.checkbox,
      title: '',
      show: true,
    },
    {
      id: 2,
      label: labelMappings.sn,
      title: 'SN',
      show: true,
    },
    {
      id: 3,
      label: labelMappings.sku,
      title: 'SKU',
      show: true,
    },
    {
      id: 4,
      label: labelMappings.lastPurchasedDate,
      title: 'Last Purchased Date',
      show: true,
    },
    {
      id: 5,
      label: labelMappings.p3mOfftake,
      title: 'P3M Offtake',
      show: true,
      className: 'text-right',
    },
    {
      id: 6,
      label: labelMappings.p1mOfftake,
      title: 'P1M Offtake',
      show: true,
      className: 'text-right',
    },
    {
      id: 7,
      label: labelMappings.stock,
      title: 'Warehouse Stock',
      show: true,
      className: 'text-right',
    },
    {
      id: 8,
      label: labelMappings.quantity,
      title: 'Quantity',
      show: true,
    },
    {
      id: 9,
      label: labelMappings.rate,
      title: 'Rate',
      show: true,
    },
    {
      id: 10,
      label: labelMappings.amount,
      title: 'Amount',
      show: true,
    },
    {
      id: 11,
      label: labelMappings.actionHolders,
      title: '',
      show: true,
    },
  // {
  //   id: 12,
  //   label: labelMappings.test,
  //   title: '',
  //   show: true,
  // },
  ],
};


const TableHeaderConfig = ({
  viewType, dataListLength, primaryCheckboxHandler, checkedListLength,
}) => (
  <TableHeader
    checkedListLength={checkedListLength}
    headerDetails={viewBasedHeaderConfig[viewType]}
    dataListLength={dataListLength}
    primaryCheckboxHandler={primaryCheckboxHandler}
  />
);

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
];

const returnTableBody = (sn, data, onIconClick, checkedList, onSecondaryCheckBoxClick) => {
  const table = {};
  table[labelMappings.checkbox] = (
    <td>
      <CheckBox
        checked={checkedList.includes(data.skuId)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.skuId)}
      />
    </td>
  );
  table[labelMappings.sn] = <td key={`${data.id}- id`}>{sn}</td>;
  table[labelMappings.sku] = <td key={`${data.id}- title`}   className= 'sku'>{data.SKU.title}</td>;
  table[labelMappings.lastPurchasedDate] = (
    <td key={`${data.id}- lastPurchasedDate`} className="text-center">
      {(normalPresentor(data.lastPurchasedDate) === 'Invalid date')
        ? '-' : normalPresentor(data.lastPurchasedDate)}
    </td>
  );
  table[labelMappings.p3mOfftake] = <td key={`${data.id}-p3mOfftake`} className="text-right">{data.p3mOfftake}</td>;
  table[labelMappings.p1mOfftake] = <td key={`${data.id}-p1mOfftake`} className="text-right">{data.p1mOfftake}</td>;
  table[labelMappings.stock] = <td key={`${data.id}-stock`} className="text-right">{data.stock}</td>;
  table[labelMappings.quantity] = <td key={`${data.id}-quantity`}>{`${parseInt(data.quantity / data.SKU.details.unitsPerCase)}cs ${parseInt(data.quantity % data.SKU.details.unitsPerCase)}pcs`}</td>;
  table[labelMappings.rate] = <td key={`${data.id}-rate`}>{fixedFloatAndCommas(data.rateDetails.rlp)}</td>;
  table[labelMappings.amount] = <td key={`${data.id}-amount`}>{fixedFloatAndCommas(data.amountDetails.netAmount)}</td>;
  table[labelMappings.actionHolders] = (
    <td
      key={`${data.id}- action`}
      className="text-right simple-popup-actions"
      onClick={e => e.stopPropagation()}
    >
      {menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        data,
      )}
    </td>
  );

  return table;
};

const TableBody = ({
  sn,
  data,
  viewType,
  onIconClick,
  checkedList,
  onSecondaryCheckBoxClick,
}) => {
  const tableBody = returnTableBody(sn, data, onIconClick, checkedList, onSecondaryCheckBoxClick);
  return (
    <tr
      key={data.id}
    >
      {viewBasedHeaderConfig[viewType].map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableHeaderConfig.propTypes = {
  viewType: PropTypes.string,
  dataListLength: PropTypes.number,
  primaryCheckboxHandler: PropTypes.func,
  checkedListLength: PropTypes.number,
};

TableHeaderConfig.defaultProps = {
  viewType: '',
  dataListLength: 0,
  primaryCheckboxHandler: () => null,
  checkedListLength: 0,
};

TableBody.propTypes = {
  sn: PropTypes.number,
  data: PropTypes.instanceOf(Object),
  viewType: PropTypes.string,
  onIconClick: PropTypes.func.isRequired,
  checkedList: PropTypes.instanceOf(Array),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};
TableBody.defaultProps = {
  sn: 0,
  data: {},
  viewType: '',
  checkedList: [],

};

export { TableHeaderConfig, TableBody };
