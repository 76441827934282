import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const CHANGE_PAYMENT_STATUS = graphql(
  gql`
    mutation (
      $ids: [Int]
      $status: PaymentStatus
      $ledgerInfo: [PaymentSplitInput]
      $tagId: Int
      $remarks: String
      $date: String
    ) {
      changePaymentStatus(
        ids: $ids
        status: $status
        ledgerInfo: $ledgerInfo
        tagId: $tagId
        remarks: $remarks
        date: $date
      ) {
        ids
        message
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      changePaymentStatus: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

export { CHANGE_PAYMENT_STATUS };
