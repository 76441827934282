import { FORM_CONFIG } from '../../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MOQ, mt } from '../../../../../data/enums/Route';
import { refGenerator } from '../../../../../utils';

const title = 'Purchase Requisition';

const breadCrumb = [
  mt,
  {
    id: 1,
    title: 'MOQ',
    path: `/${MOQ}`,
    active: true,
  },
];

const REQUISITION_VIEW = {
  BRAND: 'BRAND',
  // CATALOG: 'CATALOG',
};

const formMapper = (element = {}) => ({
  title: element.SKU ? element.SKU.title : '',
  unitsPerCase: element.SKU ? element.SKU.details.unitsPerCase : 0,
  lastPurchasedDate: element.lastPurchasedDate || '',
  p3mOfftake: element.p3mOfftake || '',
  p1mOfftake: element.p1mOfftake || '',
  quantity: element.quantity || '',
  rlp: element.rateDetails ? element.rateDetails.rlp : 0,
  stock: element.stock || 0,
  orderDetails: element.orderDetails.map(order => ({
    ...order,
    cases: parseInt(order.quantity / element.SKU.details.unitsPerCase),
    pieces: parseInt(order.quantity % element.SKU.details.unitsPerCase),
    upc: element.SKU ? element.SKU.details.unitsPerCase : 0,
  })) || [],
});

const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Edit Order',
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['pieces', 'cases']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
};

const newList = [
  { id: 1, label: 'BRAND' },
  // { id: 2, label: 'CATALOG' },
];

export {
  title, breadCrumb, REQUISITION_VIEW, newList, formConfig,
};
