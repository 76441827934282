import React from 'react';
import { PropTypes } from 'prop-types';
import { getDateList } from '../date';
import { MonthlyUnit } from '../Common';

const propTypes = {
  date: PropTypes.instanceOf(Object).isRequired,
  activeMonth: PropTypes.number.isRequired,
  events: PropTypes.instanceOf(Object).isRequired,
  weekEndList: PropTypes.instanceOf(Array).isRequired,
  dseSelected: PropTypes.bool.isRequired,
};

const defaultProps = {};

const defaultEvent = {
  routeList: [],
  holiday: null,
  leave: null,
};

const WeeklyPresenter = ({ date, activeMonth, events, weekEndList, dseSelected }) => {
  const daysList = getDateList(date.start, date.end);

  return (
    <div className='calendar-days-block'>
      {daysList.map((date) => (
        <MonthlyUnit
          date={date}
          dseSelected={dseSelected}
          activeMonth={activeMonth}
          weekEndList={weekEndList}
          event={events[date] || defaultEvent}
          key={date}
        />
      ))}
    </div>
  );
};

WeeklyPresenter.propTypes = propTypes;

WeeklyPresenter.defaultProps = defaultProps;

export default WeeklyPresenter;
