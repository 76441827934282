
import { compose } from 'recompose';
import SBD from './SBD';
import {
  query, mutation, SBD_TEMPLATE_DELETE, SBD_TEMPLATE_BULK_DELETE,
} from './API';
import withRequestTracker from '../../../../../../../utils/composition/withRequestTracker';

const ComposedSBD = compose(
  SBD_TEMPLATE_DELETE,
  SBD_TEMPLATE_BULK_DELETE,
  withRequestTracker({
    query,
    mutation,
  }),
)(SBD);

export default ComposedSBD;
