import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FocusedSkuDetailView from './View';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../data/services';
import withAlert from '../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import FocusedSkuDialog from '../components/FocusedSkuDialog';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { JWT } from '../../../../environment';
import { getFromLocalStorage } from '../../../common/HelperFunctions';
import FocusedDetailsStyled from './FocusedDetailsStyled';

const propTypes = {
  getBasicDetail: PropTypes.func.isRequired,
  getSkuDetails: PropTypes.func.isRequired,
  getBrandDetails: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  insertFocusedSkuDetails: PropTypes.func.isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Details extends Component {
  constructor(props) {
    super(props);
    const routeSegmentation =
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS) &&
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS).routeSegmentation;
    this.state = {
      menu: {
        channelList: [],
        categoryList: [],
        lineList: [],
      },
      channelId: 0,
      categoryList: [],
      categoryId: 0,
      brandList: [],
      brandId: 0,
      skuList: [],
      skuId: 0,
      focusedSkuId: parseInt(props.match.params.id, 10),
      showBrands: false,
      showSkuList: false,
      skus: [],
      dialog: {
        type: '',
        element: '',
      },
      basicDetail: {},
      checkedSkus: [],
      focusedSKUDetails: {
        Channel: [],
        Category: [],
        Brand: [],
        skus: [],
        skuFamily: [],
      },
      enableRouteSegmentation: routeSegmentation,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.loadDataForDropDown();
    this.getBasicDetails();
    this.getBrandDetails();
    this.getSKUsCount();
    const { enableRouteSegmentation } = this.state;
    if (enableRouteSegmentation) {
      this.loadLineList();
    }
  }

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CHANNEL).then(response => {
      menu.channelList = response;
      this.setState({ menu });
    });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY).then(response => {
      menu.categoryList = response;
      this.setState({ menu });
    });
  };

  getBasicDetails = () => {
    const { getBasicDetail, match } = this.props;
    const { id } = match.params;

    getBasicDetail(
      {
        value: id,
      },
      {
        handleSuccess: response => {
          const { focusedSKUs } = response.data;
          this.setState({
            basicDetail: focusedSKUs.rows[0],
          });
          this.getBrandDetails();
        },
      },
    );
  };

  loadLineList = () => {
    const { getLineDetails } = this.props;
    getLineDetails(
      { offset: 0, filter: { filters: [], queryString: '' } },
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.lineList = res.data.lines.rows || [];
          this.setState({ menu });
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  getSKUsCount = data => {
    const { getFocusSkuCount, match } = this.props;
    const { id } = match.params;

    getFocusSkuCount(
      {
        focusedSkuId: parseInt(id, 10),
        ...data,
      },
      {
        handleSuccess: response => {
          const { getFocusedSkuDetails } = response.data;
          this.setState({
            focusedSKUDetails: getFocusedSkuDetails,
          });
        },
      },
    );
  };

  getBrandDetails = (id = 3) => {
    const { getBrandDetails, displayAlert } = this.props;
    const { basicDetail } = this.state;

    getBrandDetails(
      {
        catalogId: id,
        lineId: basicDetail.lineId,
      },
      {
        handleSuccess: response => {
          this.setState({
            brandList: response.data.catalogDetails.rows.filter(list => list.SKUs.length > 0),
          });
          displayAlert(ALERT_TYPE.SUCCESS, 'Fetched');
        },
      },
    );
  };

  onBrandSelect = id => {
    const { getSkuDetails } = this.props;
    const { basicDetail, channelId, categoryId } = this.state;
    this.setState({
      brandId: id,
      skuList: [],
    });

    this.getSKUsCount({
      channelId,
      categoryId,
      brandId: id,
    });

    getSkuDetails(
      {
        value: id,
        lineId: basicDetail.lineId,
      },
      {
        handleSuccess: response => {
          const { catalogLevelDetails } = response.data;
          const catalogSkus = catalogLevelDetails.rows[0].SkuFamilies;
          this.setState({
            skuList: catalogSkus,
            showSkuList: true,
            skus: [],
            checkedSkus: [],
          });
        },
      },
    );
  };

  onChannelSelect = id => {
    const { menu } = this.state;
    this.setState({
      categoryList: menu.categoryList.filter(d => d.Channel.id === id),
      channelId: id,
      skus: [],
      checkedSkus: [],
      categoryId: null,
      showBrands: false,
      skuList: [],
      focusedSKUDetails: {
        Channel: [],
        Category: [],
        Brand: [],
        skus: [],
      },
      brandId: 0,
    });
    this.getSKUsCount({
      channelId: id,
    });
  };

  onCategorySelect = categoryId => {
    const { channelId, brandId } = this.state;
    this.setState({
      showBrands: true,
      categoryId,
    });
    this.getSKUsCount({
      channelId,
      categoryId,
      brandId,
    });
  };

  handleSubmitSKU = (id, toggleStatus) => {
    const { insertFocusedSkuDetails, displayAlert } = this.props;
    const { focusedSkuId, channelId, categoryId, brandId, skus } = this.state;
    const submittingData = {
      focusedSkuId,
      channelId,
      categoryId,
      brandId,
      skuFamilies: [id],
      status: toggleStatus,
    };
    if (toggleStatus) {
      skus.push(id);
      insertFocusedSkuDetails(
        {
          input: submittingData,
        },
        {
          handleSuccess: () => {
            this.getSKUsCount({
              ...submittingData,
            });
            displayAlert(ALERT_TYPE.INFO, 'Update Successfully');
          },
          handleError: err => {
            this.handleApiFailure(err);
          },
        },
      );
    } else {
      insertFocusedSkuDetails(
        {
          input: submittingData,
        },
        {
          handleSuccess: () => {
            this.setState({
              skus: [],
            });
            this.getSKUsCount({
              ...submittingData,
            });
            displayAlert(ALERT_TYPE.INFO, 'Disabled Successfully');
          },
        },
      );
    }
  };

  handleIconClick = action => {
    const { basicDetail } = this.state;
    this.setState({
      dialog: {
        type: action,
        element: basicDetail,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialog: {
        type: '',
        element: {},
      },
    });
  };

  handleDialogSubmit = type => {
    const { history } = this.props;

    if (type === EVENT_OPERATION.REPLICATE || type === EVENT_OPERATION.DELETE) {
      history.push('/configuration/focusedSku');
    }
    this.getBasicDetails();
  };

  handleApiFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.handleDialogClose();
  };

  setCheckedSkus = sku => {
    const { checkedSkus } = this.state;
    if (!checkedSkus.includes(sku)) {
      this.setState({
        checkedSkus: [...checkedSkus, sku],
      });
    } else {
      this.setState({
        checkedSkus: checkedSkus.filter(d => d !== sku),
      });
    }
  };

  render() {
    const { dialog } = this.state;
    const { serverResponseWaiting, getSKUList } = this.props;
    return (
      <FocusedDetailsStyled>
        <FocusedSkuDetailView
          {...this.state}
          onIconClick={this.handleIconClick}
          onChannelSelect={this.onChannelSelect}
          loading={serverResponseWaiting}
          onCategorySelect={this.onCategorySelect}
          getSkuDetails={this.onBrandSelect}
          handleSubmit={this.handleSubmitSKU}
          setCheckedSkus={this.setCheckedSkus}
          getSKUList={getSKUList}
        />
        {dialog.type && (
          <div className="sbd-modal">
            <FocusedSkuDialog
              type={dialog.type}
              element={dialog.element}
              onSubmit={this.handleDialogSubmit}
              onClose={this.handleDialogClose}
              onApiFailure={this.handleApiFailure}
            />
          </div>
        )}
      </FocusedDetailsStyled>
    );
  }
}

Details.propTypes = propTypes;

Details.defaultProps = defaultProps;

export default withAlert()(Details);
