import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  apiFilterProcessor,
  getOffsetFromPagination,
} from '../../../../../utils/api';
import View from './View';
import { breadCrumbConfig, title } from './config';
import PageHeader from '../../../../base/PageHeader';
import BUStyled from './BUStyled';
import * as queryService from '../../../../base/query.service';
import { PanelStyled } from '../../../../common/configuration';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { MT_BU_DETAILS } from '../../../../../data/enums/Route';
import history from '../../../../../utils/history';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getBuList: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class BU extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
      },
    };

    const { displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      displayAlert,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  setQueryParameters = (queryParams, callBack = () => null) => {
    this.setState({ queryParameters: queryParams }, callBack);
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getBuList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getBuList(
      {
        includeSku: false,
        catalogId: 1,
        offset,
        limit: queryParameters.pagination.limit,
      },
      {
        handleSuccess: (response) => {
          data.list = (response.data.catalogDetails
              && response.data.catalogDetails.rows)
            || [];
          data.total = (response.data.catalogDetails
              && response.data.catalogDetails.count)
            || 0;
          this.setState(data);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };


  handleRowClick=(id, butitle) => {
    history.push(`/${MT_BU_DETAILS}/${butitle}/${id}`);
  }

  render() {
    const { data, queryParameters } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <BUStyled>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              config={{
                title,
              }}
              breadCrumb={breadCrumbConfig}
              queryParameters={queryParameters}
            />
          </PanelStyled>
        </div>
        <div className="section-content table-present">
          <View
            data={data}
            loading={serverResponseWaiting}
            pagination={queryParameters.pagination}
            onRowClick={this.handleRowClick}
            onPageChange={this.basePaginationService.onPageSelect}
          />
        </div>
      </BUStyled>
    );
  }
}

BU.propTypes = propTypes;

BU.defaultProps = defaultProps;

export default withAlert()(BU);
