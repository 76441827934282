import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Pagination from '../../../../../components/Pagination';
import { TableHeader, TableBody } from './tableConfig';
import { dataProps, paginationProps } from '../../../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onRowClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  onPageChange: () => null,
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const BUView = ({ ...props }) => {
  const {
    data,
    onPageChange,
    pagination,
    onRowClick,
    permission,
  } = props;

  return (
    <Fragment>
      <div className="table-wrap">
        <table>
          {TableHeader({
            dataListLength: data.list.length,
            permission,
          })}

          <tbody>
            {data.list.map(bu => TableBody({
              data: bu,
              onRowClick,
              permission,
            }))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

BUView.propTypes = propTypes;

BUView.defaultProps = defaultProps;

const BUViewWithErrorAndLoading = withErrorAndLoadingScreen(BUView);

export default BUViewWithErrorAndLoading;
