import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getOffsetFromPagination } from '../../../../utils/api';
import withAlert from '../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../v4/constants/AlertType';
import * as queryService from '../../../base/query.service';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../base/PageHeader';
import {
  breadCrumbConfig, title, formConfig
} from './config';
import View from './View';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import DesignationForm from './Form';
import { DialogFormWrapper } from '../../../common';
import { MESSAGE_EVENT_OPERATION } from '../../../../v4/constants/successMessage';
import { isConfirmationType } from '../../../common/HelperFunctions';
import DialogConfirmation from '../../../../v4/components/DialogConfirmation';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getDistributors: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const initialData = {
  list: [],
  total: 0,
}
const Designation = (props) => {
  const [data, setData] = useState(initialData);
  const [dialog, setDialog] = useState({
    type: '',
    element: {},
  })
  const [dialogData, setDialogData] = useState({
    title:'',
  });
  const [queryParameters, setQueryParameters] = useState({
    pagination: queryService.baseQueryParameters.pagination,
    search:'',
  });

  const { displayAlert, serverResponseWaiting, getDesignationList, create, update, toggleState } = props;

  const handleIconClick = (type, element) => {
    setDialog({
      type,
      ...element,
    });
    if(type!==EVENT_OPERATION.CREATE){
      setDialogData(
        {
          id: element.id,
          title: element.title,
        }
      )
    }
  }

  const handleBulkDelete = () => {
    loadTableData()
  };

  const handleInputChange = (e) => {
    setDialogData({ ...dialogData, [e.target.name]: e.target.value });
  };

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
    setDialogData({ title:'' });
  };

  const createDesignation = () => {
    if(dialogData.title.trim()!==''){
      create({
        input:{
          title: dialogData.title.trim()
        }
      },{
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`);
          loadTableData();
          resetDialog();
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      })
    }else{
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, `${title} title is required`);
    }
  };

  const updateDesignation = () => {
    if(dialogData.title.trim()!==''){
      update({
        id: dialogData.id,
        input:{
          title: dialogData.title
        }
      },{
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
          loadTableData();
          resetDialog();
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      })
    }else{
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, `${title} title is required`);
    }
  };

  const deleteDesignation = () => {
    toggleState(
      {
        ids: [dialogData.id],
        type: 'Designation',
        active: false,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, `${title}${MESSAGE_EVENT_OPERATION.DELETE}`);
          loadTableData();
          resetDialog();
        },
        handleError: (err) => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  }

  const handleDialogSubmit=(type)=>{
    switch (type) {
    case EVENT_OPERATION.CREATE:
      createDesignation()
      break;
    case EVENT_OPERATION.UPDATE:
      updateDesignation()
      break;
    case EVENT_OPERATION.DELETE:
      deleteDesignation();
      break;
    default:
      break;
    }
  }

  const onAPIRequestFailure = (error) => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const loadTableData = () => {
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getDesignationList({
      offset,
      limit: queryParameters.pagination.limit,
      filter:{
        queryString: queryParameters.search,
      }
    }, {
      handleSuccess: (res) => {
        const response = res?.data?.getDesignations;

        setData({
          list: response ? response.rows : [],
          total: response ? response.total : 0,
        });
      },
      handleError: (err) => {
        onAPIRequestFailure(err);
      },
    });
  };

  const setParameters = (queryParams) => setQueryParameters({ ...queryParams });

  const basePaginationService = new queryService.QueryClass(
    setParameters,
    () => (queryParameters),
    loadTableData,
  );


  useEffect(() => {
    loadTableData();
  }, [queryParameters]);

  return (
    <>
      {dialog.type && (
        <DialogFormWrapper
          type={dialog.type}
          dialogElement={dialog.element}
          disableDialogClose
          formConfig={formConfig[dialog.type]}
          onDialogCancel={resetDialog}
          onDialogSubmit={handleDialogSubmit}
          renderDialog={({ refsObj,enableErrorDisplay }) => (
            <>
              {(dialog.type===EVENT_OPERATION.CREATE||dialog.type===EVENT_OPERATION.UPDATE)&&(
                <DesignationForm
                  data={dialogData}
                  refsObj={refsObj}
                  enableErrorDisplay={enableErrorDisplay}
                  handleInputChange={handleInputChange}
                />
              )}
              {isConfirmationType(dialog.type) && DialogConfirmation(dialog.type)}
            </>
          )}
        />
      )}
      <div className='section-header'>
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title,
              create: true,
              search: true,
            }}
            queryParameters={queryParameters}
            handleCreateClick={()=>handleIconClick(EVENT_OPERATION.CREATE,{})}
            handleSearchChange={basePaginationService.handleSearchInputChange}
            clearSearchText={basePaginationService.clearSearchText}
          />
        </PanelStyled>
      </div>
      <div className='section-content table-present'>
        <View
          data={data}
          loading={serverResponseWaiting}
          handleBulkDelete={handleBulkDelete}
          pagination={queryParameters.pagination}
          onPageChange={basePaginationService.onPageSelect}
          onIconClick={handleIconClick}
        />
      </div>
    </>
  )
}

Designation.defaultProps = defaultProps;


export default withAlert()(Designation);
