import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../../../../../common/GqlAPI';

const GET_SBD_LIST = gql`
query getRscoreSBDTemplateList($offset: Int, $limit: Int, $filter: FilterInput,$buId:Int!) {
  getRscoreSBDTemplateList(offset: $offset, limit: $limit, filter: $filter,buId:$buId) {
    rows{
      id
      title
      active
      end_date
      tags
      numberofSkus
      start_date
      canbeDeleted
    }
    count
  }
}
`;

const SBD_TEMPLATE_DELETE = graphql(gql`
    mutation deleteSBDTemplate($id: Int!,$type:String,$active:Boolean){
      deleteSBDTemplate(id: $id,type:$type,active:$active){
        ids
      }
    }`, {
  props: ({ mutate }) => ({
    deleteSBDTemplate: variables => mutate({
      variables,
    }),
  }),
});
const SBD_TEMPLATE_BULK_DELETE = graphql(gql`
    mutation bulkDeleteSBDTemplate($id: [Int]!,$type:String,$active:Boolean){
      bulkDeleteSBDTemplate(id: $id,type:$type,active:$active){
        ids
      }
    }`, {
  props: ({ mutate }) => ({
    bulkDeleteSBDTemplate: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getRscoreSBDTemplateList: GET_SBD_LIST,
};

const mutation = {
  deleteSBDTemplate: SBD_TEMPLATE_DELETE,
  bulkDeleteSBDTemplate: SBD_TEMPLATE_BULK_DELETE,
};

export {
  GET_SBD_LIST, TOGGLE_STATE, SBD_TEMPLATE_DELETE, SBD_TEMPLATE_BULK_DELETE,
};

export { query, mutation };
