import { FORM_CONFIG } from '../../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { mt as mtRoute, VISIBILITY_AUDIT } from '../../../../../data/enums/Route';
import { refGenerator } from '../../../../../utils';

const visibilityAuditDetail = {
  id: 1,
  title: 'Visibility Audit Detail',
  path: `/${VISIBILITY_AUDIT}`,
  active: true,
}

const breadCrumb = [mtRoute, visibilityAuditDetail];

const Title = 'Visibility Audit Detail';
const requiredList = ['title', 'frequency', 'assignedTo'];

const formMapper = element => ({
  id: element.id || '',
  title: element.title || '',
  frequency: element.count_days || '',
  assignedTo: element.assigned_to || '',
})

const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Edit Visibility Audit',
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'Delete Visibility Audit',
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
};

export {
  breadCrumb as breadCrumbConfig,
  formConfig,
  Title as title,

};
