import React, { useState } from 'react';
import Composedchart from '../../../../../components/Chart/ComposedChart';
import RscoreReportDropDown from '../../../components/RscoreReportDropDown';
import { dimension, getLogo, isEmptyObject, weekCustomize } from '../../../config';
import { TitleWrapStyled } from './TitleWrapStyled';
import moment from 'moment';
import { currentWeekNo, currentYear } from '../../../../../utils/date';
import { useSelector } from 'react-redux';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { ArrayOfObjSum, filterItems } from '../../../../../utils/arrayProcessor';
import { ResponsiveContainer } from 'recharts';

const currentWeek = weekCustomize(currentWeekNo);

const TitleWrap = ({
  audit_week,
  selected_week,
  weekList,
  getWeek,
  brand,
  date,
  handleDateRangeChange,
  setDate,
  setIsCustomWeekSelected,
  isCustomWeekSelected,
}) => {
  const [selectedWeek, setSelectedWeek] = useState({
    week: selected_week?.week,
    week_no: selected_week?.week_no,
  });

  const [auditWeek, setAuditWeek] = useState({
    firstDate: audit_week?.firstDate,
    lastDate: audit_week?.lastDate,
  });

  const calculateDateFromWeekNumAndYear = (year, week) => {
    const startDate = moment().day('Sunday').year(year).week(week).format('DD MMM YYYY');
    const endDate = moment(startDate).add(6, 'days').format('DD MMM YYYY');
    setAuditWeek({ firstDate: startDate, lastDate: endDate });
    getWeek({ startDate, endDate });
  };

  const onSelectChange = e => {
    setIsCustomWeekSelected(false);
    setDate({});
    setSelectedWeek({ week: e.week, week_no: e?.week_no });
    calculateDateFromWeekNumAndYear(currentYear, e?.week_no);
  };

  const P3WRscore = useSelector(state => state?.RscoreDatas?.P3WRscore);
  const WEEKLY_RSCORE = useSelector(state => state?.RscoreDatas?.rScore);
  const WEEKLY_WISE_RSCORE_BU = filterItems(WEEKLY_RSCORE, brand, 'bu');

  const BRAND_WISE_P3W = filterItems(P3WRscore, brand, 'bu');

  const P3MRSCORE_TO_CHART = _(BRAND_WISE_P3W)
    .groupBy('audit_period')
    .map((item, week) => ({
      week: week.replace(currentYear, ''),
      pointScored: fixedFloatAndCommas(_.sumBy(item, 'point_scored'), 1),
      points_allocated: fixedFloatAndCommas(_.sumBy(item, 'points_allocated'), 1),
      percent: fixedFloatAndCommas((_.sumBy(item, 'point_scored') / _.sumBy(item, 'points_allocated')) * 100, 1),
    }))
    .value();

  const POINT_SCORED_SUM = ArrayOfObjSum(WEEKLY_WISE_RSCORE_BU, 'point_scored');
  const POINT_ALLOCATED_SUM = ArrayOfObjSum(WEEKLY_WISE_RSCORE_BU, 'points_allocated');
  const totalPercent = (POINT_SCORED_SUM / POINT_ALLOCATED_SUM) * 100;

  return (
    <>
      <TitleWrapStyled>
        <div className="brandImg-week">
          <div className="brand-img">
            <img src={getLogo(brand?.split(' ')[0].toLowerCase())} alt={brand} />
          </div>
          <div className="title__dropdown">
            <RscoreReportDropDown
              currentWeek={currentWeek}
              audit_week={isEmptyObject(date) ? auditWeek : date}
              weekList={weekList}
              selectedWeek={selectedWeek}
              onSelectChange={onSelectChange}
              handleDateRangeChange={handleDateRangeChange}
              date={date}
              isCustomWeekSelected={isCustomWeekSelected}
            />
          </div>
        </div>
        <div className="title__number">
          <div className="header-number">
            {totalPercent > 0 ? (
              fixedFloatAndCommas(totalPercent, 1)
            ) : (
              <div style={{ fontSize: '15px' }}> {WEEKLY_WISE_RSCORE_BU.length > 0 ?? <>Loading... </>}</div>
            )}
            {totalPercent > 0 ? '%' : ''}
          </div>

          <div className="title__composedChart">
            {P3MRSCORE_TO_CHART.length > 0 && (
              <ResponsiveContainer width="100%" aspect={3}>
                <Composedchart
                  data={_.sortBy(P3MRSCORE_TO_CHART, 'week')}
                  dimensions={dimension}
                  isXAxis={true}
                  isYAxis={true}
                  legend={false}
                  cartesianGrid={false}
                  tooltip={false}
                />
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </TitleWrapStyled>
    </>
  );
};

export default TitleWrap;
