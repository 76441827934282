import gql from 'graphql-tag';

const GET_ALL_RSCORE = gql`
  query GetRscoreData($filter: FilterInput, $buIds: [Int]) {
    getRscoreData(filter: $filter, buIds: $buIds) {
      bu
      bu_id
      fundamental_id
      fundamental
      fundamental_type
      outlet_channel_id
      outlet_channel
      outlet_id
      outlet
      audit_period
      category
      points_allocated
      point_scored
      bonus_point
      gap_point
      compliance
      category_id
      month
      images
      audited_date
    }
  }
`;

const query = {
  // getBusinessRscore: GET_BUSINESS_RSCORE,
  getAllRscore: GET_ALL_RSCORE,
};

export { query, GET_ALL_RSCORE };
