import React, { useState, useEffect } from 'react';
import { DialogFormWrapper } from '../../../common';
import withAlert from '../../../../utils/composition/withAlert';
import PageHeader from '../../../base/PageHeader';
import { PanelStyled } from '../../../common/configuration';
import { formConfig, breadCrumbConfig, title } from './config';
import * as queryService from '../../../base/query.service';
import View from './View';
import { getOffsetFromPagination } from '../../../../utils/api';
import { isConfirmationType, isError } from '../../../common/HelperFunctions';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { VISIBILITY_AUDIT } from '../../../../data/enums/Route';
import history from '../../../../utils/history';
import { getPermissionForUserAccount } from '../../../base/permission';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import Form from './Form';
import confirmationGenerator from '../../../common/DialogConfirmation';

const initialDialogState = {
  title: '',
  frequency: '',
  assignedTo: '',
};

const VisibilityAudit = (props) => {
  const {
    getVisibilityAudit,
    serverResponseWaiting,
    getRouteVisitFrequencyList,
    getUsersList,
    displayAlert,
    createVisibilityAudit,
    getVisibilityAuditbasicbyId,
    deletebulkVisibilityAudit,
    editVisibilityAudit,
    deleteVisibilityAudit,
  } = props;

  const [data, setData] = useState({ list: [], total: 0 });
  const [queryParameters, setQueryParameters] = useState({
    pagination: queryService.baseQueryParameters.pagination,
    search: queryService.baseQueryParameters.search,
    sort: queryService.baseQueryParameters.sort,
  });
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });
  const [dialogData, setDialogData] = useState({
    ...initialDialogState,
  });
  const [frequency, setFrequency] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);

  const setParameters = queryParams => setQueryParameters({ ...queryParams });

  const onAPIRequestFailure = (error) => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
    setDialogData(initialDialogState);
  };

  const loadTableData = () => {
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getVisibilityAudit(
      {
        offset,
        limit: 50,
        filter: {
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: (res) => {
          setData({
            list: res.data.getVisibilityAuditList
              ? res.data.getVisibilityAuditList.rows
              : [],
            total: res.data.getVisibilityAuditList
              ? res.data.getVisibilityAuditList.count
              : 0,
          });
          if (isError(res)) onAPIRequestFailure(res);
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      },
    );
  };

  const loadFrequenciesAndUsers = () => {
    getRouteVisitFrequencyList({}, {
      handleSuccess: (res) => {
        const { data: { routeVisitFrequencyList } } = res;
        const options = routeVisitFrequencyList
          .rows
          .map(row => ({ id: row.numberOfDays, label: row.label }));
        setFrequency([...options]);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });

    getUsersList({
      offset: 0,
      limit: 500,
      filter: {},
    }, {
      handleSuccess: (res) => {
        const { data: { users } } = res;
        setAssignedTo(users.rows);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  useEffect(() => {
    loadTableData();
  }, [queryParameters]);


  const handleBulkDelete = () => {
    loadTableData();
  };

  const basePaginationService = new queryService.QueryClass(
    setParameters,
    () => (queryParameters),
    loadTableData,
  );

  const onTableRowClick = (id) => {
    history.push(`/${VISIBILITY_AUDIT}/${id}`);
  };

  const handleError = (err) => {
    displayAlert(ALERT_TYPE.DANGER, err);
  };

  const handleDialogSubmit = (type, data) => {
    switch (type) {
      case EVENT_OPERATION.UPDATE:
        editVisibilityAudit({
          id: data.id,
          input: {
            title: dialogData.title,
            assigned_to: dialogData.assignedTo,
            count_days: dialogData.frequency,
            active: true,
          },
        }, {
          handleSuccess: () => {
            loadTableData();
            resetDialog();
          },
          handleError: (error) => {
            handleError(error);
          },
        });
        break;

      case EVENT_OPERATION.DELETE:
        deleteVisibilityAudit({ id: data.id },
          {
            handleSuccess: () => {
              loadTableData();
              resetDialog();
            },
            handleError: (error) => {
              handleError(error);
            },
          });
        break;

      default:
        createVisibilityAudit({
          input: {
            title: dialogData.title,
            assigned_to: dialogData.assignedTo,
            count_days: dialogData.frequency,
            active: true,
          },
        }, {
          handleSuccess: () => {
            loadTableData();
            resetDialog();
          },
          handleError: (error) => {
            handleError(error);
          },
        });
        break;
    }
  };

  const handleCreate = (type, element = {}) => {
    setDialog({
      type,
      element,
    });

    if (type === EVENT_OPERATION.UPDATE) {
      getVisibilityAuditbasicbyId({ mtvisibilityAuditId: element.id },
        {
          handleSuccess: (res) => {
            const { data: { getVisibilityAuditbasicbyId: detail } } = res;
            setDialogData({
              title: detail.title,
              frequency: Number(detail.count_days),
              assignedTo: Number(detail.assigned_to),
            });
          },
          handleError: (error) => {
            handleError(error);
          },
        });
    }
    loadFrequenciesAndUsers();
  };

  const clearSearchText = () => {
    setQueryParameters({ ...queryParameters, search: '' });
  };

  const handleSearch = (value) => {
    setQueryParameters({ ...queryParameters, search: value });
  };

  const handleInputChange = (e) => {
    setDialogData({ ...dialogData, [e.target.name]: e.target.value });
  };

  const handleDropDownChange = (value, label) => {
    const dialogDataClone = { ...dialogData };
    dialogDataClone[label] = value;
    setDialogData({ ...dialogDataClone });
  };

  return (
    <>
      <div className="section-header">
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title,
              create: true,
              search: true,
            }}
            queryParameters={queryParameters}
            handleCreateClick={() => handleCreate(EVENT_OPERATION.CREATE, {})}
            clearSearchText={clearSearchText}
            handleSearchChange={handleSearch}
          />
        </PanelStyled>
      </div>
      <div className="section-content table-present">
        {dialog.type && (
          <DialogFormWrapper
            formConfig={formConfig[dialog.type]}
            dialogElement={dialog.element}
            onDialogSubmit={handleDialogSubmit}
            onDialogCancel={resetDialog}
            enableReset={false}
            type={dialog.type}
            renderDialog={({ refsObj, enableErrorDisplay }) => (
              <>
                {(dialog.type === EVENT_OPERATION.UPDATE
                  || dialog.type === EVENT_OPERATION.CREATE) && (
                    <Form
                      data={dialogData}
                      refsObj={refsObj}
                      loading={serverResponseWaiting}
                      handleInputChange={event => handleInputChange(event)}
                      enableErrorDisplay={enableErrorDisplay}
                      handleDropDownChange={(value, label) => handleDropDownChange(value, label)
                      }
                      frequency={frequency}
                      assignedTo={assignedTo}
                    />)}
                {isConfirmationType(dialog.type)
                  && confirmationGenerator(dialog.type)
                }
              </>
            )}
          />
        )}
        <View
          data={data}
          permission={getPermissionForUserAccount()}
          pagination={queryParameters.pagination}
          onIconClick={handleCreate}
          loading={serverResponseWaiting}
          onPageChange={basePaginationService.onPageSelect}
          onBulkDelete={handleBulkDelete}
          onTableRowClick={onTableRowClick}
          deletebulkVisibilityAudit={deletebulkVisibilityAudit}
        />
      </div>
    </>
  );
};

export default withAlert()(VisibilityAudit);
