import gql from 'graphql-tag';
import { DOWNLOAD_PURCHASE, PRINT_PURCHASE } from '../../API';

const GET_REQUISITION_DETAIL = gql`
query getPurchaseRequisitionOrderDetail ($id: Int!) {
  getPurchaseRequisitionOrderDetail(id: $id) {
    id
    requisitionDate
    channelId
    channel
    orderDetails {
      skuId
      sku
      brandId
      brand
      buId
      bu
      upc
      quantity
      lastPurchasedDate
      p3mOfftake
      p1mOfftake
      stock
      rateDetails {
        rlp
      }
      amountDetails {
        grossAmount
        netAmount
        taxableAmount
        taxAmount
      }
    }
  }
}
`;
const query = {
  getRequisitionDetail: GET_REQUISITION_DETAIL,
};

const mutation = {
  downloadReport: DOWNLOAD_PURCHASE,
  printPurchaseRequisition: PRINT_PURCHASE,
};

export {
  GET_REQUISITION_DETAIL,
};

export {
  query, mutation, DOWNLOAD_PURCHASE, PRINT_PURCHASE,
};
