import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ConfigPanelStyled from './ConfigPanelStyled';

const propTypes = {
  /**
   * @default null
   * children passed within button
   */
  children: PropTypes.node,
  /**
   * @default ''
   * children passed along with the button
   */
  className: PropTypes.string,
  /**
   * @default false
   * if card is active or not.
   */
  active: PropTypes.bool,
};

const defaultProps = {
  children: null,
  className: '',
  active: false,
  skuClassStatus: false,
};

const PanelCard = ({ children, className, cardTitle, pad48, active, skuClassStatus }) => {
  const cardclasses = classNames(' config-panel-card', { sku: skuClassStatus }, className);
  return (
    <ConfigPanelStyled>
      <div className={cardclasses}>
        <h3 className='config-panel-title'>{cardTitle.toUpperCase()}</h3>
        <div className='config-panel-contents'>{children}</div>
      </div>
    </ConfigPanelStyled>
  );
};

PanelCard.propTypes = propTypes;

PanelCard.defaultProps = defaultProps;

export default PanelCard;
