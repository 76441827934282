import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as queryService from '../../base/query.service';
import withAlert from '../../../utils/composition/withAlert';
import { DialogFormWrapper } from '../../common';
import View from './View';
import DistributorSalesStyled from './DistributorSalesStyled';
import Form from './Form';
import {
  title, formConfig, breadCrumbConfig,filterConfig,
} from './config';
import { PanelStyled } from '../../common/configuration';
import PageHeader from '../../base/PageHeader';
import { EVENT_OPERATION } from '../../../v4/constants/EventOperation';
import { DISTRIBUTOR_SALES_TARGET_DETAILS } from '../../../data/enums/Route';
import history from '../../../utils/history';
import { ALERT_TYPE } from '../../../v4/constants/AlertType';
import { getOffsetFromPagination,apiFilterProcessor } from '../../../utils/api';
import { MESSAGE_EVENT_OPERATION } from '../../../v4/constants/successMessage';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { clone } from '../../../utils/objectProcessor';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getDistributors: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const initialDialogState = {
  id:'',
  distributorId:'',
  duration: {
    year: 2022,
    month: 1,
  },
  target:'',
}
const DistributorSalesTarget = (props) => {
  const filterConfigMenu = clone(filterConfig.menu);
  const { displayAlert, serverResponseWaiting, getDistributors, getDistributorTarget, createDistributorIcoTarget, editDistributorIcoTarget } = props;

  const [data, setData] = useState({ isDistributorTargetDerived: false, list: [], total: 0 });
  const [distributorList, setDistributorList] =useState([]);
  const [queryParameters, setQueryParameters] = useState({
    pagination: queryService.baseQueryParameters.pagination,
    filter: queryService.baseQueryParameters.filter,
    date: { ...queryService.baseQueryParameters.date },
  });
  const [filterMenu,setFilterMenu] =useState(filterConfigMenu);
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });
  const [dialogData, setDialogData] = useState({
    ...initialDialogState,
  });

  const loadTableData = () => {
    const offset = getOffsetFromPagination(queryParameters.pagination);
    setFilterStateInStorage();
    getDistributorTarget({
      offset,
      limit: queryParameters.pagination.limit,
      filter: {
        filters: apiFilterProcessor(queryParameters.filter),
        dateRange: queryParameters.date,
        queryString: queryParameters.search,
      },
    }, {
      handleSuccess: (res) => {
        const response = res.data.getDistributorTarget;
        setData({
          isDistributorTargetDerived: response?.isDistributorTargetDerived,
          list: response ? response.rows : [],
          total: response ? response.total : 0,
        });
      },
      handleError: (err) => {
        onAPIRequestFailure(err);
      },
    });
  };

  const setParameters = (queryParams) => setQueryParameters({ ...queryParams });

  const basePaginationService = new queryService.QueryClass(
    setParameters,
    () => (queryParameters),
    loadTableData,
  );

  const onAPIRequestFailure = (error) => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const getDistributorsList = () => {
    getDistributors(
      {},
      {
        handleSuccess: (response) => {
          setDistributorList( response.data.distributors.rows );
        },
        handleError: (error) => {
          onAPIRequestFailure(error);
        },
      },
    );
  };

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
    setDialogData(initialDialogState);
  };

  const handleIconClick = (type, element = {}) => {
    getDistributorsList();
    setDialog({
      type,
      element,
    });
    if(type!==EVENT_OPERATION.CREATE){
      setDialogData(
        {
          id: element.id,
          distributorId: element.distributorId,
          duration: {
            year: element.year,
            month: element.month,
          },
          target: element.target,
        }
      )
    }
  }

  const handleInputChange = (e) => {
    setDialogData({ ...dialogData, [e.target.name]: e.target.value });
  };

  const handleDropDownChange = (value, label) => {
    const dialogDataClone = { ...dialogData };
    dialogDataClone[label] = value;
    setDialogData({ ...dialogDataClone });
  };

  const handleDateChange = (date) => {
    setDialogData({ ...dialogData, duration: date });
  };

  const handleDialogSubmit = (type) => {
    if (type===EVENT_OPERATION.UPDATE) {
      editDistributorIcoTarget({
        id: dialogData.id,
        input:{
          target: Number(dialogData.target),
          distributor_id: dialogData.distributorId,
          year: dialogData.duration.year,
          month: dialogData.duration.month,
        }
      },{
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, `Target ${MESSAGE_EVENT_OPERATION.UPDATE}`);
          loadTableData();
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      })}else{
      createDistributorIcoTarget({
        input:{
          target: Number(dialogData.target),
          distributor_id: dialogData.distributorId,
          year: dialogData.duration.year,
          month: dialogData.duration.month,
        }
      },{
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, `Target ${MESSAGE_EVENT_OPERATION.CREATE}`);
          loadTableData();
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      })}
  }

  const handleTableRowClick = (rowData) => {
    history.push(`/${DISTRIBUTOR_SALES_TARGET_DETAILS}/${rowData.id}`,{ distributorId: rowData.distributorId , subDTitle: `${rowData.title} / ${rowData.date} / ${fixedFloatAndCommas(rowData.target)}` });
  };

  const  setFilterStateInStorage = () => {
    const { filter,date,search } =queryParameters;
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.DST
    );
  };
  const getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.DST);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };
    if (!localData) {
      return loadTableData();
    }
    setFilterMenu(newFilterConfig.filterMenu)
    setQueryParameters({
      ...queryParameters,
      filter: newFilterConfig.filter,
      searchText: newFilterConfig.searchText,
      date: newFilterConfig.date,
    })
    loadTableData();
  };

  useEffect(() => {
    getFilterStateFromStorage();
  }, []);
  useEffect(() => {
    setData({
      list: [],
    });
    loadTableData();
  }, [queryParameters]);
  return (
    <DistributorSalesStyled>
      {dialog.type && (
        <DialogFormWrapper
          formConfig={formConfig[dialog.type]}
          dialogElement={dialog.element}
          onDialogSubmit={handleDialogSubmit}
          onDialogCancel={resetDialog}
          type={dialog.type}
          renderDialog={({ refsObj, enableErrorDisplay }) => (
            <Form
              type={dialog.type}
              data={dialogData}
              distributorList={distributorList}
              refsObj={refsObj}
              loading={serverResponseWaiting}
              handleInputChange={handleInputChange}
              handleDateChange={handleDateChange}
              enableErrorDisplay={enableErrorDisplay}
              handleDropDownChange={(value, label) => handleDropDownChange(value, label)
              }
            />
          )}
        />
      )}
      <div className='section-header'>
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title,
              create: queryParameters.filter ?  false : !data?.isDistributorTargetDerived,
              filter: true,
              date: true,
            }}
            queryParameters={queryParameters}
            handleCreateClick={() => handleIconClick(EVENT_OPERATION.CREATE, {})}
            filter={{
              date: queryParameters.date,
              menuList: filterMenu,
              onFilterChange:basePaginationService.handleFilterChange,
            }}
            resetFilter={basePaginationService.resetFilter}
            handleDateRangeChange={basePaginationService.handleDateRangeChange}
          />
        </PanelStyled>
      </div>
      <div className='section-content table-present'>
        <View
          data={data}
          loading={serverResponseWaiting}
          handleIconClick={handleIconClick}
          onTableBodyClick={handleTableRowClick}
          pagination={queryParameters.pagination}
          onPageChange={basePaginationService.onPageSelect}
        />
      </div>
    </DistributorSalesStyled>
  )
}

DistributorSalesTarget.propTypes = propTypes;

DistributorSalesTarget.defaultProps = defaultProps;

export default withAlert()(DistributorSalesTarget);
