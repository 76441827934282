import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import DetailView from './View';
import withAlert from '../../../../../utils/composition/withAlert';
import DetailWraper from '../../received/detail/DetailedStyled';
import { DialogFormWrapper } from '../../../../common';
import { formConfig } from '../../received/detail/config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import PromotionForm from '../../received/detail/promotion/PromotionForm';
import withImageSlider from '../../../../../utils/composition/withImageSlider';
import BillDiscountView from '../../received/detail/billDiscount';

const propTypes = {
  loading: PropTypes.bool,
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  data: PropTypes.instanceOf(Array),
  handleSliderOpen: PropTypes.func,
  onRowClick: PropTypes.func.isRequired,
  onOrderStatusChangeClick: PropTypes.func.isRequired,
};

const defaultProps = {
  data: [],
  activeLine: {
    id: '',
    sellerId: 0,
  },
  loading: false,
  handleSliderOpen: () => null,
};

class Detail extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { data: nextProps.data };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
    };
  }

  componentDidMount() {}

  handleIconClick = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  render() {
    const { data, loading, activeLine, onRowClick, handleSliderOpen, onOrderStatusChangeClick } = this.props;
    const { dialog } = this.state;
    const { type } = dialog;

    return (
      <Fragment>
        <DetailWraper>
          <div className={type.toLowerCase()}>
            {type && (
              <DialogFormWrapper
                formConfig={formConfig[type]}
                dialogElement={dialog.element}
                onDialogSubmit={() => null}
                onDialogCancel={this.resetDialog}
                type={type}
                renderDialog={({ dialogData }) => (
                  <Fragment>
                    {type === EVENT_OPERATION.READ && <PromotionForm data={dialogData} loading={loading} />}
                    {type === EVENT_OPERATION.READ_SUB_TYPE && <BillDiscountView data={dialogData} loading={loading} />}
                  </Fragment>
                )}
              />
            )}
          </div>
        </DetailWraper>
        <DetailView
          data={data}
          loading={loading}
          activeLine={activeLine}
          onRowClick={onRowClick}
          onIconClick={this.handleIconClick}
          handleSliderOpen={handleSliderOpen}
          onOrderStatusChangeClick={onOrderStatusChangeClick}
        />
      </Fragment>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

const composedDetailWithAlert = withAlert()(Detail);

export default withImageSlider(composedDetailWithAlert);
