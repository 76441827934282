import Select from 'react-select';
import styled from 'styled-components';
import { DatePicker } from '../../../../v4/components';
import { isEmptyObject } from '../../config';
import { modifyDate } from './config';

const DropDown = props => {
  const {
    currentWeek,
    weekList,
    onSelectChange,
    selectedWeek,
    audit_week,
    date,
    handleDateRangeChange,
    isCustomWeekSelected,
  } = props;

  return (
    <>
      <DropDownStyled>
        <div className="datePicker-wrap">
          <div className="weekdropdown-container">
            <div className="week-dropdown-wrapper">
              <span>R-Score</span>
              <div className="filter-select-input">
                <Select
                  isClearable={false}
                  className="custom-select"
                  classNamePrefix="filter-select"
                  placeholder={!isCustomWeekSelected ? currentWeek : `Custom-Week`}
                  getOptionLabel={({ week }) => `${week}`}
                  getOptionValue={({ week_no }) => week_no}
                  value={
                    !isCustomWeekSelected ? weekList?.filter(label => label.week === selectedWeek?.week) : 'Custom-Week'
                  }
                  options={weekList}
                  onChange={e => onSelectChange(e)}
                />
              </div>
            </div>
            <div className="date-range">
              <span>
                {/* {audit_week?.firstDate} - {audit_week?.lastDate} */}
              </span>
            </div>
          </div>
          <div>
            <DatePicker
              date={isEmptyObject(date) ? modifyDate(audit_week) : modifyDate(date)}
              onChange={handleDateRangeChange}
            />
          </div>
        </div>
      </DropDownStyled>
    </>
  );
};

export default DropDown;

const DropDownStyled = styled.div`
  .filter-select__value-container {
    width: 100px;
    line-height: 12px;
    .filter-select__single-value {
      font-size: 13.5px;
      font-weight: 500;
      color: #272833;
      line-height: 16px;
    }
    .filter-select__placeholder {
      font-size: 13px;
      font-weight: 500;
      color: #272833;
    }
  }
  .filter-select__dropdown-indicator {
    svg {
      color: #272833 !important;
      padding-left: 0;
      margin-left: 0px
    }
  }

  .filter-select__indicator-separator {
    margin: 0;
    margin-right: 0px !important;
  }

  .filter-select__input {
    font-size: 20px;
  }
  .datePicker-wrap {
    display: flex;
    align-items: flex-start;
    .weekdropdown-container {
      .week-dropdown-wrapper {
        display: flex;
        align-items: center;
        span {
          font-size: 20px;
          font-weight: 400;
          color: #6b6c7e;
          margin-right: 12px;
          padding-bottom: 22px;
        }
      }

      .date-range {
        font-size: 14px;
        font-weight: 400;
        color: #6b6c7e;
        margin-top: -18px;
      }
    }
  }

  .datepicker-content {
    line-height: 24px;
    margin-left: 9px;
  }

  .filter-dropdown-card {
    left: 0;
  }
`;
