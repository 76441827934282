import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../v4/components';
import { PanelStyled } from '../../common/configuration';
import CustomSelect from '../../../components/CustomSelect';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {
  binId: PropTypes.number,
  distributorId: PropTypes.number,
  handleIconClick: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  handleSearchInput: PropTypes.func.isRequired,
  onWareHouseDetailsCardClick: PropTypes.func.isRequired,
  distributorList: PropTypes.instanceOf(Array).isRequired,
  wareHouseLevelList: PropTypes.instanceOf(Array).isRequired,
  wareHouseDetailList: PropTypes.instanceOf(Array).isRequired,
};

const defaultProps = {
  binId: null,
  distributorId: null,
};

const getTitleFromWareHouseLevels = (levelList, index) => {
  const object = levelList[index];
  return object && object.title;
};

const View = ({ ...props }) => {
  const {
    onWareHouseDetailsCardClick,
    handleSearchInput,
    handleIconClick,
    wareHouseLevelList,
    binId,
    wareHouseDetailList,
    distributorList,
    onDropDownChange,
    distributorId,
  } = props;
  return (
    <PanelStyled>
      {distributorList.length > 1 && (
        <CustomSelect
          small
          enableValidation
          options={distributorList}
          className='custom-select'
          placeholder='Select Distributor'
          getOptionValue={({ id }) => id}
          getOptionLabel={({ title }) => title}
          onChange={(event) => onDropDownChange(event.id)}
          value={distributorList.filter(({ id }) => id === distributorId) || []}
        />
      )}
      {distributorId && (
        <div className='card-wrapper'>
          {wareHouseDetailList.map(
            (wareHouseDetail, index) =>
              wareHouseDetail.levelId !== null && (
                <Card
                  selectedId={binId[wareHouseDetail.levelId]}
                  data={wareHouseDetail.list}
                  handleIconClick={handleIconClick}
                  handleSearchInput={handleSearchInput}
                  onItemClick={onWareHouseDetailsCardClick}
                  title={getTitleFromWareHouseLevels(wareHouseLevelList, index)}
                />
              ),
          )}
        </div>
      )}
    </PanelStyled>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const WarehouseViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default WarehouseViewWithErrorAndLoading;
