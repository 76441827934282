import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
  CATALOG_FRAGMENT, CATALOG_DETAIL_FRAGMENT, SKU_FRAGMENT, SKU_CATALOG_DETAIL_FRAGMENT
} from '../API';
import { GET_BU_DATA } from '../../bu/API';
import { IMAGE_FRAGMENT } from '../../../common/GqlFragments';

const GET_CATALOG_LIST = gql`
  query getCatalogsList {
    catalogs {
      rows {
        ...CatalogFragment
      }
    }
  }
  ${CATALOG_FRAGMENT}
`;

const GET_ROUTE_SEGMENTATION_LIST = gql`
  query getRouteSegmentation($offset: Int, $limit: Int, $filter: FilterInput) {
    lines(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
      }
      count
    }
  }
`;


const GET_SKU_DETAIL = gql`
  query getSkuDetail($id: Int!) {
    findSKU(id: $id) {
      ...SkuFragment
      powerSku
      free_sku
      description
      details {
        ean
        gtin
        skuCode
        skuProductCode
        unitsPerCase
        standardUnit
        packagingUnit
        minimumOrderQuantity
        erpCode
      }
      oneTimePurchase
      oneTimePurchaseQuantity
      catalogDetails {
        ...SkuCatalogDetailFragment
      }
      dimension {
        length
        width
        height
      }
      weight
      uom
      images {
        ...ImageFragment
      }
      powerSku
      skuTags
      caseUnit
      shelfLife
      shortDescription
      caseReportingUnit
      vendorSBF {
        id
      }
    }
  }
  ${IMAGE_FRAGMENT}
  ${SKU_FRAGMENT}
  ${SKU_CATALOG_DETAIL_FRAGMENT}
`;

const GET_CATALOG_DETAIL = gql`
  query getCatalogDetail(
    $limit: Int
    $offset: Int
    $catalogId: Int
    $parentCatalogDetailId: Int
    $includeSku: Boolean = false
  ) {
    catalogDetails(
      limit: $limit
      offset: $offset
      catalogId: $catalogId
      parentCatalogDetailId: $parentCatalogDetailId
    ) {
      rows {
        ...CatalogDetailFragment
        parentCatalogDetailId
        skus: SKUs @include(if: $includeSku) {
          id
          title
        }
      }
    }
  }
  ${CATALOG_DETAIL_FRAGMENT}
`;

const GET_RETAILER_CATALOG_DETAIL = gql`
  query getRetailCatalogDetail(
    $distributorGroupId: Int
    $limit: Int
    $offset: Int
    $catalogId: Int
    $parentCatalogDetailId: Int
    $includeSku: Boolean = false
  ) {
    retailerCatalogDetails(
      distributorGroupId: $distributorGroupId
      limit: $limit
      offset: $offset
      catalogId: $catalogId
      parentCatalogDetailId: $parentCatalogDetailId
    ) {
      rows {
        ...CatalogDetailFragment
        parentCatalogDetailId
        skus: SKUs @include(if: $includeSku) {
          id
          title
        }
      }
    }
  }
  ${CATALOG_DETAIL_FRAGMENT}
`;

const GET_VENDOR_SBF_LIST = gql`
  query getVendorSBFs {
    vendorSBFs {
      id
      title
    }
  }
`;

const CREATE_SKU = graphql(
  gql`
    mutation ($input: SKUInput!) {
      createSKU(input: $input) {
        ...SkuFragment
        images {
          ...ImageFragment
        }
      }
    }
    ${SKU_FRAGMENT}
    ${IMAGE_FRAGMENT}
  `,
  {
    props: ({ mutate }) => ({
      createSKU: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_SKU = graphql(
  gql`
    mutation ($id: Int!, $input: SKUInput!) {
      updateSKU(id: $id, input: $input) {
        ...SkuFragment
        powerSku
        free_sku
      }
    }
    ${SKU_FRAGMENT}
  `,
  {
    props: ({ mutate }) => ({
      updateSKU: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_MASTER_DATA = gql`
  query getMasterData($type: String) {
    listMasterData(type: $type) {
      type
      list
    }
  }
`;

const LIST_MASTER_CONFIGURATION = gql`
  query ListMasterConfiguration($type: configurationType) {
  listMasterConfiguration(type: $type) {
    type
    configuration
  }
}
`;

const query = {
  getCatalogList: GET_CATALOG_LIST,
  getSkuDetail: GET_SKU_DETAIL,
  getCatalogDetail: GET_CATALOG_DETAIL,
  getBUData: GET_BU_DATA,
  getMasterData: GET_MASTER_DATA,
  getVendorSBFs: GET_VENDOR_SBF_LIST,
  getRouteSegmentation:GET_ROUTE_SEGMENTATION_LIST,
  listMasterConfiguration:LIST_MASTER_CONFIGURATION
};

const mutation = {
  createSKU: CREATE_SKU,
  updateSKU: UPDATE_SKU,
};

export {
  GET_CATALOG_LIST, GET_SKU_DETAIL, GET_CATALOG_DETAIL, CREATE_SKU, UPDATE_SKU, GET_RETAILER_CATALOG_DETAIL
};

export { query, mutation };
