import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from './View';
import PrintBody from './PrintBody';
import DetailsStyled from './DetailsStyled';
import history from '../../../../../../utils/history';
import { getTodaysDate } from '../../../../../../utils/date';
import { has } from '../../../../../../utils/objectPrototypes';
import { clone } from '../../../../../../utils/objectProcessor';
import * as queryService from '../../../../../base/query.service';
import withAlert from '../../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import { BreadCrumb, Button, Icon } from '../../../../../../components';
import { isError, groupBy } from '../../../../../common/HelperFunctions';
import { breadCrumbConfig, REQUISITION_VIEW, newList, printEnums } from './config';
import { PanelHeader, PanelStyled } from '../../../../../common/configuration';
import { PURCHASE_REQUISITION_CREATE } from '../../../../../../data/enums/Route';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getRequisitionDetail: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};
class Details extends Component {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      requisitionId: has.call(props.match.params, 'id')
        ? parseInt(props.match.params.id, 10)
        : 0,
      data: {
        list: [],
        details: {
          channel: '',
          id: null,
          date: getTodaysDate(),
        },
      },
      printButtonClicked: false,
      dataPrint: {
        list: [],
        details:{},
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
        type: REQUISITION_VIEW.BRAND,
      },
    };
    const { displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      displayAlert,
    );
  }

  componentDidMount() {
    const { requisitionId } = this.state;
    this.getRequistionDetails(requisitionId);
    this.print();
  }

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

   getDownload = () => {
     const { downloadReport, displayAlert } = this.props;
     const { requisitionId } = this.state;
     downloadReport({
       input: {
         domain: 'PURCHASE_REQUISITION',
         filter: {
           filters: [
             {
               column: 'id',
               value: [requisitionId.toString()],
             },
           ],
         },
         reportType: 'csv',
       },
     },
     {
       handleSuccess: (response) => {
         const { downloadReport } = response.data;
         window.open(`${window.location.origin}/${downloadReport.file.name}`);
         displayAlert(ALERT_TYPE.SUCCESS, 'Successfully downloaded file');
       },
       handleError: (err) => {
         displayAlert(ALERT_TYPE.DANGER, err);
       },
     });
   };

  setQueryParameters = (queryParams, callBack = () => null) => {
    this.setState({ queryParameters: queryParams }, callBack);
  };

  getPrint = () => {
    const self = this;
    this.setState({ printButtonClicked: true }, () => {
      window.print();
    });
    window.onafterprint = function () {
      self.setState({ printButtonClicked: false });
    };
  };

  groupPrintDataset(data) {
    const orders = clone(data);
    const derivedChunkSize = 26;
    const {
      ordinaryList,
      lengthyList,
    } = this.separateOrderIntoOrdinaryAndLengthyList(orders);
    let dataList = this.derivePrintDataSet(
      orders,
      ordinaryList,
      lengthyList,
      derivedChunkSize,
      printEnums
    );
    dataList = this.addIndexInPrintDataSet(dataList);
    this.setState({ dataList });
  }
  separateOrderIntoOrdinaryAndLengthyList = (orders) => {
    const l1 = [];
    const l2 = [];
    orders.forEach((i) => {
      if (
        i.sku &&
        i.sku.length <= printEnums.titleOffset
      ) {
        l1.push(i);
      } else {
        l2.push(i);
      }
    });
    return { ordinaryList: l1, lengthyList: l2 };
  };
 addIndexInPrintDataSet = (dataList) => {
    const group = [...dataList];
    let index = 0;
    for (let i = 0; i < group.length; i++) {
      for (let j = 0; j < group[i].list.length; j++) {
        if(group[i].list[j].sku_id < 10000){
        group[i].list[j].index = index;
        index += 1;
        }
      }
    }
    return [...group];
  };

  derivePrintDataSet = (
    orders,
    ordinaryList,
    lengthyLists,
    chunkSize,
    printEnum
  ) => {
    const dataList = [];
    let count = 0;
    let tempDataList = [];
    let indexChunk = chunkSize;
    let remainingLines =0;
    let remainingOrdinary = ordinaryList.length;
    let lengthyList = [...lengthyLists];
    for (let index = 0, i = orders.length; index < i; index = indexChunk) {
      const chunkObj = {
        list: [],
        footer: false,
        page: 0,
      };
      let lengthyListAddedItems = [];
      count += 1;
      if (remainingOrdinary >= chunkSize) {
        chunkObj.list = ordinaryList.splice(0, chunkSize );
        remainingOrdinary = ordinaryList.length;
      } else {
        chunkObj.list = ordinaryList.splice(0, ordinaryList.length);
        remainingOrdinary = ordinaryList.length;
        let availableLines = chunkSize - chunkObj.list.length;
        let lengthySpliceIndex = 0;
        lengthyList.forEach((o,i) => {
          let requiredLines = 0;
          requiredLines =
            Math.max(
              o.sku.length / printEnum.titleOffset,
            );
          if (requiredLines <= availableLines) {
            chunkObj.list = [...chunkObj.list, o];
            availableLines -= requiredLines;
            lengthySpliceIndex += 1;
            lengthyListAddedItems.push(o.sku_id);
          }
        });
        lengthyList = lengthyList.filter(l => !lengthyListAddedItems.includes(l.sku_id));
        remainingLines=availableLines;
      }
      tempDataList = tempDataList.concat(chunkObj.list);
      if (orders.length - tempDataList.length === 0) {
        let remaingingLineArrObj = Array.from({length: remainingLines },()=> ({}));
        chunkObj.footer = true;
        chunkObj.page = count;
        chunkObj.remainingLines=remaingingLineArrObj;
      } else {
        chunkObj.footer = false;
        chunkObj.page = count;
        chunkObj.remainingLines=[];
      }
      indexChunk = tempDataList.length;
      dataList.push(chunkObj);
    }
    return dataList;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  brandName = (obj) => {
    const { queryParameters } = this.state;
    if (queryParameters.type === REQUISITION_VIEW.BRAND) {
      return obj.brand;
    }
  };

  redirectToCreate = () => {
    history.push(`/${PURCHASE_REQUISITION_CREATE}`);
  }

  getRequistionDetails = (id) => {
    const { data } = this.state;
    const { getRequisitionDetail, displayAlert } = this.props;
    getRequisitionDetail({ id },
      {
        handleSuccess: (response) => {
          const res = response.data.getPurchaseRequisitionOrderDetail;
          const responseList = (res && res.orderDetails) || [];
          const groupedArray = groupBy(responseList, this.brandName);
          const keys = ['group', 'skuList'];
          const order = groupedArray.map(r => (keys.reduce((o, k, i) => (o[k] = r[i], o), {})));
          const orderWithImage = order.map(list => ({
            ...list,
            skuList: list.skuList.map(sku => sku),
          }));
          data.list = orderWithImage;
          data.details.channel = res && res.channel;
          data.details.id = res && res.id;
          data.details.date = res && res.requisitionDate;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
  }
   onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };
  brandName = (obj) => {
    const { queryParameters } = this.state;
    if (queryParameters.type === REQUISITION_VIEW.BRAND) {
      return obj.brand;
    }
  };
  groupBy = (list, property) =>
  (list.reduce((acc, x,index) => {
    let key = x[property]
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].unshift({brand:x.brand,sku_id:x.sku_id + 10000 + index,sku:'title'});
    acc[key].push(x);
    return acc;
  }, {}));

  groupByFiltered = (arr) =>{
   const unqiueArr =arr.reduce((unique, o) => {
    if(!unique.some(obj => obj.brand === o.brand && obj.sku === o.sku)) {
      unique.push(o);
    }
    return unique;
},[]);
return unqiueArr;
  }

   print = () => {
    const { printPurchaseRequisition,displayAlert } = this.props;
    const {requisitionId,dataPrint} =this.state;
    printPurchaseRequisition({ id:requisitionId },
      {
        handleSuccess: (response) => {
          const res = response.data.printPurchaseRequisition;
            const groupedArray = this.groupBy(res.MTPurchaseRequisitionPrint, 'brand');
            const unqiueArr = this.groupByFiltered(Object.values(groupedArray).flat());
            dataPrint.list = unqiueArr;
            dataPrint.details.taxableAmount =res.taxableAmount;
            dataPrint.details.subTotal =res.subTotal;
            dataPrint.details.totalCases =res.totalCases;
            dataPrint.details.totalPcs =res.totalPcs;
            dataPrint.details.tradeDiscount =res.tradeDiscount;
            dataPrint.details.vat =res.vat;
            dataPrint.details.billDiscount =res.billDiscount;
          this.setState({dataPrint},() =>{
            this.groupPrintDataset(dataPrint.list)
          })
        },
        handleError: (err) => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      });
  };

  render() {
    const { data, queryParameters, printButtonClicked, dataPrint,dataList } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <DetailsStyled>
        <div className={printButtonClicked ? 'no-print' : 'display-block portrait'}>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>{`# ${data.details.id}`}</h2>
              <div className="flex m-0">
                <Button
                  secondary
                  iconBtnSmall
                  disabled={serverResponseWaiting}
                  onClick={() => this.getPrint()}
                >
                  <Icon iconName="print" />
                </Button>
                <Button
                  secondary
                  iconBtnSmall
                  disabled={serverResponseWaiting}
                  onClick={() => this.getDownload()}
                >
                  <Icon iconName="download" />
                </Button>
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className="section-content invoice">
          <View
            data={data}
            viewTypeConfig={{
              value: queryParameters.type,
              list: newList,
            }}
            handleViewTypeChange={this.basePaginationService.handleViewTypeChange}
            loading={serverResponseWaiting}
          />
        </div>
        </div>

        <PrintBody
          printButtonClicked={printButtonClicked}
          dataList={dataList}
          dataPrint ={dataPrint}
          purchaseRequisitionData={data}
        />
      </DetailsStyled>
    );
  }
}

Details.propTypes = propTypes;

Details.defaultProps = defaultProps;

export default withAlert()(Details);
