import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import withLoading from '../../../utils/composition/withLoading';
import { Pagination, BulkDelete } from '../../../v4/components';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import withImageSlider from '../../../utils/composition/withImageSlider';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onBulkDelete: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  lastLocation: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  permission: PERMISSION_OBJ,
  lastLocation: {},
};

const View = ({ ...props }) => {
  const { data, onPageChange, onIconClick, pagination,onTableRowClick, permission, onBulkDelete, lastLocation,handleSliderOpen } = props;
  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.OUTLET_CHAIN}
        handler={onBulkDelete}
        render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className='table-wrap'>
            <table>
              {TableHeader({
                dataListLength: 0,
                checkedListLength: 0,
                primaryCheckboxHandler,
                permission,
                lastLocation,
              })}
              <tbody>
                {data.list.map((outletChain) =>
                  TableBody({
                    data: outletChain,
                    checkedList: [],
                    onIconClick,
                    secondaryCheckboxHandler,
                    onTableRowClick,
                    permission,
                    handleSliderOpen,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);
const DetailViewWithImageSlider = withImageSlider(LoginViewWithErrorAndLoading);

export default DetailViewWithImageSlider;
