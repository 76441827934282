import { compose } from 'recompose';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import Images from './Images';
import { query } from './API';

const ComposeImages = compose(
  withRequestTracker({
    query,
  }),
)(Images);

export default ComposeImages;
