import { compose } from 'recompose';
import VisibilityAuditDetail from './VisibilityAuditDetail';
import { query as auditQuery, mutation as auditMutation, DELETE_VISIBILITY_AUDIT, UPDATE_VISIBILITY_AUDIT } from '../API';
import { CREATE_VISIBILITY_AUDIT_DETAILS, query, mutation, DELETE_VISIBILITY_AUDIT_DETAIL } from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposedVisibilityAuditDetail = compose(
  CREATE_VISIBILITY_AUDIT_DETAILS,
  DELETE_VISIBILITY_AUDIT_DETAIL,
  DELETE_VISIBILITY_AUDIT,
  UPDATE_VISIBILITY_AUDIT,
  withRequestTracker({
    query: { ...auditQuery, ...query },
    mutation: { ...auditMutation, ...mutation },
  })
)(VisibilityAuditDetail);


export default ComposedVisibilityAuditDetail;
