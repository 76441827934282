import { useSelector } from 'react-redux';
import { Bar, Cell, XAxis } from 'recharts';
import { Loading } from '../../../../../components';
import Barchart from '../../../../../components/Chart/BarChart';
import { filterItems } from '../../../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { RecentPastWeekStyled } from './RecentPastWeekStyled';
import { TableBody, TableHeader } from './tableConfig';

const dimension = {
  height: 300,
  width: 700,
};

const brandColor = {
  bbsm: '#0e69ff',
  bigmart: '#f27024',
  salesways: '#43c187',
};

const RecentPastWeek = ({ brand }) => {
  const rScore = useSelector(state => state?.RscoreDatas?.rScore);
  const RSCORE_BU = filterItems(rScore, brand, 'bu');

  const RSCORE_BY_BRAND = _(RSCORE_BU)
    .groupBy('outlet_channel')
    .map((item, outlet) => ({
      outlet,
      outlet_length: Object.keys(_(item)?.groupBy('outlet').value())?.length,
      pointScored: fixedFloatAndCommas(_.sumBy(item, 'point_scored'), 1),
      points_allocated: fixedFloatAndCommas(_.sumBy(item, 'points_allocated'), 1),
      percent: fixedFloatAndCommas((_.sumBy(item, 'point_scored') / _.sumBy(item, 'points_allocated')) * 100, 1),
    }))
    .value();

  return (
    <>
      {RSCORE_BY_BRAND?.length === 0 && <Loading title="Loading data... Please wait!" />}

      {RSCORE_BY_BRAND.length > 0 && (
        <RecentPastWeekStyled>
          <>
            <div style={{ width: '100%' }}>
              <Barchart
                data={_.sortBy(RSCORE_BY_BRAND, 'outlet')}
                dimensions={dimension}
                isXAxis={true}
                isYAxis={true}
                legend={false}
                cartesianGrid={true}
                tooltip={true}
                color={brandColor}
                xAxisTitle="Score"
                yAxisTitle="Brands"
                title="R-Score by Modern Trade Accounts"
                // barGap={32}
                barSize={170}
                aspect={4.5}
              >
                <XAxis dataKey="outlet" axisLine={false} fontSize={12} fontWeight={600} />

                <Bar
                  dataKey="percent"
                  label={{ position: 'top', fill: '#030913', fontSize: '12px', fontWeight: '500' }}
                  isAnimationActive={false}
                >
                  {_.sortBy(RSCORE_BY_BRAND, 'outlet')?.map((entry, index) => (
                    <Cell cursor="pointer" fill={brandColor[entry?.outlet.toLowerCase()]} key={`cell-${index}`} />
                  ))}
                </Bar>
              </Barchart>
            </div>

            <div className="table-wrap">
              <table>
                <TableHeader />
                <tbody>
                  {_.sortBy(RSCORE_BY_BRAND, 'outlet')?.map(data => (
                    <TableBody data={data} />
                  ))}
                </tbody>
              </table>
            </div>
          </>
        </RecentPastWeekStyled>
      )}
    </>
  );
};

export default RecentPastWeek;
