import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { has } from '../../../../../../../../utils/filter';
import View from './View';
import { MT_BU_DETAILS } from '../../../../../../../../data/enums/Route';
import { PanelHeader, PanelStyled } from '../../../../../../../common/configuration';
import {
  formMapper, breadCrumbConfig, getPayload, title, errorMessageSKU,
} from './config';
import { BreadCrumb, Button, Icon } from '../../../../../../../../components';
import { ALERT_TYPE } from '../../../../../../../../data/enums/AlertType';
import withAlert from '../../../../../../../../utils/composition/withAlert';
import { clone } from '../../../../../../../../utils/objectProcessor';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../../../../../data/services';
import SBDStyle from '../SBDStyle';
import {
  EVENT_OPERATION,
  EVENT_OPERATION_MAPPER,
} from '../../../../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../../../../data/enums/SuccessMessage';
import { isError } from '../../../../../../../common/HelperFunctions';
import { refGenerator, refValidator } from '../../../../../../../../utils/refGenerator';
import history from '../../../../../../../../utils/history';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  match: PropTypes.instanceOf(Object).isRequired,
  getProductGroupDetails: PropTypes.func.isRequired,
  getRetailOutlet: PropTypes.func.isRequired,
  getMTSBDTemplateDetails: PropTypes.func.isRequired,
  createSBDTemplate: PropTypes.func.isRequired,
  getBrandDetails: PropTypes.func.isRequired,
};

const defaultProps = {
  location: {
    state: {},
  },
  serverResponseWaiting: false,
};

class Create extends Component {
  constructor(props) {
    super(props);
    const { state = {} } = props.location;
    const id = has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0;
    const sbdId = has.call(props.match.params, 'extraId') ? parseInt(props.match.params.extraId, 10) : 0;
    this.state = {
      id,
      sbdId,
      createJBPId: state.createJBPId,
      update: {
        type: EVENT_OPERATION.CREATE,
        status: true,
      },
      cardId: {
        categoryId: '',
        productCategoryId: '',
        brandId: '',
        skuId: '',
      },
      sbdData: {
        basicData: formMapper(id),
        sbdSku: [],
      },
      count: {
        brandCount: [],
        categoryCount: [],
      },
      backBrandCount: [],
      backCategoryCount: [],
      basicDataBackUp: {},
      menu: {
        channel: [],
        category: [],
        outlet: [],
      },
      categoryList: [{
        title: '',
        categories: [],
      }],
      SKUFamilyList: [],
      skuFamilyList: [],
      productCategoryList: [],
      filteredBrandList: [],
      searchText: '',
      enableErrorDisplay: false,
      backUpSKUFamily: [],
    };
    this.formReference = refGenerator(['title', 'channel_id', 'sbdcategory', 'start_date', 'end_date']);
  }

  componentDidMount() {
    const {
      sbdId, update, id, createJBPId,
    } = this.state;
    this.onBUSelect(id);
    this.getChannelList();
    this.getCategoryListing();
    this.getProductGroupList(id);
    this.getBrandList();
    if (sbdId) {
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.getCategoryList();
      this.setState(
        {
          sbdId,
          update,
        }, () => {
          this.getSBDBasicDetails();
          this.getSBDDetails();
        },
      );
    }
    if (createJBPId) {
      this.getCategoryList();
      this.JBPDetail();
    }
  }

  JBPDetail=() => {
    const { getDetailsForJBPTemplate, displayAlert } = this.props;
    const { id, createJBPId, sbdData } = this.state;
    getDetailsForJBPTemplate({
      buId: id,
      jbpTemplateId: createJBPId,
    }, {
      handleSuccess: (res) => {
        const response = res.data.getDetailsForJBPTemplate ? res.data.getDetailsForJBPTemplate.basicData : {};

        sbdData.basicData = {
          channel_id: response.outlet_chain_id,
          start_date: response.start_date,
          sbdcategory: response.category_id,
          end_date: response.end_date,
          outlet_id: response.outlet_id,
        };
        this.setState({
          sbdData,
        }, () => {
          this.getOutletList();
        });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  getSBDBasicDetails =(pId = 0, bId = 0) => {
    const { getMTSBDTemplateDetails } = this.props;
    const { sbdData, id, sbdId, update } = this.state;
    getMTSBDTemplateDetails({
      buId: id,
      sbdTemplateId: sbdId,
      categoryId: pId,
      brandId: bId,
    }, {
      handleSuccess: (res) => {
        const response = res.data.getMTSBDTemplateDetails.rows && res.data.getMTSBDTemplateDetails.rows[0] || {};
        if (update.type === 'UPDATE') {
          sbdData.basicData = response && response.basicSBDDetails || {};
          const backUp = clone(response.basicSBDDetails);
          this.setState({ sbdData, basicDataBackUp: backUp || {} });
        }
      },
    });
  }

  getSBDDetails=(pId = 0, bId = 0) => {
    const { displayAlert, getMTSBDTemplateDetails } = this.props;
    const { id, sbdId, count, update } = this.state;
    getMTSBDTemplateDetails({
      buId: id,
      sbdTemplateId: sbdId,
      categoryId: pId,
      brandId: bId,
    }, {
      handleSuccess: (res) => {
        const response = res.data.getMTSBDTemplateDetails.rows && res.data.getMTSBDTemplateDetails.rows[0];
        count.categoryCount = response && response.Category;
        count.brandCount = response && response.Brand;
        if (update.type === 'UPDATE') {
          const backUpCategoryCount = response && clone(response.Category);
          const backUpBrandCount = response && clone(response.Brand);
          this.setState({
            backBrandCount: backUpBrandCount, backCategoryCount: backUpCategoryCount,
          });
        }
        const backUpSKU = clone(response.SkuFamily);
        this.setState({
          count,
          SKUFamilyList: response.SkuFamily,
          skuFamilyList: response.SkuFamily,
          backUpSKUFamily: backUpSKU,
        }, () => {
          this.getOutletList();
          this.retainData();
        });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  onBUSelect = (id) => {
    this.setState({
      buId: id,
    });
  } ;

  handleProductCategoryClick = (groupId) => {
    const { cardId, brandList } = this.state;
    cardId.productCategoryId = groupId;
    cardId.brandId = '';
    const newBrandList = brandList
      .filter(brand => parseInt(brand.parentCatalogDetailId, 10) === groupId);
    this.getSBDDetails(cardId.productCategoryId);
    this.setState({ cardId, filteredBrandList: newBrandList });
  };

  retainData=() => {
    const {productCategoryList, sbdData, count, SKUFamilyList } = this.state;
    const pushedCategory = [];
    const pushedBrand = [];
    if (sbdData.sbdSku) {
      for (let i = 0; i < sbdData.sbdSku.length; i++) {
        if (!pushedCategory.includes(sbdData.sbdSku[i].product_category_id)) {
          pushedCategory.push(sbdData.sbdSku[i].product_category_id);
          const countFilter = count.categoryCount.filter(x => x.id === sbdData.sbdSku[i].product_category_id);
          if (countFilter.length > 0) {
            countFilter[0].count = countFilter[0].count + sbdData.sbdSku.filter(x => x.product_category_id === sbdData.sbdSku[i].product_category_id && x.active === true).length;
            countFilter[0].count = countFilter[0].count - sbdData.sbdSku.filter(x => x.product_category_id === sbdData.sbdSku[i].product_category_id && x.active === false).length;
          } else {
            const cd = {};
            cd.id = sbdData.sbdSku[i].product_category_id;
            cd.count = sbdData.sbdSku.filter(x => x.product_category_id === sbdData.sbdSku[i].product_category_id && x.active === true).length;
            count.categoryCount.push(
              cd,
            );
          }
        }
        if (!pushedBrand.includes(sbdData.sbdSku[i].brand_id)) {
          pushedBrand.push(sbdData.sbdSku[i].brand_id);
          const countFilter = count.brandCount.filter(x => x.id === sbdData.sbdSku[i].brand_id);
          if (countFilter.length > 0) {
            countFilter[0].count = countFilter[0].count + sbdData.sbdSku.filter(x => x.brand_id === sbdData.sbdSku[i].brand_id && x.active === true).length;
            countFilter[0].count = countFilter[0].count - sbdData.sbdSku.filter(x => x.brand_id === sbdData.sbdSku[i].brand_id && x.active === false).length;
          } else {
            const cd = {};
            cd.id = sbdData.sbdSku[i].brand_id;
            cd.count = sbdData.sbdSku.filter(x => x.brand_id === sbdData.sbdSku[i].brand_id && x.active === true).length;
            count.brandCount.push(
              cd,
            );
          }
        }
        for (let i = 0; i < SKUFamilyList.length; i++) {
          for (let j = 0; j < SKUFamilyList[i].skus.length; j++) {
            for (let k = 0; k < sbdData.sbdSku.length; k++) {
              if (SKUFamilyList[i].skus[j].id === sbdData.sbdSku[k].sku_id && sbdData.sbdSku[k].active === true) {
                SKUFamilyList[i].skus[j].status = true;
              }
              if (SKUFamilyList[i].skus[j].id === sbdData.sbdSku[k].sku_id && sbdData.sbdSku[k].active === false) {
                SKUFamilyList[i].skus[j].status = false;
              }
            }
          }
        }
        for (let i = 0; i < SKUFamilyList.length; i++) {
          if (SKUFamilyList[i].skus.length === SKUFamilyList[i].skus.filter(x => x.status === true).length) {
            SKUFamilyList[i].status = true;
          }
        }
      }
    }
    this.setState({ sbdData, productCategoryList, count });
  }


  handleDropDownChange = (value, name) => {
    const { sbdData, menu } = this.state;
    sbdData.basicData[name] = value;
    this.setState(sbdData);
    switch (name) {
      case 'channel_id':
        sbdData.basicData.sbdcategory = [];
        sbdData.basicData.outlet_id = null;
        menu.outlet = [];
        this.setState({ sbdData, menu });
        this.getCategoryList();
        break;
      case 'sbdcategory':
        sbdData.basicData.outlet_id = null;
        menu.outlet = [];
        this.setState({ sbdData, menu });
        if (sbdData.basicData.sbdcategory.length) {
          this.getOutletList();
        }
        break;
      default:
    }
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { sbdData: { basicData } } = this.state;
    if (firstParam) {
      if (paramList.length > 1) {
        paramList.reduce((acc, value, index, list) => {
          if (index === list.length - 1) {
            return (acc[value] = event.formattedValue);
          }
          return acc[value];
        }, basicData);
      } else {
        basicData[firstParam][event.target.name] = event.formattedValue;
      }
    } else {
      basicData[event.target.name] = event.formattedValue;
    }
    this.setState({ basicData });
  };

  handleSwitchChange = (event, label, paramList = [], familyId, childId, checked) => {
    const {
      SKUFamilyList, cardId, count,
    } = this.state;
    if (label === 'grandParent') {
      SKUFamilyList.forEach((val) => {
        val.status = event.formattedValue;
      });
      SKUFamilyList.forEach(val => val.skus.forEach((x) => {
        x.status = event.formattedValue;
      }));
      const x = SKUFamilyList.map(val => val.skus.map(x => x.id));
      const y = SKUFamilyList.map(val => val.id);
      this.pushArr(x, event, y);
      this.setState({ SKUFamilyList });
    }
    if (label === 'parent') {
      if (event.formattedValue) {
        if (count.brandCount.filter(x => x.id === cardId.brandId).length > 0) {
          for (let i = 0; i < count.brandCount.length; i++) {
            if (count.brandCount[i].id === cardId.brandId) {
              count.brandCount[i].count = count.brandCount[i].count + SKUFamilyList.filter(x => x.id === familyId)[0].skus.filter(x => x.status === false).length;
            }
          }
        } else {
          count.brandCount.push({
            count: SKUFamilyList.filter(x => x.id === familyId)[0].skus.filter(x => x.status === false).length,
            id: cardId.brandId,
          });
        }
        if (count.categoryCount.filter(x => x.id === cardId.productCategoryId).length > 0) {
          for (let i = 0; i < count.categoryCount.length; i++) {
            if (count.categoryCount[i].id === cardId.productCategoryId) {
              count.categoryCount[i].count = count.categoryCount[i].count + SKUFamilyList.filter(x => x.id === familyId)[0].skus.filter(x => x.status === false).length;
            }
          }
        } else {
          count.categoryCount.push({
            count: SKUFamilyList.filter(x => x.id === familyId)[0].skus.filter(x => x.status === false).length,
            id: cardId.productCategoryId,
          });
        }
      }
      if (!event.formattedValue) {
        if (count.brandCount.filter(x => x.id === cardId.brandId).length > 0) {
          for (let i = 0; i < count.brandCount.length; i++) {
            if (count.brandCount[i].id === cardId.brandId) {
              count.brandCount[i].count = count.brandCount[i].count - SKUFamilyList.filter(x => x.id === familyId)[0].skus.filter(x => x.status === true).length;
            }
          }
        }
        if (count.categoryCount.filter(x => x.id === cardId.productCategoryId).length > 0) {
          for (let i = 0; i < count.categoryCount.length; i++) {
            if (count.categoryCount[i].id === cardId.productCategoryId) {
              count.categoryCount[i].count = count.categoryCount[i].count - SKUFamilyList.filter(x => x.id === familyId)[0].skus.filter(x => x.status === true).length;
            }
          }
        }
      }
      SKUFamilyList[paramList[1]].status = checked;
      SKUFamilyList[paramList[1]].status = event.formattedValue;
      SKUFamilyList[paramList[1]].skus.map(a => a.status = event.formattedValue);
      const x = SKUFamilyList[paramList[1]].skus.map(a => a.id);
      this.pushArr(x, event, familyId);
    }
    if (label == 'child') {
      SKUFamilyList[paramList[1]][paramList[2]][paramList[3]].status = event.formattedValue;
      SKUFamilyList[paramList[1]].status = checked;

      const x = [childId];
      this.pushArr(x, event, familyId);
      if (event.formattedValue) {
        if (count.brandCount.filter(x => x.id === cardId.brandId).length > 0) {
          for (let i = 0; i < count.brandCount.length; i++) {
            if (count.brandCount[i].id === cardId.brandId) {
              count.brandCount[i].count = count.brandCount[i].count + 1;
            }
          }
        } else {
          count.brandCount.push({
            count: 1,
            id: cardId.brandId,
          });
        }
        if (count.categoryCount.filter(x => x.id === cardId.productCategoryId).length > 0) {
          for (let i = 0; i < count.categoryCount.length; i++) {
            if (count.categoryCount[i].id === cardId.productCategoryId) {
              count.categoryCount[i].count = count.categoryCount[i].count + 1;
            }
          }
        } else {
          count.categoryCount.push({
            count: 1,
            id: cardId.productCategoryId,
          });
        }
      }
      if (!event.formattedValue) {
        if (count.brandCount.length > 0) {
          for (let i = 0; i < count.brandCount.length; i++) {
            if (count.brandCount[i].id === cardId.brandId) {
              count.brandCount[i].count = count.brandCount[i].count - 1;
            }
          }
        }
        if (count.categoryCount.length > 0) {
          for (let i = 0; i < count.categoryCount.length; i++) {
            if (count.categoryCount[i].id === cardId.productCategoryId) {
              count.categoryCount[i].count = count.categoryCount[i].count - 1;
            }
          }
        }
      }
      this.setState(count);
    }
    this.setState({ SKUFamilyList });
  };

  pushArr= (x, event, familyId) => {
    const { sbdData, cardId } = this.state;
    x.map((o) => {
      const sbdSkuArr = {
        product_category_id: cardId.productCategoryId,
        brand_id: cardId.brandId,
        sku_family_id: familyId,
        sku_id: o,
        active: event.formattedValue,
      };
      this.sbdSkuArrUnique(sbdData.sbdSku, sbdSkuArr);
    });
  }

  sbdSkuArrUnique =(array, item) => {
    const i = array.findIndex(_item => _item.sku_id === item.sku_id);
    if (i > -1) array[i] = item;
    else array.push(item);
  }

  getChannelList = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CHANNEL).then((response) => {
      menu.channel = response;
      this.setState({ menu });
    });
  }

 getCategoryList=() => {
   const { menu } = this.state;
   getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY).then((response) => {
     menu.category = response;
     this.setState({ menu });
   });
 }

 onAPIRequestFailure = error => {
  const { displayAlert } = this.props;
  displayAlert(ALERT_TYPE.DANGER, error);
};

 getProductGroupList = (id) => {
   const { getProductGroupDetails } = this.props;
   getProductGroupDetails({ parentCatalogDetailId: id }, {
     handleSuccess: (response) => {
       const productCategoryList = (response.data.catalogDetails
        && response.data.catalogDetails.rows) || [];
       if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
       this.setState({ productCategoryList });
     },
     handleError: (error) => { this.onAPIRequestFailure(error); },
   });
 };

getBrandList = () => {
  const { getBrandDetails } = this.props;
  getBrandDetails({}, {
    handleSuccess: (response) => {
      const brandList = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
      if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
      this.setState({ brandList });
    },
    handleError: (error) => { this.onAPIRequestFailure(error); },
  });
};

getCategoryListing = () => { // bu List
  getDataFromLocalStorage(CLIENT_STORAGE_TABLE.PRINCIPAL).then(
    (response) => {
      const categoryList = [{ categories: response }];
      this.setState({ categoryList });
    },
  );
};

getOutletList = () => {
  const { getRetailOutlet, displayAlert } = this.props;
  const { menu, sbdData } = this.state;
  let ids = [];
  if (sbdData && sbdData.basicData) {
    ids = sbdData.basicData.sbdcategory && sbdData.basicData.sbdcategory.map(a => a.toString());
  }
  getRetailOutlet({
    limit: 1000,
    offset: 0,
    filter: {
      filters: {
        column: 'category_id',
        value: ids,
      },
    },
  }, {
    handleSuccess: (response) => {
      menu.outlet = response.data.retailOutlets ? response.data.retailOutlets.rows || [] : [];
      this.setState({ menu });
    },
    handleError: (err) => {
      displayAlert(ALERT_TYPE.DANGER, err);
    },
  });
};

 onCategorySelect = (categoryId) => {
   this.setState({
     showBrands: true,
     categoryId,
   });
 }

 handleBrandClick = (brandId) => {
   const { cardId } = this.state;
   cardId.brandId = brandId;
   this.getSBDDetails(cardId.productCategoryId, cardId.brandId);
   this.setState({ cardId });
 };


handleSearchInput = (text) => {
  const { skuFamilyList } = this.state;
  const regex = new RegExp(text, 'i');
  const bySkus = skuFamilyList.filter(i => i.skus
    .filter(s => s.title.search(regex) > -1).length > 0);
  const bySKUFamilies = skuFamilyList.filter(p => p.title.search(regex) > -1);
  const union = [...bySKUFamilies, ...bySkus];
  const result = union.filter((item, index) => union.indexOf(item) === index);
  this.setState({ SKUFamilyList: result, searchText: text });
};

handleButtonCancel = (sbdId) => {
  const {
    update, basicDataBackUp, backUpSKUFamily, backCategoryCount, backBrandCount} = this.state;
  if (sbdId) {
    update.type = EVENT_OPERATION.UPDATE;
    update.status = false;
    const backUpBranchCount = clone(backBrandCount);
    const backUpCategoryCount = clone(backCategoryCount);
    const backUpBasicData = clone(basicDataBackUp);
    this.setState({ sbdData: { basicData: backUpBasicData, sbdSku: [] }, update, count: { brandCount: backUpBranchCount, categoryCount: backUpCategoryCount } });
  } else {
    this.setState({
      sbdData: { basicData: formMapper({}), sbdSku: [] },
      count: {
        brandCount: [],
        categoryCount: [],
      },
    });
  }
  const backUpSKU = clone(backUpSKUFamily);
  this.setState({ SKUFamilyList: backUpSKU });
};

skuDataValid = () => {
  const { count: { categoryCount } } = this.state;
  const { displayAlert } = this.props;
  if (categoryCount.length === 0 || categoryCount[0].count === 0) {
   displayAlert(ALERT_TYPE.CUSTOM_DANGER, errorMessageSKU);
  return false;
}
return true;
}
handleButtonSubmit = () => {
  const { sbdId, id, sbdData, update, createJBPId } = this.state;
  const { createSBDTemplate, displayAlert, location: { state } } = this.props;
  const formValidation = refValidator(this.formReference) && this.skuDataValid();
  if (!formValidation) {
    this.setState({ enableErrorDisplay: true });
  } else {
    createSBDTemplate({
      id: update.type === 'CREATE' ? 0 : sbdId,
      input: getPayload(sbdData, id, createJBPId),
    }, {
      handleSuccess: () => {
        displayAlert(ALERT_TYPE.SUCCESS, `SBD Template ${update.type === 'CREATE' ? MESSAGE_EVENT_OPERATION.CREATE : MESSAGE_EVENT_OPERATION.UPDATE}`);
        if (createJBPId) {
          setTimeout(() => {
            history.push(`/${MT_BU_DETAILS}/${state.title}/${state.id}/jbp-detail/${createJBPId}`);
          }, 900);
        } else {
          setTimeout(() => {
            history.push(`/${MT_BU_DETAILS}/${state.title}/${state.id}`, { activeTab: 2 });
          }, 900);
        }
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },
    });
  }
};

getHeader = () => {
  const { update, sbdData: { basicData } } = this.state;
  const crudMode = update.status
    ? `${EVENT_OPERATION_MAPPER[update.type].toLowerCase()} ${title}`
    : `${basicData.title}`;
  return (
    <>
      <span>
        {crudMode}
      </span>
    </>
  );
};

render() {
  const {
    cardId, sbdData, menu, categoryId, update, sbdId, categoryList, productCategoryList, filteredBrandList, SKUFamilyList, skuAssortedList, searchText, overallSwitchStatus, enableErrorDisplay, count,
  } = this.state;
  const { serverResponseWaiting, location: { state } } = this.props;
  const createBreadCrumb = {
    id: 3,
    title: 'SBD Templates',
    path: `/${MT_BU_DETAILS}/${state.title}/${state.id}`,
    active: true,
  };
  const header = this.getHeader();
  return (
    <SBDStyle>
      <div className="section-header">
        <PanelStyled>
          <BreadCrumb list={[...breadCrumbConfig, createBreadCrumb]} />
          <PanelHeader>
            <h2>{header}</h2>
            <div className="flex m-0">
              {update.status && (
              <div>
                <Button
                  small
                  secondary
                  disabled={serverResponseWaiting}
                  onClick={() => this.handleButtonCancel(sbdId)}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  small
                  primary
                  disabled={serverResponseWaiting}
                  onClick={() => this.handleButtonSubmit()}
                >
                  <span>Save</span>
                </Button>
              </div>
              )}
              {!update.status && (
              <Button
                secondary
                iconBtnSmall
                disabled = {update.type === EVENT_OPERATION.UPDATE && update.status}
                onClick={() => {
                  this.setState({
                    update: {
                      type: EVENT_OPERATION.UPDATE,
                      status: true,
                    },
                  });
                }}
                className="ml-16"
              >
                <Icon iconName="pencil" />
              </Button>
              )}
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className={`section-content  ${update.type === 'UPDATE'
            && 'update'}`}
      >
        <View
          cardId={cardId}
          sbdData={sbdData}
          menu={menu}
          onInputChange={this.handleInputChange}
          onDropDownChange={this.handleDropDownChange}
          onCategorySelect={this.onCategorySelect}
          categoryId={categoryId}
          update={update}
          onHandleSearchInput={this.handleSearchInput}
          categoryList={categoryList[0].categories}
          productCategoryList={productCategoryList}
          onProductCategoryClick={this.handleProductCategoryClick}
          brandList={filteredBrandList}
          onBrandClick={this.handleBrandClick}
          SKUFamilyList={SKUFamilyList}
          skuAssortedList={skuAssortedList}
          searchText={searchText}
          handleSwitchChange={this.handleSwitchChange}
          refsObj={this.formReference}
          overallSwitchStatus={overallSwitchStatus}
          enableErrorDisplay={enableErrorDisplay}
          count={count}
        />
      </div>
    </SBDStyle>
  );
}
}
Create.propTypes = propTypes;
Create.defaultProps = defaultProps;

export default withAlert()(Create);
