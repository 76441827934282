import { STATUS } from './Status';
import { USER_ROLE } from './UserRole';
import {
  PROMOTION_RELATED_TYPE, PROMOTION_TYPE, PROMOTION_AREAS
} from './Promotion';
import { SKU_CATALOG_LEVEL } from './Catalog';

const SORTING = {
  ASC: 'ASC',
  DESC: 'DESC',
  NO_SORT: 'NO_SORT',
};

const PAGINATION = {
  PAGE: 1,
  LIMIT: 50,
};

const ACTION = {
  UPLOAD: 'Upload',
  ADD: 'Create',
  UPDATE: 'Update',
  DELETE: 'Delete',
  NULL: 'NoAction',
};

const STOCK_TYPE = {
  INWARD: 0,
  OUTWARD: 1,
};

const CALENDAR_PREVIEW = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export {
  SORTING,
  PAGINATION,
  ACTION,
  STOCK_TYPE,
  CALENDAR_PREVIEW,
  STATUS,
  USER_ROLE,
  PROMOTION_TYPE,
  PROMOTION_AREAS,
  SKU_CATALOG_LEVEL,
  PROMOTION_RELATED_TYPE,
};
