import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_FUNDAMENTALS_LIST } from '../../../../MTSetting/fundamentals/API';
import { GET_SBD_LIST } from '../../SBD/API';
import { GET_BRAND_DETAILS } from '../../SBD/create/API';

const GET_JBP_TEMPLATE_DETAILS = gql`
query getvaluesForJBPTemplateCreation($buId: Int!){
  getvaluesForJBPTemplateCreation(buId:$buId){
    configuredFundamentals{
      id
      title
      category
      abbreviation
    }
    sos{
      catalog_id
      catalog_detail_id
      title
    }
    sod{
      merchandise_device_id
      title
      catalog_id
      catalog_detail_id
      count
    }
    sosku{
      id
      category
      isChildPresent
      status
      childUsers{
        id
        title
        status
        isChildPresent
      }
    }
  }
}
`;

const GET_DETAIL_JBP_TEMPLATE = gql`
query getDetailsForJBPTemplate($buId: Int!, $jbpTemplateId: Int!){
  getDetailsForJBPTemplate(buId:$buId,jbpTemplateId:$jbpTemplateId){
    sbdTemplateId
    configuredFundamentals{
      id
      title
      category
      abbreviation
    }
    basicData{
      title
      start_date
      end_date
      principal_id
      outlet_chain_id
      category_id
      point
      outlet_id
      active
      fundamentalWeightage: fundamentalWeightageDetails{
        fundamental_type_id
        weightage
      }
    }
    sos{
      catalog_id
      catalog_detail_id
      title
      jbpshare
      weightage
    }
    sod{
      merchandise_device_id
      title
      count
      catalog_id
      catalog_detail_id
    }
    sosku{
      id
      category
      isChildPresent
      status
      childUsers{
        id
        title
        status
        isChildPresent
      }
    }
  }
}
`;

const CREATE_JBP_TEMPLATE = graphql(gql`
    mutation createJBPTemplate($id: Int,$input: JBPTemplateInput!){
      createJBPTemplate(id: $id,input: $input){
        id 
        title
      }
    }`, {
  props: ({ mutate }) => ({
    createJBPTemplate: (variables) => mutate({
      variables,
    }),
  }),
});

const GET_RETAIL_OUTLETS = gql`
query retailoutlets($limit:Int, $offset: Int, $filter: FilterInput) {
  retailOutlets(limit:$limit, offset:$offset, filter:$filter){
    rows{
      title
      id
    }
    count
  }
}`;

const GET_PRODUCT_GROUP_DETAILS = gql`
    query getProductGroupDetails($parentCatalogDetailId:Int) {
        catalogDetails(catalogId:2,parentCatalogDetailId:$parentCatalogDetailId){
            rows {
                id
                title
                catalogId
            }
        }
    }
`;

const GET_DETAILS_MT_JBP_TEMPLATE = gql`
query getMTJBPTemplateDetails($jbpTemplateId: Int!, $buId: Int, $categoryId: Int, $brandId: Int) {
  getMTJBPTemplateDetails(jbpTemplateId: $jbpTemplateId, buId: $buId, categoryId: $categoryId, brandId: $brandId) {
    rows {
      basicSBDDetails {
        id
        title
        active
        end_date
        tags
        numberofSkus
        start_date
        category_id
        outlet_id
        channel_id
        canbeDeleted
      }
      Category {
        id
        count
      }
      Brand {
        id
        count
      }
      SkuFamily {
        id
        title
        skus {
          id
          title
          status
        }
        status
      }
    }
    count
  }
}
`;

const query = {
  getBrandDetails: GET_BRAND_DETAILS,
  getSBDTemplateList: GET_SBD_LIST,
  getRetailOutlet: GET_RETAIL_OUTLETS,
  getFundamentalsList: GET_FUNDAMENTALS_LIST,
  getValuesForJBPTemplate: GET_JBP_TEMPLATE_DETAILS,
  getProductGroupDetails: GET_PRODUCT_GROUP_DETAILS,
  getDetailsForJBPTemplate: GET_DETAIL_JBP_TEMPLATE,
  getMTJBPTemplateDetails: GET_DETAILS_MT_JBP_TEMPLATE,
};

const mutation = {
  createJBPTemplate: CREATE_JBP_TEMPLATE,
};

export {
  query, mutation, CREATE_JBP_TEMPLATE
};

export { GET_DETAIL_JBP_TEMPLATE }
