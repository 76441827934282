import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_QUESTIONNAIRE_LIST = gql`
query getQuestionnaire($limit: Int, $offset: Int, $filter: FilterInput) {
  getQuestionnaire(limit: $limit, offset: $offset, filter: $filter) {
    count
    rows {
      active
      brandIds {
        brand
        brand_id
        id
      }
      categoryIds {
        category
        category_id
        id
      }
      channel_id
      channel
      end
      id
      principal_id
      principal
      question
      start
    }
  }
}
`;

export const DELETE_QUESTIONNAIRE = graphql(gql`
mutation DeleteQuestionnaire($id: Int!) {
  deleteQuestionnaire(id: $id) {
    id
    question
    principal_id
  }
}`, {
  props: ({ mutate }) => ({
    deleteQuestionnaire: (variables) => mutate({
      variables,
    }),
  }),
});

const query = {
  getQuestionnaire: GET_QUESTIONNAIRE_LIST,
};

const mutation = {
  deleteQuestionnaire: DELETE_QUESTIONNAIRE,
};

export { query, mutation }
