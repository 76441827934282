import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { breadCrumb, detailDataMapper } from './config';
import SrnView from './View';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { DOMAIN, WIDGETS } from '../../../../data/enums/config';
import { handlePrint } from '../../../common/HelperFunctions';
import SRNStyled from '../SRNStyled';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  getSrnDetails: PropTypes.func.isRequired,
};
class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: detailDataMapper({}),
      actionWidgets: {
        [WIDGETS.PRINT]: false,
      },
    };
  }

  componentDidMount() {
    this.getList();
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  getList = () => {
    const { actionWidgets } = this.state;
    const { getSrnDetails, displayAlert, match } = this.props;
    /** after api supports details fetch from prnInvoiceNumber  * */
    // prnInvoiceNumber: voucherDecoder(match.params.id),
    getSrnDetails(
      {
        srnId: parseInt(match.params.id || '', 10),
      },
      {
        handleSuccess: response => {
          const { srnDetails = {} } = response.data;
          const modifiedData = detailDataMapper(srnDetails);
          actionWidgets[WIDGETS.PRINT] = modifiedData.Distributor.servicesUsed.billing || false;
          this.setState({ data: modifiedData });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handlePrintClick = () => {
    const { data } = this.state;
    handlePrint({
      module: DOMAIN.SALES_RETURN,
      invoiceNumber: data.srnInvoiceNumber,
      distributorServices: data.Distributor.servicesUsed,
    });
  };

  getActionConfig = type => ({ handler: this.handlePrintClick });

  render() {
    const { data, actionWidgets } = this.state;

    return (
      <SRNStyled>
        <SrnView
          data={data}
          actionWidgets={actionWidgets}
          getActionConfig={this.getActionConfig}
          title={`SRN #${data.srnInvoiceNumber}`}
          breadCrumb={breadCrumb}
          handlePrintClick={this.handlePrintClick}
          onPhotoClick={this.handlePhotoSwipeOpen}
          onCarouselClose={this.handlePhotoSwipeClose}
        />
      </SRNStyled>
    );
  }
}

Detail.propTypes = propTypes;

export default withAlert()(Detail);
