import styled from 'styled-components';

const PurchaseOrderStyled = styled.div`
.form-select-input{
  label{
    display: none;
  }
  width: 20%;
}
.sku-section{
  border: 1px solid #E7E7ED;
  border-radius: 4px;
  .sku-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    line-height: 21px;
    font-size: 14px;
    color: #272833;
    padding: 10px 24px;
    border-bottom: 1px solid #e7e7ed;
    background-color: #f1f2f5;
    height: auto;
    .group-by{
      display: flex;
      align-items: center;
      div{
        padding-right: 0px;
        border: none;
        label{
          margin-right: 0px;
        }
      }
      .form-select-input{
      .zindex-2__control{
        border: none;
        cursor: pointer;
        background: #f1f2f5;
      }
    }
    }
  }
}
.upload-section{
  background: #ffffff;
  height: 581px;
  border-radius: 4px;
  button{
    margin: 24px;
  }
  .table-wrap{
    >table >thead >tr >th{
      position: revert;
    }
  }
}

.config-panel-card{
  margin-bottom: 40px;
  .config-panel-contents{
    label {
      display: inline-block;
      margin-bottom: 11px;
      color: #272833;
      font-size: 14px;
      font-weight: 600;
  }
  .config-panel-value{
    color: #6B6C7E;
    line-height: 24px;
    font-size: 16px;
  }
 }
}

`;

export default PurchaseOrderStyled;
