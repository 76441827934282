
import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../../../../data/enums/EventOperation';
import {
  menuAction, defaultMenuConfigList, tableData
} from '../../../../../../../utils/paginatedListUtils';
import { PERMISSION_OBJ } from '../../../../../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  channel:'channel',
  category:'category',
  brand:'brand',
  actionHolders: 'actionHolders',
};

const menuConfigList = [
  defaultMenuConfigList[EVENT_OPERATION.DELETE],
];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: false,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Question',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.channel,
    title: 'Channel',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.category,
    title: 'Category',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const tableHeaderWithCheckBox = (dataListLength, checkedListLength, primaryCheckboxHandler, permission,
  labelConfig, labelMappings, newTitleObj) => (
  <thead className=''>
    <tr>
      {labelConfig.map((label) => (label.show
        ?tableData(label.title, label.align, label.classNames, label.dynamicTitle, label.id, newTitleObj)
        : null))}
    </tr>
  </thead>
);

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission, }) => (tableHeaderWithCheckBox(dataListLength, checkedListLength, primaryCheckboxHandler, permission,
  labelConfig, labelMappings));

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.title] = <td key={`vgp-${data.id}-question`}>{data.question}</td>;
  table[labelMappings.channel] = <td key={`vgp-${data.id}-channel`}>{data?.channel}</td>;
  table[labelMappings.category] = <td key={`vgp-${data.id}-category`}>{Array.from(new Set(data.categoryIds.map((a) => a.category))).join(', ')}</td>;
  table[labelMappings.brand] = <td key={`vgp-${data.id}-brand`}>{Array.from(new Set(data.brandIds.map((a) => a.brand))).join(', ')}</td>;
  table[labelMappings.actionHolders] = (
    <td
      key={`${data.id}-action`}
      className='text-right simple-popup-actions'
      onClick={(e) => e.stopPropagation()}
    >
      {menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        data,
        permission,
      )}
    </td>
  );

  return table;
};

const TableBody = ({
  data, checkedList, onIconClick, secondaryCheckboxHandler, permission,
  onTableRowClick,
}) => {
  const tableBody = returnTableBody(data, checkedList,
    onIconClick, secondaryCheckboxHandler, permission);
  return (
    <tr
      key={data.id}
      style={{ cursor: 'pointer' }}
      onClick={() => onTableRowClick(data.id, EVENT_OPERATION.UPDATE)}
    >
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
  onTableRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export {
  TableHeader,
  TableBody,
};
