import styled from 'styled-components';

const MOQWrap = styled.div`
  position: relative;

  .subtitle {
    position: absolute;
    top: -49px;
    left: 24px;
    font-size: 18px;
    font-weight: normal;
    color: #6b6c7e;
  }
  
  .table-wrap{
    height: calc(100vh - 218px) !important;
  }

  ul.list li {
    padding-right: 24px;
  }
  
  section {
    margin-bottom: 40px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .container-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
  
  .tab-container {
    overflow-x: hidden;
  }
  
  .tab-section {
    table {
      th {
       
        &:nth-child(2) {
          width: 8%;
        }
        
        &:last-child {
          width: 5%;
        }
      }
      
      tr {
        &:hover {
          background: #f0f5ff;
        }
        
        &.active {
          background: #f0f5ff;
        
        td {
            border-bottom: 1px solid #0c5fff;
          }
        }
      }
      
      th:first-child, td:first-child {
         padding-left: 48px;
      }
      
      th:last-child, td:last-child {
        padding-right: 48px;
      }
      
      .collapsible-body-tr td.collapsible-body-td {
        padding: 0 !important;
      }
    }
    
    .split-form-wrap {
      margin: -40px -24px 0;
      padding: 16px 24px 0;
      
      table {
        th {
          &:first-child {
            padding: 13px 24px;
          }
        }
        tbody {
          tr td:first-child {
            padding: 13px 24px !important;
          }
          
          tr td:last-child  {
            padding: 13px 24px !important;
          }
        }
      }
    }
    
    .collapsible-body-tr {
      &:hover, &:focus {
        background: transparent;
      }
      
      table {
        box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
        border: none;
        
        tbody {
          tr:last-child {
            border-top: 1px solid #e7e7ed;
          }
        }
        
        tr {
          th {
            background: transparent;
            width: auto;
          }
        
          th, td {
            border-bottom: none;
          }
        }
      }
    }
  }
  
  table th:first-child,
  table td:first-child {
    width: 13%;
  }
  
  .tab-section {
    table thead tr th:first-child {
      padding-left: 70px;
    }
  }
  
  table tbody {
    tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(180deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #004AD7;
        }
      }
      td:first-child {
        > span {
          position: relative;
          padding-left: 26px;
          
          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }
    }
  }
  
  .stock-label {
    border: 1px solid transparent;
    display: inline-block;
    font-size: 10px;
    font-weight: 600;
    border-radius: 2px;
    padding: 1px 3px;
    
    &.instock {
      .light{
      border-color: #47c464;
      color: #287d3c;
    }
    }
    
    &.outofstock {
      .light{
      border-color: rgba(218,20,20,0.4);
      color: #DA1414;
    }
    }
    &.stockinsufficient{
      .light{
       border-color: #ff8f39;
      color: #B95000;
    }
    }
  }
  
  .split-form-container {
  
    .table-wrap {
      max-height: 350px;
      overflow-y: auto;
    }
  
    .stats {
      margin: 16px -24px;
      padding: 0 24px 16px;
      border-bottom: 1px solid #E7E7EE;
    }
    
    .stock {
      margin: 0px -24px;
      padding: 0 24px 16px;
      border-bottom: 1px solid #E7E7EE;
    }
    .split-header {
      label {
        font-size: 14px;
        font-weight: 600;
        display: block;
        color: #272833;
        line-height: 21px;
        margin-bottom: 4px;
      }
      
      span {
        display: inline-block;
        font-size: 14px;
        color: #6b6c7e;
      }
      
      p {
        margin: 0;
        padding-bottom: 16px;
        font-size: 14px;
        line-height: 21px;
        color: #6b6c7e;
      }
    }
    
    .split-body {
      margin: 0 -24px;
      
      .table-paginate {
        text-align: right;
        padding: 10px 0;
      }
    }
  }


  .clickable {
cursor: pointer;
}
.no-print{
  display:none;
}
    .display-block.portrait{
      .purchase-info{
        padding-bottom: 20px;
        .left{
          h4,p{
            margin:0;
          }
          h4{
            margin-bottom:4px;
            font-size:16px;
          }
          p{
            font-size:11px;
            font-weight:500;
            span{
              font-weight:400;
            }
          }
        }
        .center{
          text-align:center;
          h2{
            margin:0;
            font-size:21px;
          }
        }
        .right{
          text-align:right;
          p{
            margin:0;
            font-size:11px;
            font-weight:500;
            span{
              font-weight:400;
            }
          }
        }
      }
    }
    .table-wrap.print{     
        height:unset !important;
          caption{
              padding:10px 24px;
              span{
                font-size:12px;
                line-height:16px;
                color:#272833;
                font-weight:500;
              }
          }
          table {
            border:0;
            thead{
              tr{
                height:24px;
              th{
              padding:7px 12px;
              border:0;
              border-bottom:1px solid  rgba(0,0,0,0.5);
              font-size:10px;
                line-height:12px;
                }
                }
            }
            tbody{
              tr{
                height:30px;
            td{
              border:0;
              border-bottom:0.5px solid  rgba(0,0,0,0.2);
              padding:7px 12px;
              font-size:10px;
                line-height:12px;
                p{
                margin:0;
                font-size:10px;
                line-height:12px;
                }
                }
              }
              }
        }
    }
  .visibility-shown-print{
          visibility: hidden !important;
      }
    @media print {
      border
        .print_display_none{
            display:none ;
        }
        .visibility-shown-print{
            visibility: visible !important;
        }
        .display-block{
          display:block;
          &.portrait{
            margin:20px;
          }
        }
        @page {
            margin: 0 !important;
            size: A4 portrait !important;
            counter-increment: page;
            counter-reset: page 1;
        }
        .side-nav,
        .no-print{
          display:none;
        }
    }
`;

export default MOQWrap;
