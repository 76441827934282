import { EVENT_OPERATION } from '../../../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../../../data/enums/SuccessMessage';

const title = 'SBD Template';
const sucessMessage = 'SBD Template record is Deleted sucessfully';
const crudRequest = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      id: data.id,
      type: 'sbdDelete',
      active: false,
    }),
    responseName: 'deleteSBDTemplate',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};
const crudBulkDeleteRequestConfig = {
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: element => ({
      id: [...element.data],
      type: 'sbdDeleteBulk',
      active: false,
    }),
    responseName: 'bulkDeleteSBDTemplate',
    message: `${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};
export {
  crudRequest,
  crudBulkDeleteRequestConfig,
  sucessMessage,
};
