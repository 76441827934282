import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Form from './Form';
import { DialogFormWrapper } from '../../../../common';
import { formConfig, crudRequestConfig } from './config';
import Confirmation from '../../../../common/DialogConfirmation';
import withAlert from '../../../../../utils/composition/withAlert';
import { isConfirmationType } from '../../../../common/HelperFunctions';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { handleFormSubmit, responseInterpreter } from '../../../../../utils/crudResponseProcessor';

const propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  editMTStock: PropTypes.func.isRequired,
  createMTStock: PropTypes.func.isRequired,
  element: PropTypes.instanceOf(Object),
  serverResponseWaiting: PropTypes.bool,
  deleteMTStock: PropTypes.func.isRequired,
  onApiFailure: PropTypes.func.isRequired,
  bulkDeleteMTStock: PropTypes.func.isRequired,
};

const defaultProps = {
  element: {},
  serverResponseWaiting: false,
  type: EVENT_OPERATION.CREATE,
};

class MTDialog extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createMTStock,
      [EVENT_OPERATION.UPDATE]: props.editMTStock,
      [EVENT_OPERATION.DELETE]: props.deleteMTStock,
      [EVENT_OPERATION.BULK_DELETE]: props.bulkDeleteMTStock,
    };
    this.onCRUDSuccess = responseInterpreter(this.handleSuccessResponse);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, props.onApiFailure || props.onClose,
      crudRequestConfig, serverCall);
  }

  handleSuccessResponse = (type, response) => {
    const { onClose, onSubmit } = this.props;
    onClose();
    onSubmit(type, response);
  };

  render() {
    const {
      type, element, onClose, serverResponseWaiting, frequency, assignedTo,
    } = this.props;

    return (
      <DialogFormWrapper
        formConfig={formConfig[type]}
        dialogElement={element}
        onDialogSubmit={this.onFormSubmit}
        onDialogCancel={onClose}
        type={type}
        renderDialog={
              ({
                refsObj,
                dialogData,
                handleInputChange,
                enableErrorDisplay,
                handleDropDownChange,
              }) => (
                <Fragment>
                  {
                    (type === EVENT_OPERATION.UPDATE
                      || type === EVENT_OPERATION.CREATE
                    ) && (
                      <Form
                        show
                        type={type}
                        refsObj={refsObj}
                        data={dialogData}
                        loading={serverResponseWaiting}
                        handleInputChange={handleInputChange}
                        enableErrorDisplay={enableErrorDisplay}
                        handleDropDownChange={handleDropDownChange}
                        frequency={frequency}
                        assignedTo={assignedTo}
                      />
                    )
                  }
                  { isConfirmationType(type)
                    && Confirmation(type)
                  }
                </Fragment>
              )}
      />
    );
  }
}

MTDialog.propTypes = propTypes;

MTDialog.defaultProps = defaultProps;

export default withAlert()(MTDialog);
