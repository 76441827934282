import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const GET_USER_TARGET_DETAILS = gql`
  query GetUserTargetDetails($targetId: Int) {
    getUserTargetDetails(targetId: $targetId) {
      id
      userTargetId
      user
      role
      ulpo
      tls
      productivity
      active_outlets
      dgp
      sbd
      bill_cuts
      isChildPresent
      ChildUsers {
        id
        user
        role
        ulpo
        tls
        sbd
        dgp
        productivity
        active_outlets
        bill_cuts
        userTargetId
        targetDetailId
        isChildPresent
        ChildUsers {
          id
          user
          role
          ulpo
          tls
          sbd
          dgp
          productivity
          active_outlets
          bill_cuts
          userTargetId
          targetDetailId
          isChildPresent
          ChildUsers {
            id
            user
            role
            ulpo
            tls
            sbd
            dgp
            active_outlets
            productivity
            bill_cuts
            userTargetId
            targetDetailId
            isChildPresent
            ChildUsers {
              id
              user
              role
              ulpo
              tls
              sbd
              dgp
              productivity
              active_outlets
              bill_cuts
              userTargetId
              targetDetailId
              isChildPresent
            }
          }
        }
      }
    }
  }
`;

const UPDATE_USER_FUNDAMENTAL_TARGET_DETAILS = graphql(
  gql`
    mutation UpdateUserFundamentalTargetDetail($input: UpdateUserFundamentalTargetInput!) {
      updateUserFundamentalTargetDetail(input: $input) {
        ulpo
        tls
        sbd
        dgp
        billCuts
        activeOutlets
        productivity
        targetId
        userId
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateUserFundamentalTargetDetail: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  // getUserTargetDetails: GET_USER_COVERAGE_TARGET,
  getUserTargetDetails: GET_USER_TARGET_DETAILS,
};

const mutation = {
  updateUserFundamentalTargetDetail: UPDATE_USER_FUNDAMENTAL_TARGET_DETAILS,
};

export { query, mutation, GET_USER_TARGET_DETAILS, UPDATE_USER_FUNDAMENTAL_TARGET_DETAILS };
