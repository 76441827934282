import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { LIMIT, PAGE } from '../../../../data/enums/GeneralConstants';
import { ORDER_TABS } from '../config';
import { TableHeader } from './tableConfig';

const propTypes = {
  data: PropTypes.shape({
    list: PropTypes.array,
    total: PropTypes.number,
  }),
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onCallRowClick: PropTypes.func.isRequired,
  onHeaderClick: PropTypes.func,
};

const defaultProps = {
  data: {
    list: [],
    total: 0,
    limit: LIMIT,
    page: PAGE,
  },
  activeLine: {
    id: '',
    sellerId: 0,
  },
  serverResponseWaiting: false,
  onHeaderClick: () => null,
};

class Delivered extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedOrders: [],
      activeCall: {
        index: -1,
        value: '',
        sellerId: '',
      },
    };

    this.permission = this.getUserPermission();
  }

  componentDidMount() {
    const { onHeaderClick } = this.props;
    this.resetActiveCall();
    TableHeader({ onHeaderClick, dynamicHeader: true });
  }

  getUserPermission = () => ({ GRN: {} });
  // todo fetch user permission here

  resetActiveCall = () => {
    const { activeCall } = this.state;
    activeCall.index = '';
    activeCall.value = '';
    activeCall.sellerId = '';
    this.setState({ activeCall });
  };

  handleRowClick = (callId, sellerId, refId, orderType) => {
    const { onCallRowClick, data } = this.props;
    const { activeCall } = this.state;
    if (callId !== activeCall.value) {
      const indexInDataList = data.list.findIndex(
        element => element.Distributor.id === sellerId && element.id === callId,
      );
      activeCall.value = callId;
      activeCall.index = indexInDataList;
      this.setState({ activeCall });

      onCallRowClick(callId, sellerId, refId, orderType);
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  handlePageChange = pagination => {
    const { onPageChange } = this.props;
    onPageChange(ORDER_TABS.DELIVERED, pagination);
  };

  render() {
    const { selectedOrders } = this.state;
    const { data, activeLine, serverResponseWaiting, onHeaderClick } = this.props;

    return (
      <Fragment>
        <View
          data={data}
          activeLine={activeLine}
          onPageChange={this.handlePageChange}
          selectedOrders={selectedOrders}
          loading={serverResponseWaiting}
          onRowClick={this.handleRowClick}
          onHeaderClick={onHeaderClick}
        />
      </Fragment>
    );
  }
}

Delivered.propTypes = propTypes;

Delivered.defaultProps = defaultProps;

export default withAlert()(Delivered);
