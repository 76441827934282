const fileParser = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.split(',')[1]);
  reader.onerror = error => reject(error);
});

const csvToArray = (str, delimiter = ',') => {
  const headers = str.slice(0, str.indexOf('\n'))
    .replace(/&/g, '')
    .replace(/</g, '')
    .replace(/"/g, '')
    .split(delimiter);
  const rows = str.slice(str.indexOf('\n') + 1)
    .replace(/&/g, '')
    .replace(/</g, '')
    .replace(/"/g, '')
    .split('\n');
  return (rows.map((row) => {
    const values = row.split(delimiter);
    return (headers.reduce((object, header, index) => {
      object[header] = values[index];
      return object;
    }, {}));
  }));
};

const getCSVData = async event => new Promise((resolve, reject) => {
  if (typeof FileReader !== 'undefined') {
    const myReader = new FileReader();
    myReader.onload = () => {
      const content = myReader.result;
      const csvHeaderMap = csvToArray(content);

      resolve({ list: csvHeaderMap, message: '' });
    };
    myReader.onerror = () => {
      reject({
        list: [],
        message: 'Error while processing CSV file',
      });
    };
    myReader.readAsText(event.files[0]);
  } else {
    reject({
      list: [],
      message: 'This browser does not support HTML5.',
    });
  }
});

export default getCSVData;

export {
  fileParser, getCSVData,
};



