const getEnumFromObject = (object) => Object.freeze({ ...object });

const PROGRAM = getEnumFromObject({
  BILLING_PLATFORM: 'Billing Platform',
  BU_SETTINGS: 'BU Settings',
  CASH: 'Cash',
  CATALOG: 'Create Catalog',
  CHANNEL_CATEGORY: 'Channel/Category',
  CHEQUE: 'Cheque',
  FOCUSED_SKU: 'Focused SKU',
  FUNDAMENTAL_REPORTS: 'Fundamental Reports',
  GRN: 'GRN',
  LEAVE_REQUEST: 'Leave Request',
  MERCHANDISING_DEVICE: 'Merchandising Device',
  OPENING_BALANCE: 'Opening Balance',
  ORDER_FULLFILLMENT: 'Order Fullfillment',
  ORDER_RECEIVE: 'Order Receive',
  OUTLET: 'Outlet',
  OUTLET_RECOMENDATION: 'Outlet Recomendation',
  OUTSTANDING_DUES: 'Outstanding Dues',
  PAYMENT_RECEIVE: 'Payment Recieve',
  POST_DATES_CHEQUE: 'Post Dates Cheque',
  PROMOTION: 'Promotion',
  PURCHASE_RETURN: 'Purchase Return',
  RECEIPT: 'Receipt',
  ROSTER: 'Call Roster',
  ROUTE: 'Route',
  SALES_RETURN: 'Sales Return',
  SBD: 'SBD',
  SBD_FOCUSED_SKU: 'SBD & Focused SKU',
  SKU: 'Create/Upload SKU',
  STOCK_ADJUSTMENT: 'Stock Adjustment',
  STOCK_LEDGER: 'Stock Ledger',
  SUB_D: 'Sub-D',
  TARGET_ACHIEVEMENT: 'Target & Achievment',
  TERRITORY: 'Territory',
  TOWN: 'Town',
  USER_ACCOUNT: 'Create User Account',
  USER_GROUP: 'Create User Group',
  ROUTE_SEGMENTATION: 'Route Segmentation',
  PSR: 'Reports',
  PRODUCTIVITY: 'Reports',
  SUBD_ASSORTMENT: 'Distributor Assortment',
  CHANNEL_ASSORTMENT: 'Channel Assortment',
  SUBD_REPLENISHMENT: 'Replenishment Report',
  USER_CLUSTER: 'User Cluster',
  APPROVAL_REQUEST: 'Approval Request',
  PROMOTIONAL_SKU: 'promotionalsku',
  MT: 'MT',
  RSCORE: 'R-Score',
  STOCK_COUNT: 'Stock Count',
  TRADE:'TRADE',
  DOWNLOAD_USER_ACCOUNT:'User Account',
  DOWNLOAD_USER_HIERARCHY: 'User Hierarchy',
  OUTLET_CHAIN:'Outlet Chain',
  VGP_REPORT:'VGP Audit'
});

const PERMISSION_OBJ = {
  read: true,
  create: true,
  update: true,
  delete: true,
  approve: false,
};
export { PROGRAM, PERMISSION_OBJ };
