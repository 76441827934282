
import { compose } from 'recompose';
import Details from './Details';
import {
  query, mutation, DOWNLOAD_PURCHASE, PRINT_PURCHASE,
} from './API';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';

const ComposedDetails = compose(
  DOWNLOAD_PURCHASE,
  PRINT_PURCHASE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Details);

export default ComposedDetails;
