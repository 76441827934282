import { MT } from '../../../../data/enums/Route';
import { breadCrumb as breadCrumbConfig } from '../config';
import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'MT',
    path: `/${MT}`,
    active: true,
  },
];

const filter = getFilterConfig([FILTER.STATUS]);

export { breadCrumb, filter as filterConfig };
