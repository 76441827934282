import { refGenerator } from '../../../../utils';
import { getCurrentDay } from '../../../../utils/date';
import { FORM_CONFIG } from '../../../../v4/constants/formConfig';

const OPERATION = {
  ADD_ORDER: 'ADD_ORDER',
  NO_ORDER: 'NO_ORDER',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  UNSUCCESSFUL_CALL: 'UNSUCCESSFUL_CALL',
  CALL_ANALYSIS: 'CALL_ANALYSIS',
  EDIT: 'EDIT',
  PROMOTION: 'PROMOTION',
  DELETE: 'DELETE',
  DELETE_PROMOTION:'DELETE_PROMOTION',
  EDIT_PROMOTION:'EDIT_PROMOTION',
};

const formMapper = (element = {}) => (
  {
  id: element.id || 0,
  title: element.title || '',
  promotion: element.promotion || null,
  Rates: element.Rates || [],
  images: element.images || [],
  purchase_order_history: element.purchase_order_history || [],
  pfdtarget: element.pfdtarget || 0,
  quantity: element.quantity || 0,
  familyId: element.skuFamily?.id || element.familyId || 0,
  netAmount: element.netAmount || '-',
  brandId: element.brand?.id || element.brandId || 0,
  isTopSku: element.isTopSku,
  isRecommendedSku: element.isRecommendedSku,
  isSBDSku: element.isSBDSku,
  isFocusedSku: element.isFocusedSku,
  freeSku: element.freeSku || [],
  netSaving: element.netSaving || '-',
  promotionSkus: element?.promotionSkus?.map((ele) => ({
    id: ele.id,
    title: ele.title,
    Rates: ele.Rates,
    images: ele.images || [],
    quantity: ele.quantity || '',
    familyId: ele.skuFamily?.id || ele.familyId || 0,
  })
  ) || null,
  promotionTitle: element.promotionTitle || '',
  promotionId: element.promotionId || '',
  stockavailable:element.stockavailable,
  balance:element.balance||'',
});

const ACTIVE_TAB ={
  SBD_SKU:3,
}

const noOrderFormMapper = (element = {}) => ({
  title: element.title || '',
  reason: element.reason || '',
  message: element.message || '',
  rlp: element.rlp || 0,
  id: element.id || 0,
  promotion: element.promotion || null,
  Rates: element.Rates || [],
  images: element.images || [],
  purchase_order_history: element.purchase_order_history || [],
  pfdtarget: element.pfdtarget || 0,
  quantity: element.quantity || 0,
  familyId: element.skuFamily?.id || element.familyId || 0,
  netAmount: element.netAmount || 0,
  brandId: element.brand?.id || element.brandId || 0,
  isTopSku: element.isTopSku,
  isRecommendedSku: element.isRecommendedSku,
  isSBDSku: element.isSBDSku,
  isFocusedSku: element.isFocusedSku
});

const unSuccessFullFormMapper = (element = {}) => ({
  tas_user_call_plan_id: element.tas_user_call_plan_id || '',
  tasReminder: element.tasReminder || '',
  startDate: element.startDate || getCurrentDay(),
  callTime: {
    callStart: (element?.callTime?.callStart) || '',
    callEnd: (element?.callTime?.callEnd) || ''
  },
  message: element.message,
  remarks: {
    receive: (element.remarks && element.remarks.receive) || '',
    authorize: (element.remarks && element.remarks.authorize) || '',
    sold: (element.remarks && element.remarks.sold) || '',
    call: (element.remarks && element.remarks.call) || '',
    others: (element.remarks && element.remarks.others) || '',
  },
  type: element.type,
  completecall: element.completecall,
  times: {
    hours: (element.times && element.times.hours) || '00',
    minutes: (element.times && element.times.minutes) || '00',
    period: (element.times && element.times.period) || 'AM',
  },
});

const promotionMapper = (element = {}) => (
  {
    promotionSkus: element?.promotionSkus?.map((ele) => ({
      id: ele.id,
      title: ele.title,
      Rates: ele.Rates,
      images: ele.images || [],
      quantity: ele.quantity || '',
      brandTitle:ele?.brand?.title || ele?.brandTitle || {},
      familyId: ele.skuFamily?.id || ele.familyId || 0,
      stockavailable:ele.stockavailable,
      balance:ele.balance||'',
    })
    ) || [],
    promotionTitle: element.promotionTitle || '',
    promotionId: element.promotionId || '',
    id: element.remainingData?.id || 0,
    title: element.remainingData?.title || '',
    promotion: element.remainingData?.promotion || element.promotion,
    Rates: element.remainingData?.Rates || [],
    images: element.remainingData?.images || [],
    purchase_order_history: element.remainingData?.purchase_order_history || [],
    pfdtarget: element.remainingData?.pfdtarget || 0,
    quantity: element.remainingData?.quantity || 0,
    familyId: element.remainingData?.skuFamily?.id || element.familyId || 0,
    netAmount: element.remainingData?.netAmount || '-',
    brandId: element.remainingData?.brand?.id || element.remainingData?.brandId || 0,
    isTopSku: element.remainingData?.isTopSku,
    isRecommendedSku: element.remainingData?.isRecommendedSku,
    isSBDSku: element.remainingData?.isSBDSku,
    isFocusedSku: element.remainingData?.isFocusedSku,
    freeSku: element.freeSku||[],
  });


const formConfig = {
  [OPERATION.ADD_ORDER]: {
    [FORM_CONFIG.TITLE]: '',
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    buttonName: 'Add to Order Pad',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator([])
  },
  [OPERATION.NO_ORDER]: {
    [FORM_CONFIG.TITLE]: 'No Order Reason',
    [FORM_CONFIG.MAPPER]: (element = {}) => (
      noOrderFormMapper(element)),
    buttonName: 'Save',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['marginissue']),
  },
  [OPERATION.UNSUCCESSFUL_CALL]: {
    [FORM_CONFIG.TITLE]: 'Unsuccessful Call',
    [FORM_CONFIG.MAPPER]: (element = {}) => (
      unSuccessFullFormMapper(element)),
    buttonName: 'Save',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator([])
  },
  [OPERATION.CALL_ANALYSIS]: {
    [FORM_CONFIG.TITLE]: 'Call Analysis',
    [FORM_CONFIG.MAPPER]: (element = {}) => (
      noOrderFormMapper(element)),
    buttonName: 'Submit'
  },
  [OPERATION.EDIT]: {
    [FORM_CONFIG.TITLE]: 'Edit SKU Quantity',
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    buttonName: 'Save',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator([])
  },
  [OPERATION.PROMOTION]: {
    [FORM_CONFIG.TITLE]: 'Promotion',
    [FORM_CONFIG.MAPPER]: (element = {}) => (
      promotionMapper(element)),
    buttonName: 'Add to Order',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator([])
  },
  [OPERATION.DELETE_PROMOTION]: {
    [FORM_CONFIG.TITLE]: 'Confirm',
    [FORM_CONFIG.MAPPER]: (element = {}) => (
      promotionMapper(element)),
    buttonName: 'Ok',
  },
  [OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'Confirm',
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
    buttonName: 'Ok',
  },
  [OPERATION.EDIT_PROMOTION]:{
    [FORM_CONFIG.TITLE]: 'Promotion',
    [FORM_CONFIG.MAPPER]: (element = {}) => (
      promotionMapper(element)),
    buttonName: 'Add to Order Pad',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator([])
  }
};

export {
  formConfig, OPERATION,ACTIVE_TAB
};
