import styled from 'styled-components';

const DetailStyled = styled.div`
  .section-panel{
    .section-content.section-tab.table-present {
      .table-wrap{
       height: calc(100vh - 308px);
      }
    }
  }
`;

export default DetailStyled;
