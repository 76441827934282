import React, { useEffect, useState } from 'react';
import PageHeader from '../../../../base/PageHeader';
import View from './View';
import { title, breadCrumbConfig, get, filterConfig, formConfig, filterPermission } from './config';
import SubDDetailsStyled from './SubDDetailsStyled';
import { PanelStyled } from '../../../../common/configuration';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as queryService from '../../../../base/query.service';
import withAlert from '../../../../../utils/composition/withAlert';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../../../common/HelperFunctions';
import { storageKeys } from '../../../../sales/orderProcessing/config';
import { DOMAIN } from '../../../../../data/enums/config';
import { clone } from '../../../../../utils/objectProcessor';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../../utils/api';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';
import { DialogFormWrapper } from '../../../../common';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import Form from './Form';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';

const propTypes = {
  getDistributors: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  updateOutletFundamentalTargetDetail: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const SubDDetails = props => {
  const [data, setData] = useState({ list: [], total: 0 });
  const [queryParameters, setQueryParameters] = useState({
    pagination: queryService.baseQueryParameters.pagination,
    filter: queryService.baseQueryParameters.filter,
    date: { ...queryService.baseQueryParameters.date },
    search: queryService.baseQueryParameters.search,
  });
  const filterConfigMenu = clone(filterConfig.menu);
  const [filterMenu, setFilterMenu] = useState(filterConfigMenu);
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });
  const { serverResponseWaiting, displayAlert, getOutletTargetDetails, updateOutletFundamentalTargetDetail } = props;

  const location = useLocation();
  const targetDetail = get('outletTarget');

  const { id } = useParams();

  const loadTableData = () => {
    const offset = getOffsetFromPagination(queryParameters.pagination);
    setFilterStateInStorage();

    getOutletTargetDetails(
      {
        limit: queryParameters.pagination.limit,
        offset,
        getOutletTargetDetailsId: targetDetail?.id,
        distributorId: +id,
        filter: {
          queryString: queryParameters.search,
          filters: apiFilterProcessor(queryParameters.filter),
        },
      },
      {
        handleSuccess: res => {
          const response = res?.data?.getOutletTargetDetails;

          setData({
            list: response ? response.rows : [],
            total: response ? response.count : 0,
          });
        },
        handleError: error => {
          onAPIRequestFailure(error);
        },
      },
    );
  };

  const setParameters = queryParams => setQueryParameters({ ...queryParams });

  const basePaginationService = new queryService.QueryClass(setParameters, () => queryParameters, loadTableData);

  const setFilterStateInStorage = () => {
    const { filter, date, search } = queryParameters;
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.OFT,
    );
  };
  const getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.OFT);
    const newFilterConfig = {
      ...(localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      }),
    };
    if (!localData) {
      return loadTableData();
    }
    setFilterMenu(newFilterConfig.filterMenu);
    setQueryParameters({
      ...queryParameters,
      filter: newFilterConfig.filter,
      searchText: newFilterConfig.searchText,
      date: newFilterConfig.date,
    });
    loadTableData();
  };

  useEffect(() => {
    getFilterStateFromStorage();
  }, []);

  useEffect(() => {
    setData({
      list: [],
    });
    loadTableData();
  }, [queryParameters]);

  const handleIconClick = (type, element) => {
    setDialog({ type, element });
  };

  const onDialogCancel = () => {
    setDialog({
      type: '',
      element: '',
    });
  };

  const handleButtonSubmit = (type, dialogData) => {
    const { bill_cuts, id, sbd, tls, ulpo, dgp, sales } = dialogData;

    updateOutletFundamentalTargetDetail(
      {
        input: {
          ulpo: +ulpo,
          tls: +tls,
          sbd: +sbd,
          dgp: +dgp,
          sales: +sales,
          billCuts: +bill_cuts,
          outletId: dialog?.element?.outlet_id,
          lineId: dialog?.element?.line_id,
          targetId: targetDetail?.id,
        },
      },
      {
        handleSuccess: res => {
          loadTableData();
          displayAlert(ALERT_TYPE.SUCCESS, `${MESSAGE_EVENT_OPERATION.UPDATE}`);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  const onAPIRequestFailure = error => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  return (
    <>
      <SubDDetailsStyled>
        {dialog?.type && (
          <div className="targets-wrapper">
            <DialogFormWrapper
              type={dialog?.type}
              dialogElement={dialog.element}
              formConfig={formConfig[dialog?.type]}
              onDialogCancel={onDialogCancel}
              onDialogSubmit={handleButtonSubmit}
              formTitle={dialog?.element?.outlet}
              submitClickedControl
              renderDialog={({ dialogData, enableErrorDisplay, handleInputChange, refsObj, handleDateChange }) => (
                <>
                  {(dialog?.type === EVENT_OPERATION.UPDATE || dialog?.type === EVENT_OPERATION.READ) && (
                    <>
                      <Form
                        show
                        type={dialog?.type}
                        refsObj={refsObj}
                        data={dialogData}
                        enableErrorDisplay={enableErrorDisplay}
                        handleInputChange={handleInputChange}
                        // brandDialogData={brandDialogData}
                        handleDateChange={handleDateChange}
                        targetDetail={targetDetail}
                      />
                    </>
                  )}
                </>
              )}
            />
          </div>
        )}

        <div className="section-header">
          <PanelStyled>
            <PageHeader
              breadCrumb={breadCrumbConfig}
              config={{
                title: location?.state?.subD_name ? location?.state?.subD_name : null,
                search: true,
                filter: true,
              }}
              filter={{
                // date: queryParameters.date,
                menuList: filterMenu,
                onFilterChange: basePaginationService.handleFilterChange,
              }}
              handleSearchChange={basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>

        <div className="section-content table-present">
          <View
            data={data}
            loading={serverResponseWaiting}
            handleIconClick={handleIconClick}
            pagination={queryParameters.pagination}
            onPageChange={basePaginationService.onPageSelect}
          />
        </div>
      </SubDDetailsStyled>
    </>
  );
};

SubDDetails.propTypes = propTypes;
SubDDetails.defaultProps = defaultProps;

export default withAlert()(SubDDetails);
