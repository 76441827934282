import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { configuration as configurationRoute } from '../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';

const breadCrumb = [configurationRoute];

const Title = 'BU';

const dateFormat = [
  {
    id: 'mm/dd/yy',
    title: 'MM/DD/YY',
  },
  {
    id: 'dd/mm/yy',
    title: 'DD/MM/YY',
  },
  {
    id: 'yy/mm/dd',
    title: 'YY/MM/DD',
  },
];

const dateType = [
  {
    id: 'NEPALI',
    title: 'BS',
  },
  {
    id: 'ENGLISH',
    title: 'AD',
  },
];

const responseToState = ({ ...response }) => {
  const {
    ico,
    currency,
    fiscalYear,
    unitsOfMeasurement,
    dateSettings,
    orderForMultipleDistributors,
    targetDimension,
    geoFencing,
    pfd,
    brandDistributionCoverageTarget,
    outletCoverageTarget,
    multipleDseInRoute,
    payment,
    srn,
    orderStatus,
    targets,
    sbd,
    focusedSKU,
    skuAssortment,
    routeSegmentation,
    stockMovementRule,
    sapIntegration,
    smsIntegration,
    smsforOrderIntegration,
    callRoster,
    brand_wise_order,
    fundamental_targets
  } = response;

  return {
    ico,
    currency,
    fiscalYear,
    unitsOfMeasurement,
    dateSettings,
    orderForMultipleDistributors,
    targetDimension,
    geoFencing,
    pfd,
    brandDistributionCoverageTarget,
    outletCoverageTarget,
    multipleDseInRoute,
    payment,
    srn,
    skuAssortment,
    // show menu if either of one is configured.
    skuAssortmentMenu: skuAssortment.channel || skuAssortment.distributorGroup,
    orderStatus: {
      received: orderStatus ? orderStatus.received : false,
      invoiced: orderStatus ? orderStatus.invoiced : false,
      dispatched: orderStatus ? orderStatus.dispatched : false,
      delivered: orderStatus ? orderStatus.delivered : false,
    },
    targets: {
      salesTeamWise: targets ? targets.salesTeamWise : false,
      subDWise: targets ? targets.subDWise : false,
      brandWise: targets ? targets.brandWise : false,
    },
    sbd,
    focusedSKU,
    routeSegmentation,
    stockMovementRule,
    sapIntegration,
    smsIntegration,
    smsforOrderIntegration,
    callRoster,
    brand_wise_order: {
      status:brand_wise_order?brand_wise_order.status:false,
      skipped_phone_numbers:brand_wise_order?brand_wise_order.skipped_phone_numbers:[],
      distributor_ids:brand_wise_order?brand_wise_order.distributor_ids:[]
    },
    fundamental_targets
  };
};

const targetDimension = ['VALUE', 'VOLUME'];

const formConfig = {
  mapper: (element) => ({
    id: element.id || '',
    title: element.title || '',
    territoryId: (element.Territory ? element.Territory.id : element.territoryId) || '',
  }),
  validationField: ['title', 'territoryId'],
  validationRequired: true,
};

const crudRequest = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({ title: data.title, territoryId: Number(data.territoryId) }),
    responseName: 'createTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
      territoryId: Number(data.territoryId),
    }),
    responseName: 'updateTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'deleteTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'updateTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};

export {
  crudRequest,
  breadCrumb as breadCrumbConfig,
  formConfig,
  dateFormat,
  dateType,
  responseToState,
  targetDimension,
};
