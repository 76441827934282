import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CoverageWrapper from './CoverageWrapper';
import { TableBody, TableHeader } from './tableConfig';
import PageHeader from '../../../base/PageHeader';
import { dataProps } from '../../../../data/enums/Component';
import { PanelStyled } from '../../../common/configuration/index';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { UPLOADABLES } from '../../../../data/enums/GraphQL';

const propTypes = {
  data: dataProps.value,
  onIconClick: PropTypes.func,
  breadCrumb: PropTypes.arrayOf(Object),
  onCSVUpload: PropTypes.func,
  onTableBodyClick: PropTypes.func,
  onHandleBrandDialog: PropTypes.func.isRequired,
  handleIconClick: PropTypes.func,
};

const defaultProps = {
  breadCrumb: [],
  onIconClick: () => null,
  onCSVUpload: () => null,
  data: dataProps.defaultValue,
  onTableBodyClick: () => null,
  handleIconClick: () => null,
};

const View = ({ ...props }) => {
  const { data, breadCrumb, onTableBodyClick, onCSVUpload, onIconClick, handleIconClick } = props;

  return (
    <Fragment>
      <div className='section-header'>
        <PanelStyled>
          <PageHeader
            display={{}}
            breadCrumb={breadCrumb}
            config={{
              title: 'Active Coverage Target',
              create: true,
              download: false,
              filter: false,
              upload: true,
              date: false,
            }}
            handleUploadClick={() => {
              onCSVUpload(UPLOADABLES.OUTLETCOVERAGETARGET);
            }}
            handleDownloadClick={() => {}}
            handleCreateClick={handleIconClick}
          />
        </PanelStyled>
      </div>
      <div className='section-content'>
        <CoverageWrapper>
          <div className='table-wrap'>
            <table>
              <TableHeader />
              <tbody>
                {data.list.map((targetData) => (
                  <TableBody
                    key={targetData.id}
                    data={targetData}
                    onTableBodyClick={onTableBodyClick}
                    onIconClick={onIconClick}
                    handleIconClick={handleIconClick}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </CoverageWrapper>
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
