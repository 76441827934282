import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const CREATE_PURCHASE_ORDER = graphql(
  gql`
  mutation createPurchaseOrder($input: MTPurchaseOrderInput!) {
    createPurchaseOrder(input: $input) {
      id
      purchaseOrderDate
      Channel {
        title
      }
      Order {
        id
        prId
        buId
        skuId
        SKU {
          title
        }
        brandId
        Brand {
          title
        }
        rateDetails {
          rlp
          rlpWithVat
        }
        quantity
        quantityInCases
        amountDetails {
          grossAmount
          taxableAmount
          taxAmount
          netAmount
        }
      }
    }
  }
  `, {
    props: ({ mutate }) => ({
      createPurchaseOrder: variables => mutate({
        variables,
      }),
    }),
  },
);

const mutation = {
  createPurchaseOrder: CREATE_PURCHASE_ORDER,
};

export { mutation, CREATE_PURCHASE_ORDER };
