import styled from 'styled-components';

const SettingStyled = styled.div`
  .settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 85px;
    &__card {
      width: 420px;
      width:35%;
      margin-bottom: 64px;
      .icon {
          color: #6b6c7e;
          width: 16px;
          height: 16px;
          position: relative;
      }
      &--header {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 32px;
        .icon {
          top: -4px;
        }
        .main-title {
          display: block;
          font-size: 14px;
          line-height: 17.5px;
          color: #272833;
          padding-bottom: 6px;
          border-bottom: 1px solid #cdced9;
          margin-left: 25px;
          flex: 1;
          font-weight: 600;
        }
      }
      &--body {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        li {
          box-shadow: 0 2px 4px 0 rgba(39, 40, 51, 0.12);
          cursor: pointer;
          width: 90%;
          padding: 14px 24px;
          margin-bottom: 16px;
          display: flex;
          &:last-child{
            margin-bottom:0;
          }
          .title {
            flex: 1;
            font-size:14px;
            line-height:21px;
            font-weight:400;
          }
          .icon {
            left:4px;
          }
        }
      }
    }
  }
`;
export default SettingStyled;
