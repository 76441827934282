import React from 'react';
import { LegendStyled } from './LegendStyled';

const CustomLegend = ({ data }) => {
  return (
    <>
      <LegendStyled>
        <div className="legend-wrap">
          {data?.map(item => (
            <div className="legend-title">
              <svg height="12px" width="12px">
                <circle cx="6" cy="6" fill={item?.color} r="6" />
              </svg>
              <span>{item?.title}</span>
            </div>
          ))}
        </div>
      </LegendStyled>
    </>
  );
};

export default CustomLegend;
