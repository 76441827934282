import { compose } from 'recompose';
import PurchaseRequisition from './PurchaseRequisition';
import {
  query, mutation, UPDATE_PURCHASE_REQUISITION, CREATE_PURCHASE_REQUISITION,
} from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposetPurchaseRequisition = compose(
  UPDATE_PURCHASE_REQUISITION,
  CREATE_PURCHASE_REQUISITION,
  withRequestTracker({
    query,
    mutation,
  }),
)(PurchaseRequisition);

export default ComposetPurchaseRequisition;
