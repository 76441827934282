import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Search,
  Switch,
  Collapsible,
} from '../../../../../../../../components';
import SBDStyle from '../SBDStyle';

const propTypes = {
  handleSwitchChange: PropTypes.func.isRequired,
  onProductCategoryClick: PropTypes.func.isRequired,
  productCategoryList: PropTypes.func.isRequired,
  onHandleSearchInput: PropTypes.func.isRequired,
  brandList: PropTypes.arrayOf(PropTypes.object),
  onBrandClick: PropTypes.func.isRequired,
  SKUFamilyList: PropTypes.arrayOf(PropTypes.object),
  cardId: PropTypes.num,
  searchText: PropTypes.string.isRequired,
  update: PropTypes.PropTypes.shape({
    status: PropTypes.bool,
    type: PropTypes.string,
  }),
  count: PropTypes.shape({
    brandCount: PropTypes.array,
    categoryCount: PropTypes.array,
  }),
};
const defaultProps = {
  brandList: [],
  SKUFamilyList: [],
  update: {
    status: false,
    type: 'CREATE',
  },
};

const SBDDistribution = (props) => {
  const {
    brandList,
    cardId,
    onProductCategoryClick,
    onBrandClick,
    SKUFamilyList,
    count,
    onHandleSearchInput,
    searchText,
    handleSwitchChange,
    productCategoryList,
    update,
  } = props;
  const { productCategoryId, brandId } = cardId;
  const { brandCount, categoryCount } = count;
  const getCount = (countItems, id, key, type) => {
    const countItem = countItems.find(item => item[key] === id) || {};
    if (type === 'badge') {
      return countItem.count ? <Badge light badge label={countItem.count} className="badge" /> : '';
    }
    return countItem.count ? countItem.count : 0;
  };
  return (
    <div className="create-ui-inner tab-less-height">
      <section className="assortment-detail">
        <div className="select-section-wrap">
          <SBDStyle>
            <div className="panel-section">
              <div className="panel one">
                <p className="p-title">
Product Category
                </p>
                <ul>
                  {productCategoryList.map(group => (
                    <li
                      key={group.id}
                      onClick={() => onProductCategoryClick(group.id)}
                      className={
                    parseInt(productCategoryId, 10) === group.id
                      ? 'active'
                      : 'inactive'
                  }
                      title={group.title}
                    >
                      <p>{group.title}</p>
                      {getCount(categoryCount, group.id, 'id', 'badge')}
                    </li>
                  ))}
                </ul>
              </div>
              <>
              {productCategoryId && (
              <div className="panel two">
                <p className="p-title">Brands</p>
                <ul>
                  {brandList.map(brand => (
                    <li
                      key={brand.id}
                      onClick={() => onBrandClick(brand.id)}
                      className={
                      parseInt(cardId.brandId, 10) === brand.id
                        ? 'active'
                        : 'inactive'
                    }
                      title={brand.title}
                    >
                      <p>{brand.title}</p>
                      {getCount(brandCount,  brand.id, 'id', 'badge')}
                    </li>
                  ))}
                </ul>
              </div>
              )}
              {brandId && (
            <div className="panel three">
              <div className="panel-title">
                <p className="p-title">SKU Family / SKUs</p>
                <Search
                  searchText={searchText}
                  clearText={!searchText}
                  handleSearchInput={onHandleSearchInput}
                />
              </div>
              <div className="div-thead">
                <span>SKU</span>
                <div className="status">
                  <span>Status</span>
                  {/* <Switch
                             checked={
                               SKUFamilyList.filter(a => a.status === true).length === SKUFamilyList.length}
                             onToggle={(checked, e) => {
                               e.stopPropagation();
                               handleSwitchChange(
                                 {
                                   formattedValue: !!checked,
                                   target: {
                                     name: 'status',
                                     type: 'boolean',
                                     value: !!checked,
                                   },
                                 },
                                 'grandParent', ['SKUFamilyList']
                               );
                             }}
                          /> */}
                </div>
              </div>
              <div className="div-tbody">
                {SKUFamilyList.map((skuFamily,i) => (
                  <Collapsible
                    key={skuFamily.id}
                    trigger={
                      <>
                        <span>
                          <span className="text">
                            {skuFamily.title}
                          </span>
                        </span>
                        <div onClick={e => e.stopPropagation()}>
                        <Switch
                         className={!update.status ? 'disabled' : null}
                             checked={skuFamily.skus.filter(a => a.status === true).length === skuFamily.skus.length}
                             onToggle={(checked, e) => {
                               e.stopPropagation();
                               handleSwitchChange(
                                 {
                                   formattedValue: !!checked,
                                   target: {
                                     name: 'status',
                                     type: 'boolean',
                                     value: !!checked,
                                   },
                                 },
                                 'parent', ['SKUFamilyList', `${i}`,'status'],skuFamily.id,0,checked,
                               );
                             }}
                          />
                        </div>
                      </>
                    }
                  >
                    {skuFamily.skus.map((sku,j) => (
                      <div className="collapsible-inner" key={sku.id}>
                        <span>{sku.title}</span>
                        <Switch
                         className={!update.status ? 'disabled' : null}
                          checked={sku.status}
                          onToggle={(checked) => {
                            handleSwitchChange(
                              {
                                formattedValue: !!checked,
                                target: {
                                  name: 'status',
                                  type: 'boolean',
                                  value: !!checked,
                                },
                              },
                              'child', ['SKUFamilyList', `${i}`, 'skus', `${j}`, 'status'],skuFamily.id,sku.id,checked
                            );
                          }}
                        />
                      </div>
                    ))}
                  </Collapsible>
                ))}
              </div>
            </div>
          )}
          </>
            </div>
          </SBDStyle>
        </div>
      </section>
    </div>

  );
};

SBDDistribution.propTypes = propTypes;
SBDDistribution.defaultProps = defaultProps;

export default SBDDistribution;
