import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BreadCrumb, Button, Col, CustomSelect, Input, Row,
} from '../../../../../components';
import { PanelHeader, PanelStyled } from '../../../../common/configuration';
import { breadCrumbConfig, formConfig, title } from './config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
import View from './View';
import { DialogFormWrapper } from '../../../../common';
import Form from '../Form';
import { isConfirmationType } from '../../../../common/HelperFunctions';
import confirmationGenerator from '../../../../common/DialogConfirmation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import history from '../../../../../utils/history';
import { VISIBILITY_AUDIT } from '../../../../../data/enums/Route';

const initialDialogState = {
  title: '',
  frequency: '',
  assignedTo: '',
};

const VisibilityAuditDetail = (props) => {
  const { id } = props.match.params;
  const {
    serverResponseWaiting,
    getRouteVisitFrequencyList,
    getUsersList,
    displayAlert,
    getVisibilityAuditbasicbyId,
    getCatalogDetailsHierarchy,
    createVisibilityAuditDetails,
    deleteVisibilityAuditDetail,
    editVisibilityAudit,
    deleteVisibilityAudit,
  } = props;
  const [frequency, setFrequency] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [basicDetail, setBasicDetail] = useState(initialDialogState);
  const [catalogDetailsHierarchy, setCatalogDetailsHierarchy] = useState([]);
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });

  const [dialogData, setDialogData] = useState({
    ...initialDialogState,
  });

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
  };

  const handleInputChange = (e) => {
    setDialogData({ ...dialogData, [e.target.name]: e.target.value });
  };

  const handleDropDownChange = (value, label) => {
    const dialogDataClone = { ...dialogData };
    dialogDataClone[label] = value;
    setDialogData({ ...dialogDataClone });
  };

  const handleFormChange = (e) => {
    setBasicDetail({ [e.target.name]: e.target.value });
  };

  const getValuesForVisibilityAudit = () => {
    getCatalogDetailsHierarchy({ mtvisibilityAuditId: Number(id) }, {
      handleSuccess: (res) => {
        const { data: { getvaluesForVisibilityAudit } } = res;
        setCatalogDetailsHierarchy(getvaluesForVisibilityAudit);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  const handleToggle = (type, status, value, parent) => {
    const data = [];
    switch (type) {
      case 'principal':
        if (status) {
          catalogDetailsHierarchy
            .find(item => item.id === value.id)
            .childCategory
            .forEach((productCategory) => {
              productCategory.childBrands.forEach(brand => (data.push({
                visibility_audit_id: Number(id),
                principal_id: value.id,
                product_category_id: productCategory.id,
                brand_id: brand.id,
                active: status,
              })));
            });
        } else {
          catalogDetailsHierarchy
            .find(item => item.id === value.id)
            .childCategory
            .forEach((productCategory) => {
              productCategory.childBrands.forEach(brand => (data.push(
                brand.id,
              )));
            });
        }

        break;

      case 'productCategory':
        if (status) {
          catalogDetailsHierarchy.find(item => item.id === parent.principal)
            .childCategory
            .find(item => item.id === value.id)
            .childBrands.forEach(brand => (
              data.push({
                visibility_audit_id: Number(id),
                principal_id: parent.principal,
                product_category_id: value.id,
                brand_id: brand.id,
                active: status,
              })));
        } else {
          catalogDetailsHierarchy.find(item => item.id === parent.principal)
            .childCategory
            .find(item => item.id === value.id)
            .childBrands.forEach(brand => (
              data.push(brand.id)));
        }
        break;

      case 'brand':
        if (status) {
          data.push({
            visibility_audit_id: Number(id),
            principal_id: value.principal_id,
            product_category_id: value.product_category_id,
            brand_id: value.id,
            active: status,
          });
        } else {
          data.push(value.id);
        }
        break;

      default:
        break;
    }

    if (status) {
      createVisibilityAuditDetails({ input: data }, {
        handleSuccess: () => {
          getValuesForVisibilityAudit();
          displayAlert(ALERT_TYPE.SUCCESS, `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    } else {
      deleteVisibilityAuditDetail({ visibilityAuditId: Number(id), brandId: data }, {
        handleSuccess: () => {
          getValuesForVisibilityAudit();
          displayAlert(ALERT_TYPE.SUCCESS, `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    }
  };

  const handleError = (err) => {
    displayAlert(ALERT_TYPE.DANGER, err);
  };


  const getBasicDetail = useCallback(() => {
    getVisibilityAuditbasicbyId({ mtvisibilityAuditId: Number(id) }, {
      handleSuccess: (res) => {
        const { data: { getVisibilityAuditbasicbyId: detail } } = res;
        if (detail) {
          setBasicDetail({
            title: detail.title.trim(),
            frequency: Number(detail.count_days),
            assignedTo: Number(detail.assigned_to),
          });
          setDialogData({
            title: detail.title.trim(),
            frequency: Number(detail.count_days),
            assignedTo: Number(detail.assigned_to),
          });
        }
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  });

  const loadFrequenciesAndUsers = useCallback(() => {
    getRouteVisitFrequencyList({}, {
      handleSuccess: (res) => {
        const { data: { routeVisitFrequencyList } } = res;
        const options = routeVisitFrequencyList
          .rows
          .map(row => ({ id: row.numberOfDays, label: row.label }));
        setFrequency([...options]);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });

    getUsersList({
      offset: 0,
      limit: 500,
      filter: {},
    }, {
      handleSuccess: (res) => {
        const { data: { users } } = res;
        setAssignedTo(users.rows);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }, []);

  useEffect(() => {
    loadFrequenciesAndUsers();
    getBasicDetail();
    getValuesForVisibilityAudit();
  }, [id]);

  const handleDialogSubmit = (type, data) => {
    switch (type) {
      case EVENT_OPERATION.UPDATE:
        editVisibilityAudit({
          id: Number(id),
          input: {
            title: dialogData.title.trim(),
            assigned_to: dialogData.assignedTo,
            count_days: dialogData.frequency,
            active: true,
          },
        }, {
          handleSuccess: () => {
            getBasicDetail();
            setDialogData(initialDialogState);
            displayAlert(ALERT_TYPE.SUCCESS, `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            setDialog({ type: '' });
          },
          handleError: (error) => {
            handleError(error);
          },
        });
        break;

      case EVENT_OPERATION.DELETE:
        deleteVisibilityAudit({ id: Number(id) },
          {
            handleSuccess: () => {
              history.push(`/${VISIBILITY_AUDIT}`);
            },
            handleError: (error) => {
              handleError(error);
            },
          });
        break;

      default:
        break;
    }
  };


  const handleIconClick = (action) => {
    setDialog({
      type: action,
      element: dialogData,
    });
  };
  return (
    <div className="section-header">
      <PanelStyled>
        <BreadCrumb list={breadCrumbConfig} />
        <PanelHeader>
          <div className="flex items-center justify-between m-0 flex-1">
            <h2>{title}</h2>
            <div className="flex m-0">
              <Button
                secondary
                iconBtnSmall
                onClick={() => handleIconClick(EVENT_OPERATION.UPDATE)}
                iconName="pencil"
              />
              <Button
                secondary
                iconBtnSmall
                onClick={() => handleIconClick(EVENT_OPERATION.DELETE)}
                iconName="trash"
              />
            </div>
          </div>
        </PanelHeader>
      </PanelStyled>
      <div className="section-content pad-48">
        <Row className="select-section disabled">
          <Col md={6}>
            <Input name="title" type="text" placeholder="Title" labelContent="Title" value={basicDetail.title} onChange={handleFormChange} />
          </Col>
          <Col md={3}>
            <CustomSelect
              labelContent="Frequency (in days)"
              onChange={() => { }}
              className="custom-select"
              placeholder="Frequency"
              getOptionValue={({ id }) => id}
              getOptionLabel={({ label }) => label}
              options={frequency}
              name="frequency"
              value={frequency.length && frequency.filter(item => item.id === basicDetail.frequency)}
            />
          </Col>
          <Col md={3}>
            <CustomSelect
              onChange={() => { }}
              labelContent="Assign"
              className="custom-select"
              placeholder="Assign"
              getOptionValue={({ id }) => id}
              getOptionLabel={({ fullName }) => fullName}
              options={assignedTo}
              name="assignedTo"
              value={assignedTo.length && assignedTo.filter(item => item.id === basicDetail.assignedTo)}
            />
          </Col>
        </Row>
        <View data={catalogDetailsHierarchy} onToggle={handleToggle} />
      </div>
      <div className="section-content table-present">
        {dialog.type && (
          <DialogFormWrapper
            formConfig={formConfig[dialog.type]}
            dialogElement={dialog.element}
            onDialogSubmit={handleDialogSubmit}
            onDialogCancel={resetDialog}
            type={dialog.type}
            renderDialog={({ refsObj, enableErrorDisplay }) => (
              <>

                {(dialog.type === EVENT_OPERATION.UPDATE
                  || dialog.type === EVENT_OPERATION.CREATE) && (
                    <Form
                      data={dialogData}
                      refsObj={refsObj}
                      loading={serverResponseWaiting}
                      handleInputChange={event => handleInputChange(event)}
                      enableErrorDisplay={enableErrorDisplay}
                      handleDropDownChange={(value, label) => handleDropDownChange(value, label)
                      }
                      frequency={frequency}
                      assignedTo={assignedTo}
                    />)}
                {isConfirmationType(dialog.type)
                  && confirmationGenerator(dialog.type)
                }
              </>
            )}
          />
        )}
      </div>
    </div>
  );
};

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getRouteVisitFrequencyList: PropTypes.func,
  getUsersList: PropTypes.func,
  displayAlert: PropTypes.func,
  getVisibilityAuditbasicbyId: PropTypes.func.isRequired,
  getCatalogDetailsHierarchy: PropTypes.func.isRequired,
  createVisibilityAuditDetails: PropTypes.func.isRequired,
  deleteVisibilityAuditDetail: PropTypes.func.isRequired,
  editVisibilityAudit: PropTypes.func.isRequired,
  deleteVisibilityAudit: PropTypes.func.isRequired,
};


const defaultProps = {
  serverResponseWaiting: false,
  getRouteVisitFrequencyList: () => { },
  getUsersList: () => { },
  displayAlert: () => { },
};

VisibilityAuditDetail.propTypes = propTypes;
VisibilityAuditDetail.defaultProps = defaultProps;

export default withAlert()(VisibilityAuditDetail);
