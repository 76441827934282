import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReplenishmentModelView from './View';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import withAlert from '../../../../../../utils/composition/withAlert';
import {  debouncer } from '../../../../../../utils/formHandlers';
import { clone, updateObjectAttribute } from '../../../../../../utils/objectProcessor';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  getReplenishmentGroupDetail: PropTypes.func.isRequired,
  updateReplenishmentModel: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class ReplenishmentModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        stockDaysHolding:{
          powerSKU: '',
          nonPowerSKU: ''
        },
        availabilityDays: '',
        averageDays: '',
        sufficiencyDays: {
          max: '',
          min: '',
        },
        sufficiencyMatrix: {
          under: '',
          ideal: '',
          over: '',
        },
        sufficiencyWeightage: {
          availability: '',
          sufficiency: '',
          overStock: '',
        },
        repeatedTimes: {
          daily: true,
          weekly: false,
          fortnightly: false,
          trimonthly: false,
          monthly: false
        },
        times: {
          hours: '00',
          minutes: '00',
          period: 'AM',
        },
        dayOfTheMonth: 1,
        repeatDays: [],
        repeatDates: []
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  checkIfIntOrFloatOrString = (data, entity) => {
    if (entity === 'format') {
      return data === '' ? 0 : data;
    }
    return data === 0 ? '' : data;
  };

  formattedData = (data, entity) => 
  (
    {
    stockDaysHolding:{
      powerSKU:this.checkIfIntOrFloatOrString(data.stockDaysHolding?data.stockDaysHolding.powerSKU:0, entity),
      nonPowerSKU:this.checkIfIntOrFloatOrString(data.stockDaysHolding?data.stockDaysHolding.nonPowerSKU:0, entity)
    },
    availabilityDays: this.checkIfIntOrFloatOrString(data.availabilityDays, entity),
    averageDays: this.checkIfIntOrFloatOrString(data.averageDays, entity),
    sufficiencyMatrix: {
      under: this.checkIfIntOrFloatOrString(data.sufficiencyMatrix?data.sufficiencyMatrix.under:0, entity),
      ideal: this.checkIfIntOrFloatOrString(data.sufficiencyMatrix?data.sufficiencyMatrix.ideal:0, entity),
      over: this.checkIfIntOrFloatOrString(data.sufficiencyMatrix?data.sufficiencyMatrix.over:0, entity),
    },
    sufficiencyWeightage: {
      availability: this.checkIfIntOrFloatOrString(data.sufficiencyWeightage?data.sufficiencyWeightage.availability:0, entity),
      sufficiency: this.checkIfIntOrFloatOrString(data.sufficiencyWeightage?data.sufficiencyWeightage.sufficiency:0, entity),
      overStock: this.checkIfIntOrFloatOrString(data.sufficiencyWeightage?data.sufficiencyWeightage.overStock:0, entity),
    },
    sufficiencyDays: {
      max: this.checkIfIntOrFloatOrString(data.sufficiencyDays ? data.sufficiencyDays.max : 0, entity),
      min: this.checkIfIntOrFloatOrString(data.sufficiencyDays ? data.sufficiencyDays.min : 0, entity),
    },
    repeatDays: data.repeatDays || [],
    repeatedTimes: data.repeatedTimes || {
      daily: true,
      weekly: false,
      fortnightly: false,
      trimonthly: false,
      monthly: false
    },
    times: data.times || {
      hours: '00',
      minutes: '00',
      period: 'AM',
    },
    repeatDays: data.repeatDays || [],
    repeatDates: data.repeatDates || [],
    dayOfTheMonth: data.dayOfTheMonth || 1,
  }
  
  );




 inputChange = (data, event, firstParam = '', paramList = []) => {
  const details = clone(data);
  const { checked, name, value } = event.target;
  const eventValue = event.formattedValue || value;

  if (!checked && firstParam === 'repeatDays' && paramList.length === 0) {
    details.repeatDays = details.repeatDays.filter(d => d !== value).filter(d => d !== null)
    return details;
  }

  if (!firstParam) {
    details[name] = eventValue;
    return details;
  }

  if (paramList.length === 0) {
    details[firstParam][name] = eventValue;
    return details;
  }
  
  return updateObjectAttribute(details, paramList, eventValue);
}
  loadData = () => {
    const { getReplenishmentGroupDetail, groupId } = this.props;
    const { data } = this.state;
    getReplenishmentGroupDetail(
      {
        id: groupId,
      },
      {
        handleSuccess: response => {

          const frequencyConfig = {
            date:null, 
            day:null, 
            time:'10:00 PM', 
            type:'DAILY',
            dayOfTheMonth:1
          }
          
      
          const {date, day, time, type,dayOfTheMonth}=response.data.getReplenishmentGroupDetail.replenishmentFrequency===null?frequencyConfig:response.data.getReplenishmentGroupDetail.replenishmentFrequency;
      
          const replenishmentConfig = response.data.getReplenishmentGroupDetail.replenishmentConfig ;
          const restructredFrequencyData = {
            repeatedTimes: {
              daily: false,
              weekly: false,
              fortnightly: false,
              trimonthly: false,
              monthly: false
            },
            times: {
              hours: time.split(":")[0],
              minutes: (time.split(":")[1]).split(" ")[0],
              period: (time.split(":")[1]).split(" ")[1],
            },
            repeatDays: day || [],
            repeatDates: date || [],
            dayOfTheMonth: dayOfTheMonth || 1,
          }

          restructredFrequencyData.repeatedTimes[type.toLowerCase()] = true;
          const formattedResponeData = {
            ...replenishmentConfig,
            ...restructredFrequencyData,
          }

          this.setState({
            data: this.formattedData(formattedResponeData||data, 'response'),
          });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  onHandleInputChange = data => {
    const { updateReplenishmentModel, groupId, displayAlert } = this.props;
    const input = this.formattedData(data, 'format');
    const [type, val] = Object.entries(data.repeatedTimes).find(([key,value]) => !!value);
    const {hours, minutes, period} = data.times;
    const frequencyData = {
      type : type.toUpperCase(),
      time: `${hours}:${minutes} ${period}`,
      day: null,
      date: null,
      dayOfTheMonth:parseInt(data.dayOfTheMonth)
    }
    if( type === "weekly" ) {
      frequencyData.day = data.repeatDays;
    } else if ( type !== "daily" ) {
      frequencyData.date = data.repeatDates;
    }
    
    const{dayOfTheMonth,repeatDays,repeatDates,repeatedTimes,times,...formattedInput}=input 

    updateReplenishmentModel(
      {
        id: groupId,
        input:formattedInput,
        frequencyInput:frequencyData
      },
      {
        handleSuccess: response => {
        
          const {date, day, time, type}=response.data.updateReplenishmentModel.replenishmentFrequency;
          const replenishmentConfig = response.data.updateReplenishmentModel.replenishmentConfig ;

          const restructredFrequencyData = {
            repeatedTimes: {
              daily: false,
              weekly: false,
              fortnightly: false,
              trimonthly: false,
              monthly: false
            },
            times: {
              hours: time.split(":")[0],
              minutes: (time.split(":")[1]).split(" ")[0],
              period: (time.split(":")[1]).split(" ")[1],
            },
            repeatDays: day || [],
            repeatDates: date || [],
            dayOfTheMonth: dayOfTheMonth
          }

          restructredFrequencyData.repeatedTimes[type.toLowerCase()] = true;

          const formattedResponeData = {
            ...replenishmentConfig,
            ...restructredFrequencyData,
          }
          const updatedData = this.formattedData(formattedResponeData, 'response');
          this.setState(
            {
              data: updatedData,
            },
            () => {
              displayAlert(ALERT_TYPE.SUCCESS, 'Updated Successfully');
            },
          );
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  getRepeatDates = (param, dayOfTheMonth) => {
    let repeatDates = [] 
    const todayDate = new Date();
    let [year, month, day] = [todayDate.getFullYear(), todayDate.getMonth()+1,todayDate.getDate()];
    day = parseInt(dayOfTheMonth);
    const  lastDayOfMonth = new Date(year, month, 0);

    if (param === "fortnightly") {
      if(day+14 > lastDayOfMonth){
        repeatDates.push(day)
      } else {
        repeatDates.push(day)
        repeatDates.push(day+14)
      }
    } else if (param === "monthly") {
      repeatDates.push(day)
    }
    else if (param === "trimonthly") {
      if(day+10 > lastDayOfMonth) {
        repeatDates.push(day);
      } else if(day + 20 > lastDayOfMonth) {
        repeatDates.push(day);
        repeatDates.push(day +10);
      } else {
        repeatDates.push(day);
        repeatDates.push(day + 10);
        repeatDates.push(day + 20);
      }
    }
    return repeatDates; 
  }

  handleRadioButtonChange = (e, param) => {
    const { data } = this.state;
    const { name, value } = e.target;
    const repeatedTimes = {...data.repeatedTimes};
    Object.entries(repeatedTimes).map(([key,value]) => {
      repeatedTimes[key] = false;
    });
    repeatedTimes[value.toLowerCase()] = e.target.checked;

    if(param) {
      const repeatDates = this.getRepeatDates(param, data.dayOfTheMonth);     
      this.setState({
        data: {
          ...data,
          repeatedTimes,
          repeatDates
        },
      }, () => {
        debouncer(this.onHandleInputChange, 1800)(this.state.data);
      });
    } else {
      this.setState({
        data: {
          ...data,
          repeatedTimes
        },
      }, () => {
        debouncer(this.onHandleInputChange, 1800)(this.state.data);
      });
    }
  };


  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onInputChange = (e, firstParam, paramList) => {
    const { data } = this.state;
    if(firstParam === "dayOfTheMonth") {
      const dayOfTheMonth = e.target.value;
      const [param, val] = Object.entries(data.repeatedTimes).find(([key,value]) => !!value);
      const repeatDates = this.getRepeatDates(param, dayOfTheMonth);
      this.setState(
        {
          data: {
            ...data,  
            repeatDates,
            dayOfTheMonth
          },
        },
        () => {
          debouncer(this.onHandleInputChange, 1800)(this.state.data);
        },
      );
    } else {
      const updatedDetails = this.inputChange(data, e, firstParam, paramList);
      this.setState(
        {
          data: updatedDetails,
        },
        () => {
          debouncer(this.onHandleInputChange, 1800)(updatedDetails);
        },
      );
    }
  };

  render() {
    const { data } = this.state;
    const { serverResponseWaiting } = this.props;
    return <ReplenishmentModelView data={data} loading={serverResponseWaiting} onInputChange={this.onInputChange} onRadioButtonChange={this.handleRadioButtonChange} />;
  }
}

ReplenishmentModel.propTypes = propTypes;
ReplenishmentModel.defaultProps = defaultProps;

export default withAlert()(ReplenishmentModel);
