import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../../../../../components';

const propTypes = {
  readMode: PropTypes.bool,
  sod: PropTypes.instanceOf(Object).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  category: PropTypes.string,
};

const defaultProps = {
  readMode: false,
  category: '',
};

const SOD = (props) => {
  const { sod, readMode, category, handleInputChange } = props;
  return (
    <div className='table-wrap'>
      <table>
        <thead>
          <th>{category}</th>
          <th>Count</th>
        </thead>
        <tbody>

          {sod.map((list, i) => (
            <tr>
              <td>{list.title}</td>
              <td>
                <div className='placeholder'>
                  <Input
                    type='number'
                    placeholder='0'
                    disabled={readMode}
                    value={list.count}
                    onChange={(e) => handleInputChange(e, 'sod', ['sod', `${i}`, 'count'])}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

SOD.propTypes = propTypes;
SOD.defaultProps = defaultProps;

export default SOD;
