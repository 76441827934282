import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withAlert from '../../../../utils/composition/withAlert';
import sampleData from './data';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { DialogWrapper } from '../../../common';
import View from './View';
import BrandDialogWrapper from '../BrandDialogWrapper';
import { Input } from '../../../../components';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { MonthPicker } from '../../../../v4/components';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import * as downloadService from '../../../base/download.service'


const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getUserTarget: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getUserBrandTarget: PropTypes.func.isRequired,
  getSubDBrandTarget: PropTypes.func.isRequired,
  getSubDTarget: PropTypes.func.isRequired,
  getCatalogDetailList: PropTypes.func.isRequired,
  createOrEditUserBrandTarget: PropTypes.func.isRequired,
  createOrEditUserTarget: PropTypes.func.isRequired,
  downloadReport: PropTypes.func,
  match: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class TargetDetails extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      data: sampleData,
      id: parseInt(match.params.id, 10),
      brandDialogData: [
        {
          id: 0,
          brandName: 'xxxxx',
          target: 0,
          monthName: 'xxxxx',
          year: 'xxxx',
          total: 0,
        },
      ],
      brandNameList: [
        {
          target: 0,
          userId: 0,
          total: 0,
        },
      ],
    };

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.settings = JSON.parse(localStorage.getItem('settings'));
  }

  static getDerivedStateFromError(error) {
    return { hasError: false };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const { getUserTarget, displayAlert, getSubDTarget } = this.props;
    const { id } = this.state;

    this.settings.salesTeamWise &&
      getUserTarget(
        {
          buTargetId: id,
        },
        {
          handleSuccess: response => {
            this.setState({
              data: response.data.userTargetPerMonth || [],
            });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );

    this.settings.subDWise &&
      getSubDTarget(
        {
          buTargetId: id,
        },
        {
          handleSuccess: response => {
            this.setState({
              data: response.data.subdTargetPerMonth || [],
            });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
  };

  handleBrandDialog = (id, targetData = {}) => {
    const { getUserBrandTarget, displayAlert, getSubDBrandTarget, getCatalogDetailList } = this.props;
    const { id: targetID, data } = this.state;
    if (this.settings.brandWise) {
      getUserBrandTarget(
        {
          userId: id,
          buTargetId: targetID,
        },
        {
          handleSuccess: response => {
            this.setState({
              brandDialogData: response.data.userBrandTargets,
            });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
      getCatalogDetailList(
        {
          catalogId: 3,
          limit: 50,
        },
        {
          handleSuccess: response => {
            const brandList = response.data.catalogDetails.rows;
            const brandNameList = brandList.map(brand => ({
              title: brand.title,
              brandId: brand.id,
              userId: id,
              buTargetId: targetID,
              target: 0,
            }));
            this.setState({
              brandNameList,
            });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    } else {
      this.setState({
        brandDialogData: [
          {
            id: targetData.id,
            name: targetData.name,
            month: data[0].month,
            year: data[0].year,
            total: targetData.target,
            role: targetData.role,
          },
        ],
      });
    }
    if (this.settings.subDWise) {
      getSubDBrandTarget(
        {
          distributorId: id,
          buTargetId: targetID,
        },
        {
          handleSuccess: response => {
            this.setState({
              brandDialogData: response.data.subdBrandTargets,
            });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  handleInputChange = (event, index, params) => {
    const { brandDialogData, brandNameList } = this.state;
    if (params === 'target') {
      brandDialogData[index].target = event.target.value;
      this.setState(brandDialogData);
    } else {
      brandNameList[index].target = event.formattedValue;
      this.setState(brandNameList);
    }
    if (params === 'brandDialogTotal') {
      brandDialogData[index].total = event.formattedValue;
      this.setState(brandDialogData);
    }
    if (params === 'brandNameListTotal') {
      brandNameList[index].total = event.formattedValue;
      this.setState(brandNameList);
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onFormSubmit = (type, response) => {
    const { createOrEditUserBrandTarget, createOrEditUserTarget } = this.props;
    const { brandNameList, brandDialogData, id } = this.state;
    const updatedBrandDialogData = brandDialogData.map(brand => ({
      brandId: brand.brandId,
      target: Number(brand.target),
    }));
    const updatedList = brandNameList.map(brand => ({
      brandId: brand.brandId,
      target: Number(brand.target),
    }));
    const totalListTarget = brandNameList.reduce((prev, current) => prev + +current.target, 0);
    const totalBrandDialogTarget = brandDialogData.reduce((prev, current) => prev + +current.target, 0);
    if (this.settings.brandWise) {
      createOrEditUserBrandTarget(
        brandDialogData.length !== 0
          ? {
              buTargetId: brandDialogData[0].buTargetId,
              userId: brandDialogData[0].userId,
              target: totalBrandDialogTarget,
              userBrandTargets: [...updatedBrandDialogData],
            }
          : {
              buTargetId: brandNameList[0].buTargetId,
              userId: brandNameList[0].userId,
              target: totalListTarget,
              userBrandTargets: [...updatedList],
            },
        {
          handleSuccess: () => {
            const { displayAlert } = this.props;
            displayAlert(ALERT_TYPE.SUCCESS, ` Target ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            this.getList();
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );
    } else {
      createOrEditUserTarget(
        brandDialogData.length !== 0
          ? {
              buTargetId: id,
              userId: brandDialogData[0].id,
              target: Number(brandDialogData[0].total),
            }
          : {
              buTargetId: brandNameList[0].buTargetId,
              userId: brandNameList[0].userId,
              target: Number(brandNameList[0].total),
            },
        {
          handleSuccess: () => {
            const { displayAlert } = this.props;
            displayAlert(ALERT_TYPE.SUCCESS, ` Target ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            this.getList();
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );
    }
  };

  handleDownloadClick=()=>{
    const { downloadReport, displayAlert } = this.props;
    const { id: targetID } = this.state;
    const filterBuTargetId ={
      column: 'buTarget_Id',
      value: [targetID.toString()],
    };
    downloadReport(
      {
        input: {
          domain: DOWNLOAD_DOMAIN.USER_TARGET,
          reportType: 'csv',
          filter:{
            filters:[
              filterBuTargetId
            ]
          }
        },
      },
      {
        handleSuccess: response => {
          downloadService.resolver(response.data.downloadReport.file);
          displayAlert(ALERT_TYPE.SUCCESS, 'Successfully downloaded file');
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  render() {
    const { data, brandDialogData, brandNameList } = this.state;
    const { settings } = this;
    const { serverResponseWaiting } = this.props;
    const title = brandDialogData[0] ? `${brandDialogData[0][settings.salesTeamWise ? 'name' : 'title'] || ''}` : '';
    const totalListTarget = brandNameList.reduce((prev, current) => prev + +current.target, 0);
    const totalBrandDialogTarget = brandDialogData.reduce((prev, current) => prev + +current.target, 0);
    return (
      <BrandDialogWrapper>
        <DialogWrapper
          title={title}
          subTitle={settings.salesTeamWise ? (brandDialogData[0] ? brandDialogData[0].role : '') : false}
          onDialogSubmit={this.onFormSubmit}
          withOutPadding
          renderDialog={({ type }) => (
            <>
              {brandDialogData.length !== 0 ? (
                <>
                  <div className="detail-wrap">
                    <div className="month-wrap">
                      <label>Month</label>
                      <MonthPicker value={brandDialogData[0]} />
                    </div>

                    <Input
                      disabled={type === EVENT_OPERATION.READ || this.settings.brandWise}
                      type="number"
                      decimalWithPrecedingZero
                      argument={{ min: 0 }}
                      name="monthly"
                      labelContent="Monthly Target"
                      placeholder="Monthly Target"
                      value={settings.brandWise ? totalBrandDialogTarget : brandDialogData[0].total}
                      onChange={event => this.handleInputChange(event, 0, 'brandDialogTotal')}
                    />
                  </div>
                  {this.settings.brandWise ? (
                    <div className="table-wrap">
                      <table>
                        <thead>
                          <tr>
                            <th>Brand</th>
                            <th>Brand Target</th>
                          </tr>
                        </thead>
                        <tbody>
                          {brandDialogData.map((brand, i) => (
                            <tr style={{ height: 41 }}>
                              <td>{brand.brandName || brand.brandNameList.abc}</td>
                              <td>
                                <Input
                                  value={brand.target}
                                  name="target"
                                  type="number"
                                  rule="isFloat"
                                  argument={{ min: 0 }}
                                  disabled={type === EVENT_OPERATION.READ}
                                  onChange={event => this.handleInputChange(event, i, 'target')}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div />
                  )}
                </>
              ) : (
                <>
                  <div className="detail-wrap">
                    <div className="month-wrap">
                      <label>Month</label>
                      <MonthPicker value={data[0]} />
                    </div>

                    <Input
                      disabled={type === EVENT_OPERATION.READ || this.settings.brandWise}
                      type="number"
                      decimalWithPrecedingZero
                      argument={{ min: 0 }}
                      name="monthly"
                      labelContent="Monthly Target"
                      placeholder="Monthly Target"
                      value={this.settings.brandWise ? totalListTarget : brandNameList[0].total}
                      onChange={event => this.handleInputChange(event, 0, 'brandNameListTotal')}
                    />
                  </div>
                  {this.settings.brandWise ? (
                    <div className="table-wrap">
                      <table>
                        <thead>
                          <tr>
                            <th>Brand</th>
                            <th>Brand Target</th>
                          </tr>
                        </thead>
                        <tbody>
                          {brandNameList.map((brand, i) => (
                            <tr style={{ height: 41 }}>
                              <td>{brand.title}</td>
                              <td>
                                <Input
                                  value={brand.target}
                                  name="target"
                                  type="number"
                                  rule="isFloat"
                                  argument={{ min: 0 }}
                                  placeholder="Target"
                                  disabled={type === EVENT_OPERATION.READ}
                                  onChange={event => this.handleInputChange(event, i)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div />
                  )}
                </>
              )}
            </>
          )}
          render={({ onDialogItemClick }) => (
            <View
              data={data}
              settings={settings}
              onIconClick={onDialogItemClick}
              handleBrandDialog={this.handleBrandDialog}
              loading={serverResponseWaiting}
              handleDownloadClick={this.handleDownloadClick}
            />
          )}
        />
      </BrandDialogWrapper>
    );
  }
}

TargetDetails.propTypes = propTypes;

TargetDetails.defaultProps = defaultProps;

export default withAlert()(TargetDetails);
