import styled from 'styled-components';

const TableStyled = styled.div`
.return-create{
  .modal-wrapper{
    .modal-inner{
      .evolve-dialog__body{
        max-height:70vh;
        overflow:auto;
        .actual_discount>div{
          cursor:not-allowed;
        }
      }
    }
  }
}
  .selected-status .content-center {
    padding: 14px 25px;
  }
  .control-label {
    &::before {
      position: absolute;
    }
  }
`;
export default TableStyled;
