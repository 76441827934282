import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button} from '../../../v4/components';
import { DialogBody, DialogFooter, DialogHeader } from '../../../components/Dialog';
import { getUser } from '../../../data/services';
import Input from '../../../components/Input/Text';
import CustomSelect from '../../../components/CustomSelect';
import { refValidator } from '../../../utils/refGenerator';
import { formConfig, openingBalanceTypeData, ledgerInfoToInfoMapper } from './config';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { EMPTY, INVALID } from '../../../data/enums/ErrorMessage';
import CustomAutoComplete from '../../../components/CustomAutoComplete';

const propTypes = {
  townId: PropTypes.Number,
  update: PropTypes.bool,
  search: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  createLedger: PropTypes.func.isRequired,
  editLedger: PropTypes.func.isRequired,
  billingCustomers: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const defaultProps = {
  townId: null,
  update: false,
};

const initialInfo = {
  typeId: 1,
  panNo: 0,
  phoneNumber: '',
  address: '',
  openingBalance: '',
  openingBalanceType: '',
  creditDay: 0,
  creditLimit: 0,
  billingLedgerId: '',
};

class LedgerAdd extends Component {
  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.distributorExist = this.userInfo.Distributor.length > 0;
    this.state = {
      info: {
        ...initialInfo,
        title: '',
        distributorId: this.distributorExist ? this.userInfo.Distributor[0].id : null,
      },
      enableErrorDisplay: false,
      formReference: formConfig.refsObj,
      billingCustomerList: [],
      distributorList: [],
      billingService: this.distributorExist ? this.userInfo.Distributor[0].servicesUsed.billing.status || false : null,
      editDisableFlag: false,
    };
  }

  componentDidMount() {
    const { getDistributors, townId, update, ledgerInfo } = this.props;
    let { info } = this.state;
    if (update) {
      info = ledgerInfoToInfoMapper(info, ledgerInfo);
      this.setState({ info });
    }

    getDistributors(
      {
        filter: {
          filters: [
            { column: 'town_id', value: [townId.toString()] },
            { column: 'active', value: ['true'] },
          ],
        },
      },
      {
        handleSuccess: response => {
          const data = response.data.distributors.rows || [];
          this.setState({
            distributorList: data.map(({ id, title, servicesUsed }) => ({
              id,
              title,
              servicesUsed,
            })),
          });
        },
        handleError: () => {},
      },
    );
  }

  onInputChange = (e, type) => {
    const { info } = this.state;
    this.setState({
      info: {
        ...info,
        [e.target.name]: type === 'number' ? Number(e.target.value) : e.target.value,
      },
      disableOk: false,
    });
  };

  checkBillingLedgerId = (id, object) => {
    if (id) {
      delete object.titleWithPAN;
      return object;
    }
    delete object.billingLedgerId;
    return object;
  };

  onSubmit = e => {
    e.preventDefault();
    const { info } = this.state;
    const { onSubmit, createLedger, displayAlert, id, update, editLedger } = this.props;
    const { formReference, billingService } = this.state;

    const { panNo, ...formReferenceWithOutPAN } = formReference;

    const formValidation = info.panNo ? refValidator(formReference) : refValidator(formReferenceWithOutPAN);
    if (!formValidation) {
      this.setState({ enableErrorDisplay: true });
    } else {
      const { panNo, ...infoWithoutPanNo } = info;

      const formattedData = {
        outletId: id,
        ...infoWithoutPanNo,
      };

      const { outletId, title, address, billingLedgerId, distributorId, typeId, phoneNumber } = formattedData;

      const aliasOutletData = {
        outletId,
        title,
        address,
        distributorId,
        typeId,
        billingLedgerId,
        phoneNumber,
      };

      if (panNo) {
        aliasOutletData.panNo = panNo;
        formattedData.panNo = panNo;
      }
      this.setState({ disableOk: true });

      const inputData = billingService
        ? this.checkBillingLedgerId(billingLedgerId, formattedData)
        : this.checkBillingLedgerId(billingLedgerId, aliasOutletData);
      if (!update) {
        createLedger(
          { input: inputData },
          {
            handleSuccess: response => {
              const { createLedger } = response.data;
              if (billingLedgerId) {
                createLedger.billingLedgerId = billingLedgerId;
              } else {
                createLedger.openingBalance = info.openingBalance;
              }
              onSubmit(createLedger);
              displayAlert(ALERT_TYPE.SUCCESS, 'Ledger successfully created');
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      } else if (update) {
        const { creditLimit, creditDay } = inputData;
        const { ledgerInfo } = this.props;
        const editData = {
          id: ledgerInfo.id,
          input: {
            title,
            distributorId,
            panNo: Number(panNo),
            address,
            creditLimit,
            creditDay,
            billingLedgerId,
            phoneNumber,
          },
        };

        editLedger(editData, {
          handleSuccess: () => {
            onSubmit();
            displayAlert(ALERT_TYPE.SUCCESS, 'Ledger successfully updated');
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        });
      }
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onSelectChange = (event, name, type) => {
    const { id, servicesUsed } = event;
    const { info } = this.state;
    this.setState({
      info: {
        ...info,
        [name]: type === 'number' ? parseInt(id, 10) : id,
      },
    });
    if (servicesUsed) {
      this.setState(() => ({
        billingService: servicesUsed.billing.status,
      }));
    }
  };

  customInputChange = event => {
    const { info } = this.state;
    const { billingCustomers } = this.props;
    this.setState(
      {
        info: {
          ...initialInfo,
          title: event.formattedValue,
          distributorId: this.distributorExist ? this.userInfo.Distributor[0].id : null,
        },
        editDisableFlag: false,
        disableOk: false,
      },
      () => {
        billingCustomers(
          {
            distributorId: info.distributorId,
            searchText: event.formattedValue,
          },
          {
            handleSuccess: response => {
              let customers = response.data.billingCustomers && response.data.billingCustomers.rows;
              customers = customers.map(c => {
                c.titleWithPAN = `${c.title} ${c.panNo === null ? '' : `(${c.panNo})`}`;
                return c;
              });
              this.setState({
                billingCustomerList: customers || [],
              });
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      },
    );
  };

  customInputSelect = selected => {
    const { customerId, id, ...items } = selected;
    const {
      info: { typeId, distributorId },
    } = this.state;
    this.setState({
      info: {
        ...items,
        billingLedgerId: customerId,
        typeId,
        distributorId,
      },
      editDisableFlag: true,
      disableOk: false,
    });
  };

  render() {
    const { onCancel, search, update } = this.props;
    const {
      info,
      enableErrorDisplay,
      formReference: refsObj,
      billingCustomerList,
      distributorList,
      billingService,
      editDisableFlag,
      disableOk,
    } = this.state;
    const {
      title: ledgerTitle,
      typeId,
      panNo,
      address,
      phoneNumber,
      openingBalance,
      openingBalanceType,
      creditDay,
      creditLimit,
      distributorId,
    } = info;

    return (
      <div className="modal-wrapper">
        <div className="modal-inner">
          <DialogHeader>
            <h2>{`${update ? 'Update' : 'Create/Map'} Ledger`}</h2>
          </DialogHeader>
          <DialogBody className="ledger-modal-body">
            {!this.distributorExist && (
              <CustomSelect
                labelContent="Distributor"
                placeholder="Select distributor"
                name="distributor"
                getOptionLabel={({ title }) => title}
                getOptionValue={({ id }) => id}
                options={distributorList}
                onChange={e => this.onSelectChange(e, 'distributorId', 'number')}
                value={distributorList.filter(d => d.id === distributorId)}
                enableValidation
                enableErrorDisplay={enableErrorDisplay}
                ref={ref => (refsObj.distributor = ref)}
              />
            )}

            {(!search || update) && (
              <>
                <Input
                  type="text"
                  placeholder="Ledger Name"
                  labelContent="Ledger Name"
                  name="title"
                  onChange={e => this.onInputChange(e, 'string')}
                  value={ledgerTitle}
                  enableValidation
                  enableErrorDisplay={enableErrorDisplay}
                  ref={ref => (refsObj.title = ref)}
                />
              </>
            )}
            {search && !update && (
              <CustomAutoComplete
                name="title"
                onChange={this.customInputChange}
                dropDownList={billingCustomerList}
                labelContent="Ledger Name"
                placeholder="Search or Type name"
                dropDownDisplayKey="titleWithPAN"
                dropDownValueKey="title"
                enableValidation
                refs={ref => (refsObj.title = ref)}
                enableErrorDisplay={enableErrorDisplay}
                onDropDownSelection={this.customInputSelect}
              />
            )}
            <Input
              type="text"
              placeholder="Address"
              labelContent="Address"
              name="address"
              onChange={e => this.onInputChange(e, 'string')}
              value={address}
              enableValidation
              enableErrorDisplay={enableErrorDisplay}
              ref={!editDisableFlag?ref => (refsObj.address = ref):null}
              disabled={editDisableFlag}
            />
            <Input
              type="number"
              placeholder="PAN"
              labelContent="PAN"
              name="panNo"
              rule="matches"
              argument="^((?!(0))[0-9]{9})$" /* matches digit of length 9 and doesn't start with 0 */
              errorMessage={panNo ? INVALID : EMPTY}
              onChange={e => this.onInputChange(e, 'number')}
              value={panNo}
              enableValidation
              enableErrorDisplay={panNo ? enableErrorDisplay : false}
              ref={ref => (refsObj.panNo = ref)}
              disabled={editDisableFlag}
            />
            <Input
              type="number"
              placeholder="Phone Number"
              labelContent="Phone Number"
              name="phoneNumber"
              onChange={e => this.onInputChange(e, 'string')}
              value={phoneNumber}
              disabled={editDisableFlag}
            />
            <CustomSelect
              labelContent="Opening Balance Type"
              placeholder="Select Opening Balance Type"
              name="openingBalanceType"
              getOptionLabel={({ title }) => title}
              getOptionValue={({ id }) => id}
              options={openingBalanceTypeData}
              onChange={e => this.onSelectChange(e, 'openingBalanceType', 'string')}
              value={openingBalanceTypeData.filter(d => d.id === openingBalanceType)}
              enableValidation
              enableErrorDisplay={enableErrorDisplay}
              ref={ref => (refsObj.openingBalanceType = ref)}
              disabled={update || editDisableFlag}
            />
            {billingService && (
              <>
                {/* <CustomSelect
                    labelContent="Ledger Type"
                    placeholder="Select Ledger Type"
                    getOptionLabel={({ title }) => title}
                    getOptionValue={({ id }) => id}
                    name="typeId"
                    options={ledgerType}
                    onChange={e => this.onSelectChange(e, 'typeId', 'number')}
                    value={ledgerType.filter(d => d.id === typeId)}
                    enableValidation
                    enableErrorDisplay={enableErrorDisplay}
                    ref={ref => refsObj.typeId = ref}
                  /> */}
                <Input
                  name="openingBalance"
                  type="number"
                  rule="isFloat"
                  placeholder="0"
                  labelContent="Opening Balance"
                  onChange={e => this.onInputChange(e, 'number')}
                  value={openingBalance}
                  enableValidation
                  enableErrorDisplay={enableErrorDisplay}
                  ref={ref => (refsObj.openingBalance = ref)}
                  disabled={update || editDisableFlag}
                />
                <Input
                  name="creditLimit"
                  type="number"
                  placeholder="0"
                  labelContent="Credit Limit"
                  onChange={e => this.onInputChange(e, 'number')}
                  value={creditLimit}
                  enableValidation
                  enableErrorDisplay={enableErrorDisplay}
                  ref={ref => (refsObj.creditLimit = ref)}
                  disabled={editDisableFlag}
                />
                <Input
                  name="creditDay"
                  type="number"
                  placeholder="0"
                  labelContent="Credit Days"
                  onChange={e => this.onInputChange(e, 'number')}
                  value={creditDay}
                  enableValidation
                  enableErrorDisplay={enableErrorDisplay}
                  ref={ref => (refsObj.creditDay = ref)}
                  disabled={editDisableFlag}
                />
              </>
            )}
          </DialogBody>
          <DialogFooter>
            <Button secondary onClick={e => onCancel(e)}>
              Cancel
            </Button>
            <Button primary disabled={disableOk} onClick={e => this.onSubmit(e)}>
              ok
            </Button>
          </DialogFooter>
        </div>
        <div className="overlay" />
      </div>
    );
  }
}

LedgerAdd.propTypes = propTypes;

LedgerAdd.defaultProps = defaultProps;

export default withAlert()(LedgerAdd);
