export const ActionTypes = {
  SEND_RSCORE_REQ: 'SEND_RSCORE_REQ',
  SEND_RSCORE_SUC: 'SEND_RSCORE_SUC',
  SEND_RSCORE_FAIL: 'SEND_RSCORE_FAIL',

  SEND_P3M_RSCORE_REQ: 'SEND_P3M_RSCORE_REQ',
  SEND_P3M_RSCORE_SUC: 'SEND_P3M_RSCORE_SUC',
  SEND_P3M_RSCORE_FAIL: 'SEND_P3M_RSCORE_FAIL',

  SEND_P3W_RSCORE_REQ: 'SEND_P3W_RSCORE_REQ',
  SEND_P3W_RSCORE_SUC: 'SEND_P3W_RSCORE_SUC',
  SEND_P3W_RSCORE_FAIL: 'SEND_P3W_RSCORE_FAIL',
};
