import styled from 'styled-components';

export const ReportTableStyled = styled.div`
  margin: 20px 0;

  h2 {
    color: #8993a4;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 15px 0 15px;
  }

  .progress-wrap {
    progress {
      height: 20px;
      width: 100%;
    }
  }

  .compliance-green {
    text-align: center;
    display: inline-block;
    .light {
      border: 1px solid;
      color: #47c464;
      min-width: 64px;
      padding: 3px 4px 3px 4px;
      border-radius: 2px;
      display: block;
      font-size: 12px;
      letter-spacing: 0.7px;
      font-weight: 400;
      height: 18px;
      line-height: 11.5px;
      text-align: center;
    }
  }

  .compliance-yellow {
    text-align: center;
    display: inline-block;
    .light {
      color: #e5ad12;
      border: 1px solid;
      min-width: 64px;
      padding: 3px 4px 3px 4px;
      border-radius: 2px;
      display: block;
      font-size: 12px;
      letter-spacing: 0.7px;
      font-weight: 400;
      height: 18px;
      line-height: 11.5px;
      text-align: center;
    }
  }

  .table-wrap {
    margin-top: 25px;

    table {
      thead {
        tr {
          height: 36px;
        }
      }
      tbody {
        tr {
          height: 34px;
        }
      }
    }
  }

  .td-right {
    text-align: right;
  }
  .td-middle {
    text-align: center;
  }
  .text-middle {
    text-align: center;
  }

  table {
    thead {
      tr {
        th {
          &:first-child {
            padding: 7px 0px 7px 48px;
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background: #ffffff;
        }
        td {
          &:first-child {
            padding: 9px 0px 9px 48px;
            white-space: nowrap;
          }
        }
      }
    }

    tfoot {
      td {
      }
    }
  }

  .modal-wrap {
    position: relative;
    display: flex;
    align-items: center;

    .progress-bar {
      width: 120px;
    }
    .icon-wrap {
      img {
        cursor: pointer;
      }
    }
  }

  .tooltip-wrap {
    bottom: 20px;
    left: 50px;
    opacity: 0;
    z-index: 99;
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    -webkit-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    transition: all 150ms linear;
    font-weight: 500;
    visibility: hidden;
    pointer-events: none;
    background-color: #ffffff;
    position: absolute;
    min-width: 10vw;
    padding: 5px 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    line-height: 0px;
    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        font-size: 12px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        padding-left: 5px;
      }
    }
  }

  .info-button {
    padding-left: 8px;
  }

  .info-button:hover + .tooltip-wrap {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;
