const getEnumFromObject = (object) => Object.freeze({ ...object });

const USER_ROLE = getEnumFromObject({
  SUPER_ADMIN: 1,
  ADMIN: 2,
  LEADERSHIP: 3,
  BRANCH_HEAD: 4,
  MIS_ADMIN: 5,
  MIS_ASSOCIATE: 6,
  ASM: 7,
  STL: 8,
  DISTRIBUTOR_ADMIN: 9,
  DSE: 10,
  LI: 11,
  DRIVER: 12,
  NSM: 13,
  DSM: 17,
  TAS: 18,
  BILLING_USER: 19,
  VERIFIER: 16,
  VIRTUAL_USER:20,
  VISIBILITY_TEAM_LEADER:14,
  TAS_TEAM_LEAD:14,
  TSI: 21,
  RSM: 22,
});

const ADMIN_ROLE_GROUP = [
  USER_ROLE.ADMIN,
  USER_ROLE.MIS_ADMIN,
  USER_ROLE.LEADERSHIP,
  USER_ROLE.SUPER_ADMIN,
  USER_ROLE.MIS_ASSOCIATE,
];

const DISTRIBUTOR_ASSOCIATED_ROLES = [
  USER_ROLE.NSM,
  USER_ROLE.ASM,
  USER_ROLE.STL,
  USER_ROLE.DSM,
  USER_ROLE.BRANCH_HEAD,
  USER_ROLE.RSM,
  USER_ROLE.TSI,
];

const USER_LABEL = {
  dsm: 'dsm',
  asm: 'asm',
  stl: 'stl',
  dse: 'dse',
};

const USER_LABEL_ROLE_MAPPER = {
  _17: USER_LABEL.dsm /** object auto sorts in ascending order while implementing if only number is used. * */,
  _7: USER_LABEL.asm,
  _8: USER_LABEL.stl,
  _10: USER_LABEL.dse,
};

const USER_ROLE_TITLE = {
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  LEADERSHIP: 'Leadership',
  BRANCH_HEAD: 'Branch Head',
  MIS_ADMIN: 'MIS Admin',
  MIS_ASSOCIATE: 'MIS Associate',
  ASM: 'Area Sales Manager',
  STL: 'Sales Team Leader',
  DISTRIBUTOR_ADMIN: 'Distributor Admin',
  DSE: 'Direct Sales Representative',
  LI: 'Logistics Incharge',
  DRIVER: 'Driver',
  NSM: 'National Sales Manager',
  DSM: 'Deputy Sales Manager',
  TAS: 'TAS',
  BILLING: 'Billing',
  MERCHANDISER: 'Merchandiser',
  VERIFIER: 'Verifier',
  VIRTUAL_USER: 'Virtual User',
};

export {
  USER_ROLE,
  ADMIN_ROLE_GROUP,
  USER_LABEL,
  USER_LABEL_ROLE_MAPPER,
  DISTRIBUTOR_ASSOCIATED_ROLES,
  USER_ROLE_TITLE,
};
