import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { INVOICE_FRAGMENT } from '../../API';

const INVOICE_ORDERS = graphql(
  gql`
    mutation (
      $orderIds: [Int]!
      $invoiceNumber: String
      $customerId: Int
      $tradeDiscountValue: Float
      $remarks: String
      $isAbbreviatedInvoice: Boolean
      $isCash: Boolean
      $customerName: String
    ) {
      invoiceOrders(
        orderIds: $orderIds
        invoiceNumber: $invoiceNumber
        customerId: $customerId
        tradeDiscountValue: $tradeDiscountValue
        remarks: $remarks
        isAbbreviatedInvoice: $isAbbreviatedInvoice
        isCash: $isCash
        customerName: $customerName
      ) {
        ...InvoiceFragment
        id
      }
    }
    ${INVOICE_FRAGMENT}
  `,
  {
    props: ({ mutate }) => ({
      invoiceOrders: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CHECK_LEDGER_STATUS = gql`
  query checkLedgerStatus($customerId: Int!) {
    checkLedgerStatus(customerId: $customerId) {
      status
      reason
    }
  }
`;

const query = {
  checkLedgerStatus: CHECK_LEDGER_STATUS,
};

const mutation = {
  invoiceOrders: INVOICE_ORDERS,
};

export { INVOICE_ORDERS, CHECK_LEDGER_STATUS };

export { query, mutation };
