import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Row, Col, Input,
} from '../../../../../components/index';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { normalPresentor } from '../../../../../utils/date';
import { ImageColumn } from '../../../../../utils/tableUtils';
import { getTotalSum } from '../../../../common/HelperFunctions';

const InfoDetail = ({
  label, value, className,
}) => (
  <div>
    <label>{label}</label>
    <p className={className}>{value}</p>
  </div>
);

InfoDetail.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
};

InfoDetail.defaultProps = {
  label: '',
  value: '',
  className: '',
};

const Dialog = (props) => {
  const {
    quantityValidation, data, handleInputChange, handleSliderOpen, refsObj, resetQntValidation,
  } = props;
  return (
    <div>
      <Form>
        <h2>{data.title}</h2>
        <Row className="info-section">
          <Col md="3">
            <InfoDetail
              label="Last Purchased"
              value={(normalPresentor(data.lastPurchasedDate) === 'Invalid date')
                ? '-' : normalPresentor(data.lastPurchasedDate)}
              className="text-center"
            />
          </Col>
          <Col md="3">
            <InfoDetail
              label="P3M Offtake"
              value={data.p3mOfftake}
            />

          </Col>
          <Col md="3">
            <InfoDetail
              label="P1M Offtake"
              value={data.p1mOfftake}
            />

          </Col>
          <Col md="3">
            <InfoDetail
              label="Ordered Quantity "
              value={`${getTotalSum(data.orderDetails, 'cases')}cs ${getTotalSum(data.orderDetails, 'pieces')}pcs`}
            />
          </Col>
        </Row>
        <Row className="info-section">
          <Col md="3">
            <InfoDetail
              label="Warehouse Stock"
              value={`${data.stock} cs`}
            />
          </Col>
          <Col md="3">
            <InfoDetail
              label="Rate"
              value={fixedFloatAndCommas(data.rlp)}
            />
          </Col>
          <Col md="3" />
          <Col md="3" />
        </Row>
        <div className="table-wrap">
          <table>
            <thead>
              <th>Outlet</th>
              <th>Sign Offs</th>
              <th>Cases (cs)</th>
              <th>Pieces (pcs)</th>
            </thead>
            <tbody>
              {data.orderDetails.map((order, i) => (
                <tr>
                  <td>{order.retailOutlet}</td>
                  <ImageColumn
                    keyTitle={`order-image-${order.orderId}`}
                    imageList={order.images.map(a => a.callImages).flat() || []}
                    onImageClick={handleSliderOpen}
                    key={`order-image-${order.orderId}`}
                  />
                  <td>
                    <Input
                      name="cases"
                      type="number"
                      value={order.cases}
                      onChange={event => handleInputChange(event, 'orderDetails', ['orderDetails', `${i}`, 'cases'], resetQntValidation)}
                      enableValidation
                      externalValidation={!quantityValidation}
                      errorMessage={quantityValidation ? "Sum shouldn't be 0" : ''}
                      enableErrorDisplay={quantityValidation}
                      ref={ref => (refsObj.cases = ref)}
                    />
                  </td>
                  <td>
                    <Input
                      name="pieces"
                      type="number"
                      value={order.pieces}
                      onChange={event => handleInputChange(event, 'orderDetails', ['orderDetails', `${i}`, 'pieces'], resetQntValidation)}
                      enableValidation
                      externalValidation={!quantityValidation}
                      errorMessage={quantityValidation ? "Sum shouldn't be 0" : ''}
                      enableErrorDisplay={quantityValidation}
                      ref={ref => (refsObj.pieces = ref)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>
      </Form>
    </div>
  );
};

Dialog.propTypes = {
  quantityValidation: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  refsObj: PropTypes.instanceOf(Object),
  handleInputChange: PropTypes.func.isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
  resetQntValidation: PropTypes.func.isRequired,
};

Dialog.defaultProps = {
  data: {},
  refsObj: {},
  quantityValidation: false,
};

export default Dialog;
