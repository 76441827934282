import React from 'react';
import { Button, CustomSelect } from '../../../../../components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withLoading from '../../../../../utils/composition/withLoading';
import ViewType from '../../../../components/ViewType';
import { TableHeaderConfig, TableBody } from '../common/tableConfig';

const View = (props) => {
  const {
    data, menu, channelId, onDropDownChange, handleViewTypeChange, viewTypeConfig, viewType, onUploadClick,
  } = props;
  let sn = 0;
  return (
    <>
      <CustomSelect
        name="channel"
        onChange={(event) => {
          onDropDownChange(event.id);
        }}
        options={menu.channel}
        placeholder="Select Channel"
        getOptionValue={({ id }) => id}
        getOptionLabel={({ title }) => title}
        value={menu.channel.filter(
          ({ id }) => id === channelId,
        )}
      />
      <div className="sku-section">
        <div className="sku-header">
          <span>SKU</span>
          <div className="group-by">
            <span>Group By:</span>
            <ViewType
              handleDropDownChange={handleViewTypeChange}
              list={viewTypeConfig.list}
              value={viewTypeConfig.value}
              displayLabel={false}
            />
          </div>
        </div>
        <div className="upload-section">
          {data.list.length === 0 ? (
            <Button
              secondary
              iconBtnSmall
              iconName="upload"
              onClick={() => onUploadClick(EVENT_OPERATION.UPLOAD, {})}
            />
          ) : (
            <div className="table-wrap">
              <table>
                <TableHeaderConfig
                  viewType={viewType}
                />
                <tbody>
                  {data.list.map((sku, index) => (
                  <>
                    <tr key={`${index}-sku`}>
                      <td>{sku.group}</td>
                      <td colSpan="4" />
                    </tr>
                    { sku.skuList.map((list) => {
                      sn += 1;
                      return (
                        TableBody({
                          sn,
                          data: list,
                          viewType,
                        })
                      );
                    })}
                </>
                  ))}
                </tbody>
              </table>
            </div>)}
        </div>
      </div>
    </>
  );
};

const ViewWithErrorAndLoading = withLoading(View);
export default ViewWithErrorAndLoading;
