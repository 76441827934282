import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { sales } from '../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';

const breadCrumb = [
  sales,
];

const title = 'Outlet Chain';

const formConfig = {
  mapper: (element) => ({
    id: element.id || '',
    title: element.title || '',
    business_partner_id:element?.business_partner_id || '',
    images:element?.images || [],
  }),
  validationField: ['title','business_partner_id','images'],
  validationRequired: true,
};


const crudRequest = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => {
      const dataList={
        id: data.id,
        title:data?.title,
        active: false,
      }
      return ({input:dataList})},
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => {
      const dataList={
        id: data.id,
        title:data?.title,
        active: false,
      }
      return ({input:dataList})},
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

export {
  crudRequest, breadCrumb as breadCrumbConfig, formConfig, title
};
