import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../../base/TableHeader';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { PURCHASE_ORDER_VIEW } from './config';

const labelMappings = {
  sn: 'sn',
  sku: 'sku',
  quantity: 'quantity',
  rate: 'rate',
  amount: 'amount',
};

const viewBasedHeaderConfig = {
  [PURCHASE_ORDER_VIEW.BRAND]: [{
    id: 1,
    label: labelMappings.sn,
    title: 'SN',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.quantity,
    title: 'Quantity',
    show: true,
    className: 'text-right',
  },
  {
    id: 4,
    label: labelMappings.rate,
    title: 'Rate',
    show: true,
    className: 'text-right',
  },
  {
    id: 5,
    label: labelMappings.amount,
    title: 'Gross Amount',
    show: true,
  },
  ],
};

const TableHeaderConfig = ({ viewType }) => (
  <TableHeader
    headerDetails={viewBasedHeaderConfig[viewType]}
  />
);

TableHeaderConfig.propTypes = {
  viewType: PropTypes.string,
};

TableHeaderConfig.defaultProps = {
  viewType: '',
};

const returnTableBody = (sn, data) => {
  const table = {};
  table[labelMappings.sn] = <td key={`${data['SKU ID']}- id`}>{sn}</td>;
  table[labelMappings.sku] = <td key={`${data['SKU ID']}- title`}>{data.SKU}</td>;
  table[labelMappings.quantity] = <td key={`${data['SKU ID']}-quantity`} className="text-right">{fixedFloatAndCommas(((Number(data.Cases) * Number(data['Units Per Case'])) + Number(data.Pieces)))}</td>;
  table[labelMappings.rate] = <td key={`${data['SKU ID']}-rate`} className="text-right">{fixedFloatAndCommas(Number(data.Rate))}</td>;
  table[labelMappings.amount] = <td key={`${data['SKU ID']}-amount`}>{fixedFloatAndCommas(((Number(data.Cases) * Number(data['Units Per Case']) + Number(data.Pieces)) * Number(data['Rate With VAT'])))}</td>;

  return table;
};

const returnDetailTableBody = (sn, data) => {
  const table = {};
  table[labelMappings.sn] = <td key={`${data.poId}- id`}>{sn}</td>;
  table[labelMappings.sku] = <td key={`${data.poId}- title`}>{data.SKU.title}</td>;
  table[labelMappings.quantity] = <td key={`${data.poId}-quantity`} className="text-right">{fixedFloatAndCommas(data.quantity)}</td>;
  table[labelMappings.rate] = <td key={`${data.poId}-rate`} className="text-right">{fixedFloatAndCommas(data.rateDetails.rlp)}</td>;
  table[labelMappings.amount] = <td key={`${data.poId}-amount`}>{fixedFloatAndCommas(data.amountDetails.grossAmount)}</td>;

  return table;
};

const TableBody = ({
  sn,
  data,
  viewType,
  id,
}) => {
  const tableBody = id ? returnDetailTableBody(sn, data) : returnTableBody(sn, data);
  return (
    <tr
      key={data.id}
    >
      {viewBasedHeaderConfig[viewType].map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  id: PropTypes.number,
  sn: PropTypes.number,
  data: PropTypes.instanceOf(Object),
  viewType: PropTypes.string,
};
TableBody.defaultProps = {
  id: null,
  sn: 0,
  data: {},
  viewType: '',
};

export { TableHeaderConfig, TableBody };
