import React from 'react';
import PropTypes from 'prop-types';
import { ImageColumn } from '../../../../utils/tableUtils';
import { Badge, menuAction, tableData } from '../../../../v4/components';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { collectionTypes } from './config';
import { normalPresentor } from '../../../../utils/date';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { getUpdatedLabel, headerLabelConfig } from '../../../common/HelperFunctions';
import { dataProps } from '../../../../data/enums/Component';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';

const propTypes = {
  data: dataProps.value,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
  index: PropTypes.number,
};

const defaultProps = {
  data: dataProps.defaultValue,
  index: null,
};

const labelMappings = {
  date: 'date',
  outlet: 'outlet',
  dse: 'dse',
  collection: 'collection',
  amount: 'amount',
  status: 'status',
  received: 'received',
  role: 'role',
  distributor: 'distributor',
  route: 'route',
  bank: 'bank',
  valuedDate: 'valuedDate',
  remarks: 'remarks',
  images: 'images',
  actionHolder: 'actionHolder',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.dse,
    title: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
    show: true,
    isDynamic: true,
  },
  {
    id: 4,
    label: labelMappings.collection,
    title: 'Collection Type',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.received,
    title: 'Received By',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.role,
    title: 'Role',
    show: false,
  },
  {
    id: 9,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: false,
  },
  {
    id: 10,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 11,
    label: labelMappings.bank,
    title: 'Bank',
    show: true,
  },
  {
    id: 12,
    label: labelMappings.valuedDate,
    title: 'Valued Date',
    show: true,
  },
  {
    id: 13,
    label: labelMappings.remarks,
    title: 'Remarks',
    show: false,
  },
  {
    id: 14,
    label: labelMappings.images,
    title: 'Images',
    show: true,
  },
];

const menuConfigList = [
  {
    title: 'REMOVE',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.DISCARDED,
  },
];

const TableHeader = ({ tableConfig, dynamicHeader }) => (
  <thead className=''>
    <tr>
      {(dynamicHeader ? getUpdatedLabel(tableConfig) : tableConfig).map((label) =>
        label.show ? tableData(label) : null,
      )}
    </tr>
  </thead>
);

const returnTableBody = (data, handleSliderOpen, onIconClick, permission) => {
  const table = {};
  table[labelMappings.date] = <td key={`receipts-${data.id}-date`}>{normalPresentor(data.date)}</td>;
  table[labelMappings.outlet] = <td key={`receipts-${data.id}-outlet`}>{data.outlet.title}</td>;
  table[labelMappings.dse] = (
    <td key={`receipts-${data.id}-dse`}>
      {data.outlet.route && data.outlet.route.user && data.outlet.route.user.fullName}
    </td>
  );
  table[labelMappings.collection] = (
    <td key={`receipts-${data.id}-collectonType`}>
      {collectionTypes.find((d) => d.label === data.collectionType).title}
    </td>
  );
  table[labelMappings.amount] = <td key={`receipts-${data.id}-amount`}>{fixedFloatAndCommas(data.amount)}</td>;
  table[labelMappings.status] = (
    <td key={`receipts-${data.id}-status`}>
      <span className={data.status.toLowerCase()}>
        <span className='badge_border'>
          <Badge light>{data.status.replace('_', ' ')}</Badge>
        </span>
      </span>
    </td>
  );
  table[labelMappings.received] = <td key={`receipts-${data.id}-received`}>{data.collector.fullName}</td>;
  table[labelMappings.role] = <td key={`receipts-${data.id}-role`}>{data.collector.role.name}</td>;
  table[labelMappings.distributor] = <td key={`receipts-${data.id}-distributor`}>{data.distributor.title}</td>;
  table[labelMappings.route] = (
    <td key={`receipts-${data.id}-route`}>{data.outlet.route && data.outlet.route.title}</td>
  );
  table[labelMappings.bank] = <td key={`receipts-${data.id}-bank`}>{data.details.bank}</td>;
  table[labelMappings.valuedDate] = (
    <td key={`receipts-${data.id}-valuedDate`}>
      {normalPresentor(data.details.valuedDate) === 'Invalid date' ? '' : normalPresentor(data.details.valuedDate)}
    </td>
  );
  table[labelMappings.remarks] = <td key={`receipts-${data.id}-remarks`}>{data.remarks}</td>;
  table[labelMappings.images] = (
    <ImageColumn
      keyTitle={`rp-image-${data.id}`}
      imageList={data.details.image || []}
      onImageClick={handleSliderOpen}
      key={`rp-image-${data.id}`}
    />
  );
  table[labelMappings.actionHolder] = (
    <td key={`${data.id}-action`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {data.status === 'PENDING'
        ? menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)
        : null}
    </td>
  );
  return table;
};

const TableBody = ({ data, handleSliderOpen, tableConfig, index, onIconClick, permission }) => {
  const tableBody = returnTableBody(data, handleSliderOpen, onIconClick, permission);
  return (
    <tr key={`receipts-${index}-details`}>{tableConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>
  );
};

TableBody.propTypes = propTypes;

TableBody.defaultProps = defaultProps;

export {
  TableHeader, TableBody, labelConfig
};
