import styled from 'styled-components';

const MerchandiseDeviceStyled = styled.div`
.section-content{
.table-wrap{
  table{
    tbody{
      td{
        &>div{
          margin-right:4px;
          position:relative;
          .control-label:before{
            position:absolute;
          }
        }
        &.categories-td,
        &.brand-td{
          width:32%;

        }
      }
    }
  }
}
}
.merchandising-config-panel{
  .merchandise-dialog{
    .suggested-autocomplete-list-wrap{
      margin-top:8px;
    .suggested-autocomplete-list {
      border:1px solid #CDCED9;
      border-radius:2px;
      padding: 0px 24px 0px 4px;
      .text{
      font-size:12px;
      line-height:15px;
      color:#6B6C7E;
      text-transform:capitalize;
      }
      .ic-close{
      top:4px;
      right:8px;
      height:9px;
      width:9px;
      color:#6B6C7E;
    }
    }
    }
  &>div{
    margin-bottom:24px;
    padding:0;
    &:last-child{
      margin-bottom:0;
    }
    &.parent_div{
      position:relative;
      .form-input{
        border: 1px solid #C1C7D0;
    background: #FAFBFC;
    border-radius: 4px;
        input{
          width:90%;
          border:0;
          :focus{
            background-color:#FAFBFC;
          }
        }
      }
      &>span{
        position: absolute;
        top: 33px;
        right: 14px;
        color: #6B6C7E;
        height: 16px;
        width: 16px;
      }
    }
  }
  }
.modal-wrapper{
  .modal-inner.update,
  .modal-inner.create{
    width:379px;
    /* width:30%; */
    max-width:unset;
    &>div{
      box-shadow:unset;
    }
  }
}
}
`;
export default MerchandiseDeviceStyled;
