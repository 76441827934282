
import { compose } from 'recompose';
import Details from './Details';
import {
  query, mutation, CREATE_MT_SKUS, DELETE_MT_SKU, TOGGLE_CHANNEL_CATEGORY_SKUS,
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';


const composedMT = compose(
  CREATE_MT_SKUS,
  DELETE_MT_SKU,
  TOGGLE_CHANNEL_CATEGORY_SKUS,
  withRequestTracker({
    query,
    mutation,
  }),
)(Details);

export default composedMT;
