import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { breadCrumbConfig, formConfig as form, reponseToStateTransformer, transformData } from './config';
import { title } from '../config';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { BreadCrumb, Icon ,Button} from '../../../../v4/components';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../data/enums/EventOperation';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { dropdownChange } from '../../../../utils/formHandlers';
import { periodType, NOTIFICATION_ALERT_MESSAGE } from '../../../../data/enums/Notification';
import MASTER_DATA_TYPES from '../../../../data/enums/MasterData';
import history from '../../../../utils/history';
import withAlert from '../../../../utils/composition/withAlert';
import { refGenerator } from '../../../../utils';
import { refValidator } from '../../../../utils/refGenerator';
import { SYSTEM_ALERT } from '../../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { STATEFUL_ENTITIES } from '../../../../data/enums/GraphQL';
import { DialogWrapper } from '../../../common';
import { messagePlatform } from '../../../salesForce/pushMessage/detail/config';

const propTypes = {
  toggleState: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getMasterData: PropTypes.func.isRequired,
  getUserRoleList: PropTypes.func.isRequired,
  sendSystemAlert: PropTypes.func.isRequired,
  editSystemAlert: PropTypes.func.isRequired,
  getSystemAlertDetail: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  match: PropTypes.instanceOf(Object).isRequired,
  getDataDictionaryList: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Detail extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = props;
    this.state = {
      id,
      crudMode: props.match.params.id ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
      data: form.mapper({}),
      dataDictionaryList: [],
      menu: {
        periodType: [],
        roleList: [],
        userList: [],
        messagePlatform: [],
      },
      attributeList: [],
      operatorList: [],
      dimensionList: [],
      enableFormValidation: false,
    };
    this.formReference = refGenerator(form.validationField);
  }

  componentDidMount() {
    const { id } = this.state;

    if (id) {
      this.getSystemAlertDetail(id);
    }
    this.getAttributeOperatorAndDimension();
    this.loadDataForDropDown();
  }

  loadDataForDropDown = () => {
    const { menu } = this.state;
    menu.periodType = periodType;
    menu.messagePlatform = messagePlatform;
    this.getUserRole();
    this.getUserList();
    this.setState({ menu });
  };

  getSystemAlertDetail = id => {
    const { getSystemAlertDetail } = this.props;

    getSystemAlertDetail(
      {
        id: Number(id),
      },
      {
        handleSuccess: response => {
          const message = reponseToStateTransformer(response.data.systemAlertDetail || {});
          this.setState({
            data: message,
          });
          this.getDataDictionaryList(message.attribute);
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  getUserRole = () => {
    const { menu } = this.state;
    const { getUserRoleList } = this.props;
    getUserRoleList(
      {},
      {
        handleSuccess: response => {
          const { rows = [] } = response.data ? response.data.roles || {} : {};
          menu.roleList = rows;
          this.setState({ menu });
        },
        handleError: () => {
          this.onAPIRequestFailure();
        },
      },
    );
  };

  getHeader = () => {
    const { crudMode, data } = this.state;
    const header = crudMode === EVENT_OPERATION.UPDATE ? data.title : title;
    if (crudMode === EVENT_OPERATION.READ) return data.title;

    return (
      <>
        <span>{EVENT_OPERATION_MAPPER[crudMode].toLowerCase()}</span> {header}
      </>
    );
  };

  getDataDictionaryList = type => {
    if(type === "DSE ATTENDANCE"){
      type = "DSE_ATTENDANCE" ;
    }

    let { dataDictionaryList } = this.state;
    const { getDataDictionaryList, displayAlert } = this.props;

    getDataDictionaryList(
      {
        type,
      },
      {
        handleSuccess: response => {
          dataDictionaryList = response.data.getDataDictionaryList || [];
          this.setState({ dataDictionaryList });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleDropDownChange = (value, parameterRef = []) => {
    const { data, dataDictionaryList } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    switch (updatedData.attribute) {
      case MASTER_DATA_TYPES.SBD:
        this.getDataDictionaryList(MASTER_DATA_TYPES.SBD);
        break;
      case MASTER_DATA_TYPES.PRODUCTIVITY:
        this.getDataDictionaryList(MASTER_DATA_TYPES.PRODUCTIVITY);
        break;
      case MASTER_DATA_TYPES.CALLAGE:
        this.getDataDictionaryList(MASTER_DATA_TYPES.CALLAGE);
        break;
      case MASTER_DATA_TYPES.DSE_ATTENDANCE:
        this.getDataDictionaryList(MASTER_DATA_TYPES.DSE_ATTENDANCE);
        break;
      default:
        break;
    }
    this.setState({ data: updatedData, dataDictionaryList });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const { checked, name, value } = event.target;
    if (!checked && firstParam === 'repeatDays' && paramList.length === 0) {
      this.setState({
        data: {
          ...data,
          ...{
            repeatDays: data.repeatDays.filter(d => d !== value).filter(d => d !== null),
          },
        },
      });
    } else {
      if (firstParam) {
        if (paramList.length > 1) {
          paramList.reduce((acc, crValue, index, list) => {
            if (index === list.length - 1) {
              return (acc[crValue] = event.formattedValue);
            }
            return acc[crValue];
          }, data);
        } else {
          data[firstParam][name] = value;
        }
      } else {
        data[name] = value;
      }
      this.setState({ data });
    }
  };

  handleRadioButtonChange = (e, params) => {
    const { data } = this.state;
    const { name, value } = e.target;
    this.setState({
      data: {
        ...data,
        ...{
          [name]: {
            ...{ [value.toLowerCase()]: e.target.checked },
          },
        },
      },
    });
    // }
  };

  handleAudienceIconClick = (action, field, index = 0) => {
    const {
      data: { audience },
    } = this.state;
    const audienceBase = {
      info: {
        userRole: [],
        userIds: [],
        platform: [],
      },
    };
    if (action === 'remove') {
      audience[field].splice(index, 1);
    }
    if (action === 'add') {
      const baseObj = audienceBase[field];
      audience[field].push({ ...baseObj });
    }

    this.setState({ audience });
  };

  getAttributeOperatorAndDimension = () => {
    const { getMasterData } = this.props;
    getMasterData(
      {},
      {
        handleSuccess: res => {
          const { listMasterData = [] } = res.data;
          const dataObj = {
            [MASTER_DATA_TYPES.SYSTEM_ALERT_ATTRIBUTE]: [],
            [MASTER_DATA_TYPES.OPERATOR]: [],
            [MASTER_DATA_TYPES.DIMENSION]: [],
          };
          if (listMasterData.length > 0) {
            listMasterData.forEach(item => {
              dataObj[item.type] = [...(item.list || [])];
            });
            this.setState({
              attributeList: dataObj[MASTER_DATA_TYPES.SYSTEM_ALERT_ATTRIBUTE],
              operatorList: dataObj[MASTER_DATA_TYPES.OPERATOR],
              dimensionList: dataObj[MASTER_DATA_TYPES.DIMENSION],
            });
          }
        },
        handleError: err => {
          this.onAPIRequestFailure();
        },
      },
    );
  };

  getUserList = () => {
    const { menu } = this.state;
    const { getUserList } = this.props;
    getUserList(
      {
        offset: 0,
        filter: {},
      },
      {
        handleSuccess: response => {
          const { rows = [] } = response.data ? response.data.users || {} : {};
          menu.userList = rows;
          this.setState({ menu });
        },
        handleError: () => {
          this.onAPIRequestFailure();
        },
      },
    );
  };

  handleAudienceChange = (value, index = 0, field = '') => {
    const { data, menu } = this.state;
    if (field === 'userRole') {
      data.audience.info[index][field] = [value];
      data.audience.info[index].userIds = [];
    } else if (field === 'platform') {
      const platFormList = data.audience.info[index][field];

      const i = platFormList.indexOf(value);
      if (i > -1) {
        platFormList.splice(i, 1);
      } else {
        platFormList.push(value);
      }
    } else if (field === 'userIds') {
      if (value[value.length - 1] === 0) {
        const getUserListUnderRole = () =>
          menu.userList.filter(user => user.roleId === data.audience.info[index].userRole[0]).map(a => a.id);
        data.audience.info[index].userIds = getUserListUnderRole();
      } else {
        data.audience.info[index][field] = [...value];
      }
    }
    this.setState({ data });
  };

  handleButtonSubmit = () => {
    const { data, crudMode, id } = this.state;
    const { sendSystemAlert, displayAlert, editSystemAlert } = this.props;
    const { formReference } = this;
    const formValidation = refValidator(formReference);
    const submissiveData = transformData(data);
    const { weekly } = data.repeatedTimes;

    const platFormValidation = data.audience.info.map(a => a.platform.every(b => b.length === 0));

    const userValidation = data.audience.info.map(a => a.userIds.every(b => b.length === 0));

    if (!formValidation || platFormValidation.includes(true) || userValidation.includes(true)) {
      this.setState({ enableFormValidation: true });
      return;
    }

    if (new Date(data.endDate) < new Date(data.startDate)) {
      displayAlert(ALERT_TYPE.WARNING, `${NOTIFICATION_ALERT_MESSAGE.END_DATE_GREATER}`);
      return;
    }

    if (new Date(data.startDate) > new Date(data.endDate)) {
      displayAlert(ALERT_TYPE.WARNING, `${NOTIFICATION_ALERT_MESSAGE.START_DATE_EARLIER}`);
      return;
    }
    if (weekly && data.repeatDays.length === 0) {
      displayAlert(ALERT_TYPE.WARNING, `${NOTIFICATION_ALERT_MESSAGE.REPEAT_DAYS}`);
      return;
    }
    if (data.times && data.times.hours > 12) {
      displayAlert(ALERT_TYPE.WARNING, `${NOTIFICATION_ALERT_MESSAGE.HOUR_ALERT}`);
      return;
    }

    if (data.times && data.times.minutes > 59) {
      displayAlert(ALERT_TYPE.WARNING, `${NOTIFICATION_ALERT_MESSAGE.MINUTE_ALERT}`);
      return;
    }

    if (crudMode !== EVENT_OPERATION.UPDATE) {
      sendSystemAlert(
        {
          input: submissiveData,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${MESSAGE_EVENT_OPERATION.MESSAGE_SENT}`);
            setTimeout(() => {
              history.push(`/${SYSTEM_ALERT}`);
            }, 2000);
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    } else {
      editSystemAlert(
        {
          id: parseInt(id, 10),
          input: submissiveData,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            setTimeout(() => {
              history.push(`/${SYSTEM_ALERT}`);
            }, 2000);
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    }
  };

  handleButtonCancel = () => {
    const { crudMode, id } = this.state;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: form.mapper({}),
      });
    } else {
      this.setState({ crudMode: EVENT_OPERATION.READ }, () => {
        this.getSystemAlertDetail(id);
      });
    }
  };

  handleDeleteIconClick = () => {
    const { id } = this.state;
    const { toggleState, displayAlert } = this.props;

    toggleState(
      {
        ids: [parseInt(id, 10)],
        type: STATEFUL_ENTITIES.MESSAGE,
        active: false,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, `${title}${MESSAGE_EVENT_OPERATION.DELETE}`);
          setTimeout(() => {
            history.push(`/${SYSTEM_ALERT}`);
          }, 1200);
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  render() {
    const {
      data,
      menu,
      enableFormValidation,
      crudMode,
      attributeList,
      operatorList,
      dimensionList,
      dataDictionaryList,
    } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <DialogWrapper
        onDialogSubmit={this.handleDeleteIconClick}
        render={({ onDialogItemClick }) => (
          <>
            <div className="section-header">
              <PanelStyled>
                <BreadCrumb list={breadCrumbConfig} />
                <PanelHeader>
                  <h2>{this.getHeader()}</h2>
                  <div className="flex m-0">
                    {crudMode !== EVENT_OPERATION.READ ? (
                      <>
                        <div>
                          <Button small secondary onClick={() => this.handleButtonCancel()}>
                            <span> Cancel </span>
                          </Button>
                          <Button
                            small
                            primary
                            onClick={() => this.handleButtonSubmit()}
                            disabled={serverResponseWaiting}
                          >
                            <span>Save</span>
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        {crudMode === EVENT_OPERATION.READ ? (
                          <div>
                            <Button secondary iconBtnSmall onClick={() => onDialogItemClick(EVENT_OPERATION.DELETE)}>
                              <Icon iconName="trash" />
                            </Button>
                            <Button
                              secondary
                              iconBtnSmall
                              onClick={() => {
                                this.setState({
                                  crudMode: EVENT_OPERATION.UPDATE,
                                });
                              }}
                            >
                              <Icon iconName="pencil" />
                            </Button>
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                </PanelHeader>
              </PanelStyled>
            </div>
            <div className="section-content section-tab">
              <View
                data={data}
                menu={menu}
                crudMode={crudMode}
                operatorList={operatorList}
                dimensionList={dimensionList}
                attributeList={attributeList}
                enableErrorDisplay={enableFormValidation}
                dataDictionaryList={dataDictionaryList}
                refsObj={this.formReference}
                onInputChange={this.handleInputChange}
                onAudienceChange={this.handleAudienceChange}
                onDropDownChange={this.handleDropDownChange}
                handleInputChange={this.handleInputChange}
                onRadioButtonChange={this.handleRadioButtonChange}
                onAudienceIconClick={this.handleAudienceIconClick}
              />
            </div>
          </>
        )}
      />
    );
  }
}
Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default withAlert()(Detail);
