import React from 'react'
import { tableData } from '../../../../../utils/paginatedListUtils'
import { childLabelConfig, returnChildTableBody } from './tableConfig'

const ChildView = ({ data }) => {
  return (
    <>
      <tr>
        {
          childLabelConfig.map(
            label => (
              tableData(label.title, label.align, label.classNames)
            )
          )
        }
      </tr>
      <>
        {data.map(item => (
          <tr key={item.id}>
            {returnChildTableBody(item)}
          </tr>
        ))}
      </>
    </>
  )
}

export default ChildView;
