import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  breadCrumbConfig,
  crudSuccess as crudRequestConfig,
  detailsFormConfig,
  formMapper,
  summaryDetailsMapper,
  title,
  totalPriceMapper,
} from './config';
import { SRN } from '../../../../data/enums/Route';
import Table from './table';
import SummaryDetails from './summaryDetails';
import { clone } from '../../../../utils/arrayProcessor';
import { grnImageUploader } from '../../../../utils/image';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../common/detailViews/pageHeader/PageHeader';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { customerMapper } from './summaryDetails/config';
import { getPermissionForSrn } from '../../../base/permission';
import withLoading from '../../../../utils/composition/withLoading';
import { PanelCard, Icon,Button } from '../../../../v4/components';
import { getSubdUserStatusAndId, handlePrint, updateCustomerList } from '../../../common/HelperFunctions';
import { DOMAIN, FORM_CONFIG } from '../../../../data/enums/config';
import { debouncer, dropdownChange, inputChange } from '../../../../utils/formHandlers';
import { refValidator } from '../../../../utils/refGenerator';

const propTypes = {
  getSkus: PropTypes.func.isRequired,
  createSRN: PropTypes.func.isRequired,
  updateSRN: PropTypes.func,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  approveSRN: PropTypes.func,
  getOutlets: PropTypes.func,
  getInvoiceDetail: PropTypes.func,
  history: PropTypes.instanceOf(Object).isRequired,
  getLinesByOutlet: PropTypes.func,
  getInvoiceNumberList: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  updateSRN: () => null,
  approveSRN: () => null,
  getOutlets: () => null,
  getLinesByOutlet: () => null,
  getInvoiceDetail: () => null,
  getInvoiceNumberList: () => null,
};

class CreateSrn extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const { id = 0, exist = false } = getSubdUserStatusAndId();
    this.distributorId = id;
    this.subDUser = exist;
    this.state = {
      skus: [],
      data: formMapper({}, this.distributorId),
      outletList: [],
      distributorList: [],
      enableErrorDisplay: false,
      invoiceNumberStatus: false,
      showDialog: false,
      loadingInvoiceStatus: false,
      distributorBatchFlag: false,
      /* distributorServices: {
        BILLING: {
          status: false,
          url: ''
        }, */

      // LOGISTICS: {}
      distributorServices: {
        billing: {
          status: false,
          url: '',
        },
        // LOGISTICS: { }
      },
      distributor: {
        id: null,
        title: '',
        townList: [],
      },
      outletId: null,
      customerList: [],
      srnRouteLineList: [],
      invoiceNumberList: [],
    };

    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createSRN,
      [EVENT_OPERATION.UPDATE]: props.updateSRN,
      [EVENT_OPERATION.APPROVE]: props.approveSRN,
    };
    this.onCRUDSuccess = this.responseProcessor(this.handleSrnSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.permission = getPermissionForSrn();
  }

  componentDidMount() {
    this.getDistributorsList();
    this.getSKUs();
  }

  setDistributorServices = (distributor) => {
    const { distributorServices, distributor: stateDistributor } = this.state;

    stateDistributor.townList = distributor.Towns.map(town => town.id);
    stateDistributor.id = distributor.id;
    stateDistributor.title = distributor.title;
    this.setState({
      distributorServices: distributor.servicesUsed || distributorServices,
      distributorBatchFlag: distributor.batchImplementation,
      distributor: stateDistributor,
    }, () => {
      this.getOutletList();
    });
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors({}, {
      handleSuccess: (response) => {
        const distributorList = response.data.distributors
          ? response.data.distributors.rows || [] : [];
        this.setState(
          {
            distributorList,
          },
          () => {
            if (this.subDUser && distributorList.length > 0) {
              this.setDistributorServices(distributorList[0]);
            }
          },
        );
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getOutletList = () => {
    const { getOutlets, displayAlert } = this.props;

    const { distributor } = this.state;

    if (distributor.townList.length > 0) {
      const filter = {
        filters: [
          {
            column: 'town_id',
            value: distributor.townList.map(String),
          },
        ],
      };

      getOutlets({
        filter,
      }, {
        handleSuccess: (response) => {
          this.setState({ outletList: response.data.retailOutlets.rows });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    }
  };

  getSKUs = () => {
    const { getSkus, displayAlert } = this.props;
    getSkus({}, {
      handleSuccess: (response) => {
        this.setState({ skus: response.data.skus.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  handleInvoiceNumberChange = (invoiceNumber, exactlyMatchedInvoiceNumber) => {
    const { data, outletList, distributorServices } = this.state;
    const { getInvoiceDetail, displayAlert } = this.props;
    if (exactlyMatchedInvoiceNumber) {
      getInvoiceDetail({ invoiceNumber, distributorId:data.Distributor.id}, { 
        handleSuccess: (response) => {
          const responseData = {
            ...response.data.invoiceDetailsByNumber,
            uuid: Math.random().toString(36).substring(7),

          };
          const invoiceDetail = formMapper(responseData)
            || formMapper({ uuid: Math.random().toString(36).substring(7) },
              this.userInfo.Distributor
                ? this.userInfo.Distributor.id : 0);
          this.updateList('outletList', invoiceDetail.RetailOutlet);
          if (!invoiceDetail.Customer) invoiceDetail.Customer = customerMapper({});

          this.setState({
            data: invoiceDetail,
            invoiceNumberStatus: exactlyMatchedInvoiceNumber,
            outletId: responseData.RetailOutlet.id,
            customerList: updateCustomerList(
              responseData.RetailOutlet.id,
              distributorServices,
              outletList,
              exactlyMatchedInvoiceNumber,
              invoiceDetail.paymentMode
              ),
          });
          this.getLinesByOutletId(responseData.RetailOutlet.id);
          if(invoiceDetail.totalValidOrders===0){
            displayAlert(ALERT_TYPE.CUSTOM_DANGER,'Sales Return already done')
          }
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    } else if (data.orders && data.orders.length > 0) {
      data.orders = [];
      data.updatedAmount = totalPriceMapper({});
      data.amount = totalPriceMapper({});
      data.totalValidOrders = 0;
      this.setState({
        data,
        invoiceNumberStatus: exactlyMatchedInvoiceNumber,
      });
    } else {
      this.setState({
        invoiceNumberStatus: exactlyMatchedInvoiceNumber,
      });
    }
  };

  getLinesByOutletId = (id) => {
    const { getLinesByOutlet, displayAlert } = this.props;
    const { data } = this.state;
    getLinesByOutlet({ outletId: id }, {
      handleSuccess: (response) => {
        const responseData = response.data.linesByOutletId;
        if (responseData.length === 1) {
          data.lineId = responseData[0] && responseData[0].id;
          this.setState({
            data,
          });
        }
        this.setState({
          srnRouteLineList: responseData || [],
        });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  directToMainPage = () => {
    /** direct to Srn page */
    const { history } = this.props;
    history.push(`/${SRN}`);
  };

  onSubmit = () => {
    const valid = this.getValidationStatus();
    if (valid) {
      this.createSrn();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  getValidationStatus = () => {
    const detailsStatus = refValidator(detailsFormConfig[FORM_CONFIG.REFS_OBJ]);
    const tableStatus = this.getTableValidationStatus();

    return (detailsStatus && tableStatus);
  };

  getInvoiceNumber = () => {
    const { distributor } = this.state;
    const timestamp = new Date().getTime();
    const distributorSubString = distributor.title
      ? distributor.title.replace(/[.]| /g, '').substring(0, 3).toUpperCase() : 'DIS';

    return `${distributorSubString}-${distributor.id}/${timestamp}`;
  };

  createSrn = () => {
    const { data, invoiceNumberStatus } = this.state;
    const updatedData = clone(data);
    const tableData = this.getTableDetails();
    // updatedData.details = this.getDetails();
    updatedData.amount = tableData.totalAmount;
    updatedData.srnType = tableData.srnType;
    updatedData.returnOrders = tableData.returnOrders;
    updatedData.oldBillStatus = !invoiceNumberStatus;
    if (!invoiceNumberStatus) {
      updatedData.salesInvoiceNumber = this.getInvoiceNumber();
    }
    this.onFormSubmit(EVENT_OPERATION.CREATE, updatedData);
  };

  updateList = (stateField, value) => {
    const list = this.state[stateField] || [];
    if (Array.isArray(list)) {
      list.push(value);
      this.setState({ [stateField]: list });
    }
  };

  handleSrnSuccess = (response, type) => {
    const { distributorServices } = this.state;
    const { displayAlert } = this.props;
    if (distributorServices.billing.status) {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      handlePrint({
        distributorServices,
        invoiceNumber: response.SRNInvoiceNumber,
        modules: DOMAIN.SALES_RETURN,
      },
      this.directToMainPage);
    } else {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage);
    }
  };


  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  responseProcessor = callBack => type => (response) => {
    callBack(
      response,
      type,
    );
  };

  filterItemFromList = (list, value, expectedDefaultValue, returnValueKey, key = 'id') => {
    const item = list.filter(item => item[key] === value)[0];
    const k = returnValueKey ? item[returnValueKey] || expectedDefaultValue : item;

    return k;
  };

  handleCustomerChange = (customerId) => {
    const { customerList, data } = this.state;
    data.Customer = this.filterItemFromList(customerList, customerId, null) || customerMapper({});
    data.customerName = data.Customer.name;
    this.setState({ data });
  };

  getInvoiceNumberList = (salesInvoiceNumber) => {
    const { data, invoiceNumberList } = this.state;
    const { getInvoiceNumberList, displayAlert } = this.props;
    if (!data.Distributor.id) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Distributor is not selected');
      return 0;
    }
    if (salesInvoiceNumber.length >= 3) {
      let exactlyMatched = invoiceNumberList.indexOf(salesInvoiceNumber) > -1;
      if (!exactlyMatched) {
        this.setState({ loadingInvoiceStatus: true });
        getInvoiceNumberList({
          invoiceString: salesInvoiceNumber,
          distributorId: data.Distributor.id,
        }, {
          handleSuccess: (response) => {
            exactlyMatched = response.data.searchInvoiceNumber.matched;
            this.setState({
              invoiceNumberList: response.data.searchInvoiceNumber.invoiceNumbers
                || [],
              loadingInvoiceStatus: false,
            },
            () => this.handleInvoiceNumberChange(salesInvoiceNumber, exactlyMatched));
          },
          handleError: (error) => {
            this.setState({ invoiceNumberList: [], loadingInvoiceStatus: false },
              () => this.handleInvoiceNumberChange(salesInvoiceNumber, exactlyMatched));
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        });
      } else {
        this.handleInvoiceNumberChange(salesInvoiceNumber, exactlyMatched);
      }
    } else {
      this.setState({ invoiceNumberList: [], loadingInvoiceStatus: false },
        () => this.handleInvoiceNumberChange(salesInvoiceNumber, false));
    }
  };

  resetData = (updatedData, callBack) => {
    const {
      outletId, distributorServices, outletList, invoiceNumberStatus, data,
    } = this.state;
    const refreshedData = summaryDetailsMapper({
      invoiceNumber: updatedData.salesInvoiceNumber,
      Distributor: { id: updatedData.Distributor.id },
    });
    this.setState({ data: formMapper(refreshedData), customerList: [] },
      () => {
        debouncer(callBack, 300)(updatedData.salesInvoiceNumber);
        if (outletId !== null) { this.setState({ customerList: updateCustomerList(outletId, distributorServices, outletList, invoiceNumberStatus) }); }
      });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;

    const updatedData = inputChange(data, event, firstParam, paramList);
    switch (event.target.name) {
      case 'salesInvoiceNumber':
        this.resetData(updatedData, this.getInvoiceNumberList);
        break;
      default:
        this.setState({ data: updatedData });
        break;
    }
  };

  handleDropDownChange = (value, parameterRef = [], name, selectedObj = {}) => {
    const {
      data, distributorServices, outletList, invoiceNumberStatus,
    } = this.state;

    const updatedData = dropdownChange(data, parameterRef, value);
    switch (name) {
      case 'outlet':
        updatedData.Customer = customerMapper({});
        this.setState({
          data: updatedData,
          outletFlag: true,
          customerList: updateCustomerList(updatedData.RetailOutlet.id, distributorServices, outletList, invoiceNumberStatus, updatedData.paymentMode),
        }, () => this.getLinesByOutletId(updatedData.RetailOutlet.id));

        break;
      case 'customer':
        this.handleCustomerChange(updatedData.Customer.id);
        break;
      case 'distributor':
        updatedData.Customer = customerMapper({});
        this.setState({ data: updatedData }, () => this.setDistributorServices(selectedObj));
        break;
      default:
        this.setState({ data: updatedData });
        break;
    }
  };

  toggleDialogAppearance = (e) => {
    const { showDialog } = this.state;
    e.preventDefault();
    this.setState({ showDialog: !showDialog });
  }

  handleDialogSubmit = (ledger) => {
    const { data } = this.state;
    const { displayAlert } = this.props;
    const { customerList } = this.state;
    const formattedLedger = customerMapper(ledger) || {};
    if (formattedLedger.id) {
      data.Customer = formattedLedger;
      data.customerName = formattedLedger.name;
      customerList.push(formattedLedger);
      this.setState({ customerList, showDialog: false, data });
    } else {
      this.setState({ showDialog: false });
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Customer Id not available');
    }
  };

  render() {
    const {
      data,
      skus,
      SrnId,
      update,
      outletList,
      editable,
      approvable,
      distributor,
      distributorList,
      distributorServices,
      enableErrorDisplay,
      invoiceNumberStatus,
      outletId,
      customerList,
      srnRouteLineList,
      invoiceNumberList,
      showDialog,
      loadingInvoiceStatus,
      distributorBatchFlag,
    } = this.state;
    const {
      displayAlert,
      serverResponseWaiting,
      getSKUBatchDetail,
    } = this.props;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <div className="prn-page-header">
              <PageHeader
                breadCrumb={breadCrumbConfig}
                title={title}
              />
              <div className="flex m-0">
                <Button
                  secondary
                  small
                  disabled={serverResponseWaiting}
                  onClick={() => {
                    this.setState({ data: formMapper({}, this.distributorId) });
                  }}
                >
                  <span> Cancel</span>
                </Button>
                <Button
                  small
                  primary
                  disabled={serverResponseWaiting}
                  onClick={() => this.onSubmit()}
                >
                  <span>Save</span>
                </Button>
                {
                    SrnId ? (
                      <div>
                        <Button
                          secondary
                          iconBtnSmall
                          disabled={!editable || update.type === EVENT_OPERATION.APPROVE}
                          onClick={() => {
                            this.setState({
                              update: {
                                type: EVENT_OPERATION.UPDATE,
                                status: true,
                              },
                            });
                          }}
                        >
                          <Icon iconName="pencil" />
                        </Button>
                        {approvable && (
                          <Button
                            small
                            primary
                            disabled={update.type === EVENT_OPERATION.UPDATE && update.status}
                            onClick={() => {
                              this.setState({
                                update: {
                                  type: EVENT_OPERATION.APPROVE,
                                  status: true,
                                },
                              });
                            }}
                          >


                            Approve
                          </Button>
                        )
                      }
                      </div>
                    ) : ''
                  }
              </div>
            </div>
          </PanelStyled>
        </div>
        <div className="section-content pad-48">
          <PanelCard cardTitle="details">
            <SummaryDetails
              enableErrorDisplay={enableErrorDisplay}
              loading={serverResponseWaiting}
              outletList={outletList}
              distributorList={distributorList}
              formConfig={detailsFormConfig}
              invoiceNumberStatus={invoiceNumberStatus}
              data={data}
              subDUser={this.subDUser}
              distributorServices={distributorServices}
              outletId={outletId}
              customerList={customerList}
              srnRouteLineList={srnRouteLineList}
              handleCustomerChange={this.handleCustomerChange}
              handleInputChange={this.handleInputChange}
              handleDropDownChange={this.handleDropDownChange}
              invoiceNumberList={invoiceNumberList}
              handleIconClick={this.toggleDialogAppearance}
              handleDialogSubmit={this.handleDialogSubmit}
              showDialog={showDialog}
              loadingInvoiceStatus={loadingInvoiceStatus}
            />
          </PanelCard>
          <PanelCard cardTitle="sku" skuClassStatus>
            <Table
              data={data}
              skuList={skus}
              update={update}
              distributorId={distributor.id}
              imageUploader={grnImageUploader}
              displayAlert={displayAlert}
              enableErrorDisplay={enableErrorDisplay}
              invoiceNumberStatus={invoiceNumberStatus}
              getStatus={childMethod => (this.getTableValidationStatus = childMethod)}
              getDetails={childMethod => (this.getTableDetails = childMethod)}
              getSKUBatchDetail={getSKUBatchDetail}
              distributorList={distributorList}
              distributorBatchFlag={distributorBatchFlag}
            />
          </PanelCard>
        </div>
      </Fragment>
    );
  }
}

CreateSrn.propTypes = propTypes;

CreateSrn.defaultProps = defaultProps;

export default withLoading(withAlert()(CreateSrn));
