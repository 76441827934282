export const week = [
  {
    id: 0,
    week: 'Week 1',
    week_no: 1,
  },
  {
    id: 1,
    week: 'Week 2',
    week_no: 2,
  },
  {
    id: 2,
    week: 'week 3',
    week_no: 3,
  },
  {
    id: 3,
    week: 'week 4',
    week_no: 4,
  },
  {
    id: 4,
    week: 'week 5',
    week_no: 5,
  },
  {
    id: 5,
    week: 'week 6',
    week_no: 6,
  },
  {
    id: 42,
    week: 'week 43',
    week_no: 43,
  },
  {
    id: 43,
    week: 'Week 44',
    week_no: 44,
  },
  {
    id: 44,
    week: 'Week 45',
    week_no: 45,
  },
  {
    id: 45,
    week: 'Week 46',
    week_no: 46,
  },
  {
    id: 8,
    week: 'week 1',
  },
  {
    id: 9,
    week: 'week 1',
  },
];

export const weekCustomize = week => {
  return ` Week ${week}`;
};

export const modifyDate = date => {
  return {
    start: date?.firstDate,
    end: date?.lastDate,
  };
};
