import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
  AMOUNT_DETAIL_FRAGMENT,
  PROMOTION_TITLE_FRAGMENT,
} from '../../../common/GqlFragments';
import {
  GET_CUSTOMERS,
  GET_DISTRIBUTORS_SERVICES, GET_OUTLETS_FOR_SRN, GET_SKU_WITH_BATCH,
} from '../../../common/GqlAPI';
import { INVOICE_FRAGMENT, ORDER_DETAIL_FRAGMENT, } from '../../orderProcessing/API';
import { GET_SRN_DETAIL } from '../details/API';

const GET_INVOICE_BY_INVOICE_NUMBER = gql`
query getInvoiceByInvoiceNumber($invoiceNumber: String!,$distributorId:Int!) {
  invoiceDetailsByNumber(invoiceNumber: $invoiceNumber,distributorId:$distributorId) {
            ...InvoiceFragment,
            paymentMode
            customerName
            id
            Orders{
                promotionId
                Promotion {
                    ...PromotionTitleFragment
                }
                Lines {
                    ...OrderDetailFragment,
                    updatedQuantity,
                    returnedQuantity,
                    returnedAmountDetails{
                      ...AmountDetailFragment
                    }
                    updatedAmountDetails {
                        ...AmountDetailFragment
                    }
                }

            }
            lineId
        }
    }
    ${INVOICE_FRAGMENT}
    ${PROMOTION_TITLE_FRAGMENT}
    ${AMOUNT_DETAIL_FRAGMENT}
    ${ORDER_DETAIL_FRAGMENT}
`;

const VALIDATE_SRN_DATA = gql`
    query validateSrnData($input: SrnValidateInput!) {
        validateSrnData(input : $input) {
            status
            Data {
                ...OrderDetailFragment,
                updatedQuantity,
                updatedAmountDetails {
                    ...AmountDetailFragment
                }
             
            }
        }
    }
   ${ORDER_DETAIL_FRAGMENT}
    ${AMOUNT_DETAIL_FRAGMENT}
`;


/* const VALIDATE_SRN_DATA = graphql(gql`
    query ($input: SrnValidateInput!) {
      validateSrnData(input: $input) {
          status
          Data {
              ...OrderDetailFragment
          }
      }
    }
  ${ORDER_DETAIL_FRAGMENT}
  `,
{
  props: ({ mutate }) => ({
    validateSrnData: variables => mutate({
      variables,
    }),
  }),
}); */
const GET_SKU_BATCH_DETAILS = gql`
  query getSKUBatchDetail($distributorId: Int!, $skuId: Int!) {
    getSkuBatchDetails(distributorId: $distributorId, skuId: $skuId) {
      id
      batchName
      manufactureDate
      expiryDate
      quantity
      dlp
      discount
    }
  }
`;


const CREATE_SRN = graphql(gql`
    mutation($input: SRNInput!) {
        returnSales(input: $input)
        {
            id
            SRNInvoiceNumber
        }
    }
`, {
  props: ({ mutate }) => ({
    createSRN: (variables) => mutate({
      variables,
    }),
  }),
});

const UPDATE_SRN = graphql(gql`
    mutation ($id: Int!, $input: GoodsReceivedNoteInput!) {
        updateGrn(id: $id, input: $input)
        {
            id
            SRNInvoiceNumber
        }
    }
`, {
  props: ({ mutate }) => ({
    updateSRN: (variables) => mutate({
      variables,
    }),
  }),
});

const APPROVE_SRN = graphql(gql`
    mutation ($id: Int!, $input: GoodsReceivedNoteInput!) {
        approveGRN(id: $id, input: $input)
        {
            id
            SRNInvoiceNumber
        }
    }
`, {
  props: ({ mutate }) => ({
    approveSRN: (variables) => mutate({
      variables,
    }),
  }),
});

const GET_INVOICE_NUMBER_LIST = gql`
    query getInvoiceNumberList( $invoiceString: String!, $distributorId: Int!) {
        searchInvoiceNumber(invoiceString: $invoiceString, distributorId: $distributorId) {
            invoiceNumbers
            matched
        }
    }
`;

const GET_LINES_BY_OUTLET = gql`
  query getLinesByOutlet($outletId:Int!){
    linesByOutletId(outletId:$outletId){
        id
        title
    }
  }
`;

const query = {
  getOutlets: GET_OUTLETS_FOR_SRN,
  getSkus: GET_SKU_WITH_BATCH,
  getDistributors: GET_DISTRIBUTORS_SERVICES,
  getInvoiceDetail: GET_INVOICE_BY_INVOICE_NUMBER,
  getSrnDetail: GET_SRN_DETAIL,
  getSKUBatchDetail: GET_SKU_BATCH_DETAILS,
  getLinesByOutlet: GET_LINES_BY_OUTLET,
  getCustomers: GET_CUSTOMERS,
  getInvoiceNumberList: GET_INVOICE_NUMBER_LIST,
};

const mutation = {
  createSRN: CREATE_SRN,
};

export {
  CREATE_SRN,
  UPDATE_SRN,
  APPROVE_SRN,
  GET_INVOICE_NUMBER_LIST,
  VALIDATE_SRN_DATA,
  GET_SKU_BATCH_DETAILS,
  GET_INVOICE_BY_INVOICE_NUMBER,
  GET_LINES_BY_OUTLET,
};

export { query, mutation };
