import styled from 'styled-components';

const StockLedgerStyled = styled.div`
  .form-select-input {
    margin-top: 8px;
    margin-bottom: 0;
    width: 22%;
    /* width:242px; */
    label {
      display: none;
    }
  }

  table {
    border: 0;
    tr {
      &.collapsible-body-tr {
        tbody {
          tr {
            th,
            td {
              &:first-child {
                padding: 7px 12px 7px 70px;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        &.active {
          td:first-child > span:before {
            transform: rotate(180deg);
            transition: all 0.23s ease-in-out;
            border-top-color: #004ad7;
          }
        }
        td:first-child {
          > span {
            position: relative;
            padding-left: 26px;

            &:before {
              content: '';
              width: 0;
              height: 0;
              border-radius: 4px;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #6b6c7e;
              position: absolute;
              top: 5px;
              left: 0;
              transition: all 0.23s ease-in-out;
            }
          }
        }
      }
    }
  }

  .collapsible-body-tr {
    &:hover,
    &:focus {
      background: transparent;
    }

    table {
      box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
      border: none;

      tbody {
        tr:last-child {
          border-top: 1px solid #e7e7ed;
        }
      }

      tr {
        th {
          background: transparent;
          width: auto;
        }
      }
    }
  }
`;

export default StockLedgerStyled;
