import React, { Component, memo } from 'react';
import PropTypes from 'prop-types';
import DetailView from './View';
import PageHeader from '../../../base/PageHeader';
import { imageSliderListFormatter, isError } from '../../../common/HelperFunctions';
import { PanelStyled } from '../../../common/configuration';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { breadCrumbConfig } from './config';
import DetailStyled from './DetailStyled';
import { formatter, getCurrentDay, normalPresentor } from '../../../../utils/date';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getUserDetailReport: PropTypes.func.isRequired,
  match: PropTypes.objectOf(
    PropTypes.shape({
      params: PropTypes.objectOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
};

const defaultProps = {
  serverResponseWaiting: false,
  match: {
    params: {
      id: 0,
    },
  },
};

class Detail extends Component {
  constructor(props) {
    super(props);
    const dateRange = {
      start: getCurrentDay(),
      end: getCurrentDay(),
    };
    const { state = {} } = props.location;
    this.state = {
      data: {
        achievementValue: 0,
        achievementVolume: 0,
        date_details: '',
        name: '',
        outletDetails: null,
        productivity: 0,
        remaining: 0,
        scheduled: 0,
        successful: 0,
        target: 0,
        unsuccessful: 0,
        route: '',
      },
      date: (state && state.date) || dateRange,
      /* dateRange,
      labelName: 'Today',
      dateList: [{
        label: 'Today',
        value: dateRange,
      },
      {
        label: 'MTD',
        value: {
          start: getStartOfCurrentMonth(),
          end: getCurrentDay(),
        },
      }], */
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { date } = this.state;
    const { displayAlert, getUserDetailReport, match } = this.props;

    getUserDetailReport(
      {
        userId: parseInt(match.params.id, 10),
        routeId: parseInt(match.params.routeId, 10),
        filter: {
          dateRange: date,
        },
      },
      {
        handleSuccess: response => {
          const data = response.data.getUserDetailReport ? response.data.getUserDetailReport : [];
          if (data.outletDetails) {
            data.outletDetails.map(dseData => {
              if (dseData.callImage) {
                dseData.callImage = [...(imageSliderListFormatter(dseData.callImage, '', { w: 2400, h: 1100 }) || [])];
                return dseData;
              }
            });
          }
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ data });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onDropDownChange = event => {
    /* const { label, value } = event;
    this.setState({
      labelName: label,
      dateRange: value,
    }, () => {
      this.loadTableData();
    }); */
  };

  handleDateRangeChange = (field, selectedDate) => {
    const { date } = this.state;
    date.start = formatter(selectedDate.start).format('DD MMM YYYY');
    date.end = formatter(selectedDate.end).format('DD MMM YYYY');
    this.setState({ date }, () => this.loadTableData());
  };

  render() {
    const { data, display, date } = this.state;
    const { serverResponseWaiting } = this.props;
    const startDate = normalPresentor(date.start);
    const rangeDate = `${date.start.slice(0, 6)} - ${normalPresentor(date.end)}`;
    const range = Date.parse(date.start) === Date.parse(date.end) ? startDate : rangeDate;

    return (
      <DetailStyled>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig()}
              imgUrl
              config={{
                title: data.name,
                filter: false,
                search: false,
                download: false,
                date: true,
              }}
              filter={{
                date,
              }}
              handleDateRangeChange={this.handleDateRangeChange}
            >
              {/* <CustomSelect
                  small
                  className="custom-select"
                  options={dateList}
                  placeholder="Select Channel"
                  getOptionValue={({ value }) => value}
                  getOptionLabel={({ label }) => label}
                  onChange={event => this.onDropDownChange(event)}
                  value={dateList.filter(d => d.label === labelName)}
                  isSearchable={false}
                /> */}
            </PageHeader>
          </PanelStyled>
        </div>
        <div className="section-content pad-lr-48">
          <DetailView data={data} dateRange={range} loading={serverResponseWaiting} permission={this.permission} />
        </div>
      </DetailStyled>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default memo(withAlert()(Detail));
