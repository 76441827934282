import React, { useState, useEffect } from 'react';
import { Menu } from '..';
import MonthPresentor from './MonthPresentor';
import MonthPickerStyled from './MonthPickerStyled';
import { monthList } from '../../constants/monthList';

const MONTH_PICKER_ICON = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
};

const MonthPicker = ({ ...props }) => {
  const { years, onSelect } = props;
  const initialValue = {
    year: 2019,
    month: 1,
  }
  const [value, setvalue] = useState(initialValue);
  const [yearInPresentation, setyearInPresentation] = useState(initialValue.year);

  const onIconClick = (ICON: any) => {
    const indexInYears = years.indexOf(yearInPresentation);
    const maxYearIndex = years.length - 1;
    const minYearIndex = 0;
    if (ICON === MONTH_PICKER_ICON.RIGHT && indexInYears < maxYearIndex) {
      setyearInPresentation(yearInPresentation + 1);
    }

    if (ICON === MONTH_PICKER_ICON.LEFT && indexInYears > minYearIndex) {
      setyearInPresentation(yearInPresentation - 1);
    }

  };

  const onMonthClick = (month: any) => {
    value.year = yearInPresentation;
    value.month = month.value.monthIndex;
    setvalue(value);
    onSelect(value);
  };

  useEffect(() => {
    setvalue({
      year: props.value.year,
      month: props.value.month,
    })
    setyearInPresentation(props.value.year);
  }, []);

  const monthName = value.month ? monthList.filter(month => month.id === value.month)[0].title : '';
  const header = (
    <div className="month-view">
      <span className="month-icon">Month</span>
      <span>
        {monthName}-{value.year}
      </span>
      <span className="ic-dropdown">*Dropdown</span>
    </div>
  );

  return (
    <MonthPickerStyled>
      <Menu header={header}>
        <div className="month-picker-wrap">
          <div className="month-picker-inner">
            <div className="month-picker-icon left" onClick={() => onIconClick(MONTH_PICKER_ICON.LEFT)}>
              LEFT
            </div>
            <div className="bold">{yearInPresentation}</div>
            <div className="month-picker-icon right" onClick={() => onIconClick(MONTH_PICKER_ICON.RIGHT)}>
              RIGHT
            </div>
          </div>
          <MonthPresentor
            onMonthClick={onMonthClick}
            activeMonth={value.month}
            isPresentationYearActive={yearInPresentation === value.year}
          />
        </div>
      </Menu>
    </MonthPickerStyled>
  );
};

MonthPicker.defaultProps = {
  years: [2018, 2019, 2020, 2021, 2022],
  value: {
    year: 2019,
    month: 1,
  },
  onSelect: () => null,
};

export default MonthPicker;
