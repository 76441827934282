import Progress from '../../../../components/progress';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { tableData } from '../../../../v4/components';
import { capitalizeAllFirstLetter, capitalizeFirstLetter, fundamentalList, getFundamentalTitle } from '../../config';

const returnTableBody = (data, labelMappings, bgColor, baseBgColor, title) => {
  const table = {};
  table[labelMappings.chart] = (
    <>
      <td key={`${data.id}-${labelMappings.chart}`} className="modal-wrap">
        <div className="progress-bar">
          <Progress
            completed={(data?.pointScoredSum / data?.pointsAllocatedSum) * 100}
            bgColor={bgColor}
            baseBgColor={baseBgColor}
          />
        </div>
        <div className={`${title?.replaceAll(' ', '-')} icon-wrap`}>
          <img src="/image/icons/info.svg" alt="info" className="info-button" />
          <div className="tooltip-wrap">
            {title !== capitalizeAllFirstLetter(fundamentalList.ON_SHELF_AVAILABILITY) && (
              <div>
                <span>{`Allocated ${getFundamentalTitle(title)} :`}</span>
                <p>{fixedFloatAndCommas(data?.allocated, 1)}</p>
              </div>
            )}
            <div>
              <span>
                {title === capitalizeAllFirstLetter(fundamentalList.ON_SHELF_AVAILABILITY) ? 'Required SKU :' : `Required ${getFundamentalTitle(title)} :`}
              </span>
              <p>{fixedFloatAndCommas(data?.requiredJBP, 1)}</p>
            </div>
            <div>
              <span>
                {title === capitalizeAllFirstLetter(fundamentalList.ON_SHELF_AVAILABILITY) ? 'Audited SKU :' : `Audited ${getFundamentalTitle(title)} :`}
              </span>
              <p>{fixedFloatAndCommas(data?.audited, 1)}</p>
            </div>
          </div>
        </div>
      </td>
    </>
  );

  table[labelMappings.category] = (
    <td key={`${data.id}-${labelMappings.category}`}> {capitalizeFirstLetter(data?.property)}</td>
  );

  table[labelMappings.pointsScored] = (
    <td key={`${data.id}-${labelMappings.pointsScored}`} className="td-right">
      {fixedFloatAndCommas(data?.pointScoredSum, 1)}
    </td>
  );

  table[labelMappings.gap] = (
    <td key={`${data.id}-${labelMappings.gap}`} className="td-right">
      {fixedFloatAndCommas(data?.pointsAllocatedSum - data?.pointScoredSum, 1)}
    </td>
  );

  return table;
};
const TableHeader = ({ labelConfig }) => (
  <thead>
    <tr>{labelConfig.map(label => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const TableBody = ({ data, labelMappings, labelConfig, bgColor, baseBgColor, title }) => {
  const tableBody = returnTableBody(data, labelMappings, bgColor, baseBgColor, title);

  return <tr key={data.distributorId}>{labelConfig.map(label => (label.show ? tableBody[label.label] : null))}</tr>;
};

export { TableHeader, TableBody };
