import React from 'react';
import { useSelector } from 'react-redux';
import { Bar, Cell, XAxis } from 'recharts';
import Barchart from '../../../../../components/Chart/BarChart';
import { filterItems } from '../../../../../utils/arrayProcessor';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { FundamentalStyled } from './FundamentalStyled';

const FundamentalsEachOutlet = ({ brand }) => {
  const dimension = {
    height: 300,
    width: 1100,
  };

  const brandColor = {
    ShareofShelf: '#5ad4d9',
    OnShelfAvailability: '#006c71',
    ShareofDisplay: '#67a3f2',
  };

  const rScore = useSelector(state => state?.RscoreDatas?.rScore);
  const RSCORE_BU = filterItems(rScore, brand, 'bu');

  const FUNDAMENTAL = _(RSCORE_BU)
    .groupBy('fundamental')
    .map((item, fundamental) => ({
      fundamental: fundamental
        .replace('Share of availability', 'On Shelf Availability')
        .replace('Share of shelf', 'Share of Shelf')
        .replace('Share of display', 'Share of Display'),
      pointScored: fixedFloatAndCommas(_.sumBy(item, 'point_scored'), 1),
      points_allocated: fixedFloatAndCommas(_.sumBy(item, 'points_allocated'), 1),
      percent: fixedFloatAndCommas((_.sumBy(item, 'point_scored') / _.sumBy(item, 'points_allocated')) * 100, 1),
    }))
    .value();

  return (
    <>
      {FUNDAMENTAL.length > 0 && (
        <FundamentalStyled>
          <Barchart
            data={FUNDAMENTAL}
            dimensions={dimension}
            isXAxis={true}
            isYAxis={true}
            legend={false}
            cartesianGrfundamental={true}
            tooltip={true}
            color={brandColor}
            xAxisTitle="Score"
            yAxisTitle=" Fundamentals"
            title="Fundamentals"
            // barGap={20}
            barSize={200}
            aspect={4}
          >
            <XAxis dataKey="fundamental" axisLine={false} fontSize={12} fontWeight={600} />

            <Bar
              dataKey="percent"
              label={{ position: 'top', fill: '#030913', fontSize: '12px', fontWeight: '500' }}
              isAnimationActive={false}
            >
              {FUNDAMENTAL?.map((entry, index) => (
                <Cell
                  cursor="pointer"
                  fill={brandColor[entry?.fundamental.replaceAll(' ', '')]}
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
          </Barchart>
        </FundamentalStyled>
      )}
    </>
  );
};

export default FundamentalsEachOutlet;
