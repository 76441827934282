const LEDGERS = {
  SALES: {
    name: 'SALES A/C',
    ledger_id: 102,
    narration: 'Sales A/C entry',
    type: 1,
    title: 'SALES',
  },
  PURCHASE: {
    name: 'PURCHASE A/C',
    ledger_id: 104,
    narration: 'Purchase A/C entry',
    type: 2,
    title: 'PURCHASE',
  },
  VAT: {
    name: 'VAT A/C',
    ledger_id: 103,
    narration: 'Vat',
    type: 3,
    title: 'TAX',
  },
  CUSTOMER: {
    name: 'CUSTOMER A/C',
    ledger_id: 'customer_reference_id',
    narration: 'Credit entry',
    type: 4,
    title: 'CUSTOMER',
  },
  VENDOR: {
    name: 'VENDOR A/C',
    ledger_id: 101,
    narration: 'Vendor entry',
    type: 6,
    title: 'VENDOR',
  },
  CASH: {
    name: 'CASH A/C',
    ledger_id: 105,
    narration: 'Cash entry',
    type: 8,
    title: 'CASH',
    id: 105,
    validInvoiceAmount: 50000,
  },
};

export default LEDGERS;
