import styled from 'styled-components';

export const LegendStyled = styled.div`
  /* border: 1px solid red; */
  padding-top: 30.5px;
  width: 100%;

  .legend-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .legend-title {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      font-weight: 400;
      color: #030913;
      margin-left: 8px;
    }
  }
`;
