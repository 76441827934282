import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JBPStyled from './JBPStyle';
import * as queryService from '../../../../../../base/query.service';
import withLoading from '../../../../../../../utils/composition/withLoading';
import { Button, Pagination, Search } from '../../../../../../../components';
import { EVENT_OPERATION } from '../../../../../../../data/enums/EventOperation';
import { dataList, TableHeader, TableBody } from './tableConfig';
import history from '../../../../../../../utils/history';
import { MT_BU_DETAILS } from '../../../../../../../data/enums/Route';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../../../../utils/api';
import { ALERT_TYPE } from '../../../../../../../data/enums/AlertType';
import withAlert from '../../../../../../../utils/composition/withAlert';
import { MESSAGE_EVENT_OPERATION } from '../../../../../../../data/enums/SuccessMessage';

const propTypes = {
  id: PropTypes.number.isRequired,
  buTitle: PropTypes.string.isRequired,
  displayAlert: PropTypes.func.isRequired,
  toggleJBPTemplate: PropTypes.func.isRequired,
  getJBPTemplateList: PropTypes.func.isRequired,

};
const defaultProps = {
};
class JBP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        filter: queryService.baseQueryParameters.filter,
      },
      data: {
        list: [],
        total: 0,
      },
    };
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  loadTableData=() => {
    const { queryParameters, data } = this.state;
    const { getJBPTemplateList, displayAlert, id } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getJBPTemplateList(
      {
        buId: id,
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: (response) => {
          data.list = (response.data.getRscoreJBPTemplateList
              && response.data.getRscoreJBPTemplateList.rows)
            || [];
          data.total = (response.data.getRscoreJBPTemplateList
              && response.data.getRscoreJBPTemplateList.count)
            || 0;
          this.setState(data);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  handleCreateClick=() => {
    const { id, buTitle } = this.props;
    history.push(`/${MT_BU_DETAILS}/${buTitle}/${id}/jbp-create`);
  }

  handleRowClick=(jbpId) => {
    const { id, buTitle } = this.props;
    history.push(`/${MT_BU_DETAILS}/${buTitle}/${id}/jbp-detail/${jbpId}`);
  }

  handleToggleStatus=(active, e, id) => {
    const { toggleJBPTemplate, displayAlert } = this.props;
    toggleJBPTemplate(
      {
        id,
        status: active,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, `Status ${MESSAGE_EVENT_OPERATION.UPDATE}`);
          this.loadTableData();
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  render() {
    const { queryParameters, data } = this.state;
    return (
      <JBPStyled>
        <div className="top-section">
          <span>{`Total ${data.total} JBP Templates.`}</span>
          <div className="button-section">
            <Search
              handleSearchInput={this.basePaginationService.handleSearchInputChange}
            />
            <Button
              primary
              iconName="plus"
              small
              title={EVENT_OPERATION.CREATE.toLocaleLowerCase()}
              onClick={() => this.handleCreateClick()}
              className="create-btn"
            />
          </div>
        </div>
        {data.list.length > 0 && (
        <>
          <div className="table-wrap">
            <table>
              { TableHeader() }
              <tbody>
                {data.list.map(jbp => (
                  TableBody({
                    data: jbp,
                    onToggleStatus: this.handleToggleStatus,
                    onRowClick: this.handleRowClick,
                  })
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            currentPage={queryParameters.pagination.page}
            limit={queryParameters.pagination.limit}
            totalItems={data.total}
            onPageChange={this.basePaginationService.onPageSelect}
          />
      </>
        )}
      </JBPStyled>
    );
  }
}

JBP.propTypes = propTypes;

JBP.defaultProps = defaultProps;

const JBPWithErrorAndLoading = withLoading(JBP);

export default withAlert()(JBPWithErrorAndLoading);
