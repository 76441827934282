import { Badge } from '../../../../../components';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { tableData } from '../../../../../v4/components';
import { trackCompliance } from '../../../config';

const labelMappings = {
  fundamentalByMrBrand: 'fundamentalByMrBrand',
  noOfoutlet: 'noOfoutlet',
  pointAllocated: 'pointAllocated',
  pointScored: 'pointScored',
  gap: 'gap',
  compliance: 'compliance',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.fundamentalByMrBrand,
    title: 'Fundamental By Modern Trade Accounts',
    show: true,
    align: 'text-left',
  },
  {
    id: 1.1,
    label: labelMappings.noOfoutlet,
    title: 'No. of Audited Outlet',
    show: true,
    align: 'text-right',
  },
  {
    id: 2,
    label: labelMappings.pointAllocated,
    title: 'Point Allocated',
    show: true,
    align: 'text-right',
  },
  {
    id: 3,
    label: labelMappings.pointScored,
    title: 'Point Scored',
    show: true,
    align: 'text-right',
  },
  {
    id: 4,
    label: labelMappings.gap,
    title: 'Gap',
    show: true,
    align: 'text-right',
  },
  {
    id: 5,
    label: labelMappings.compliance,
    title: 'Compliance',
    show: true,
    align: 'text-middle',
  },
];

const fillColor = brand => {
  const brandColors = {
    BBSMShareofshelf: '#a2cefa',
    BBSMShareofavailability: '#00378f',
    BBSMShareofdisplay: '#0e69ff',

    BigMartShareofshelf: '#fac6a7',
    BigMartShareofavailability: '#612d0e',
    BigMartShareofdisplay: '#f27024',

    SaleswaysShareofshelf: '#c3e6cf',
    SaleswaysShareofavailability: '#1a4d36',
    SaleswaysShareofdisplay: '#43c187',
  };

  return brandColors[brand];
};

const returnTableBody = (data, getOutletNumber) => {
  const table = {};
  table[labelMappings.fundamentalByMrBrand] = (
    <td key={`${data?.id}-${labelMappings.fundamentalByMrBrand}`}>
      <div className="mr-outlet">
        <svg height="12px" width="12px">
          <circle
            cx="6"
            cy="6"
            fill={fillColor(`${data?.outletChannel}${data?.fundamental.replaceAll(' ', '')}`)}
            r="6"
          />
        </svg>
        <span>
          {`${data?.outletChannel} ${data?.fundamental
            ?.replace('Share of availability', 'On Shelf Availability')
            ?.replace('Share of display', 'Share of Display')
            ?.replace('Share of shelf', 'Share of Shelf')}`}
        </span>
      </div>
    </td>
  );

  table[labelMappings.noOfoutlet] = (
    <td key={`${data?.id}-${labelMappings.noOfoutlet}`} className="td-right">
      {getOutletNumber(data?.outletChannel, data?.fundamental )}
    </td>
  );

  table[labelMappings.pointAllocated] = (
    <td key={`${data?.id}-${labelMappings.pointAllocated}`} className="td-right">
      {fixedFloatAndCommas(data?.pointsAllocatedSum, 1)}
    </td>
  );

  table[labelMappings.pointScored] = (
    <td key={`${data?.id}-${labelMappings.pointScored}`} className="td-right">
      {fixedFloatAndCommas(data?.pointScoredSum, 1)}
    </td>
  );
  table[labelMappings.gap] = (
    <td key={`${data.id}-${labelMappings.gap}`} className="td-right">
      {fixedFloatAndCommas(data?.pointsAllocatedSum - data?.pointScoredSum, 1)}
    </td>
  );
  table[labelMappings.compliance] = (
    <td key={`${data.id}-${labelMappings.compliance}`} className="td-middle">
      {/* {fixedFloatAndCommas((data?.pointScoredSum / data?.pointsAllocatedSum) * 100)}% */}
      <Badge
        label={`${fixedFloatAndCommas((data?.pointScoredSum / data?.pointsAllocatedSum) * 100, 1)}%`}
        className={trackCompliance((data?.pointScoredSum / data?.pointsAllocatedSum) * 100)}
      />
    </td>
  );

  return table;
};
const TableHeader = () => (
  <thead>
    <tr>{labelConfig.map(label => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const TableBody = ({ data, getOutletNumber }) => {
  const tableBody = returnTableBody(data, getOutletNumber);

  return (
    <tr key={data.distributorId} className="cursor-pointer">
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

export { TableHeader, TableBody };
