import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { refValidator ,refGenerator} from '../../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import history from '../../../../utils/history';
import withAlert from '../../../../utils/composition/withAlert';
import { EVENT_OPERATION,EVENT_OPERATION_MAPPER} from '../../../../data/enums/EventOperation';
import { dropdownChange } from '../../../../utils/formHandlers';
import View from './View';
import {
  BreadCrumb, Icon ,Button
} from '../../../../v4/components';
import * as queryService from '../../../base/query.service';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import {  crudSuccess as crudRequestConfig ,formMapper,title,breadCrumbConfig} from './config';
import { getUser } from '../../../../data/dao';
import DetailsStyled from './DetailStyled';
import { OUTLET_CHAIN} from '../../../../data/enums/Route';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class OutletChainDetails extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  constructor(props) {
    super(props);
    const id  =this.props.match.params ? parseInt(this.props.match.params.id) : null;
    this.userInfo = getUser();
    this.state = {
      id,
      data: formMapper({}),
      update: {
        type: EVENT_OPERATION.CREATE,
        status: true,
      },
      enableFormValidation: false,
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      menu: {
        categoryList: [],
        brandList: [],
      },
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createOutletChain,
      [EVENT_OPERATION.UPDATE]: props.updateOutletChain,
    };
    this.onCRUDSuccess =  this.responseProcessor();
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.handleAPIFailure, crudRequestConfig, serverCall);
    this.formReference = refGenerator(['title','business_partner_id']);
  }
  handleAPIFailure = err => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, err);
  };
  componentDidMount() {
    const {id,update} =this.state;
    if(id){
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.setState(
        {
          update,
        },
        () => {
          this.getOutletChainDetail();
        },
      );
    }
  }
  responseProcessor = () => {
    const onAPIRequestSuccess = type => response => {
      const { displayAlert } = this.props;
      const { update, id } = this.state;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage, response);
      if (type === 'CREATE') {
        setTimeout(() => {
          history.push(`/${OUTLET_CHAIN}`);
      })
    }
      if (type === 'UPDATE') {
        update.status = false;
        this.setState({ update ,id});
        this.getOutletChainDetail(id);
      }
    };
    return onAPIRequestSuccess;
  };
  getOutletChainDetail = () => {
    const {id} =this.state;
    const {getOutletChainList} =this.props;
    getOutletChainList(
      {
        "searchText":null
      },
      {
        handleSuccess: response => {
          const dataList =response?.data?.getOutletChain;
          const dataFiltered = dataList.find(a=>a?.id === id) || {}
          this.setState({data:dataFiltered});
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };
  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };
 
    handleButtonCancel = () => {
      const { update, id } = this.state;
      if (update.type === EVENT_OPERATION.CREATE) {
        this.setState({
          data: formMapper({}),
          enableFormValidation:false,
        },);
      } else {
        update.type = EVENT_OPERATION.UPDATE;
        update.status=false;
        this.setState({ update,enableFormValidation:false, }, () => {
          this.getOutletChainDetail(id);
        });
      }
    };

  handleDropDownChange = (value, parameterRef = []) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    this.setState({ data: updatedData });
  };

  onInputChange = (event, param) => {
    const { data } = this.state;
    data[param] = event.formattedValue;
    this.setState({ data });
  };
  handleButtonSubmit = () => {
    const {update,data} =this.state;
     data.image_url  =this.getImages();
    this.setState(data);
    const valid = refValidator(this.formReference);
    if (!valid) {
      this.setState({ enableFormValidation: true });
    } else {
      if(update.type ==EVENT_OPERATION.CREATE){
      this.createOutletChain();
      }
      else{
        this.updateOutletChain();
      }
    }
  };
  getActionType = () => {
    const { update } = this.state;
    return update.type !== EVENT_OPERATION.CREATE
      ? update.type === EVENT_OPERATION.UPDATE
        ? EVENT_OPERATION.UPDATE
        : EVENT_OPERATION.APPROVE
      : EVENT_OPERATION.CREATE;
  };

createOutletChain = () => {
  const { data } = this.state;
  const type = this.getActionType();
  this.onFormSubmit(type, data);
  this.setState({ data });
};
updateOutletChain =() =>{
  const { data } = this.state;
  const type = this.getActionType();
  this.onFormSubmit(type, data);
  this.setState({ data })
}
  getHeader = () => {
    const { update } = this.state;
    const crudMode = update.status ? EVENT_OPERATION_MAPPER[update.type].toLowerCase() : '';
    return (
      <>
        <span>{crudMode} </span>
        {title}
      </>
    );
  };

  render() {
    const {
      data,
      update,
      enableFormValidation,
      menu,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const header = this.getHeader();
    return (
      <DetailsStyled>
        <div className='section-header'>
          <PanelStyled className='padding-0'>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>{header}</h2>
              <div className="flex m-0">
                {update.status && (
                  <div>
                    <Button small secondary disabled={serverResponseWaiting} onClick={() => this.handleButtonCancel()}>
                      <span> Cancel</span>
                    </Button>
                    <Button small primary disabled={serverResponseWaiting} onClick={() => this.handleButtonSubmit()}>
                      <span>Save</span>
                    </Button>
                  </div>
                )}
                {!update.status &&  (
                  <Button
                    secondary
                    iconBtnSmall
                    disabled
                    disabled={update.type === EVENT_OPERATION.UPDATE && update.status}
                    onClick={() => {
                      this.setState({
                        update: {
                          type: EVENT_OPERATION.UPDATE,
                          status: true,
                        },
                      });
                    }}
                    className="ml-16"
                  >
                    <Icon iconName="pencil" />
                  </Button>
                )}
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className={`section-content pad-48 ${update.type === 'UPDATE' && 'update'}  ${update.status || 'disable'}`}>
         <View
          refsObj={this.formReference}
          enableErrorDisplay={enableFormValidation}
          data={data}
          update={update}
          menu ={menu}
          handleInputChange={this.onInputChange}
          getImages={images => (this.getImages = images)}
        />
        </div>
      </DetailsStyled>
    );
  }
}

OutletChainDetails.propTypes = propTypes;

OutletChainDetails.defaultProps = defaultProps;

export { OutletChainDetails };

export default withAlert()(OutletChainDetails);
