import { FILTER } from '../../../../data/enums/filter';
import { mt as mtRoute, MT_SETTINGS } from '../../../../data/enums/Route';
import { getFilterConfig } from '../../../../utils/filter';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { refGenerator } from '../../../../utils/refGenerator';

const breadCrumbConfig = [
  mtRoute, {
    id: 1,
    title: 'Rscore',
    // path: `/${MT_RSCORE}`,
    path: '#',
    active: true,
  },
  {
    id: 2,
    title: 'Settings',
    path: `/${MT_SETTINGS}`,
    active: true,
  },
];

const moqFilter = getFilterConfig([FILTER.CHANNEL, FILTER.CATEGORY, FILTER.DSE]);
const purchaseRequisitionFilter = getFilterConfig([FILTER.PARENT_OUTLETS]);

const title = 'MOQ';
const createButtonTitle = 'Create Requisition';
const EmailSuccess = 'Email sent Successfully.';
const EmailFail = 'Email Id\'s type is incorrect.';

const formMapper = (element = {}) => ({
  emails: element.emails,
  id: element.id,
});
const requiredList = [
  'id',
  'emails',
];

const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Email',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
  },
};

const REQUISITION_VIEW = {
  BRAND: 'BRAND',
};

export {
  breadCrumbConfig,
  title,
  createButtonTitle,
  moqFilter,
  purchaseRequisitionFilter,
  formConfig,
  REQUISITION_VIEW,
  EmailSuccess,
  EmailFail,
};
