import styled from 'styled-components';

const RequisitionStyled = styled.div`
padding: 32px 48px;
.top-section{
  display: flex;
  justify-content: space-between;
  .select-section{
    display: flex;
    .form-select-input{
      width: 230px;
      padding-right: 10px;
      label{
        display: none;
      }
    }
  }
}
.table-wrap{
  table tbody tr td.sku{
    white-space:nowrap;
  }
  .group-by{
    display: flex;
    align-items: center;
    div{
      padding-right: 0px;
      border: none;
      label{
        margin-right: 0px;
      }
    }
    .form-select-input{
      .zindex-2__control{
        border: none;
        cursor: pointer;
        background: #f1f2f5;
      }
    }
  }
  .sku-header{
    th{
      font-weight: 600;
      line-height: 21px;
      font-size: 14px;
      color: #272833;
      white-space: nowrap;
  }
  }
}
.modal-wrapper{
  .modal-inner{
    max-width: 865px;
    .evolve-dialog__body{
        h2{
        border-bottom: 1px solid #E7E7ED;
        padding: 24px;
        }
        .info-section{
          padding:0px 24px;
          label{
            font-size: 14px;
            line-height: 21px;
            color:#272833;
            font-weight: 600;
          }
          p{
            margin:0px;
            margin-top:10px;
            font-size: 14px;
            line-height: 24px;
            color: #6B6C7E;
            display: flex;
            flex-direction: column;
          }
        }
        .table-wrap{
          margin-top: 24px;
        }
     }
  }
}
`;

export default RequisitionStyled;
