import { ActionTypes } from './actionType';
const intialState = {
  rScore: [],
  P3MRscore: [],
  P3WRscore: [],
  businessRscore: [],
  loading: false,
  error: false,
  errorMsg: '',
};

export const rScoreReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SEND_RSCORE_REQ:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case ActionTypes.SEND_P3M_RSCORE_REQ:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case ActionTypes.SEND_P3W_RSCORE_REQ:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case ActionTypes.SEND_RSCORE_SUC:
      return {
        ...state,
        loading: false,
        rScore: payload,
        error: false,
        businessRscore: [...new Set(payload?.map(data => data.bu))],
      };

    case ActionTypes.SEND_P3M_RSCORE_SUC:
      return {
        ...state,
        loading: false,
        P3MRscore: payload,
        error: false,
      };

    case ActionTypes.SEND_P3W_RSCORE_SUC:
      return {
        ...state,
        loading: false,
        P3WRscore: payload,
        error: false,
      };

    case ActionTypes.SEND_RSCORE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: payload,
      };

    case ActionTypes.SEND_P3M_RSCORE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: payload,
      };

    case ActionTypes.SEND_P3W_RSCORE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
