import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import ViewType from '../../../../../components/ViewType';
import { TableBody, TableHeaderConfig } from './tableConfig';
import PanelCard from '../../../../../../components/Cards/PanelCard';
import withLoading from '../../../../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    list: PropTypes.array,
    month: PropTypes.object,
  }),
};


const defaultProps = {
};

const View = ({ ...props }) => {
  const {
    data: { details, list }, viewTypeConfig, handleViewTypeChange,
  } = props;
  let sn = 0;
  return (
    <>
      <PanelCard cardTitle="Details" className="card_one">
        <div className="details-info">
          <Row>
            <Col md={3}>
              <div className="info">
                <label>PRID</label>
                <p>{details.id}</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="info">
                <label>Date</label>
                <p>{details.date}</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="info">
                <label>Channel</label>
                <p>{details.channel}</p>
              </div>
            </Col>
          </Row>
        </div>
      </PanelCard>
      <PanelCard cardTitle="Sku" className="card_two">
        <caption>
          <span>SKU</span>
          <ViewType
            handleDropDownChange={handleViewTypeChange}
            list={viewTypeConfig.list}
            value={viewTypeConfig.value}
            displayLabel={false}
          />
        </caption>
        <div className="table-wrap">
          <table>
            {list.length > 0 && (
              <TableHeaderConfig
                viewType={viewTypeConfig.value}
              />
            )}
          <>
            <tbody>

              {list.map((sku, index) => (
                <>
                  <tr key={`${index}-sku`} className="brand">
                    <td colSpan="4">{sku.group}</td>
                    <td colSpan="6" />
                  </tr>
                  { sku.skuList.map((a) => {
                    sn += 1;
                    return (
                      TableBody({
                        sn,
                        data: a,
                        viewType: viewTypeConfig.value,
                      })
                    );
                  })}
              </>
              ))}
            </tbody>
          </>
          </table>
        </div>
      </PanelCard>
    </>

  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
