import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from './View';
import SettingStyled from './SettingStyled';
import history from '../../../../../utils/history';
import PageHeader from '../../../../base/PageHeader';
import { breadCrumbConfig, title, urlIdToRoute } from './config';
import { PanelStyled } from '../../../../common/configuration';
import withAlert from '../../../../../utils/composition/withAlert';

const propTypes = {};

const defaultProps = {};

class MTSettings extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  onIconClick = (urlId) => {
    const url = urlIdToRoute.find((item) => item.id === Number(urlId)).path;
    history.push(url);
  };

  render() {
    return (
      <SettingStyled>
        <div className='section-header'>
          <PanelStyled>
            <PageHeader
              config={{
                title,
              }}
              breadCrumb={breadCrumbConfig}
            />
          </PanelStyled>
        </div>
        <div className='section-content settings '>
          <View onIconClick={this.onIconClick} />
        </div>
      </SettingStyled>
    );
  }
}

MTSettings.propTypes = propTypes;

MTSettings.defaultProps = defaultProps;

export default withAlert()(MTSettings);
