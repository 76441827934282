import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_JBP_TEMPLATE_LIST = gql`
  query getRscoreJBPTemplateList(
    $limit: Int
    $offset: Int
    $filter: FilterInput 
    $buId: Int!
    ){
      getRscoreJBPTemplateList(
        limit:$limit
        offset:$offset
        filter:$filter
        buId:$buId
        ){
          rows{
            id
            title
            active
            end_date
            start_date
          }
          count
        }
    }
`;

const TOGGLE_JBP_STATUS = graphql(gql`
    mutation toggleJBPTemplate($id: Int,$status: Boolean){
      toggleJBPTemplate(id: $id,status: $status){
        id
        title
        active
        end_date
      }
    }`, {
  props: ({ mutate }) => ({
    toggleJBPTemplate: variables => mutate({
      variables,
    }),
  }),
});

const query = {
  getJBPTemplateList: GET_JBP_TEMPLATE_LIST,
};

const mutation = {
  toggleJBPTemplate: TOGGLE_JBP_STATUS,
};

export {
  query, mutation, TOGGLE_JBP_STATUS,
};
