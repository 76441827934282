import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import DetailView from './View';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
import withImageSlider from '../../../../../utils/composition/withImageSlider';

const propTypes = {
  data: PropTypes.shape({
    list: PropTypes.instanceOf(Array),
    total: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
  loadingData: PropTypes.bool,
  serverResponseWaiting: PropTypes.bool,
  onRowClick: PropTypes.func.isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  activeCallIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onOrderStatusChangeClick: PropTypes.func.isRequired,
};

const defaultProps = {
  loadingData: false,
  serverResponseWaiting: false,
};

class Detail extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { data: nextProps.data };
  }

  constructor(props) {
    super(props);
    this.state = {
      /* dialog: {
        type: '',
        element: '',
      },
      selectedOrders: [],
      tempSelectedOrders: [],
      enableErrorDisplay: false, */
    };
  }

  componentDidMount() {}

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  resetDialog = () => {};

  handleDialogSubmit = (type, data) => {};

  render() {
    const {
      data,
      onRowClick,
      activeCallIndex,
      loadingData,
      handleSliderOpen,
      serverResponseWaiting,
      onOrderStatusChangeClick,
    } = this.props;
    return (
      <Fragment>
        {/*  <DetailWraper>
          <div className={type.toLowerCase()}>
            {type && (
              <DialogFormWrapper
                formConfig={{}}
                dialogElement={dialog.element}
                onDialogSubmit={this.handleDialogSubmit}
                onDialogCancel={this.resetDialog}
                type={type}
                renderDialog={
                  (
                    {
                      refsObj,
                      dialogData,
                      handleInputChange,
                      enableErrorDisplay,
                      handleDropDownChange,
                    },
                  ) => (
                    <Fragment>
                     <div></div>
                    </Fragment>
                  )}
              />
            )}
          </div>
        </DetailWraper> */}
        <DetailView
          data={data.list}
          onRowClick={onRowClick}
          activeCallIndex={activeCallIndex}
          loading={serverResponseWaiting}
          receivedLoading={loadingData}
          handleSliderOpen={handleSliderOpen}
          onOrderStatusChangeClick={onOrderStatusChangeClick}
        />
      </Fragment>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

const composedDetailWithAlert = withAlert()(Detail);

export default withImageSlider(composedDetailWithAlert);
