import styled from 'styled-components';

const FundamentalStyled = styled.div`
.table-wrap{
  tbody{
    tr{
      cursor: pointer;
      button{
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF;
      }
      &:hover {
        button{
          background-color: #0E69FF;
          border-color: #0E69FF;
      }
        }
    }
    td{
      padding: 12px;
    }
  }
  .form-select-input {
    label{
      display: none;
    }
    margin-bottom: 0;
  }
}
`;

export default FundamentalStyled;
