import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import withImageSlider from '../../../../../utils/composition/withImageSlider';
import DetailForm from './Form';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import withLoading from '../../../../../utils/composition/withLoading';
import withFormWrapper from '../../../../../utils/composition/withFormWrapper';

const propTypes = {
  getImages: PropTypes.func,
  getDetails: PropTypes.func,
  getStatus: PropTypes.func,
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  formConfig: PropTypes.shape({
    mapper: PropTypes.func,
    refsObj: PropTypes.instanceOf(Object),
    title: PropTypes.string,
    validationRequired: PropTypes.bool,
  }),
  update: PropTypes.instanceOf(Object),
  vendorList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
  distributorExist: PropTypes.bool,
  getFormValidationStatus: PropTypes.func,
  dialogData: PropTypes.instanceOf(Object),
  updateDistributorServices: PropTypes.func,
  imageValidation: PropTypes.bool,
  handleSliderOpen: PropTypes.func.isRequired,
};

const defaultProps = {
  update: {
    type: '',
    status: false,
  },
  distributorExist: false,
  vendorList: [],
  distributorList: [],
  getStatus: () => null,
  getDetails: () => null,
  getImages: () => null,
  enableErrorDisplay: false,
  handleInputChange: () => null,
  handleDropDownChange: () => null,
  formConfig: {
    mapper: () => ({}),
    refsObj: {},
    title: '',
    validationRequired: true,
  },
  getFormValidationStatus: () => null,
  dialogData: {},
  updateDistributorServices: () => null,
  imageValidation: false,
};

class SummaryDetails extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    const { getStatus, getFormValidationStatus, getDetails } = this.props;
    getStatus(getFormValidationStatus);
    getDetails(this.exportData);
  }

  exportData = () => {
    const { dialogData } = this.props;
    return dialogData;
  };

  render() {
    const {
      update,
      handleInputChange,
      handleDropDownChange,
      dialogData,
      formConfig,
      getImages,
      enableErrorDisplay,
      vendorList,
      distributorList,
      distributorExist,
      updateDistributorServices,
      imageValidation,
      handleSliderOpen,
      pendingStatus,
    } = this.props;

    return (
      <Fragment>
        <DetailForm
          show
          update={update}
          data={dialogData}
          enableErrorDisplay={enableErrorDisplay}
          handleInputChange={handleInputChange}
          handleDropDownChange={handleDropDownChange}
          updateDistributorServices={updateDistributorServices}
          vendorList={vendorList}
          distributorList={distributorList}
          refsObj={formConfig[FORM_CONFIG.REFS_OBJ]}
          distributorExist={distributorExist}
          getImages={getImages}
          imageValidation={imageValidation}
          onImageClick={handleSliderOpen}
          pendingStatus={pendingStatus}
        />
      </Fragment>
    );
  }
}

SummaryDetails.propTypes = propTypes;

SummaryDetails.defaultProps = defaultProps;

const SummaryDetailsWithImageSlider = withImageSlider(SummaryDetails);

export default withLoading(withFormWrapper(SummaryDetailsWithImageSlider));
