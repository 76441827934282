import React from 'react';
import PropTypes from 'prop-types';
import { REQUISITION_VIEW } from './config';
import TableHeader from '../../../../../base/TableHeader';
import { normalPresentor } from '../../../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';


const labelMappings = {
  sn: 'sn',
  sku: 'sku',
  lastPurchasedDate: 'lastPurchasedDate',
  p3mOfftake: 'p3mOfftake',
  p1mOfftake: 'p1mOfftake',
  stock: 'stock',
  quantity: 'quantity',
  rate: 'rate',
  amount: 'amount',
};

const viewBasedHeaderConfig = {
  [REQUISITION_VIEW.BRAND]: [{
    id: 1,
    label: labelMappings.sn,
    title: 'SN',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.lastPurchasedDate,
    title: 'Last Purchased Date',
    className: 'print_display_none',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.p3mOfftake,
    title: 'P3M Offtake',
    show: true,
    className: 'text-right print_display_none',
  },
  {
    id: 5,
    label: labelMappings.p1mOfftake,
    title: 'P1M Offtake',
    show: true,
    className: 'text-right print_display_none',
  },
  {
    id: 6,
    label: labelMappings.stock,
    title: 'Warehouse Stock',
    show: true,
    className: 'text-right print_display_none',
  },
  {
    id: 7,
    label: labelMappings.quantity,
    title: 'Quantity',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.rate,
    title: 'Rate',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
  },
  ],
};

const TableHeaderConfig = ({ viewType }) => (
  <TableHeader
    headerDetails={viewBasedHeaderConfig[viewType]}
  />
);

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
];

const returnTableBody = (sn, data, onIconClick) => {
  const table = {};
  table[labelMappings.sn] = <td key={`${data.id}- id`}>{sn}</td>;
  table[labelMappings.sku] = <td key={`${data.id}- title`}>{data.sku}</td>;
  table[labelMappings.lastPurchasedDate] = (
    <td key={`${data.id}- lastPurchasedDate`} className="text-center print_display_none">
      {(normalPresentor(data.lastPurchasedDate) === 'Invalid date')
        ? '-' : normalPresentor(data.lastPurchasedDate)}
    </td>
  );
  table[labelMappings.p3mOfftake] = <td key={`${data.id}-p3mOfftake`} className="text-right print_display_none">{data.p3mOfftake}</td>;
  table[labelMappings.p1mOfftake] = <td key={`${data.id}-p1mOfftake`} className="text-right print_display_none">{data.p1mOfftake}</td>;
  table[labelMappings.stock] = <td key={`${data.id}-stock`} className="text-right print_display_none">{data.stock}</td>;
  table[labelMappings.quantity] = (
    <td key={`${data.id}-quantity`}>
      <p>{`${parseInt(data.quantity / data.upc)}cs`}</p>
      <p>{`${parseInt(data.quantity % data.upc)}pcs`}</p>
    </td>
  );
  table[labelMappings.rate] = <td key={`${data.id}-rate`}>{fixedFloatAndCommas(data.rateDetails.rlp)}</td>;
  table[labelMappings.amount] = <td key={`${data.id}-amount`}>{fixedFloatAndCommas(data.amountDetails.netAmount)}</td>;

  return table;
};

const TableBody = ({
  sn,
  data,
  viewType,
  onIconClick,
}) => {
  const tableBody = returnTableBody(sn, data, onIconClick);
  return (
    <tr
      key={data.id}
    >
      {viewBasedHeaderConfig[viewType].map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableHeaderConfig.propTypes = {
  viewType: PropTypes.string,
};

TableHeaderConfig.defaultProps = {
  viewType: '',
};

TableBody.propTypes = {
  sn: PropTypes.number,
  data: PropTypes.instanceOf(Object),
  viewType: PropTypes.string,
  onIconClick: PropTypes.func.isRequired,
};
TableBody.defaultProps = {
  sn: 0,
  data: {},
  viewType: '',

};

export { TableHeaderConfig, TableBody };
