import { useSelector } from 'react-redux';
import { Line, ResponsiveContainer } from 'recharts';
import Linechart from '../../../../../components/Chart/LineChart';
import { filterItems } from '../../../../../utils/arrayProcessor';
import CustomLegend from '../../../components/Legend/CustomLegend';
import { AuditWiseOutletChannelPercent, capitalizeFirstLetter, RSCORE_P3M_SORT_WEEKWISE_KEYS, modifyWeekYearWise } from '../../../config';
import { brandColor, data, dimension } from './config';
import { P3MCategoryStyled } from './P3MCategoryStyled';

const P3MCategory = ({ brand }) => {
  const P3MRscore = useSelector(state => state?.RscoreDatas?.P3MRscore);
  const RSCORE_BU = filterItems(P3MRscore, brand, 'bu');

  // for share of availability
  const SHARE_OF_AVAILABILITY = filterItems(RSCORE_BU, 'Share of availability', 'fundamental');
  const AUDIT_PERIOD_WISE = _(SHARE_OF_AVAILABILITY).groupBy('audit_period').value();
  const _AuditWiseOutletChannelPercent = AuditWiseOutletChannelPercent(AUDIT_PERIOD_WISE);
  const RScore_keys = RSCORE_P3M_SORT_WEEKWISE_KEYS(_AuditWiseOutletChannelPercent);

  // for share of display
  const SHARE_OF_DISPLAY = filterItems(RSCORE_BU, 'Share of display', 'fundamental');
  const AUDIT_PERIOD_WISE_SOD = _(SHARE_OF_DISPLAY).groupBy('audit_period').value();
  const _AuditWiseOutletChannelPercentSod = AuditWiseOutletChannelPercent(AUDIT_PERIOD_WISE_SOD);
  const RScore_keys_sod = RSCORE_P3M_SORT_WEEKWISE_KEYS(_AuditWiseOutletChannelPercentSod);

  // for share of shelf
  const SHARE_OF_SHELF = filterItems(RSCORE_BU, 'Share of shelf', 'fundamental');
  const isCategoryHeader = SHARE_OF_SHELF?.every(item => item?.brand === null); //for dynamic table header i.e brand or category
  const groupByLabel = !isCategoryHeader ? 'brand' : 'category';
  const AUDIT_PERIOD_WISE_SHELF = _(SHARE_OF_SHELF).groupBy(groupByLabel).value();
  let category = [];
  for (const property in AUDIT_PERIOD_WISE_SHELF) {
    const categoryWise = AUDIT_PERIOD_WISE_SHELF[property];
    const groupByaudit = _.groupBy(categoryWise, 'audit_period');
    const categoryWisePercent = AuditWiseOutletChannelPercent(groupByaudit);
    category.push({ categoryWisePercent, category: capitalizeFirstLetter(property) });
  }

  return (
    <>
      <P3MCategoryStyled>
        <div className="title-category"> R-Score P3M History (Category) </div>
        <CustomLegend data={data} />

        <div>
          <div className="title-fundamental" style={{ marginBottom: '20px' }}>
            On Shelf Availability
          </div>
          <div style={{ width: '45%' }}>
            <ResponsiveContainer width="100%" aspect={3}>
              <Linechart
                data={modifyWeekYearWise(_AuditWiseOutletChannelPercent)}
                dimensions={dimension}
                isXAxis={true}
                isYAxis={true}
                legend={false}
                cartesianGrid={true}
                tooltip={true}
                color={brandColor}
                xAxisTitle="Score"
                yAxisTitle="Weeks"
                title=""
                aspect={2}
              >
                {RScore_keys?.map(item => (
                  <Line
                    type="monotone"
                    dataKey={item}
                    stroke={brandColor[item]}
                    strokeWidth={1.5}
                    fill={brandColor[item]}
                    isAnimationActive={false}
                  />
                ))}
              </Linechart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* share of display  */}
        {_AuditWiseOutletChannelPercentSod.length > 0 && (
          <>
            <div className="title-fundamental" style={{ marginBottom: '20px' }}>
              Share of Display
            </div>
            <div style={{ width: '45%' }}>
              <ResponsiveContainer width="100%" aspect={3}>
                <Linechart
                  data={modifyWeekYearWise(_AuditWiseOutletChannelPercentSod)}
                  dimensions={dimension}
                  isXAxis={true}
                  isYAxis={true}
                  legend={false}
                  cartesianGrid={true}
                  tooltip={true}
                  color={brandColor}
                  xAxisTitle="Score"
                  yAxisTitle="Weeks"
                  title=""
                  aspect={2}
                >
                  {RScore_keys_sod?.map(item => (
                    <Line
                      type="monotone"
                      dataKey={item}
                      stroke={brandColor[item]}
                      strokeWidth={1.5}
                      fill={brandColor[item]}
                      isAnimationActive={false}
                    />
                  ))}
                </Linechart>
              </ResponsiveContainer>
            </div>
          </>
        )}

        <div>
          <div className="title-fundamental" style={{ marginBottom: '-20px' }}>
            Share of Shelf
          </div>
          <div className="chart-wrap">
            {category?.map(item => (
              <div style={{ width: '48%' }}>
                <ResponsiveContainer width="100%" aspect={3}>
                  <Linechart
                    data={modifyWeekYearWise(item?.categoryWisePercent)}
                    dimensions={dimension}
                    isXAxis={true}
                    isYAxis={true}
                    legend={false}
                    cartesianGrid={true}
                    tooltip={true}
                    color={brandColor}
                    xAxisTitle="Score"
                    yAxisTitle="Weeks"
                    title={item?.category}
                    aspect={2}
                  >
                    {RScore_keys?.map(cat => (
                      <Line
                        type="monotone"
                        dataKey={cat}
                        stroke={brandColor[cat]}
                        strokeWidth={2}
                        fill={brandColor[cat]}
                        isAnimationActive={false}
                      />
                    ))}
                  </Linechart>
                </ResponsiveContainer>
              </div>
            ))}
          </div>
        </div>
      </P3MCategoryStyled>
    </>
  );
};

export default P3MCategory;
