import gql from 'graphql-tag';
import { GET_CHANNEL_LIST, GET_CATEGORY_LIST } from '../../views/configuration/channel/API';
import {GET_OUTLET_CHAIN} from '../../views/configuration/MT/inventory/API'

import {
  clientResolver,
  apolloClient,
  DEFAULT_QUERY_VARIABLES,
  errorProcessor,
} from './common';

const TABLE = {
  CHANNEL: 'channel',
  CATEGORY: 'category',
};

const getChannelFromServer = () =>
  apolloClient
    .query({
      query: GET_CHANNEL_LIST,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then((response) => response.data.channels.rows)
    .catch((err) => errorProcessor(err));

const getCategoryFromServer = () =>
  apolloClient
    .query({
      query: GET_CATEGORY_LIST,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then((response) => response.data.categories.rows)
    .catch((err) => errorProcessor(err));

const query = gql(`
    query getChannel {
        ${TABLE.CHANNEL} {
            id,
            title,
        }
    }
`);

const categoryQuery = gql(`
    query getCategory {
        ${TABLE.CATEGORY} {
            id,
            title,
            Channel {
                id
            },
        }
    }
`);
const GET_OUTLET_LIST = gql`
    query getRetailOutlet($offset: Int, $limit: Int, $filter: FilterInput) {
        retailOutlets(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                id
                title
            }
            count
        }
    }
`;

const GET_MT_LIST = gql`
    query getMTStockList($offset: Int, $limit: Int, $filter: FilterInput) {
        getMTStockList(offset: $offset, limit: $limit, filter: $filter) {
            rows {
                id
                title
                count_days
                countskus
                assigned_to
            }
            count
        }
    }
`;

const GET_CATALOG_DETAIL = gql`
  query  getCatalogDetail ($limit: Int, $offset: Int, $catalogId: Int = 1, $parentCatalogDetailId: Int, $includeSku: Boolean = false) {
    catalogDetails(limit: $limit, offset: $offset, catalogId: $catalogId, parentCatalogDetailId: $parentCatalogDetailId) {
      rows {
        id 
        title
        parentCatalogDetailId
          skus: SKUs @include(if: $includeSku) {
                id
                title
          }     
      }
    }
  }
 
`;

const setCategory = (data) => {
  clientResolver.then((client) =>
    client.writeQuery({
      query: categoryQuery,
      data: {
        [TABLE.CATEGORY]: data,
      },
    }),
  );
};

const setChannel = (data) => {
  clientResolver.then((client) =>
    client.writeQuery({
      query,
      data: {
        [TABLE.CHANNEL]: data,
      },
    }),
  );
};

const getChannel = async () =>
  clientResolver.then((client) => client.readQuery({ query })).then((response) => response[TABLE.CHANNEL] || []);

const getCategory = async (channelList = []) =>
  clientResolver
    .then((client) => client.readQuery({ query: categoryQuery }))
    .then((response) => {
      const categoryList = response[TABLE.CATEGORY] || [];
      if (!channelList.length) {
        return categoryList.filter((cat) => channelList.includes(cat.Channel.id));
      }

      return categoryList;
    });

const fetchAndSetChannel = async () => {
  const channelList = await getChannelFromServer();
  setChannel(channelList);
};

const fetchAndSetCategory = async () => {
  const categoryList = await getCategoryFromServer();
  setCategory(categoryList);
};

const fetchAndSetChannelCategory = async () => {
  fetchAndSetChannel();
  fetchAndSetCategory();
};

const getBusinessType = () => [
  {
    id: 'MT',
    title: 'MT',
  },
  {
    id: 'GT',
    title: 'GT',
  },
  {
    id: 'B2B',
    title: 'B2B',
  },
];

const getoutletChainFromServer = () => (
  apolloClient
    .query({
      query: GET_OUTLET_CHAIN,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then(response => response.data.getOutletChain)
    .catch(err => errorProcessor(err))
);
const getRetailOutlet = () => (
  apolloClient
    .query({
      query: GET_OUTLET_LIST,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then(response => response.data.retailOutlets.rows)
    .catch(err => errorProcessor(err))
);

const getFrequency = () => (
  apolloClient
    .query({
      query: GET_MT_LIST,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then(response => response.data.getMTStockList.rows)
    .catch(err => errorProcessor(err))
);

const getBU = () => (
  apolloClient
    .query({
      query: GET_CATALOG_DETAIL,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then(response => response.data.catalogDetails.rows)
    .catch(err => errorProcessor(err))
);
export {
  getChannelFromServer as getChannel,
  getCategoryFromServer as getCategory,
  fetchAndSetChannel,
  fetchAndSetCategory,
  fetchAndSetChannelCategory,
  getBusinessType,
  getoutletChainFromServer as getOutlet,
  getRetailOutlet,
  getFrequency,
  getBU
};
