import styled from 'styled-components';

export const MrBrandStyled = styled.div`
  border: 1px solid #e7e7ed;
  margin: 20px 0;
  border-radius: 4px;

  .table-wrap {
    margin-top: 70px;
    border-radius: 6px;

    .light {
      background-color: #287d3c;
      color: #ffffff;
      font-weight: 500;
      width: 60px;
      height: 20px;
      font-size: 11px;
      letter-spacing: 0.4px;
    }

    table {
      th {
        width: 15%;
      }
      tr {
        height: 40px;
      }
    }
  }

  .barchart-yaxis {
    margin-bottom: -20px;
  }
  .compliance-above {
    background-color: #5aca75;
    color: #ffffff;
    font-weight: 500;
    width: 65px;
    height: 20px;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 1;
  }

  .compliance-under {
    background-color: #da1414;
    color: #ffffff;
    font-weight: 500;
    width: 65px;
    height: 20px;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 1;
  }

  .compliance-middle {
    background-color: #ff8f39;
    color: #ffffff;
    font-weight: 500;
    width: 65px;
    height: 20px;
    font-size: 11px;
    letter-spacing: 0.4px;
    line-height: 1;
  }

  .mr-outlet {
    display: flex;
    align-items: center;
    span {
      padding-left: 10px;
    }
  }

  .td-right {
    text-align: right;
  }
  .td-middle {
    text-align: center;
  }
  .text-middle {
    text-align: center;
  }
`;
