
import { MT_BU, mt } from '../../../../../../../../data/enums/Route';
import { getCurrentDay } from '../../../../../../../../utils/date';

const title = 'SBD Templates';
const errorMessageSKU = 'Atleast one SKU should be selected.';
const breadCrumb = [mt,
  {
    id: 1,
    title: 'Rscore',
    path: '#',
    active: true,
  },
  {
    id: 2,
    title: 'BU',
    path: `/${MT_BU}`,
    active: true,
  }];

const formMapper = (id, element = {}) => ({
  title: element.title || '',
  channel_id: element.channel_id || null,
  sbdcategory: element.sbdcategory || null,
  principal_id: id || 0,
  outlet_id: element.outlet_id || null,
  start_date: element.start_date || getCurrentDay(),
  end_date: element.end_date || getCurrentDay(),
});

const getPayload = (data, pid, createJBPId) => {
  const {
    active, numberofSkus, id, tags, ...newBasicData
  } = data.basicData;
  return ({
    basicData: {
      ...newBasicData, principal_id: pid,
    },
    sbdSku: data.sbdSku || [],
    jbpTemplateId: createJBPId || null,
  });
};
export {
  formMapper, breadCrumb as breadCrumbConfig, getPayload, title, errorMessageSKU,
};
