import gql from 'graphql-tag';

const USER_TARGET_ACHIEVEMENT_FRAGMENT = gql`
  fragment UserTargetAchievementFragment on UserTargetPerMonth {
    id
    name
    target
    achievement
    role
    isChildPresent
  }
`;

const FULL_USER_TARGET_ACHIEVEMENT_FRAGMENT = gql`
  fragment FullUserTargetAchievementFragment on UserTargetPerMonth {
    ...UserTargetAchievementFragment
    childUsers: ChildUsers {
      ...UserTargetAchievementFragment
      childUsers: ChildUsers {
        ...UserTargetAchievementFragment
        childUsers: ChildUsers {
          ...UserTargetAchievementFragment
          childUsers: ChildUsers {
            ...UserTargetAchievementFragment
            childUsers: ChildUsers {
              ...UserTargetAchievementFragment
              childUsers: ChildUsers {
                ...UserTargetAchievementFragment
              }
            }
          }
        }
      }
    }
  }
  ${USER_TARGET_ACHIEVEMENT_FRAGMENT}
`;

const SUB_D_TARGET_ACHIEVEMENT_FRAGMENT = gql`
  fragment SubdTargetAchievementFragment on SubDTargetDetail {
    id
    name
    target
    achievement
    childUsers: users {
      id
      name
      target
      achievement
      outlets {
        id
        name
        town
        channel
        category
        achievement
      }
    }
  }
`;

const GET_USER_TARGET_ACHIEVEMENT = gql`
  query getUserTargetAchievement($filter: FilterInput) {
    getUserWiseTargetAchievement(filter: $filter) {
      ...FullUserTargetAchievementFragment
    }
  }
  ${FULL_USER_TARGET_ACHIEVEMENT_FRAGMENT}
`;

const GET_SUB_D_TARGET_ACHIEVEMENT = gql`
  query getSubDTargetAchievement($filter: FilterInput) {
    getSubDUserTargetAchievement(filter: $filter) {
      ...SubdTargetAchievementFragment
    }
  }
  ${SUB_D_TARGET_ACHIEVEMENT_FRAGMENT}
`;

const query = {
  getUserTarget: GET_USER_TARGET_ACHIEVEMENT,
  getSubDTarget: GET_SUB_D_TARGET_ACHIEVEMENT,
};

export { query };
