import React from 'react';
import { Pagination } from '../../../../components';
import withLoading from '../../../../utils/composition/withLoading';
import { TableBody, TableHeader } from './tableConfig';

const View = (props) => {
  const {
    data, onRowClick, pagination, onPageChange,
  } = props;
  return (
    <>
      <div className="table-wrap">
        <table>
          {
            TableHeader()
          }
          <tbody>
            {data.list.map(purchaseOrder => (
              TableBody({ data: purchaseOrder, onRowClick })
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </>
  );
};

const ViewWithErrorAndLoading = withLoading(View);
export default ViewWithErrorAndLoading;
