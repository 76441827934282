import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MTDetailView from './View';
import {
  CLIENT_STORAGE_TABLE,
  getDataFromLocalStorage,
} from '../../../../data/services';
import * as queryService from '../../../base/query.service';
import withAlert from '../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import MTDialog from '../components/MTDialog';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { isError } from '../../../common/HelperFunctions';
import { PAGINATION } from '../../../../data/enums';

const propTypes = {
  getMTBasicDetail: PropTypes.func.isRequired,
  getSkuDetails: PropTypes.func.isRequired,
  getBrandDetails: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  insertMTDetails: PropTypes.func.isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Details extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const params = props.match.params ? props.match.params : {};
    const { id } = params;
    this.state = {
      data: {
        categoryId: '',
        productCategoryId: '',
        brandId: '',
        skuId: '',
      },
      menu: {
        channelList: [],
        categoryList: [],
        buList: [],
      },
      buId: 0,
      buList: [],
      channelId: 0,
      categoryId: 0,
      brandList: [],
      brandId: 0,
      skuList: [],
      skuGroupList: [],
      skuId: 0,
      mtId: parseInt(props.match.params.id, 10),
      showBrands: false,
      skus: [],
      dialog: {
        type: '',
        element: '',
      },
      basicDetail: {},
      checkedSkuFamilies: [],
      mtDetails: {
        BU: [],
        Channel: [],
        Category: [],
        Brand: [],
      },
      totalSkuFamilies: [],
      frequency: [],
      assignedTo: [],
      categoryList: [{
        title: '',
        categories: [],
      }],
      id: parseInt(id, 10),
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
      },
      productCategoryList: [],
      filteredBrandList: [],
      skuFamilyList: [],
      count: {
        category: [],
        productCategories: [],
        brands: [],
        skuFamilies: [],
        skuFamiliesList: [],
      },
      skuAssortedList: [],
      searchText: '',
      SKUFamilyList: [],
      currentSkuFamilySkus: [],
    };
  }

  componentDidMount() {
    this.getFrequencyAndUsers();
    this.getMTBasicDetails();
    this.getCategoryList();
    this.getBrandList();
  }

  getMTBasicDetails = () => {
    const { getMTBasicDetail, match } = this.props;
    const { id } = match.params;

    getMTBasicDetail(
      {
        offset: 0,
        limit: PAGINATION.LIMIT,
        filter: {
          filters: [{
            column: 'id',
            value: id,
          }],
        },
      },
      {
        handleSuccess: (response) => {
          const { rows } = response.data.getMTStockList;
          let { basicDetail } = this.state;
          basicDetail = rows.find(el => el.id === Number(id));

          this.setState({
            basicDetail,
          });
        },
      },
    );
  }

  getMTSKUsCount = (ids, status) => {
    const { getMTStockCount, match } = this.props;
    const { id } = match.params;
    const {
      data, count,
    } = this.state;
    const {
      categoryId, productCategoryId, brandId, skuId,
    } = data;
    getMTStockCount({
      mtstockId: parseInt(id, 10) || 0,
      buId: parseInt(categoryId, 10) || 0,
      categoryId: parseInt(productCategoryId, 0) || 0,
      brandId: parseInt(brandId, 10) || 0,
    }, {
      handleSuccess: (response) => {
        let { skuAssortedList, currentSkuFamilySkus } = this.state;
        const { getMTStockDetails } = response.data;
        count.category = getMTStockDetails.rows[0].bu;
        count.brands = getMTStockDetails.rows[0].Brand;
        count.productCategories = getMTStockDetails.rows[0].Category;
        count.skuFamilies = getMTStockDetails.rows[0].Sku;
        count.skuFamiliesList = getMTStockDetails.rows[0].SkuFamily;
        skuAssortedList = count.skuFamilies.map(sku => sku.skus);
        currentSkuFamilySkus = count.skuFamilies.map(skuFam => skuFam.skus);
        this.setState({ skuAssortedList, count }, () => {
          if (status) {
            const test = new Set([...skuAssortedList, ...ids]);
            this.setState({ currentSkuFamilySkus, skuAssortedList: [...(Array.from(test) || [])] });
          }
        });
      },
      handleError: (err) => {
        this.handleApiFailure(err);
      },
    });
  };

  getSkuDetails = (id) => {
    const { skuGroupList } = this.state;
    const { getSkuDetails } = this.props;

    getSkuDetails(
      {
        value: id,
      },
      {
        handleSuccess: (response) => {
          const { catalogLevelDetails } = response.data;
          const catalogSkus = catalogLevelDetails.rows[0].SkuFamilies;
          const groupedSkus = skuGroupList;

          const filterSku = catalogSkus
            .filter(sku => groupedSkus
              .find(grouped => grouped.skus.includes(sku.id)));

          const nonFilteredSku = catalogSkus
            .filter(sku => !groupedSkus
              .find(grouped => grouped.skus.includes(sku.id)));

          this.setState({
            skuList: nonFilteredSku,
            skus: [],
            checkedSkuFamilies: [],
            totalSkuFamilies: catalogSkus,
            skuGroupList: filterSku.length !== 0 ? skuGroupList : [],
          });
        },
      },
    );
  };

  handleToggleState = (toggleStatus, ids) => {
    const submittingDataArray = [];
    const { createMTStockDetails, displayAlert, deleteMTStockDetail } = this.props;
    const {
      data, mtId, skus, skuFamilyList,
    } = this.state;
    const {
      categoryId, productCategoryId, brandId, skuId,
    } = data;

    for (let i = 0; i < ids.length; i++) {
      const skuFamily = skuFamilyList.find((el) => {
        if (el.skus.find(sku => sku.id === ids[i])) {
          return true;
        }
      });
      const submittingData = {
        principal_id: categoryId,
        stock_audit_id: mtId,
        sku_id: ids[i],
        product_category_id: productCategoryId,
        brand_id: brandId,
        sku_family_id: skuFamily.id,
        active: true,
      };
      submittingDataArray.push(submittingData);
    }

    if (toggleStatus) {
      skus.push(parseInt(ids.toString(), 10));

      createMTStockDetails({
        input: submittingDataArray,
      }, {
        handleSuccess: (response) => {
          this.getMTSKUsCount(ids, toggleStatus);

          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          displayAlert(ALERT_TYPE.SUCCESS, 'MT updated successfully');
        },
        handleError: (err) => {
          this.handleApiFailure(err);
        },
      });
    } else {
      deleteMTStockDetail({
        skuId: ids,
        stockAuditId: mtId,
      }, {
        handleSuccess: (res) => {
          this.getMTSKUsCount();
          displayAlert(ALERT_TYPE.INFO, 'Disabled Successfully');
        },
      });
    }
  }

  handleIconClick = (action) => {
    const { basicDetail } = this.state;
    if (action === EVENT_OPERATION.UPDATE) {
      this.getFrequencyAndUsers(action);
    } else {
      this.setState({
        dialog: {
          type: action,
          element: basicDetail,
        },
      });
    }
  }

  handleDialogClose = () => {
    this.setState({
      dialog: {
        type: '',
        element: {},
      },
    });
  }

  handleDialogSubmit = (type) => {
    const { history } = this.props;

    if (type === EVENT_OPERATION.REPLICATE || type === EVENT_OPERATION.DELETE) {
      history.push('/configuration/mt');
    }
    this.getMTBasicDetails();
  }

  handleApiFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.handleDialogClose();
  };

  setCheckedSkuFamilies = (skuFamily) => {
    const { checkedSkuFamilies } = this.state;
    if (!checkedSkuFamilies.includes(skuFamily)) {
      this.setState({
        checkedSkuFamilies: [...checkedSkuFamilies, skuFamily],
      });
    } else {
      this.setState({
        checkedSkuFamilies: checkedSkuFamilies.filter(d => d !== skuFamily),
      });
    }
  }

  getFrequencyAndUsers = (action) => {
    const { assignedTo, basicDetail } = this.state;
    const { getRouteVisitFrequencyList, getUsersList, displayAlert } = this.props;

    getRouteVisitFrequencyList({
    }, {
      handleSuccess: (response) => {
        const { data: { routeVisitFrequencyList } } = response;
        const options = routeVisitFrequencyList.rows.map(row => ({ id: row.numberOfDays, label: row.label }));
        this.setState({ frequency: options });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });

    getUsersList({
      offset: 0,
      limit: 500,
      filter: {
        // filters: [
        //   {
        //     column: 'role_id',
        //     value: [
        //       USER_ROLE.DSE.toString(),
        //     ],
        //   },
        // ],
      },
    }, {
      handleSuccess: (response) => {
        const { data: { users } } = response;
        this.setState({ assignedTo: users.rows }, () => {
          if (action === EVENT_OPERATION.UPDATE) {
            this.setState({
              dialog: {
                type: action,
                element: basicDetail,
              },
            });
          }
        });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  }

  getCategoryList = () => { // bu List
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.PRINCIPAL).then(
      (response) => {
        const categoryList = [{ categories: response }];
        this.setState({ categoryList });
      },
    );

    this.getMTSKUsCount();
  };

  getProductGroupList = (id) => {
    const { getProductGroupDetails } = this.props;
    getProductGroupDetails({ parentCatalogDetailId: id }, {
      handleSuccess: (response) => {
        const productCategoryList = (response.data.catalogDetails
          && response.data.catalogDetails.rows) || [];
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState({ productCategoryList });
      },
      handleError: (error) => { this.onAPIRequestFailure(error); },
    });
  };

  getBrandList = () => {
    const { getBrandDetails } = this.props;
    getBrandDetails({}, {
      handleSuccess: (response) => {
        const brandList = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState({ brandList });
      },
      handleError: (error) => { this.onAPIRequestFailure(error); },
    });
  };

  getSKUFamilyList = (id) => {
    const { getSKUFamilyDetails } = this.props;
    getSKUFamilyDetails({
      parentCatalogDetailId: id,
    }, {
      handleSuccess: (response) => {
        const skuList = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
        const filteredSkuList = skuList.filter(i => i.skus && i.skus.length > 0);
        if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        this.setState({
          skuFamilyList: filteredSkuList,
          SKUFamilyList: filteredSkuList,
        });
      },
      handleError: (error) => { this.onAPIRequestFailure(error); },
    });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleListClick = (id) => {
    const { history } = this.props;
    history.push(`channel-assortment/details/${id}`);
  };

  handleCategoryChange = (id) => {
    const { data } = this.state;
    data.categoryId = id;
    data.productCategoryId = '';
    data.brandId = '';
    this.getProductGroupList(id);
    this.getMTSKUsCount();
    this.setState({
      data,
    });
  };

  handleProductCategoryClick = (groupId) => {
    const { data, brandList } = this.state;
    data.productCategoryId = groupId;
    data.brandId = '';
    const newBrandList = brandList
      .filter(brand => parseInt(brand.parentCatalogDetailId, 10) === groupId);
    this.getMTSKUsCount();
    this.setState({ data, filteredBrandList: newBrandList });
  };

  handleBrandClick = (brandId) => {
    const { data } = this.state;
    data.brandId = brandId;
    this.getSKUFamilyList(brandId);
    this.getMTSKUsCount();
    this.setState({ data });
  };

  handleSearchInput = (text) => {
    const { SKUFamilyList } = this.state;
    const regex = new RegExp(text, 'i');
    const bySkus = SKUFamilyList.filter(i => i.skus
      .filter(s => s.title.search(regex) > -1).length > 0);
    const bySKUFamilies = SKUFamilyList.filter(p => p.title.search(regex) > -1);
    const union = [...bySKUFamilies, ...bySkus];
    const result = union.filter((item, index) => union.indexOf(item) === index);

    this.setState({ skuFamilyList: result, searchText: text });
  };


  render() {
    const {
      dialog,
      frequency,
      assignedTo,
      data, queryParameters, display, categoryList, productCategoryList, filteredBrandList,
      skuFamilyList, count, skuAssortedList, searchText,
    } = this.state;

    const { serverResponseWaiting } = this.props;
    return (
      <>
        <MTDetailView
          {...this.state}
          onIconClick={this.handleIconClick}
          loading={serverResponseWaiting}
          setCheckedSkuFamilies={this.setCheckedSkuFamilies}
          data={data}
          onHandleClick={this.handleListClick}
          categoryList={categoryList[0].categories}
          onHandleCategoryChange={this.handleCategoryChange}
          productCategoryList={productCategoryList}
          onProductCategoryClick={this.handleProductCategoryClick}
          brandList={filteredBrandList}
          onBrandClick={this.handleBrandClick}
          SKUFamilyList={skuFamilyList}
          count={count}
          skuAssortedList={skuAssortedList}
          onToggle={this.handleToggleState}
          onHandleSearchInput={this.handleSearchInput}
          searchText={searchText}

        />
        {
          dialog.type && (
            <div className="sbd-modal">
              <MTDialog
                type={dialog.type}
                element={dialog.element}
                onSubmit={this.handleDialogSubmit}
                onClose={this.handleDialogClose}
                onApiFailure={this.handleApiFailure}
                frequency={frequency}
                assignedTo={assignedTo}
              />
            </div>
          )
        }
      </>
    );
  }
}

Details.propTypes = propTypes;

Details.defaultProps = defaultProps;

export default withAlert()(Details);
