import React from 'react';
import { Icon } from '..';
import { menuActionWithTitle } from '../../../utils/paginatedListUtils';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import CardStyled from './CardStyled';
import { Search } from '../../../components';

interface CardProps {
  onItemClick: (list: any) => void;
  handleSearchInput: any,
  title: string,
  selectedId: number,
  handleIconClick: any,
  data: [],
};

const menuConfigList = [
  {
    title: 'View',
    icon: '',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.READ,
  },
  {
    title: 'Edit',
    icon: '',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: '',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const Card = ({ data, onItemClick, handleSearchInput, title, selectedId, handleIconClick }: CardProps) => {
  return (
    <CardStyled>
      <div className='card-pannel'>
        <div className='title-section'>
          <label>{title}</label>
          <Icon
            iconName='plus'
            className='cursor-pointer'
            onClick={() => handleIconClick(EVENT_OPERATION.CREATE, {}, title, data)}
          />
        </div>
        <div className='body-section'>
          <Search hideInput={false} handleSearchInput={(text: any) => handleSearchInput(text, title, data)} />
          <div className='list-section'>
            <ul>
              {data.map((list: any) => (
                <li key={list.id} className={`${list.id === selectedId ? 'active' : 'inActive'}`}>
                  {menuActionWithTitle(menuConfigList, { menuIcon: 'ellipsis-v' }, handleIconClick, list, title)}
                  <span
                    key={list.id}
                    onClick={() =>
                      onItemClick
                        ? onItemClick(list)
                        : ''
                    }
                  >
                    <span>
                      {list.title}
                      <p>{list.levelIndication}</p>
                    </span>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </CardStyled>
  );
};

Card.defaultProps = {
  onItemClick: () => null,
  title: '',
  selectedId: 0,
};

export default Card;
