import { compose } from 'recompose';
import Fundamentals from './Fundamentals';
import { query } from './API';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';

const composedMT = compose(
  withRequestTracker({
    query,
  }),
)(Fundamentals);

export default composedMT;
