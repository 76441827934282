import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Map from './map/Map';
import { filterSelectedListDetails } from '../../../../../utils/arrayProcessor';
import { defaultOutletVisibility } from './map/config';
import { WIDGETS } from '../../../../../data/enums/config';
import { clone } from '../../../../../utils/objectProcessor';
import { ERROR } from '../../../../../data/enums/ErrorMessage';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
import { Search } from '../../../../../components';
import { isObjectEmpty } from '../../../../../utils/objectPrototypes';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { OUTLET_TYPES, filterFieldMapper, crudRequestConfig, filterConfig, serverDataMapper } from './config';
import ManageOutlets from './ManageOutlets';
import OutletsStyled from './OutletsStyled';
import { Filter, Icon,Button } from '../../../../../v4/components'

const propTypes = {
  getRouteList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  assignOutlets: PropTypes.func.isRequired,
  getRouteDetails: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  id: PropTypes.number.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Outlets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        assigned: {
          outlets: [],
          count: 0,
        },
        unassigned: {
          outlets: [],
          count: 0,
        },
        outletsInAnotherRoute: {
          outlets: [],
          count: 0,
        },
      },
      serverData: {},
      filter: {
        ...filterConfig.instance,
      },
      searchText: '',
      outletVisibility: clone(defaultOutletVisibility),
      routeList: {},
    };
    const serverCall = {
      [EVENT_OPERATION.ASSIGN]: props.assignOutlets,
      [EVENT_OPERATION.BULK_DELETE]: props.assignOutlets,
    };
    this.onFormSubmit = (crudMode, crudConfig, data) => {
      // eslint-disable-next-line react/prop-types
      const payload = crudConfig[crudMode].objectMapper(data);
      serverCall[crudMode](payload, {
        handleSuccess: res => {
          if (res.data[crudConfig[crudMode].responseName]) {
            this.displayMessage(ALERT_TYPE.SUCCESS, crudConfig[crudMode].message);
            if (crudMode === EVENT_OPERATION.ASSIGN) {
              this.onAssignToRouteSuccess(data.outlets, payload.input.destRouteId);
            }
            if (crudMode === EVENT_OPERATION.BULK_DELETE) {
              this.onRemoveFromRouteSuccess(data, payload.input.destRouteId);
            }
          } else this.displayMessage(ALERT_TYPE.DANGER, ERROR);
        },
        handleError: error => {
          this.displayMessage(ALERT_TYPE.DANGER, error);
        },
      });
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { getRouteDetails, id } = this.props;

    getRouteDetails(
      { id: id.toString() },
      {
        handleSuccess: response => {
          const modifiedData = serverDataMapper(response.data.routes.rows[0]);
          this.setState({ data: { ...modifiedData }, serverData: { ...modifiedData } }, () => {
            this.getRouteList();
          });
        },
        handleError: error => {},
      },
    );
  };

  getRouteList = () => {
    const { routeList, data } = this.state;
    const { getRouteList, displayAlert } = this.props;

    getRouteList(
      {
        offset: 0,
        filter: {
          filters: [
            {
              column: 'town_id',
              value: [data.townId.toString()],
            },
          ],
        },
      },
      {
        handleSuccess: response => {
          routeList.list = response.data.routes.rows;
          this.setState({ routeList });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  /**
   *
   * @param filter = { town_id: [], category_id: []}
   */
  onFilterChange = filter => {
    this.setState({ filter }, () => this.updateData());
  };

  onSearchChange = searchText => {
    this.setState({ searchText }, () => this.updateData());
  };

  handleFilterChange = (outletDetails, filterHandler, value) => {
    const data = { ...outletDetails };
    data[OUTLET_TYPES.ASSIGNED].outlets = filterHandler(
      data[OUTLET_TYPES.ASSIGNED].outlets.map(d => ({ ...d, categoryId: d.Category.id })),
      value,
    );
    data[OUTLET_TYPES.ASSIGNED].count = data[OUTLET_TYPES.ASSIGNED].outlets.length;
    data[OUTLET_TYPES.UNASSIGNED].outlets = filterHandler(
      data[OUTLET_TYPES.UNASSIGNED].outlets.map(d => ({ ...d, categoryId: d.Category.id })),
      value,
    );
    data[OUTLET_TYPES.UNASSIGNED].count = data[OUTLET_TYPES.UNASSIGNED].outlets.length;
    data[OUTLET_TYPES.OTHER].outlets = filterHandler(
      data[OUTLET_TYPES.OTHER].outlets.map(d => ({ ...d, categoryId: d.Category.id })),
      value,
    );
    data[OUTLET_TYPES.OTHER].count = data[OUTLET_TYPES.OTHER].outlets.length;

    return data;
  };

  getSearchResult = (list, searchText) => {
    const regex = new RegExp(searchText, 'i');
    const result = list.filter(o => o.title.search(regex) > -1);

    return result;
  };

  getFilteredResult = (list, filter) => {
    let modifiedList = [];
    let isFilterListEmpty = true;
    Object.keys(filter).forEach((item, index) => {
      if (filter[item].selectedIdList && filter[item].selectedIdList.length > 0) {
        const sourceList = index === 0 || isFilterListEmpty ? [...list] : modifiedList;
        isFilterListEmpty = false;
        modifiedList =
          filterSelectedListDetails(sourceList, filter[item].selectedIdList, filterFieldMapper[item]) || [];
      }
    });

    modifiedList = isFilterListEmpty ? [...list] : modifiedList;
    return modifiedList || [];
  };

  updateData = () => {
    const { filter, serverData, searchText } = this.state;

    let modifiedData = clone(serverData);
    if (searchText) {
      modifiedData = this.handleFilterChange(modifiedData, this.getSearchResult, searchText);
    }
    if (!isObjectEmpty(filter)) {
      modifiedData = this.handleFilterChange(modifiedData, this.getFilteredResult, filter);
    }

    this.setState({ data: modifiedData });
  };

  /**
   * outlets = {
   *  unassigned: [],
   *  otherRouteAssigned: []
   * }
   * * */
  handleAssignToRoute = (outlets = {}) => {
    const { data } = this.state;
    this.onFormSubmit(EVENT_OPERATION.ASSIGN, crudRequestConfig, { id: data.id, outlets });
  };

  handleAssignToOtherRoute = (outlets = {}, id) => {
    const { data } = this.state;
    this.onFormSubmit(EVENT_OPERATION.ASSIGN, crudRequestConfig, { id, outlets, sourceRouteId: data.id });
  };

  onAssignToRouteSuccess = (outlets = {}, destRouteId = null) => {
    const { serverData } = this.state;
    let updatedData = { ...serverData };

    if ((outlets[OUTLET_TYPES.ASSIGNED] || []).length > 0) {
      updatedData = this.updateOutletsCategory(
        outlets[OUTLET_TYPES.ASSIGNED].map(d => parseInt(d.id, 10)),
        OUTLET_TYPES.ASSIGNED,
        OUTLET_TYPES.OTHER,
        destRouteId,
      );
    }
    if ((outlets[OUTLET_TYPES.UNASSIGNED] || []).length > 0) {
      updatedData = this.updateOutletsCategory(
        outlets[OUTLET_TYPES.UNASSIGNED].map(d => parseInt(d.id, 10)),
        OUTLET_TYPES.UNASSIGNED,
        OUTLET_TYPES.ASSIGNED,
        destRouteId,
      );
    }
    if ((outlets[OUTLET_TYPES.OTHER] || []).length > 0) {
      updatedData = this.updateOutletsCategory(
        outlets[OUTLET_TYPES.OTHER].map(d => parseInt(d.id, 10)),
        OUTLET_TYPES.OTHER,
        OUTLET_TYPES.ASSIGNED,
        destRouteId,
      );
    }

    this.setState({ serverData: updatedData }, () => this.updateData());
  };

  handleRemoveFromRoute = (outletIds = []) => {
    const { data } = this.state;
    this.onFormSubmit(EVENT_OPERATION.BULK_DELETE, crudRequestConfig, { id: data.id, outletIds });
  };

  onRemoveFromRouteSuccess = (outlets, destRouteId = null) => {
    const updatedData = this.updateOutletsCategory(
      outlets.outletIds.map(d => parseInt(d.id, 10)),
      OUTLET_TYPES.ASSIGNED,
      OUTLET_TYPES.UNASSIGNED,
      destRouteId,
    );

    this.setState({ serverData: updatedData }, () => this.updateData());
  };

  updateSelectedOutletGroup = (type, selectedItems) => {
    const { serverData } = this.state;
    const outlets = serverData[type].outlets.filter(outlet => !selectedItems.includes(outlet));
    const count = serverData[type].count - selectedItems.length;

    return { outlets, count };
  };

  updateOutletsCategory = (triggeredOutlets, triggeredOutletType = '', targetedOutletType = '', destRouteId = null) => {
    const { serverData } = this.state;
    const filteredItems = filterSelectedListDetails(serverData[triggeredOutletType].outlets, triggeredOutlets);
    let updatedFilterItems = [...filteredItems];
    if (destRouteId) updatedFilterItems = updatedFilterItems.map(outlet => {
      outlet.routeId = destRouteId;
      return outlet;
    });
    serverData[targetedOutletType].outlets = serverData[
      targetedOutletType].outlets.concat(updatedFilterItems);
    serverData[targetedOutletType].count = serverData[
      targetedOutletType].count + updatedFilterItems.length;
    serverData[triggeredOutletType] = this
      .updateSelectedOutletGroup(triggeredOutletType, filteredItems);

    return serverData;
  };

  updateState(field, value) {
    this.setState({ [field]: value });
  }

  handleIconClick = (type, element = {}) => {
    const { outletVisibility } = this.state;
    switch (type) {
      case WIDGETS.VIEW:
        outletVisibility[OUTLET_TYPES.UNASSIGNED] = !outletVisibility[OUTLET_TYPES.UNASSIGNED];
        outletVisibility[OUTLET_TYPES.OTHER] = !outletVisibility[OUTLET_TYPES.OTHER];
        this.setState({ outletVisibility });
        break;
      default:
        break;
    }
  };

  displayMessage = (type, message) => {
    const { displayAlert } = this.props;
    displayAlert(type, message);
  };

  render() {
    const { data, outletVisibility, filter, routeList } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <OutletsStyled>
        <div className="route-wrap">
          <div className="inner">
            <div className="map-container">
              <Map
                data={{ ...data }}
                outletVisibility={{ ...outletVisibility }}
                onAssignToRoute={this.handleAssignToRoute}
                onRemoveFromRoute={this.handleRemoveFromRoute}
              />
            </div>
            <div className="route-manage-wrap">
              <div className="route-manage-top">
                <h2>Manage Outlets</h2>
                <div className="action-content">
                  <Search handleSearchInput={this.onSearchChange} />
                  <Filter metaData={filter} menuList={filterConfig.menu} onFilterChange={this.onFilterChange} />
                  <div>
                    <Button iconBtnSmall secondary onClick={() => this.handleIconClick(WIDGETS.VIEW)}>
                      <Icon
                        iconName={outletVisibility[OUTLET_TYPES.ASSIGNED]
                          && outletVisibility[OUTLET_TYPES.UNASSIGNED]
                          && outletVisibility[OUTLET_TYPES.OTHER]
                          ? 'view' : 'hidden'}
                      />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="route-manage-content">
                <ManageOutlets
                  data={{ ...data }}
                  onAssignToRoute={this.handleAssignToRoute}
                  onRemoveFromRoute={this.handleRemoveFromRoute}
                  onAssignToOthers={this.handleAssignToOtherRoute}
                  routeList={routeList}
                  loading={serverResponseWaiting}
                />
              </div>
            </div>
          </div>
        </div>
      </OutletsStyled>
    );
  }
}

Outlets.propTypes = propTypes;
Outlets.defaultProps = defaultProps;

export default withAlert()(Outlets);
