import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_BU_LIST } from '../../rscore/bu/API';

const GET_PURCHASE_REQUISITION_ORDER = gql`
query getOrdersForPurchaseRequisition ($channelId: Int!, $buId: Int!, $dateRange: DateRangeFilter!) {
  getOrdersForPurchaseRequisition(channelId: $channelId, buId: $buId, dateRange: $dateRange) {
    skuId
    brandId
    buId
    quantity
    outletChainId
    lastPurchasedDate
    p3mOfftake
    p1mOfftake
    rateDetails {
      rlp
    }
    amountDetails {
      rate
      grossAmount
      taxableAmount
      netAmount
      taxAmount
      discountAmount
      billDiscount
      promotionDiscount
      topUpDiscount
      subTotal
      tradeDiscount
    }
    orderDetails {
      orderId
      callId
      retailOutletId
      retailOutlet
      quantity
      images {
        callImages {
          src:image
          thumbnail
        }
      }
    }
    SKU {
      title
      details {
        unitsPerCase
      }
    }
    Brand{
      id
      title
    }
  }
}
`;

const CREATE_PURCHASE_REQUISITION = graphql(
  gql`
  mutation createPurchaseRequisitionOrder($input: MTPurchaseRequisitionInput!) {
    createPurchaseRequisitionOrder(input: $input) {
      id
      buId
      channelId
      requisitionDate
      amountDetails {
        grossAmount
        netAmount
        taxAmount
        taxableAmount
      }
      Channel {
        title
      }
    }
  }
  `, {
    props: ({ mutate }) => ({
      createPurchaseRequisitionOrder: variables => mutate({
        variables,
      }),
    }),
  },
);

const UPDATE_PURCHASE_REQUISITION = graphql(
  gql`
  mutation editOrdersForPurchaseRequisition($input: [MTOrderEditInput]!) {
    editOrdersForPurchaseRequisition(input: $input) {
      skuId
      quantity
      quantityInCases
      amountDetails {
        grossAmount
        discountAmount
        taxableAmount
        taxAmount
        netAmount
        subTotal
      }
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      editOrdersForPurchaseRequisition: variables => mutate({
        variables,
      }),
    }),
  },
);

const query = {
  getBuList: GET_BU_LIST,
  getOrdersForPurchaseRequisition: GET_PURCHASE_REQUISITION_ORDER,
};

const mutation = {
  editOrdersForPurchaseRequisition: UPDATE_PURCHASE_REQUISITION,
  createPurchaseRequisitionOrder: CREATE_PURCHASE_REQUISITION,
};


export {
  query, mutation, UPDATE_PURCHASE_REQUISITION, CREATE_PURCHASE_REQUISITION,
};
