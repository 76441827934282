import { compose } from 'recompose';
import Replaced from './Replaced';
import { DISPATCH_REPLACEMENT_ORDERS } from '../API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedReplaced = compose(
  DISPATCH_REPLACEMENT_ORDERS,
  withRequestTracker({ mutation: { dispatchReplacementOrder: DISPATCH_REPLACEMENT_ORDERS } }),
)(Replaced);

export default composedReplaced;
