import styled from 'styled-components';

const DetailStyled = styled.div`
table {
  border: 0;
  tr {
    &.collapsible-body-tr {
      tbody {
        tr {
          th,
          td {
            &:first-child {
              padding: 7px 12px 7px 70px;
              width: 38%;
            }
            &:nth-child(2),&:nth-child(3),&:nth-child(5) {
              width: 10%;
            }
            
            &:nth-child(4),:last-child {
              width: 15%;
            }
          }
        }
      }
    }
  }
  tbody {
    tr {
      &.active {
        td{
          &:first-child > span:before {
          transform: rotate(180deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #004ad7;
          }
        }
      }
      .input-section{
        width: 180px;
      }
      td{
        &:first-child {
          width: 38%;
          > span {
            position: relative;
            padding-left: 26px;

            &:before {
              content: '';
              width: 0;
              height: 0;
              border-radius: 4px;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #6b6c7e;
              position: absolute;
              top: 5px;
              left: 0;
              transition: all 0.23s ease-in-out;
            }
          }
        }
        &:nth-child(2),&:nth-child(3),&:nth-child(5) {
          width: 10%;
        }
        
        &:nth-child(4),&:last-child {
          width: 15%;
        }
      }
    }
  }
}
.upload-text{
    position: absolute;
    left: 50%;
    top: 300%;
    transform: translate(-50%,-50%);
  }
`;

export default DetailStyled;
