import { compose } from 'recompose';
import Inventory from './Inventories';
import { query, mutation } from './API';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedInventory = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(Inventory);

export default ComposedInventory;
