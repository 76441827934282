import { reports as reportsRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'VGP Report';

const breadCrumb = [reportsRoute];

const filter = getFilterConfig([ FILTER.CHANNEL, FILTER.FILTERED_OUTLET ]);

export {
  breadCrumb as breadCrumbConfig, filter as filterConfig, title
};
