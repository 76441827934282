import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
  DOWNLOAD_REPORT, TOGGLE_STATE, UPLOAD
} from '../../common/GqlAPI';

const DISBURSEMENT_FRAGMENT = gql`
  fragment DisbursementFragment on PromotionDisbursement {
    dimension
    value
    freeSKUs {
      skuId
    }
    allowMultiple
  }
`;

const FOCUSED_SKUS_FRAGMENT = gql`
  fragment FocusedSKUsFragment on FocusedSKUs {
    id
  }
`;

const SBD_FRAGMENT = gql`
  fragment SBDFragment on SBDFragment {
    id
  }
`;

const ACTIVE_SKU_FRAGMENT = gql`
  fragment ActiveSKUsFragment on activeSKUsFragment {
    id
    title
  }
`;

const CRITERIA_FRAGMENT = gql`
  fragment CriteriaFragment on PromotionCriteria {
    dimension
    operation {
      min {
        value
        operator
      }
      max {
        value
        operator
      }
    }
    skuCount
    allowMultiple
  }
`;

const PROMOTION_FRAGMENT = gql`
  fragment promotionFragment on Promotion {
    id
    title
    shortDescription
    budget
    active
    categoryIds
    type
    startDate
    endDate
    applicableSkus {
      skuFamily {
        id
        criteria {
          ...CriteriaFragment
        }
        skus {
          id
          criteria {
            ...CriteriaFragment
          }
        }
      }
      criteria {
        ...CriteriaFragment
      }
      familyStatus
    }
    relatedId
    relatedType
    validCount {
      status
      count
    }
    platform {
      retailer
      marketplace
      sales
    }
    billing_status
    notification
    banner: bannerImage {
      image
      thumbnail
      created_at
    }
  }
  ${CRITERIA_FRAGMENT}
`;

const PROMOTION_SUMMARY_FRAGMENT = gql`
  fragment promotionSummaryFragment on Promotion {
    id
    title
    active
    type
    startDate
    endDate
    skus: SKUs {
      brand {
        title
      }
    }
  }
`;

const PROMOTION_AREA_FRAGMENT = gql`
  fragment promotionAreaFragment on PromotionArea {
    domain
    ref_id
  }
`;

const GET_PROMOTION_LIST = gql`
  query promotions($offset: Int, $limit: Int, $filter: FilterInput) {
    promotions(offset: $offset, limit: $limit, filter: $filter) {
      count
      rows {
        ...promotionSummaryFragment
      }
    }
  }
  ${PROMOTION_SUMMARY_FRAGMENT}
`;

const CREATE_PROMOTION = graphql(
  gql`
    mutation ($name: String!, $userGrpId: Int!, $displayName: String!) {
      createPromotion(input: { name: $name, userGroupId: $userGrpId, displayName: $displayName }) {
        id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createPromotion: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const REPLICATE_PROMOTION = graphql(
  gql`
    mutation ($id: Int!, $input: PromotionReplicationInput!) {
      replicatePromotion(id: $id, input: $input) {
        ...promotionSummaryFragment
      }
    }
    ${PROMOTION_SUMMARY_FRAGMENT}
  `,
  {
    props: ({ mutate }) => ({
      replicatePromotion: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const REPLICATE_PROMOTIONS = graphql(
  gql`
    mutation ($input: PromotionChangeInput!) {
      replicatePromotions(input: $input) {
        ...promotionSummaryFragment
      }
    }
    ${PROMOTION_SUMMARY_FRAGMENT}
  `,
  {
    props: ({ mutate }) => ({
      replicatePromotions: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const REPLICATE_ALL_PROMOTIONS = graphql(
  gql`
    mutation ExtendPromotions($input: extendPromotionInput!) {
      extendPromotions(input: $input) {
        title
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      extendPromotions: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getPromotionList: GET_PROMOTION_LIST,
};

const mutation = {
  upload: UPLOAD,
  replicatePromotion: REPLICATE_PROMOTION,
  toggleState: TOGGLE_STATE,
  createPromotion: CREATE_PROMOTION,
  downloadReport: DOWNLOAD_REPORT,
  replicatePromotions: REPLICATE_PROMOTIONS,
  extendPromotions: REPLICATE_ALL_PROMOTIONS,
};

export {
  REPLICATE_PROMOTION,
  PROMOTION_FRAGMENT,
  PROMOTION_AREA_FRAGMENT,
  CRITERIA_FRAGMENT,
  DISBURSEMENT_FRAGMENT,
  CREATE_PROMOTION,
  GET_PROMOTION_LIST,
  TOGGLE_STATE,
  UPLOAD,
  DOWNLOAD_REPORT,
  REPLICATE_PROMOTIONS,
  REPLICATE_ALL_PROMOTIONS
};

export { query, mutation };
