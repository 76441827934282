import React from 'react';
import PropTypes from 'prop-types';
import { Search, Switch } from '../../../../../../../../components';
import SBDStyle from '../../SBD/SBDStyle';
import { Badge } from '../../../../../../../../v4/components';


const propTypes = {
  readMode: PropTypes.bool,
  sosku: PropTypes.instanceOf(Object).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handlePrimarySwitch: PropTypes.func.isRequired,
};

const defaultProps = {
  readMode: false,
};



const SOSKU = (props) => {
  const { readMode, productCategoryList, cardId, count, onProductCategoryClick, brandList, onBrandClick, onHandleSearchInput, searchText, SKUFamilyList, handleSwitchChange } = props;
  const { productCategoryId, brandId } = cardId;
  const { brandCount, categoryCount } = count;
  const getCount = (countItems, id, key, type) => {
    const countItem = countItems.find((item) => item[key] === id) || {};
    if (type === 'badge') {
      return countItem.count ? <Badge light badge label={countItem.count} className='badge' /> : '';
    }
    return countItem.count ? countItem.count : 0;
  };
  return (
    <div className='create-ui-inner tab-less-height'>
      <section className='assortment-detail'>
        <div className='select-section-wrap'>
          <SBDStyle>
            <div className='panel-section'>
              <div className='panel one'>
                <p className='p-title'>
Product Category
                </p>
                <ul>
                  {productCategoryList.map((group) => (
                    <li
                      key={group.id}
                      onClick={() => onProductCategoryClick(group.id)}
                      className={
                        parseInt(productCategoryId, 10) === group.id
                          ? 'active'
                          : 'inactive'
                      }
                      title={group.title}
                    >
                      <p>{group.title}</p>
                      {getCount(categoryCount, group.id, 'id', 'badge')}
                    </li>
                  ))}
                </ul>
              </div>
              <>
                {productCategoryId && (
                  <div className='panel two'>
                    <p className='p-title'>Brands</p>
                    <ul>
                      {brandList.map((brand) => (
                        <li
                          key={brand.id}
                          onClick={() => onBrandClick(brand.id)}
                          className={
                            parseInt(cardId.brandId, 10) === brand.id
                              ? 'active'
                              : 'inactive'
                          }
                          title={brand.title}
                        >
                          <p>{brand.title}</p>
                          {getCount(brandCount,  brand.id, 'id', 'badge')}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {brandId && (
                  <div className='panel three'>
                    <div className='panel-title'>
                      <p className='p-title'>SKU Family</p>
                      <Search
                        searchText={searchText}
                        clearText={!searchText}
                        handleSearchInput={onHandleSearchInput}
                      />
                    </div>
                    <div className='div-thead'>
                      <span>SKU Family</span>
                      <div className='status'>
                        <span>Status</span>
                      </div>
                    </div>
                    <div className='div-tbody'>
                      {SKUFamilyList.map((skuFamily,i) => (
                        <div className='header-wrap'>
                          <span>
                            <span className='text'>
                              {skuFamily.title}
                            </span>
                          </span>
                          <div onClick={(e) => e.stopPropagation()}>
                            <Switch
                              className={readMode ? 'disabled' : null}
                              checked={skuFamily.status}
                              onToggle={(checked, e) => {
                                e.stopPropagation();
                                handleSwitchChange(
                                  {
                                    formattedValue: !!checked,
                                    target: {
                                      name: 'status',
                                      type: 'boolean',
                                      value: !!checked,
                                    },
                                  },
                                  'parent', ['SKUFamilyList', `${i}`,'status'],skuFamily.id,0,checked,
                                );
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            </div>
          </SBDStyle>
        </div>
      </section>
    </div>
  );
};

SOSKU.propTypes = propTypes;
SOSKU.defaultProps = defaultProps;

export default SOSKU;
