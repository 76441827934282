import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { menuAction } from '../../../../utils/paginatedListUtils';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';
import { normalPresentor } from '../../../../utils/date';

const detailLabelMappings = {
  batch: 'batch',
  mfgDate: 'mfgDate',
  expDate: 'expDate',
  adjustment: 'adjustment',
  closingBalance: 'closingBalance',
  damages: 'damages',
  shortages: 'shortages',
  expiry: 'expiry',
  actionHolders: 'actionHolders',
};

const detailsLabelConfig = [
  {
    id: 1,
    label: detailLabelMappings.batch,
    title: 'Batch',
    show: true,
  },
  {
    id: 2,
    label: detailLabelMappings.mfgDate,
    title: 'MFG Date',
    show: true,
  },
  {
    id: 3,
    label: detailLabelMappings.expDate,
    title: 'Expiry Date',
    align: true,
    show: true,
  },
  {
    id: 4,
    label: detailLabelMappings.adjustment,
    title: 'Adjustment',
    align: true,
    show: true,
  },
  {
    id: 5,
    label: detailLabelMappings.closingBalance,
    title: 'Closing Balance',
    align: true,
    show: true,
  },
  {
    id: 6,
    label: detailLabelMappings.damages,
    title: 'Damaged',
    align: true,
    show: true,
  },
  {
    id: 7,
    label: detailLabelMappings.expiry,
    title: 'Expired',
    align: true,
    show: true,
  },
  {
    id: 8,
    label: detailLabelMappings.shortages,
    title: 'Shortage',
    align: true,
    show: true,
  },
  {
    id: 9,
    label: detailLabelMappings.actionHolders,
    title: '',
    show: true,
    align: true,
  },
];
const menuConfigList = [
  {
    title: 'Adjust',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
];

const returnDetailTableBody = (data, onIconClick, permission) => {
  const table = {};
  table[detailLabelMappings.batch] = <td key={`${data.SKUBatch.id}-id`}>{data.SKUBatch.batchName}</td>;
  table[detailLabelMappings.mfgDate] = (
    <td key={`${data.SKUBatch.id}-mfgDate`}>{normalPresentor(data.SKUBatch.manufactureDate)}</td>
  );
  table[detailLabelMappings.expDate] = (
    <td key={`${data.SKUBatch.id}-expDate`} className='text-right'>
      {normalPresentor(data.SKUBatch.expiryDate)}
    </td>
  );
  table[detailLabelMappings.adjustment] = (
    <td key={`${data.SKUBatch.id}-adjustment`} className='text-right'>
      <span className={!data.negative ? (data.adjustment !== 0 ? 'positive' : '') : 'negative'}>{data.adjustment}</span>
    </td>
  );
  table[detailLabelMappings.closingBalance] = (
    <td key={`${data.SKUBatch.id}-closingBalance`} className='text-right'>
      {data.balance}
    </td>
  );
  table[detailLabelMappings.damages] = (
    <td key={`${data.SKUBatch.id}-damages`} className='text-right'>
      {data.damages}
    </td>
  );
  table[detailLabelMappings.expiry] = (
    <td key={`${data.SKUBatch.id}-expiry`} className='text-right'>
      {data.expiry}
    </td>
  );
  table[detailLabelMappings.closingBalance] = <td key={`${data.SKUBatch.id}-closingBalance`} className="text-right">{data.balance}</td>;
  table[detailLabelMappings.damages] = <td key={`${data.SKUBatch.id}-damages`} className="text-right">{data.damages}</td>;
  table[detailLabelMappings.expiry] = <td key={`${data.SKUBatch.id}-expiry`} className="text-right">{data.expiry}</td>;
  table[detailLabelMappings.shortages] = <td key={`${data.SKUBatch.id}-shortages`} className="text-right">{data.shortages}</td>;
  table[detailLabelMappings.actionHolders] = permission.update && permission.create && (
    <td
      key={`stockAdjustment-${data.SKUBatch.id}-actions`}
      className='text-right simple-popup-actions'
      onClick={(e) => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );
  return table;
};

const DetailTableBody = ({ data, onIconClick, permission }) => {
  const tableBody = returnDetailTableBody(data, onIconClick, permission);

  return <Fragment>{detailsLabelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

DetailTableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
};

DetailTableBody.defaultProps = {
  data: {},
  onIconClick: () => null,
  permission: PERMISSION_OBJ,
};

export { DetailTableBody, detailsLabelConfig };
