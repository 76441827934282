import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import { navigationConfig } from './config';
// TODO enable event listener once it gets done.
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { addMouseClickListener, removeMouseClickListener } from '../../utils/event';
import { has } from '../../utils/objectPrototypes';
import { Icon } from '../../v4/components';
import history from '../../utils/history';
import { ALERT_TYPE } from '../../data/enums/AlertType';
import { getUser } from '../../data/dao';
import WithAlert from '../../utils/composition/withAlert';
import {JWT, LOGIN_SUCCESS_BASE_URL} from '../../environment';
import withLoading from '../../utils/composition/withLoading';
import { getFromLocalStorage } from '../common/HelperFunctions';
import {userRoleForTASDashboard} from '../login/config';
import {USER_ROLE as USER_ROLE} from '../../data/enums/UserRole';
import * as RouteLink from '../../data/enums/Route';

const navigationTitles = {
  'SBD Configuration': 'sbd',
  'Focused SKU Configuration': 'focusedSKU',
  'Route Segmentation': 'routeSegmentation',
  'Channel Assortment': 'skuAssortment',
  'Brand Distribution Target': 'brandDistributionCoverageTarget',
  'Active Outlet Target': 'outletCoverageTarget',
  Assortment: 'skuAssortment',
  Returns: 'srn',
  'User Fundamental Targets': 'fundamental_targets',
  'Outlet Fundamental Targets': 'fundamental_targets',
  'Fundamental Targets': 'fundamental_targets',
};

const propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Navigation extends Component {
  state = {
    showSecondaryNavigation: false,
    primaryNavigation: '',
    secondaryNavigation: [],
    // eslint-disable-next-line react/no-unused-state
    hasError: false,
    config: [],
  };

  navigationRef = React.createRef();

  static getDerivedStateFromError() {
    return { hasError: false };
  }

  componentDidMount() {
    addMouseClickListener(this.onMouseClick);
    this.loadConfig();
  }

  loadConfig = () => {
    const parsedConfig = localStorage.getItem(JWT.SESSION_STORAGE.APP_STORAGE.PAGE);
    const config = JSON.parse(parsedConfig).sort((a, b) => a.id - b.id) || [];
    this.setState({ config });
  };

  onMouseClick = event => {
    // eslint-disable-next-line max-len
    const clickedInsideDiv =
      this.navigationRef && this.navigationRef.current && this.navigationRef.current.contains(event.target);
    if (!clickedInsideDiv) {
      this.setState({ showSecondaryNavigation: false });
    }
  };

  handlePrimaryNavigationClick = element => {
    const userDetails=getUser();
    const isExternalUser = userDetails?.Role?.id === USER_ROLE.VIRTUAL_USER;
    const isTASUser=Object.values(userRoleForTASDashboard).includes(userDetails.roleId);

    const dasboardUrl = isExternalUser
      ? `/${RouteLink.RSCORE}`
      : isTASUser
      ? `/${RouteLink.TAS_DASHBOARD}`
      : `/${RouteLink.DASHBOARD}`;

    if (element?.title === 'Dashboard') {
      history.push(dasboardUrl);
      this.setState({
        showSecondaryNavigation: false,
      });
    } else if (element?.title === 'Rscore Dashboard') {
      history.push('/r-score');
      this.setState({
        showSecondaryNavigation: false,
      })
    }
    else if (element.title === 'CALL PLAN') {
      history.push('/tas/callplan');
      this.setState({
        showSecondaryNavigation: false,
      });
    }
    
    else {
      this.setState({
        showSecondaryNavigation: true,
        primaryNavigation: element.title,
        secondaryNavigation: element.children,
      });
    }
  };

  getPrimaryNavigation = () => {
    const { config } = this.state;

    return config.map(element => {
      const splittedPrimaryUrl = window.location.pathname.split('/')[1];
      const isActive = splittedPrimaryUrl && splittedPrimaryUrl.toUpperCase() === element.icon.toUpperCase();
      return (
        <>
          {element?.active && (
            <div
              key={`primary-nav-${element.title}`}
              className={isActive ? 'menu-item-link active' : 'menu-item-link'}
              data-text={element.title}
              onClick={() => this.handlePrimaryNavigationClick(element)}
            >
              <div className="img-holder">
                <img src={`/image/navigation-icons/${element.icon}.svg`} alt={element.title} />
              </div>

              <p>{element.title?.replace('Rscore Dashboard', 'R-Score Dashboard')}</p>
            </div>
          )}
        </>
      );
    });
  };

  checkNavigationTitles = (data, title) => {
    const values = data[navigationTitles[title]];
    switch (title) {
      case 'Channel Assortment':
      return values?.channel; 
      case 'Assortment':
        return values?.distributorGroup; 
      case 'User Fundamental Targets':
        return values?.find(a=>a.targetType=== "USERTARGET")?.status;
        case 'Outlet Fundamental Targets':
        return values?.find(a=>a.targetType=== "OUTLETTARGET")?.status;
       case 'Fundamental Targets':
        return values?.find(a=>a.targetType=== "OUTLETTARGET")?.status || values?.find(a=>a.targetType=== "USERTARGET")?.status;
      default:
        break;
    }
    return values;
  }


  getSecondaryNavigation = (baseNavigationLevel = false) => {
    const { secondaryNavigation } = this.state;

    const { ...localDataStorage } = getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS);
    const checkNavigationValues = d => this.checkNavigationTitles(localDataStorage, d.title);
    const data = d => {
      if (checkNavigationValues(d)) {
        d.active = true;
      }
      if (checkNavigationValues(d) !== undefined && checkNavigationValues(d) === false) {
        d.active = false;
      }
    };

    secondaryNavigation.forEach(data);

    const secNav = secondaryNavigation
      .filter(d => d.active)
      .map(nav => {
        if (!has.call(nav, 'children') || !nav.children) {
          if (baseNavigationLevel) {
            return <div className="no-title-nav">{this.getNavigationLink(nav)}</div>;
          }
          return this.getNavigationLink(nav);
        }

        nav.children.forEach(data);

        return (
          <div className="sec-nav-content">
            <h3 className="sec-title">{nav.title}</h3>
            {nav && nav.children ? (
              <div className="sec-nav-inner">
                {nav.children
                  .filter(d => d.active)
                  .map(secNavigation => {
                    if (!has.call(secNavigation, 'children')) {
                      return this.getNavigationLink(secNavigation);
                    }
                    secNavigation.children.forEach(data);
                    return (
                      <div className="sec-nav-sub-menu">
                        <h3 className="sec-nav-link-title">{secNavigation.title}</h3>
                        {secNavigation.children
                          .filter(d => d.active)
                          .map(tertiaryNavigation => this.getNavigationLink(tertiaryNavigation))}
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </div>
        );
      });

    const secondaryNavigationHeader = this.getSecondaryNavigationHeader();

    return (
      <Fragment>
        <Fragment>{secondaryNavigationHeader}</Fragment>
        <div className="no-title-nav-wrap">{secNav}</div>
      </Fragment>
    );
  };

  getSecondaryNavigationHeader = () => {
    const { primaryNavigation } = this.state;
    return <h3 className="title">{primaryNavigation}</h3>;
  };

  onNavURLClick = () => {
    this.setState({ showSecondaryNavigation: false });
  };

  getNavigationLink = nav => {
    if (nav.url) {
      const splitlength = nav.url.split('/').length;
      const isActive = window.location.pathname.split('/')[splitlength - 1] === nav.url.split('/')[splitlength - 1];

      return (
        <div key={nav.title} className={isActive ? 'sec-nav-link active' : 'sec-nav-link'}>
          <Link to={nav.url} onClick={() => this.onNavURLClick()}>
            {nav.title}
          </Link>
        </div>
      );
    }
  };

  profile = () => {
    history.push('/user-profile');
  };

  logOut = e => {
    e.stopPropagation();
    const { logout, displayAlert } = this.props;
    logout(
      {},
      {
        handleSuccess: () => {
          history.push('/login');
          localStorage.clear();
          sessionStorage.clear();
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  render() {
    const { firstName, lastName } = JSON.parse(localStorage.getItem('rosia-detail'));
    const { showSecondaryNavigation } = this.state;
    const baseNavigationLevel = true;
    return (
      <Fragment>
        <div ref={this.navigationRef}>
          <div className="menu-wrap">
            <PerfectScrollbar>
              <div className="primary-nav">{this.getPrimaryNavigation()}</div>
            </PerfectScrollbar>
            <div className="other-nav text-center">
              <div className="nav-list">
                <Icon iconName="bell-on" />
              </div>
              <div className="nav-list profile" onClick={() => this.profile()}>
                <span>{`${firstName.split('')[0].toUpperCase()}${lastName.split('')[0].toUpperCase()}`}</span>
              </div>
              <div className="nav-list ">
                <img src="/image/navigation-icons/logout.svg" alt="logoutico" onClick={this.logOut} />
              </div>
            </div>
          </div>
          {showSecondaryNavigation && (
            <div>
              <div className="off-canvas-sec-nav">{this.getSecondaryNavigation(baseNavigationLevel)}</div>
            </div>
          )}
        </div>

        {showSecondaryNavigation && <div className="navigation-overlay" />}
      </Fragment>
    );
  }
}

Navigation.propTypes = propTypes;

Navigation.defaultProps = defaultProps;

export default WithAlert()(withLoading(Navigation));
