import React from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from '../../../components';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { menuAction, tableData } from '../../../utils/paginatedListUtils';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

// todo update the configurations.
const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  countdays: 'count_days',
  countskus: 'countskus',
  assigned_to:'assigned_to',
  actionHolders: 'actionHolders',
};
const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
 
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.checkbox] = (permission.delete && (
    <td
      key={`${data.id}-checkbox`}
      onClick={e => e.stopPropagation()}
    >
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
      />
    </td>
  ));
  table[labelMappings.title] = <td key={`${data.id}-title`}>{data.title}</td>;
  table[labelMappings.countdays] = <td key={`${data.id}-countday`}>{data.count_days}</td>;
  table[labelMappings.countskus] = <td key={`${data.id}-countsku`}>{data.countskus}</td>;
  table[labelMappings.assigned_to] = <td key={`${data.id}-assigned_to`}>{data.assigned_to}</td>;
  table[labelMappings.actionHolders] = (
    <td
      key={`${data.id}-actions`}
      className="text-right simple-popup-actions"
      onClick={e => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.countdays,
    title: 'Count Days',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.countskus,
    title: 'No of SKUS',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.assigned_to,
    title: 'assigned_to',
    show: false,
  },
  {
    id: 5,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];


const TableBody = ({
  data, checkedList, onIconClick, secondaryCheckboxHandler, permission,
}) => {
  const tableBody = returnTableBody(data, checkedList,
    onIconClick, secondaryCheckboxHandler, permission);

  return (
    <tr key={data.id} onClick={() => onIconClick(EVENT_OPERATION.DETAILS, data)} style={{ cursor: 'pointer' }}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

const TableHeader = ({
  dataListLength, checkedListLength, primaryCheckboxHandler, permission,
}) => (
  <thead className="">
    <tr>
      {labelConfig.map(label => (label.show
        ? label.label === labelMappings.checkbox
          ? (dataListLength > 0 && permission.delete) && (
          <th>
            <CheckBox
              checked={(dataListLength === checkedListLength)}
              onCheckBoxClick={e => primaryCheckboxHandler(e)}
            />
          </th>
          )
          : tableData(label.title, label.align)
        : null))}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export {
  TableHeader,
  TableBody,
};
